// React
import React from 'react';
import PropTypes from 'prop-types';
// Chart
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
// Material UI Components
import Paper from '@mui/material/Paper';
// Util
import { createData, getOptions } from 'components/filter/slider/SliderPieChart/SliderPieChart.util';
// Style
import { useStyles } from 'components/filter/slider/SliderPieChart/SliderPieChart.style';
//Locales
import translate from 'locales/i18n';

const SliderPieChart = ({ sites }) => {
    const classes = useStyles();

    return (
        <>
            {!sites || sites.length === 0 ?
                <Paper className={classes.paper}>
                </Paper>
                :
                <Paper className={classes.paper}>
                    <div className={classes.title}>
                        {translate.i18n('SLIDER_PIE_TITLE')}
                    </div>
                    <div className={classes.graphContainer}>
                        <Pie
                            plugins={[ChartDataLabels]}
                            data={createData(sites)}
                            options={getOptions(createData(sites))} />
                    </div>
                </Paper>
            }
        </>
    );
};

SliderPieChart.propTypes = {
    sites: PropTypes.array
};

export default SliderPieChart;
