import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: '2px',
        top: '2px'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    dialogContentNotFullScreen: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '200px',
        minWidth: '300px'
    },
    dialogTitle: {
        marginRight: '20px'

    },
    leftIcon: {
        fontSize: 20,
        marginRight: theme.spacing(1)
    },
    textField: {
        margin: theme.spacing(1),
        width: '80%'
    }
}));
