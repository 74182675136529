// React
import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// Locales
import translate from 'locales/i18n';
// Material UI Components
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import LockIcon from '@mui/icons-material/LockOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import KeyIcon from '@mui/icons-material/VpnKey';
import AccountIcon from '@mui/icons-material/AccountCircle';
// Actions
import SignAction from 'actions/SignAction';
import LayoutAction from 'actions/LayoutAction';
// Logo
import PCPLogo from 'assets/img/white-pcp-logo.png';
// Stores
import UserStore from 'stores/UserStore';
import LayoutStore from 'stores/LayoutStore';
// Facade
import Facade from 'core/Facade';
// Styles
import styles from 'components/pages/LoginPage/LoginPage.style';

class LoginPage extends React.PureComponent {

    state = {
        errMsg: '',
        isLoading: false,
        isLogged: UserStore.isLogged(),
        login: UserStore.credentials.login,
        password: '',
        showPassword: false
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    componentDidMount() {
        UserStore.addChangeListener(this.onUserStoreChange);
        this.mounted = true;
    }

    componentWillUnmount() {
        UserStore.removeChangeListener(this.onUserStoreChange);
        this.mounted = false;
    }

    onUserStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            isLoading: UserStore.isLoading,
            isLogged: UserStore.isLogged()
        });
    };

    signIn = () => {
        const { login, password } = this.state;

        if (login !== '' && password !== '') {
            SignAction.signIn(this.state.login, this.state.password)
                .then(() => true, response => {
                    if (response.retries || response.errMsg) this.setState({ errMsg: response.error, retries: response.retries });
                    if (response && response.error && response.error === 'NO_ASSOCIATED_ACCOUNTS') {
                        LayoutAction.setError('NO_ACCOUNTS');
                    }
                });
        } else {
            this.setState({ errMsg: 'ERROR_FILL_ALL_FIELDS' });
        }
    };

    handleKeyPress = (event) => {
        const { login, password } = this.state;

        if (event.key === 'Enter') {
            if (login !== '' && password !== '') {
                SignAction.signIn(this.state.login, this.state.password)
                    .then(() => true,
                        response => {
                            if (response.retries || response.errMsg) this.setState({ errMsg: response.error, retries: response.retries });
                            if (response && response.error && response.error === 'NO_ASSOCIATED_ACCOUNTS') {
                                LayoutAction.setError('NO_ACCOUNTS');
                            }
                        });
            } else {
                this.setState({ errMsg: 'ERROR_FILL_ALL_FIELDS' });
            }
        }
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    render() {
        const { classes } = this.props;

        if (this.state.isLogged) {
            const redirection = LayoutStore.getLocationRedirect();
            LayoutStore.cleanLocationRedirect();
            if (redirection) {
                return (
                    <Redirect
                        to={{
                            pathname: redirection.pathname,
                            search: redirection.search,
                        }}
                    />
                );
            }
            return (
                <Redirect to='/smart-price-extractor' />
            );

        }

        if (this.state.isLoading) {
            return (
                <div className={classes.loginBackground}>
                    <img src={PCPLogo} className={classes.logo} alt='pricecomparator-logo'></img>
                    <div className={classes.loginContainer}>
                        <Paper className={classes.paper}
                            elevation={20} >
                            <CircularProgress className={classes.progress} size={50} />
                        </Paper>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.loginBackground}>
                <img src={PCPLogo} className={classes.logo} alt='pricecomparator-logo'></img>
                <div className={classes.loginContainer}>
                    {/* <div className={classes.promoText} style={{ fontSize: '19px' }}>
                        {translate.i18n('PRODUCT_PAGE_PROMO_FIRST')}
                    </div>
                    <div className={classes.promoText} style={{ marginBottom: '20px' }}>
                        {translate.i18n('PRODUCT_PAGE_PROMO_SECOND')}
                    </div> */}
                    {Facade.getEnv() !== 'production' &&
                        <div className={classes.envMessage}>
                            Vous êtes sur le front de <a className={classes.envText}>{Facade.getEnv().toUpperCase()}</a>
                        </div>
                    }
                    <Paper className={classes.paper}
                        elevation={20}>
                        <Avatar className={classes.avatar}>
                            <LockIcon />
                        </Avatar>
                        {this.state.errMsg && <p className={classes.errorMessage}>{typeof errMsg === 'object' ? translate.i18n(this.state.errMsg[1]) : this.state.errMsg === 'ERROR_AUTHENTICATION_FAILURE' ? translate.i18n(this.state.errMsg, { retries: this.state.retries }) : translate.i18n(this.state.errMsg)}</p>}
                        <TextField
                            error={!!this.state.errMsg}
                            className={classes.textField}
                            id='login'
                            label={translate.i18n('LOGIN')}
                            value={this.state.login}
                            onChange={this.handleChange('login')}
                            onKeyPress={this.handleKeyPress}
                            margin='normal'
                            variant='outlined'
                            autoComplete='username'
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position='start'>
                                        <AccountIcon className={classes.startIcon} />
                                    </InputAdornment>
                            }}
                        />
                        <TextField
                            error={!!this.state.errMsg}
                            className={classes.textField}
                            id='password'
                            type={this.state.showPassword ? 'text' : 'password'}
                            label={translate.i18n('PASSWORD')}
                            value={this.state.firstname}
                            onChange={this.handleChange('password')}
                            onKeyPress={this.handleKeyPress}
                            margin='normal'
                            variant='outlined'
                            autoComplete='current-password'
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='Toggle password visibility'
                                            onClick={this.handleClickShowPassword}
                                            size='large'>
                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>,
                                startAdornment:
                                    <InputAdornment position='start'>
                                        <KeyIcon className={classes.startIcon} />
                                    </InputAdornment>
                            }}
                        />
                        <Button variant='contained' color='primary' onClick={() => this.signIn()} className={classes.button}>
                            {translate.i18n('LOGIN_BUTTON')}
                        </Button>
                        <Link to='/forgot-password' className={classes.link}>
                            {translate.i18n('PASSWORD_FORGOTTEN')}
                        </Link>
                    </Paper>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(LoginPage);
