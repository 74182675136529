// Facade
import Facade from 'core/Facade';
//Locales
import translate from 'locales/i18n';

/**
  * Method which return the options for a graph display
  *
  * @param data <object> Data object to display graph
  * @returns <object> the options object
  */
export const getOptions = data => ({
    maintainAspectRatio: false,
    plugins: {
        datalabels: {
            backgroundColor: (context) => context.dataset.backgroundColor,
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 2,
            color: 'white',
            anchor: 'end',
            font: {
                weight: 'bold'
            },
            padding: 6,
            formatter: (value, context) => context.chart.data.labels[context.dataIndex],
        },
        title: {
            display: true,
            text: '',
        },
        tooltips: {
            callbacks: {
                label: tooltipItems => data.labels[tooltipItems.index]
            }
        },
        legend: {
            display: false
        }
    },
    layout: {
        padding: {
            bottom: 10
        }
    },
});

/**
  * Method which create data for a graph display
  *
  * @param sites <array<sites>> the array of sites
  * @returns <object> Data object formatted to display graph
  */
export const createData = sites => {
    const dataSlidesTotals=[];
    const dataSlideslabels=[];
    const colors = [];
    let otherSlides = 0;
    let slidesTotal = 0;
    if (sites.length === 0)
        return {
            datasets: [{
                data: []
            }],
            labels: []
        };

    sites.forEach(site => slidesTotal += site.nbNew + site.nbRemoved);

    sites.forEach(site => {
        if (site.nbNew + site.nbRemoved < slidesTotal/50) {
            otherSlides += site.nbNew + site.nbRemoved;
            return true;
        }
        dataSlidesTotals.push(site.nbNew + site.nbRemoved);
        dataSlideslabels.push(Facade.siteDisplayName(site.name) + ' ( + ' + site.nbNew + ')');
        colors.push(site.color);
        return true;
    });
    if (otherSlides !== 0) {
        dataSlidesTotals.push(otherSlides);
        dataSlideslabels.push(translate.i18n('OTHERS'));
        colors.push('rgb(190, 215, 219)');
    }

    const data = {
        datasets: [{
            backgroundColor: colors,
            data: dataSlidesTotals,
            hoverBackgroundColor: colors
        }],
        labels: dataSlideslabels
    };

    return data;
};

