const locales = {
    ABOUT: 'About',
    ABOUT_DIALOG: {
        CONTACT: 'Contact us',
        TEXT: 'Made with love in Montpellier',
        TITLE: 'About PriceComparator.pro'
    },
    ACCOUNT: 'Account',
    ACCOUNT_NOT_FOUND: 'Account not found',
    ACTIVE: 'Active(s)',
    ACTUAL_SLIDES_MESSAGE: 'Current slides',
    ADBOX: {
        NAME: 'AdBox',
        TITLE1: 'Mails analysis between the',
        TITLE2: 'and the',
        MAIL_DAYS_TITLE: 'Number of mails per day',
        MAIL_HOURS_TITLE: 'Number of mails per hour',
        MAIL_PIE_TITLE: 'Number of mail sended',
        WEEKLY_MAIL_CONCENTRATION: 'Hourly mail focusing',
        NUMBER_OF_MAILS: 'Total number',
        TAG_CLOUD_TITLE: 'Distribution of words in email objects'
    },
    ADD: 'Add',
    ADD_AN_ITEM: 'Add an item',
    ADD_CATEGORY: 'Add the category {name} ?',
    ADD_ITEM: 'Add item',
    ADD_OR_FIND_CATEGORY: 'Add or find a category',
    ADD_RULE: 'Add rule',
    ADD_URL: 'Add an URL',
    ADDED: 'Added',
    ADJUSTED_PRICES: 'Adjusted prices',
    ADMIN: 'Admin',
    ALERTS: 'Alert(s)',
    ALL: 'All',
    ALL_COMPETITORS: ' according all my competitors',
    ALL_ITEMS: 'All items',
    ALL_STOCKS: 'and according all stocks',
    ALONE_SELLER: 'Only seller',
    ALPHA_ORDER: 'Alphabetical order',
    AMONG_CHEAPEST: 'Among cheapest',
    AMONG_MOST_EXPENSIVE: 'Among most expensive',
    ANALIZE: 'Analize',
    ANALYZE: 'Analyze',
    ANALYZE_BY_CATEGORY: 'Analyze by category',
    AND: 'and',
    APPLICATIONS_DATES: 'Applications dates',
    APPLIED_RULE: 'Applied rule',
    ARTICLES: 'Products',
    ASSOCIATED_ARTICLES_LIST: 'Associated products list',
    ASSOCIATED_RULE: 'Associated rule',
    ATTRIBUTE_ERROR: 'Empty, duplicated or incorrect attribute',
    ATTRIBUTE_NAME: 'Attribute name',
    ATTRIBUTE_REGISTER_WARN: 'Be carefeul, the changes will only be saved after you clic the SAVE button on main page',
    ATTRIBUTE_VALUE: 'Attribute value',
    AVAILABILITY: 'Availability',
    AVAILABLE_SOON: 'Available soon',
    AVG_PRICE: 'at average price',
    BASED_ON: 'Based on',
    BASED_ON_ELEM: 'based on {elem}',
    BEGINNING_DATE: 'Beginning',
    BETWEEN: 'Between',
    BRAND: 'Marque',
    BUYING_PRICE: 'Prix d\'achat',
    BY_VALUE: 'by {value}',
    CANCEL: 'Cancel',
    CANNOT_RETRIEVE_USER_INFO: 'Impossible de récupérer les informations de l\'utilisateur',
    CATALOG_SEARCH: 'Search in catalog',
    CATEGORIES: 'Categories',
    CATEGORY: 'Category',
    CATEGORY_FILTER_EXPLANATION_1: 'This filter lists all your categories according to several criteria :',
    CATEGORY_FILTER_EXPLANATION_2: 'The competitor\'s price positioning compared to yours (the darker the color, the less expensive your competitors are).',
    CATEGORY_FILTER_EXPLANATION_3: 'As well as the number of products present in the selected filters on the total number of products (the length and cardinalities on the right of the filter).',
    CATEGORY_FILTER_EXPLANATION_4: 'The categories are clickable to refine your search, as well as the sorting options at the top of the filter.',
    CATEGORY_FILTER_EXPLANATION_PRICING_ENGINE_1: 'This filter lists all your categories according to several criteria currently and after repricing :',
    CATEGORY_FILTER_EXPLANATION_PRICING_ENGINE_3: 'As well as the number of products present in the selected filters on the total number of products (length and cardinalities in the center of the filter)',
    CATEGORY_ID: 'Category',
    CATEGORY_MANAGEMENT: 'Category management of "{name}"',
    CATEGORY_NAME: 'Category name',
    CATEGORY_NOT_FOUND: 'Category not found',
    CHANGE_ATTRIBUTES: 'Update attributes',
    CHANGE_PASSWORD: 'Change password',
    CHANGE_URL: 'Change URL',
    CHANGE_VIEW: 'Change view',
    CHANGES: 'changes',
    CHANGES_BY_WEEK: 'changes by week',
    CHANGES_ON_SELECTED_PERIOD: 'Changes on selected period',
    CHEAPEST: 'Cheapest',
    CHECK_CODE: 'For your safety, we need to verify your identity. The PriceComparator security team just emailed you a code at {email}. Please enter this code below.',
    CHOOSE_A_DELAY: 'Choose a delay',
    CHOOSE_A_NEW_PASSWORD: 'Choose a new password',
    CHOOSE_A_REPORT: 'Choose a report',
    CHOOSE_CONFIG: 'Choose a configuration',
    CLICK_HERE: 'click here',
    CLOSE: 'Close',
    CODE_NOT_GOOD: 'The code is not the ONE !',
    COLOR: 'Color',
    CONFIGURATION: 'Configuration',
    CONFIRM_DELETE_HISTORY: 'Be aware that if you do this, associated product history will be destroyed !',
    CONFIRM_DELETE_ITEM: 'Are you sure do you want to delete this item? This action is irreversible.',
    CONFIRM_DELETE_RULE: 'Do you really want delete the rule {ruleName} ?',
    CONFIRM_DELETE_USER: 'Do you really want delete this user?',
    CONFIRM_EDIT_ITEM: 'Are you sure do you want to edit this item? This action is irreversible.',
    CONFIRM_PASSWORD: 'Confirm password',
    CONTACT_SUPPORT: 'Please contact support',
    COPY_TO_CLIPBOARD: 'Copy to clipboard',
    COUNT_OF_PRODUCTS: 'Nombre de produits',
    COUNT_PRODUCTS: '({count} Product[s])',
    CRITICAL_CATEGORY: 'Critical category',
    CRITICAL_PRICES: 'Critical prices',
    CURRENT_PASSWORD: 'Current password',
    CURRENT_SLIDES: 'Current slide(s)',
    DATE: 'Date',
    DAYS: 'Days',
    DELAY: 'Delay',
    DELETE: 'Delete',
    DELETE_ARTICLE: 'Delete this article',
    DELETE_ATTRIBUTE: 'Delete attribute',
    DELETE_CATEGORY: 'Do you really want to delete the category {name}, there are probably some items associated with it?',
    DELETED: 'Deleted',
    DELETED_SLIDES: 'Removed slide(s)',
    DETAILS: 'Details',
    DIALOG_ADD_URL: {
        ADD: 'Add',
        ADD_CONFIG: 'Add config',
        BRAND: 'Brand',
        CATEGORY: 'Category',
        CONFIG_NAME: 'Name of the config',
        CONFIGS: 'Configs',
        CURRENT_ARTICLE: 'Article currently selected for',
        DELETE: 'Delete',
        DELETE_CONFIRM: 'Do you really want to delete the URL ?',
        DELETE_URL: 'Delete URL',
        EAN: 'EAN',
        ENTER_CONFIGS: 'Add configurations',
        ENTER_OTHER: 'Enter an other value',
        ENTER_URL: 'enter URL or choose one in suggestions',
        FINISH_CONFIG: 'Click to finish URL configurtion',
        MULTIPLY_BY: 'Mutiply by',
        NEW_PRODUCT: 'The URL does not mtch any suggestion',
        NO_PRODUCT: 'No selected product',
        NONE: 'None',
        NOT_SOLD: 'Not sold',
        NV: 'The article is not available on this URL or is not sold in th website',
        NV_BLL_URL: 'Product not available on this URL',
        NV_URL: 'Product not sold on this website',
        OPERATIONS: 'Operations',
        PENDING_PRODUCT: 'Product info\'s extraction is ongoing',
        PRICE: 'Price',
        REF: 'Ref',
        RRP: 'RRP',
        SAVE_CHANGES: 'Save changes',
        SAVE_WOUT_CONF: 'Save without configurtion',
        SEARCH_GOOGLE: 'Search in google shopping',
        SEARCH_IN_SUGGESTIONS: 'Search in suggestions',
        SEE_ON_SITE: 'See on site',
        SEE_PROPS: 'See values',
        SELECT: 'Select',
        SUGGESTION_INFO_MESSAGE: 'Displayed informations may be slightly different from current website infos',
        SUGGESTIONS: 'Suggestions',
        URL_ERROR: 'The written url does not match the website',
        VALUE: 'Value',
        YOUR_ARTICLE: 'Your article'
    },
    DIALOG_CONFIRM_MAIL_SEARCH: 'The search you ask fored is a long time range and should take a few minutes to perform. Would you like to continue?',
    DIFF_ALERTING_PRICE: 'Price difference with alerting price',
    DIFF_RETAILER_PRICE: 'Price difference with your price',
    DISCOUNT: 'Discount',
    DISCOUNT_RRP: 'Discount RRP',
    DISTRIBUTION_OF_REPRICED_PRICES: 'Distribution of the repriced prices',
    DND_ATTRIBUTES: 'Drag and drop attributes to change their order',
    DO_NOT_HAVE_MODULE: '(You do not have this module)',
    DROPPING_PRICES: 'Dropping prices',
    EACH_ITEM_CAN_HAVE_A_RULE: 'You can add multiple rules to a product, the one with the highest rank will apply, if it\'s not applicable, it will be the next one and so on until one can apply',
    EDIT: 'Edit',
    EDIT_ARTICLE: 'Edit article',
    EMAIL: 'Email',
    EMAIL_NOT_REGISTERED: 'Your email does not seems to be associated to an account.',
    END_DATE: 'End date',
    ENDING: 'Ending',
    EQUAL_PRICES: 'Equal prices',
    ERR_PRICING_ENGINE_ALREADY_USED_RULE_NAME: 'Rule is already used',
    ERROR_ADD_ARTICLE: 'Product cannot be added',
    ERROR_AUTHENTICATION_FAILURE: 'Wrong password, you have {retries} tries left.',
    ERROR_BLOCKED_USER: 'Your user is blocked, please contact PriceComparator support.',
    ERROR_CREATE_RULE: 'Rule cannot be created',
    ERROR_DELETE_ARTICLE: 'Product cannot be deleted',
    ERROR_EMAIL: 'Your email is not valid.',
    ERROR_FIELD_EMPTY: 'Please fill this field',
    ERROR_FILL_ALL_FIELDS: 'Please fill all the fields.',
    ERROR_INVALID_PRODUCT_ID: 'Incorrect product id',
    ERROR_MARKETPLACE: 'This url must be a url marketplace.',
    ERROR_OPEN_SUGGESTIONS: 'Product name is needed to launch lucky search',
    ERROR_REQUIRED_ATTRIBUTES: 'Every required attributes need to be completed',
    ERROR_RETRIEVE_ARTICLE: 'Product cannot be retrieved',
    ERROR_RETRIEVE_ARTICLES: 'Products cannot be retrieved',
    ERROR_RETRIEVE_EMAILS: 'Emails cannot be retrieved',
    ERROR_RETRIEVE_HISTORY: 'History cannot be retrieved',
    ERROR_RETRIEVE_PERFORMANCES: 'Performances cannot be retrieved',
    ERROR_RETRIEVE_RULES: 'Rules cannot be retrieved',
    ERROR_RETRIEVE_SLIDERS: 'Sliders cannot be retrieved',
    ERROR_RETRIEVE_SUGGESTIONS: 'Suggestions cannot be retrived',
    ERROR_UPDATE_ARTICLE: 'Product cannot be updated',
    ERROR_UPDATE_CATEGORY: 'Category cannot be modified',
    ERROR_UPDATE_RULE: 'Rule cannot be created',
    ERROR_USER_SELECTED_NOT_EXIST: 'The user does not exist.',
    EVOLUTION_OF_PRICE_AND_STOCK: 'Evolution of price and stock',
    EXPORT_DATA: 'Export data',
    EXPORT_REPORTS: 'Export report',
    EXTRACTING: 'Extracting',
    FILE_EXPORTED: 'The file has been exported',
    FILE_NAME: 'File name',
    FILL_PASSWORD: 'Fill password fields.',
    FILTERS: 'FILTERS',
    FIRST_COLUMN_IS_YOURS: 'Your column : Your current prices',
    FIRSTNAME: 'Firstname',
    FORBIDDEN_MODULE: 'You do not have access to this feature',
    FRIDAY: 'friday',
    FROM: 'From',
    GENDER: 'Gender',
    GIVE_REGISTERED_EMAIL: 'Send the email associated to your account',
    HELP: 'Help',
    HIDE_FILTERS: 'Hide filters',
    HOME: 'Home',
    HOW_IT_WORKS: 'How that works?',
    I_WANT_TO_BE: 'I want to be ',
    ICON_INDICATE_INFORMATIONS: 'Information point.',
    ICON_INDICATE_REPRICED_PRICE_AND_VARIATION: 'New price calculated from the applied rule.',
    ICON_INDICATE_REPRICING_ERROR: 'None of the associated rules are applicable (product parameter(s) missing or calculation inconsistency).',
    IF_YOU_LEAVE: 'If you leave without save, your changes will be lost.',
    IMG_URL: 'URL Image',
    IMPOSSIBLE_REPRICING: 'Impossible repricing',
    IN: 'in',
    INSERT_CODE: 'Enter the code received',
    INVALID_DATE: 'The date is not valid',
    INVALID_URL: 'Invali url',
    ITEM_DELETE_CONFIRM: 'Do you really want to delete the following item : {name}?',
    ITEMS: 'Items',
    ITEMS_SELECTION: 'Items selection',
    LASTNAME: 'Lastname',
    LAUNCH_SEARCH: 'Launch a search',
    LEADERSHIP_FILTER_1_RETAILER: 'This filter represents the price positioning of each of your competitors into the market :',
    LEADERSHIP_FILTER_1_SUPPLIER: 'This filter represents the price positioning of each of your distributors into the market :',
    LEADERSHIP_FILTER_2: 'You can hover over the sections to get more information, and click on them to refine your search.',
    LEADERSHIP_FILTER_PRICING_ENGINE: 'This filter represents the price positioning of each of your competitors into the market currently and after repricing :',
    LEADERSHIP_TITLE: 'Price leadership',
    LEGEND: 'Legend :',
    LESS_THAN: 'Less than {number}',
    LESS_THAN_LIMIT: 'Less than minimal threshold from {min} to {max}',
    LIBRARY: 'Library',
    LIMIT: 'Limit',
    LIMIT_PRICE: 'Limit price',
    LIST_OF_PRODUCTS: 'PRODUCTS LIST',
    LIST_OF_REPRICED_ITEMS: 'PRODUCTS LIST',
    LISTING: 'Listing',
    LOGIN: 'Login',
    LOGIN_BUTTON: 'Login',
    LOGOUT: 'Logout',
    MAILS: 'Mails',
    MANAGE_CATEGORIES: 'Manage categories',
    MANAGE_PRODUCTS: 'Manage products',
    MANAGE_WEBSITES: 'Manage website',
    MAP_FILTER_EXPLANATION_1: 'This map shows you the localization of your competitors around the world and gives you some additional information :',
    MAP_FILTER_EXPLANATION_2: 'The size, which represents the number of products sold by your customer in common with your catalog.',
    MAP_FILTER_EXPLANATION_3: 'The color, which tells you where you stand relative to your competitor\'s prices.',
    MAP_FILTER_EXPLANATION_4: '(The different points on the map are clickable if you want to select specific sites)',
    MARGIN: 'Margin',
    MARKETPLACE_REPLACE_URL: '{siteName} is a marketplace. PriceComparator is configured to retrieve all the offers proposed for the product. If necessary, the url you have just entered will be replaced by the url corresponding to the page containing all the offers. No action on your part is required.',
    MARKETPLACE_REPORTS: 'Marketplace reports',
    MAX_PRICE: 'the cheaper',
    MAXIMUM_SALE_PRICE: 'Maximum sale price',
    MIN_PRICE: 'the most expensive',
    MINIMUM_SALE_PRICE: 'Minimum sale price',
    MISSING_RETAIL_PRICE: 'Please fill retail price',
    MISSING_RRP: 'Please fill RRP',
    MISSING_RRP_PERCENTAGE: 'Please fill discount RRP',
    MODE: 'Mode',
    MONDAY: 'monday',
    MORE_EXPENSIVE: 'More expensive',
    MORE_THAN: 'More than {number}',
    MORE_THAN_RRP: 'More than RRP from {min} to {max}',
    MOST_EXPENSIVE: 'Most expensive',
    MP_RECAP_NOT_FOUND: 'The marketplace reports are not found',
    MULTIPLY_BY: 'Multiply by',
    MUST_CONTAINS_: 'It must contain at least one digit (0 … 9).',
    MUST_CONTAINS_8_CHAR: 'It must contain at least 8 characters.',
    MUST_CONTAINS_ONE_LOWER_CASE: 'It must contain at least one lowercase characters (a … z).',
    MUST_CONTAINS_ONE_UPPER_CASE: 'It must contain at least one uppercase characters (A … Z).',
    MUST_CONTAINS_SPECIAL_CHAR: 'It must contain at least one special character among !?=_€£$;&@+-',
    NAME: 'Name',
    NAME_IS_MISSING: 'You have to set up name before save your rule',
    NAME_OF_THE_RULE: 'Name of the rule (obligatory)',
    NEITHER_MOST_EXPENSIVE_NOR_CHEAPER: 'Neither most expensive nor less expensive',
    NEW_SLIDES: 'New slide(s)',
    NEW_SLIDES_MESSAGE: 'New slides',
    NEXT: 'Next',
    NO_ACCOUNTS: 'No account associated to your user',
    NO_BEGINNING_DATE: 'No beginning date',
    NO_CATEGORY: 'No category',
    NO_DATA_FOUND: 'No data found',
    NO_DATA_YET: 'No scraping data yet',
    NO_DATE: 'No date',
    NO_EFFECT: 'No effect',
    NO_EVALUATION: 'No evaluation',
    NO_INFORMATION: 'No information',
    NO_ITEM_FOUND: 'No item found',
    NO_LIMIT_DATE: 'No limit date',
    NO_MAIL_FOR_THIS_DATES: 'No mail found for this dates',
    NO_NEW_SLIDES_MESSAGE: 'No new slides for this date',
    NO_PRICE: 'No price',
    NO_PRICE_FOUND: 'No price found',
    NO_PROMO_PRICE: 'Price without promo',
    NO_RULE_ASSOCIATED: 'No rule associated',
    NO_RULE_SELECTED: 'No rule selected',
    NO_SLIDES_MESSAGE: 'No slides',
    NO_SUGGESTIONS_FOUND: 'No suggestions found',
    NO_SUGGESTIONS_FOUND_FOR: 'No suggestions found for "{text}"',
    NOT_APPLICABLES_RULES: 'Rule not applied',
    NOT_FOUND: '{dataName} not found',
    NOT_FOUND_EVAL: 'Not found',
    NOT_SOLD: 'Not sold',
    NOT_SOLD_BELL: 'Not sold (temporary)',
    OF: 'of',
    OF_MY: 'of my',
    OLD_PASSWORD_NOT_GOOD: 'Your old password is not good.',
    ONLY_WITHOUT_RULES: 'Only items without rules',
    OPEN: 'Open',
    OPEN_MENU: 'Open menu',
    OPPONENTS_AVERAGE: 'Opponent average',
    OPPORTUNITY: 'Oportunity',
    OPTIONAL_ATTRIBUTES: 'Optional attributes',
    OTHERS: 'Others',
    PAGE_NOT_FOUND: 'This page could not be found.',
    PASSWORD: 'Password',
    PASSWORD_DOES_NOT_MATCH_CRITERIA: 'Your password does not match the criteria.',
    PASSWORD_FORGOTTEN: 'Password forgotten ?',
    PASSWORD_HAS_BEEN_RESET: 'Password has been reset go back to login.',
    PASSWORDS_DOES_NOT_MATCH: 'The passwords does not match.',
    PENDING_EXTRACTION: 'The extraction of the price will be made tomorrow, thanks for your understanding',
    PERCENTAGE: 'Percentage',
    PERFORMANCE: 'Performance',
    POSITIONING: 'Positioning',
    POSITIONING_IS_MISSING: 'Select positioning',
    POSITIONING_KPIS: {
        AVG_MARGIN: 'Average margin',
        AVG_POSITION: 'Average position',
        AVG_PRICE_DIF: 'Average price difference',
        TITLE: 'EVOLUTION OF YOUR POSITIONING'
    },
    PREVIOUS: 'Previous',
    PRICE: 'Price',
    PRICE_ADVISING: 'Price advising',
    PRICE_BASKETS_FILTER_1_RETAILER: 'This filter represents the positioning of your prices compared to those of your competitors from "below -50%" to "above 50%" :',
    PRICE_BASKETS_FILTER_1_SUPPLIER: 'This filter represents the positioning of your distributor\'s prices in relation to your "RRP" and your "Limit" :',
    PRICE_BASKETS_FILTER_2: 'You can hover over the columns to get more information, and click on them to refine your search.',
    PRICE_BASKETS_FILTER_3_RETAILER: 'The "=" sign represents the column of prices equal to yours.',
    PRICE_BASKETS_FILTER_3_SUPPLIER: 'The acronym "RRP" and "Limit" represents the column of prices equal to the RRP or the limit.',
    PRICE_BASKETS_FILTER_PRICING_ENGINE: 'This filter represents the positioning of your prices compared to those of your competitors from "below -50%" to "above 50%" currently and after repricing :',
    PRICE_BETWEEN_RRP_LIMIT: 'Price between min and RRP',
    PRICE_EQUAL_LIMIT: 'Price equal to minimal threshold',
    PRICE_EQUAL_RRP: 'Price equal to RRP',
    PRICE_LIKE_YOURS: 'Prices same as yours',
    PRICE_MOVEMENTS: 'Price movements',
    PRICE_SECTION: 'Price section',
    PRICE_STATS: 'Price stats',
    PRICES_BASKETS_TITLE_RETAILER: 'Competitor prices compared to yours',
    PRICES_BASKETS_TITLE_SUPPLIER: 'Reseller prices compared to RRP',
    PRICES_FILTERED: 'prices filtered',
    PRICES_LESS_EXPENSIVE: '{number}% of prices are less expensive',
    PRICES_LESS_EXPENSIVE_BR: '{number}% of prices are </br> less expensive',
    PRICING_ENGINE: 'Pricing Engine',
    PRICING_ENGINE_ANALYZE: 'Pricing Engine analyze',
    PRICING_ENGINE_INFO: {
        ASSOCIATE_RULE_TEXT: 'to your product : Chose a rule in the right part of your screen (Rules Library section) and drag it to the rank whom you wish it to applies',
        ASSOCIATE_RULE_TITLE: 'To add a new rule',
        DELETE_RULE_TEXT: 'currently associated to a product : Take a rule in the left side (Repricing Rules section) and put it back in the library',
        DELETE_RULE_TITLE: 'To delete a rule',
        EDIT_RULE_TEXT: ': Chose a rule and simply drag it to the wished rank.',
        EDIT_RULE_TITLE: 'To change the order of rule application',
        RULE_ORDER_TEXT: 'When your product is associated to more than one rule, the Pricing Engine applies the rules in the ranking order and stops on the first who gives the effective adjusted price.',
        RULE_ORDER_TITLE: 'Order of rule application : how that works?',
        TITLE: 'Pricing Engine Informations'
    },
    PRICING_ENGINE_INFORMATIONS: 'Pricing engine informations',
    PRICING_ENGINE_LIBRARY: 'Pricing Engine Library',
    PRICING_ENGINE_RULE: 'Pricing Engine rule',
    PRICING_ENGINE_RULE_NOT_FOUND: 'Rule not found.',
    PRICING_ENGINE_RULES: 'Pricing Engine rules',
    PRICING_ENGINE_WARNING: 'This module allows you to define your price according to your own pricing rules.',
    PRINT: 'Print',
    PRINT_DIALOG_TITLE: 'To print this page you should set this params after clicking print button :',
    PRODUCT_NOT_FOUND: 'Product not found',
    PRODUCT_NOT_SET: 'Product not set',
    PRODUCT_PAGE_NO_HISTORY: 'No history available at this time',
    PRODUCT_PAGE_PRICE_SECTION_ALL_COMPETITOR_AVERAGE_PRICE: 'All competitor average price',
    PRODUCT_PAGE_PRICE_SECTION_MAX_PRICE: 'Max price',
    PRODUCT_PAGE_PRICE_SECTION_MIN_PRICE: 'Min Price',
    PRODUCT_PAGE_PRICE_SECTION_NO_PRICE: 'No price to be displayed',
    PRODUCT_PAGE_PRICE_SECTION_PRODUCT_ALERT_PRICE: 'Alert price',
    PRODUCT_PAGE_PRICE_SECTION_PRODUCT_AVERAGE_PRICE: 'Product average Price',
    PRODUCT_PAGE_PRICE_SECTION_PRODUCT_AVG_PRICE: 'Average Price',
    PRODUCT_PAGE_PRICE_SECTION_RRP_ALERT_MAX: 'RRP alrt max',
    PRODUCT_PAGE_PRICE_SECTION_RRP_ALERT_MIN: 'RRP alert min',
    PRODUCT_PAGE_PRICE_SECTION_YOUR_AVERAGE_PRICE: 'Your average price',
    PRODUCT_PAGE_PRICE_SECTION_YOUR_PRICE: 'Your price',
    PRODUCT_PAGE_PROMO_FIRST: 'Check out the new product page: new features, new graphs, new filters...',
    PRODUCT_PAGE_PROMO_SECOND: 'You can access it now via the Smart Price Extractor module by clicking on a product.',
    PROFILE: 'Profile',
    PUBLIC_PRICE: 'Public price',
    PURCHASING_PRICE: 'P. price',
    PURCHASING_PRICE_BASED: 'Based on purchasing price',
    RAISING_PRICES: 'Raising prices',
    RANK: 'Rank',
    RATING: 'Rating',
    REACTIVITY: 'Reactivity',
    RECOMMENDED_RETAIL_PRICE: 'Selling price advised',
    REF: 'Ref',
    REFERENCE: 'Reference',
    REMOVED_SLIDES_MESSAGE: 'Removed slides',
    RENAME: 'Rename',
    RENAME_CATEGORY: 'Please specify the new category name :',
    REPRICED_PRICES_GROUPS_EXPLANATION_1: 'This filter represent the distribution of the repriced prices suggesting to you :',
    REPRICED_PRICES_GROUPS_EXPLANATION_2: 'You can hover over the sections to get more information, and click on them to refine your search.',
    REPRICING: 'Repricing',
    REQUIRED_ATTRIBUTES: 'Required attributes',
    RESET_FILTERS: 'Reset filters',
    RETAIL_PRICE: 'Retail price',
    ROW_PER_PAGE: 'Row per page',
    RRP: 'RRP',
    RRP_ALERT_PERCENTAGE: 'RRP Alert Percentage',
    RULE_APPLICATION_POPPER: {
        FINAL_REPRICED_PRICE: 'Final repriced price',
        NONE: 'None',
        PE_ERR_ANALYSIS_DATE_AFTER_PERIOD: 'The Analysis period does not correspond to the application dates of the rule',
        PE_ERR_ANALYSIS_DATE_BEFORE_PERIOD: 'The Analysis period does not correspond to the application dates of the rule',
        PE_ERR_DATE_AFTER_PERIOD: 'The analysis date is after to the rule end date',
        PE_ERR_DATE_BEFORE_PERIOD: 'The analysis date is prior to the rule start date',
        PE_ERR_RULE_INVALID_PRICE_BOUNDS: 'The Minimum Price is bigger than the Maximum Price',
        PE_ERR_RULE_MAXIMUM_PRICE_ELEMENT_UNSUPPORTED_VALUE: 'Other product Attribute missing',
        PE_ERR_RULE_MAXIMUM_PRICE_MODE_UNSUPPORTED_VALUE: 'Error on Maximum Price: Unsupported element value/pourcentage ',
        PE_ERR_RULE_MAXIMUM_PRICE_PURCHASING_PRICE_MISSING: 'Purchasing price missing',
        PE_ERR_RULE_MAXIMUM_PRICE_RRP_MISSING: 'RRP missing',
        PE_ERR_RULE_MINIMUM_PRICE_ELEMENT_UNSUPPORTED_VALUE: 'Other product Attribute missing',
        PE_ERR_RULE_MINIMUM_PRICE_MODE_UNSUPPORTED_VALUE: 'Error on Minimum Price: Unsupported element value/percentage ',
        PE_ERR_RULE_MINIMUM_PRICE_PURCHASING_PRICE_MISSING: 'Purchasing price missing',
        PE_ERR_RULE_MINIMUM_PRICE_RRP_MISSING: 'RRP missing',
        PE_ERR_RULE_SKIPPED_VALUE: 'A higher ranked rule has given a repriced price.',
        PE_ERR_STRATEGY_IRRELEVANT_VALUE: 'The defined Strategy does not allow to obtain a relevant price (unsold product or inconsistent stock)',
        PE_ERR_STRATEGY_MAXIMUM_PRICE_ELEMENT_UNSUPPORTED_VALUE: 'Other product Attribute missing',
        PE_ERR_STRATEGY_MAXIMUM_PRICE_IRRELEVANT_VALUE: 'The defined Upper Bound does not allow to calculate the Strategy',
        PE_ERR_STRATEGY_MAXIMUM_PRICE_MODE_UNSUPPORTED_VALUE: 'The defined Upper Bound of the Startegy can\'t be calculated because of an unsupported element value/percentage',
        PE_ERR_STRATEGY_MAXIMUM_PRICE_PURCHASING_PRICE_MISSING: 'Purchasing price missing',
        PE_ERR_STRATEGY_MAXIMUM_PRICE_RRP_MISSING: 'RRP missing',
        PE_ERR_STRATEGY_MINIMUM_PRICE_ELEMENT_UNSUPPORTED_VALUE: 'Other product Attribute missing',
        PE_ERR_STRATEGY_MINIMUM_PRICE_IRRELEVANT_VALUE: 'The defined Lower Bound does not allow to calculate the Strategy',
        PE_ERR_STRATEGY_MINIMUM_PRICE_MODE_UNSUPPORTED_VALUE: 'The defined Lower Bound of the Startegy can\'t be calculated because of an unsupported element value/percentage',
        PE_ERR_STRATEGY_MINIMUM_PRICE_PURCHASING_PRICE_MISSING: 'Purchasing price missing',
        PE_ERR_STRATEGY_MINIMUM_PRICE_RRP_MISSING: 'RRP missing',
        PE_ERR_STRATEGY_OFFSET_MODE_UNSUPPORTED_VALUE: 'The offset can\'t be calculated because of an unsupported element value/percentage',
        PE_ERR_STRATEGY_OFFSET_NAN_VALUE: 'The offset does not allow to obtain a relevant price (unsold product or inconsistent stock).',
        PE_ERR_STRATEGY_POSITIONING_UNSUPPORTED_VALUE: 'The defined Strategy can\'t be calculated because of an unsupported Positionning element',
        PE_ERR_STRATEGY_ROUNDING_UNSUPPORTED_VALUE: 'The defined Strategy can\'t be calculated because of an unsupported Rounding element',
        RULE_APPLIED: 'Rule applied',
        RULE_NOT_APPLICABLE: 'Rule not applicable',
        STRATEGY_PRICE: 'Price from strategy'
    },
    RULE_ASSOCIATED_TO_X_ITEMS: 'This rule is associated to {nbOfItems} items',
    RULE_CREATED: 'Rule created',
    RULE_DELETED: 'Rule deleted',
    RULE_UPDATED: 'Rule updated',
    RULES: 'Rules',
    RULES_FILTER_EXPLANATION_1: 'This filter represents the distribution of associated rules : ',
    RULES_FILTER_EXPLANATION_2: 'If an article has multiple rules associated, only the applied one will be kept.',
    RULES_FILTER_TITLE: 'Distribution of the applied rules',
    SATURDAY: 'saturday',
    SAVE: 'Save',
    SAVE_PROFILE: 'Save profile',
    SCRAPING_DATA_NOT_FOUND: 'Scraping data not found',
    SEARCH: 'Search',
    SECOND_COLUMN_IS_PRICING_ENGINE: 'Repricing column : Your calculated prices',
    SECTOR_AVERAGE: 'Sector average',
    SEE_IMAGE: 'Visualize image',
    SEE_MORE: 'See more',
    SELECT: 'Select',
    SELECT_ALL_PRODUCTS: 'Select all products',
    SELECT_COMPETITORS: 'Select competitors',
    SELECT_STOCKS: 'Select stocks',
    SELECTED_SITES: 'Selected sites',
    SELECTED_STOCKS: 'Selected stocks',
    SELF_ORIENTED_STRATEGY: 'at fixed price',
    SELLER: 'Seller',
    SEND_CODE_AGAIN: 'Send code again',
    SENDERS: 'Senders',
    SEPARATOR: 'Separator',
    SESSION_EXPIRED: 'Your session has expired',
    SETTINGS: 'Settings',
    SHIPPING_COST: 'Shipping cost',
    SHIPPING_INFORMATION: 'Shipping information',
    SHORT_FRIDAY: 'Fri',
    SHORT_MONDAY: 'Mon',
    SHORT_SATURDAY: 'Sat',
    SHORT_SUNDAY: 'Sun',
    SHORT_THURSDAY: 'Thu',
    SHORT_TUESDAY: 'Tue',
    SHORT_WEDNESDAY: 'Wed',
    SHOW_FILTERS: 'Show more filters',
    SIGN_IN_ERROR: 'Wrong login or wrong password',
    SINCE_7_DAYS: 'Since 7 days',
    SINCE_LAST_VALUE: 'Since last value',
    SITE_NOT_FOUND: 'Site not found',
    SLIDER: 'Slider',
    SLIDER_DAY_TITLE: 'Number of new slides per day',
    SLIDER_PIE_TITLE: 'Number of new slides per sites',
    SMART_PRICE_EXTRACTOR: 'Smart Price Extractor',
    SOLD: 'Sold',
    SOME_COMPETITORS: ' according competitors selected',
    SOME_INFORMATIONS: 'Here are some important information before you start :',
    SOME_STOCKS: 'and according stocks selected',
    SORT_BY: 'Sort by',
    START_DATE: 'Start date',
    STATUS: 'Status',
    STOCK: 'Stock',
    STOCK_5_DAYS: '+5 days',
    STOCK_FILTER_1_RETAILER: 'This filter represents the stocks of each of your competitors :',
    STOCK_FILTER_1_SUPPLIER: 'This filter represents the stocks of each of your distributors :',
    STOCK_FILTER_2: 'You can hover over the sections to get more information, and click on them to refine your search.',
    STOCK_FILTER_TITLE: 'Stock availability',
    STOCK_IN: 'In stock',
    STOCK_MOVEMENTS: 'Stock movements',
    STOCK_NO_INFORMATION: 'Stock not specified',
    STOCK_PRICE_CHART_TITLE: 'Price and supply changes charts',
    STOCK_SOLD_OUT: 'Sold out',
    STOCK_SUP_48H: 'Greater than 48 hours',
    STRATEGY: 'Strategy',
    STRICTLY_CHEAPEST: 'Strictly cheapest',
    SUCCESS_ADD_ARTICLE: 'Product has been added',
    SUCCESS_COPY: 'Copied to clipboard',
    SUCCESS_DELETE_ARTICLE: 'Product has been deleted',
    SUCCESS_UPDATE_ARTICLE: 'Product has been updated',
    SUCCESS_UPDATE_CATEGORY: 'Category successfully updated',
    SUGGESTED_PRICE: 'Suggested price',
    SUNDAY: 'sunday',
    TEASER: {
        ACTION_BUTTON: 'Make an appointment with the customer department',
        ACTION_TEXT: 'You want to benefit from this module or learn more?',
        ADBOX_TEXT_1_0: 'Thanks to AdBox, don\'t suffer your market anymore! Identify the ',
        ADBOX_TEXT_1_1: 'promotional strategies of your competitors ',
        ADBOX_TEXT_1_2: 'through their emailing campaigns and newsletters (date, time and frequency of sending). The dashboard of the module and its graphs guide you to counter them with the most adapted strategy.',
        ADBOX_TEXT_1_3: 'The analysis of emailing campaigns is essential to elaborate relevant newsletters with a ',
        ADBOX_TEXT_1_4: 'high ROI guarantee. ',
        ADBOX_TEXT_1_5: 'PriceComparator allows you to choose the right offers to send at the right price. Both are essential to ensure good click-through rates, to ',
        ADBOX_TEXT_1_6: 'improve your e-reputation ',
        ADBOX_TEXT_1_7: 'and to increase your sales with each sending!',
        ADBOX_TEXT_1_TITLE: 'View the newsletters of your competitors and distributors',
        ADBOX_TEXT_2_0: 'Analyze your competitors\' latest promotions within the dashboard of our Adbox module. ',
        ADBOX_TEXT_2_1: 'Adopt the best possible promotion strategy ',
        ADBOX_TEXT_2_2: 'based on your competitors\' offers.',
        ADBOX_TEXT_2_3: 'Monitoring prices is essential. But if you are not aware of the release of a Promo Code from your competitor, then you are missing out on the "real" online prices and therefore possibly many sales!',
        ADBOX_TEXT_2_TITLE: 'Identify new sales operations of your competitors/distributors',
        PE_TEXT_1_0: 'Dynamic pricing allows you ',
        PE_TEXT_1_1: 'to develop the selling price automatically ',
        PE_TEXT_1_2: 'of your products by automatically optimizing your margins and your competitiveness.',
        PE_TEXT_1_3: 'With the ',
        PE_TEXT_1_4: 'Pricing Engine, ',
        PE_TEXT_1_5: 'define ',
        PE_TEXT_1_6: 'intelligent rules ',
        PE_TEXT_1_7: 'that adapt your prices to the daily movements of your competitors (prices, stocks, promotions, etc.).',
        PE_TEXT_1_8: 'From data collection to the ',
        PE_TEXT_1_9: 'application of your pricing strategies, ',
        PE_TEXT_1_10: 'through ',
        PE_TEXT_1_11: 'intelligent data analysis, ',
        PE_TEXT_1_12: 'PriceComparator and its Pricing Engine module provide the best technology on the market.',
        PE_TEXT_1_13: 'Propel your e-commerce towards price optimization and digital growth with high added value!',
        PE_TEXT_1_TITLE: 'What is the Pricing Engine by PriceComparator?',
        PE_TEXT_2_0: 'Seize competitive opportunities ',
        PE_TEXT_2_1: 'while saving time and respecting your overall e-commerce strategy, for ',
        PE_TEXT_2_2: 'more sales and margins!',
        PE_TEXT_2_3: 'Don\'t be surprised by competing actions during seasonal promotions. ',
        PE_TEXT_2_4: 'Analyze the impact of price movements on your price positioning ',
        PE_TEXT_2_5: 'and adjust your price rules according to your strategies. Your online traffic and your sales will be indicative of your performance!',
        PE_TEXT_2_6: 'Examples of what you can do with the Pricing Engine:',
        PE_TEXT_2_7_0: '- React automatically to competitor price changes',
        PE_TEXT_2_7_1: '- Automate the calculations of your optimal prices',
        PE_TEXT_2_7_2: '- Raise prices too low to increase your margins!',
        PE_TEXT_2_7_3: '- Optimize prices that are too high to boost your sales!',
        PE_TEXT_2_7_4: '- Anticipate your promotional campaigns and commercial offers',
        PE_TEXT_2_TITLE: 'Optimized repricing for each product',
        PERF_TEXT_1_0: 'With PriceComparator\'s Performance module, analyze ',
        PERF_TEXT_1_1: 'the sales and marketing performance ',
        PERF_TEXT_1_2: 'of your market in relation to you.',
        PERF_TEXT_1_3: 'Find the ',
        PERF_TEXT_1_4: 'winning strategies ',
        PERF_TEXT_1_5: 'of your market and prioritize your activities accordingly. Adapt your actions and your KPIs by following the performance of your market players and ',
        PERF_TEXT_1_6: 'reach your positioning objectives!',
        PERF_TEXT_1_TITLE: 'Analyze the performance of your market',
        PERF_TEXT_2_0: 'Track the frequentation of the sites of your sector ',
        PERF_TEXT_2_1: 'over regular and defined periods. Traffic trends, both organic (SEO) and paid (SEA), show the health of a website and its marketing performance. With the web performance dashboard, you can compare the traffic of players’ sites in your market and follow their evolution.',
        PERF_TEXT_2_2: 'The performance analysis allows you to identify your competitors\' actions and deduce their impact on your market. ',
        PERF_TEXT_2_3: 'Compare pricing, sales and marketing trends to determine winning strategies.',
        PERF_TEXT_2_TITLE: 'Follow the evolution of your market',
        SLIDER_TEXT_1_0: 'View the new banners and promotions ',
        SLIDER_TEXT_1_1: 'available on your competitors\' sites. Everything is accessible from the Slider module.',
        SLIDER_TEXT_1_2: 'Don\'t waste your time collecting and saving the latest news and promotional offers: ',
        SLIDER_TEXT_1_3: 'everything is automated!',
        SLIDER_TEXT_1_4: 'With the history and its access filters, ',
        SLIDER_TEXT_1_5: 'identify the winning strategies ',
        SLIDER_TEXT_1_6: 'and their periodicity to inspire you in turn!',
        SLIDER_TEXT_1_TITLE: 'View the promotions available on your competitors/distributors\' sites',
        SLIDER_TEXT_2_0: 'Thanks to the Slider module, you can follow the latest marketing trends and ',
        SLIDER_TEXT_2_1: 'sales operations of your competitors at a glance. ',
        SLIDER_TEXT_2_2: 'Stay reactive by launching your own commercial operations at the right time.',
        SLIDER_TEXT_2_3: 'The new Promo Code that has just been released or the new competitor\'s discount that has just appeared no longer catches you off guard and you can ',
        SLIDER_TEXT_2_4: 'react immediately to clear your stocks!',
        SLIDER_TEXT_2_TITLE: 'Gain in reactivity',
        SPE_TEXT_1_0: 'Internet users compare online offers in 2 clicks and choose the best one !',
        SPE_TEXT_1_1: 'Compare prices and stocks ',
        SPE_TEXT_1_2: 'of your competitors and identify your level of competitiveness compared to your market. ',
        SPE_TEXT_1_3: 'Everything is    automatic !',
        SPE_TEXT_1_4: 'Find out the ',
        SPE_TEXT_1_5: 'price evolution ',
        SPE_TEXT_1_6: 'of your competitors or resellers during the year thanks to the ',
        SPE_TEXT_1_7: 'complete history of each product. ',
        SPE_TEXT_1_8: 'Objective: ',
        SPE_TEXT_1_9: 'Develop your sales, maintain your margins while gaining market share.',
        SPE_TEXT_1_10: 'You can ',
        SPE_TEXT_1_11: 'sort the information ',
        SPE_TEXT_1_12: 'by brand, category and product in a few clicks ',
        SPE_TEXT_1_13: 'to focus on what’s important to you. ',
        SPE_TEXT_1_14: 'Adapt your pricing strategy at any time and identify your best strategy with the interactive charts of our pricing analysis module.',
        SPE_TEXT_1_TITLE: 'Gain in competitiveness',
        SPE_TEXT_2_0: 'View ',
        SPE_TEXT_2_1: 'all price movements ',
        SPE_TEXT_2_2: 'over the period you want for any product in your catalog.',
        SPE_TEXT_2_3: 'Identify which competitor is the most competitive, so that your price changes allow you to gain competitiveness in your market.',
        SPE_TEXT_2_4: 'Our robots collect the prices of your competitors or resellers every day. ',
        SPE_TEXT_2_5: 'You can be sure to have ',
        SPE_TEXT_2_6: 'the latest prices and availability of products online. ',
        SPE_TEXT_2_7: 'Adapt your market strategy according to the prices, but also the stocks of your competitors or resellers.',
        SPE_TEXT_2_TITLE: 'Identify price movements',
        TRENDS_TEXT_1_0: 'Assessing its price positioning in ',
        TRENDS_TEXT_1_1: 'times of inflation ',
        TRENDS_TEXT_1_2: 'is essential to maintain its sales, margins and brand image. ',
        TRENDS_TEXT_1_3: 'The stakes are enormous: ',
        TRENDS_TEXT_1_4: 'it is a question of increasing its prices in line with inflation to maintain its margins, while remaining attractive to its customers.',
        TRENDS_TEXT_1_5: 'The Trends module allows you ',
        TRENDS_TEXT_1_6: 'to compare the evolutions of the average prices ',
        TRENDS_TEXT_1_7: 'of all the players in your market. Choose the period and the curves appear to reveal the trends of each actor! An essential tool ',
        TRENDS_TEXT_1_8: 'to secure your pricing strategy ',
        TRENDS_TEXT_1_9: 'and your sales.',
        TRENDS_TEXT_1_TITLE: 'Measure the impact of inflation on your market',
        TRENDS_TEXT_2_0: 'Identify the evolution of the pricing strategies ',
        TRENDS_TEXT_2_1: 'of your competitors and resellers in the face of inflation. Maintain your positioning and your brand image with your customers to optimize your sales and your margins.',
        TRENDS_TEXT_2_2: 'Compare the impact of inflation ',
        TRENDS_TEXT_2_3: 'on different product categories to optimize your new product positioning and future categorization.',
        TRENDS_TEXT_2_4: 'Cross-reference the data to decipher the impact of inflation on your market and draw on decisive indicators ',
        TRENDS_TEXT_2_5: 'to achieve your sales ',
        TRENDS_TEXT_2_6: 'and ',
        TRENDS_TEXT_2_7: 'turnover objectives!',
        TRENDS_TEXT_2_TITLE: 'Inflation and trends in your sector'
    },
    THAT_IS_IT: 'That\'s it',
    THE: 'The',
    THURSDAY: 'thursday',
    TIPS_N_TRICKS: 'Tips & Tricks',
    TO: 'to',
    TO_HIGHEST_9: ' by rounding to the highest 9.',
    TO_HIGHEST_09: ' by rounding to the highest 0.9.',
    TO_HIGHEST_099: ' by rounding to the highest 0.99.',
    TO_LOWEST_9: ' by rounding to the lowest 9.',
    TO_LOWEST_09: ' by rounding to the lowest 0.9.',
    TO_LOWEST_099: ' by rounding to the lowest 0.99.',
    TO_NEAREST_9: ' by rounding to the nearest 9.',
    TO_NEAREST_09: ' by rounding to the nearest 0.9.',
    TO_NEAREST_099: ' by rounding to the nearest 0.99.',
    TO_PRICE: ' without rouding it.',
    TO_REQUEST_IT: 'To request it',
    TOP_CRITICAL_PRICES: 'Top critical prices',
    TOP_GROWTH: 'Top Growth',
    TOTAL_PRICE: 'Total price',
    TRENDS: {
        CONCURRENT_AVG_PRICE: 'Average price of your concurrents',
        CONCURRENT_EVOLUTION: 'Your concurrents',
        CONCURRENT_EVOLUTION_LEGEND: 'Concurrents average price evolution',
        CONCURRENT_TREND_LEGEND: 'Concurrents price trend',
        CONSTANT: 'Constant',
        EXP: 'Exponential',
        FILTERS_TITLE: 'Analysis filters',
        LEGENDS: 'Legends',
        LINEAR: 'Linear',
        LOADING: 'Loading data, this operation may take some time',
        LOGARITHMIC: 'Logarithmic',
        NAME: 'Trends',
        NOT_ENOUGH_DATA: 'Insufficient data: Trends does not have enough data to establish a reliable result. Please expand the date range and/or change the analysis filters.',
        POWER: 'Powers',
        RESELLERS_AVG_PRICE: 'Average price of your resellers',
        RESELLERS_EVOLUTION: 'Your resellers',
        RETAILER_TITLE: 'Evolution of your tarification compared to your concurrents from ',
        SELLERS_EVOLUTION_LEGEND: 'Resellers average price evolution',
        SELLERS_TREND_LEGEND: 'Resellers price trend',
        TENDENCE_TYPE: 'Trend type :',
        UNKNOWN: 'Oups, an error occurred during data processing. Please retry. If the problem persists, do not hesitate to contact the support team.',
        YOUR_AVG_PRICE: 'Your average price',
        YOUR_EVOLUTION: 'You',
        YOUR_EVOLUTION_LEGEND: 'Your average price evolution',
        YOUR_TREND_LEGEND: 'Your price trend'
    },
    TUESDAY: 'tuesday',
    TYPE_EMAIL_PASSWORD_LOST: 'To be able to recover your access to the application, please enter the email address associated to your PriceComparator account. This is the email address you use to log in to the app.',
    UNAVAILABLE_NAME: 'The chosen name already exists. Please specify another name.',
    UNCHANGEABLE_RESOURCE: 'The specified category refers to a special one (i.e. category named \'n/a\') which can not be deleted.',
    UNDEFINED: 'Udefined',
    UNSELECTED: 'Unselected',
    UNSPECIFIED: 'Unspecified',
    UPDATE: 'Update',
    UPDATE_ATTRIBUTE: 'Add, update or organize attributes',
    UPDATE_DATA: 'Update data',
    USER_DISCONNECTED: 'You had been disconnected',
    USERS: 'Users',
    VALUE: 'Value',
    VISUALIZE: 'Visualize',
    WARNING_CMS_ID: 'Warning: you are about to modify the CMS ID',
    WARNING_CMS_ID_CONTENT_FIRST: 'The CMS ID must match the same item in your CMS and in PriceComparator. A wrong entry could lead to irremediable changes to your CMS.',
    WARNING_CMS_ID_CONTENT_SECOND: 'If in doubt, contact your PriceComparator advisor.',
    WEBSITE: 'Website',
    WEBSITES: 'Websites',
    WEDNESDAY: 'wednesday',
    WELCOME_ON_PRICING_ENGINE: 'Welcome on Pricing Engine !',
    WITH_HIGH_LIMIT: 'unless the price is higher than',
    WITH_LOW_LIMIT: 'unless the price is less than',
    WITH_PRICE_HIGHER_OR_EQUAL_AT: 'with a price higher or equal at',
    WITH_PRICE_LOWER_OR_EQUAL_AT: 'with a price lower or equal at',
    WITHOUT_ASSOCIATED_PRICING_ENGINE_RULE: 'Products w/o associated rule',
    WITHOUT_HIGH_LIMIT: 'without high limit price',
    WITHOUT_LOW_LIMIT: 'without low limit price',
    YOU_DO_NOT_HAVE_ACCESS: 'You do not have access to this module yet',
    YOUR_LAST_VALUE: 'Your last value'
};

export default locales;

