// Constants
import Constants from 'constants.js';
// Dispatcher
import Dispatcher from 'core/Dispatcher';

class LayoutAction {

    /**
      * Method called to clean location redirect
      */
    cleanLocationRedirect() {
        Dispatcher.dispatch({
            actionType: Constants.CLEAN_LOCATION_REDIRECT
        });
    }

    /**
     * Method called to change the theme color
     */
    changeColors() {
        Dispatcher.dispatch({
            actionType: Constants.CHANGE_COLOR_THEME
        });
    }

    /**
     * Method called to set error
     */
    setError(error) {
        Dispatcher.dispatch({
            actionType: Constants.SET_ERROR,
            value: error
        });
    }
}

export default new LayoutAction();
