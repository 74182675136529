// React
import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import { DialogContent, DialogTitle } from '@mui/material';
// Locales
import translate from 'locales/i18n';
// Styles
import { useStyles } from 'components/dialog/DialogPrint/DialogPrint.style.js';
// Theme
import { useTheme } from '@mui/styles';

const DialogPrint = ({ open, close, settings, callback }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Dialog
            fullScreen={useMediaQuery(theme.breakpoints.down('md'))}
            open={open}
            onClose={close}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
            classes={{
                paper: classes.paper
            }}
        >
            <DialogTitle>{translate.i18n('PRINT_DIALOG_TITLE')}</DialogTitle>
            <DialogContent>
                {settings.map((setting, key) => <div key={key} className={classes.settingText}><strong>{setting.name}</strong> : {setting.value}</div>)}
            </DialogContent>
            <DialogActions>
                <IconButton color='primary' onClick={callback} size='large'>
                    <PrintIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

DialogPrint.propTypes = {
    callback: PropTypes.func,
    close: PropTypes.func,
    open: PropTypes.bool,
    settings: PropTypes.array
};

export default DialogPrint;
