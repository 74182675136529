// React
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// Material UI Components
import { TableCell } from '@mui/material';
// Stores
import AccountStore from 'stores/AccountStore';
// Locales
import translate from 'locales/i18n';
// Style
import { useStyles } from 'components/cell/ItemCellSpecificInfos/ItemCellSpecificInfos.style';
//Theme
import { useTheme } from '@mui/styles';

const ItemCellSpecificInfos = ({ item, index, blur }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <TableCell className={classes.tableCell} style={{ backgroundColor: index % 2 === 0 ? theme.palette.grey.semilight : theme.palette.white.main }}>
            <div className={classes.tableCellContent}>
                <Link to={'/items/' + item.id} className={classes.link}>
                    {AccountStore.getCurrentAccount().isRetailer() &&
                        <div className={classes.specificInformationContainer}>
                            {item.margin &&
                                <div className={classes.specificLabel}>
                                    {`${translate.i18n('MARGIN')}: `} &nbsp; {Math.round((item.margin - 1) * 10000) / 100 < 0 ? <div className={ blur? classes.blur:null }> 0 </div> : <div className={ blur? classes.blur:null }>{ Math.round((item.margin - 1) * 10000) / 100 } </div>} %
                                </div>
                            }
                            {item.purchasingPrice &&
                                <div className={classes.specificLabel} title={`${translate.i18n('PURCHASING_PRICE')}: ${parseFloat(item.purchasingPrice).toFixed(2).replace('.', ',')} ${AccountStore.getCurrentAccount().getCurrency()}`}>
                                    {`${translate.i18n('PURCHASING_PRICE')}: `} &nbsp; <div className={ blur? classes.blur:null }> { parseFloat(item.purchasingPrice).toFixed(2).replace('.', ',') } </div> {`${AccountStore.getCurrentAccount().getCurrency()}`}
                                </div>
                            }
                            {item.rrp &&
                                <div className={classes.specificLabel} title={`RRP: ${parseFloat(item.rrp).toFixed(2).replace('.', ',')} ${AccountStore.getCurrentAccount().getCurrency()}`}>
                                    {`RRP: ${parseFloat(item.rrp).toFixed(2).replace('.', ',')} ${AccountStore.getCurrentAccount().getCurrency()}`}
                                </div>
                            }
                        </div>
                    }
                    {AccountStore.getCurrentAccount().isSupplier() &&
                        <div className={classes.specificInformationContainer}>
                            {item.rrp &&
                                <div className={classes.specificLabel} title={`RRP: ${parseFloat(item.rrp).toFixed(2).replace('.', ',')} ${AccountStore.getCurrentAccount().getCurrency()}`}>
                                    {`RRP: ${parseFloat(item.rrp).toFixed(2).replace('.', ',')} ${AccountStore.getCurrentAccount().getCurrency()}`}
                                </div>
                            }
                            {item.rrpAlertPercentage &&
                                <div className={classes.specificLabel} title={`${translate.i18n('DISCOUNT')}: ${parseFloat(item.rrpAlertPercentage)} %`}>
                                    {`${translate.i18n('DISCOUNT')}:`} &nbsp; <div className={ blur? classes.blur:null }> {parseFloat(item.rrpAlertPercentage)} </div>  %
                                </div>
                            }
                            {item.alertingLowPrice &&
                                <div className={classes.specificLabel} title={`${translate.i18n('LIMIT')}: ${parseInt(item.alertingLowPrice).toFixed(2).replace('.', ',')} ${AccountStore.getCurrentAccount().getCurrency()}`}>
                                    {`${translate.i18n('LIMIT')}:`} &nbsp; <div className={ blur? classes.blur:null }> {parseInt(item.alertingLowPrice).toFixed(2).replace('.', ',')} </div> {`${AccountStore.getCurrentAccount().getCurrency()}`}
                                </div>
                            }
                        </div>
                    }
                </Link>
            </div>
        </TableCell>
    );
};

ItemCellSpecificInfos.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    blur: PropTypes.bool
};

export default ItemCellSpecificInfos;
