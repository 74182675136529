export const styles = () => ({
    cancelButton: {
        marginLeft: 'auto'
    },
    contentTitle: {
        color: '#0E5562',
        fontSize: '20px',
        fontWeight: '500',
        paddingRight: '10px'
    },
    contentTitleContainer: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center'
    },
    deleteButton: {
        marginLeft: 'auto'
    },
    editButtons: {
        display: 'flex',
        height: 'auto'
    },
    editContainer: {
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.37)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '10px'
    },
    editElements: {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        marginBottom: '10px'
    },
    editProductContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    editProductLeft: {
        display: 'flex',
        gap: '15px',
        flexGrow: 1
    },
    editProductRight: {
        maxWidth: '300px'
    },
    editProductName: {
        fontSize: '17px',
        fontWeight: '600',
        overflowX: 'hidden',
        width: '15%'
    },
    editTitle: {
        height: 'auto'
    },
    editTitleUpSection: {
        display: 'flex'
    },
    saveButton: {
        marginLeft: '10px'
    },
    searchUrlIcon: {
        cursor: 'pointer'
    },
    siteUrl: {
        height: '40px',
        width: '100%'
    }
});
