// React
import React from 'react';
import PropTypes from 'prop-types';
// PCP Components
import Spinner from 'assets/img/spin.gif';

class Loader extends React.PureComponent {

    static propTypes = {
        className: PropTypes.string,
        onClick: PropTypes.func,
        size: PropTypes.number
    };

    render() {
        return (
            this.props.size ?
                <img className={this.props.className} src={Spinner} alt='loading' height={this.props.size} onClick={this.props.onClick}/>
                :
                <img className={this.props.className} src={Spinner} alt='loading' onClick={this.props.onClick}/>
        );
    }
}

export default Loader;
