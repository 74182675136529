import AbstractService from 'core/abstracts/AbstractService';
import Facade from 'core/Facade';

class RuleService extends AbstractService {

    /**
     * Get rules.
     */
    getRules(accountId) {
        if (!accountId) {
            return Promise.reject();
        }

        return this.request({
            method: 'GET',
            url: Facade.getApiRoot() + `accounts/${accountId}/pricing-engine-rules`,
        }).then(responseData => {
            if (responseData.ok) {
                return responseData.json();
            }
            throw responseData.ok;
        });
    }

    /**
     * Get rule.
     */
    getRule(accountId, ruleId) {
        if (!ruleId) {
            return Promise.reject();
        }

        return this.request({
            method: 'GET',
            url: Facade.getApiRoot() + `accounts/${accountId}/pricing-engine-rules/${ruleId}`,
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Delete rule.
     */
    deleteRule(accountId, ruleId) {
        // if no were provided return reject
        if (!ruleId || !accountId) {
            return Promise.reject();
        }

        return this.request({
            method: 'DELETE',
            url: Facade.getApiRoot() + `accounts/${accountId}/pricing-engine-rules/${ruleId}`,
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Add rule.
     */
    addRule(accountId, rule) {
        if (!rule || !accountId) {
            return Promise.reject();
        }

        return this.request({
            method: 'POST',
            url: Facade.getApiRoot() + `accounts/${accountId}/pricing-engine-rules`,
            json: rule,
            responseType: 'text'
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Update rule.
     */
    updateRule(accountId, rule, ruleId) {
        if (!rule || !accountId) {
            return Promise.reject();
        }

        return this.request({
            method: 'PUT',
            url: Facade.getApiRoot() + `accounts/${accountId}/pricing-engine-rules/${ruleId}`,
            json: rule
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }
}

export default new RuleService();
