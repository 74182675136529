// React
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
// Stores
import AccountStore from 'stores/AccountStore';
import UserStore from 'stores/UserStore';
import AlertStore from 'stores/AlertStore';
// Locales
import translate from 'locales/i18n';
// Routes
import routesConfig from 'routes/routes';
// PCP components
import Sidebar from 'components/sidebar/Sidebar';
import Appbar from 'components/appbar/Appbar';
import Alert from 'components/commons/Alert/Alert';
// Material ui components
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import CssBaseline from '@mui/material/CssBaseline';
// CSS
import 'simplebar/dist/simplebar.min.css';
import 'assets/css/style.css';
// Theme
import theme from 'assets/theme/theme.js';
// Page
import NotAccessPage from 'components/pages/NotAccessPage/NotAccessPage';
import TeaserPage from 'components/pages/TeaserPage/TeaserPage';

// Styles
import styles from 'components/pages/MainLayout/MainLayout.style';
import LayoutStore from 'stores/LayoutStore';

class Main extends React.Component {

    state = {
        alertOpen: AlertStore.isOpen(),
        currentLanguage: translate.getCurrentLanguage(),
        displayEnv: UserStore.isLogged(),
        sidebarOpen: false,
        isBlue: LayoutStore.getColor(),
        error: LayoutStore.getError()
    };

    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    componentDidMount() {
        UserStore.addChangeListener(this.onUserStoreChange);
        AlertStore.addChangeListener(this.onAlertStoreChange);
        LayoutStore.addChangeListener(this.onLayoutStoreChange);
        this.mounted = true;
    }

    componentWillUnmount() {
        UserStore.removeChangeListener(this.onUserStoreChange);
        AlertStore.removeChangeListener(this.onAlertStoreChange);
        LayoutStore.removeChangeListener(this.onLayoutStoreChange);
        this.mounted = false;
    }

    onUserStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            currentLanguage: translate.getCurrentLanguage(),
            displayEnv: UserStore.isLogged()
        });
    };

    onAlertStoreChange = () => {
        this.setState({
            alertOpen: AlertStore.isOpen()
        });
    };

    onLayoutStoreChange = () => {
        this.setState({
            isBlue: LayoutStore.getColor(),
            error: LayoutStore.getError()
        });
    };

    toggleSidebar = () => {
        this.setState({ sidebarOpen: !this.state.sidebarOpen });
    };

    getCurrentRouteProp = routes => {
        const [, path] = window.location.pathname.split('/');
        for (let i = 0; i < Object.keys(routes).length; i += 1) {
            if (routes[i].path.split('/')[1] === path || routes[i].path === '*')
                return routes[i];
        }
        return null;
    };

    handledisplayLayout = routes => {
        const currentRoute = this.getCurrentRouteProp(routes);
        if (currentRoute && currentRoute.displayLayout === true)
            return true;
        return false;
    };

    getComponentFromUrl = (url) => {
        const [cleanUrl] = url.split('/')[3].split('?');
        switch (cleanUrl) {
        case 'smart-price-extractor':
            return 'SPE';
        case 'performance':
            return 'PERF';
        case 'pricing-engine-analyze':
            return 'PE';
        case 'trends':
            return 'TRENDS';
        case 'slider':
            return 'SLIDER';
        case 'adbox':
            return 'ADBOX';
        default:
            return false;
        }
    };

    renderAlert = () => {
        const { text, type, position } = AlertStore.getAlertConfig();
        const { alertOpen } = this.state;
        return (
            <Alert
                text={text}
                type={type}
                position={position}
                open={alertOpen} />
        );
    };

    renderAppBar = routes => {
        const { displayEnv, sidebarOpen } = this.state;

        if (!displayEnv || this.handledisplayLayout(routes) === false || !UserStore.isLogged()) return null;

        return (
            <Appbar
                routes={routes}
                toggleSidebar={this.toggleSidebar}
                sidebarOpen={sidebarOpen} />
        );
    };

    renderSidebar = routes => {
        const { displayEnv, sidebarOpen } = this.state;

        if (!displayEnv || this.handledisplayLayout(routes) === false || !UserStore.isLogged()) return null;

        return (
            <Sidebar
                routes={routes}
                handleDrawerClose={this.toggleSidebar}
                sidebarOpen={sidebarOpen} />
        );
    };

    render() {
        const { classes } = this.props;
        const { error } = this.state;
        const modules = AccountStore.getCurrentAccount().getModules();

        const routes = routesConfig.map(route => {
            if (!route.display)
                route.display = AccountStore.getCurrentAccount().getRight(route.rights);

            return route;
        });

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme(this.state.isBlue)}>
                    <CssBaseline />
                    <div className={classes.root}>
                        {this.renderAppBar(routes)}
                        {this.renderSidebar(routes)}
                        <main className={classes.content}>
                            <Switch>
                                {routes.map((prop, key) => {
                                    if (prop.error && !error) {
                                        return <Redirect isExact from={prop.path} to={'/login'} key={key} />;
                                    }

                                    if (error && !prop.error) {
                                        return <Redirect isExact from={prop.path} to={'/error'} key={key} />;
                                    }

                                    // Module routes
                                    if (modules && prop.rights) {
                                        if (modules[prop.rights] === false) {
                                            if (prop.private) {
                                                return <Route
                                                    isExact
                                                    path={prop.path}
                                                    key={key}
                                                    render={() =>
                                                        UserStore.isLogged() ?
                                                            this.getComponentFromUrl(window.location.href) ?
                                                                <TeaserPage componentName={this.getComponentFromUrl(window.location.href)}/>
                                                                :
                                                                <NotAccessPage />
                                                            :
                                                            <Redirect to='/login' />
                                                    }
                                                />;
                                            }
                                            return <Route path={prop.path} render={() => <NotAccessPage />} key={key} />;
                                        }
                                    }

                                    // Redirect
                                    if (prop.redirect) {
                                        if (prop.path === window.location.pathname)
                                            return <Redirect isExact from={prop.path} to={prop.to} key={key} />;
                                        return null;
                                    }

                                    const Component = prop.component;

                                    if (prop.private) {
                                        return <Route
                                            isExact
                                            path={prop.path}
                                            key={key}
                                            render={({ match }) =>
                                                UserStore.isLogged() ?
                                                    <Component match={match} />
                                                    :
                                                    <Redirect to='/login' />
                                            }
                                        />;
                                    }

                                    return <Route isExact path={prop.path} render={({ match }) => <Component match={match} />} key={key} />;
                                })}
                            </Switch>
                        </main>
                    </div>
                    {this.renderAlert()}
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

export default withStyles(styles)(Main);
