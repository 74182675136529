import makeStyles from '@mui/styles/makeStyles';
import { getRatingColor } from 'components/sheet/NotationSheet/NotationSheet.util';

export const useStyles = makeStyles(theme => ({
    bellIcon: {
        color: theme.palette.grey.dark,
        fontSize: '16px',
        marginLeft: '2px'
    },
    notSoldLabel: {
        color: theme.palette.grey.dark,
        fontWeight: '500',
        fontSize: 13
    },
    count: {
        fontWeight: 'bold',
        color: '#C7C7C7',
        fontSize: 'small',
        marginTop: '5px',
        marginLeft: '3px'
    },
    date: {
        display: 'flex',
        fontFamily: 'roboto',
        fontSize: 13,
        justifyContent: 'center'
    },
    divider: {
        border: '1px solid' + theme.palette.grey.light,
        flexGrow: 1,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    dividerContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1)
    },
    gradeFrom: {
        color: (from) => getRatingColor(from),
        fontWeight: 'bolder',
        fontSize: 'large',
        marginRight: '5px'
    },
    gradeTo: {
        color: (to) => getRatingColor(to),
        fontWeight: 'bolder',
        fontSize: 'large',
        marginRight: '5px'
    },
    infoContainer: {
        alignItems: 'center',
        display: 'flex',
        maxWidth: '180px',
    },
    notSoldInfoContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        minWidth: '100px'
    },
    notationContainer: {
        marginTop: '10px',
        marginRight: '5px',
    },
    notationText: {
        display: 'flex',
        marginLeft: '3px'
    },
    notFound: {
        color: 'grey',
        marginRight: '5px',
        marginTop: '5px'
    },
    oldPrice: {
        color: theme.palette.red.main,
        marginLeft: '5px',
        textDecoration: 'line-through'
    },
    p: {
        fontSize: 13
    },
    paper: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        padding: theme.spacing(1),
        width: '100%',
    },
    popper: {
        minWidth: '200px',
        zIndex: 1000
    },
    smallIcon: {
        fontSize: '18px',
        marginRight: theme.spacing(1)
    },
    smallIconArrow: {
        fontSize: '18px',
        marginTop: '4px'
    },
    halfContainer: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '100px'
    }
}));
