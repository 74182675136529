// Encryptage
import { sha256 } from 'js-sha256';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Services
import UserService from 'services/UserService';
import AccountService from 'services/AccountService';
// Actions
import AlertAction from './AlertAction';
// Translate
import translate from 'locales/i18n';

class UserAction {

    /**
      * Method called to get self of user
      *
      * @returns <Promise> the response of the request done
      */
    self() {
        return UserService.self().then(user => {
            if (user.associated_accounts.length === 0) {
                throw {
                    error: 'NO_ASSOCIATED_ACCOUNTS'
                };
            }

            let accountId = user.associated_accounts[0].id;
            if (localStorage.getItem('pricecomparator_account')) {
                accountId = user.associated_accounts.find(a => a.id === localStorage.getItem('pricecomparator_account')) ? localStorage.getItem('pricecomparator_account') : accountId;
                localStorage.removeItem('pricecomparator_account');
                localStorage.setItem('pricecomparator_account', accountId);
            }

            return AccountService.getAccount(accountId).then(account => {
                Dispatcher.dispatch({
                    actionType: Constants.USER_LOGIN,
                    value: {
                        account,
                        user
                    }
                });
            }, response => {
                if (response && response.error) AlertAction.open('error', translate.i18n(response.error), 'top-right');
                throw response;
            });
        }, response => {
            throw response;
        });
    }

    /**
      * Method called to send forgot password email
      *
      * @param email <string> the email
      * @returns <Promise> the response of the request done
      */
    sendForgotPasswordEmail(email) {
        return UserService.sendForgotPasswordEmail(email).then(data => data, err => {
            throw err;
        });
    }

    /**
      * Method called to check the reset password code
      *
      * @param email <string> the email
      * @param code <string> the reset password code
      * @returns <Promise> the response of the request done
      */
    checkResetPasswordCode(email, code) {
        return UserService.checkResetPasswordCode(email, code).then(response => response, err => {
            throw err;
        });
    }

    /**
      * Method called to reset the password
      *
      * @param password <string> the new password
      * @param token <string> the token to reset password
      * @returns <Promise> the response of the request done
      */
    resetPassword(password, token) {
        return UserService.resetPassword(sha256(password), token).then(data => data, err => {
            throw err;
        });
    }

    /**
      * Method called to save new user profile data
      *
      * @param data <object> the email
      */
    saveProfile(data) {
        Dispatcher.dispatch({
            actionType: Constants.USER_SAVE_PROFILE,
            value: data
        });
    }

    /**
      * Method called to update a user
      *
      * @param options <object> the options of update
      * @param id <string> the id of the user to update
      * @returns <Promise> the response of the request done
      */
    updateUser(options, id) {
        return UserService.updateUser(options, id).then(data => {
            Dispatcher.dispatch({
                actionType: Constants.USER_UPDATED,
                value: {
                    id,
                    options
                }
            });

            return data;
        }, err => {
            throw err;
        });
    }

    /**
      * The method to change language
      *
      * @param lang <string> the new language (fr || gb)
      */
    changeLanguage(lang) {
        Dispatcher.dispatch({
            actionType: Constants.USER_CHANGE_LANGUAGE,
            value: lang
        });
    }
}

export default new UserAction();
