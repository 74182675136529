// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Service
import RuleService from 'services/RuleService';
// Locales
import translate from 'locales/i18n';
// Action
import AlertAction from 'actions/AlertAction';
// Store
import AccountStore from 'stores/AccountStore';
// Entities
import Rule from 'core/entities/RuleObject';

class RuleAction {

    /**
      * Method called to get rules
      *
      * @param accountId <string> the current account id
      */
    getRules(accountId) {
        return RuleService.getRules(accountId).then(rules => {
            Dispatcher.dispatch({
                actionType: Constants.PRICING_ENGINE_GET_RULES,
                value: rules.map(rule => new Rule(rule))
            });

            return rules.map(rule => new Rule(rule));
        }, () => AlertAction.open('error', translate.i18n('ERROR_RETRIEVE_RULES'), 'top-right'));
    }

    /**
      * Method called to get rule
      *
      * @param ruleId <string> the rule id to get
      * @returns <Promise> the promise of the request done
      */
    getRule(ruleId) {
        return RuleService.getRule(AccountStore.getCurrentAccount().getId(), ruleId).then(data => new Rule(data), response => {
            AlertAction.open('error', translate.i18n('PRICING_ENGINE_RULE_NOT_FOUND'), 'top-right');
            throw response;
        });
    }

    /**
      * Method called to delet a rule
      *
      * @param accountId <string> the current account id
      * @param ruleId <string> the rule id to delete
      */
    deleteRule(accountId, ruleId) {
        RuleService.deleteRule(accountId, ruleId).then(() => {
            Dispatcher.dispatch({
                actionType: Constants.PRICING_ENGINE_DELETE_RULE,
                value: ruleId
            });
            AlertAction.open('success', translate.i18n('RULE_DELETED'), 'top-right');
        }, response => AlertAction.open('error', translate.i18n(response && response.error ? response.error :'ERROR_DELETE_RULE'), 'top-right'));
    }

    /**
      * Method called to update a rule
      *
      * @param accountId <string> the current account id
      * @param rule <object> the rule with new values
      * @param ruleId <string> the rule id to update
      * @returns <Promise> the promise of the request done
      */
    updateRule(accountId, rule, ruleId) {
        return RuleService.updateRule(accountId, rule, ruleId).then(() => {
            AlertAction.open('success', translate.i18n('RULE_UPDATED'), 'top-right');
        }, response => {
            AlertAction.open('error', translate.i18n('ERROR_UPDATE_RULE'), 'top-right');
            throw response;
        });
    }

    /**
      * Method called to get rules
      *
      * @param accountId <string> the current account id
      * @param rule <object> the rule to create
      * @returns <Promise> the promise of the request done
      */
    createRule(accountId, rule) {
        return RuleService.addRule(accountId, rule).then(data => {
            AlertAction.open('success', translate.i18n('RULE_CREATED'), 'top-right');
            return data;
        }, response => {
            AlertAction.open('error', translate.i18n('ERROR_CREATE_RULE'), 'top-right');
            throw response;
        });
    }
}

export default new RuleAction();
