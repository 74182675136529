export const colorsBarem = totalMails => {
    const barem = [{ color: '#cccccc', value: 0 },
        { color: '#93BCC3', value: 1 },
        { color: '#3D8794', value: 0 },
        { color: '#0F5A67', value: 0 },
        { color: '#051E23', value: 0 },
        { color: '#020A0C', value: 0 }];
    if (!totalMails)
        return barem;

    barem[2].value=Math.round(totalMails/7);
    barem[3].value=Math.round(totalMails/6);
    barem[4].value=Math.round(totalMails/5);
    barem[5].value=Math.round(totalMails/2);

    return barem;
};
