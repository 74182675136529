// React
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Facade
import Facade from 'core/Facade';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
//PCP components
import MarketplaceOffersTable from 'components/table/MarketplaceOffersTable/MarketplaceOffersTable';
// Images
import imgNotFound from 'assets/img/img-not-found.png';
import NoImage from 'assets/img/no-image.png';
// Styles
import { useStyles } from 'components/dialog/DialogMarketplace/DialogMarketplace.style';

const DialogMarketplace = ({ close, item, open, website }) => {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    if (!item || !website) return null;

    const img = 'https://img.pricecomparator.pro/' + item.imgPath;

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={close}
            maxWidth={'md'}
            fullWidth={true}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
        >
            <IconButton
                color='secondary'
                onClick={() => {
                    close();
                }}
                className={classes.closeButton}
                size='large'>
                <ClearIcon />
            </IconButton>
            <DialogTitle id='responsive-dialog-title' onClick={() => Facade.openUrl(item.scrapingData.find(sd => sd.siteId === website.id).url)} className={classes.dialogTitle}>
                <div className={classes.titleDialogBox}>
                    <div className={classes.itemPicture}>
                        <img src={img}
                            height={50} width={50}
                            className={classes.img}
                            alt={item.name}
                            onError={(error) => {
                                error.target.onerror = null;
                                error.target.src = imgNotFound;
                            }}
                        ></img>
                    </div>
                    <div className={classes.titleItemDialog}>
                        <p>
                            {item.name}
                        </p>
                    </div>
                    <div className={classes.titleWebsiteDialog}>
                        <p>
                            {Facade.siteDisplayName(website.name)}
                        </p>
                    </div>
                    <div className={classes.websitePicture}>
                        <img src={Facade.getWebsiteIco(website.url)} onError={(e) => {
                            e.target.onerror = null; e.target.src = NoImage;
                        }} alt={Facade.siteDisplayName(website.name)} height={20} width={20} className={classes.img} />
                    </div>
                </div>
            </DialogTitle>
            <DialogContent className={classNames(classes.dialogContentNotFullScreen, classes.dialogContent)} onClick={() => Facade.openUrl(item.scrapingData.find(sd => sd.siteId === website.id).url)} style={{ cursor: 'pointer' }}>
                <MarketplaceOffersTable
                    scrapingData={item.scrapingData.find(sd => sd.siteId === website.id)}
                    marketplaceOffers={item.scrapingData.find(sd => sd.siteId === website.id).to ? item.scrapingData.find(sd => sd.siteId === website.id).to.marketplaceOffers : item.scrapingData.find(sd => sd.siteId === website.id).marketplaceOffers} />
            </DialogContent>
        </Dialog>
    );
};

DialogMarketplace.propTypes = {
    close: PropTypes.func,
    item: PropTypes.object,
    open: PropTypes.bool,
    website: PropTypes.object
};

export default DialogMarketplace;
