import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    graphContainer: {
        height: '93%',
        marginRight: 10,
        width: '100%'
    },
    infoButton: {
        padding: '5px',
        position: 'absolute',
        right: '6px',
        top: '6px',
        zIndex: 400
    },
    infoImgContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    infoMainText: {
        fontWeight: 500
    },
    infoSubText: {
        fontStyle: 'italic'
    },
    logoContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%'
    },
    notAllowed: {
        cursor: 'wait',
        opacity: 0.5
    },
    paper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    resetButton: {
        padding: '5px',
        position: 'absolute',
        right: '30px',
        top: '6px',
        zIndex: 400
    }
}));
