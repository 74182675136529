// Material UI Components
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(2)
    },
    formControlLabel: {
        width: '40%'
    },
    infoButton: {
        padding: '5px',
        position: 'absolute',
        right: '2px',
        top: '2px',
        zIndex: 400
    },
    resetButton: {
        padding: '5px',
        position: 'absolute',
        right: '30px',
        top: '2px',
        zIndex: 400
    },
    infoImgContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    infoLegendText: {
        fontSize: '12px',
        marginTop: '6px',
        textAlign: 'center'
    },
    infoMainText: {
        fontWeight: 500
    },
    infoSubText: {
        fontStyle: 'italic'
    },
    notAllowed: {
        cursor: 'wait',
        opacity: 0.5
    },
    notAllowedMap: {
        cursor: 'wait'
    },
    paper: {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'relative',
        width: '100%'
    },
    showMapButton: {
        left: '2px',
        padding: '5px',
        position: 'absolute',
        top: '2px',
        zIndex: 400
    },
    simpleBar: {
        height: '100%',
        width: '100%'
    }
}));
