import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    graphContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '60%',
        justifyContent: 'center',
        width: '100%'
    },
    loaderContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    }
}));
