import { EventEmitter } from 'events';
// Logger
import Logger from 'core/Logger';

class AbstractStore {

    constructor(name, changeEvent) {
        this.NAME = name;
        this.CHANGE_EVENT = changeEvent;
        this._emitter = new EventEmitter();
    }

    /**
     * Abstract Method for emitChange
     * Emit an event to this subscribers views
     *
     * Subclass for Store must have
     * a static property CHANGE_EVENT
     * and a static property NAME
     *
     * @return {Boolean}
     */
    _emitChange() {
        const evt = this.CHANGE_EVENT;
        let msg = null;
        let listeners = null;

        if (typeof variable !== 'undefined') {
            msg = 'change event for store "' + this.NAME + '" is not valid';
            Logger.error(msg);
            return false;
        }
        listeners = this._emitter.listeners(evt);
        if (listeners.length) {
            return this._emitter.emit(evt);
        }
        return false;
    }

    /**
     * @param {Function} callback [description]
     */
    addChangeListener(callback) {
        let max = null;
        const name = this.NAME;
        const evt = this.CHANGE_EVENT;
        if (evt === null) {
            Logger.error('change event for store "' + name + '" is not valid');
            return false;
        }
        // if store has too much listeners
        max = this._emitter.listeners(evt).length > EventEmitter.defaultMaxListeners;
        if (max) {
            Logger.error('max limit - too much listeners for "' + name + '"');
            return false;
        }
        if (callback && {}.toString.call(callback) === '[object Function]') {
            return this._emitter.on(evt, callback);
        }

        return false;
    }

    /**
     * @param  {Function} callback [description]
     * @return {Boolean}            [description]
     */
    removeChangeListener(callback) {
        const name = this.NAME,
            evt = this.CHANGE_EVENT;
        if (evt === null) {
            Logger.error('change event for store "' + name + '" is not valid');
            return false;
        }
        if (callback && {}.toString.call(callback) === '[object Function]') {
            return this._emitter.removeListener(evt, callback);
        }

        return false;
    }
    /**
     *
     * Return current emitter instance
     *
     * @return {EventEmitter}
     */
    getEmitter() {
        return this._emitter;
    }
}

export default AbstractStore;
