import AbstractService from 'core/abstracts/AbstractService';
import Facade from 'core/Facade';

class MarketplaceService extends AbstractService {
    /**
     * Get all marketplace report.
     */
    getMarketplaceReports(accountId) {
        return this.request({
            method: 'GET',
            url:  Facade.getApiRoot() + `accounts/${accountId}/mp-recaps`
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Get a marketplace report.
     */
    getMarketplaceReport(accountId, id) {
        return this.request({
            method: 'GET',
            url:  Facade.getApiRoot() + `accounts/${accountId}/mp-recaps/${id}`
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }
}

export default new MarketplaceService();
