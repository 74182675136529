// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Service
import TrendService from 'services/TrendService';
// Action
import AlertAction from './AlertAction';
//Locales
import translate from 'locales/i18n';
//Object
import Trend from 'core/entities/TrendObject';

class TrendAction {

    /**
      * @param accountId the account id
      * @param fromStr
      * @param toStr
      * @param body the body of the request
      */
    getTrends(accountId, fromStr, toStr, body) {
        Dispatcher.dispatch({
            actionType: Constants.TRENDS_GET_START
        });

        TrendService.getTrends({ accountId, fromStr, toStr, body }).then(trendAnalyze => {
            Dispatcher.dispatch({
                actionType: Constants.TRENDS_GET_END,
                value: new Trend(trendAnalyze)
            });
        }, response => {
            if (response && response.error) AlertAction.open('error', translate.i18n(`TRENDS.${response.error}`), 'top-right');
            Dispatcher.dispatch({
                actionType: Constants.TRENDS_GET_ERROR,
            });
        });
    }

}

export default new TrendAction();
