/**
  * Method to add find the copy nomber of the new rule copied
  *
  * @param ruleName <string>
  * @param rulesList <object[]>
  * @returns <string> _COPY_ + the rule number
  */
export const findCopyName = (ruleName, rulesList) => {
    let copyName = '_COPY_';
    const copies = [];
    if (!ruleName || !rulesList) {
        return '_COPY_001';
    }
    for (const rule of rulesList) {
        if (rule.name.startsWith(ruleName+'_COPY_')) {
            if (rule.name.split('_')[rule.name.split('_').length -1].match('[0-9]*')[0] !== ''){
                copies.push(rule.name);
            }
        }
    }
    copies.sort();
    if (copies.length === 0){
        copyName += '001';
    } else {
        const lastCopy = copies[copies.length-1].split('_');
        const copyNumber = parseInt(lastCopy[lastCopy.length - 1]) +1;
        copyNumber > 99? copyName+=copyNumber:copyNumber > 9? copyName+= '0'+copyNumber:copyName+= '00'+copyNumber;
    }
    return copyName;
};
