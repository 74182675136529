import locales from 'locales/Locales';
// Logger
import Logger from 'core/Logger';

let _language = 'gb';
let _translations = locales.gb;

if (typeof navigator !== 'undefined') {
    if (navigator.language.indexOf('fr') > -1) {
        _language = 'fr';
        _translations = locales.fr;
    }
}

const translate = (_locales, key) => {
    if (_locales){
        const keys = key.split('.');
        if (keys.length > 1) {
            const removedKey = keys.splice(0,1);
            return translate(_locales[removedKey], keys.join('.'));
        }
        return _locales[key];
    }
    return '';
};

const i18n = {

    /**
     * Change the current language. Load the locales of the new language
     *
     * @param { string } language New language to load
     * @returns <boolean>
     */
    setLanguage(language) {
        if (!language || !(typeof language === 'string') || !locales[language]) {
            return false;
        }

        _language = language;

        _translations = locales[language];
        return true;
    },

    getCurrentLanguage() {
        return _language;
    },

    getCurrentTranslations() {
        return _translations;
    },

    i18n(key, translateObj) {
        if (arguments.length < 1) {
            return '';
        }

        try {
            let sentence = translate(_translations, key);
            if (translateObj) {
                for (const k in translateObj) {
                    sentence = sentence.split('{' + k + '}').join(translateObj[k]);
                }
            }
            return sentence;
        } catch (e) {
            Logger.error('Cannot get translation for "' + key + '" in ' + _language);
        }
    }
};

export default i18n;
