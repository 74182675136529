import { createTheme } from '@mui/material/styles';
import 'typeface-roboto';
import { palette } from 'assets/theme/palette';

const theme = (isBlue) => createTheme({
    palette: palette(isBlue),
    typography: {
        fontFamily: 'Roboto',
        htmlFontSize: 18,
    }
});

export default theme;
