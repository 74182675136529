import Performance from 'core/entities/PerformanceObject';
import ScrapedData from 'core/entities/ScrapedDataObject';

class Site {

    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.url = data.url;
        this.alternativeUrls = data.alternative_urls ? data.alternative_urls : [];
        this.isMarketplace = data.is_marketplace;
        this.lat = data.lat;
        this.long = data.long;
        this.color = data.color;

        // PERFORMANCE MODULE
        this.performances = data && data.performances ? data.performances.map(performance => new Performance(performance)) : data.performances;

        // SPE MODULE
        this.pricesCardinalities = data.prices_cardinalities;
        this.pricesLeadership = data.prices_leadership;
        this.pricesLeaderships = data.prices_leaderships;
        this.stocksBaskets = data.stocks_baskets;
        this.stats = data.stats;

        // HISTORY
        if (data.site_id && !this.id) this.id = data.site_id;
        if (data.site && !this.name) this.name = data.site;
        this.history = data && data.price_history ? data.price_history.map(h => new ScrapedData(h)) : data.price_history;

        this.evaluation = data?.evaluation;
    }

}

export default Site;
