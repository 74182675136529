// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import classNames from 'classnames';
// Locales
import translate from 'locales/i18n';
// Material UI Components
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import IconRefresh from '@mui/icons-material/Cached';
// Chart
import ChartDataLabels from 'chartjs-plugin-datalabels';
// Util
import { getOptions, createData } from 'components/filter/pricingEngine/PriceBasketsFilter/PriceBasketsFilter.util';
// Styles
import { useStyles } from 'components/filter/pricingEngine/PriceBasketsFilter/PriceBasketsFilter.style';
// Theme
import { useTheme } from '@emotion/react';
// PCP Components
import DialogInfo from 'components/dialog/DialogInfo/DialogInfo';
// Images
import PriceBasketsFilterExample from 'assets/img/pricing-engine-examples/pricing-engine-prices-baskets-filter.png';

const PriceBasketsFilter = ({ isLoading, pricesBaskets, pricesBasketsSelected, reset, selectPricesBaskets }) => {
    const [dialogInfoOpen, setDialogInfoOpen] = useState(false);
    const classes = useStyles();
    const theme = useTheme();

    if (pricesBaskets.length === 0)
        return (
            <Paper className={classNames(classes.paper, isLoading && classes.notAllowed)}>
            </Paper>
        );

    const renderDialogInfo = () => <DialogInfo open={dialogInfoOpen}
        close={() => setDialogInfoOpen(false)}>
        <div className={classes.infoMainText}>{translate.i18n('PRICE_BASKETS_FILTER_PRICING_ENGINE')}</div>
        <ul>
            <li className={classes.infoSubText}>{translate.i18n('PRICE_BASKETS_FILTER_2')}</li>
            <li className={classes.infoSubText}>{translate.i18n('PRICE_BASKETS_FILTER_3_RETAILER')}</li>
        </ul>
        <div className={classes.infoImgContainer}><img src={PriceBasketsFilterExample} /></div>
    </DialogInfo>;

    return (
        <Paper className={classNames(classes.paper, isLoading && classes.notAllowed)}>
            <div className={classes.content}>
                <div className={classes.subtitleContainer}>
                    <div className={classes.subtitle}>Actuellement</div>
                    <div className={classes.subtitle}>Après repricing</div>
                </div>
                <div className={classes.graphs}>
                    <div className={classes.graphContainer}>
                        <Bar
                            plugins={[ChartDataLabels]}
                            data={createData(pricesBaskets, pricesBasketsSelected, 'current', theme)}
                            getElementAtEvent={element => {
                                if (element.length !== 0 && !isLoading)
                                    selectPricesBaskets(pricesBaskets[element[0].index].id, 'current');
                            }}
                            options={getOptions(pricesBaskets, isLoading, 'current')}
                        />
                    </div>
                    <div className={classes.graphContainer}>
                        <Bar
                            plugins={[ChartDataLabels]}
                            data={createData(pricesBaskets, pricesBasketsSelected, 'withRepricing', theme)}
                            getElementAtEvent={element => {
                                if (element.length !== 0 && !isLoading)
                                    selectPricesBaskets(pricesBaskets[element[0].index].id, 'withRepricing');
                            }}
                            options={getOptions(pricesBaskets, isLoading, 'withRepricing')}
                        />
                    </div>
                    <div className={classes.ticks}><p className={classes.tooExpensiveLabel}>{translate.i18n('CHEAPEST')}</p><p className={classes.tooCheapLabel}>{translate.i18n('MORE_EXPENSIVE')}</p></div>
                </div>
            </div>
            <IconButton
                color='primary'
                onClick={reset}
                className={classes.resetButton}>
                <IconRefresh />
            </IconButton>
            <IconButton
                color='primary'
                onClick={() => setDialogInfoOpen(true)}
                className={classes.infoButton}>
                <InfoIcon />
            </IconButton>
            {renderDialogInfo()}
        </Paper>
    );
};

PriceBasketsFilter.propTypes = {
    isLoading: PropTypes.bool,
    pricesBaskets: PropTypes.array,
    pricesBasketsSelected: PropTypes.object,
    reset: PropTypes.func,
    selectPricesBaskets: PropTypes.func
};

export default PriceBasketsFilter;
