import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    deleteButton: {
        alignItems: 'center',
        borderRight: 'solid 1px ' + theme.palette.grey.light,
        color: theme.palette.grey.main,
        display: 'flex',
        fontWeight: 900,
        height: '100%',
        justifyContent: 'center',
        minHeight: '64px',
        minWidth: '15px'
    },
    fullWidth: {
        width: '100%'
    },
    icon: {
        height: '16px',
        width: '16px'
    },
    img: {
        marginRight: '4px',
        maxHeight: theme.spacing(8),
        maxWidth: theme.spacing(8)
    },
    imgContainer: {
        alignItems: 'center',
        background: theme.palette.white.main,
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        minHeight: '64px',
        width: '80px'
    },
    imgLarge: {
        borderRadius: '3px',
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
        maxHeight: '300px',
        maxWidth: '300px'
    },
    itemAvgEval: {
        marginRight: '5px'
    },
    label: {
        color: theme.palette.grey.dark,
        fontSize: '14px',
        fontWeight: '500',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
    },
    link: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        marginLeft: '3px',
        height: '80px',
        width: '200px',
        textDecoration: 'none'
    },
    popper: {
        backgroundColor: 'transparent',
        zIndex: 1500
    },
    reactHoverObserver: {
        height: '100%'
    },
    ref: {
        fontSize: '13px',
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center'
    },
    refsContainer: {
        color: theme.palette.grey.semidark,
        display: 'flex',
        fontWeight: '400',
        flexDirection: 'column',
    },
    refSeparator: {
        backgroundColor: theme.palette.grey.main,
        borderRadius: '2px',
        color: theme.palette.grey.main,
        fontWeight: 300,
        marginLeft: '4px',
        marginRight: '4px'
    },
    tableCell: {
        borderRight: '1px solid' + theme.palette.grey.light,
        cursor: 'pointer',
        height: '100%',
        left: 0,
        padding: 0,
        position: 'sticky',
        top: 'auto',
        zIndex: 11
    },
    tableCellContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '270px'
    }
}));
