// Material UI
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    kpi: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    kpisContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        paddingBottom: '10px',
        paddingTop: '10px'
    },
    kpiText: {
        fontSize: '0.83rem',
        fontWeight: 'bold',
        marginRight: '5px'
    },
    kpiTrendArrow: {
        color: '#03A9F4',
        height: '30px',
        width: '30px'
    },
    kpiTrendContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        width: '50px'
    },
    kpiTrendText: {
        color: 'rgb(113, 113, 113)',
        margin: '0px'
    },
    loader: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    kpiBlock: {
        height: '55px',
        width: '88px',
        borderRadius: '10px',
        backgroundColor: (props) => props?.value ? props?.color : theme.palette.grey.light,
        color: (props) => props?.color === theme.palette.primary.main ? 'white' : 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '18.5px'
    }
}));
