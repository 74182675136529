// React
import React from 'react';
import PropTypes from 'prop-types';
// Chart
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
// Material UI Components
import Paper from '@mui/material/Paper';
// Util
import { createData, getOptions } from 'components/filter/slider/SliderDayFilter/SliderDayFilter.util';
// Style
import { useStyles } from 'components/filter/slider/SliderDayFilter/SliderDayFilter.style';
//Locacles
import translate from 'locales/i18n';

const SliderDayFilter = ({ daysValues }) => {
    const classes = useStyles();

    return (
        <>
            {!daysValues ?
                <Paper className={classes.paper}>
                </Paper>
                :
                <Paper className={classes.paper}>
                    <div className={classes.title}>
                        {translate.i18n('SLIDER_DAY_TITLE')}
                    </div>
                    <div className={classes.graphContainer}>
                        <Bar
                            plugins={[ChartDataLabels]}
                            data={createData(daysValues)}
                            options={getOptions()}
                        />
                    </div>
                </Paper>
            }
        </>
    );
};

SliderDayFilter.propTypes = {
    daysValues: PropTypes.array
};

export default SliderDayFilter;
