import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// PCP Components
import ImageViewer from 'components/commons/ImageViewer/ImageViewer';
//Style
import { useStyles } from 'components/sheet/SelectItemsSheet/SelectItemsSheet.style';
//MUI components
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
// Locales
import translate from 'locales/i18n';

const ProductLine = ({ product, selectProduct, unSelectProduct, selected, ruleId, setProductRank }) => {

    const [rank, setRank] = useState(0);
    const classes = useStyles();

    useEffect(() => {
        setRank(0);
        ruleId && product.associatedPricingEngineRules.forEach((rule, index) => {
            if (rule.id === ruleId) setRank(index);
        });
        setProductRank(product.id, rank);
    }, [selected]);

    return <div className={classes.itemContainer}>
        <Checkbox
            checked={selected}
            onChange={() => selected ? unSelectProduct([product.id]) : selectProduct([product.id])}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            color='primary'
        />
        <div className={classes.imageContainer}>
            <ImageViewer
                imageUrl={'https://img.pricecomparator.pro/' + product.imgPath}
                title={product.name}
                alt={product.name}
            />
        </div>
        <div className={classes.itemName}>{product.name}<div className={classes.itemInfo}>{`${product.rrp || product.purchasingPrice ? '(' : ''}${product.rrp ? `${translate.i18n('RRP')}: ${product.rrp}` : ''}${product.purchasingPrice && product.rrp ? ', ' : ''}${product.purchasingPrice ? `${translate.i18n('PURCHASING_PRICE')}: ${product.purchasingPrice}` : ''}${product.rrp || product.purchasingPrice ? ')' : ''}`}</div></div>
        {selected &&
            <FormControl className={classes.selectRank}>
                <InputLabel id='ruleRankLabel'>Rang</InputLabel>
                <Select
                    labelId='ruleRankLabel'
                    id='ruleRank'
                    value={rank}
                    label='Rang'
                    style={{ height: '100%' }}
                    onChange={(event) => {
                        setRank(event.target.value);
                        setProductRank(product.id, event.target.value);
                    }}
                >
                    <MenuItem value={0}>1</MenuItem>;
                    {product.associatedPricingEngineRules.map((rule, index) => <MenuItem key={index} value={index + 1}>{index + 2}</MenuItem>)}
                </Select>
            </FormControl>
        }
    </div>;
};

ProductLine.propTypes = {
    product: PropTypes.object,
    selectProduct: PropTypes.func,
    unSelectProduct: PropTypes.func,
    selected: PropTypes.bool,
    ruleId: PropTypes.string,
    setProductRank: PropTypes.func
};

export default ProductLine;
