/**
  * Method sort categories
  *
  * @param categories <array> the array of category and sort options
  * @returns <array<object>> array of categories sorted
  */
export const sortCategories = (categories, alpha, sort, percent, tab) => {
    if (tab === 0)
        return categories.sort((x, y) => {
            if ((x.name.localeCompare(y.name) === -1 && alpha) || (x.name.localeCompare(y.name) === 1 && !alpha))
                return 1;
            return -1;
        });

    if (tab === 1) {
        return categories.sort((x, y) => {
            if ((!(x.articlesCardinalities[0] > y.articlesCardinalities[0]) && sort) || (x.articlesCardinalities[0] > y.articlesCardinalities[0] && !sort))
                return 1;
            return -1;
        });
    }

    if (tab === 2) {
        return categories.sort((x, y) => {
            if ((!(x.withRepricingFaceToFaceLoosingRate > y.withRepricingFaceToFaceLoosingRate) && percent) || (x.withRepricingFaceToFaceLoosingRate > y.withRepricingFaceToFaceLoosingRate && !percent))
                return -1;
            return 1;
        });
    }
};

/**
  * Method called to calc the higher cardinalities of an array off categories
  *
  * @param categories the array of category
  * @returns <int> higher cardinality
  */
export const getHigherCardinality = categories => {
    let higherCardinality = 0;

    categories.map((category) => {
        if (category.articlesCardinalities[0] > higherCardinality)
            [higherCardinality] = category.articlesCardinalities;
        return true;
    });

    return higherCardinality;
};

/**
  * Method called to calc the color and size for each categories on the category filter
  *
  * @param categories <array<object>> the array of category
  * @returns <array<object>> the array of category with color and size
  */
export const calcColorAndSize = (categories, theme) => {
    const colors = [theme.palette.graph.one, theme.palette.graph.two, theme.palette.graph.three, theme.palette.graph.four, theme.palette.graph.five];
    let lowerWinningRate = 0;
    let higherWinningRate = 0;
    let lowerRepricedWinningRate = 0;
    let higherRepricedWinningRate = 0;
    let slot = 0;
    let repricedSlot = 0;

    categories.forEach((category) => {
        if (category.currentFaceToFaceLoosingRate > higherWinningRate)
            higherWinningRate = category.currentFaceToFaceLoosingRate;
        if (category.currentFaceToFaceLoosingRate < lowerWinningRate)
            lowerWinningRate = category.currentFaceToFaceLoosingRate;

        if (category.withRepricingFaceToFaceLoosingRate > higherRepricedWinningRate)
            higherRepricedWinningRate = category.withRepricingFaceToFaceLoosingRate;
        if (category.withRepricingFaceToFaceLoosingRate < lowerRepricedWinningRate)
            lowerRepricedWinningRate = category.withRepricingFaceToFaceLoosingRate;
    });

    slot = (higherWinningRate - lowerWinningRate) / 5;
    repricedSlot = (higherRepricedWinningRate - lowerRepricedWinningRate) / 5;

    return categories.map((category) => {
        let position = (category.currentFaceToFaceLoosingRate - lowerWinningRate) / slot;
        let repricedPosition = (category.withRepricingFaceToFaceLoosingRate - lowerRepricedWinningRate) / repricedSlot;

        if (position === 5)
            position = 4;
        if (slot === 0)
            position = 0;

        if (repricedPosition === 5)
            repricedPosition = 4;
        if (repricedSlot === 0)
            repricedPosition = 0;

        category.color = colors[Math.floor(position)];
        category.repricedColor = colors[Math.floor(repricedPosition)];

        return category;
    });
};
