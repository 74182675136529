// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Locales
import translate from 'locales/i18n';
import withStyles from '@mui/styles/withStyles';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
// Styles
import styles from 'components/filter/price/CategoryBar/CategoryBar.style';
//Theme
import { useTheme } from '@mui/styles';

const CategoryBar = ({ classes, category, higherCardinality, categoriesVisible, isLoading, selectCategory }) => {
    const [hover, setHover] = useState(false);

    const theme = useTheme();

    const title = category.name + ': ' + translate.i18n('PRICES_LESS_EXPENSIVE', {
        number: category.faceToFaceLoosingRate
    });

    let backgroundColor = theme.palette.grey.light;

    if (categoriesVisible.length === 0 || categoriesVisible.indexOf(category.id) !== -1)
        backgroundColor = category.color;

    let backgroundSize = (category.articlesCardinalities[0] / higherCardinality) * 100;

    if (backgroundSize < 1 && category.articlesCardinalities[0] > 0)
        backgroundSize = 1;

    const categoryRectStyle = {
        backgroundColor,
        borderBottomRightRadius: '3px',
        borderTopRightRadius: '3px',
        height: '100%',
        transition: 'width 1s, opacity 0.5s',
        width: Math.trunc(backgroundSize) + '%'
    };

    const categoryRectStyleHover = {
        backgroundColor,
        borderBottomRightRadius: '3px',
        borderTopRightRadius: '3px',
        height: '100%',
        opacity: '0.9',
        transition: 'width 1s',
        width: Math.trunc(backgroundSize) + '%'
    };

    return (
        <Tooltip title={<div className={classes.tooltipLabel}>{title}</div>} arrow placement='top' followCursor>
            <div className={classes.hoverObserver}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <div className={classNames(classes.categoryContainer, isLoading && classes.notAllowedCategory)} onClick={() => !isLoading && selectCategory(category.id)}>
                    <div style={hover ? categoryRectStyleHover : categoryRectStyle}></div>
                    <div className={classNames(classes.categoryTextContainer, isLoading && classes.notAllowedCategory)}>
                        <Chip label={category.name}
                            style={{ maxWidth: '75%' }}
                            classes={{
                                label: classes.categoryLabel,
                                root: classNames(classes.chip, isLoading && classes.notAllowedCategory)
                            }} />
                        <Chip label={category.articlesCardinalities[0] + '/' + category.articlesCardinalities[1]}
                            style={{ marginLeft: 'auto' }}
                            classes={{
                                label: classes.ratioLabel,
                                root: classNames(classes.chip, isLoading && classes.notAllowedCategory)
                            }} title={category.articlesCardinalities[0] + '/' + category.articlesCardinalities[1]} />
                    </div>
                </div>
            </div>
        </Tooltip>
    );
};

CategoryBar.propTypes = {
    categoriesVisible: PropTypes.array,
    category: PropTypes.object,
    classes: PropTypes.object.isRequired,
    higherCardinality: PropTypes.number,
    isLoading: PropTypes.bool,
    selectCategory: PropTypes.func
};

export default withStyles(styles)(CategoryBar);
