import AbstractService from 'core/abstracts/AbstractService';
import Facade from 'core/Facade';

class TrendService extends AbstractService {

    /**
     * Get trends info
    */
    getTrends(options) {
        return this.request({
            method: 'POST',
            url:  Facade.getApiRoot() + `trends/analyse?accountId=${options.accountId}&fromStr=${options.fromStr}&toStr=${options.toStr}`,
            json: options.body
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

}

export default new TrendService();
