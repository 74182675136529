// React
import React from 'react';
import PropTypes from 'prop-types';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
// Styles
import { useStyles } from 'components/dialog/DialogMail/DialogMail.style';
// Locales
import translate from 'locales/i18n';
import { format } from 'date-fns/esm';

const DialogMail = ({ close, mail, open }) => {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={close}
            maxWidth={'md'}
            fullWidth={true}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
        >
            <IconButton
                color='secondary'
                onClick={close}
                className={classes.closeButton}
                size='large'>
                <ClearIcon />
            </IconButton>
            <DialogTitle id='responsive-dialog-title'>
                {mail && <span className={classes.titleDialogBox}>
                    {mail.fromName} - {mail.fromMail} à {format(new Date(mail.date), 'hh:mm')} le {format(new Date(mail.date), 'dd/MM/yyyy')}
                </span>}
                {mail && <div className={classes.subTitleDialogBox}>
                    {mail.subject}
                </div>}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Paper elevation={4} className={classes.paper}>
                    {mail && <iframe id={mail.fromName + '-full-page'} target='_blank' srcDoc={mail.html} className={classes.iframeMail} title={mail.fromName + '-full-page'}></iframe>}
                </Paper>
            </DialogContent>
            <Button variant='contained' color='primary' className={classes.button} size='large' onClick={() => mail && window.frames[mail.fromName + '-full-page'].contentWindow.print()}>
                {translate.i18n('PRINT')}
            </Button>
        </Dialog>
    );
};

DialogMail.propTypes = {
    close: PropTypes.func,
    mail: PropTypes.object,
    open: PropTypes.bool
};

export default DialogMail;
