const styles = theme => ({
    doNotHaveModuleText: {
        fontSize: '25px'
    },
    getModuleText: {
        fontSize: '20px',
        marginBottom: '60px',
        marginTop: '15px'
    },
    link: {
        color: theme.palette.green.dark
    },
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    },
    smiley: {
        color: theme.palette.green.dark,
        fontSize: '160px',
        transform: 'rotate(90deg)'
    }
});

export default styles;
