import AbstractService from 'core/abstracts/AbstractService';
import Facade from 'core/Facade';

class AccountService extends AbstractService {
    /**
     * Get account.
     */
    getAccount(id) {
        return this.request({
            method: 'GET',
            url: Facade.getApiRoot() + `accounts/${id}`,
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }
}

export default new AccountService();
