const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        overflow: 'auto'
    },
    basicCellContent: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        color: theme.palette.black.main,
        textDecoration: 'none'
    },
    itemName: {
        fontSize: '12px'
    },
    title: {
        fontSize: '17px',
        fontWeight: '500'
    },
    table: {
        borderCollapse: 'separate'
    },
    tableHead: {
        height: '50px'
    },
    tableHeadCell: {
        backgroundColor: theme.palette.white.main,
        padding: 10,
        position: 'sticky',
        top: 0,
        zIndex: 10
    },
    tableHeadContent: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    }
});

export default styles;
