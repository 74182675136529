class AdditionalData {

    constructor (data) {
        this.evaluation = data.evaluation;
        this.ref = data.ref;
        this.oldPrice = data.old_price;
        this.brand = data.brand;
    }

    /**
     * Methods
     */
    toJson() {
        return {
            evaluation: this.evaluation,
            ref: this.ref,
            old_price: this.oldPrice,
            brand: this.brand
        };
    }
}
export default AdditionalData;
