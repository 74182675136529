// React
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// Locales
import translate from 'locales/i18n';
// Style
import { styles } from 'components/popper/MenuPopper/MenuPopper.style';
// Material UI Components
import withStyles from '@mui/styles/withStyles';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExitIcon from '@mui/icons-material/ExitToApp';
import InfoIcon from '@mui/icons-material/Info';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ProfileIcon from '@mui/icons-material/Person';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const MenuPopper = ({ anchor, classes, handleClose, logout, open, openAboutPopup }) =>
    <Popper
        open={open}
        anchorEl={anchor}
        transition
        disablePortal
        className={classes.popper}
        modifiers={[]}>
        {({ TransitionProps, placement }) =>
            <Grow
                {...TransitionProps}
                id='menu-list-grow'
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
                <Paper className={classes.paper} id={'menu-container'}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList className={classes.menuList}>
                            <NavLink
                                to={'/profile'}
                                className={classes.navLink}>
                                <MenuItem>
                                    <ListItemIcon>
                                        <ProfileIcon />
                                    </ListItemIcon>
                                    <ListItemText inset primary={translate.i18n('PROFILE')} />
                                    <ArrowRightIcon />
                                </MenuItem>
                            </NavLink>
                            <MenuItem onClick={() => openAboutPopup()}>
                                <ListItemIcon>
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText inset primary={translate.i18n('ABOUT')} />
                            </MenuItem>
                            <MenuItem onClick={() => logout()}>
                                <ListItemIcon>
                                    <ExitIcon />
                                </ListItemIcon>
                                <ListItemText inset primary={translate.i18n('LOGOUT')} />
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>}
    </Popper>;

MenuPopper.propTypes = {
    anchor: PropTypes.object,
    classes: PropTypes.object.isRequired,
    handleClose: PropTypes.func,
    logout: PropTypes.func,
    open: PropTypes.bool,
    openAboutPopup: PropTypes.func
};

export default withStyles(styles)(MenuPopper);
