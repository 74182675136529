// Locales
import translate from 'locales/i18n';
// Stores
import AccountStore from 'stores/AccountStore';

/**
  * Method called to get the resume of strategy
  *
  * @param strategy <object> the strategy object
  * @returns <string> the resume of strategy
  */
export const getResume = strategy => {
    if (!strategy) return '';

    const { filteringSiteIds, positioning, filteringStocks, rounding, lowLimitPrice, highLimitPrice, offset, type, element } = strategy;

    let resume = '';

    if (type === 'SELF_ORIENTED_STRATEGY') {
        resume = `${translate.i18n('I_WANT_TO_BE')} ${translate.i18n('SELF_ORIENTED_STRATEGY')} ${translate.i18n('BASED_ON_ELEM', { elem: translate.i18n(element) })} ${translate.i18n('BY_VALUE', { value: `${offset.value} ${offset.mode === 'VALUE' ? AccountStore.getCurrentAccount().getCurrency() : '%'}` })}, ${rounding ? translate.i18n(rounding) : translate.i18n('TO_PRICE')}`;
        return resume;
    }

    // Positioning
    resume = `${translate.i18n('I_WANT_TO_BE')} ${positioning ? translate.i18n(positioning) : translate.i18n('MIN_PRICE')}`;

    // Offset
    resume += offset && offset.value !== 0 ? ` ${translate.i18n('BY_VALUE', { value: `${offset.value} ${offset.mode === 'VALUE' ? AccountStore.getCurrentAccount().getCurrency() : '%'}` })},` : '';

    // Competitors
    resume += filteringSiteIds ? `${translate.i18n('SOME_COMPETITORS')}, ` : `${translate.i18n('ALL_COMPETITORS')}, `;

    // Stocks
    resume += filteringStocks ? `${translate.i18n('SOME_STOCKS')}, ` : `${translate.i18n('ALL_STOCKS')}, `;

    // Low limit
    resume += !lowLimitPrice ? ` ${translate.i18n('WITHOUT_LOW_LIMIT')}, ` : `${translate.i18n('WITH_LOW_LIMIT')} ${lowLimitPrice.value < 0 ? '' : '+'}${lowLimitPrice.value}${lowLimitPrice.mode === 'PERCENTAGE' ? '%'
        : AccountStore.getCurrentAccount().getCurrency()} ${translate.i18n('OF_MY')} ${translate.i18n(lowLimitPrice.element)}, `;

    // High limit
    resume += !highLimitPrice ? ` ${translate.i18n('WITHOUT_HIGH_LIMIT')}, `
        : ` ${translate.i18n('WITH_HIGH_LIMIT')} ${highLimitPrice.value < 0 ? '' : '+'}${highLimitPrice.value}${highLimitPrice.mode === 'PERCENTAGE' ? '%' : AccountStore.getCurrentAccount().getCurrency()} ${translate.i18n('OF_MY')} ${translate.i18n(highLimitPrice.element)}, `;

    // Rounding
    resume += rounding ? translate.i18n(rounding) : translate.i18n('TO_PRICE');

    return resume;
};

export const displayPrice = (priceRule, isMinPrice, product) => {
    const { value } = priceRule;
    const prodPrice = parseFloat(product.purchasingPrice);
    const prodRRP = parseFloat(product.rrp);
    let res = 0;
    if (priceRule.mode === 'PERCENTAGE') {
        if (priceRule.element === 'PURCHASING_PRICE') {
            const priceChange = (prodPrice * value) / 100;
            res = isMinPrice ? prodPrice - priceChange : prodPrice + priceChange;
        } else {
            const priceChange = (prodRRP * value) / 100;
            res = isMinPrice ? prodRRP - priceChange : prodRRP + priceChange;
        }
    } else {
        if (priceRule.element === 'PURCHASING_PRICE') {
            res = isMinPrice ? prodPrice - value : prodPrice + value;
        } else {
            res = isMinPrice ? prodRRP - value : prodRRP + value;
        }
    }

    return displayPriceString(parseFloat(res.toFixed(2)));
};

export const displayDate = (date) => date.split('-')[2]+'/'+date.split('-')[1]+'/'+date.split('-')[0];

export const displayPriceString = (price) => {
    let priceString = typeof price === 'number' ? price.toString() : price;

    priceString = priceString.split('.');
    if (priceString.length < 2) return price;

    if (priceString[1].length === 1) return `${priceString[0]},${priceString[1]}0`;
    return `${priceString[0]},${priceString[1]}`;
};
