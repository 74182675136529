export const styles = theme => ({
    arrow: {
        '&::before': {
            borderStyle: 'solid',
            content: '""',
            display: 'block',
            height: 0,
            margin: 'auto',
            width: 0
        },
        color: theme.palette.grey.light,
        fontSize: 7,
        height: '3em',
        position: 'absolute',
        width: '3em'
    },
    menuList: {
        padding: 0
    },
    navLink: {
        '&:active': {
            textDecoration: 'none'
        },
        '&:focus': {
            textDecoration: 'none'
        },
        '&:hover': {
            color: theme.palette.grey.dark,
            textDecoration: 'none'
        },
        '&:link': {
            textDecoration: 'none'
        },
        '&:visited': {
            textDecoration: 'none'
        },
        color: theme.palette.black.main,
        textDecoration: 'none'
    },
    popper: {
        '&[x-placement*="bottom"] $arrow': {
            '&::before': {
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
                borderWidth: '0 1em 1em 1em'
            },
            height: '1em',
            left: 0,
            marginTop: '-0.9em',
            top: 0,
            width: '3em'
        },
        zIndex: 1
    }
});
