export const teaserConst = {
    ADBOX: {
        image1: 'https://img.pricecomparator.pro/front/images/utils/newsletter1.png',
        image2: 'https://img.pricecomparator.pro/front/images/utils/newsletter2.png',
        text1: [
            { text: 'TEASER.ADBOX_TEXT_1_0', bold: false },
            { text: 'TEASER.ADBOX_TEXT_1_1', bold: true },
            { text: 'TEASER.ADBOX_TEXT_1_2', bold: false },
            { jumpLine: true },
            { text: 'TEASER.ADBOX_TEXT_1_3', bold: false },
            { text: 'TEASER.ADBOX_TEXT_1_4', bold: true },
            { text: 'TEASER.ADBOX_TEXT_1_5', bold: false },
            { text: 'TEASER.ADBOX_TEXT_1_6', bold: true },
            { text: 'TEASER.ADBOX_TEXT_1_7', bold: false },
        ],
        text1Title: 'TEASER.ADBOX_TEXT_1_TITLE',
        text2: [
            { text: 'TEASER.ADBOX_TEXT_2_0', bold: false },
            { text: 'TEASER.ADBOX_TEXT_2_1', bold: true },
            { text: 'TEASER.ADBOX_TEXT_2_2', bold: false },
            { jumpLine: true },
            { text: 'TEASER.ADBOX_TEXT_2_3', bold: false },
        ],
        text2Title: 'TEASER.ADBOX_TEXT_2_TITLE',
        title: 'AdBox'
    },
    PE: {
        image1: 'https://img.pricecomparator.pro/front/images/utils/pe1.png',
        image2: 'https://img.pricecomparator.pro/front/images/utils/pe2.png',
        text1: [
            { text: 'TEASER.PE_TEXT_1_0', bold: false },
            { text: 'TEASER.PE_TEXT_1_1', bold: true },
            { text: 'TEASER.PE_TEXT_1_2', bold: false },
            { jumpLine: true },
            { text: 'TEASER.PE_TEXT_1_3', bold: false },
            { text: 'TEASER.PE_TEXT_1_4', bold: true },
            { text: 'TEASER.PE_TEXT_1_5', bold: false },
            { text: 'TEASER.PE_TEXT_1_6', bold: true },
            { text: 'TEASER.PE_TEXT_1_7', bold: false },
            { jumpLine: true },
            { text: 'TEASER.PE_TEXT_1_8', bold: false },
            { text: 'TEASER.PE_TEXT_1_9', bold: true },
            { text: 'TEASER.PE_TEXT_1_10', bold: false },
            { text: 'TEASER.PE_TEXT_1_11', bold: true },
            { text: 'TEASER.PE_TEXT_1_12', bold: false },
            { jumpLine: true },
            { text: 'TEASER.PE_TEXT_1_13', bold: false },
        ],
        text1Title: 'TEASER.PE_TEXT_1_TITLE',
        text2: [
            { text: 'TEASER.PE_TEXT_2_0', bold: true },
            { text: 'TEASER.PE_TEXT_2_1', bold: false },
            { text: 'TEASER.PE_TEXT_2_2', bold: true },
            { jumpLine: true },
            { text: 'TEASER.PE_TEXT_2_3', bold: false },
            { text: 'TEASER.PE_TEXT_2_4', bold: true },
            { text: 'TEASER.PE_TEXT_2_5', bold: false },
            { jumpLine: true },
            { text: 'TEASER.PE_TEXT_2_6', bold: false },
            { text: [
                'TEASER.PE_TEXT_2_7_0',
                'TEASER.PE_TEXT_2_7_1',
                'TEASER.PE_TEXT_2_7_2',
                'TEASER.PE_TEXT_2_7_3',
                'TEASER.PE_TEXT_2_7_4',
            ], bold: false },
        ],
        text2Title: 'TEASER.PE_TEXT_2_TITLE',
        title: 'Pricing Engine'
    },
    PERF: {
        image1: 'https://img.pricecomparator.pro/front/images/utils/perf2.png',
        image2: 'https://img.pricecomparator.pro/front/images/utils/perf1.png',
        text1: [
            { text: 'TEASER.PERF_TEXT_1_0', bold: false },
            { text: 'TEASER.PERF_TEXT_1_1', bold: true },
            { text: 'TEASER.PERF_TEXT_1_2', bold: false },
            { jumpLine: true },
            { text: 'TEASER.PERF_TEXT_1_3', bold: false },
            { text: 'TEASER.PERF_TEXT_1_4', bold: true },
            { text: 'TEASER.PERF_TEXT_1_5', bold: false },
            { text: 'TEASER.PERF_TEXT_1_6', bold: true },
        ],
        text1Title: 'TEASER.PERF_TEXT_1_TITLE',
        text2: [
            { text: 'TEASER.PERF_TEXT_2_0', bold: true },
            { text: 'TEASER.PERF_TEXT_2_1', bold: false },
            { jumpLine: true },
            { text: 'TEASER.PERF_TEXT_2_2', bold: false },
            { text: 'TEASER.PERF_TEXT_2_3', bold: true },
        ],
        text2Title: 'TEASER.PERF_TEXT_2_TITLE',
        title: 'Performance'
    },
    SLIDER: {
        image1: 'https://img.pricecomparator.pro/front/images/utils/slider1.png',
        image2: 'https://img.pricecomparator.pro/front/images/utils/slider2.png',
        text1: [
            { text: 'TEASER.SLIDER_TEXT_1_0', bold: true },
            { text: 'TEASER.SLIDER_TEXT_1_1', bold: false },
            { jumpLine: true },
            { text: 'TEASER.SLIDER_TEXT_1_2', bold: false },
            { text: 'TEASER.SLIDER_TEXT_1_3', bold: true },
            { jumpLine: true },
            { text: 'TEASER.SLIDER_TEXT_1_4', bold: false },
            { text: 'TEASER.SLIDER_TEXT_1_5', bold: true },
            { text: 'TEASER.SLIDER_TEXT_1_6', bold: false },
        ],
        text1Title: 'TEASER.SLIDER_TEXT_1_TITLE',
        text2: [
            { text: 'TEASER.SLIDER_TEXT_2_0', bold: false },
            { text: 'TEASER.SLIDER_TEXT_2_1', bold: true },
            { text: 'TEASER.SLIDER_TEXT_2_2', bold: false },
            { jumpLine: true },
            { text: 'TEASER.SLIDER_TEXT_2_3', bold: false },
            { text: 'TEASER.SLIDER_TEXT_2_4', bold: true },
        ],
        text2Title: 'TEASER.SLIDER_TEXT_2_TITLE',
        title: 'Slider'
    },
    SPE: {
        image1: 'https://img.pricecomparator.pro/front/images/utils/spe1.png',
        image2: 'https://img.pricecomparator.pro/front/images/utils/spe2.png',
        text1: [
            { text: 'TEASER.SPE_TEXT_1_0', bold: true },
            { jumpLine: true },
            { text: 'TEASER.SPE_TEXT_1_1', bold: true },
            { text: 'TEASER.SPE_TEXT_1_2', bold: false },
            { text: 'TEASER.SPE_TEXT_1_3', bold: true },
            { jumpLine: true },
            { text: 'TEASER.SPE_TEXT_1_4', bold: false },
            { text: 'TEASER.SPE_TEXT_1_5', bold: true },
            { text: 'TEASER.SPE_TEXT_1_6', bold: false },
            { text: 'TEASER.SPE_TEXT_1_7', bold: true },
            { text: 'TEASER.SPE_TEXT_1_8', bold: false },
            { text: 'TEASER.SPE_TEXT_1_9', bold: true },
            { jumpLine: true },
            { text: 'TEASER.SPE_TEXT_1_10', bold: false },
            { text: 'TEASER.SPE_TEXT_1_11', bold: true },
            { text: 'TEASER.SPE_TEXT_1_12', bold: false },
            { text: 'TEASER.SPE_TEXT_1_13', bold: true },
            { text: 'TEASER.SPE_TEXT_1_14', bold: false },
        ],
        text1Title: 'TEASER.SPE_TEXT_1_TITLE',
        text2: [
            { text: 'TEASER.SPE_TEXT_2_0', bold: false },
            { text: 'TEASER.SPE_TEXT_2_1', bold: true },
            { text: 'TEASER.SPE_TEXT_2_2', bold: false },
            { jumpLine: true },
            { text: 'TEASER.SPE_TEXT_2_3', bold: false },
            { jumpLine: true },
            { text: 'TEASER.SPE_TEXT_2_4', bold: true },
            { text: 'TEASER.SPE_TEXT_2_5', bold: false },
            { text: 'TEASER.SPE_TEXT_2_6', bold: true },
            { text: 'TEASER.SPE_TEXT_2_7', bold: false },
        ],
        text2Title: 'TEASER.SPE_TEXT_2_TITLE',
        title: 'Smart Price Extractor'
    },
    TRENDS: {
        image1: 'https://img.pricecomparator.pro/front/images/utils/trends2.png',
        image2: 'https://img.pricecomparator.pro/front/images/utils/trends1.png',
        text1: [
            { text: 'TEASER.TRENDS_TEXT_1_0', bold: false },
            { text: 'TEASER.TRENDS_TEXT_1_1', bold: true },
            { text: 'TEASER.TRENDS_TEXT_1_2', bold: false },
            { text: 'TEASER.TRENDS_TEXT_1_3', bold: true },
            { text: 'TEASER.TRENDS_TEXT_1_4', bold: false },
            { jumpLine: true },
            { text: 'TEASER.TRENDS_TEXT_1_5', bold: false },
            { text: 'TEASER.TRENDS_TEXT_1_6', bold: true },
            { text: 'TEASER.TRENDS_TEXT_1_7', bold: false },
            { text: 'TEASER.TRENDS_TEXT_1_8', bold: true },
            { text: 'TEASER.TRENDS_TEXT_1_9', bold: false },
        ],
        text1Title: 'TEASER.TRENDS_TEXT_1_TITLE',
        text2: [
            { text: 'TEASER.TRENDS_TEXT_2_0', bold: true },
            { text: 'TEASER.TRENDS_TEXT_2_1', bold: false },
            { jumpLine: true },
            { text: 'TEASER.TRENDS_TEXT_2_2', bold: true },
            { text: 'TEASER.TRENDS_TEXT_2_3', bold: false },
            { jumpLine: true },
            { text: 'TEASER.TRENDS_TEXT_2_4', bold: false },
            { text: 'TEASER.TRENDS_TEXT_2_5', bold: true },
            { text: 'TEASER.TRENDS_TEXT_2_6', bold: false },
            { text: 'TEASER.TRENDS_TEXT_2_7', bold: true },
        ],
        text2Title: 'TEASER.TRENDS_TEXT_2_TITLE',
        title: 'Trends'
    }
};
