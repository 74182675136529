const styles = theme => ({
    coloredPriceMode: {
        color: theme.palette.primary.dark
    },
    date: {
        color: theme.palette.primary.dark
    },
    dates: {
        color: theme.palette.grey.dark,
        fontSize: '23px',
        fontWeight: 500,
        marginBottom: '10px',
        textAlign: 'center'
    },
    detailsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        height: 'calc(100% - 93px)',
        width: '100%'
    },
    midPaper: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(50% - 15px)',
        justifyContent: 'center',
        margin: '5px',
        width: 'calc(50% - 10px)',
        padding: '15px'
    },
    paperTitle: {
        color: theme.palette.grey.dark,
        fontSize: '25px',
        fontWeight: 500,
        margin: '15px',
        textAlign: 'center'
    },
    price: {
        color: theme.palette.primary.main,
        fontSize: '120px',
        fontWeight: 500,
        margin: '5px',
        textAlign: 'center'
    },
    priceMode: {
        color: theme.palette.grey.dark,
        fontSize: '20px',
        fontWeight: 500,
        margin: '5px',
        textAlign: 'center'
    },
    subTitle: {
        fontWeight: 600,
        fontSize: '15px'
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    bigPrice: {
        color: theme.palette.primary.main,
        fontSize: '140px',
        fontWeight: 500,
        margin: '5px',
        textAlign: 'center'
    },
    strategyResume: {
        color: theme.palette.black.main,
        fontSize: '16px',
        fontWeight: 500,
        margin: '5px',
        textAlign: 'center'
    },
    strategyInfos: {
        display: 'flex',
        justifyContent: 'space-around',
        fontSize: '14px',
        marginTop: '10px',
        maxHeight: '50%',
        overflow: 'auto'
    },
    root: {
        height: '100%',
        padding: '10px',
        width: '100%',
        position: 'relative'
    },
    ruleName: {
        color: theme.palette.primary.main,
        fontSize: '40px',
        fontWeight: 600,
        textAlign: 'center'
    }
});

export default styles;
