import React from 'react';
import PropTypes from 'prop-types';
// Stores
import UserStore from 'stores/UserStore';
import PerformanceStore from 'stores/PerformanceStore';
import AccountStore from 'stores/AccountStore';
// Actions
import PerformanceAction from 'actions/PerformanceAction';
// Locales
import translate from 'locales/i18n';
// Material UI Components
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// PCP components
import PerformanceGraph from 'components/chart/PerformanceGraph/PerformanceChart';
import PerformanceTable from 'components/table/PerformanceTable/PerformanceTable';
import Loader from 'components/commons/Loader/Loader';
// Styles
import styles from 'components/pages/PerformancePage/PerformancePage.style';
// Util
import { addToUrl } from 'components/pages/PerformancePage/PerformancePage.util';

class Performance extends React.Component {

    state = {
        from: PerformanceStore.from,
        isLoading: PerformanceStore.isLoading(),
        sites: PerformanceStore.getSites(),
        to: PerformanceStore.to
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    componentDidMount() {
        PerformanceStore.addChangeListener(this.onPerformanceStoreChange);

        if (!PerformanceStore.getSites().length && UserStore.isLogged()) {
            const options = {
                accountId: AccountStore.getCurrentAccount().getId(),
                from: PerformanceStore.from,
                to: PerformanceStore.to
            };
            PerformanceAction.getPerformances(options);
        }

        this.mounted = true;
    }

    componentWillUnmount() {
        PerformanceStore.removeChangeListener(this.onPerformanceStoreChange);
        this.mounted = false;
    }

    onPerformanceStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            isLoading: PerformanceStore.isLoading(),
            sites: PerformanceStore.getSites()
        });
    };

    getPerformances = () => {
        const options = {
            accountId: AccountStore.getCurrentAccount().getId(),
            from: this.state.from,
            to: this.state.to
        };

        PerformanceAction.getPerformances(options);
    };

    handleDates = ([from, to]) => {
        if (from && to && from.getTime() < to.getTime()) {
            this.setState({ from, to }, () => {
                addToUrl('from_perf', from);
                addToUrl('to_perf', to);
                this.getPerformances();
            });
        }
    };

    render() {
        const { classes } = this.props;
        const { to, from, isLoading, sites } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.topContainer}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                            inputFormat={'dd/MM/yyyy'}
                            startText={translate.i18n('FROM')}
                            endText={translate.i18n('TO')}
                            value={[from, to]}
                            onChange={this.handleDates}
                            maxDate={new Date()}
                            renderInput={(startProps, endProps) =>
                                <React.Fragment>
                                    <TextField {...startProps} size='small' classes={{ root: classes.datePicker }} />
                                    <TextField {...endProps} size='small' classes={{ root: classes.datePicker }} />
                                </React.Fragment>
                            }
                        />
                    </LocalizationProvider>
                </div>
                {isLoading ?
                    <div className={classes.loaderContainer}>
                        <Loader size={70} />
                    </div>
                    :
                    !sites.find(site => site.performances.length > 0) ?
                        <div className={classes.noDataFoundContainer}>{translate.i18n('NO_DATA_FOUND')}</div>
                        :
                        <div className={classes.informationContainer}>
                            <PerformanceGraph
                                sites={sites}
                                isLoading={isLoading} />
                            <PerformanceTable
                                sites={sites}
                                to={to}
                                from={from} />
                        </div>
                }
            </div>
        );
    }
}

export default withStyles(styles)(Performance);
