// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Locales
import translate from 'locales/i18n';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
// Styles
import styles from 'components/filter/pricingEngine/DoubleCategoryBar/DoubleCategoryBar.style';
//Theme
import { useTheme } from '@mui/styles';

const DoubleCategoryBar = ({ classes, category, higherCardinality, categoriesVisible, isLoading, selectCategory }) => {
    const [hover, setHover] = useState(false);

    const theme = useTheme();

    const titleCurrent = category.name + ': ' + translate.i18n('PRICES_LESS_EXPENSIVE', {
        number: category.currentFaceToFaceLoosingRate
    });

    const titleRepriced = category.name + ': ' + translate.i18n('PRICES_LESS_EXPENSIVE', {
        number: category.withRepricingFaceToFaceLoosingRate
    });

    let backgroundColor = theme.palette.grey.light;
    let repricedBackgroundColor = theme.palette.grey.light;

    if (categoriesVisible.length === 0 || categoriesVisible.indexOf(category.id) !== -1)
        backgroundColor = category.color;

    if (categoriesVisible.length === 0 || categoriesVisible.indexOf(category.id) !== -1)
        repricedBackgroundColor = category.repricedColor;

    let backgroundSize = (category.articlesCardinalities[0] / higherCardinality) * 100;

    if (backgroundSize < 1 && category.articlesCardinalities[0] > 0)
        backgroundSize = 1;

    return (
        <div className={classes.hoverObserver}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => !isLoading && selectCategory(category.id)}>

            <div className={classNames(classes.categoryContainer, isLoading && classes.notAllowedCategory)}>
                <div style={{
                    backgroundColor,
                    transition: hover ? 'width 1s' : 'width 1s, opacity 0.5s',
                    opacity: hover ? '0.9' : undefined,
                    width: Math.trunc(backgroundSize) + '%',
                }} className={classes.categoryLeft}></div>
            </div>
            <div className={classes.divider} />

            <div className={classNames(classes.categoryContainer, isLoading && classes.notAllowedCategory)}>
                <div style={{
                    backgroundColor: repricedBackgroundColor,
                    transition: hover ? 'width 1s' : 'width 1s, opacity 0.5s',
                    opacity: hover ? '0.9' : undefined,
                    width: Math.trunc(backgroundSize) + '%'
                }} className={classes.categoryRight}></div>
            </div>
            <div className={classes.chipContainer}>
                <Chip label={`${category.name} ${category.articlesCardinalities[0]}/${category.articlesCardinalities[1]}`}
                    classes={{
                        label: classes.categoryLabel,
                        root: classNames(classes.chip, isLoading && classes.notAllowedCategory)
                    }} />
            </div>
            <div className={classes.tooltipContainer}>
                <Tooltip title={<div className={classes.tooltipLabel}>{titleCurrent}</div>} arrow placement='top' followCursor>
                    <div className={classes.tooltipZone}/>
                </Tooltip>
                <Tooltip title={<div className={classes.tooltipLabel}>{titleRepriced}</div>} arrow placement='top' followCursor>
                    <div className={classes.tooltipZone}/>
                </Tooltip>
            </div>
        </div>
    );
};

DoubleCategoryBar.propTypes = {
    categoriesVisible: PropTypes.array,
    category: PropTypes.object,
    classes: PropTypes.object.isRequired,
    higherCardinality: PropTypes.number,
    isLoading: PropTypes.bool,
    selectCategory: PropTypes.func
};

export default withStyles(styles)(DoubleCategoryBar);
