import Ajax from 'core/Ajax';
import Facade from 'core/Facade';

class HttpService {

    /**
     *
     * Perform an HTTP call
     *
     */
    request(options, useToken = true) {
        const apiRoot = Facade.getApiRoot();

        // Handle authentication
        if (options.url.indexOf(apiRoot) === 0) {
            const userToken = localStorage.getItem('pricecomparator_token');
            // user authentication
            if (userToken && useToken) {
                if (!options.headers) {
                    options.headers = {};
                }
                options.headers.Authorization = 'Bearer ' + userToken;
            }
        }

        return Ajax.call(options);
    }
}

export default new HttpService();
