// Material UI
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    doNotHaveModuleText: {
        fontSize: '25px'
    },
    getModuleText: {
        fontSize: '20px',
        marginTop: '15px'
    },
    link: {
        color: theme.palette.green.dark
    },
    logoutText: {
        color: theme.palette.green.dark,
        cursor: 'pointer',
        fontSize: '15px',
        marginTop: '15px',
        textDecoration: 'underline'
    },
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        paddingTop: '10%',
        width: '100%'
    },
    smiley: {
        color: theme.palette.green.dark,
        fontSize: '160px',
        transform: 'rotate(90deg)'
    }
}));
