const styles = theme => ({
    body: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexWrap: 'wrap',
        height: 'calc(100% - 55px)',
        minHeight: '730px',
        padding: '6px',
        width: '100%'
    },
    bottomContainer: {
        display: 'flex',
        flexGrow: 1,
        height: 0
    },
    button: {
        height: '76%',
        marginTop: '8px'
    },
    datePicker: {
        marginLeft: '8px',
        maxWidth: '120px'
    },
    editPriceContainer: {// width: '30%'
    },
    footer: {
        display: 'flex'
    },
    fullPaper: {
        margin: '5px'
    },
    header: {
        display: 'flex',
        marginLeft: '10px',
        marginRight: '10px'
    },
    itemsPaper: {
        flexGrow: 1,
        height: 'calc(100% - 5px)',
        marginRight: '5px'
    },
    link: {
        textDecoration: 'none'
    },
    loaderContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    },
    midPaper: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(50% - 46px)',
        margin: '5px'
    },
    datePaper: {
        margin: '5px',
        padding: '5px'
    },
    name: {
        flexGrow: 1,
        marginLeft: '8px',
        marginRight: '8px'
    },
    paperTitle: {
        color: theme.palette.grey.dark,
        fontSize: '17px',
        fontWeight: 500,
        margin: '10px',
        textAlign: 'center'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'auto',
        width: '100%'
    },
    ruleButton: {
        marginBottom: '10px',
        marginRight: '10px',
        width: 'auto'
    },
    title: {
        alignItems: 'center',
        color: theme.palette.primary.main,
        display: 'flex',
        fontSize: '17px',
        fontWeight: 500,
        marginLeft: '11px',
        marginRight: '11px',
        marginBottom: '4px'
    },
    titleBar: {
        backgroundColor: theme.palette.primary.main,
        flexGrow: 1,
        height: '2px',
        marginLeft: '16px'
    }
});

export default styles;
