// Locales
import translate from 'locales/i18n';

export const createData = (statsByHour, theme) => {
    const values = [];
    const colors = [];
    for (let i = 0; i < 24; i++) {
        colors.push(theme.palette.primary.main);
    }

    if (statsByHour && statsByHour.length > 0) {
        statsByHour.forEach(hour => values.push(hour.cardinality));
    }
    const data = {
        datasets: [{
            backgroundColor: colors,
            borderColor: colors,
            data: values,
            hoverBackgroundColor: colors,
            hoverBorderColor: colors,
            label: 'mail(s)'
        }],
        labels: ['00h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h']
    };

    return data;
};

export const options = {
    maintainAspectRatio: false,
    borderRadius: 2,
    plugins: {
        title: {
            display: true,
            text: translate.i18n('ADBOX.MAIL_HOURS_TITLE'),
            padding: {
                bottom: 15,
                top: 8
            }
        },
        legend: {
            display: false
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            ticks: {
                display: true
            }
        },
        y: {
            grid: {
                display: false,
                drawBorder: false
            },
            ticks: {
                display: true,
                callback: (value) => Number.isInteger(value) ? value : null
            }
        }
    }
};
