import AbstractService from 'core/abstracts/AbstractService';
import Facade from 'core/Facade';

class UserService extends AbstractService {
    /**
     * Sign in with a username and password.
     */
    self() {
        return this.request({
            method: 'GET',
            url:  Facade.getApiRoot() + 'users/self',
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }

            throw await responseData.json();
        });
    }

    sendForgotPasswordEmail(email) {
        return this.request({
            method: 'POST',
            url:  Facade.getApiRoot() + `init_reset_password/${email}`,
        }, false).then(responseData => {
            if (responseData.ok) {
                return responseData.ok;
            }
            throw responseData.ok;
        });
    }

    checkResetPasswordCode(email, code) {
        return this.request({
            method: 'GET',
            url:  Facade.getApiRoot() + `reset_tokens/${code}/is-valid?email=${email}`,
        }, false).then(responseData => {
            if (responseData.ok) {
                return responseData.json();
            }
            throw responseData.ok;
        });
    }

    resetPassword(pwd, token) {
        return this.request({
            method: 'POST',
            url:  Facade.getApiRoot() + 'reset_password',
            json: {
                token,
                pwd
            }
        }, false).then(responseData => {
            if (responseData.ok) {
                return responseData.ok;
            }
            throw responseData.ok;
        });
    }

    updateUser(options, id) {
        return this.request({
            method: 'PUT',
            url:  Facade.getApiRoot() + `users/${id}`,
            json: options
        }, false).then(responseData => {
            if (responseData.ok) {
                return responseData.ok;
            }
            throw responseData.ok;
        });
    }
}

export default new UserService();
