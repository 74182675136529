import PropTypes from 'prop-types';
//React
import React, { useEffect, useState } from 'react';
//Store
import AccountStore from 'stores/AccountStore';
import ItemStore from 'stores/ItemStore';
//Actions
import RuleAction from 'actions/RuleAction';
import ItemAction from 'actions/ItemAction';
//Styles
import { useStyles } from 'components/sheet/ProductRulesSheet/ProductRulesSheet.style';
//Mui
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Gavel';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
// Locales
import translate from 'locales/i18n';
//Facade
import Facade from 'core/Facade';
import { Chip, Grid, IconButton } from '@mui/material';
//Drag and Drop
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
//Theme
import { useTheme } from '@mui/styles';
//Pcp components
import ProductRuleBlock from './ProductRuleBlock';
import DialogInfo from 'components/dialog/DialogInfo/DialogInfo';
import Loader from 'components/commons/Loader/Loader';
//Utils
import { reorder, insert, deleteAtIndex, getRankColor } from './ProductRulesSheet.util';
import RuleInfoPopper from 'components/popper/RuleInfoPopper/RuleInfoPopper';

const ProductRulesSheet = ({ product }) => {

    //Definition des variables utilisées

    const theme = useTheme();
    const classes = useStyles(height);

    const [rules, setRules] = useState([]);
    const [productRules, setProductRules] = useState([]);

    const [lastRule, setLastRule] = useState();
    const [search, setSearch] = useState('');

    const [centerDate, setCenterDate] = ItemStore.getParams().to ? useState(ItemStore.getParams().to) : useState(new Date());
    const nextDate = () => new Date(+centerDate + (1000 * 60 * 60 * 24 * 15));
    const prevDate = () => new Date(+centerDate - (1000 * 60 * 60 * 24 * 15));

    const [widthUnit, setWidthUnit] = useState(135);
    const [height, setHeight] = useState(300);

    const [delayHandler, setDelayHandler] = useState(null);
    const [requestDelay, setRequestDelay] = useState(null);
    const [hover, setHover] = useState(false);
    const [fullRule, setFullRule] = useState(null);
    const [popperAnchor, setPopperAnchor] = useState(null);

    const [showDialogInfo, setShowDialogInfo] = useState(false);

    const [displayRules, setDisplayRules] = useState(false);

    //Fonctions de récupération/update/sauvegarde des données

    useEffect(() => {

        RuleAction.getRules(AccountStore.getCurrentAccount().getId()).then(fetchedRules => {
            // GET RULES
            setRules(fetchedRules.filter(r => !product.associatedPricingEngineRules.map(rule => rule.id).some(elem => elem === r.id)));
            // GET PRODUCT RULES
            const prodRules = [];
            product.associatedPricingEngineRules.forEach(r => fetchedRules.forEach(fr => r.id === fr.id ? prodRules.push(fr) : null));
            setProductRules(prodRules);

            setDisplayRules(true);
        });
    }, []);

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId === 'libraryRules') {
            if (destination.droppableId === 'libraryRules') return;
            setProductRules(insert(productRules, destination.index, rules[source.index]));
            setRules(deleteAtIndex(rules, source.index));
            setLastRule(rules[source.index]);
        } else {
            if (destination.droppableId === 'libraryRules') {
                setProductRules(deleteAtIndex(productRules, source.index));
                setRules(insert(rules, 0, productRules[source.index]).sort((a, b) => a.name.localeCompare(b.name)));
            } else {
                setProductRules(reorder(productRules, source.index, destination.index));
            }
            setLastRule(productRules[source.index]);
        }
    };

    const save = () => {
        product.associatedPricingEngineRules = productRules.map(rule => rule.id);
        ItemAction.updateItem(product);
        setLastRule(null);
    };

    const reset = () => {
        setDisplayRules(false);
        RuleAction.getRules(AccountStore.getCurrentAccount().getId()).then(fetchedRules => {
            // GET RULES
            setRules(fetchedRules.filter(r => !product.associatedPricingEngineRules.map(rule => rule.id).some(elem => elem === r.id)));
            // GET PRODUCT RULES
            const prodRules = [];
            product.associatedPricingEngineRules.forEach(r => fetchedRules.forEach(fr => r.id === fr.id ? prodRules.push(fr) : null));
            setProductRules(prodRules);

            setDisplayRules(true);
        });
        setLastRule(null);
    };

    const getRule = (ruleId) => {
        RuleAction.getRule(ruleId).then(response => setFullRule(response));
    };

    //Fonctions de gestion d'evenements

    const handleChangeDate = (inFutur) => {
        if (inFutur) setCenterDate(new Date(+centerDate + (1000 * 60 * 60 * 24 * 15)));
        else setCenterDate(new Date(+centerDate - (1000 * 60 * 60 * 24 * 15)));
    };

    const handleMouseEnter = (event, ruleId) => {
        setPopperAnchor(event.relatedTarget);
        setDelayHandler(setTimeout(() => setHover(true), 750));
        setRequestDelay(setTimeout(() => getRule(ruleId), 250));
    };

    const handleMouseLeave = () => {
        clearTimeout(delayHandler);
        clearTimeout(requestDelay);
        setHover(false);
    };

    //Renders

    const renderDialogInfo = () =>
        <DialogInfo open={showDialogInfo} close={() => setShowDialogInfo(false)}>
            <>
                <div className={classes.dialogInfoTitle}><InfoIcon color='primary' className={classes.titleIcon} />{translate.i18n('PRICING_ENGINE_INFO.TITLE')} </div>
                <div className={classes.dialogInfoSubtitle}> <SettingsSuggestIcon className={classes.subTitleIcon} /> {translate.i18n('PRICING_ENGINE_INFO.RULE_ORDER_TITLE')} </div>
                <div className={classes.dialogInfoText} style={{ marginBottom: '50px' }}> {translate.i18n('PRICING_ENGINE_INFO.RULE_ORDER_TEXT')} </div>
                <div className={classes.dialogInfoSubtitle}> <TipsAndUpdatesOutlinedIcon className={classes.subTitleIcon} /> {translate.i18n('TIPS_N_TRICKS') + ' : '}</div>
                <div className={classes.dialogInfoText}> - &nbsp; <strong>{translate.i18n('PRICING_ENGINE_INFO.ASSOCIATE_RULE_TITLE')}</strong> {translate.i18n('PRICING_ENGINE_INFO.ASSOCIATE_RULE_TEXT')}</div>
                <div className={classes.dialogInfoText}> - &nbsp; <strong>{translate.i18n('PRICING_ENGINE_INFO.DELETE_RULE_TITLE')}</strong> {translate.i18n('PRICING_ENGINE_INFO.DELETE_RULE_TEXT')}</div>
                <div className={classes.dialogInfoText}> - &nbsp; <strong>{translate.i18n('PRICING_ENGINE_INFO.EDIT_RULE_TITLE')}</strong> {translate.i18n('PRICING_ENGINE_INFO.EDIT_RULE_TEXT')}</div>
            </>
        </DialogInfo>;

    return (
        <>
            <Paper className={classes.container} elevation={5}>
                <DragDropContext onDragEnd={result => onDragEnd(result)} onDragStart={() => clearTimeout(delayHandler)}>
                    <div className={classes.rulesContainer}>
                        {displayRules ?
                            <div className={classes.repricingRules} ref={el => el ? setHeight(el.clientHeight) : null}>
                                <div className={classes.rulesAndChipsList}>
                                    <List className={classes.rankingChips}>
                                        {productRules.map((productRule, index) => productRule &&
                                            <div key={index}>
                                                <Chip className={classes.rankChip} classes={{ label: classes.chipLabel }} label={index + 1} sx={{ backgroundColor: getRankColor(index + 1, theme) }} />
                                            </div>
                                        )}
                                    </List>
                                    <Droppable droppableId='repricingRules'>
                                        {(providedDroppable) =>
                                            <div ref={providedDroppable.innerRef} {...providedDroppable.droppableProps} className={classes.rankedRulesList} style={{ paddingLeft: (widthUnit / 8) - 60 + 'px' }}>
                                                {productRules.map((productRule, index) => productRule &&
                                                    <Draggable key={productRule.id} draggableId={productRule.id} index={index}>
                                                        {(providedDraggable, snapshot) =>
                                                            <ProductRuleBlock
                                                                providedDraggable={providedDraggable}
                                                                rule={productRule}
                                                                index={index}
                                                                widthUnit={widthUnit}
                                                                prevDate={prevDate}
                                                                isDragging={snapshot.isDragging}
                                                                draggableStyle={providedDraggable.draggableProps.style}
                                                                delayHandler={delayHandler}
                                                                setDelayHandler={setDelayHandler}
                                                            />
                                                        }
                                                    </Draggable>
                                                )}
                                                {providedDroppable.placeholder}
                                            </div>
                                        }
                                    </Droppable>
                                </div>
                                <Grid container className={classes.datePicker} ref={el => el ? setWidthUnit(el.clientWidth) : null}>
                                    <Grid item xs={1.5} className={classes.arrowBackIcon}>
                                        <IconButton onClick={() => handleChangeDate(false)}>
                                            <ArrowBackIosNewIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={3} className={classes.dateText}>
                                        {Facade.formatDate(prevDate())}
                                    </Grid>
                                    <Grid item xs={3} className={classes.dateText}>
                                        {Facade.formatDate(centerDate)}
                                    </Grid>
                                    <Grid item xs={3} className={classes.dateText}>
                                        {Facade.formatDate(nextDate())}
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <IconButton onClick={() => handleChangeDate(true)}>
                                            <ArrowForwardIosIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <div className={classes.prevDateLane} style={{ left: (widthUnit / 4) + 'px', height: height + 'px' }}></div>
                                <div className={classes.prevDateLane} style={{ left: (widthUnit / 2) + 'px', height: height + 'px', borderColor: '#00802b' }}></div>
                                <div className={classes.prevDateLane} style={{ left: 3 * (widthUnit / 4) + 'px', height: height + 'px' }}></div>
                            </div>
                            :
                            <div className={classes.loaderContainer}>
                                <Loader size={50} />
                            </div>
                        }
                        <div className={classes.libraryRules}>
                            <Paper elevation={1}
                                className={classes.libraryRulesContainer}>
                                <TextField
                                    label={translate.i18n('SEARCH')}
                                    value={search}
                                    onChange={event => setSearch(event.target.value)}
                                    variant='outlined'
                                    className={classes.searchTextField}
                                    InputProps={{
                                        startAdornment: <InputAdornment position='start'><SearchIcon className={classes.searchIcon} /></InputAdornment>
                                    }}
                                />
                                {displayRules ?
                                    <Droppable droppableId='libraryRules'>
                                        {(providedDroppable) =>
                                            <List dense={true} className={classes.libraryRulesList} {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
                                                {rules.filter(r => r.name.toLowerCase().includes(search.toLowerCase())).map((rule, index) =>
                                                    <Draggable draggableId={rule.id} key={rule.id} index={index}>
                                                        {(providedDraggable) =>
                                                            <ListItem ref={providedDraggable.innerRef} {...providedDraggable.draggableProps} {...providedDraggable.dragHandleProps}
                                                                onMouseEnter={() => handleMouseEnter(event, rule.id)}
                                                                onMouseLeave={() => handleMouseLeave()}
                                                                className={lastRule && lastRule.id === rule.id ? classes.ruleSelectedContent : classes.ruleContent}
                                                                autoFocus={lastRule && lastRule.id === rule.id}>
                                                                <ListItemAvatar>
                                                                    <Avatar>
                                                                        <FolderIcon />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    classes={{
                                                                        primary: classes.ruleNameText,
                                                                        secondary: classes.ruleDateText
                                                                    }}
                                                                    primary={<div className={classes.listPrimaryText}>{rule.name}</div>}
                                                                    disableTypography={true}
                                                                    secondary={
                                                                        <Grid container>
                                                                            <Grid item xs={4} className={classes.listSecondaryText}>
                                                                                <span className={classes.itemsAssociated}> Du : </span> {rule.applyFrom ? Facade.formatDate(new Date(rule.applyFrom)) : '-'}
                                                                            </Grid>
                                                                            <Grid item xs={4} className={classes.listSecondaryText}>
                                                                                <span className={classes.itemsAssociated}> Au : </span> {rule.applyFrom ? Facade.formatDate(new Date(rule.applyUntil)) : '-'}
                                                                            </Grid>
                                                                            <Grid item xs={4} className={classes.listSecondaryText}>
                                                                                <span className={classes.itemsAssociated}>| {`${rule.nbOfAssociatedArticles} ${translate.i18n('ITEMS')}`}</span>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                />
                                                            </ListItem>
                                                        }
                                                    </Draggable>
                                                )}
                                                {providedDroppable.placeholder}
                                            </List>
                                        }
                                    </Droppable>
                                    :
                                    <div className={classes.libraryLoaderContainer}>
                                        <Loader size={50} />
                                    </div>
                                }
                            </Paper>
                        </div>
                    </div>
                </DragDropContext>
                <div className={classes.helpText} onClick={() => setShowDialogInfo(true)}>
                    <InfoIcon color='primary' className={classes.helpIcon} />  &nbsp;  &nbsp;  {translate.i18n('HOW_IT_WORKS') + ' ' + translate.i18n('TIPS_N_TRICKS') + '?'}
                </div>
                <div className={classes.bottomButtonsContainer}>
                    <Button variant='contained' className={classes.bottomButton} disabled={!lastRule} startIcon={<ClearIcon />} color='error' onClick={() => reset()}>{translate.i18n('CANCEL')}</Button>
                    <Button variant='contained' className={classes.bottomButton} startIcon={<SaveIcon />} color='primary' onClick={() => save()}>{translate.i18n('SAVE')}</Button>
                </div>
                <RuleInfoPopper rule={fullRule ? fullRule : rules[0]} hover={hover} popperAnchor={popperAnchor} mwidth={400} />
            </Paper>
            {renderDialogInfo()}
        </>
    );
};

ProductRulesSheet.propTypes = {
    product: PropTypes.object
};

export default ProductRulesSheet;
