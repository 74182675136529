import React from 'react';
import PropTypes from 'prop-types';
// Material UI Components
import Switch from '@mui/material/Switch';
//Theme
import { useTheme } from '@mui/styles';

export const CustomSwitch = React.memo(({ handleChange, checked, checkedIcon, unCheckedIcon, checkedIconStyle, unCheckedIconStyle, trackColor, roundColor, uncheckedColor, uncheckedTrackColor }) => {
    const theme = useTheme();

    return <Switch
        checked={checked}
        onClick={handleChange}
        sx={{
            width: 48,
            height: 34,
            padding: '8px 0px 8px 0px',
            margin: '2px',
            '& .MuiSwitch-switchBase': {
                margin: '1px 1px 1px -6px',
                padding: '0px',
                transform: 'translateX(6px)',
                '&.Mui-checked': {
                    color: '#fff',
                    transform: 'translateX(24px)',
                    '& .MuiSwitch-thumb:before': {
                        left: checkedIconStyle && checkedIconStyle.left ? checkedIconStyle.left : 0,
                        top: checkedIconStyle && checkedIconStyle.top ? checkedIconStyle.top : 0,
                        backgroundImage: `url(${checkedIcon})`,
                    },
                    '& + .MuiSwitch-track': {
                        opacity: 1,
                        backgroundColor: checked ? trackColor : '#ababab85',
                    },
                },
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: checked ? roundColor : uncheckedColor? uncheckedColor:theme.palette.grey.main,
                width: 30,
                height: 30,
                '&:before': {
                    // eslint-disable-next-line
                    content: "''",
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    left: unCheckedIconStyle && unCheckedIconStyle.left ? unCheckedIconStyle.left : 0,
                    top: unCheckedIconStyle && unCheckedIconStyle.top ? unCheckedIconStyle.top : 0,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `url(${unCheckedIcon})`,
                },
            },
            '& .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: checked ? trackColor : uncheckedTrackColor? uncheckedTrackColor:'#ababab85',
                borderRadius: 20 / 2,
            },
        }}
    />;
}, (prevProps, nextProps) => {
    if (prevProps.checked !== nextProps.checked) return false;
    return true;
});

CustomSwitch.propTypes = {
    checked: PropTypes.bool,
    checkedIcon: PropTypes.string,
    checkedIconStyle: PropTypes.object,
    handleChange: PropTypes.func,
    roundColor: PropTypes.string,
    trackColor: PropTypes.string,
    unCheckedIcon: PropTypes.string,
    unCheckedIconStyle: PropTypes.object,
    uncheckedColor: PropTypes.string,
    uncheckedTrackColor: PropTypes.string
};
