// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import classNames from 'classnames';
// Locales
import translate from 'locales/i18n';
// Stores
import AccountStore from 'stores/AccountStore';
// Material UI Components
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import IconRefresh from '@mui/icons-material/Cached';
// Chart
import ChartDataLabels from 'chartjs-plugin-datalabels';
// Util
import { getOptions, createData } from 'components/filter/price/PriceBasketsFilter/PriceBasketsFilter.util';
// Styles
import { useStyles } from 'components/filter/price/PriceBasketsFilter/PriceBasketsFilter.style';
// Theme
import { useTheme } from '@emotion/react';
// PCP Components
import DialogInfo from 'components/dialog/DialogInfo/DialogInfo';
// Images
import PriceBasketsFilterExampleRetailer from 'assets/img/price-baskets-filter-example-retailer.png';
import PriceBasketsFilterExampleSupplier from 'assets/img/price-baskets-filter-example-supplier.png';

const PriceBasketsFilter = React.memo(({ isLoading, pricesBaskets, pricesBasketsSelected, reset, selectPricesBaskets }) => {
    const [dialogInfoOpen, setDialogInfoOpen] = useState(false);
    const classes = useStyles();
    const theme = useTheme();

    if (pricesBaskets.length === 0)
        return (
            <Paper className={classNames(classes.paper, isLoading && classes.notAllowed)}>
            </Paper>
        );

    const renderDialogInfo = () => <DialogInfo open={dialogInfoOpen}
        close={() => setDialogInfoOpen(false)}>
        <div className={classes.infoMainText}>{AccountStore.getCurrentAccount().isRetailer() ? translate.i18n('PRICE_BASKETS_FILTER_1_RETAILER') : translate.i18n('PRICE_BASKETS_FILTER_1_SUPPLIER')}</div>
        <ul>
            <li className={classes.infoSubText}>{translate.i18n('PRICE_BASKETS_FILTER_2')}</li>
            <li className={classes.infoSubText}>{AccountStore.getCurrentAccount().isRetailer() ? translate.i18n('PRICE_BASKETS_FILTER_3_RETAILER') : translate.i18n('PRICE_BASKETS_FILTER_3_SUPPLIER')}</li>
        </ul>
        <div className={classes.infoImgContainer}><img src={AccountStore.getCurrentAccount().isRetailer() ? PriceBasketsFilterExampleRetailer : PriceBasketsFilterExampleSupplier} /></div>
    </DialogInfo>;

    return (
        <Paper className={classNames(classes.paper, isLoading && classes.notAllowed)}>
            <div className={classes.graphContainer}>
                <Bar
                    plugins={[ChartDataLabels]}
                    data={createData(pricesBaskets, pricesBasketsSelected, AccountStore.getCurrentAccount(), theme)}
                    getElementAtEvent={element => {
                        if (element.length !== 0 && !isLoading)
                            selectPricesBaskets(pricesBaskets[element[0].index].id);
                    }}
                    options={getOptions(pricesBaskets, isLoading, AccountStore.getCurrentAccount())}
                />
            </div>
            <div className={classes.ticks}><p className={classes.tooExpensiveLabel}>{translate.i18n('CHEAPEST')}</p><p className={classes.tooCheapLabel}>{translate.i18n('MORE_EXPENSIVE')}</p></div>
            <IconButton
                color='primary'
                onClick={reset}
                className={classes.resetButton}>
                <IconRefresh />
            </IconButton>
            <IconButton
                color='primary'
                onClick={() => setDialogInfoOpen(true)}
                className={classes.infoButton}>
                <InfoIcon />
            </IconButton>
            {renderDialogInfo()}
        </Paper>
    );
}, (prevProps, nextProps) => {
    if (prevProps.isLoading !== nextProps.isLoading || prevProps.pricesBasketsSelected !== nextProps.pricesBasketsSelected) {
        return false;
    }
    return true;
});

PriceBasketsFilter.propTypes = {
    isLoading: PropTypes.bool,
    pricesBaskets: PropTypes.array,
    pricesBasketsSelected: PropTypes.array,
    reset: PropTypes.func,
    selectPricesBaskets: PropTypes.func
};

export default PriceBasketsFilter;
