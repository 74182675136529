import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';
// Caroussel
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// Facade
import Facade from 'core/Facade';
// Stores
import UserStore from 'stores/UserStore';
import ItemStore from 'stores/ItemStore';
import AccountStore from 'stores/AccountStore';
import LayoutStore from 'stores/LayoutStore';
// Actions
import ItemAction from 'actions/ItemAction';
import LayoutAction from 'actions/LayoutAction';
// Locales
import translate from 'locales/i18n';
// PCP components
import CustomTablePagination from 'components/pagination/CustomTablePagination';
import CategoryFilter from 'components/filter/price/CategoryFilter/CategoryFilter';
import MapFilter from 'components/filter/price/MapFilter/MapFilter';
import PriceBasketsFilter from 'components/filter/price/PriceBasketsFilter/PriceBasketsFilter';
import LeadershipFilter from 'components/filter/price/LeadershipFilter/LeadershipFilter';
//import DialogCreateItem from 'components/dialog/DialogCreateItem/DialogCreateItem';
import DialogAddProduct from 'components/dialog/DialogAddProduct/DialogAddProduct';
import DialogExportData from 'components/dialog/DialogExportData/DialogExportData';
import DialogMarketplaceReports from 'components/dialog/DialogMarketplaceReports/DialogMarketplaceReports';
import PriceTable from 'components/table/PriceTable/PriceTable';
import DialogAddUrl from 'components/dialog/DialogAddUrl/DialogAddUrl';
import Loader from 'components/commons/Loader/Loader';
import DialogMarketplace from 'components/dialog/DialogMarketplace/DialogMarketplace';
import DialogUpdateScrapedData from 'components/dialog/DialogUpdateScrapedData/DialogUpdateScrapedData';
import StockFilter from 'components/filter/price/StockFilter/StockFilter';
import { CustomSwitch } from 'components/commons/CustomSwitch/CustomSwitch';
// Material UI Components
import withStyles from '@mui/styles/withStyles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import MarketplaceIcon from '@mui/icons-material/Storefront';
import ExportIcon from '@mui/icons-material/CloudDownload';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Table } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import IconRefresh from '@mui/icons-material/Cached';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// Styles
import styles from 'components/pages/SmartPriceExtractorPage/SmartPriceExtractorPage.style';
// Theme
import theme from 'assets/theme/theme';
// SVG
import cameraIcon from 'assets/img/svg/camera.svg';
import barChartIcon from 'assets/img/svg/bar_chart.svg';
import boxIcon from 'assets/img/svg/box.svg';
import ratingStarIcon from 'assets/img/svg/rating_star_24.svg';
import euroIcon from 'assets/img/svg/euro.svg';
import tricoloreIcon from 'assets/img/svg/feu_tricolore.svg';
import blueShadesIcon from 'assets/img/svg/blue_shades_tricolore.svg';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 2500 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 2500, min: 1300 },
        items: 4
    },
    smallDesktop: {
        breakpoint: { max: 1300, min: 1000 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1000, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

class SmartPriceExtractorPage extends React.Component {

    state = {
        categories: ItemStore.getCategories(),
        countItems: ItemStore.getCount(),
        currentItem: null,
        currentScrapingData: null,
        currentWebsite: null,
        dialogAddUrlOpen: false,
        dialogMarketplaceOffers: [],
        dialogMarketplaceReportOpen: false,
        dialogMarketplaceOpen: false,
        dialogUpdateScrapedDataOpen: false,
        displayWebsites: true,
        exportError: ItemStore.getExportError(),
        hideFilters: Facade.getUrlParam('hide_filters') ? Facade.getUrlParam('hide_filters') === 'true' : false,
        hideStocks: Facade.getUrlParam('hide_stocks') ? Facade.getUrlParam('hide_stocks') === 'true' : false,
        hideImages: Facade.getUrlParam('hide_images') ? Facade.getUrlParam('hide_images') === 'true' : true,
        hideNotations: Facade.getUrlParam('hide_notations') ? Facade.getUrlParam('hide_notations') === 'true' : true,
        hidePrices: Facade.getUrlParam('hide_prices') ? Facade.getUrlParam('hide_prices') === 'true' : false,
        isLoadingExport: ItemStore.getLoadingExport(),
        isSearchLoading: ItemStore.isLoading(),
        items: ItemStore.getItems(),
        marginTableItem: 0,
        newSearchText: '',
        openDialogAddItem: false,
        dialogExportDataOpen: false,
        params: ItemStore.getParams(),
        pricesBaskets: ItemStore.getPricesBaskets(),
        websites: ItemStore.getWebsites(),
        value: [null, null],
        isBlue: LayoutStore.getColor(),
        avgEvaluation: ItemStore.getAvgEvaluation()
    };

    static propTypes = {
        classes: PropTypes.object,
    };

    componentDidMount() {
        ItemStore.addChangeListener(this.onItemStoreChange);
        LayoutStore.addChangeListener(this.onLayoutStoreChange);

        if (UserStore.isLogged()) Facade.setAllUrlParams(this.state.params);

        if (UserStore.isLogged())
            this.searchItems();

        this.mounted = true;
    }

    componentWillUnmount() {
        ItemStore.removeChangeListener(this.onItemStoreChange);
        LayoutStore.removeChangeListener(this.onLayoutStoreChange);
        this.mounted = false;
    }

    componentDidUpdate() {
        const { hideImages, hideNotations, hideStocks, hidePrices } = this.state;

        if (hideImages && hideNotations && hideStocks && hidePrices) {
            this.setState({ hidePrices: false });
        }
    }

    /*
     * Store change methods
     */
    onItemStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            categories: ItemStore.getCategories(),
            countItems: ItemStore.getCount(),
            exportError: ItemStore.getExportError(),
            isLoadingExport: ItemStore.getLoadingExport(),
            isSearchLoading: ItemStore.isLoading(),
            items: ItemStore.getItems().slice(0),
            params: ItemStore.getParams(),
            pricesBaskets: ItemStore.getPricesBaskets(),
            websites: ItemStore.getWebsites(),
            avgEvaluation: ItemStore.getAvgEvaluation()
        }, () => Facade.setAllUrlParams(this.state.params));
    };

    onLayoutStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({ isBlue: LayoutStore.getColor() });
    };

    /*
     * handle change methods
     */

    selectWebsite = (websiteId) => {
        const newParams = this.state.params;
        let { displayWebsites } = this.state;
        newParams.pageIndex = 1;

        if (websiteId === 'all') {
            if (this.state.params.websites.length !== 0 || !displayWebsites) {
                newParams.websites = [];
                displayWebsites = true;
            } else {
                displayWebsites = false;
            }
        } else {
            if (this.state.params.websites.length === 0 && displayWebsites) {
                this.state.websites.map(website => website.id !== websiteId ? newParams.websites.push(website.id) : null);
                displayWebsites = true;
            } else if (newParams.websites.indexOf(websiteId) === -1) {
                newParams.websites.length === this.state.websites.length - 1 ? newParams.websites = [] : newParams.websites.push(websiteId);
                displayWebsites = true;
            } else {
                newParams.websites.splice(newParams.websites.indexOf(websiteId), 1);
                if (newParams.websites.length === 0) {
                    displayWebsites = false;
                } else {
                    displayWebsites = true;
                }
            }
        }

        this.setState({
            displayWebsites,
            params: newParams
        });

        this.searchItemsWithTimeout();

    };

    selectCategory = (categoryId) => {
        const newParams = this.state.params;
        newParams.pageIndex = 1;

        if (newParams.categories.indexOf(categoryId) === -1) {
            newParams.categories.push(categoryId);
        } else {
            newParams.categories.splice(newParams.categories.indexOf(categoryId), 1);
        }

        this.setState({
            params: newParams
        });

        this.searchItemsWithTimeout();
    };

    selectPricesBaskets = (pricesBasketsId) => {
        const newParams = this.state.params;
        newParams.pageIndex = 1;

        if (newParams.pricesBaskets.indexOf(pricesBasketsId) === -1) {
            newParams.pricesBaskets.push(pricesBasketsId);
        } else {
            newParams.pricesBaskets.splice(newParams.pricesBaskets.indexOf(pricesBasketsId), 1);
        }

        this.setState({
            params: newParams
        });

        this.searchItemsWithTimeout();
    };

    selectLeadership = (leaderships) => {
        const newParams = this.state.params;
        newParams.pageIndex = 1;
        leaderships.map((leadership) => {
            let isInTab = false;
            newParams.leadership.map((l, key) => {
                if (l[0] === leadership[0] && l[1] === leadership[1]) {
                    newParams.leadership.splice(key, 1);
                    isInTab = true;
                }

                return true;
            });

            if (!isInTab) {
                newParams.leadership.push(leadership);
            }

            return true;
        });

        this.setState({
            params: newParams
        });

        this.searchItemsWithTimeout();
    };

    selectStocksBaskets = (stocksBasketsId, websiteId) => {
        const newParams = this.state.params;
        newParams.pageIndex = 1;
        let isInTab = false;

        newParams.stocksBaskets.map((stocksBaskets, key) => {
            if (stocksBaskets[0] === stocksBasketsId && stocksBaskets[1] === websiteId) {
                newParams.stocksBaskets.splice(key, 1);
                isInTab = true;
            }

            return true;
        });

        if (!isInTab)
            newParams.stocksBaskets.push([stocksBasketsId, websiteId]);

        this.setState({
            params: newParams
        });

        this.searchItemsWithTimeout();
    };

    handleChangeSort = value => {
        const newParams = this.state.params;
        newParams.sort = value;
        newParams.pageIndex = 1;

        this.setState({
            params: newParams
        }, () => {
            this.searchItems();
        });
    };

    handleChangeSearchText = event => {
        const newParams = this.state.params;
        newParams.searchText = event.target.value;
        newParams.pageIndex = 1;

        this.setState({
            params: newParams
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.searchItems();
        }
    };

    handleDates = ([from, to]) => {
        if (from && to && (from.getTime() !== this.state.params.from.getTime() || to.getTime() !== this.state.params.from.getTime())) {
            const newParams = this.state.params;
            newParams.from = from;
            newParams.to = to;

            this.setState({ params: newParams }, () => {
                this.searchItems();
            });
        }
    };

    handleChangePage = (event, page) => {
        const newParams = this.state.params;
        newParams.pageIndex = page + 1;

        this.setState({
            params: newParams
        }, () => {
            this.searchItems();
        });
    };

    handleChangeRowsPerPage = event => {
        const newParams = this.state.params;

        if (this.state.countItems < event.target.value * newParams.pageIndex) {
            newParams.pageIndex = Math.ceil(this.state.countItems / event.target.value);
        } else {
            const newPageIndex = Math.ceil((newParams.pageIndex - 1) * newParams.pageSize / event.target.value);
            if (newPageIndex > 0)
                newParams.pageIndex = newPageIndex;
        }

        newParams.pageSize = event.target.value;

        this.setState({
            params: newParams
        }, () => {
            this.searchItems();
        });
    };

    handleChangeMode = event => {
        const newParams = this.state.params;
        newParams.mode = event.target.value;

        this.setState({
            params: newParams
        }, () => {
            this.searchItems();
        });
    };

    /*
     * search item method
     */

    getCurrentSearchOptions = () => {
        const accountId = AccountStore.getCurrentAccount().getId();

        return {
            accountId,
            categories: this.state.params.categories,
            from: this.state.params.from,
            leadership: this.state.params.leadership,
            mode: this.state.params.mode,
            pageIndex: this.state.params.pageIndex,
            pageSize: this.state.params.pageSize,
            pricesBaskets: this.state.params.pricesBaskets,
            searchText: this.state.params.searchText,
            sort: this.state.params.sort,
            stocksBaskets: this.state.params.stocksBaskets,
            to: this.state.params.to,
            websites: this.state.params.websites
        };
    };

    searchItemsWithTimeout = () => {
        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            ItemAction.search(this.getCurrentSearchOptions());
        }, 800);
    };

    searchItems = () => {
        ItemAction.search(this.getCurrentSearchOptions());
    };

    resetFilters = () => {
        Facade.deleteAllUrlParams();
        ItemAction.reset({
            pageSize: this.state.params.pageSize,
            mode: this.state.params.mode
        });
    };

    /*
     * methods and render for dialogs
     */

    closeDialog = () => {
        this.setState({
            currentItem: null,
            currentWebsite: null,
            dialogAddUrlOpen: false,
            dialogMarketplaceOpen: false,
            dialogUpdateScrapedDataOpen: false,
            openDialogAddItem: false,
            dialogExportDataOpen: false,
            dialogMarketplaceReportOpen: false
        });
    };

    openDialogAddItem = () => {
        this.setState({ openDialogAddItem: true });
    };

    openDialogExportData = () => {
        this.setState({ dialogExportDataOpen: true });
    };

    openDialogAddUrl = (scrapingData, website, item) => {
        this.setState({
            currentItem: item,
            currentScrapingData: scrapingData,
            currentWebsite: website,
            dialogAddUrlOpen: true
        });
    };

    openDialogUpdateScrapedData = (scrapingData, website, item) => {
        this.setState({
            currentItem: item,
            currentScrapingData: scrapingData,
            currentWebsite: website,
            dialogUpdateScrapedDataOpen: true
        });
    };

    openDialogMarketPlace = (item, website) => {
        this.setState({
            currentItem: item,
            currentWebsite: website,
            dialogMarketplaceOpen: true
        });
    };

    /* RENDERS */
    renderDialogCreateItem = () => <DialogAddProduct
        open={this.state.openDialogAddItem}
        onClose={this.closeDialog} />;

    renderDialogAddUrl() {
        if (!this.state.currentItem || !this.state.currentWebsite || !this.state.currentScrapingData) return null;

        const website = AccountStore.getCurrentAccount().priceMonitoring.sites.find(s => s.id === this.state.currentWebsite.id);

        return (
            <DialogAddUrl
                close={this.closeDialog}
                open={this.state.dialogAddUrlOpen}
                displayUrlField={true}
                scrapingData={this.state.currentScrapingData}
                item={this.state.currentItem}
                website={website}
                accountSiteUrl={this.state.currentWebsite.url}
            />
        );
    }

    renderDialogMarketplace() {
        return (
            <DialogMarketplace
                close={this.closeDialog}
                open={this.state.dialogMarketplaceOpen}
                item={this.state.currentItem}
                website={this.state.currentWebsite}
            />
        );
    }

    renderDialogUpdateScrapedData() {
        if (!this.state.currentItem || !this.state.currentWebsite || !this.state.currentScrapingData) return null;

        return (
            <DialogUpdateScrapedData
                close={this.closeDialog}
                open={this.state.dialogUpdateScrapedDataOpen}
                item={this.state.currentItem}
                website={this.state.currentWebsite}
                scrapingData={this.state.currentScrapingData}
                key={this.state.currentItem.id}
            />
        );
    }

    renderPriceTable() {
        const { classes } = this.props;
        const { displayWebsites, hideStocks, hideImages, isSearchLoading, items, params, websites, hideNotations, hidePrices, avgEvaluation } = this.state;

        if (!displayWebsites)
            return null;

        return (
            <SimpleBar className={classes.tablePriceContainer}>
                <PriceTable
                    items={items}
                    openDialogAddUrl={this.openDialogAddUrl}
                    websites={websites}
                    isLoading={isSearchLoading || websites.length < 1}
                    websitesSelected={params.websites}
                    openDialogMarketPlace={this.openDialogMarketPlace}
                    openDialogUpdateScrapedData={this.openDialogUpdateScrapedData}
                    mode={params.mode}
                    handleChangeSort={this.handleChangeSort}
                    hideStocks={hideStocks}
                    hideImages={hideImages}
                    hideNotations={hideNotations}
                    hidePrices={hidePrices}
                    avgEvaluation={avgEvaluation}
                />
            </SimpleBar>

        );
    }

    renderTable() {
        const { classes } = this.props;
        const { websites, hideFilters, isSearchLoading, items } = this.state;

        if (websites.length === 0) {
            return (
                <div className={classNames(classes.progressContainer, !hideFilters && classes.minifiedProgressContainer)}>
                    <Loader size={50} />
                </div>
            );
        }

        if (items.length === 0) {
            return (
                <div className={classNames(classes.noItemFound, !hideFilters && classes.minifiedNoItemFound)}>
                    {translate.i18n('NO_ITEM_FOUND')}
                </div>
            );
        }

        return (
            <Paper className={classNames(classes.paper, (!hideFilters && !isSearchLoading) && classes.minifiedPaper, isSearchLoading && classes.progressContainer, (!this.state.hideFilters && this.state.isSearchLoading) && classes.minifiedProgressContainer)} ref={this.nav}>
                {this.renderPriceTable()}
                {this.renderDialogAddUrl()}
                {this.renderDialogMarketplace()}
                {this.renderDialogUpdateScrapedData()}
            </Paper>
        );
    }

    renderDialogExportData = () =>
        <DialogExportData
            open={this.state.dialogExportDataOpen}
            close={this.closeDialog}
            exportData={(options) => ItemAction.exportItems(options, this.getCurrentSearchOptions())}
            isLoadingExport={this.state.isLoadingExport}
            exportError={this.state.exportError} />;

    renderDialogMarketplaceReports = () =>
        <DialogMarketplaceReports
            open={this.state.dialogMarketplaceReportOpen}
            close={this.closeDialog} />;

    renderPagination() {
        const { params, countItems } = this.state;
        const { classes } = this.props;

        return (
            <Table className={classes.tablePagination}>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            className={classes.pagination}
                            colSpan={3}
                            count={countItems}
                            rowsPerPage={params.pageSize}
                            page={countItems > 0 ? parseInt(params.pageIndex - 1) : 0}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                            labelRowsPerPage={translate.i18n('ROW_PER_PAGE')}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            labelDisplayedRows={({ from, to, count }) => from + '-' + to + ' ' + translate.i18n('OF') + ' ' + count}
                            ActionsComponent={CustomTablePagination}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        );
    }

    render() {
        const { classes } = this.props;
        const { params, isSearchLoading, hideFilters, hideStocks, hideImages, websites, displayWebsites, categories, pricesBaskets, hideNotations, hidePrices, isBlue } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.pricePageContainer}>
                    <div className={classes.filtersTopContainer}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateRangePicker
                                inputFormat={'dd/MM/yyyy'}
                                startText={translate.i18n('FROM')}
                                endText={translate.i18n('TO')}
                                value={[params.from, params.to]}
                                onChange={this.handleDates}
                                maxDate={new Date()}
                                renderInput={(startProps, endProps) =>
                                    <React.Fragment>
                                        <TextField {...startProps} size='small' classes={{ root: classes.datePicker }} />
                                        <TextField {...endProps} size='small' classes={{ root: classes.datePicker }} />
                                    </React.Fragment>
                                }
                            />
                        </LocalizationProvider>
                        <TextField
                            id='searchfield'
                            className={classes.searchBar}
                            disabled={isSearchLoading}
                            variant='outlined'
                            value={params.searchText}
                            onChange={this.handleChangeSearchText}
                            onKeyPress={this.handleKeyPress}
                            placeholder={'Article name, EAN, etc...'}
                            // margin={'dense'}
                            size='small'
                            InputProps={{
                                startAdornment: <InputAdornment position='start'><SearchIcon className={classes.searchIcon} /></InputAdornment>
                            }}
                        />
                        <FormControl variant='outlined'
                            size='small'
                            disabled={isSearchLoading}
                            classes={{
                                root: classes.formControlMode
                            }}>
                            <InputLabel>
                                {translate.i18n('MODE')}
                            </InputLabel>
                            <Select
                                label={translate.i18n('MODE')}
                                value={params.mode}
                                onChange={this.handleChangeMode}
                            >
                                <MenuItem value={'listing'}>{translate.i18n('LISTING')}</MenuItem>
                                <MenuItem value={'reactivity'}>{translate.i18n('REACTIVITY')}</MenuItem>
                                <MenuItem value={'price_movements'}>{translate.i18n('PRICE_MOVEMENTS')}</MenuItem>
                                <MenuItem value={'stock_movements'}>{translate.i18n('STOCK_MOVEMENTS')}</MenuItem>
                                {AccountStore.getCurrentAccount().isRetailer() ?
                                    <MenuItem value={'pricing_advising'}>{translate.i18n('PRICE_ADVISING')}</MenuItem>
                                    :
                                    null
                                }
                            </Select>
                        </FormControl>
                        <IconButton
                            onClick={this.resetFilters}
                            color='primary'
                            title={translate.i18n('RESET_FILTERS')}
                            disabled={isSearchLoading}
                            size='large'>
                            <IconRefresh />
                        </IconButton>
                    </div>
                    <div className={classes.filtersTitle}>
                        <div>
                            {translate.i18n('FILTERS')}
                        </div>
                        <div>
                            <CustomSwitch
                                handleChange={() => LayoutAction.changeColors()}
                                checked={isBlue}
                                checkedIconStyle={{
                                    top: '0px',
                                    left: '0px',
                                }}
                                unCheckedIconStyle={{
                                    top: '0px',
                                    left: '0px',
                                }}
                                checkedIcon={blueShadesIcon}
                                unCheckedIcon={tricoloreIcon}
                                trackColor={'#1890a552'}
                                roundColor={theme().palette.primary.main}
                                uncheckedColor={'#B5DBE2'}
                                uncheckedTrackColor={'#188FA4'}
                            />
                            <CustomSwitch
                                handleChange={() => {
                                    this.setState({ hideFilters: !hideFilters });
                                    Facade.setUrlParam('hide_filters', !hideFilters);
                                }}
                                checked={!hideFilters}
                                checkedIconStyle={{
                                    top: '0px',
                                    left: '0px',
                                }}
                                unCheckedIconStyle={{
                                    top: '0px',
                                    left: '0px',
                                }}
                                checkedIcon={barChartIcon}
                                unCheckedIcon={barChartIcon}
                                trackColor={'#1890a552'}
                                roundColor={theme().palette.primary.main}
                            />
                        </div>
                    </div>
                    <div className={classNames(classes.filtersBottomContainer, hideFilters && classes.hide)}>
                        <Carousel
                            swipeable={true}
                            draggable={false}
                            responsive={responsive}
                            keyBoardControl={true}
                            transitionDuration={300}
                            removeArrowOnDeviceType={['mobile']}
                            itemClass={classes.itemClass}
                            sliderClass={classes.sliderClass}
                            containerClass={classes.containerClass}
                            autoPlay={false}
                            shouldResetAutoplay={false}
                        >
                            <MapFilter websites={websites}
                                websitesSelected={params.websites}
                                selectWebsite={this.selectWebsite}
                                reset={() => {
                                    this.setState({ params: Object.assign(params, { websites: [] }) }, () => this.searchItems());
                                    Facade.deleteUrlParam('websites');
                                }}
                                displayWebsites={displayWebsites}
                                isLoading={isSearchLoading || websites.length < 1}
                                hidden={hideFilters} />
                            <CategoryFilter categoriesStats={categories}
                                selectCategory={this.selectCategory}
                                reset={() => {
                                    this.setState({ params: Object.assign(params, { categories: [] }) }, () => this.searchItems());
                                    Facade.deleteUrlParam('categories');
                                }}
                                categoriesVisible={params.categories}
                                isLoading={isSearchLoading || websites.length < 1} />
                            <PriceBasketsFilter
                                pricesBaskets={pricesBaskets}
                                selectPricesBaskets={this.selectPricesBaskets}
                                reset={() => {
                                    this.setState({ params: Object.assign(params, { pricesBaskets: [] }) }, () => this.searchItems());
                                    Facade.deleteUrlParam('pricesBaskets');
                                }}
                                pricesBasketsSelected={params.pricesBaskets}
                                isLoading={isSearchLoading || websites.length < 1} />
                            <LeadershipFilter
                                websites={websites}
                                selectLeadership={this.selectLeadership}
                                reset={() => {
                                    this.setState({ params: Object.assign(params, { leadership: [] }) }, () => this.searchItems());
                                    Facade.deleteUrlParam('leadership');
                                }}
                                isLoading={isSearchLoading || websites.length < 1} />
                            <StockFilter
                                websites={websites}
                                selectStocksBaskets={this.selectStocksBaskets}
                                reset={() => {
                                    this.setState({ params: Object.assign(params, { stocksBaskets: [] }) }, () => this.searchItems());
                                    Facade.deleteUrlParam('stocksBaskets');
                                }}
                                isLoading={isSearchLoading || websites.length < 1} />
                        </Carousel>
                    </div>
                    <div className={classes.listingTitle}>
                        <div>
                            {translate.i18n('LIST_OF_PRODUCTS')}
                        </div>
                        <div>
                            <CustomSwitch
                                handleChange={() => {
                                    this.setState({ hideImages: !hideImages });
                                    Facade.setUrlParam('hide_images', !hideImages);
                                }}
                                checked={!hideImages}
                                checkedIconStyle={{
                                    top: '0px',
                                    left: '0px',
                                }}
                                unCheckedIconStyle={{
                                    top: '0px',
                                    left: '0px',
                                }}
                                checkedIcon={cameraIcon}
                                unCheckedIcon={cameraIcon}
                                trackColor={'#1890a552'}
                                roundColor={theme().palette.primary.main} />
                            <CustomSwitch
                                handleChange={() => {
                                    this.setState({ hideNotations: !hideNotations });
                                    Facade.setUrlParam('hide_notations', !hideNotations);
                                }}
                                checked={!hideNotations}
                                checkedIconStyle={{
                                    top: '2px',
                                    left: '2px',
                                }}
                                unCheckedIconStyle={{
                                    top: '2px',
                                    left: '2px',
                                }}
                                checkedIcon={ratingStarIcon}
                                unCheckedIcon={ratingStarIcon}
                                trackColor={'#1890a552'}
                                roundColor={theme().palette.primary.main} />
                            <CustomSwitch
                                handleChange={() => {
                                    this.setState({ hideStocks: !hideStocks });
                                    Facade.setUrlParam('hide_stocks', !hideStocks);
                                }}
                                checked={!hideStocks}
                                checkedIconStyle={{
                                    top: '1px',
                                    left: '1px',
                                }}
                                unCheckedIconStyle={{
                                    top: '1px',
                                    left: '1px',
                                }}
                                checkedIcon={boxIcon}
                                unCheckedIcon={boxIcon}
                                trackColor={'#1890a552'}
                                roundColor={theme().palette.primary.main} />
                            <CustomSwitch
                                handleChange={() => {
                                    this.setState({ hidePrices: !hidePrices });
                                    Facade.setUrlParam('hide_prices', !hidePrices);
                                }}
                                checked={!hidePrices}
                                checkedIconStyle={{
                                    top: '3px',
                                    left: '1px',
                                }}
                                unCheckedIconStyle={{
                                    top: '3px',
                                    left: '1px',
                                }}
                                checkedIcon={euroIcon}
                                unCheckedIcon={euroIcon}
                                trackColor={'#1890a552'}
                                roundColor={theme().palette.primary.main} />
                        </div>
                    </div>
                    {this.renderTable()}
                    <div className={classes.footer}>
                        {this.renderPagination()}
                        {UserStore.getCurrentUser().isScrapingDataEditor() ?
                            <Button color='primary'
                                size='small'
                                style={{ marginLeft: 'auto' }}
                                classes={{
                                    root: classes.buttonBottom
                                }}
                                onClick={this.openDialogAddItem}>
                                <AddIcon className={classes.leftIcon} />
                                <div className={classes.hideLabel}>{translate.i18n('ADD_ITEM')}</div>
                            </Button>
                            :
                            null
                        }
                        <Button color='primary'
                            size='small'
                            classes={{
                                root: classes.buttonBottom
                            }}
                            disabled={!AccountStore.getCurrentAccount().getModules().mp}
                            onClick={() => this.setState({ dialogMarketplaceReportOpen: true })}>
                            <MarketplaceIcon className={classes.leftIcon} />
                            <div className={classes.hideLabel}>{translate.i18n('MARKETPLACE_REPORTS')}</div>
                        </Button>
                        <Button color='primary'
                            size='small'
                            classes={{
                                root: classes.buttonBottom
                            }}
                            onClick={this.openDialogExportData}>
                            <ExportIcon className={classes.leftIcon} />
                            <div className={classes.hideLabel}>{translate.i18n('EXPORT_DATA')}</div>
                        </Button>
                    </div>
                </div>
                {this.renderDialogCreateItem()}
                {this.renderDialogExportData()}
                {AccountStore.getCurrentAccount().getModules().mp && this.renderDialogMarketplaceReports()}
            </div>
        );
    }
}

export default withStyles(styles)(SmartPriceExtractorPage);
