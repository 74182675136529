// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Encryptage
import { sha256 } from 'js-sha256';
// Translate
import translate from 'locales/i18n';
// Actions
import AlertAction from 'actions/AlertAction';
// Services
import SignService from 'services/SignService';
import UserService from 'services/UserService';
import AccountService from 'services/AccountService';

class SignAction {

    /**
      * Method called to sign in
      *
      * @param login <string> the login
      * @param password <string> the password
      * @returns <Promise> of delete item request
      */
    signIn(login, password) {
        const credentials = {
            login,
            password: sha256(password)
        };

        Dispatcher.dispatch({
            actionType: Constants.USER_LOGIN_START,
            value: credentials
        });

        return SignService.getToken(credentials).then(data => {
            this._setToken(data);
            return UserService.self();
        }, err => {
            this._onSignInError();
            throw err;
        }).then(user => {
            if (user.associated_accounts.length === 0) {
                throw {
                    error: 'NO_ASSOCIATED_ACCOUNTS'
                };
            }

            this.onSignIn(user);
        }, err => {
            throw err;
        });
    }

    /**
      * Method called to sign in
      *
      * @param forceLogout <boolean> to know if the user is disconnected manually or automatically
      */
    logout(forceLogout) {
        if (!forceLogout) {
            SignService.logout().then(() => {
                localStorage.removeItem('pricecomparator_token');
                localStorage.removeItem('pricing_engine_tutorial');
                Dispatcher.dispatch({
                    actionType: Constants.USER_LOGOUT
                });
                AlertAction.open('success', translate.i18n('USER_DISCONNECTED'), 'top-right');
            });
        }

        if (forceLogout) {
            localStorage.removeItem('pricecomparator_token');
            localStorage.removeItem('pricing_engine_tutorial');
            Dispatcher.dispatch({
                actionType: Constants.USER_LOGOUT
            });
            AlertAction.open('error', translate.i18n('SESSION_EXPIRED'), 'top-right');
        }
    }

    /**
      * Method called on sign in to set up local storage and log in user
      *
      * @param user <object> the connected user
      */
    onSignIn(user) {
        let accountId = user.associated_accounts[0].id;
        if (localStorage.getItem('pricecomparator_account')) {
            accountId = user.associated_accounts.find(a => a.id === localStorage.getItem('pricecomparator_account')) ? localStorage.getItem('pricecomparator_account') : accountId;
            localStorage.removeItem('pricecomparator_account');
            localStorage.setItem('pricecomparator_account', accountId);
        }

        AccountService.getAccount(accountId).then(data => {
            Dispatcher.dispatch({
                actionType: Constants.USER_LOGIN,
                value: {
                    account: data,
                    user
                }
            });
        }, response => (response && response.error) && AlertAction.open('error', translate.i18n(response.error), 'top-right'));
    }

    /**
      * Method called to set token on local storage
      *
      * @param { token } data <object> the data of the connected user
      */
    _setToken(data) {
        localStorage.setItem('pricecomparator_token', data.token);
    }

    /**
      * Method called on sign in error
      */
    _onSignInError() {
        Dispatcher.dispatch({
            actionType: Constants.USER_LOGIN_FAILED
        });
    }
}

export default new SignAction();
