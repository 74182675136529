// Facade
import Facade from 'core/Facade';
// Locales
import translate from 'locales/i18n';

/**
  * Method which create data for a graph display
  *
  * @param sites <array<object>> array of sites
  * @returns array<object>> Data object formatted to display graph
  */
export const createData = (sites,theme) => {
    if (sites.length === 0)
        return null;

    const firstSet = [];
    const secondSet = [];
    const thirdSet = [];
    const forthSet = [];
    const labels = [];

    sites.forEach((website) => {
        firstSet.push(website.stocksBaskets[0].cardinality);
        secondSet.push(website.stocksBaskets[1].cardinality);
        thirdSet.push(website.stocksBaskets[2].cardinality);
        forthSet.push(website.stocksBaskets[3].cardinality);

        labels.push(Facade.siteDisplayName(website.name));

        return true;
    });

    const data = {
        datasets: [{
            backgroundColor: theme.palette.grey.semidark,
            borderColor: theme.palette.grey.semidark,
            data: forthSet,
            hoverBackgroundColor: theme.palette.grey.semidark,
            hoverBorderColor: theme.palette.grey.semidark,
            label: translate.i18n('STOCK_NO_INFORMATION')
        }, {
            backgroundColor: theme.palette.green.main,
            borderColor: theme.palette.green.main,
            data: firstSet,
            hoverBackgroundColor: theme.palette.green.main,
            hoverBorderColor: theme.palette.green.main,
            label: translate.i18n('STOCK_IN')
        }, {
            backgroundColor: theme.palette.red.main,
            borderColor: theme.palette.red.main,
            data: secondSet,
            hoverBackgroundColor: theme.palette.red.main,
            hoverBorderColor: theme.palette.red.main,
            label: translate.i18n('AVAILABLE_SOON')
        }, {
            backgroundColor: theme.palette.black.main,
            borderColor: theme.palette.black.main,
            data: thirdSet,
            hoverBackgroundColor: theme.palette.black.main,
            hoverBorderColor: theme.palette.black.main,
            label: translate.i18n('STOCK_SOLD_OUT')
        }],
        labels
    };
    return data;
};

/**
  * Method which return the options for a graph display
  *
  * @returns <object> the options object
  */
export const getOptions = () => ({
    maintainAspectRatio: false,
    borderRadius: 2,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            fontSize: 12,
            text: translate.i18n('STOCK_FILTER_TITLE'),
            padding: {
                bottom: 15,
                top: 8
            }
        },
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            stacked: true,
            ticks: {
                display: false
            }
        },
        y: {
            grid: {
                display: false,
                drawBorder: false
            },
            stacked: true,
            ticks: {
                display: false
            }
        }
    },
});
