// Abstract
import AbstractStore from 'core/abstracts/AbstractStore';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Object

// Facade
import Facade from 'core/Facade';

class AdBoxStore extends AbstractStore {

    constructor() {

        const CHANGE_EVENT = 'ADBOX_CHANGE_EVENT';
        const NAME = 'ADBOX_STORE';

        super(NAME, CHANGE_EVENT);

        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));

        this._initStore();
    }

    _initStore() {
        // Mails
        this.mails = [];
        this.params = this._initParams();
        this.isLoadingMail = true;
        this.statsByDay = [];
        this.statsByHour = [];
        this.statsByDayAndHour = [];
        this.statsBySites = [];
        this.totalMails = 0;
        this.tagCloud = [];
    }

    _initParams() {
        const yesterdayDate = new Date();
        yesterdayDate.setDate(yesterdayDate.getDate() - 7);

        const today = new Date();

        const params = {
            days: [],
            domains: [],
            from: yesterdayDate,
            hours: [],
            to: today
        };

        const urlParams = Facade.getAllUrlParams();

        if (urlParams.length === 0)
            return params;

        if (Facade.getUrlParam('from'))
            params.from = new Date(Facade.getUrlParam('from'));

        if (Facade.getUrlParam('to'))
            params.to = new Date(Facade.getUrlParam('to'));

        return params;
    }

    getIsLoadingMail() {
        return this.isLoadingMail;
    }

    getMails(){
        return this.mails;
    }

    getParams() {
        return this.params;
    }

    getStats(){
        return {
            statsByDay: this.statsByDay,
            statsByHour: this.statsByHour,
            statsByDayAndHour: this.statsByDayAndHour,
            statsBySites: this.statsBySites,
            totalMails: this.totalMails,
            tagCloud: this.tagCloud
        };
    }

    setParams(params) {
        this.params = Object.assign({}, params);
    }

    setData(data) {
        this.mails = data.mails;
        this.statsByDay = data.statsByDay;
        this.statsByHour = data.statsByHour;
        this.statsByDayAndHour = data.statsByDayAndHour;
        this.statsBySites = data.statsBySites;
        this.totalMails = data.totalMails;
        this.tagCloud = data.tagCloud;
    }

    _registerToActions(action) {
        switch (action.actionType) {
        case Constants.ADBOX_SEARCH_START:
            this.setParams(action.value);
            this.isLoadingMail = true;
            this._emitChange();
            break;
        case Constants.ADBOX_SEARCH_END:
            this.setData(action.value);
            this.firstLoad = false;
            this.isLoadingMail = false;
            this._emitChange();
            break;
        case Constants.RESET_ADBOX_STORE:
            this._initStore();
            this.isLoading = true;
            this._emitChange();
            break;
        case Constants.USER_LOGOUT:
            this._initStore();
            this._emitChange();
            break;
        case Constants.ACCOUNT_CHANGE:
            this._initStore();
            this._emitChange();
            break;
        default:
            return false;
        }
    }
}

export default new AdBoxStore();
