import ScrapedData from 'core/entities/ScrapedDataObject';

class ScrapingData {

    constructor(data) {
        this.id = data?.id;
        this.siteId = data?.site_id;
        this.siteUrl = data?.site_url;
        this.siteName = data?.site_name;
        this.satisfiesFilters = data?.satisfies_filters;
        this.status = data?.status;
        this.url = data?.url;
        this.alternativeUrls = data?.site_alternative_urls ? data?.site_alternative_urls:[];
        this.nvBellParams = data && data.nv_bell_params ? {
            delayInDays: data.nv_bell_params.delay_in_days,
            displayDate: data.nv_bell_params.display_date,
            insertDate: data.nv_bell_params.insert_date,
        } : undefined;

        // SPE MODULE
        this.from = data && data.from ? new ScrapedData(data.from) : data.from;
        this.to = data && data.to ? new ScrapedData(data.to) : data.to;
        this.to = data && data.data ? new ScrapedData(data.data) : this.to;
        this.from = data && data.data ? new ScrapedData(data.data) : this.from;

        // SCRAPING DATA UPDATE
        this.data = data && data.data ? new ScrapedData(data.data) : data.data;

        // PRICING ENGINE MODULE
        this.scrapedData = data && data.scraped_data ? new ScrapedData(data.scraped_data) : data.scraped_data;

        // PRODUCT PAGE
        if (!this.scrapedData) this.scrapedData = new ScrapedData({
            price: data?.price,
            availability: data?.availability,
            status: data?.status,
            stock: data?.stock,
            img_url: data?.img_url
        });
    }

    /**
     * METHODS
     */
    toJson() {
        return {
            site_id: this.siteId,
            status: this.status && this.status !== 'UNDEFINED' ? this.status : '',
            params: {
                url: this.url,
                delay: this.nvBellParams.delayInDays
            }
        };
    }
}

export default ScrapingData;
