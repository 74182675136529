/**
  * Method called to turn first letter of a string uppercase
  *
  * @param s <string> to transform
  * @returns <string>
  */
export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};
