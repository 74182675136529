// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Locales
import translate from 'locales/i18n';
// Material UI Components
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RightIcon from '@mui/icons-material/KeyboardArrowRight';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
// Styles
import { useStyles } from 'components/dialog/DialogCategoryProducts/DialogCategoryProducts.style';
// Theme
import { useTheme } from '@mui/styles';
// PCP Components
import ProductBar from 'components/bar/ProductBar/ProductBar';

const DialogCategorys = ({ open, close, currentCategory, categories }) => {
    const [searchText, setSearchText] = useState('');
    const [formattedCategories, setFormattedCategories] = useState(categories);
    const classes = useStyles();
    const theme = useTheme();

    useEffect(() => setFormattedCategories(categories), [categories]);

    const hideCategory = (id) => {
        setFormattedCategories(formattedCategories.map(category => {
            if (category.id === id) category.hide = !category.hide;
            return category;
        }));
    };

    return <Dialog
        fullScreen={useMediaQuery(theme.breakpoints.down('md'))}
        open={open}
        onClose={close}
        disableRestoreFocus={true}
        aria-labelledby='responsive-dialog-title'
        classes={{
            paper: classes.dialogPaper
        }}
    >
        <IconButton
            color='secondary'
            onClick={close}
            className={classes.closeButton}
            size='large'>
            <ClearIcon />
        </IconButton>
        <DialogTitle id='responsive-dialog-title' className={classes.title}>{translate.i18n('CATEGORY_MANAGEMENT', { name: currentCategory?.name })}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <TextField
                id='searchfield'
                variant='outlined'
                size='small'
                value={searchText}
                onChange={event => setSearchText(event.target.value)}
                placeholder={translate.i18n('SEARCH')}
                margin={'dense'}
                InputProps={{
                    startAdornment: <InputAdornment position='start'><SearchIcon className={classes.searchIcon} /></InputAdornment>
                }}
            />
            {categories.map((category, key) =>
                <div key={key}>
                    <div className={classes.categoryNameContainer}>
                        <IconButton
                            className={classes.margin}
                            onClick={() => hideCategory(category.id)}
                            size='large'>
                            {!category.hide ?
                                <DownIcon fontSize='small' />
                                :
                                <RightIcon fontSize='small' />
                            }
                        </IconButton>
                        <p className={classes.categoryName}>{category.name + ' (' + category.associatedItems.length + ' ' + translate.i18n('ITEMS') + ')'}</p>
                    </div>
                    {category.hide ? null
                        :
                        category.associatedItems.map((item, index) => <ProductBar key={index}
                            currentCategory={currentCategory}
                            productProps={item}
                            NACategory={categories.find(c => c.id === '5cb9754894ede413bc253a12')}
                            searchText={searchText} />)}
                </div>
            )}
        </DialogContent>
    </Dialog>;
};

DialogCategorys.propTypes = {
    categories: PropTypes.array,
    close: PropTypes.func,
    currentCategory: PropTypes.object,
    open: PropTypes.bool
};

export default DialogCategorys;
