const styles = () => ({
    '@media (max-width: 600px)': {
        content: {
            paddingTop: '68px'
        }
    },
    content: {
        flexGrow: 1,
        maxHeight: '100vh',
        overflow: 'auto',
        paddingTop: '75px'
    },
    root: {
        display: 'flex'
    }
});

export default styles;
