// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Service
import AdBoxService from 'services/AdBoxService';
//Stores
import AccountStore from 'stores/AccountStore';
//Action
import AlertAction from 'actions/AlertAction';
//i18n
import translate from 'locales/i18n';
// Logger
import Logger from 'core/Logger';
import Mail from 'core/entities/MailObject';

class AdBoxAction {

    /**
      * Method called to get emails of Adbox module
      *
      * @param {  accountId, from, to } options the options to search emails
      */
    search(options) {
        Dispatcher.dispatch({
            actionType: Constants.ADBOX_SEARCH_START,
            value: options
        });

        AdBoxService.getMails(options).then((response) => {
            Dispatcher.dispatch({
                actionType: Constants.ADBOX_SEARCH_END,
                value: {
                    from: response.from,
                    mails: response.mails.map(mail => new Mail(mail)),
                    statsByDay: response.stats_by_day,
                    statsByHour: response.stats_by_hour,
                    statsByDayAndHour: response.stats_by_day_and_hour,
                    statsBySites: response.stats_by_site,
                    totalMails: response.total_mails,
                    tagCloud: response.stats_tag_cloud,
                    to: response.to
                }
            });
        }, err => {
            Logger.error(err);
            AlertAction.open('error', translate.i18n('ERROR_RETRIEVE_EMAILS'), 'top-right');
        });
    }

    /**
      * Method called to reset params of adbox search
      */
    reset() {
        Dispatcher.dispatch({
            actionType: Constants.RESET_ADBOX_STORE
        });

        const yesterdayDate = new Date();
        yesterdayDate.setDate(yesterdayDate.getDate() - 7);

        const today = new Date();

        const options = {
            accountId: AccountStore.getCurrentAccount().getId(),
            days: [],
            domains: [],
            from: yesterdayDate,
            hours: [],
            to: today
        };

        AdBoxService.getMails(options).then(({ from, mails, stats_globals, stats_senders, to }) => {
            Dispatcher.dispatch({
                actionType: Constants.ADBOX_SEARCH_END,
                value: {
                    from,
                    mails: mails.map(mail => new Mail(mail)),
                    statsGlobals: stats_globals,
                    statsSenders: stats_senders,
                    to
                }
            });
        }, err => {
            Logger.error(err);
            AlertAction.open('error', translate.i18n('ERROR_RETRIEVE_EMAILS'), 'top-right');
        });
    }
}

export default new AdBoxAction();
