import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    bwContainer: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: '10px',
        padding: '10px',
        width: '100%'
    },
    configFab: {
        fontSize: '9px',
        height: '30px',
        marginLeft: 'auto',
        minHeight: '30px',
        whiteSpace: 'nowrap'
    },
    dialogContainer: {
        width: '450px'
    },
    longTextField: {
        marginLeft: 'auto',
        width: '319px'
    },
    longOperationFab: {
        height: '30px',
        minHeight: '30px',
    },
    operationFab: {
        height: '30px',
        minHeight: '30px',
        width: '30px'
    },
    subText: {
        fontSize: '13px',
        fontWeight: 500,
        marginRight: '5px'
    },
    text: {
        color: theme.palette.primary.main,
        fontSize: '13px',
        paddingLeft: '15px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: '45px',
        width: '160px'
    },
    title: {
        color: theme.palette.primary.dark,
        fontSize: '18px',
        fontWeight: 500,
        marginRight: '5px',
        width: '50%'
    }
}));

