import makeStyles from '@mui/styles/makeStyles';
import { getRatingColor } from './NotationSheet.util';

export const useStyles = makeStyles(() => ({
    count: {
        color: '#C8C8C8',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    noStarIcon: {
        height: '30px',
        width: '30px'
    },
    notationContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        width: '30px'
    },
    ratingStar: {
        backgroundColor: evaluation => getRatingColor(evaluation),
        borderRadius: '2px',
        height: '30px',
        paddingLeft: '3px',
        paddingTop: '2px',
        width: '30px'
    },
    ratingStarIcon: {
        marginLeft: '3px',
        marginTop: '2px'
    },
    ratingText: {
        color: evaluation => getRatingColor(evaluation),
        fontSize: 'large',
        fontWeight: 'bolder',
        textAlign: 'center'
    }
}));
