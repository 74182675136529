class Strategy {

    constructor(data) {
        this.type = data?.type;
        this.positioning = data?.positioning;
        this.element = data?.element;
        this.offset = data?.offset;
        this.filteringSiteIds = data?.filtering_site_ids;
        this.filteringStocks = data?.filtering_stocks;
        this.lowLimitPrice = data?.low_limit_price;
        this.highLimitPrice = data?.high_limit_price;
        this.rounding = data?.rounding;
    }

}

export default Strategy;
