// Abstract
import AbstractStore from 'core/abstracts/AbstractStore';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';

class AttributeStore extends AbstractStore {

    constructor() {

        const CHANGE_EVENT = 'ACCOUNT_CHANGE_EVENT';
        const NAME = 'ACCOUNT_STORE';

        super(NAME, CHANGE_EVENT);

        Dispatcher.register(this._registerToActions.bind(this));

        this._initStore();
    }

    _initStore() {
        this.attributeNames = [];
        this.attributeValues = {};
        this.currentAttribute = '';
        this.isLoading = false;
    }

    setIsLoading(isLoading) {
        this.isLoading = isLoading;
    }

    getIsLoading() {
        return this.isLoading;
    }

    setAttributeNames(attributeNames) {
        this.attributeNames = attributeNames;
    }

    getAttributeNames() {
        return this.attributeNames;
    }

    addAttributeValues({ attributes, attributeName }) {
        this.attributeValues = { ...this.attributeValues, [attributeName] : attributes };
    }

    resetAttributeValues() {
        this.attributeValues = {};
    }

    getAttributeValues() {
        return this.attributeValues;
    }

    setCurrentAttribute(attribute) {
        this.currentAttribute = attribute;
    }

    _registerToActions(action) {
        switch (action.actionType) {
        case Constants.USER_LOGOUT:
            this._initStore();
            break;
        case Constants.ATTRIBUTE_NAMES_GET_START:
            this.setIsLoading(true);
            this._emitChange();
            break;
        case Constants.ATTRIBUTE_NAMES_GET_END:
            this.setAttributeNames(action.value);
            this.setIsLoading(false);
            this._emitChange();
            break;
        case Constants.ATTRIBUTE_VALUES_GET_START:
            this.setIsLoading(true);
            this._emitChange();
            break;
        case Constants.ATTRIBUTE_VALUES_GET_END:
            this.addAttributeValues(action.value);
            this.setIsLoading(false);
            this._emitChange();
            break;
        default:
            return false;
        }
    }
}

export default new AttributeStore();
