// Locales
import translate from 'locales/i18n';

/**
 * Method called to get highest cardinality of all prices baskets for a type (current or withRepricing)
 *
 * @param pricesBaskets <array<object>> the array of prices baskets
 * @param type <string> the type (current or withRepricing)
 */
const getHigherCardinality = (pricesBaskets, type) => {
    let higherCardinality = 1;

    pricesBaskets.forEach((pricesBasket) => {
        if (pricesBasket[type] > higherCardinality && pricesBasket[type] !== 0)
            higherCardinality = pricesBasket[type];
    });

    return higherCardinality;
};

/**
 * Method called to get the right tooltip title on filter
 *
 * @param item <object> the filter item when hover
 * @param pricesBaskets <array<object>> the array of prices baskets
 */
const getTooltipTitle = (item, pricesBaskets) => {
    const { bounds } = pricesBaskets.find(pricesBasket => pricesBasket.id === item[0].label);

    if (bounds[0] === 0 && bounds[1] === 0) return translate.i18n('PRICE_LIKE_YOURS');

    if (bounds[0] < -50) return translate.i18n('LESS_THAN', { number: '-50%' });

    if (bounds[1] > 50) return translate.i18n('MORE_THAN', { number: '50%' });

    return translate.i18n('BETWEEN') + ' ' + bounds[0] + '% ' + translate.i18n('AND') + ' ' + bounds[1] + '%';
};

/**
  * Method called to create data needed for prices baskets filter
  *
  * @param pricesBaskets <array<object>> the array of prices baskets
  * @param pricesBaskets <object> the object with the prices baskets selected
  * @param type <string> the type (current or withRepricing)
  * @param theme <object> the theme to get colors
  * @returns <array<object>> data to create prices baskets filter
  */
export const createData = (pricesBaskets, pricesBasketsSelected, type, theme) => {
    const basketData = [];
    const higherCardinality = getHigherCardinality(pricesBaskets, type);
    const colorRetailer = [theme.palette.largeGraph.one,
        theme.palette.largeGraph.two, theme.palette.largeGraph.three,
        theme.palette.largeGraph.four, theme.palette.largeGraph.five,
        theme.palette.largeGraph.six, theme.palette.largeGraph.seven,
        theme.palette.largeGraph.eight, theme.palette.largeGraph.nine,
        theme.palette.largeGraph.ten, theme.palette.largeGraph.eleven,
        theme.palette.largeGraph.twelve, theme.palette.largeGraph.thirteen,
        theme.palette.largeGraph.fourteen, theme.palette.largeGraph.fifteen,
        theme.palette.largeGraph.sixteen, theme.palette.largeGraph.seventeen,
        theme.palette.largeGraph.eighteen, theme.palette.largeGraph.nineteen,
        theme.palette.largeGraph.twenty, theme.palette.largeGraph.twentyOne,
        theme.palette.largeGraph.twentyTwo, theme.palette.largeGraph.twentyThree];
    const formatedColor = [];
    const label = [];

    pricesBaskets.map((pricesBasket, key) => {
        // Manage data value
        if (pricesBasket[type] < (higherCardinality / 5) * 0.2) {
            basketData.push((higherCardinality / 5) * 0.2);
        } else {
            basketData.push(pricesBasket[type]);
        }

        // Manage data color
        if (pricesBasket[type] === 0 && (pricesBasketsSelected[type].indexOf(pricesBasket.id) !== -1 || pricesBasketsSelected[type].length === 0)) {
            formatedColor.push(theme.palette.white.main);
        } else {
            pricesBasketsSelected[type].indexOf(pricesBasket.id) === -1 && pricesBasketsSelected[type].length !== 0 ? formatedColor.push(theme.palette.grey.light) : formatedColor.push(colorRetailer[key]);
        }

        label.push(pricesBasket.id);
        return true;
    });

    const data = {
        datasets: [{
            backgroundColor: formatedColor,
            borderColor: formatedColor,
            borderWidth: 1,
            data: basketData,
            hoverBackgroundColor: formatedColor,
            hoverBorderColor: formatedColor,
            label: 'Cardinality'
        }],
        labels: label
    };

    return data;
};

/**
 * Method called to get all needed options of prices baskets filter
 */
export const getOptions = (pricesBaskets, isLoading, type) => ({
    maintainAspectRatio: false,
    borderRadius: 2,
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: tooltipItems => {
                    if (pricesBaskets.find(pricesBasket => pricesBasket.id === tooltipItems.label).current === 0) return '0 ' + translate.i18n('PRICES_FILTERED');
                    return ' ' + pricesBaskets[tooltipItems.dataIndex].current + ' ' + translate.i18n('PRICES_FILTERED');
                },
                title: tooltipItems => getTooltipTitle(tooltipItems, pricesBaskets)
            },
            enabled: !isLoading
        },
        datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: (value, context) => context.dataIndex === 11 ? '=' : '',
        },
        title: {
            display: true,
            fontSize: 12,
            text: type === 'current' ? translate.i18n('PRICES_BASKETS_TITLE_RETAILER') : '',
            padding: {
                bottom: 15,
                top: 8
            }
        },
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            ticks: {
                display: false
            }
        },
        y: {
            grid: {
                display: false,
                drawBorder: false
            },
            ticks: {
                display: false
            }
        }
    },
});
