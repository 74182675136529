import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    closeButton: {
        position: 'absolute',
        right: '2px',
        top: '2px'
    },
    dialogContent: {
        padding: '0px 24px 24px 24px'
    },
    image: {
        height: '60px',
        width: '60px',
        marginRight: '5px'
    },
    paper: {
        maxWidth: '650px',
        minWidth: '420px'
    },
    title: {
        padding: '16px 45px 16px 24px',
        display: 'flex',
        alignItems: 'center'
    }
}));
