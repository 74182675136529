// React
import React from 'react';
import PropTypes from 'prop-types';
// i18n
import translate from 'locales/i18n';
import withStyles from '@mui/styles/withStyles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
// Styles
import styles from 'components/sheet/EditPriceRuleSheet/EditPriceRuleSheet.style';
// Stores
import AccountStore from 'stores/AccountStore';

const getFinalPrice = (mode, value, price) => {
    if (mode === 'PERCENTAGE') {
        return (price * (1 + (value / 100))).toFixed(2);
    }
    return (price + parseFloat(value)).toFixed(2);
};

const EditPriceRuleSheet = ({ classes, title, element, mode, value, edit }) =>
    <div className={classes.root}>
        <div className={classes.title}>{title}<div className={classes.titleBar}></div></div>
        <div className={classes.editContainer}>
            <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel>{translate.i18n('BASED_ON')}</InputLabel>
                <Select
                    value={element}
                    onChange={event => edit('element', event.target.value)}
                    label={translate.i18n('BASED_ON')}
                    inputProps={{
                        name: translate.i18n('BASED_ON'),
                        className: classes.input
                    }}
                >
                    <MenuItem value={'RRP'}>RRP</MenuItem>
                    <MenuItem value={'PURCHASING_PRICE'}>{translate.i18n('PURCHASING_PRICE')}</MenuItem>
                </Select>
            </FormControl>
            <div className={classes.plusSymbol}>+</div>
            <TextField
                label={translate.i18n('VALUE')}
                type='number'
                value={value}
                onChange={event => edit('value', parseFloat(event.target.value))}
                variant='outlined'
                className={classes.valueTextField}
                inputProps={{
                    className: classes.input
                }}
            />
            <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel>Mode</InputLabel>
                <Select
                    value={mode}
                    onChange={event => edit('mode', event.target.value)}
                    label='Mode'
                    inputProps={{
                        name: 'Mode',
                        className: classes.input
                    }}
                >
                    <MenuItem value={'PERCENTAGE'}>%</MenuItem>
                    <MenuItem value={'VALUE'}>{AccountStore.getCurrentAccount().getCurrency()}</MenuItem>
                </Select>
            </FormControl>
        </div>
        <div className={classes.exempleContainer}>
            <div className={classes.exempleText}>
                Ex : {translate.i18n(element)}: {`${element === 'RRP' ? 120 : 100} ${AccountStore.getCurrentAccount().getCurrency()}`}
            </div>
            <div className={classes.exempleText} style={{ margin: '0px 5px 5px 5px' }}>
                ❱
            </div>
            <div className={classes.titleSecondaryText}>
                {title.toUpperCase()} :
            </div>
            <div className={classes.titleSecondaryText}>
                {`${getFinalPrice(mode, value, element === 'RRP' ? 120 : 100)} ${AccountStore.getCurrentAccount().getCurrency()}`}
            </div>
        </div>
    </div>;

EditPriceRuleSheet.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    element: PropTypes.string,
    mode: PropTypes.string,
    value: PropTypes.number,
    edit: PropTypes.func
};

export default withStyles(styles)(EditPriceRuleSheet);
