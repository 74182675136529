// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Locales
import Checkbox from '@mui/material/Checkbox';
// Styles
import { useStyles } from 'components/bar/ProductBar/ProductBar.style';
// PCP Components
import ImageViewer from 'components/commons/ImageViewer/ImageViewer';
// Actions
import ItemAction from 'actions/ItemAction';

const ProductBar = ({ productProps, currentCategory, NACategory, searchText }) => {
    const [product, setProduct] = useState(productProps);
    const [oldCategory, setOldCategory] = useState(null);
    const classes = useStyles();

    useEffect(() => setProduct(productProps), [productProps]);

    const onCheck = productToUpdate => {
        if (productToUpdate.category.id === currentCategory.id) {
            ItemAction.updateProductCategory(product.id, oldCategory ? oldCategory.id : NACategory.id).then(p => {
                setProduct(p);
                setOldCategory(Object.assign({}, NACategory));
            });
        } else {
            ItemAction.updateProductCategory(product.id, currentCategory.id).then(p => {
                const { category } = product;
                setProduct(p);
                setOldCategory(Object.assign({}, category));
            });
        }
    };

    if (searchText !== ''
        && !productProps.category.name.toLowerCase().includes(searchText.toLowerCase())
        && !productProps.name.toLowerCase().includes(searchText.toLowerCase())) return null;

    return <div key={product.id} className={classes.itemContainer}>
        <Checkbox
            checked={currentCategory && currentCategory.id === product.category.id}
            onChange={() => onCheck(product)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            color='primary'
        />
        <div className={classes.imageContainer}>
            <ImageViewer
                imageUrl={'https://img.pricecomparator.pro/' + product.imgPath}
                title={product.name}
                alt={product.name}
            />
        </div>
        <div className={classes.itemName}>{product.name}</div>
    </div>;
};

ProductBar.propTypes = {
    currentCategory: PropTypes.object,
    NACategory: PropTypes.object,
    productProps: PropTypes.object,
    searchText: PropTypes.string
};

export default ProductBar;
