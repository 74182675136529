// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Facade
import Facade from 'core/Facade';
// Locales
import translate from 'locales/i18n';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// Actions
import ItemAction from 'actions/ItemAction';
//Stores
import AccountStore from 'stores/AccountStore';
// Style
import { useStyles } from 'components/dialog/DialogUpdateScrapedData/DialogUpdateScrapedData.style';

const DialogUpdateScrapedData = ({ close, from, item, open, scrapingData, to, website }) => {
    const [availability, setAvailability] = useState(scrapingData.to.availability);
    const [price, setPrice] = useState(scrapingData.to.status === 'PENDING' ? '' : scrapingData.to.price);
    const [status, setStatus] = useState(scrapingData.to.status === 'PENDING' ? 'SOLD' : scrapingData.to.status);
    const [stock, setStock] = useState(scrapingData.to.status === 'PENDING' ? 'en stock' : scrapingData.to.stock !== '' ? scrapingData.to.stock : 'no information');
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            update();
        }
    };

    const update = () => {
        let newScrapedData = {};

        if (status === 'SOLD') {
            newScrapedData = {
                availability,
                price: parseFloat(price),
                status,
                stock: stock === 'no information' ? '' : stock
            };
        } else {
            newScrapedData.status = status;
        }

        if (scrapingData.date) {
            ItemAction.updateHistory({
                accountId: AccountStore.getCurrentAccount().getId(),
                date: scrapingData.date,
                from,
                itemId: item.id,
                newScrapedData,
                scrapingData: scrapingData.id,
                siteId: website.id,
                to
            });
        } else {
            ItemAction.updateScrapedData({
                accountId: AccountStore.getCurrentAccount().getId(),
                date: 'LAST',
                itemId: item.id,
                newScrapedData,
                scrapingData: scrapingData.id,
                siteId: website.id
            });
        }

        close();
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={close}
            maxWidth={'md'}
            fullWidth={true}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
            onKeyPress={handleKeyPress}
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <IconButton
                color='secondary'
                onClick={() => close()}
                className={classes.closeButton}
                size='large'>
                <ClearIcon />
            </IconButton>
            <DialogTitle id='responsive-dialog-title'>
                <span className={classes.titleDialogBox}>
                    {scrapingData.date ? Facade.formatDate(new Date(scrapingData.date)) + ' - ' : translate.i18n('UPDATE_DATA') + ' - '}
                    <span className={classes.titleDialogBoxCompany}>
                        {Facade.siteDisplayName(website.name)}
                    </span>
                </span>
                <p className={classes.titleDialogBoxProduct}>
                    {item.name}
                </p>
            </DialogTitle>
            <DialogContent className={classes.dialogContent} style={{ paddingTop: '4px' }}>
                <FormControl variant='outlined' className={classes.margin}>
                    <InputLabel htmlFor='outlined'>
                        {translate.i18n('STATUS')}
                    </InputLabel>
                    <Select
                        id='demo-simple-select-helper'
                        value={status}
                        onChange={event => setStatus(event.target.value)}
                        label={translate.i18n('STATUS')}
                    >
                        <MenuItem value={'SOLD'}>{translate.i18n('SOLD')}</MenuItem>
                        <MenuItem value={'NV_BELL'}>{translate.i18n('NOT_SOLD_BELL')}</MenuItem>
                        <MenuItem value={'NV'}>{translate.i18n('NOT_SOLD')}</MenuItem>
                        <MenuItem value={'PENDING'}>{translate.i18n('EXTRACTING')}</MenuItem>
                    </Select>
                </FormControl>
                <br />
                <TextField
                    className={classes.margin}
                    id='price'
                    variant='outlined'
                    label={translate.i18n('PRICE')}
                    value={price}
                    onChange={event => setPrice(event.target.value)}
                    disabled={status !== 'SOLD'}
                    autoFocus={scrapingData.to.status === 'PENDING'}
                    type={'number'}
                />
                <br />
                <TextField
                    className={classes.margin}
                    id='availability'
                    variant='outlined'
                    label={'Availability'}
                    value={availability}
                    onChange={event => setAvailability(event.target.value)}
                    disabled={status !== 'SOLD'} />
                <br />
                <FormControl variant='outlined'
                    className={classes.margin}
                    disabled={status !== 'SOLD'}>
                    <InputLabel htmlFor='outlined'>
                        {translate.i18n('STOCK')}
                    </InputLabel>
                    <Select
                        id='demo-simple-select-helper'
                        value={stock}
                        onChange={event => setStock(event.target.value)}
                        label={translate.i18n('STOCK')}
                    >
                        <MenuItem value={'en stock'}>{translate.i18n('STOCK_IN')}</MenuItem>
                        <MenuItem value={'sup. 48h'}>{translate.i18n('STOCK_SUP_48H')}</MenuItem>
                        <MenuItem value={'rupture'}>{translate.i18n('STOCK_SOLD_OUT')}</MenuItem>
                        <MenuItem value={'no information'}>{translate.i18n('STOCK_NO_INFORMATION')}</MenuItem>
                    </Select>
                </FormControl>
                <br />
                <Button variant='contained'
                    color='primary'
                    className={classes.margin}
                    onClick={update}>
                    {translate.i18n('UPDATE')}
                </Button>
                <br />
            </DialogContent>
        </Dialog>
    );
};

DialogUpdateScrapedData.propTypes = {
    close: PropTypes.func,
    from: PropTypes.instanceOf(Date),
    item: PropTypes.object,
    open: PropTypes.bool,
    scrapingData: PropTypes.object,
    to: PropTypes.instanceOf(Date),
    website: PropTypes.object
};

export default DialogUpdateScrapedData;
