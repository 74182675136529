import React from 'react';
import PropTypes from 'prop-types';
//Styles
import { useStyles } from 'components/dialog/DialogAddProduct/DialogAddProduct.style';
//Mui components
import {
    Autocomplete,
    IconButton,
    ListItem,
    TextField
} from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';
//Locales
import translate from 'locales/i18n';

const DialogAddProductOptionalAttributes = ({
    attribute,
    existingAttributeNames,
    existingAttributeValues,
    handleChangeOptionalAttributeName,
    handleChangeOptionalAttributeValue,
    index,
    removeAttribute,
    verifyAttributeName,
    verifyAttributeValue
}) => {

    const classes = useStyles();

    return <ListItem>
        <div className={classes.optionalAttribute}>
            <ListIcon className={classes.listIcon}/>
            <Autocomplete
                disablePortal
                freeSolo={true}
                value={attribute.name}
                options={existingAttributeNames}
                className={classes.optionalAttributeText}
                onInputChange={(event, value) => handleChangeOptionalAttributeName(value, index)}
                renderInput={(params) => <TextField
                    {...params}
                    error={verifyAttributeName(attribute.name)}
                    onChange={(event) => handleChangeOptionalAttributeName(event, index)}
                    value={attribute.name}
                    label={translate.i18n('ATTRIBUTE_NAME')}
                    size='small'
                />}
            />
            <Autocomplete
                disablePortal
                freeSolo={true}
                options={existingAttributeValues[attribute.name] ? existingAttributeValues[attribute.name] : []}
                className={classes.optionalAttributeText}
                value={attribute.value}
                onInputChange={(event, value) => handleChangeOptionalAttributeValue(value, index)}
                renderInput={(params) => <TextField
                    {...params}
                    error={verifyAttributeValue(attribute.value)}
                    value={attribute.value}
                    onChange={(event) => handleChangeOptionalAttributeValue(event, index)}
                    label={translate.i18n('ATTRIBUTE_VALUE')}
                    size='small'
                />}
            />
            <IconButton
                edge='end'
                aria-label='delete'
                title={translate.i18n('DELETE_ATTRIBUTE')}
                onClick={() => removeAttribute(index)}
            >
                <CloseIcon />
            </IconButton>
        </div>
    </ListItem>;
};

DialogAddProductOptionalAttributes.propTypes = {
    attribute: PropTypes.object,
    existingAttributeNames: PropTypes.array,
    existingAttributeValues: PropTypes.object,
    handleChangeOptionalAttributeName: PropTypes.func,
    handleChangeOptionalAttributeValue: PropTypes.func,
    index: PropTypes.number,
    removeAttribute: PropTypes.func,
    verifyAttributeName: PropTypes.func,
    verifyAttributeValue: PropTypes.func
};

export default DialogAddProductOptionalAttributes;
