// Abstract
import AbstractStore from 'core/abstracts/AbstractStore';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Entities
import Account from 'core/entities/AccountObject';

class AccountStore extends AbstractStore {

    constructor() {

        const CHANGE_EVENT = 'ACCOUNT_CHANGE_EVENT';
        const NAME = 'ACCOUNT_STORE';

        super(NAME, CHANGE_EVENT);

        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));

        this._initStore();
    }

    _initStore() {
        this.currentAccount = new Account();
        this.websites = [];
    }

    setAccount(data) {
        this.currentAccount = new Account(data);
    }

    getCurrentAccount() {
        return this.currentAccount;
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
        case Constants.USER_LOGIN:
            this.setAccount(action.value.account);
            this._emitChange();
            break;
        case Constants.ACCOUNT_CHANGE:
            this.setAccount(action.value);
            this._emitChange();
            break;
        default:
            return false;
        }
    }
}

export default new AccountStore();
