import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    arrowBackIcon: {
        display: 'flex',
        justifyContent: 'end'
    },
    bottomButton: {
        height: '40px',
        margin: '5px'
    },
    bottomButtonsContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '25px'
    },
    chipLabel: {
        paddingLeft: 0,
        paddingRight: 0,
        textAlign: 'center'
    },
    container: {
        height: '100%',
        width: '100%'
    },
    dateText: {
        color: theme.palette.grey.dark,
        fontWeight: 600,
        marginTop: '1%',
        textAlign: 'center'
    },
    dialogInfoSubtitle: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '17px',
        marginBottom: '10px',
        marginTop: '10px'
    },
    dialogInfoText: {
        fontSize: '15px',
        marginBottom: '10px'
    },
    dialogInfoTitle: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '20px',
        fontWeight: 450,
        justifyContent: 'center',
        marginBottom: '40px'
    },
    helpText: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '12px',
        fontStyle: 'italic',
        marginLeft: '40px',
        marginTop: '25px'
    },
    itemsAssociated: {
        fontWeight: 600
    },
    libraryLoaderContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    },
    libraryRules: {
        height: '100%',
        width: '350px'
    },
    libraryRulesContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
        padding: '10px'
    },
    libraryRulesList: {
        marginBottom: 'auto',
        marginTop: '4px',
        overflowX: 'clip',
        overflowY: 'auto',
        width: '340px'
    },
    listPrimaryText: {
        fontSize: '13px',
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    listSecondaryText: {
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: 1.43,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    loaderContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: 'calc(100% - 360px)'
    },
    prevDateLane: {
        borderLeft: 'dashed',
        borderLeftColor: theme.palette.grey.dark,
        bottom: '35px',
        position: 'absolute',
        zIndex: '0'
    },
    rankChip: {
        borderRadius: '50px',
        color: '#fff',
        fontSize: '35px',
        fontWeight: 600,
        height: '50px',
        margin: '5px',
        width: '50px'
    },
    rankedRuleContainer: {
        color: '#fff',
        display: 'flex',
        fontWeight: '500',
        marginTop: '20px',
        position: 'relative',
        textAlign: 'center',
        width: '100%',
        zIndex: '100'
    },
    rankedRulesList: {
        height: '100%',
        width: '100%'
    },
    rankingChips: {
        left: '3%',
        position: 'relative',
        top: '2px',
        zIndex: 1000
    },
    repricingRules: {
        height: 'calc(100% - 30px)',
        width: 'calc(100% - 360px)'
    },
    rulesAndChipsList: {
        display: 'flex',
        height: '100%',
        overflowY: 'scroll'
    },
    rulesContainer: {
        display: 'flex',
        height: '80%',
        paddingRight: '20px',
        paddingTop: '33px',
        position: 'relative',
        width: '100%'
    },
    ruleSelectedContent: {
        backgroundColor: theme.palette.grey.light
    },
    searchTextField: {
        marginTop: '15px',
        width: '100%'
    },
    subTitleIcon: {
        height: '25px',
        marginRight: '10px',
        width: '25px'
    },
    titleIcon: {
        height: '35px',
        marginRight: '10px',
        width: '35px'
    }
}));

