// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Locales
import translate from 'locales/i18n';
// Store
import AccountStore from 'stores/AccountStore';
import CategoryStore from 'stores/CategoryStore';
// Action
import CategoryAction from 'actions/CategoryAction';
// PCP components
import DialogConfirm from 'components/dialog/DialogConfirm/DialogConfirm';
import CategoryChip from 'components/chip/CategoryChip/CategoryChip';
import DialogEdit from 'components/dialog/DialogEdit/DialogEdit';
import Loader from 'components/commons/Loader/Loader';
import DialogCategoryProducts from 'components/dialog/DialogCategoryProducts/DialogCategoryProducts';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// Style
import { useStyles } from 'components/dialog/DialogManageCategories/DialogManageCategories.style';

const DialogManageCategories = ({ categories, close, open }) => {
    const [dialogConfirmAddOpen, setDialogConfirmAddOpen] = useState(false);
    const [dialogConfirmDeleteOpen, setDialogConfirmDeleteOpen] = useState(false);
    const [dialogEditOpen, setDialogEditOpen] = useState(false);
    const [dialogCategoryProductsOpen, setDialogCategoryProductsOpen] = useState(false);
    const [errorEdit, setErrorEdit] = useState('');
    const [searchText, setSearchText] = useState('');
    const [orderBy, setOrderBy] = useState('alpha');
    const [currentCategory, setCurrentCategory] = useState(null);
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const deleteCategories = (category) => {
        CategoryAction.deleteCategory({
            accountId: AccountStore.getCurrentAccount().getId(),
            category
        }).then(() => CategoryAction.getCategories(AccountStore.getCurrentAccount().getId()));
    };

    const addCategory = () => {
        if (searchText !== '')
            CategoryAction.addCategory({
                accountId: AccountStore.getCurrentAccount().getId(),
                name: searchText
            });
    };

    const renderDialogConfirmAdd = () =>
        <DialogConfirm
            close={() => setDialogConfirmAddOpen(false)}
            callback={() => {
                addCategory();
            }}
            open={dialogConfirmAddOpen}
            text={translate.i18n('ADD_CATEGORY', {
                name: searchText
            })}
        />;

    const renderDialogConfirmDelete = () =>
        <DialogConfirm
            close={() => setDialogConfirmDeleteOpen(false)}
            callback={() => {
                if (currentCategory)
                    deleteCategories(currentCategory);
            }}
            open={dialogConfirmDeleteOpen}
            text={translate.i18n('DELETE_CATEGORY', {
                name: currentCategory ? currentCategory.name : ''
            })}
        />;

    const renderDialogCategoryProducts = () =>
        <DialogCategoryProducts
            close={() => {
                CategoryAction.getCategories(AccountStore.getCurrentAccount().getId());
                setDialogCategoryProductsOpen(false);
            }}
            open={dialogCategoryProductsOpen}
            currentCategory={currentCategory}
            categories={categories}
        />;

    const renderDialogEdit = () =>
        <DialogEdit
            label={translate.i18n('CATEGORY_NAME')}
            title={translate.i18n('RENAME_CATEGORY')}
            close={() => {
                setDialogEditOpen(false);
                setErrorEdit('');
            }}
            open={dialogEditOpen}
            error={errorEdit}
            defaultValue={currentCategory?.name}
            callback={name => {
                if (!name) {
                    setErrorEdit(translate.i18n('ERROR_FIELD_EMPTY'));
                } else {
                    CategoryAction.editCategory({
                        accountId: AccountStore.getCurrentAccount().getId(),
                        id: currentCategory.id,
                        name
                    }).then();
                    setDialogEditOpen(false);
                }
            }} />;

    let filteredCategories = categories.filter(category => category.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
    if (orderBy === 'alpha') filteredCategories = filteredCategories.sort((c1, c2) => c1.name > c2.name);
    if (orderBy === 'count') filteredCategories = filteredCategories.sort((c1, c2) => c1.associatedItems.length > c2.associatedItems.length ? -1 : 1);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={() => {
                setSearchText('');
                close();
            }}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <IconButton
                color='secondary'
                onClick={() => {
                    setSearchText('');
                    close();
                }}
                className={classes.closeButton}
                size='large'>
                <ClearIcon />
            </IconButton>
            <DialogTitle id='responsive-dialog-title'>{translate.i18n('MANAGE_CATEGORIES')}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <div className={classes.filterContainer}>
                    <TextField
                        id='searchfield'
                        className={classes.searchBar}
                        variant='outlined'
                        size='small'
                        value={searchText}
                        onChange={event => setSearchText(event.target.value)}
                        onKeyPress={(event) => event.key === 'Enter' && searchText !== '' && setDialogConfirmAddOpen(true)}
                        placeholder={translate.i18n('ADD_OR_FIND_CATEGORY')}
                        margin={'dense'}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'><SearchIcon className={classes.searchIcon} /></InputAdornment>
                        }}
                    />
                    <FormControl variant='outlined'
                        size='small'>
                        <InputLabel>
                            {translate.i18n('SORT_BY')}
                        </InputLabel>
                        <Select
                            label={translate.i18n('SORT_BY')}
                            value={orderBy}
                            onChange={event => setOrderBy(event.target.value)}
                        >
                            <MenuItem value={'count'}>{translate.i18n('COUNT_OF_PRODUCTS')}</MenuItem>
                            <MenuItem value={'alpha'}>{translate.i18n('ALPHA_ORDER')}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {CategoryStore.isLoading() ?
                    <div className={classes.loaderContainer}><Loader size={50} /></div>
                    :
                    <div className={classes.categoriesContainer}>
                        {filteredCategories.map((category, key) =>
                            <CategoryChip
                                key={key}
                                category={category}
                                deleteCategory={categoryToDelete => {
                                    setCurrentCategory(categoryToDelete);
                                    setDialogConfirmDeleteOpen(true);
                                }}
                                editCategory={categoryToEdit => {
                                    setCurrentCategory(categoryToEdit);
                                    setDialogEditOpen(true);
                                }}
                                manageCategoryItems={categoryToManage => {
                                    setCurrentCategory(categoryToManage);
                                    setDialogCategoryProductsOpen(true);
                                }}
                            />)}
                    </div>
                }
                {renderDialogConfirmAdd()}
                {renderDialogConfirmDelete()}
                {renderDialogEdit()}
                {renderDialogCategoryProducts()}
            </DialogContent>
        </Dialog>
    );
};

DialogManageCategories.propTypes = {
    categories: PropTypes.array,
    close: PropTypes.func,
    open: PropTypes.bool
};

export default DialogManageCategories;
