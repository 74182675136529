// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Locales
import translate from 'locales/i18n';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircle';
import BellIcon from '@mui/icons-material/Notifications';
import UpdateIcon from '@mui/icons-material/Create';
import UrlIcon from '@mui/icons-material/Link';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import NewIcon from '@mui/icons-material/FiberNewOutlined';
// PCP Components
import PricePopper from 'components/popper/PricePopper/PricePopper';
import Loader from 'components/commons/Loader/Loader';
import ImageViewer from 'components/commons/ImageViewer/ImageViewer';
import NotationSheet from 'components/sheet/NotationSheet/NotationSheet';
// Store
import AccountStore from 'stores/AccountStore';
import ItemStore from 'stores/ItemStore';
import UserStore from 'stores/UserStore';
// Util
import { priceColor, displayPrice } from 'components/cell/PriceCell/PriceCell.util';
// Style
import { useStyles } from 'components/cell/PriceCell/PriceCell.style';
// SVG
import stockIcon from 'assets/img/svg/stockIcon';
//Theme
import { useTheme } from '@mui/styles';
// Facade
import Facade from 'core/Facade';

const PriceCell = ({ hideStocks, hideImages, item, openDialogAddUrl, openDialogMarketPlace, openDialogUpdateScrapedData, scrapingData, website, showEditIcon, hideNotations, hidePrices }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [priceHover, setPriceHover] = useState(false);
    const [stockHover, setStockHover] = useState(false);
    const [notationHover, setNotationHover] = useState(false);
    const [delayHandler, setDelayHandler] = useState(null);
    const [anchor, setAnchor] = useState(null);

    const price = scrapingData.to.price === -2 || scrapingData.to.price === -1 ? translate.i18n('NO_PRICE_FOUND') : scrapingData.to.price;

    const handleMouseEnter = (zone) => setDelayHandler(setTimeout(() => {
        switch (zone) {
        case 'PRICE':
            setPriceHover(true);
            break;
        case 'STOCK':
            setStockHover(true);
            break;
        case 'NOTATION':
            setNotationHover(true);
            break;
        }
    }, UserStore.getCurrentUser().isSuperUser() ? 1300 : 750));

    const handleMouseLeave = () => {
        clearTimeout(delayHandler);
        setPriceHover(false);
        setStockHover(false);
        setNotationHover(false);
    };

    /**
     * Method which return the svg icon in the good color
     *
     * @param stock <string> of the stock
     * @returns <string> svg icon
     */
    const getStockIcon = (stock) => {
        let color = '#ABABAB';

        if (!stock || typeof stock !== 'string') return stockIcon('30', '30', color);

        switch (stock.toLowerCase()) {
        case 'en stock':
            color = '#1BCC00';
            break;
        case 'rupture':
            color = '#000000';
            break;
        case '+5 jours':
            color = '#B30000';
            break;
        case 'sup 48h':
        case 'sup. 48h':
        case 'supp 48h':
        case 'supp. 48h':
            color = '#B30000';
            break;
        default:
            color = '#ABABAB';
        }
        return stockIcon('30', '30', color);
    };

    const renderEvolutionIcon = () => {
        const priceFrom = scrapingData.from.price;
        const priceTo = scrapingData.to.price;
        const fontSizeClasse = priceTo < priceFrom ? classes.smallIconDown : classes.smallIconUp;
        const Icon = priceTo < priceFrom ? ArrowDownwardIcon : ArrowUpwardIcon;
        let arrowColor = '';

        if (priceTo === priceFrom || priceTo <= 0 || !(ItemStore.getParams().mode === 'reactivity' || ItemStore.getParams().mode === 'price_movements'))
            return null;

        if (priceFrom <= 0)
            return <NewIcon classes={{ root: classes.newIcon }} />;

        if (AccountStore.getCurrentAccount().isRetailer()) {
            if (website.id === AccountStore.getCurrentAccount().getSiteId()) {
                if (priceTo > priceFrom) {
                    arrowColor = theme.palette.red.main;
                } else {
                    arrowColor = theme.palette.green.main;
                }
            } else {
                if (priceTo > priceFrom) {
                    arrowColor = theme.palette.green.main;
                } else {
                    arrowColor = theme.palette.red.main;
                }
            }
        } else {
            arrowColor = priceTo > priceFrom ? theme.palette.green.main : theme.palette.red.main;
        }

        return <Icon fontSize={'small'}
            classes={{
                fontSizeSmall: fontSizeClasse,
            }}
            style={{ color: arrowColor }} />;
    };

    const renderPrice = () => <div className={classes.priceContainer}
        onClick={() => {
            if (typeof scrapingData.to.marketplaceOffers === 'undefined' || scrapingData.to.price === -2) {
                Facade.openUrl(scrapingData.url);
            } else {
                openDialogMarketPlace(item, website);
            }
        }}>
        <div style={{
            fontSize: scrapingData.to.price === -2 ? '12px' : '17px',
            color: priceColor(scrapingData, item, website, AccountStore.getCurrentAccount(), theme)
        }} className={classes.priceCellContent}>
            {price >= 0 ? displayPrice(price) + ' ' + AccountStore.getCurrentAccount().getCurrency() : displayPrice(price)}
            {(price >= 0 && !scrapingData.to.pricingAdvising && scrapingData.to.additionalData && scrapingData.to.additionalData.oldPrice) && <div className={classes.oldPrice}>{displayPrice(scrapingData.to.additionalData.oldPrice) + ' ' + AccountStore.getCurrentAccount().getCurrency()}</div>}
            {scrapingData.to.pricingAdvising && <ArrowDownwardIcon fontSize={'small'} style={{ color: theme.palette.info.light, transform: scrapingData.to.pricingAdvising < parseFloat(price) ? 'rotate(-45deg)' : 'rotate(-135deg)' }} />}
            {scrapingData.to.pricingAdvising && <div className={classes.pricingAdvising}>{displayPrice(scrapingData.to.pricingAdvising) + ' ' + AccountStore.getCurrentAccount().getCurrency()}</div>}
        </div>
        <div className={classes.evolutionIconContainer}>
            {renderEvolutionIcon()}
        </div>
        <PricePopper
            anchor={anchor}
            isHovering={priceHover && scrapingData.to.price !== -2}
            from={scrapingData.from}
            to={scrapingData.to} />
    </div>;

    const isSiteOfCurrentAccount = AccountStore.getCurrentAccount().isRetailer() && website.id === AccountStore.getCurrentAccount().getSiteId();

    if (scrapingData.isLoading)
        return (
            <TableCell className={classes.tableCellLoading}>
                <div className={classes.priceCellContainerEmpty}>
                    <Loader size={50} />
                </div>
            </TableCell>
        );

    if (scrapingData.to.status === 'PENDING')
        return (
            <TableCell className={classes.tableCell}>
                <div className={classes.priceCellContainerEmpty} title={translate.i18n('PENDING_EXTRACTION')}>
                    <Typography variant='body2' color='inherit' noWrap className={classes.label} onClick={() => Facade.openUrl(scrapingData.url)}>
                        {translate.i18n('EXTRACTING')}
                    </Typography>
                    <div className={classes.bottomContainer}>
                        <Loader size={30} onClick={() => Facade.openUrl(scrapingData.url)} />
                        {UserStore.getCurrentUser().isScrapingDataEditor() && !hidePrices ?
                            <IconButton
                                onClick={() => openDialogAddUrl(scrapingData, website, item)}
                                className={classes.editIcon}
                                size='large'>
                                <UrlIcon />
                            </IconButton>
                            :
                            null
                        }
                        {UserStore.getCurrentUser().isScrapedDataEditor() && showEditIcon && !hidePrices ?
                            <IconButton
                                onClick={() => openDialogUpdateScrapedData(scrapingData, website, item)}
                                className={classes.editIcon}
                                size='large'>
                                <UpdateIcon />
                            </IconButton>
                            :
                            null
                        }
                    </div>
                </div>
            </TableCell>
        );

    if (scrapingData.to.status === 'UNDEFINED')
        return (
            <TableCell className={classes.tableCellEmpty}>
                <div className={!scrapingData.satisfiesFilters && !isSiteOfCurrentAccount ? classes.disabled : undefined}>
                    {UserStore.getCurrentUser().isScrapingDataEditor() ?
                        <div className={classes.priceCellContainerEmpty}>
                            <IconButton
                                color='default'
                                className={classes.button}
                                onClick={() => openDialogAddUrl(scrapingData, website, item)}
                                size='large'>
                                <AddIcon className={classes.addIcon} />
                            </IconButton>
                        </div>
                        :
                        null
                    }
                </div>
            </TableCell>
        );

    if (scrapingData.to.status === 'NV_BELL')
        return (
            <TableCell className={classes.tableCellEmpty}>
                <div className={classNames(classes.priceCellContainerEmpty, !scrapingData.satisfiesFilters && !isSiteOfCurrentAccount ? classes.disabled : undefined)}>
                    <div className={classes.nvBellContent}
                        onClick={() => Facade.openUrl(scrapingData.url)}
                        ref={node => setAnchor(node)}
                        onMouseEnter={() => handleMouseEnter('PRICE')}
                        onMouseLeave={handleMouseLeave}>
                        <Typography variant='body2' color='inherit' noWrap className={classes.label}>
                            {translate.i18n('NOT_SOLD')}
                        </Typography>
                        <BellIcon className={classes.bellIcon} />
                        <PricePopper
                            anchor={anchor}
                            isHovering={priceHover}
                            from={scrapingData.from}
                            to={scrapingData.to} />
                    </div>
                    {UserStore.getCurrentUser().isScrapingDataEditor() ?
                        <IconButton
                            color='default'
                            className={classes.button}
                            onClick={() => openDialogAddUrl(scrapingData, website, item)}
                            size='large'>
                            <AddIcon className={classes.addIcon} />
                        </IconButton>
                        :
                        null
                    }
                </div>
            </TableCell>
        );

    if (scrapingData.to.status === 'NV')
        return (
            <TableCell className={classes.tableCellEmpty}>
                <div className={classNames(classes.priceCellContainerEmpty, !scrapingData.satisfiesFilters && !isSiteOfCurrentAccount ? classes.disabled : undefined)}>
                    <Typography variant='body2' color='inherit' noWrap className={classes.label}>
                        {translate.i18n('NOT_SOLD')}
                    </Typography>
                    {UserStore.getCurrentUser().isScrapingDataEditor() ?
                        <IconButton
                            color='default'
                            className={classes.button}
                            onClick={() => openDialogAddUrl(scrapingData, website, item)}
                            size='large'>
                            <AddIcon className={classes.addIcon} />
                        </IconButton>
                        :
                        null
                    }
                </div>
            </TableCell>
        );

    return (
        <TableCell className={classes.tableCell}>
            <div className={classNames(classes.cellContent, !scrapingData.satisfiesFilters && !isSiteOfCurrentAccount ? classes.disabled : undefined)}>
                {!hideImages &&
                    <div className={classes.imgContainer}>
                        <ImageViewer
                            imageUrl={scrapingData.to.imgUrl}
                            title={item.name}
                            alt={item.name}
                        />
                    </div>
                }
                {!hideNotations &&
                    <div
                        className={classes.notationContainer}
                        ref={node => setAnchor(node)}
                        onMouseEnter={() => handleMouseEnter('NOTATION')}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => scrapingData.to.additionalData?.evaluation?.url ? Facade.openUrl(scrapingData.to.additionalData.evaluation.url) : Facade.openUrl(scrapingData.url)}>

                        <PricePopper
                            anchor={anchor}
                            isHovering={notationHover && scrapingData.to.price !== -2}
                            from={scrapingData.from}
                            to={scrapingData.to} />
                        <NotationSheet evaluation={scrapingData?.to.additionalData?.evaluation} />
                    </div>
                }
                {(!hidePrices || !hideStocks) &&
                    <div className={classes.informationContainer}>
                        {!hidePrices &&
                            <div ref={node => setAnchor(node)} onMouseEnter={() => handleMouseEnter('PRICE')} onMouseLeave={handleMouseLeave}>
                                {renderPrice()}
                            </div>
                        }
                        <div className={classes.stockEditContainer}>
                            {!hideStocks &&
                                <div ref={node => setAnchor(node)} onMouseEnter={() => handleMouseEnter('STOCK')} onMouseLeave={handleMouseLeave}>

                                    <PricePopper
                                        anchor={anchor}
                                        isHovering={stockHover && scrapingData.to.price !== -2}
                                        from={scrapingData.from}
                                        to={scrapingData.to} />

                                    {getStockIcon(scrapingData.to?.stock)}
                                </div>
                            }
                            <div className={classes.editContainer}>
                                <div>
                                    {UserStore.getCurrentUser().isScrapingDataEditor() && !hidePrices ?
                                        <IconButton
                                            onClick={() => openDialogAddUrl(scrapingData, website, item)}
                                            className={classes.editIcon}
                                            size='large'>
                                            <UrlIcon />
                                        </IconButton>
                                        :
                                        null
                                    }
                                </div>
                                <div>
                                    {UserStore.getCurrentUser().isScrapedDataEditor() && showEditIcon && !hidePrices ?
                                        <IconButton
                                            onClick={() => openDialogUpdateScrapedData(scrapingData, website, item)}
                                            className={classes.editIcon}
                                            size='large'>
                                            <UpdateIcon />
                                        </IconButton>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </TableCell>
    );
};

PriceCell.propTypes = {
    hideImages: PropTypes.bool,
    hideStocks: PropTypes.bool,
    item: PropTypes.object,
    openDialogAddUrl: PropTypes.func,
    openDialogDeleteItem: PropTypes.func,
    openDialogMarketPlace: PropTypes.func,
    openDialogUpdateScrapedData: PropTypes.func,
    scrapingData: PropTypes.object,
    website: PropTypes.object,
    showEditIcon: PropTypes.bool,
    hideNotations: PropTypes.bool,
    hidePrices: PropTypes.bool
};

export default PriceCell;
