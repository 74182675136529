import AbstractService from 'core/abstracts/AbstractService';
import Facade from 'core/Facade';

class ItemService extends AbstractService {

    /**
     * Get analyse of smart price extractor.
     */
    getAnalyse(options) {
        // If no options were provided return reject
        if (!options.accountId || !options.pageSize || !options.pageIndex || !options.from || !options.to) {
            return Promise.reject();
        }

        const from = options.from.getFullYear() + '-' + (options.from.getMonth() + 1) + '-' + options.from.getDate();
        const to = options.to.getFullYear() + '-' + (options.to.getMonth() + 1) + '-' + options.to.getDate();

        return this.request({
            method: 'POST',
            url: Facade.getApiRoot() + `spe/analyse?accountId=${options.accountId}&fromStr=${from}&toStr=${to}&pageSize=${options.pageSize}&pageIndex=${options.pageIndex}&sort=${options.sort}&mode=${options.mode}`,
            json: {
                categories: options.categories,
                leadership: options.leadership,
                prices_baskets: options.pricesBaskets,
                search_text: options.searchText,
                sites: options.websites,
                stocks_baskets: options.stocksBaskets
            }
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Get repriced items.
     */
    getRepricedItems(options) {
        // if no options were provided return reject
        if (!options.accountId || !options.pageSize || !options.page || !options.date) {
            return Promise.reject();
        }

        const formattedDate = options.date.getFullYear() + '-' + (options.date.getMonth() + 1) + '-' + options.date.getDate();

        return this.request({
            method: 'POST',
            url: Facade.getApiRoot() + `pricing-engine/analyse?accountId=${options.accountId}&dateStr=${formattedDate}&pageSize=${options.pageSize}&pageIndex=${options.page}`,
            json: {
                search_text: options.searchText,
                repriced_prices_group_ids: options.repricedPricesGroups,
                categories: options.categories,
                stocks_baskets: options.stocksBaskets,
                prices_leaderships: {
                    current: options.leaderships.current,
                    with_repricing: options.leaderships.withRepricing
                },
                prices_baskets: {
                    current: options.pricesBaskets.current,
                    with_repricing: options.pricesBaskets.withRepricing
                },
                pricing_engine_rule_ids: options.rules
            }
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Get items export.
     */
    getItemsExport(options) {
        const from = options.from.getFullYear() + '-' + (options.from.getMonth() + 1) + '-' + options.from.getDate();
        const to = options.to.getFullYear() + '-' + (options.to.getMonth() + 1) + '-' + options.to.getDate();

        // if no options were provided return reject
        if (!options.accountId) {
            return Promise.reject();
        }

        return this.request({
            method: 'POST',
            url: Facade.getApiRoot() + `spe/export?accountId=${options.accountId}&fromStr=${from}&toStr=${to}&sort=${options.sort}&mode=${options.mode}`,
            json: {
                search_text: options.searchText,
                categories: options.categories,
                prices_baskets: options.pricesBaskets,
                sites: options.websites,
                leadership: options.leadership,
                stocks_baskets: options.stocksBaskets
            }
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Get items export.
     */
    getRepricedItemsExport(options) {
        const date = options.date.getFullYear() + '-' + (options.date.getMonth() + 1) + '-' + options.date.getDate();

        // if no options were provided return reject
        if (!options.accountId) {
            return Promise.reject();
        }

        return this.request({
            method: 'POST',
            url: Facade.getApiRoot() + `pricing-engine/export?accountId=${options.accountId}&dateStr=${date}`,
            json: {
                search_text: options.searchText,
                repriced_prices_group_ids: options.repricedPricesGroups
            }
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Get product.
     */
    getProduct(options) {
        const date = options.to.getFullYear() + '-' + (options.to.getMonth() + 1) + '-' + options.to.getDate();

        // If no options were provided return reject
        if (!options.itemId || !options.to || !options.from) {
            return Promise.reject();
        }

        return this.request({
            method: 'GET',
            url: Facade.getApiRoot() + `accounts/${options.accountId}/products/${options.itemId}?date=${date}`,
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Delete product.
     */
    deleteProduct(options) {
        // If no options were provided return reject
        if (!options.itemId || !options.accountId) {
            return Promise.reject();
        }

        return this.request({
            method: 'DELETE',
            url: Facade.getApiRoot() + `accounts/${options.accountId}/products/${options.itemId}`,
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData;
            }
            throw await responseData.json();
        });
    }

    /**
     * Add item.
     */
    addItem(item, accountId) {
        // if no params were provided return reject
        if (!item || !accountId) {
            return Promise.reject();
        }

        return this.request({
            method: 'POST',
            url: Facade.getApiRoot() + `accounts/${accountId}/products`,
            json: item
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Update item.
     */
    updateItem(item, accountId) {
        // if no params were provided return reject
        if (!item || !accountId) {
            return Promise.reject();
        }
        return this.request({
            method: 'PUT',
            url: Facade.getApiRoot() + `accounts/${accountId}/products/${item.id}`,
            json: item.toJson()
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Update product category.
     */
    updateProductCategory(productId, categoryId, accountId) {
        // if no params were provided return reject
        if (!productId || !accountId || !categoryId) {
            return Promise.reject();
        }

        return this.request({
            method: 'PUT',
            url: Facade.getApiRoot() + `accounts/${accountId}/products/${productId}/category`,
            json: {
                category_id: categoryId
            }
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Get history.
     */
    getHistory(options) {
        const from = options.from.getFullYear() + '-' + (options.from.getMonth() + 1) + '-' + options.from.getDate();
        const to = options.to.getFullYear() + '-' + (options.to.getMonth() + 1) + '-' + options.to.getDate();

        return this.request({
            method: 'GET',
            url: Facade.getApiRoot() + `accounts/${options.accountId}/histories/${options.itemId}?fromStr=${from}&toStr=${to}`
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Update history.
     */
    updateHistory(options) {
        return this.request({
            method: 'PUT',
            url: Facade.getApiRoot() + `accounts/${options.accountId}/histories/${options.itemId}/sites/${options.siteId}?dateStr=${options.date}`,
            json: options.newScrapedData
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Update scraping data.
     */
    updateScrapingData(options) {
        return this.request({
            method: options.scrapingDataId === '' ? 'POST' : 'PUT',
            url: Facade.getApiRoot() + `accounts/${options.accountId}/scraping-data/${options.itemId}/${options.siteId}`,
            json: {
                status: options.status,
                params: options.params
            }
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * GET Scraped data
     */
    getScrapedData(options) {
        return this.request({
            method: 'GET',
            url: Facade.getApiRoot() + `accounts/${options.accountId}/scraped-data/${options.itemId}/${options.siteId}?date=${options.date.getFullYear() + '-' + (options.date.getMonth() + 1) + '-' + options.date.getDate()}`,
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Delete scraping data.
     */
    deleteScrapingData(options) {
        return this.request({
            method: 'DELETE',
            url: Facade.getApiRoot() + `accounts/${options.accountId}/scraping-data/${options.itemId}/${options.siteId}`,
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.ok;
            }
            throw await responseData.json();
        });
    }
}

export default new ItemService();
