import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    nameCell: {
        cursor: 'pointer'
    },
    table: {
        height: '100%',
        width: '100%'
    },
    tableHead: {
        backgroundColor: theme.palette.white.main,
        borderBottom: '1px solid' + theme.palette.grey.light,
        position: 'sticky',
        top: 0,
        zIndex: 10
    }
}));
