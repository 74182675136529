// Facade
import Facade from 'core/Facade';
// Locales
import translate from 'locales/i18n';
// Stores
import AccountStore from 'stores/AccountStore';

/**
  * Method called to get the strategy object ready for api
  *
  * @param strategy <object> the strategy object
  * @returns <object> the new object ready for api call
  */
export const getStrategy = (strategy) => {
    if (!strategy) return { type: 'STANDARD_STRATEGY' };

    if (strategy.positioning === 'SELF_ORIENTED_STRATEGY') {
        return {
            type: 'SELF_ORIENTED_STRATEGY',
            element: strategy.element,
            offset: {
                mode: strategy.offset.mode,
                value: Number(strategy.offset.sign + strategy.offset.value)
            },
            rounding: strategy.rounding !== 'TO_PRICE' ? strategy.rounding : undefined
        };
    }

    const formattedStrategy = {
        type: 'STANDARD_STRATEGY',
        positioning: strategy.positioning,
        offset: {
            mode: strategy.offset.mode,
            value: Number(strategy.offset.sign + strategy.offset.value)
        }
    };

    if (strategy.competitors !== 'ALL_COMPETITORS') {
        formattedStrategy.filtering_site_ids = strategy.filteringSiteIds;
    }

    if (strategy.stocks !== 'ALL_STOCKS') {
        formattedStrategy.filtering_stocks = strategy.filteringStocks;
    }

    if (strategy.isLowLimitPrice !== 'WITHOUT_LOW_LIMIT') {
        if (!strategy.lowLimitPrice.value)
            strategy.lowLimitPrice.value = parseFloat(0);
        formattedStrategy.low_limit_price = strategy.lowLimitPrice;
    }

    if (strategy.isHighLimitPrice !== 'WITHOUT_HIGH_LIMIT') {
        if (!strategy.highLimitPrice.value)
            strategy.highLimitPrice.value = parseFloat(0);
        formattedStrategy.high_limit_price = strategy.highLimitPrice;
    }

    if (strategy.rounding !== 'TO_PRICE') {
        formattedStrategy.rounding = strategy.rounding;
    }

    return formattedStrategy;
};

/**
  * Method called to get the resume of strategy
  *
  * @param strategy <object> the strategy object
  * @returns <string> the resume of strategy
  */
export const getResume = (strategy, from, to) => {
    if (!strategy || strategy.type === 'SELF_ORIENTED_STRATEGY') return '';

    const { competitors, stocks, rounding, isHighLimitPrice, isLowLimitPrice, lowLimitPrice, highLimitPrice } = strategy;
    let resume = `${translate.i18n(competitors)}, ${translate.i18n(stocks)}, `;

    if (isLowLimitPrice === 'WITHOUT_LOW_LIMIT')
        resume += translate.i18n('WITHOUT_LOW_LIMIT');
    else {
        resume += translate.i18n('WITH_LOW_LIMIT');
        resume += ` ${lowLimitPrice.value < 0 ? '' : '+'}${lowLimitPrice.value}${lowLimitPrice.mode === 'PERCENTAGE' ? '%' : AccountStore.getCurrentAccount().getCurrency()} ${translate.i18n('OF_MY')} ${translate.i18n(lowLimitPrice.element)}, `;
    }

    if (isHighLimitPrice === 'WITHOUT_HIGH_LIMIT')
        resume += ` ${translate.i18n('WITHOUT_HIGH_LIMIT')}, `;
    else {
        resume += translate.i18n('WITH_HIGH_LIMIT');
        resume += ` ${highLimitPrice.value < 0 ? '' : '+'}${highLimitPrice.value}${highLimitPrice.mode === 'PERCENTAGE' ? '%' : AccountStore.getCurrentAccount().getCurrency()} ${translate.i18n('OF_MY')} ${translate.i18n(highLimitPrice.element)}, `;
    }

    resume += ` ${translate.i18n(rounding)}`;

    if (from || to) {
        resume += ' (';

        if (from) {
            resume += translate.i18n('FROM') + ': ';
            resume += Facade.formatDate(from);
        }

        if (to) {
            resume += ', ' + translate.i18n('TO') + ': ';
            resume += Facade.formatDate(to);
        }

        resume += ')';
    }

    return resume;
};

