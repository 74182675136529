// React
import React from 'react';
import PropTypes from 'prop-types';
// Locales
import translate from 'locales/i18n';
// Store
import LayoutStore from 'stores/LayoutStore';
// Styles
import { useStyles } from 'components/pages/ErrorPage/ErrorPage.style';
// Actions
import SignAction from 'actions/SignAction';

const ErrorPage = () => {
    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.smiley}>:-(</div>
        <div className={classes.doNotHaveModuleText}>
            {translate.i18n(LayoutStore.getError())}
        </div>
        <div className={classes.getModuleText}>
            {translate.i18n('CONTACT_SUPPORT')}, <a href='https://pricecomparator.pro/contact/' target='_blank' className={classes.link} rel='noreferrer'>{translate.i18n('CLICK_HERE')}</a>
        </div>
        <div className={classes.logoutText} onClick={() => SignAction.logout()}>
            {translate.i18n('LOGOUT')}
        </div>
    </div>;
};

ErrorPage.propTypes = {
    error: PropTypes.string
};

export default ErrorPage;
