class User {

    constructor(data) {
        this.id = data?.id;
        this.login = data?.login;
        this.settings = data?.settings;
        this.firstname = data?.firstName;
        this.lastname = data?.lastName;
        this.accountId = data?.accountId;
        this.accounts = data?.associated_accounts;
        this.superUser = data?.is_super_user;
        this.permissions = data?.permissions;
        this.token = localStorage.getItem('pricecomparator_token');
        this.isLogged = false;
    }

    /**
     * GETTERS
     */
    getId() {
        return this.id;
    }

    getLogin() {
        return this.login;
    }

    getToken() {
        return this.token;
    }

    getLanguage() {
        return this.settings.language;
    }

    getPageSize() {
        return this.settings.pageSize;
    }

    getSettings() {
        return this.settings;
    }

    getFirstname() {
        return this.firstname;
    }

    getLastname() {
        return this.lastname;
    }

    getAccounts() {
        return this.accounts;
    }

    getSuperUser() {
        return this.superUser;
    }

    /**
     * SETTERS
     */
    setLogin(login) {
        this.login = login;
    }

    setToken(token) {
        this.token = token;
    }

    setLanguage(lang) {
        this.settings.language = lang;
    }

    setFirstname(firstname) {
        this.firstname = firstname;
    }

    setLastname(lastname) {
        this.lastname = lastname;
    }

    setAccountId(accounts) {
        this.accounts = accounts;
    }

    setSuperUser(superUser) {
        this.superUser = superUser;
    }

    /**
     * METHODS
     */
    isLogged() {
        return this.isLogged;
    }

    isSuperUser() {
        return this.permissions ? this.permissions.SuperUser : false;
    }

    isScrapedDataEditor() {
        return this.permissions ? this.permissions.SPEScrapedDataEditor : false;
    }

    isScrapingDataEditor() {
        return this.permissions ? this.permissions.SPEScrapingDataEditor : false;
    }
}

export default User;
