/**
  * Method which return the color for the price
  *
  * @param scrapingData <object> Scraping Data object needed to find the color
  * @param item <object> Item object of the scraping data juste nbefore
  * @param website <object> Website object which is the current website
  * @param currentAccount <AccountObject> Account Object the current account
  * @param theme The theme of the application
  * @returns <string> of stock color
  */
export const priceColor = (scrapingData, item, website, currentAccount,theme) => {
    if (!theme) return '#7A7A7A';
    if (!scrapingData || scrapingData.to.price === -2 || scrapingData.to.price === -1) {
        return theme.palette.grey.semidark;
    } else if (currentAccount.isRetailer()) {
        // Behaviour case of retailer
        const isSiteOfCurrentAccount = website.id === currentAccount.getSiteId();
        if (isSiteOfCurrentAccount) {
            // Behaviour case of site of current retailer
            if (scrapingData.to.price <= item.toMinPrice)
                return theme.palette.green.main;
            if (scrapingData.to.price > item.toMinPrice)
                return theme.palette.red.main;
        } else {
            if (scrapingData.to.price <= item.toMinPrice)
                return theme.palette.green.main;
        }
    } else {
        // Behaviour case of supplier
        if (scrapingData.to.price < item.alertingLowPrice && scrapingData.to.price === item.toMinPrice)
            return theme.palette.red.main;
        if (scrapingData.to.price < item.alertingLowPrice)
            return theme.palette.orange.main;
        if (scrapingData.to.price >= item.alertingLowPrice && scrapingData.to.price <= item.rrp)
            return theme.palette.green.main;
    }

    return theme.palette.grey.semidark;
};

export const displayPrice = (price) => {
    let priceString = typeof price === 'number' ? price.toString() : price;

    priceString = priceString.split('.');
    if (priceString.length < 2) return price;

    if (priceString[1].length === 1) return `${priceString[0]},${priceString[1]}0`;
    return `${priceString[0]},${priceString[1]}`;
};

