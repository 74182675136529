import makeStyles from '@mui/styles/makeStyles';
// Images
import phoneEmpty from 'assets/img/phone-empty.png';

export const useStyles = makeStyles((theme) => ({
    iconMail: {
        fontSize: '85%',
        marginRight: '5px'
    },
    iframeMail: {
        height: '250%',
        MozTransform: 'scale(0.4)',
        MozTransformOrigin: '0 0',
        MsZoom: '0.4',
        OTransform: 'scale(0.4)',
        OTransformOrigin: '0 0',
        overflowX: 'hidden',
        overflowY: 'auto',
        pointerEvents: 'none',
        WebkitTransform: 'scale(0.4)',
        WebkitTransformOrigin: '0 0',
        width: '250%'
    },
    mailBox: {
        backgroundImage: `url(${phoneEmpty})`,
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'calc(100% + 2px) calc(100% + 2px)',
        height: '600px',
        maxWidth: '400px',
        padding: '37px calc(5% + 1px) 0px calc(5% + 2px)',
        width: '100%',
        borderRadius: '50px'
    },
    mailContent: {
        height: 'calc(100% - 60px)',
        overflow: 'hidden',
        width: '100%',
        borderRadius: '0px 0px 30px 30px',
        backgroundColor: theme.palette.white.main
    },
    mailDate: {
        fontWeight: 'normal'
    },
    mailTitle: {
        fontSize: '70%',
        margin: '0',
        overflow: 'hidden',
        padding: '0'
    },
    notifContainer: {
        '&:hover': {
            backgroundColor: theme.palette.grey.dark,
            cursor: 'pointer'
        },
        height: '45px',
        margin: '0',
        overflowX: 'hidden',
        overflowY: 'hidden',
        borderRadius: '5px',
        padding: '2px 2px 0px 2px',
    },
    senderTitle: {
        fontSize: '70%',
        fontWeight: 'bold',
        margin: '0',
        padding: '0',
        textAlign: 'left'
    },
}));
