// React
import React from 'react';
import PropTypes from 'prop-types';
// Locales
import translate from 'locales/i18n';
import withStyles from '@mui/styles/withStyles';
// Styles
import styles from 'components/pages/NotAccessPage/NotAccessPage.style';

class NotAccess extends React.PureComponent {

    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.smiley}>:-(</div>
                <div className={classes.doNotHaveModuleText}>
                    {translate.i18n('YOU_DO_NOT_HAVE_ACCESS') + '!'}
                </div>
                <div className={classes.getModuleText}>
                    {translate.i18n('TO_REQUEST_IT')}, <a href='https://pricecomparator.pro/formulaire-packs-modules/' target='_blank' className={classes.link} rel='noreferrer'>{translate.i18n('CLICK_HERE')}</a>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(NotAccess);
