import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    priceSectionContainer: {
        backgroundColor: theme.palette.white.main,
        borderRadius: '5px',
        boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.37)',
        margin: '10px',
        padding: '10px 10px 20px 10px'
    },
    chartSectionFullScreen: {
        backgroundColor: theme.palette.white.main,
        borderRadius: '5px',
        boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.37)',
        height: '98vh',
        left: '0.5vh',
        padding: '10px',
        position: 'absolute',
        top: '0.5vw',
        width: '99vw',
        zIndex: 9999
    },
    priceSection: {
        display: 'flex',
        height: '95%'
    },
    contentTitleContainer: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center'
    },
    contentTitle: {
        color: '#0E5562',
        fontSize: '20px',
        fontWeight: '500',
        paddingRight: '10px'
    },
    priceDetails: {
        [theme.breakpoints.down('md')]: {
            fontSize: '10px',
            margin: '5px 0 0 5px'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '15px',
            margin: '5px 0 0 20px'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '18px',
            margin: '5px 0 0 0px'
        },
        color: '#808080',
        fontFamily: 'roboto',
        lineHeight: '1.8',
        width: '35%'
    },
    priceDetailsBold: {
        fontWeight: 'bold'
    },
}));
