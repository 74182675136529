import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Style
import styles from 'components/sheet/ProductInfoSheet/ProductInfoSheet.style';
// Material UI
import withStyles from '@mui/styles/withStyles';
import IconUrl from '@mui/icons-material/OpenInNew';
import SettingsIcon from '@mui/icons-material/Settings';
import InputAdornment from '@mui/material/InputAdornment';
import Unlock from '@mui/icons-material/LockOpen';
import Lock from '@mui/icons-material/Lock';
import IconButton from '@mui/material/IconButton';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
// Util
import { capitalize } from 'components/sheet/ProductInfoSheet/ProductInfoSheet.util';
// Locales
import translate from 'locales/i18n';
import AccountStore from 'stores/AccountStore';
import DialogConfirm from 'components/dialog/DialogConfirm/DialogConfirm';
// Facade
import Facade from 'core/Facade';
//PCP components
import DialogAddProduct from 'components/dialog/DialogAddProduct/DialogAddProduct';

const ProductInfoSheet = ({ classes, currentItem, edit, categories, updateItem, handleManageCategories, blur }) => {
    const [canEditCMS, setCanEditCMS] = useState(false);
    const [dialogConfirmOpen, setDialogConfirmOpen] = useState(false);

    const [openEditAttributes, setOpenEditAttributes] = useState(false);
    const cmsIdRef = React.useRef();

    return (
        edit ?
            <div className={classNames(classes.productInfos)}>
                <div className={classes.productEdit}>
                    <TextField
                        variant='standard'
                        label={translate.i18n('NAME')}
                        id='productName'
                        className={classes.productNameEditContainer}
                        InputProps={{
                            className: classes.productNameEdit
                        }}
                        value={currentItem.name}
                        onChange={(e) => updateItem('name', e.target.value)}
                    />
                </div>
                <div className={classes.productEdit}>
                    <FormControl className={classes.formControl}
                        variant='standard'>
                        <InputLabel id='productCategoryLabel'>{translate.i18n('CATEGORY')}</InputLabel>
                        <Select
                            label={translate.i18n('CATEGORY')}
                            id='productCategory'
                            value={currentItem.category.id}
                            onChange={(e) => updateItem('category', e.target.value)}
                        >
                            {categories.map((value, index) =>
                                <MenuItem key={index} value={value.id}>{capitalize(value.name)}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <SettingsIcon color='primary' className={classes.categorySetting} onClick={() => handleManageCategories()} />
                </div>
                <div className={classes.productEdit}>
                    <TextField
                        variant='standard'
                        label={'CMS ID'}
                        id='cmsId'
                        className={classes.productNameEditContainer}
                        value={currentItem.cmsProductId}
                        onChange={(e) => updateItem('cmsProductId', e.target.value)}
                        disabled={!canEditCMS}
                        inputRef={cmsIdRef}
                        InputProps={{
                            onBlur: () => setCanEditCMS(false),
                            className: classes.productNameEdit,
                            endAdornment:
                                <InputAdornment position='end'>
                                    <IconButton
                                        onClick={() => setDialogConfirmOpen(true)}
                                        size='large'>
                                        {!canEditCMS ? <Lock /> : <Unlock />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </div>
                {AccountStore.getCurrentAccount().isRetailer() &&
                    <div className={classes.productEdit}>
                        <TextField
                            variant='standard'
                            label='CMS STOCK'
                            id='productCMSStock'
                            className={classes.productNameEditContainer}
                            InputProps={{
                                className: classes.productNameEdit
                            }}
                            type='number'
                            value={currentItem.cmsLastKnownStock}
                            onChange={(e) => updateItem('cmsLastKnownStock', e.target.value, false)}
                        />
                    </div>
                }
                {AccountStore.getCurrentAccount().isRetailer()
                    && <div className={classes.productEdit}>
                        <TextField
                            variant='standard'
                            label={translate.i18n('RETAIL_PRICE')}
                            id='productPurchasingPrice'
                            className={classes.productNameEditContainer}
                            InputProps={{
                                className: classes.productNameEdit,
                                startAdornment: <InputAdornment position='start'>{AccountStore.getCurrentAccount().currency.symbol}</InputAdornment>,
                            }}
                            value={currentItem.purchasingPrice}
                            onChange={(e) => updateItem('purchasingPrice', e.target.value, true)}
                        />
                    </div>
                }
                <div className={classes.productEdit}>
                    <TextField
                        variant='standard'
                        error={AccountStore.getCurrentAccount().isSupplier() && currentItem.rrp === ''}
                        label='RRP'
                        id='productRRP'
                        className={classes.productNameEditContainer}
                        InputProps={{
                            className: classes.productNameEdit,
                            startAdornment: <InputAdornment position='start'>{AccountStore.getCurrentAccount().currency.symbol}</InputAdornment>,
                        }}
                        value={currentItem.rrp}
                        onChange={(e) => updateItem('rrp', e.target.value, true)}
                    />
                </div>
                {AccountStore.getCurrentAccount().isSupplier()
                    && <div className={classes.productEdit}>
                        <TextField
                            variant='standard'
                            error={currentItem.rrpAlertPercentage === ''}
                            helperText={currentItem.rrpAlertPercentage === '' ? translate.i18n('MISSING_RRP_PERCENTAGE') : ''}
                            label={translate.i18n('DISCOUNT')}
                            id='productRRPPercentage'
                            className={classes.productNameEditContainer}
                            InputProps={{
                                className: classes.productNameEdit,
                                startAdornment: <InputAdornment position='start'>%</InputAdornment>,
                            }}
                            value={currentItem.rrpAlertPercentage}
                            onChange={(e) => updateItem('rrpAlertPercentage', e.target.value, true)}
                        />
                    </div>
                }
                {AccountStore.getCurrentAccount().isSupplier()
                    && <div className={classes.productEdit}>
                        <TextField
                            variant='standard'
                            label={translate.i18n('LIMIT')}
                            id='alertingLowPrice'
                            className={classes.productNameEditContainer}
                            InputProps={{
                                className: classes.productNameEdit,
                                startAdornment: <InputAdornment position='start'>{AccountStore.getCurrentAccount().currency.symbol}</InputAdornment>,
                            }}
                            value={currentItem.alertingLowPrice}
                            disabled
                        />
                    </div>
                }
                <div className={classes.productEdit}>
                    <TextField
                        variant='standard'
                        label={translate.i18n('IMG_URL')}
                        id='productImgUrl'
                        className={classes.productNameEditContainer}
                        InputProps={{
                            className: classes.productNameEdit
                        }}
                        value={currentItem.imgUrl}
                        onChange={(e) => updateItem('imgUrl', e.target.value)}
                    />
                </div>
                <div className={classes.productEdit}>
                    <Button color='primary' onClick={() => setOpenEditAttributes(true)}>
                        {translate.i18n('CHANGE_ATTRIBUTES')}
                    </Button>
                </div>
                <DialogAddProduct
                    open={openEditAttributes}
                    onClose={() => setOpenEditAttributes(false)}
                    defaultValues={currentItem.customAttributes}
                    setCustomAttributes={(ca) => updateItem('customAttributes', ca)}
                />
                <DialogConfirm open={dialogConfirmOpen}
                    close={() => setDialogConfirmOpen(false)}
                    callback={() => {
                        setCanEditCMS(true);
                        setTimeout(() => cmsIdRef.current.focus(), 100);
                    }}
                    text={translate.i18n('WARNING_CMS_ID')}
                    content={<div><div>{translate.i18n('WARNING_CMS_ID_CONTENT_FIRST')}</div><br /><div>{translate.i18n('WARNING_CMS_ID_CONTENT_SECOND')}</div></div>} />
            </div>
            :
            <div className={classes.productInfos}>
                {currentItem.name && <div className={classes.productName}>{currentItem.name}</div>}
                {currentItem.cmsProductId && AccountStore.getCurrentAccount().isRetailer() && <div className={classes.productInfosText}>{'CMS ID'} : {currentItem.cmsProductId}</div>}
                {currentItem.category.name && <div className={classes.productInfosText} title={`Category : ${currentItem.category.name}`}>{translate.i18n('CATEGORY')} : {currentItem.category.name}</div>}
                {currentItem.cmsLastKnownStock && AccountStore.getCurrentAccount().isRetailer() && <div className={classes.productInfosText}>{'CMS STOCK'} : {currentItem.cmsLastKnownStock}</div>}
                {currentItem.purchasingPrice && AccountStore.getCurrentAccount().isRetailer() && <div className={classes.productInfosText}><div>{translate.i18n('RETAIL_PRICE')} :&nbsp;</div><div className={ blur? classes.blur:null }> {currentItem.purchasingPrice}</div></div>}
                {currentItem.margin && AccountStore.getCurrentAccount().isRetailer() && <div className={classes.productInfosText}><div>{translate.i18n('MARGIN')} :&nbsp;</div> <div className={ blur? classes.blur:null }>{currentItem.margin}</div></div>}
                {currentItem.alertingLowPrice && AccountStore.getCurrentAccount().isSupplier() && <div className={classes.productInfosText}><div>{translate.i18n('LIMIT_PRICE')} :&nbsp;</div> <div className={ blur? classes.blur:null }>{parseInt(currentItem.alertingLowPrice).toFixed(2).replace('.', ',') + AccountStore.getCurrentAccount().getCurrency()}</div></div>}
                {currentItem.rrpAlertPercentage && AccountStore.getCurrentAccount().isSupplier() && <div className={classes.productInfosText}><div>{translate.i18n('DISCOUNT')} :&nbsp;</div> <div className={ blur? classes.blur:null }>{currentItem.rrpAlertPercentage + '%'}</div></div>}
                {currentItem.rrp && <div className={classes.productInfosText}>{translate.i18n('RRP')} : {currentItem.rrp.replace('.', ',') + AccountStore.getCurrentAccount().getCurrency()}</div>}
                {currentItem.customAttributes.map(attribute => <div className={classes.productInfosText} key={attribute.name}>{attribute.name} : {attribute.value.startsWith('http') ? <IconUrl onClick={() => Facade.openUrl(attribute.value)} /> : attribute.value}</div>)}
            </div>
    );
};

ProductInfoSheet.propTypes = {
    classes: PropTypes.object.isRequired,
    currentItem: PropTypes.object,
    categories: PropTypes.array,
    edit: PropTypes.bool,
    updateItem: PropTypes.func,
    handleManageCategories: PropTypes.func,
    blur: PropTypes.bool
};

export default withStyles(styles)(ProductInfoSheet);
