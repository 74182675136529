// Locales
import translate from 'locales/i18n';

// Array of names of the days
const daysNames = [translate.i18n('SUNDAY'),
    translate.i18n('MONDAY'),
    translate.i18n('TUESDAY'),
    translate.i18n('WEDNESDAY'),
    translate.i18n('THURSDAY'),
    translate.i18n('FRIDAY'),
    translate.i18n('SATURDAY')
];
/**
  * Method which format a date to display it with day name or without day name
  *
  * @param dateToFormat <Date> object
  * @param withDay <boolean>
  * @returns <string> with the formatted date
  */
export const formatDate = (dateToFormat, withDay) => {
    const date = new Date(dateToFormat);

    return (withDay?
        daysNames[date.getDay()].charAt(0).toUpperCase() + daysNames[date.getDay()].substring(1).toLowerCase() + ' ' : '') +
        (date.getDate() >= 10 ?
            date.getDate() : '0'+ date.getDate()) + '/' +
            (date.getMonth() + 1 >= 10 ?
                date.getMonth() + 1 : '0'+ (date.getMonth() + 1)) + '/' + date.getFullYear();
};

/**
 * Methode qui donne la couleur des point de la timeline.
 * La couleur est donnée en fonction du max et du min.
 *
 * @param  dayCount <number> Le nombre de slides de la journée
 * @param {*} scale <object> L'échelle, qui correspond au min et au max de toute la durée selectionée
 * @param {*} theme
 * @returns <string> La couleur
 */
export const getColor = (dayCount, scale, theme) => {
    if (!scale || scale.min === null || !scale.max || typeof dayCount !== 'number') return theme.palette.primary.main;
    const step = (scale.max - scale.min) / 3;

    switch (true) {
    case dayCount < scale.min + step:
        return theme.palette.primary.light;
    case dayCount < scale.min + 2 * step:
        return theme.palette.primary.main;
    default:
        return theme.palette.primary.dark;
    }
};

