import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    paper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '280px',
        width: '100%',
        position: 'relative',
    },
    graphContainer: {
        alignItems: 'center',
        height: 'calc(100% - 50px)',
        justifyContent: 'center',
        margin: 0,
        padding: '0 5px 5px 5px',
        width: '90%'
    },
    titleGraph: {
        color: theme.palette.grey.semidark,
        margin: '15px 0 10px 0',
        padding: 0,
        fontSize: '13px'
    },
}));
