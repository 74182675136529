class MarketplaceOffer {

    constructor(data) {
        this.sellerName = data.seller_name;
        this.price = data.price;
        this.shippingCost = data.shipping_cost;
        this.availability = data.availability;
        this.shippingInfo = data.shipping_info;
    }

}

export default MarketplaceOffer;
