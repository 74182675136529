// Abstract
import AbstractStore from 'core/abstracts/AbstractStore';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';

class RuleStore extends AbstractStore {

    constructor() {

        const CHANGE_EVENT = 'RULE_CHANGE_EVENT';
        const NAME = 'RULE_STORE';

        super(NAME, CHANGE_EVENT);

        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));

        this._initStore();
    }

    _initStore() {
        this.rules = [];
        this.loadingRules = true;
        this.loadingItems = false;
        this.repricedItems = [];
        this.categories = [];
        this.rulesDistribution = [];
        this.repricedPricesGroups = [];
        this.pricesBaskets = [];
        this.websites = [];
        this.count = 0;
        this.exportingRepricedItems = false;
        this.params = {
            page: 1,
            pageSize: 25,
            date: new Date(),
            categories: [],
            stocksBaskets: [],
            repricedPricesGroups: [],
            rules: [],
            leaderships: {
                current: [],
                withRepricing: []
            },
            pricesBaskets: {
                current: [],
                withRepricing: []
            },
            searchText: ''
        };
        this.positioningKpis = {};
    }

    deleteRule(ruleId) {
        this.rules = this.rules.filter(rule => rule.id !== ruleId);
    }

    isLoadingRules() {
        return this.loadingRules;
    }

    isLoadingItems() {
        return this.loadingItems;
    }

    isExportingRepricedItems() {
        return this.exportingRepricedItems;
    }

    getRepricedItems() {
        return this.repricedItems;
    }

    getRepricedPricesGroups() {
        return this.repricedPricesGroups;
    }

    getPricesBaskets() {
        return this.pricesBaskets;
    }

    getCategories() {
        return this.categories;
    }

    getRulesDistribution() {
        return this.rulesDistribution;
    }

    getParams() {
        return this.params;
    }

    getRules(){
        return this.rules;
    }

    getWebsites(){
        return this.websites;
    }

    getPositioningKpis(){
        return this.positioningKpis;
    }

    setRules(rules) {
        this.rules = rules;
    }

    setParams(params) {
        this.params = params;
    }

    setRepricedItems(items) {
        this.repricedItems = items;
    }

    setRepricedPricesGroups(repricedPricesGroups) {
        this.repricedPricesGroups = repricedPricesGroups;
    }

    setCategories(categories) {
        this.categories = categories;
    }

    setRulesDistribution(rulesDistribution) {
        this.rulesDistribution = rulesDistribution;
    }

    setPricesBaskets(pricesBaskets) {
        this.pricesBaskets = pricesBaskets;
    }

    setWebsites(websites) {
        this.websites = websites;
    }

    setPositioningKpis(kpis) {
        this.positioningKpis = kpis;
    }

    _registerToActions(action) {
        switch (action.actionType) {
        case Constants.PRICING_ENGINE_GET_RULES:
            this.setRules(action.value);
            this.loadingRules = false;
            this._emitChange();
            break;
        case Constants.REPRICED_ITEMS_GET_START:
            this.loadingItems = true;
            this.setParams(action.value);
            this._emitChange();
            break;
        case Constants.REPRICED_ITEMS_GET_END:
            this.setRepricedItems(action.value.items);
            this.setWebsites(action.value.sites);
            this.setPositioningKpis(action.value.kpis);
            this.setRepricedPricesGroups(action.value.repricedPricesGroups);
            this.setCategories(action.value.categories);
            this.setRulesDistribution(action.value.rulesDistribution);
            this.setPricesBaskets(action.value.pricesBaskets);
            this.count = action.value.count;
            this.loadingItems = false;
            this._emitChange();
            break;
        case Constants.PRICING_ENGINE_ITEM_EXPORT_START:
            this.exportingRepricedItems = true;
            this._emitChange();
            break;
        case Constants.PRICING_ENGINE_ITEM_EXPORT_END:
            this.exportingRepricedItems = false;
            this._emitChange();
            break;
        case Constants.PRICING_ENGINE_DELETE_RULE:
            this.deleteRule(action.value);
            this._emitChange();
            break;
        default:
            return false;
        }
    }
}

export default new RuleStore();
