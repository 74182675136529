import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Styles
import { useStyles } from 'components/dialog/DialogAddUrl/DialogAddUrl.style.js';
//Mui components
import { Card, CardMedia, Grid, Tooltip, TextField, Button } from '@mui/material';
//Pcp components
import ImageViewer from 'components/commons/ImageViewer/ImageViewer';
import AlertAction from 'actions/AlertAction';
//Mui icons
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import BellIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
// Locales
import translate from 'locales/i18n';

const DialogAddUrlSiteProduct = ({
    selectedProduct,
    newUrl,
    setNewUrl,
    displayPrice,
    scrapingData,
    siteName,
    setDialogConfirmDelete,
    changeStatus,
    setDialogEditOpen,
    setDialogConfig,
    itemName,
    displayCategories,
    websiteUrls
}) => {

    const classes = useStyles();

    const shouldDisplayInfo = () => selectedProduct && newUrl.split('?')[0] === selectedProduct.url?.split('?')[0];

    const [productInfos, setProductInfos] = useState({});

    const getStatus = (product) => {
        const status = product.status ? product.status : product.scrapedData?.status ? product.scrapedData.status : product.score ? 'SUGGESTION' : 'UNDEFINED';
        const price = product.price ? product.price : product.scrapedData?.price ? product.scrapedData.price : 0;
        if (product.status !== 'SOLD' || price >= 0) {
            return status;
        }
        if (price === -1) return 'NV_BELL';
        if (price === -101) return 'PENDING';
    };

    const formatUrl = (url) => url.slice(url.indexOf('www'), url.lenght).split('/')[0];

    const verifyUrl = (url) => {
        let res = false;
        websiteUrls.forEach(websiteUrl => {
            if (url.includes(formatUrl(websiteUrl))) res = true;
        });
        return res;
    };

    useEffect(() => {
        const infos = {
            status: getStatus(selectedProduct),
            price: selectedProduct.price ? displayPrice(selectedProduct.price) : selectedProduct.scrapedData?.price ? displayPrice(selectedProduct.scrapedData.price) : ' - ',
            brand: selectedProduct.brand ? selectedProduct.brand : ' - ',
            categories: selectedProduct.categories,
            imgUrl: selectedProduct.imgUrl ? selectedProduct.imgUrl : selectedProduct.scrapedData?.imgUrl ? selectedProduct.scrapedData.imgUrl : '',
            name: selectedProduct.name ? selectedProduct.name : itemName
        };

        setProductInfos(infos);
    }, [selectedProduct, newUrl]);

    return <Card className={classes.siteProduct}>
        <Grid container>
            {productInfos && productInfos.status !== 'UNDEFINED' ?
                <>
                    {shouldDisplayInfo() && (productInfos.status === 'SOLD' || productInfos.status === 'SUGGESTION') ?
                        <>
                            <Grid item className={classes.siteProductInfoImage} xs={4}>
                                <CardMedia className={classes.siteProductImage}>
                                    <ImageViewer imageUrl={productInfos.imgUrl} />
                                </CardMedia>
                            </Grid>
                            <Grid item xs={5} className={classes.siteProductInfo}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.productName}>
                                        {productInfos.name}
                                    </Grid>
                                    <Grid item xs={6} className={classes.productInfo}>
                                        {translate.i18n('DIALOG_ADD_URL.BRAND')} :
                                    </Grid>
                                    <Grid item xs={6} className={classes.productInfo}>
                                        {productInfos.brand}
                                    </Grid>
                                    <Grid item xs={6} className={classes.productInfo}>
                                        {translate.i18n('DIALOG_ADD_URL.PRICE')} :
                                    </Grid>
                                    <Grid item xs={6} className={classes.productInfo}>
                                        {productInfos.price}
                                    </Grid>
                                    <Grid item xs={6} className={classes.productInfo}>
                                        {translate.i18n('DIALOG_ADD_URL.CATEGORY')} :
                                    </Grid>
                                    {productInfos.categories ?
                                        <Tooltip
                                            title={displayCategories(productInfos.categories)}
                                            placement='top'
                                            disableInteractive
                                            classes={{
                                                tooltip: classes.categoryTooltip
                                            }}>
                                            <Grid item xs={5} className={classes.hideOverflow}>
                                                {productInfos.categories[0].split('>').at(-1)}
                                            </Grid>
                                        </Tooltip>
                                        :
                                        <Grid item xs={5}>
                                            -
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </>
                        :
                        productInfos.status === 'PENDING' ?
                            <Grid item xs={9} className={classes.noProduct}>
                                {translate.i18n('DIALOG_ADD_URL.PENDING_PRODUCT')}
                            </Grid>
                            :
                            shouldDisplayInfo() ?
                                <Grid item xs={9} className={classes.noProduct}>
                                    {translate.i18n('DIALOG_ADD_URL.NV')}
                                </Grid>
                                :
                                <Grid item xs={9} className={classes.noProduct}>
                                    {translate.i18n('DIALOG_ADD_URL.NEW_PRODUCT')}
                                </Grid>

                    }
                    <Grid item xs={3} className={classes.siteConfigs}>
                        <Grid container>
                            <Grid item xs={12} className={classes.configsButton}>
                                <Tooltip title={translate.i18n('DIALOG_ADD_URL.DELETE_URL')} placement='top' disableInteractive>
                                    <Button variant='outlined'
                                        disabled={scrapingData.id === ''}
                                        color='red'
                                        className={classes.siteConfigsButtons}
                                        style={{ color: 'red' }}
                                        size='small'
                                        onClick={() => setDialogConfirmDelete(true)}>
                                        {translate.i18n('DIALOG_ADD_URL.DELETE')}
                                        <DeleteIcon className={classes.rightIcon} color='red' />
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} className={classes.configsButton}>
                                <Tooltip title={translate.i18n('DIALOG_ADD_URL.NV_URL')} placement='top' disableInteractive>
                                    <Button variant='contained'
                                        color={scrapingData.to.status === 'NV' ? 'primary' : 'inherit'}
                                        className={classes.siteConfigsButtons}
                                        size='small'
                                        onClick={() => changeStatus('NV', {})}>
                                        {translate.i18n('DIALOG_ADD_URL.NOT_SOLD')}
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} className={classes.configsButton}>
                                <Tooltip title={translate.i18n('DIALOG_ADD_URL.NV_BLL_URL')} placement='top' disableInteractive>
                                    <Button variant='contained'
                                        disabled={!newUrl}
                                        color={scrapingData.to.status === 'NV_BELL' ? 'primary' : 'inherit'}
                                        className={classes.siteConfigsButtons}
                                        size='small'
                                        onClick={() => setDialogEditOpen(true)}>
                                        {translate.i18n('DIALOG_ADD_URL.NOT_SOLD')}
                                        <BellIcon className={classes.rightIcon} />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                :
                <>
                    <Grid item xs={9} className={classes.noProduct}>
                        {translate.i18n('DIALOG_ADD_URL.NO_PRODUCT')}
                        <br />
                        {translate.i18n('DIALOG_ADD_URL.ENTER_URL')}
                    </Grid>
                    <Grid item xs={3} className={classes.configsButton} style={{ margin: 'auto' }}>
                        <Tooltip title={translate.i18n('DIALOG_ADD_URL.NV_URL')} placement='top' disableInteractive>
                            <Button variant='contained'
                                color={scrapingData.to.status === 'NV' ? 'primary' : 'inherit'}
                                className={classes.siteConfigsButtons}
                                size='small'
                                onClick={() => changeStatus('NV', {})}>
                                {translate.i18n('DIALOG_ADD_URL.NOT_SOLD')}
                            </Button>
                        </Tooltip>
                    </Grid>
                </>
            }
            <Grid container>
                <TextField
                    id='searchfield'
                    className={classes.modifyUrlTextField}
                    variant='outlined'
                    label={scrapingData.url ? translate.i18n('CHANGE_URL') : translate.i18n('ADD_URL')}
                    value={newUrl}
                    error={!!newUrl && !verifyUrl(newUrl)}
                    size='small'
                    onChange={event => setNewUrl(event.target.value)}
                />
                <Tooltip title={translate.i18n('DIALOG_ADD_URL.ENTER_CONFIGS')} placement='top' disableInteractive>
                    <Button
                        id='bw_configs'
                        variant='standard'
                        size='small'
                        disabled={!newUrl}
                        onClick={() => setDialogConfig(true)}
                        endIcon={<SettingsIcon />}
                        className={classes.margin} >
                        Configs
                    </Button>
                </Tooltip>
                <Tooltip title={translate.i18n('DIALOG_ADD_URL.SAVE_CHANGES')} placement='top' disableInteractive>
                    <Button
                        id='save'
                        color='primary'
                        variant='contained'
                        size='small'
                        className={classes.margin}
                        disabled={!newUrl}
                        onClick={() => {
                            if (verifyUrl(newUrl)) changeStatus('SOLD', { url: newUrl });
                            else AlertAction.open('error', translate.i18n('DIALOG_ADD_URL.URL_ERROR'), 'top-right');
                        }}>
                        <SaveIcon />
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>
        <div className={classes.siteName}>
            {translate.i18n('DIALOG_ADD_URL.CURRENT_ARTICLE')} {siteName}
        </div>
    </Card>;

};

DialogAddUrlSiteProduct.propTypes = {
    changeStatus: PropTypes.func,
    displayCategories: PropTypes.func,
    displayPrice: PropTypes.func,
    itemName: PropTypes.string,
    newUrl: PropTypes.string,
    scrapingData: PropTypes.object,
    selectedProduct: PropTypes.object,
    setDialogConfig: PropTypes.func,
    setDialogConfirmDelete: PropTypes.func,
    setDialogEditOpen: PropTypes.func,
    setNewUrl: PropTypes.func,
    siteName: PropTypes.string,
    websiteUrls: PropTypes.array
};

export default DialogAddUrlSiteProduct;
