// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
// Facade
import Facade from 'core/Facade';
// Util
import { getHigherCardinality, calcColorAndSize } from 'components/filter/price/MapFilter/MapFilter.util';
// Style
import { useStyles } from 'components/filter/price/MapFilter/MapFilter.style';
import 'mapbox-gl/dist/mapbox-gl.css';
// Maps
import * as L from 'leaflet';
import ReactMapGL, { FlyToInterpolator, Marker, AttributionControl } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import { easeCubic } from 'd3-ease';
// Locales
import translate from 'locales/i18n';
// Material Components
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import InfoIcon from '@mui/icons-material/Info';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconRefresh from '@mui/icons-material/Cached';
// PCP Components
import DialogInfo from 'components/dialog/DialogInfo/DialogInfo';
//Theme
import { useTheme } from '@mui/styles';
// Images
import MapFilterExample from 'assets/img/map-filter-example.png';

const MapFilter = React.memo(({ displayWebsites, isLoading, reset, selectWebsite, websites, websitesSelected }) => {
    const classes = useStyles();
    const [dialogInfoOpen, setDialogInfoOpen] = useState(false);
    const [showMap, setShowMap] = useState(true);
    const [viewport, setViewport] = useState({
        height: '100%',
        latitude: 0,
        longitude: 0,
        width: '100%',
        zoom: 0
    });

    const theme = useTheme();

    const getNewViewport = () => {
        const bounds = L.latLngBounds(websites.map((website) => [website.lat, website.long]));
        const newViewport = new WebMercatorViewport({ width: 600, height: 400 });
        const { latitude, longitude, zoom } = newViewport.fitBounds(
            [[bounds.getWest(), bounds.getSouth()], [bounds.getEast(), bounds.getNorth()]],
            { padding: 20, offset: [0, -40] }
        );

        return { latitude, longitude, zoom };
    };

    useEffect(() => {
        if (websites.length > 0) {
            const { latitude, longitude, zoom } = getNewViewport();
            setViewport({
                ...viewport,
                longitude: longitude ? longitude : 0.0,
                latitude: latitude ? latitude : 0.0,
                zoom,
                transitionDuration: 1500,
                transitionInterpolator: new FlyToInterpolator(),
                transitionEasing: easeCubic
            });
        }
    }, [showMap, websites]);

    const renderDialogInfo = () => <DialogInfo open={dialogInfoOpen}
        close={() => setDialogInfoOpen(false)}>
        <div className={classes.infoMainText}>{translate.i18n('MAP_FILTER_EXPLANATION_1')}</div>
        <ul>
            <li className={classes.infoSubText}>{translate.i18n('MAP_FILTER_EXPLANATION_2')}</li>
            <li className={classes.infoSubText}>{translate.i18n('MAP_FILTER_EXPLANATION_3')}</li>
        </ul>
        <div className={classes.infoImgContainer}><img src={MapFilterExample}/></div>
        <div className={classes.infoLegendText}>{translate.i18n('MAP_FILTER_EXPLANATION_4')}</div>
    </DialogInfo>;

    const renderMarkers = () => {
        if (websites.length === 0)
            return null;

        if (!displayWebsites)
            return null;

        const higherCardinality = getHigherCardinality(websites);

        return calcColorAndSize(websites.slice(0).sort((x, y) => {
            if (x.pricesCardinalities[0] < y.pricesCardinalities[0])
                return 1;
            return -1;
        }),theme).map((website, key) => {

            let size = Math.trunc((website.stats.cardinality + 1) * 70 / (higherCardinality + 1));

            if (size < 15)
                size = 15;

            const title = Facade.siteDisplayName(website.name) + ' (' + website.stats.cardinality + ' ' + translate.i18n('ITEMS') + ') ' + translate.i18n('PRICES_LESS_EXPENSIVE', {
                number: website.stats.rate
            });

            const color = websitesSelected.length === 0 || websitesSelected.indexOf(website.id) !== -1 ? website.color : theme.palette.white.main;

            return (
                <Marker latitude={website.lat ? website.lat : 0} longitude={website.long ? website.long : 0} offsetLeft={-20} offsetTop={-10} key={key}>
                    <div title={title} style={{ height: size, width: size, backgroundColor: color, borderRadius: '50%', opacity: 0.7, border: '2px solid grey', cursor: 'pointer' }} onClick={() => !isLoading && selectWebsite(website.id)}></div>
                </Marker>
            );
        }
        );
    };

    const renderWebsites = () => websites.map((website, key) =>
        <FormControlLabel
            control={
                <Checkbox checked={(websitesSelected.length === 0 || websitesSelected.indexOf(website.id) !== -1) && displayWebsites} onChange={() => !isLoading && selectWebsite(website.id)} value={Facade.siteDisplayName(website.name)} color='primary' />
            }
            label={Facade.siteDisplayName(website.name)}
            key={key}
            className={classes.formControlLabel}
        />
    );

    return (
        <Paper className={classNames(classes.paper, isLoading && classes.notAllowed)}>
            {showMap ?
                <ReactMapGL
                    mapboxApiAccessToken={'pk.eyJ1IjoiamVhbm5pdG8iLCJhIjoiY2p4Ymo3b2JxMGNnbzNubWE4dXYybjN3ZCJ9.Igcc5NKorK-DcRXbqVxLJg'}
                    mapStyle='mapbox://styles/mapbox/streets-v9'
                    {...viewport}
                    onViewportChange={v => setViewport(v)}
                    width={'100%'}
                    height={'100%'}
                    doubleClickZoom={false}
                    attributionControl={false}
                >
                    {renderMarkers()}
                    <AttributionControl compact={false} style={{ display: 'none' }}/>
                </ReactMapGL>
                :
                <SimpleBar className={classes.simpleBar}>
                    <FormControl component='fieldset' className={classes.formControl}>
                        <FormGroup>
                            {websites.length !== 0 && <FormControlLabel
                                control={
                                    <Checkbox checked={displayWebsites && websitesSelected.length === 0} onChange={() => !isLoading && selectWebsite('all')} value={'all'} color='primary' />
                                }
                                label={translate.i18n('ALL')}
                                key={'all'}
                                className={classes.formControlLabel}
                            />}
                            {renderWebsites()}
                        </FormGroup>
                    </FormControl>
                </SimpleBar>}
            {renderDialogInfo()}
            <IconButton
                color='primary'
                onClick={() => setShowMap(!showMap)}
                className={classes.showMapButton}>
                <ShuffleIcon />
            </IconButton>
            <IconButton
                color='primary'
                onClick={reset}
                className={classes.resetButton}>
                <IconRefresh />
            </IconButton>
            <IconButton
                color='primary'
                onClick={() => setDialogInfoOpen(true)}
                className={classes.infoButton}>
                <InfoIcon />
            </IconButton>
        </Paper>
    );
}, (prevProps, nextProps) => {
    if (prevProps.isLoading !== nextProps.isLoading || prevProps.websitesSelected !== nextProps.websitesSelected || prevProps.displayWebsites !== nextProps.displayWebsites) {
        return false;
    }
    return true;
});

MapFilter.propTypes = {
    displayWebsites: PropTypes.bool,
    isLoading: PropTypes.bool,
    reset: PropTypes.func,
    selectWebsite: PropTypes.func,
    websites: PropTypes.array,
    websitesSelected: PropTypes.array
};

export default MapFilter;
