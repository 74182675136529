// React
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// Locales
import translate from 'locales/i18n';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
//Actions
import UserAction from 'actions/UserAction';
// Stores
import UserStore from 'stores/UserStore';
// Style
import styles from 'components/pages/ForgotPasswordPage/ForgotPasswordPage.style';
// Util
import { validateEmail, validatePassword } from 'components/pages/ForgotPasswordPage/ForgotPasswordPage.util';

class ForgotPasswordPage extends React.PureComponent {

    state = {
        activeStep: 0,
        code: ['', '', '', '', '', ''],
        confirmPassword: '',
        email: '',
        isLoading: false,
        isLogged: UserStore.isLogged(),
        password: '',
        showPassword: false
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    componentDidMount() {
        UserStore.addChangeListener(this.onUserStoreChange);
        this.mounted = true;
    }

    componentWillUnmount() {
        UserStore.removeChangeListener(this.onUserStoreChange);
        this.mounted = false;
    }

    onUserStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            isLoading: UserStore.isLoading,
            isLogged: UserStore.isLogged()
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleNext();
        }
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleChange = name => event => {
        this.setState({
            errorMsg: '',
            [name]: event.target.value
        });
    };

    handleNext = () => {
        const { activeStep, email, password, confirmPassword } = this.state;

        let code = '';
        this.state.code.forEach(c => code += c);

        switch (activeStep) {
        case 0:
            if (validateEmail(email)) {
                this.setState({ isLoading: true });
                UserAction.sendForgotPasswordEmail(email).then(() => {
                    this.setState({ activeStep: activeStep + 1, errorMsg: '', isLoading: false });
                }, () => this.setState({ errorMsg: translate.i18n('EMAIL_NOT_REGISTERED'), isLoading: false }));
            } else {
                this.setState({ errorMsg: translate.i18n('ERROR_EMAIL') });
            }
            break;
        case 1:
            this.setState({ isLoading: true });
            UserAction.checkResetPasswordCode(email, code).then(isValid => {
                if (isValid) {
                    this.setState({ activeStep: activeStep + 1, errorMsg: '', isLoading: false });
                } else {
                    this.setState({ errorMsg: translate.i18n('CODE_NOT_GOOD'), isLoading: false });
                }
            });
            break;
        case 2:
            if (password === '' || confirmPassword === '') {
                this.setState({ errorMsg: translate.i18n('FILL_PASSWORD') });
            } else if (password !== confirmPassword) {
                this.setState({ errorMsg: translate.i18n('PASSWORDS_DOES_NOT_MATCH') });
            } else if (!validatePassword(password)) {
                this.setState({ errorMsg: translate.i18n('PASSWORD_DOES_NOT_MATCH_CRITERIA') });
            } else {
                this.setState({ isLoading: true });
                UserAction.resetPassword(password, code).then(() => {
                    this.setState({ activeStep: activeStep + 1, errorMsg: '', isLoading: false });
                }, () => this.setState({ errorMsg: translate.i18n('PASSWORD_DOES_NOT_MATCH_CRITERIA'), isLoading: false }));
            }
            break;
        default:
            this.setState({ activeStep: activeStep + 1 });
        }

    };

    handleReset = () => {
        this.setState({ activeStep: 0 });
    };

    getStepContent = (stepIndex) => {
        const { classes } = this.props;

        switch (stepIndex) {
        case 0:
            return <div>
                <p className={classes.informationText}>
                    {translate.i18n('TYPE_EMAIL_PASSWORD_LOST')}
                </p>
                <TextField
                    style={{ marginTop: '8px' }}
                    error={!!this.state.errorMsg}
                    className={classes.textField}
                    id='email'
                    label={translate.i18n('EMAIL')}
                    value={this.state.email}
                    onChange={this.handleChange('email')}
                    onKeyPress={this.handleKeyPress}
                    margin='normal'
                    variant='outlined'
                    helperText={this.state.errorMsg ? this.state.errorMsg : ''}
                />
            </div>;
        case 1:
            return <div>
                <p className={classes.informationText}>
                    {translate.i18n('CHECK_CODE', { email: this.state.email })}
                </p>
                <div className={classes.codeContainer}>
                    <TextField
                        error={!!this.state.errorMsg}
                        className={classes.textFieldCode}
                        value={this.state.code[0]}
                        onChange={event => {
                            const { code } = this.state;
                            code[0] = event.target.value;
                            this.setState({ code: code.slice(0) });
                        }}
                        onKeyPress={this.handleKeyPress}
                        margin='normal'
                        variant='outlined'
                        inputProps={{ className: classes.codeInput, maxLength: 1 }}
                    />
                    <TextField
                        className={classes.textFieldCode}
                        error={!!this.state.errorMsg}
                        value={this.state.code[1]}
                        onChange={event => {
                            const { code } = this.state;
                            code[1] = event.target.value;
                            this.setState({ code: code.slice(0) });
                        }}
                        onKeyPress={this.handleKeyPress}
                        margin='normal'
                        variant='outlined'
                        inputProps={{ className: classes.codeInput, maxLength: 1 }}
                    />
                    <TextField
                        className={classes.textFieldCode}
                        error={!!this.state.errorMsg}
                        value={this.state.code[2]}
                        onChange={event => {
                            const { code } = this.state;
                            code[2] = event.target.value;
                            this.setState({ code: code.slice(0) });
                        }}
                        onKeyPress={this.handleKeyPress}
                        margin='normal'
                        variant='outlined'
                        inputProps={{ className: classes.codeInput, maxLength: 1 }}
                    />
                    <TextField
                        className={classes.textFieldCode}
                        error={!!this.state.errorMsg}
                        value={this.state.code[3]}
                        onChange={event => {
                            const { code } = this.state;
                            code[3] = event.target.value;
                            this.setState({ code: code.slice(0) });
                        }}
                        onKeyPress={this.handleKeyPress}
                        margin='normal'
                        variant='outlined'
                        inputProps={{ className: classes.codeInput, maxLength: 1 }}
                    />
                    <TextField
                        className={classes.textFieldCode}
                        error={!!this.state.errorMsg}
                        value={this.state.code[4]}
                        onChange={event => {
                            const { code } = this.state;
                            code[4] = event.target.value;
                            this.setState({ code: code.slice(0) });
                        }}
                        onKeyPress={this.handleKeyPress}
                        margin='normal'
                        variant='outlined'
                        inputProps={{ className: classes.codeInput, maxLength: 1 }}
                    />
                    <TextField
                        className={classes.textFieldCode}
                        error={!!this.state.errorMsg}
                        value={this.state.code[5]}
                        onChange={event => {
                            const { code } = this.state;
                            code[5] = event.target.value;
                            this.setState({ code: code.slice(0) });
                        }}
                        onKeyPress={this.handleKeyPress}
                        margin='normal'
                        variant='outlined'
                        inputProps={{ className: classes.codeInput, maxLength: 1 }}
                    />
                </div>
                <div className={classes.sendCodeAgainLinkContainer}>
                    <a href='/#' className={classes.sendCodeAgainLink} onClick={() => UserAction.sendForgotPasswordEmail(this.state.email)}>{translate.i18n('SEND_CODE_AGAIN')}</a>
                </div>
            </div>;
        case 2:
            return (
                <div>
                    <TextField
                        error={!!this.state.errorMsg}
                        style={{ marginBottom:0, marginTop: 0 }}
                        className={classes.textField}
                        id='password'
                        type={this.state.showPassword ? 'text' : 'password'}
                        label={translate.i18n('PASSWORD')}
                        value={this.state.password}
                        onChange={this.handleChange('password')}
                        onKeyPress={this.handleKeyPress}
                        margin='normal'
                        variant='outlined'
                        autoComplete='current-password'
                        InputProps={{
                            endAdornment:
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='Toggle password visibility'
                                        onClick={this.handleClickShowPassword}
                                        size='large'>
                                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    <div>
                        <ul>
                            <li>
                                {translate.i18n('MUST_CONTAINS_8_CHAR')}
                            </li>
                            <li>
                                {translate.i18n('MUST_CONTAINS_ONE_LOWER_CASE')}
                            </li>
                            <li>
                                {translate.i18n('MUST_CONTAINS_ONE_UPPER_CASE')}
                            </li>
                            <li>
                                {translate.i18n('MUST_CONTAINS_')}
                            </li>
                            <li>
                                {translate.i18n('MUST_CONTAINS_SPECIAL_CHAR')}
                            </li>
                        </ul>
                    </div>
                    <TextField
                        error={!!this.state.errorMsg}
                        style={{ marginBottom: '24px', marginTop: 0 }}
                        className={classes.textField}
                        id='confirmPassword'
                        type={this.state.showPassword ? 'text' : 'password'}
                        label={translate.i18n('CONFIRM_PASSWORD')}
                        value={this.state.confirmPassword}
                        onChange={this.handleChange('confirmPassword')}
                        onKeyPress={this.handleKeyPress}
                        margin='normal'
                        variant='outlined'
                        autoComplete='current-password'
                        helperText={this.state.errorMsg ? this.state.errorMsg : ''}
                    />
                </div>
            );
        default:
            return 'Unknown stepIndex';
        }
    };

    render() {
        const { classes } = this.props;
        const { activeStep, isLoading } = this.state;

        const steps = [translate.i18n('GIVE_REGISTERED_EMAIL'), translate.i18n('INSERT_CODE'), translate.i18n('CHOOSE_A_NEW_PASSWORD')];

        if (isLoading) {
            return (
                <div className={classes.background}>
                    <div className={classes.pageContainer}>
                        <Paper className={classes.paperLoading}
                            elevation={20} >
                            <CircularProgress className={classes.progress} size={50} />
                        </Paper>
                    </div>
                </div>
            );
        }

        if (activeStep === 3) {
            return (
                <div className={classes.background}>
                    <div className={classes.pageContainer}>
                        <Paper className={classes.paperFinalStep}
                            elevation={20} >
                            {translate.i18n('PASSWORD_HAS_BEEN_RESET')}
                            <div className={classes.bottomButtonContainer}>
                                <Link to='/login' className={classes.link}>
                                    <Button variant='contained' color='primary' onClick={this.handleNext}>
                                        Login
                                    </Button>
                                </Link>
                            </div>
                        </Paper>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.background}>
                <div className={classes.pageContainer}>
                    <Paper className={classes.paper}
                        elevation={20} >
                        <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                            {steps.map((label) =>
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            )}
                        </Stepper>
                        {this.getStepContent(activeStep)}
                        <div className={classes.bottomButtonContainer}>
                            <Button variant='contained' color='primary' onClick={this.handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </div>
                    </Paper>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ForgotPasswordPage);
