// React
import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
// Material UI Components
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import IconRefresh from '@mui/icons-material/Cached';
// Style
import { useStyles } from 'components/filter/mail/MailHourFilter/MailHourFilter.style';
// Util
import { createData, options } from 'components/filter/mail/MailHourFilter/MailHourFilter.util';
//Theme
import { useTheme } from '@mui/styles';

const MailHourFilter = ({
    resetHour,
    selectHour,
    statsByHour
}) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <>
            {!statsByHour ?
                <Paper className={classes.paper}>
                </Paper>
                :
                <Paper className={classes.paper}>
                    <div className={classes.graphContainer}>
                        <Bar
                            data={createData(statsByHour,theme)}
                            options={options}
                            getElementAtEvent={element => {
                                if (element.length !== 0)
                                    selectHour('HOUR',element[0].index);
                            }}
                        />
                    </div>
                    <IconButton className={classes.iconReset} color='primary' onClick={resetHour}>
                        <IconRefresh />
                    </IconButton>
                </Paper>
            }
        </>
    );
};

MailHourFilter.propTypes = {
    resetHour: PropTypes.func,
    selectHour: PropTypes.func,
    statsByHour: PropTypes.array
};

export default MailHourFilter;
