// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Service
import AttributeService from 'services/AttributeService';
// Action
import AlertAction from './AlertAction';
//Locales
import translate from 'locales/i18n';

class AttributeAction {

    /**
      * Method called to get all attributeNames
      *
      * @param accountId the account id
      */
    getAttributeNames(accountId) {
        Dispatcher.dispatch({
            actionType: Constants.ATTRIBUTE_NAMES_GET_START
        });

        AttributeService.getAttributeNames(accountId).then(attributes => {
            Dispatcher.dispatch({
                actionType: Constants.ATTRIBUTE_NAMES_GET_END,
                value: attributes
            });
        }, response => (response && response.error) && AlertAction.open('error', translate.i18n(response.error), 'top-right'));
    }

    /**
      * Method called to get existing attributeValues for one attribute
      *
      * @param accountId the account id
      * @param attributeName the attribute name
      */
    getAttributeValues(accountId, attributeName) {
        Dispatcher.dispatch({
            actionType: Constants.ATTRIBUTE_VALUES_GET_START
        });

        AttributeService.getAttributeValues({ accountId, attributeName }).then(attributes => {
            Dispatcher.dispatch({
                actionType: Constants.ATTRIBUTE_VALUES_GET_END,
                value: { attributes, attributeName }
            });
        }, response => (response && response.error) && AlertAction.open('error', translate.i18n(response.error), 'top-right'));
    }
}

export default new AttributeAction();
