import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    alignText: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '16px',
        marginBottom: '8px'
    },
    appliedPrice: {
        color: theme.palette.info.light,
        fontWeight: 500
    },
    appliedRuleList: {
        marginLeft: '60px',
        width: '300px'
    },
    bluetext: {
        color: '#028ebd',
        display: 'flex'
    },
    boldText: {
        fontWeight: 500
    },
    dialogText: {
        fontSize: '16px',
        marginBottom: '8px'
    },
    errorText: {
        color: theme.palette.red.main,
        fontSize: '15px'
    },
    evolutionIcon: {
        fontSize: '22px',
        marginTop: '-2px'
    },
    evolutionIconDialog: {
        fontSize: '20px',
        marginTop: '1px'
    },
    icon: {
        marginRight: '5px'
    },
    priceCell: {
        paddingLeft: '10px',
        textAlign: 'end'
    },
    newMargin: {
        color: theme.palette.primary.dark,
        fontSize: '11px',
        fontWeight: 'normal',
        marginTop: '8px',
        justifyContent: 'center',
        display: 'flex'
    },
    ruleName: {
        color: theme.palette.primary.dark,
        fontWeight: 500
    },
    rulePopperInfo: {
        marginLeft: '30px'
    },
    rulePopperRank: {
        fontSize: '16px'
    },
    ruleStrategy: {
        color: theme.palette.grey.ultradark,
        fontSize: '14px',
        fontStyle: 'italic',
        fontWeight: 400
    },
    tableCell: {
        borderRight: '1px solid' + theme.palette.grey.light,
        color: theme.palette.grey.dark,
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '500',
        padding: 0
    },
    tableCellContentError: {
        alignItems: 'center',
        color: theme.palette.red.main,
        display: 'flex',
        justifyContent: 'center'
    },
    tableCellContentNoRule: {
        alignItems: 'center',
        color: theme.palette.primary.dark,
        display: 'flex',
        justifyContent: 'center'
    },
    tableCellContentSuccess: {
        alignItems: 'center',
        color: theme.palette.info.light,
        display: 'flex',
        justifyContent: 'center'
    }
}));
