/**
  * Method to validate an email
  *
  * @param date <Date> the date to turn into month name
  * @returns <string> the month name
  */
export const getMonthName = (language, date) => {
    if (!date) return '';

    let monthNames = [
        'January', 'February', 'March',
        'April', 'May', 'June', 'July',
        'August', 'September', 'October',
        'November', 'December'
    ];

    if (language === 'fr') {
        monthNames = [
            'January', 'Fevrier', 'Mars',
            'Avril', 'Mai', 'Juin', 'Juillet',
            'Août', 'Septembre', 'Octobre',
            'Novembre', 'Decembre'
        ];
    }

    return monthNames[date.getMonth()];
};

/**
     * Method which return formatted date
     *
     * @param date <Date> the date to format
     * @returns <string> the formatted date
     */
export const formatDate = (date) => {
    if (!(date instanceof Date))
        return '';

    return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}`;
};
