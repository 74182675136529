// Locales
import translate from 'locales/i18n';

const getHigherCardinality = pricesBaskets => {
    let higherCardinality = 1;

    pricesBaskets.forEach((pricesBasket) => {
        if (pricesBasket.pricesCardinalities[0] > higherCardinality && pricesBasket.pricesCardinalities[0] !== 0)
            [higherCardinality] = pricesBasket.pricesCardinalities;
    });

    return higherCardinality;
};

const getTooltipTitle = (item, pricesBaskets, currentAccount) => {
    const { bounds } = pricesBaskets.find(pricesBasket => pricesBasket.id === item[0].label);

    if (currentAccount.isRetailer()) {
        if (bounds[0] === 0 && bounds[1] === 0)
            return translate.i18n('PRICE_LIKE_YOURS');

        if (bounds[0] < -50)
            return translate.i18n('LESS_THAN', {
                number: '-50%'
            });

        if (bounds[1] > 50)
            return translate.i18n('MORE_THAN', {
                number: '50%'
            });

        return translate.i18n('BETWEEN') + ' ' + bounds[0] + '% ' + translate.i18n('AND') + ' ' + bounds[1] + '%';
    }

    if (currentAccount.isSupplier()) {
        if (bounds[0] === 0 && bounds[1] === 0)
            return translate.i18n('PRICE_EQUAL_RRP');

        if (bounds[0] === -5 && bounds[1] === 0)
            return translate.i18n('PRICE_BETWEEN_RRP_LIMIT');

        if (bounds[0] === -10 && bounds[1] === -5)
            return translate.i18n('PRICE_EQUAL_LIMIT');

        if (bounds[0] < -50)
            return translate.i18n('LESS_THAN', {
                number: '-35%'
            });

        if (bounds[1] > 50)
            return translate.i18n('MORE_THAN', {
                number: '5%'
            });

        if (bounds[0] < -10)
            return translate.i18n('LESS_THAN_LIMIT', {
                max: bounds[1] + 10 + '%',
                min: bounds[0] + 10 + '%'
            });

        if (bounds[1] > 0)
            return translate.i18n('MORE_THAN_RRP', {
                max: bounds[1] + '%',
                min: bounds[0] + '%'
            });
    }

    return 'No label';
};

export const createData = (pricesBaskets, pricesBasketsSelected, currentAccount,theme) => {
    const basketData = [];
    const higherCardinality = getHigherCardinality(pricesBaskets);
    const colorRetailer = [theme.palette.largeGraph.one,
        theme.palette.largeGraph.two,theme.palette.largeGraph.three,
        theme.palette.largeGraph.four,theme.palette.largeGraph.five,
        theme.palette.largeGraph.six,theme.palette.largeGraph.seven,
        theme.palette.largeGraph.eight,theme.palette.largeGraph.nine,
        theme.palette.largeGraph.ten,theme.palette.largeGraph.eleven,
        theme.palette.largeGraph.twelve,theme.palette.largeGraph.thirteen,
        theme.palette.largeGraph.fourteen,theme.palette.largeGraph.fifteen,
        theme.palette.largeGraph.sixteen,theme.palette.largeGraph.seventeen,
        theme.palette.largeGraph.eighteen,theme.palette.largeGraph.nineteen,
        theme.palette.largeGraph.twenty,theme.palette.largeGraph.twentyOne,
        theme.palette.largeGraph.twentyTwo,theme.palette.largeGraph.twentyThree];
    const colorSupplier = [theme.palette.largeGraph.one,
        theme.palette.largeGraph.three,theme.palette.largeGraph.five,
        theme.palette.largeGraph.seven,theme.palette.largeGraph.nine,
        theme.palette.largeGraph.eleven,theme.palette.largeGraph.thirteen,
        theme.palette.largeGraph.fifteen,theme.palette.largeGraph.seventeen,
        theme.palette.largeGraph.nineteen,theme.palette.largeGraph.twentyOne,
        theme.palette.largeGraph.twentyThree,theme.palette.largeGraph.one,];
    const formatedColor = [];
    const label = [];

    pricesBaskets.map((pricesBasket, key) => {
        // Manage data value
        if (pricesBasket.pricesCardinalities[0] < (higherCardinality / 5) * 0.2) {
            basketData.push((higherCardinality / 5) * 0.2);
        } else {
            basketData.push(pricesBasket.pricesCardinalities[0]);
        }

        // Manage data color
        if (currentAccount.isRetailer()) {
            if (pricesBasket.pricesCardinalities[0] === 0 && (pricesBasketsSelected.indexOf(pricesBasket.id) !== -1 || pricesBasketsSelected.length === 0)) {
                formatedColor.push(theme.palette.white.main);
            } else {
                pricesBasketsSelected.indexOf(pricesBasket.id) === -1 && pricesBasketsSelected.length !== 0 ? formatedColor.push(theme.palette.grey.light) : formatedColor.push(colorRetailer[key]);
            }
        } else if (currentAccount.isSupplier()) {
            if (pricesBasket.pricesCardinalities[0] === 0 && (pricesBasketsSelected.indexOf(pricesBasket.id) !== -1 || pricesBasketsSelected.length === 0)) {
                formatedColor.push(theme.palette.white.main);
            } else {
                pricesBasketsSelected.indexOf(pricesBasket.id) === -1 && pricesBasketsSelected.length !== 0 ? formatedColor.push(theme.palette.grey.light) : formatedColor.push(colorSupplier[key]);
            }
        }

        label.push(pricesBasket.id);
        return true;
    });

    const data = {
        datasets: [{
            backgroundColor: formatedColor,
            borderColor: formatedColor,
            borderWidth: 1,
            data: basketData,
            hoverBackgroundColor: formatedColor,
            hoverBorderColor: formatedColor,
            label: 'Cardinality'
        }],
        labels: label
    };

    return data;
};

export const getOptions = (pricesBaskets, isLoading, currentAccount) => ({
    maintainAspectRatio: false,
    borderRadius: 2,
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: tooltipItems => {
                    if (pricesBaskets.find(pricesBasket => pricesBasket.id === tooltipItems.label).pricesCardinalities[0] === 0) return '0 ' + translate.i18n('PRICES_FILTERED');
                    return ' ' + pricesBaskets[tooltipItems.dataIndex].pricesCardinalities[0] + ' ' + translate.i18n('PRICES_FILTERED');
                },
                title: tooltipItems => getTooltipTitle(tooltipItems, pricesBaskets, currentAccount)
            },
            enabled: !isLoading
        },
        datalabels: {
            anchor: 'end',
            align: 'top',
            rotation: currentAccount.isSupplier() && -90,
            formatter: (value, context) => {
                if (currentAccount.isSupplier()) {
                    if (context.dataIndex === 10) return ' RRP';
                    if (context.dataIndex === 8) return ' Limit';
                } else {
                    if (context.dataIndex === 11) return '=';
                }

                return '';
            },
        },
        title: {
            display: true,
            fontSize: 12,
            text: currentAccount.isRetailer() ? translate.i18n('PRICES_BASKETS_TITLE_RETAILER') : translate.i18n('PRICES_BASKETS_TITLE_SUPPLIER'),
            padding: {
                bottom: 15,
                top: 8
            }
        },
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            ticks: {
                display: false
            }
        },
        y: {
            grid: {
                display: false,
                drawBorder: false
            },
            ticks: {
                display: false
            }
        }
    },
});
