import Strategy from 'core/entities/StrategyObject';
import Item from './ItemObject';

class Rule {

    constructor(data) {
        this.id = data?.id;
        this.accountId = data?.account_id;
        this.name = data?.name;
        this.cardinalities = data?.cardinalities;
        this.creationDate = data?.creation_date;
        this.lastUpdateDate = data?.last_update_date;
        this.applyFrom = data?.apply_from;
        this.applyUntil = data?.apply_until;
        this.minPrice = data?.min_price;
        this.maxPrice = data?.max_price;
        this.strategy = new Strategy(data?.strategy);
        this.associatedArticles = data && data.associated_articles ? data.associated_articles.map(article => new Item(article)) : [];
        this.nbOfAssociatedArticles = data?.nb_of_associated_articles;
    }

    toJson() {
        return {
            id: this.id,
            account_id: this.accountId,
            name: this.name,
            creation_date: this.creationDate,
            last_update_date: this.lastUpdateDate,
            apply_from: this.applyFrom,
            apply_until: this.applyUntil,
            min_price: this.minPrice,
            max_price: this.maxPrice,
            strategy: this.strategy,
            associated_articles: this.associatedArticles,
            nb_of_associated_articles: this.nbOfAssociatedArticles

        };
    }

}

export default Rule;
