import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    bottomCellIcon: {
        position: 'absolute',
        right: '15%',
        top: '35%'
    },
    greenIcon: {
        color: theme.palette.green.main
    },
    hide: {
        display: 'none'
    },
    lastRow: {
        bottom: 0,
        position: 'sticky',
        backgroundColor: theme.palette.grey.light,
    },
    leftCell: {
        minWidth: '180px'
    },
    redIcon: {
        color: theme.palette.red.main,
        transform: 'rotate(-90deg)'
    },
    root: {
        height: '40%',
        marginTop: '10px',
        overflow: 'auto',
        width: '100%'
    },
    table: {
        height: '100%'
    },
    tableBottomCell: {
        backgroundColor: theme.palette.grey.light,
        bottom: 0,
        position: 'sticky',
        zIndex: 10
    },
    tableHeadCell: {
        backgroundColor: theme.palette.grey.light,
        position: 'sticky',
        top: 0,
        zIndex: 10
    }
}));
