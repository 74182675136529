// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Facade
import Facade from 'core/Facade';
// Locales
import translate from 'locales/i18n';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Loader from 'components/commons/Loader/Loader';
import IconButton from '@mui/material/IconButton';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// PCP Components
import PriceCell from 'components/cell/PriceCell/PriceCell';
import ItemCell from 'components/cell/ItemCell/ItemCell';
import ItemCellSpecificInfos from 'components/cell/ItemCellSpecificInfos/ItemCellSpecificInfos';
// Images
import AccountStore from 'stores/AccountStore';
// Style
import { useStyles } from 'components/table/PriceTable/PriceTable.style';
// Store
import LayoutStore from 'stores/LayoutStore';
import UserStore from 'stores/UserStore';
//Theme
import { useTheme } from '@mui/styles';
import NotationSheet from 'components/sheet/NotationSheet/NotationSheet';

const PriceTable = ({
    isLoading,
    items,
    handleChangeSort,
    hideStocks,
    hideImages,
    mode,
    openDialogAddUrl,
    openDialogMarketPlace,
    openDialogUpdateScrapedData,
    websites,
    websitesSelected,
    hideNotations,
    hidePrices,
    avgEvaluation
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const theme = useTheme();

    const handleOpenMenu = event => setAnchorEl(event.currentTarget);

    const handleClick = (value) => {
        setAnchorEl(null);
        handleChangeSort(value);
    };

    const [showEditIcon, setshowEditIcon] = useState(UserStore.getCurrentUser().isSuperUser() ? LayoutStore.getshowEditIcon() : true);
    const [blur, setBlur] = useState(UserStore.getCurrentUser().isSuperUser() ? LayoutStore.getBlur() : false);

    function onLayoutStoreChange() {
        setshowEditIcon(LayoutStore.getshowEditIcon());
        setBlur(LayoutStore.getBlur());
    }

    useEffect(() => {
        UserStore.getCurrentUser().isSuperUser() ? LayoutStore.addChangeListener(onLayoutStoreChange) : null;
        return () => UserStore.getCurrentUser().isSuperUser() ? LayoutStore.removeChangeListener(onLayoutStoreChange) : null;
    }, []);

    return (
        <div className={classNames(isLoading && classes.tableContainer)}>
            <Table className={classes.table} style={isLoading ? null : { height: '100%' }}>
                <TableHead className={classes.tableHead}>
                    <TableRow className={classes.tableHead}>
                        <TableCell className={classes.tableHeadFirstCell}>
                            <div className={classes.tableHeadContent} style={{ width: 270 }}>
                                <div className={classes.label}>
                                    {translate.i18n('ITEMS') + ' / ' + translate.i18n('WEBSITES')}
                                </div>
                                <IconButton
                                    aria-label='Toggle password visibility'
                                    onClick={handleOpenMenu}
                                    size='large'>
                                    <SortByAlphaIcon />
                                </IconButton>
                                <Menu
                                    id='simple-menu'
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                >
                                    <MenuItem onClick={() => handleClick('alpha_asc')}>A-Z</MenuItem>
                                    <MenuItem onClick={() => handleClick('alpha_desc')}>Z-A</MenuItem>
                                </Menu>
                                {!hideNotations &&
                                    <div className={classes.avgEvaluation}>
                                        <NotationSheet evaluation={avgEvaluation} isAvg={true} />
                                    </div>
                                }
                            </div>
                        </TableCell>
                        <TableCell className={classes.tableHeadSecondCell}>
                            <div className={classes.tableHeadContent}>
                                <div className={classes.label}>
                                    {translate.i18n('DETAILS')}
                                </div>
                            </div>
                        </TableCell>
                        {websites.map((website, key) => {
                            if (websitesSelected.length === 0 || websitesSelected.indexOf(website.id) !== -1)
                                return (
                                    <TableCell key={key} className={classes.tableHeadCell} title={Facade.siteDisplayName(website.name)}>
                                        <div className={classes.tableHeadContent} onClick={() => Facade.openUrl(website.url)} style={blur ? { filter: 'blur(0.2rem)' } : null} title={website.url}>
                                            <img src={`https://img.pricecomparator.pro/sites_images/${website.name}/1`}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = `https://www.google.com/s2/favicons?domain=${website.url}`;
                                                }}
                                                alt={Facade.siteDisplayName(website.name)}
                                                height={18}
                                                width={18}
                                                className={classes.siteIcon} />
                                            <Typography variant='body2' color='inherit' noWrap className={classes.label}>
                                                {Facade.siteDisplayName(website.name)}
                                            </Typography>
                                        </div>
                                        {AccountStore.getCurrentAccount().isRetailer() && AccountStore.getCurrentAccount().getSite().id === website.id && mode === 'pricing_advising' && <div className={classes.pricingAdvising}> {'* ' + translate.i18n('SUGGESTED_PRICE')}</div>}
                                        {!hideNotations &&
                                            <div className={classes.siteEvaluation}>
                                                <NotationSheet evaluation={website.evaluation} isAvg={true} />
                                            </div>
                                        }
                                    </TableCell>
                                );
                            return false;
                        })
                        }
                    </TableRow>
                </TableHead>
                {!isLoading &&
                    <TableBody>
                        {items.map((item, index) =>
                            <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? theme.palette.grey.semilight : theme.palette.white.main }}>
                                <ItemCell item={item}
                                    index={index}
                                    key={index}
                                    canDelete={true}
                                    hideNotations={hideNotations}
                                />
                                <ItemCellSpecificInfos
                                    item={item}
                                    index={index}
                                    blur={blur}
                                />
                                {item.scrapingData.map((scrapingData, key) => {
                                    if (websitesSelected.length === 0 || websitesSelected.indexOf(scrapingData.siteId) !== -1)
                                        return (
                                            <PriceCell key={key}
                                                index={index}
                                                item={item}
                                                scrapingData={scrapingData}
                                                hideStocks={hideStocks}
                                                website={websites.find(w => w.id === scrapingData.siteId)}
                                                openDialogAddUrl={openDialogAddUrl}
                                                openDialogMarketPlace={openDialogMarketPlace}
                                                openDialogUpdateScrapedData={openDialogUpdateScrapedData}
                                                hideImages={hideImages}
                                                showEditIcon={showEditIcon}
                                                hideNotations={hideNotations}
                                                hidePrices={hidePrices} />
                                        );

                                    return false;
                                })}
                            </TableRow>)}
                    </TableBody>}
            </Table>
            <div className={classes.endSpace}></div>
            {isLoading &&
                <div className={classes.progressContainer}>
                    <Loader size={50} />
                </div>}
        </div>
    );
};

PriceTable.propTypes = {
    handleChangeSort: PropTypes.func,
    hideImages: PropTypes.bool,
    hideStocks: PropTypes.bool,
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    mode: PropTypes.string,
    openDialogAddUrl: PropTypes.func,
    openDialogDeleteItem: PropTypes.func,
    openDialogMarketPlace: PropTypes.func,
    openDialogUpdateScrapedData: PropTypes.func,
    websites: PropTypes.array,
    websitesSelected: PropTypes.array,
    hideNotations: PropTypes.bool,
    hidePrices: PropTypes.bool,
    avgEvaluation: PropTypes.object
};

export default PriceTable;
