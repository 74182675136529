// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Locales
import translate from 'locales/i18n';
// Style
import { useStyles } from 'components/dialog/DialogExportData/DialogExportData.style';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ExportIcon from '@mui/icons-material/CloudDownload';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/material/styles';
// Stores
import AccountStore from 'stores/AccountStore';
import Facade from 'core/Facade';
import { Collapse } from '@mui/material';

const DialogExportData = ({ close, exportData, isLoadingExport, open }) => {
    const [name, setName] = useState(AccountStore.getCurrentAccount().name ? AccountStore.getCurrentAccount().name + '-' + Facade.formatDate(new Date(), true) : 'pcp-data');
    const [separator, setSeparator] = useState(',');
    const [format, setFormat] = useState('xlsx');
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const launchExport = () => {
        const options = {
            decimalseparator: ',',
            fieldSeparator: separator,
            filename: name ? name : 'pcp-data',
            quoteStrings: '',
            showLabels: true,
            showTitle: false,
            useBom: true,
            useKeysAsHeaders: true,
            format
        };

        exportData(options);
        close();
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={close}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
        >
            <IconButton
                color='secondary'
                onClick={close}
                className={classes.closeButton}
                size='large'>
                <ClearIcon />
            </IconButton>
            <DialogTitle id='responsive-dialog-title'>{translate.i18n('EXPORT_DATA')}</DialogTitle>
            {isLoadingExport ?
                <DialogContent className={classNames(!fullScreen && classes.dialogContentNotFullScreen, classes.dialogContent)}>
                    <CircularProgress className={classes.progress} size={50} />
                </DialogContent>
                :
                <DialogContent className={classNames(!fullScreen && classes.dialogContentNotFullScreen, classes.dialogContent)}>
                    <TextField
                        id='filename'
                        className={classes.textField}
                        variant='outlined'
                        type='text'
                        label={translate.i18n('FILE_NAME')}
                        value={name}
                        onChange={event => setName(event.target.value)}
                    />
                    <FormControl>
                        <FormLabel>Format</FormLabel>
                        <RadioGroup
                            value={format}
                            onChange={e => setFormat(e.target.value)}
                            className={classes.radioButton}>
                            <FormControlLabel value='xlsx' control={<Radio />} label='xlsx' />
                            <FormControlLabel value='csv' control={<Radio />} label='csv' />
                        </RadioGroup>
                    </FormControl>
                    <Collapse in={format === 'csv'}>
                        <FormControl variant='outlined' className={classes.textField}>
                            <InputLabel
                                htmlFor='outlined-age-native-simple'
                            >
                                {translate.i18n('SEPARATOR')}
                            </InputLabel>
                            <Select
                                label={translate.i18n('SEPARATOR')}
                                native
                                value={separator}
                                onChange={event => setSeparator(event.target.value)}
                            >
                                <option value={','}>,</option>
                                <option value={';'}>;</option>
                                <option value={'.'}>.</option>
                                <option value={'|'}>|</option>
                            </Select>
                        </FormControl>
                    </Collapse>
                </DialogContent>}
            <DialogActions>
                <Button color='primary' size='small'
                    onClick={launchExport}>
                    <ExportIcon className={classes.leftIcon} />
                    {translate.i18n('EXPORT_DATA')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DialogExportData.propTypes = {
    close: PropTypes.func,
    exportData: PropTypes.func,
    exportError: PropTypes.bool,
    fullScreen: PropTypes.bool,
    isLoadingExport: PropTypes.bool,
    open: PropTypes.bool
};

export default DialogExportData;
