// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Service
import SliderService from 'services/SliderService';
//Stores
import AccountStore from 'stores/AccountStore';
import SliderStore from 'stores/SliderStore';
//Action
import AlertAction from 'actions/AlertAction';
//i18n
import translate from 'locales/i18n';
// Logger
import Logger from 'core/Logger';
// Entities
import Site from 'core/entities/SiteObject';

class SliderAction {

    /**
      * Method called to get sliders of Slider module
      *
      * @param {  accountId, from, to } options the options to search emails
      */
    search(options) {
        if (!SliderStore.firstLoad) {
            Dispatcher.dispatch({
                actionType: Constants.SLIDER_SEARCH_START,
                value: options
            });
        }

        SliderService.getSliders(options).then(({ evolution, from, slider_sites, to }) => {
            Dispatcher.dispatch({
                actionType: Constants.SLIDER_SEARCH_END,
                value: {
                    evolution,
                    from,
                    sliderSites: slider_sites.map(site => new Site(site)),
                    to
                }
            });
        }, err => {
            Logger.error(err);
            AlertAction.open('error', translate.i18n('ERROR_RETRIEVE_SLIDERS'), 'top-right');
        });
    }

    /**
      * Method called to reset params of slider search
      */
    reset() {
        Dispatcher.dispatch({
            actionType: Constants.RESET_SLIDER_STORE
        });

        const yesterdayDate = new Date();
        yesterdayDate.setDate(yesterdayDate.getDate() - 30);

        const today = new Date();

        const options = {
            accountId: AccountStore.getCurrentAccount().getId(),
            from: yesterdayDate,
            to: today
        };

        SliderService.getSliders(options).then(({ evolution, from, slider_sites, to }) => {
            Dispatcher.dispatch({
                actionType: Constants.SLIDER_SEARCH_END,
                value: {
                    evolution,
                    from,
                    sliderSites: slider_sites.map(site => new Site(site)),
                    to
                }
            });
        }, err => {
            Logger.error(err);
            AlertAction.open('error', 'Sliders cannot be retrieved', 'top-right');
        });
    }
}

export default new SliderAction();
