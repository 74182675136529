// React
import React from 'react';
import { NavLink } from 'react-router-dom';
// Locales
import translate from 'locales/i18n';
// Material UI Components
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
// Styles
import { useStyles } from 'components/pages/NotFoundPage/NotFoundPage.style';

const NotFoundPage = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}
                elevation={20}>
                <div className={classes.textColumnCenter}>
                    <div className={classes.displayText}>
                        <span className={classes.text404}>
                            <div className={classes.quatreText}>4</div><div className={classes.zeroText}>0</div><div className={classes.quatreText}>4</div>
                        </span>
                        <span className={classes.sentence}>
                            {translate.i18n('PAGE_NOT_FOUND')}
                        </span>
                    </div>
                    <div className={classes.displayHomeButton}>
                        <NavLink
                            to={'/smart-price-extractor'}
                            activeClassName='active'
                            className={classes.navLink}>
                            <Button
                                variant='contained'
                                color='primary'
                                className={classes.button}
                                startIcon={<Icon>home</Icon>}
                                size='large'
                            >
                                {translate.i18n('HOME')}
                            </Button>
                        </NavLink>
                    </div>
                </div>
            </Paper>
        </div>
    );
};

export default NotFoundPage;
