class Suggestion {

    constructor(data) {
        this.configs = data?.configs;
        this.imgUrl = data?.img_url;
        this.name = data?.name;
        this.price = data?.price;
        this.score = data?.score;
        this.url = data?.url;
        this.brand = data?.brand;
        this.categories = data?.categories;
    }

}

export default Suggestion;
