import React from 'react';
import PropTypes from 'prop-types';
// Styles
import { useStyles } from 'components/dialog/DialogAddUrl/DialogAddUrl.style.js';
import { useTheme } from '@mui/styles';
//Mui components
import {
    Card,
    CardMedia,
    Grid,
    Tooltip,
    CardContent,
    IconButton
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
//Pcp components
import ImageViewer from 'components/commons/ImageViewer/ImageViewer';
// Locales
import translate from 'locales/i18n';
//Actions & Stores
import AccountStore from 'stores/AccountStore';
// Facade
import Facade from 'core/Facade';

const DialogAddUrlAccountProduct = ({
    item,
    displayPrice,
    accountSiteUrl
}) => {

    const classes = useStyles();
    const theme = useTheme();

    const hoverBg = (e) => e.target.style.background = theme.palette.grey.ultralight;
    const whiteBg = (e) => e.target.style.background = 'white';

    const copyCodeToClipboard = text => {
        navigator.clipboard.writeText(text);
    };

    const formatUrlForSerach = () => accountSiteUrl.replace('https://','').replace('www.','').split('/')[0];

    return <Card className={classes.accountProduct}>
        <CardMedia className={classes.accountProductImage}>
            <ImageViewer imageUrl={'https://img.pricecomparator.pro/' + item.imgPath} />
        </CardMedia>
        <CardContent className={classes.accountProductInfos}>
            <Grid container>
                <Grid
                    item xs={11}
                    className={classes.productName}>
                    <Tooltip title='Click to copy' placement='top' disableInteractive>
                        <div onClick={() => copyCodeToClipboard(item.name)}
                            onMouseOver={(e) => hoverBg(e)}
                            onMouseLeave={(e) => whiteBg(e)}
                            className={classes.hoverBg}>
                            {item.name}
                        </div>
                    </Tooltip>
                </Grid>
                <Tooltip title={translate.i18n('DIALOG_ADD_URL.SEARCH_GOOGLE')} placement='top' disableInteractive>
                    <Grid item xs={1}>
                        <IconButton size='small' onClick={() => Facade.openUrl(`https://www.google.com/search?q=${item.name} site:${formatUrlForSerach()}&tbm=shop`)}>
                            <GoogleIcon style={{ width: '15px', height: '15px' }} />
                        </IconButton>
                    </Grid>
                </Tooltip>
                {AccountStore.getCurrentAccount().isRetailer() &&
                    <>
                        <Grid item xs={4}>
                            {translate.i18n('DIALOG_ADD_URL.PRICE')} :
                        </Grid>
                        <Grid item xs={8}>
                            {item.scrapingData.find(sd => AccountStore.getCurrentAccount().getSiteId() === sd.siteId).to?.price ?
                                item.scrapingData.find(sd => AccountStore.getCurrentAccount().getSiteId() === sd.siteId).to.status === 'SOLD' ? displayPrice(item.scrapingData.find(sd => AccountStore.getCurrentAccount().getSiteId() === sd.siteId).to.price) : ' - '
                                :
                                item.scrapingData.find(sd => AccountStore.getCurrentAccount().getSiteId() === sd.siteId).scrapedData?.status === 'SOLD' ? displayPrice(item.scrapingData.find(sd => AccountStore.getCurrentAccount().getSiteId() === sd.siteId).scrapedData.price) : ' - '
                            }
                        </Grid>
                    </>
                }
                <Grid item xs={4}>
                    {translate.i18n('DIALOG_ADD_URL.RRP')} :
                </Grid>
                <Grid item xs={8}>
                    {item.rrp ? displayPrice(item.rrp) : ' - '}
                </Grid>
                <Grid item xs={4}>
                    {translate.i18n('DIALOG_ADD_URL.CATEGORY')} :
                </Grid>
                {item.category?.name && item.category.name.length > 22 ?
                    <Tooltip title={item.category.name} placement='top' disableInteractive>
                        <Grid item xs={8} className={classes.hideOverflow}>
                            <div onClick={() => item.category?.name ? copyCodeToClipboard(item.category?.name) : null}
                                onMouseOver={(e) => item.category?.name ? hoverBg(e) : null}
                                onMouseLeave={(e) => whiteBg(e)}
                                className={classes.hoverBg}>
                                {item.category?.name ? item.category.name : ' - '}
                            </div>
                        </Grid>
                    </Tooltip>
                    :
                    <Grid item xs={8}>
                        <div onClick={() => item.category?.name ? copyCodeToClipboard(item.category?.name) : null}
                            onMouseOver={(e) => item.category?.name ? hoverBg(e) : null}
                            onMouseLeave={(e) => whiteBg(e)}
                            className={classes.hoverBg}>
                            {item.category?.name ? item.category.name : ' - '}
                        </div>
                    </Grid>
                }
                {item.customAttributes.map((attribute, index) => index < 6 && <div key={index} style={{ display: 'contents' }}><Grid item xs={4}>
                    {attribute.name} :
                </Grid>
                {attribute.value.length > 22 ?
                    <Tooltip title={attribute.value} placement='top' disableInteractive>
                        <Grid item xs={8} className={classes.hideOverflow}>
                            <div onClick={() => copyCodeToClipboard(attribute.value)}
                                onMouseOver={(e) => hoverBg(e)}
                                onMouseLeave={(e) => whiteBg(e)}
                                className={classes.hoverBg}>
                                {attribute.value}
                            </div>
                        </Grid>
                    </Tooltip>
                    :
                    <Grid item xs={8}>
                        <div onClick={() => copyCodeToClipboard(attribute.value)}
                            onMouseOver={(e) => hoverBg(e)}
                            onMouseLeave={(e) => whiteBg(e)}
                            className={classes.hoverBg}>
                            {attribute.value}
                        </div>
                    </Grid>
                }
                </div>
                )}
            </Grid>
            <div className={classes.accountName}>
                {translate.i18n('DIALOG_ADD_URL.YOUR_ARTICLE')}
            </div>
        </CardContent>
    </Card>;

};

DialogAddUrlAccountProduct.propTypes = {
    item: PropTypes.object,
    displayPrice: PropTypes.func,
    accountSiteUrl: PropTypes.string,
};

export default DialogAddUrlAccountProduct;
