import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    categoryName: {
        fontSize: '14px',
        fontWeight: 500
    },
    categoryNameContainer: {
        alignItems: 'center',
        backgroundColor: theme.palette.grey.light,
        display: 'flex',
        flexDirection: 'row',
        margin: 0
    },
    closeButton: {
        position: 'absolute',
        right: '2px',
        top: '2px'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0px 5px 5px 5px',
        width: '100%'
    },
    dialogPaper: {
        height: '80%',
        width: '500px'
    },
    title: {
        margin: 'auto'
    }
}));
