import makeStyles from '@mui/styles/makeStyles';
import { getColor } from '../SliderTimelineFilter/SliderTimelineFilter.util';

export const useStyles = makeStyles((theme) => ({
    dayChip: {
        borderRadius: '90px',
        color: 'white',
        fontSize: 'medium',
        fontWeight: 'bold',
        height: '12px',
        width: '12px'
    },
    dayChipLabel: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    dayChipTooltipArrow: {
        color: 'rgba(24, 144, 165, 0.9)'
    },
    dayChipTooltipTitle: {
        fontSize: '15px'
    },
    selectedDayChipTooltipTitle: {
        backgroundColor: 'rgba(24, 144, 165, 0.9)',
        fontSize: 'large'
    },
    firstDayTooltipTitle: {
        alignItems: 'center',
        backgroundColor: (props) => getColor(props?.day.count, props?.scale, theme),
        borderRadius: '25%',
        display: 'flex',
        fontWeight: 'bold',
        height: '20px',
        justifyContent: 'center',
        marginBottom: '-1px !important',
        width: '15px'
    },
    infoTooltipArrow: {
        color: (props) => getColor(props?.day.count, props?.scale, theme),
        left: '-0.5px !important',
        top: '19px'
    },
    infoTooltipTitle: {
        alignItems: 'center',
        backgroundColor: (props) => getColor(props?.day.count, props?.scale, theme),
        borderRadius: '25%',
        display: 'flex',
        fontWeight: 'bold',
        height: '20px',
        justifyContent: 'center',
        marginBottom: '7px !important',
        width: '18px'
    },
    selectedChipText: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    tooltipPopper: {
        zIndex: 1000,
        cursor: 'pointer'
    }
}));
