// Locales
import translate from 'locales/i18n';

/**
  * Method which create data for rules filter display
  *
  * @param rules <array<object>> array of rules
  * @returns array<object>> Data object formatted to display graph
  */
export const createData = (rules, theme) => {
    if (rules.length === 0)
        return null;

    const firstSet = [];
    const secondSet = [];
    const thirdSet = [];
    const forthSet = [];
    const fifthSet = [];
    const labels = [];

    rules.forEach((rule) => {

        const value = rule.cardinalities.TOTAL;

        firstSet.push(rule.cardinalities.DROPPING_PRICES);
        secondSet.push(rule.cardinalities.EQUAL_PRICES);
        thirdSet.push(rule.cardinalities.RAISING_PRICES);
        forthSet.push(rule.cardinalities.IMPOSSIBLE_REPRICING);

        if (value === 0) {
            fifthSet.push(0.9);
        } else {
            fifthSet.push(0);
        }
        labels.push(rule.name);

        return true;
    });

    const data = {
        datasets: [{
            backgroundColor: theme.palette.graph.five,
            borderColor: theme.palette.graph.five,
            data: firstSet,
            hoverBackgroundColor: theme.palette.graph.five,
            hoverBorderColor: theme.palette.graph.five,
            label: translate.i18n('DROPPING_PRICES')
        }, {
            backgroundColor: theme.palette.graph.four,
            borderColor: theme.palette.graph.four,
            data: secondSet,
            hoverBackgroundColor: theme.palette.graph.four,
            hoverBorderColor: theme.palette.graph.four,
            label: translate.i18n('EQUAL_PRICES')
        }, {
            backgroundColor: theme.palette.graph.three,
            borderColor: theme.palette.graph.three,
            data: thirdSet,
            hoverBackgroundColor: theme.palette.graph.three,
            hoverBorderColor: theme.palette.graph.three,
            label: translate.i18n('RAISING_PRICES')
        }, {
            backgroundColor: theme.palette.rulesRanks.five,
            borderColor: theme.palette.rulesRanks.five,
            data: forthSet,
            hoverBackgroundColor: theme.palette.rulesRanks.five,
            hoverBorderColor: theme.palette.rulesRanks.five,
            label: translate.i18n('IMPOSSIBLE_REPRICING')
        },
        {
            backgroundColor: 'white',
            borderColor: 'white',
            data: fifthSet,
            hoverBackgroundColor: theme.palette.grey.ultralight,
        }],
        labels
    };
    return data;
};

/**
  * Method which return the options for a graph display
  *
  * @returns <object> the options object
  */
export const getOptions = rules => ({
    borderRadius: 2,
    indexAxis: 'y',
    maintainAspectRatio: false,
    maxBarThickness: 30,
    plugins: {
        datalabels: {
            color: 'rgba(0, 0, 0, 0.87)',
            anchor: 'start',
            align: 'right',
            offset: 4,
            backgroundColor: 'rgba(242, 242, 242, 0.8)',
            padding: {
                top: 2.5,
                left: 8,
                right: 8,
                bottom: 1,
            },
            borderRadius: 14,
            formatter: (value, context) => {
                if (context.datasetIndex === 0) return rules[context.dataIndex].name;
                return null;
            },
        },
        legend: {
            display: false
        },
        tooltip: {
            filter(arg) {
                return arg.raw !== 0 && arg.raw !== 0.9;
            }
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
                drawBorder: false
            },
            stacked: true,
            ticks: {
                display: false
            }
        },
        y: {
            grid: {
                display: false,
                drawBorder: false
            },
            stacked: true,
            ticks: {
                display: false,
            }
        }
    }
});
