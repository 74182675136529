// React
import React from 'react';
import PropTypes from 'prop-types';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// Styles
import { useStyles } from 'components/dialog/DialogConfirm/DialogConfirm.style';
import { DialogContent } from '@mui/material';

const DialogConfirm = ({ callback, close, content, open, text }) => {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={close}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
            classes={{
                paper: classes.paper
            }}
        >
            <DialogTitle id='responsive-dialog-title'>{text}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <IconButton color='secondary' onClick={close} size='large'>
                    <ClearIcon />
                </IconButton>
                <IconButton
                    color='primary'
                    onClick={() => {
                        callback();
                        close();
                    }}
                    size='large'>
                    <DoneIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

DialogConfirm.propTypes = {
    callback: PropTypes.func,
    close: PropTypes.func,
    content: PropTypes.string,
    fullScreen: PropTypes.bool,
    open: PropTypes.bool,
    text: PropTypes.string
};

export default DialogConfirm;
