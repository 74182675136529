import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Locales
import translate from 'locales/i18n';
//Mui components
import IconButton from '@mui/material/IconButton';
import RightIcon from '@mui/icons-material/KeyboardArrowRight';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
// Style
import { useStyles } from 'components/sheet/SelectItemsSheet/SelectItemsSheet.style';
// PCP Components
import ProductLine from 'components/sheet/SelectItemsSheet/ProductLine';
import { TextField } from '@mui/material';

const SelectItemsSheet = React.memo(({ categories, selectedItems, selectItems, unselectItems, hideCategory, ruleId, setRank }) => {
    const [onlyNoRule, setOnlyNoRule] = useState(false);
    const [searchText, setSearchText] = useState('');

    const searchFilter = (product) => {
        let isSearched = false;
        if (searchText === '') return true;
        if (product.name.toLowerCase().includes(searchText.toLowerCase())) return true;

        product.customAttributes.forEach(ca => {
            if (ca.value.toLowerCase().includes(searchText.toLowerCase())) isSearched = true;
        });

        return isSearched;
    };
    const allItemsChecked = selectedItems.length === categories.reduce((acc, c) => c.associatedItems.filter(item => searchFilter(item)).map(item => item.id).concat(acc), []).length;

    const isSelected = (productId) => {
        let isIn = false;
        selectedItems.forEach(p => {
            if (p.id === productId) isIn = true;
        });
        return isIn;
    };

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                {translate.i18n('ITEMS_SELECTION')}
                <div className={classes.titleBar}></div>
                <FormControlLabel
                    control={
                        <Switch
                            checked={onlyNoRule}
                            onChange={() => setOnlyNoRule(!onlyNoRule)}
                            name='onlyNoRule'
                            color='primary'
                        />
                    }
                    label={translate.i18n('ONLY_WITHOUT_RULES')}
                />
            </div>
            <div className={classes.fullContainer}>
                <div className={classes.categoryNameContainer}>
                    <Checkbox
                        checked={!!allItemsChecked}
                        onChange={() => allItemsChecked ? unselectItems(categories.reduce((acc, c) => c.associatedItems.filter(item => searchFilter(item)).map(item => item.id).concat(acc), [])) : selectItems(categories.reduce((acc, c) => c.associatedItems.filter(item => searchFilter(item)).map(item => item.id).concat(acc), []))}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        color='primary'
                    />
                    <p className={classes.categoryName}>{translate.i18n('ALL_ITEMS') + ' (' + categories.reduce((acc, c) => c.associatedItems.filter(item => searchFilter(item)).concat(acc), []).length + ' ' + translate.i18n('ITEMS') + ')'}</p>
                    <TextField
                        className={classes.searchItems}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder={'Article name, EAN, etc...'}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'><SearchIcon className={classes.searchIcon} /></InputAdornment>
                        }}
                        size='small'
                    />
                </div>
                {categories.map((category, key) =>
                    <div key={key}>
                        <div className={classes.categoryNameContainer}>
                            <Checkbox
                                checked={!!category.checked}
                                onChange={() => category.checked ? unselectItems(category.associatedItems.filter(item => searchFilter(item)).map(item => item.id), category.id) : selectItems(category.associatedItems.filter(item => searchFilter(item)).map(item => item.id), category.id)}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                color='primary'
                            />
                            <IconButton
                                className={classes.margin}
                                onClick={() => hideCategory(category.id)}
                                size='large'>
                                {!category.hide ?
                                    <DownIcon fontSize='small' />
                                    :
                                    <RightIcon fontSize='small' />
                                }
                            </IconButton>
                            <p className={classes.categoryName}>{category.name + ' (' + category.associatedItems.filter(item => searchFilter(item)).length + ' ' + translate.i18n('ITEMS') + ')'}</p>
                        </div>
                        {category.hide ? null
                            :
                            category.associatedItems.filter(item => searchFilter(item)).map((item, index) => {
                                if (onlyNoRule && item.associatedPricingEngineRules.length > 0)
                                    return null;

                                return <ProductLine
                                    key={index}
                                    product={item}
                                    selectProduct={selectItems}
                                    ruleId={ruleId}
                                    unSelectProduct={unselectItems}
                                    setProductRank={setRank}
                                    selected={isSelected(item.id)} />;
                            })}
                    </div>
                )}
            </div>
        </div>
    );
});

SelectItemsSheet.propTypes = {
    categories: PropTypes.array,
    selectedItems: PropTypes.array,
    selectItems: PropTypes.func,
    unselectItems: PropTypes.func,
    hideCategory: PropTypes.func,
    ruleId: PropTypes.string,
    setRank: PropTypes.func
};

export default SelectItemsSheet;
