// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//Material
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import { Collapse, DialogContent, Fab, List, ListItem, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ListIcon from '@mui/icons-material/List';
import { TransitionGroup } from 'react-transition-group';
// Locales
import translate from 'locales/i18n';
// Styles
import { useStyles } from 'components/dialog/DialogConfigUrl/DialogConfigUrl.style.js';
// Util
import { buildUrl, decodeUrl } from 'components/dialog/DialogConfigUrl/DialogConfigUrl.util.js';
// Theme
import { useTheme } from '@mui/styles';

const DialogConfigUrl = ({ open, close, callback, selectedProduct, fullUrl }) => {

    const classes = useStyles();
    const theme = useTheme();

    const [configs, setConfigs] = useState([]);
    const [operations, setOperations] = useState([]);
    const [refs, setRefs] = useState('');

    const [decodedUrl, setDecodedUrl] = useState({});

    const [cleanUrl, setCleanUrl] = useState('');

    useEffect(() => {
        setDecodedUrl(decodeUrl(fullUrl, selectedProduct.configs));
        if (decodedUrl.refs) {
            setCleanUrl(decodedUrl.newUrl);
            setOperations(decodedUrl.operations);
            decodedUrl.refs && setRefs(decodedUrl.refs[0]);
            setConfigs(decodedUrl.configs);
        }

    }, [open, fullUrl, selectedProduct]);

    return (
        <Dialog
            fullScreen={useMediaQuery(theme.breakpoints.down('md'))}
            open={open}
            onClose={close}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
        >
            <DialogContent className={classes.dialogContainer}>
                <div className={classes.bwContainer}>
                    <span className={classes.title}>{translate.i18n('DIALOG_ADD_URL.OPERATIONS')} : </span>
                    <Tooltip title={translate.i18n('DIALOG_ADD_URL.MULTIPLY_BY')} placement='top'>
                        <Fab
                            color='primary'
                            aria-label='Mul operation'
                            onClick={() => setOperations([...operations, { mul: 1 }])}
                            className={classes.operationFab}
                            style={{ marginLeft: 'auto' }}>
                            <CloseIcon />
                        </Fab>
                    </Tooltip>
                    <Tooltip title={translate.i18n('DIALOG_ADD_URL.ADD')} placement='top'>
                        <Fab
                            color='warning'
                            aria-label='Add operation'
                            onClick={() => setOperations([...operations, { add: 0 }])}
                            className={classes.operationFab}
                            style={{ marginLeft: '20px' }}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                    <Fab
                        color='success'
                        aria-label='TTC to HT'
                        variant='extended'
                        onClick={() => setOperations([...operations, { mul: 0.833333334 }])}
                        className={classes.longOperationFab}
                        style={{ marginLeft: 'auto' }}>
                        TTC to HT
                    </Fab>
                </div>
                <List>
                    <TransitionGroup>
                        {operations.map((operation, index) =>
                            <Collapse key={index}>
                                {Object.keys(operation)[0] === 'add' ?
                                    <ListItem
                                        className={classes.bwContainer}
                                        secondaryAction={
                                            <IconButton
                                                edge='end'
                                                onClick={() => {
                                                    const newArray = operations.slice();
                                                    newArray.splice(index, 1);
                                                    setOperations(newArray);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <span className={classes.text}>{translate.i18n('DIALOG_ADD_URL.ADD')} : </span>
                                        <TextField
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            className={classes.textField}
                                            size='small'
                                            id='outlined-number'
                                            variant='standard'
                                            label={translate.i18n('DIALOG_ADD_URL.ADD')}
                                            type='number'
                                            value={operation.add}
                                            onChange={event => {
                                                const newArray = operations.slice();
                                                const value = parseFloat(event.target.value);
                                                if (isNaN(value)) return;
                                                if (value >= 0) {
                                                    newArray[index] = { add: value };
                                                    setOperations(newArray);
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </ListItem>
                                    :
                                    <ListItem
                                        className={classes.bwContainer}
                                        secondaryAction={
                                            <IconButton
                                                edge='end'
                                                onClick={() => {
                                                    const newArray = [...operations];
                                                    newArray.splice(index, 1);
                                                    setOperations(newArray);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <span className={classes.text}>{translate.i18n('DIALOG_ADD_URL.MULTIPLY_BY')} : </span>
                                        <TextField
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            className={classes.textField}
                                            size='small'
                                            id='outlined-number'
                                            variant='standard'
                                            label={translate.i18n('DIALOG_ADD_URL.MULTIPLY_BY')}
                                            type='number'
                                            value={operation.mul}
                                            onChange={event => {
                                                const newArray = operations.slice();
                                                const value = parseFloat(event.target.value);
                                                if (isNaN(value)) return;
                                                if (value >= 0) {
                                                    newArray[index] = { mul: isNaN(value) ? event.target.value : value };
                                                    setOperations(newArray);
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </ListItem>}
                            </Collapse>
                        )}
                    </TransitionGroup>
                </List>
                <div className={classes.bwContainer}>
                    <span className={classes.title}>{translate.i18n('DIALOG_ADD_URL.CONFIGS')} : </span>
                    <Fab
                        color='primary'
                        aria-label='Add config'
                        onClick={() => setConfigs([...configs, {}])}
                        variant='extended'
                        className={classes.configFab}>
                        {translate.i18n('DIALOG_ADD_URL.ADD_CONFIG')}
                    </Fab>
                </div>
                <List>
                    <TransitionGroup>
                        {configs.map((c, index) =>
                            <Collapse key={index}>
                                {c.value ?
                                    c.display ?
                                        <ListItem
                                            className={classes.bwContainer}
                                            secondaryAction={
                                                <IconButton
                                                    edge='end'
                                                    onClick={() => {
                                                        const newConf = configs.slice(0);
                                                        newConf[index].select = !c.select;
                                                        setConfigs(newConf);
                                                    }}
                                                >
                                                    {c.select ?
                                                        <Tooltip title={translate.i18n('DIALOG_ADD_URL.ENTER_OTHER')} placement='top'>
                                                            <BorderColorIcon />
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title={translate.i18n('DIALOG_ADD_URL.SEE_PROPS')} placement='top'>
                                                            <ListIcon />
                                                        </Tooltip>
                                                    }
                                                </IconButton>
                                            }>
                                            <span className={classes.text}>{c.key + ' : '}</span>
                                            {c.select ?
                                                <FormControl variant='outlined' size='small' className={classes.textField}>
                                                    <Select
                                                        value={c.chosenValue}
                                                        className={classes.textField}
                                                        label={c.key}
                                                        variant='standard'
                                                        onChange={event => {
                                                            const newConf = configs.slice(0);
                                                            newConf[index].chosenValue = event.target.value;
                                                            setConfigs(newConf);
                                                        }}
                                                    >
                                                        <MenuItem value={''}>{translate.i18n('DIALOG_ADD_URL.NONE')}</MenuItem>
                                                        {c.value.map(v => <MenuItem value={v} key={v}>{v}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                                :
                                                <TextField
                                                    className={classes.textField}
                                                    size='small'
                                                    id='outlined-number'
                                                    variant='standard'
                                                    value={c.chosenValue}
                                                    onChange={(event) => {
                                                        const newConf = configs.slice(0);
                                                        newConf[index].chosenValue = event.target.value;
                                                        setConfigs(newConf);
                                                    }}
                                                />
                                            }
                                        </ListItem>
                                        :
                                        <ListItem
                                            className={classes.bwContainer}
                                            secondaryAction={
                                                !c.display && <IconButton
                                                    edge='end'
                                                    onClick={() => {
                                                        const newArray = [...configs];
                                                        newArray.splice(index, 1);
                                                        setConfigs(newArray);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            }>
                                            <span className={classes.text}>{c.key + ' : '}</span>
                                        </ListItem>
                                    :
                                    <ListItem
                                        className={classes.bwContainer}
                                        secondaryAction={
                                            !c.display && <IconButton
                                                edge='end'
                                                onClick={() => {
                                                    const newArray = [...configs];
                                                    newArray.splice(index, 1);
                                                    setConfigs(newArray);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }>
                                        {c.display ?
                                            <span className={classes.text}>{c.key + ' : '}</span>
                                            :
                                            <TextField
                                                className={classes.textField}
                                                size='small'
                                                id='outlined-number'
                                                variant='standard'
                                                label={translate.i18n('DIALOG_ADD_URL.CONFIG_NAME')}
                                                value={c.key ? c.key.toUpperCase() : ''}
                                                onChange={(event) => {
                                                    const newConf = configs.slice(0);
                                                    newConf[index].key = event.target.value.toUpperCase();
                                                    setConfigs(newConf);
                                                }}
                                            />
                                        }
                                        <TextField
                                            className={classes.textField}
                                            size='small'
                                            id='outlined-number'
                                            variant='standard'
                                            label={translate.i18n('DIALOG_ADD_URL.VALUE')}
                                            value={c.chosenValue ? c.chosenValue.toUpperCase() : ''}
                                            onChange={(event) => {
                                                const newConf = configs.slice(0);
                                                newConf[index].chosenValue = event.target.value.toUpperCase();
                                                setConfigs(newConf);
                                            }}
                                        />
                                    </ListItem>
                                }
                            </Collapse>
                        )}
                    </TransitionGroup>
                </List>
                <div className={classes.bwContainer}>
                    <span className={classes.title}>{translate.i18n('DIALOG_ADD_URL.REF')} : </span>
                    <TextField
                        className={classes.longTextField}
                        size='small'
                        id='outlined-number'
                        variant='standard'
                        value={refs}
                        onChange={(event) => setRefs(event.target.value)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <IconButton
                    color='red'
                    onClick={() => {
                        setOperations([]);
                        close();
                    }}
                    size='large'>
                    <CloseIcon />
                </IconButton>
                <IconButton
                    color='primary'
                    onClick={() => {
                        const newUrl = buildUrl(cleanUrl, operations, configs, refs);
                        setOperations([]);
                        callback(newUrl);
                    }}
                    size='large'>
                    <DoneIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

DialogConfigUrl.propTypes = {
    callback: PropTypes.func,
    close: PropTypes.func,
    open: PropTypes.bool,
    selectedProduct: PropTypes.object,
    fullUrl: PropTypes.string,
};

export default DialogConfigUrl;
