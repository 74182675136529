import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    avgEvaluation: {
        position: 'absolute',
        right: '5px',
        top: '12px'
    },
    endSpace: {
        height: '14px',
        width: '100%'
    },
    label: {
        color: theme.palette.grey.dark,
        display: 'flex',
        fontSize: '14px',
        fontWeight: '500',
        justifyContent: 'center',
        marginLeft: '3px',
        marginRight: '3px'
    },
    pricingAdvising: {
        bottom: '0px',
        color: theme.palette.info.light,
        position: 'absolute',
        right: '5px'
    },
    progressContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    },
    siteEvaluation: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    siteIcon: {
        marginLeft: '3px'
    },
    table: {
        borderCollapse: 'separate'
    },
    tableContainer: {
        height: 'calc(100% - 57px)'
    },
    tableHead: {
        height: '57px'
    },
    tableHeadCell: {
        backgroundColor: theme.palette.white.main,
        borderRight: '1px solid' + theme.palette.grey.light,
        minWidth: '130px',
        padding: 0,
        position: 'sticky',
        top: 0,
        zIndex: 11
    },
    tableHeadContent: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        marginBottom: '5px',
        marginTop: '5px',
        maxWidth: '270px',
        position: 'relative',
        width: '100%'
    },
    tableHeadFirstCell: {
        backgroundColor: theme.palette.white.main,
        borderRight: '1px solid' + theme.palette.grey.light,
        left: 0,
        minWidth: '130px',
        padding: 0,
        position: 'sticky',
        top: 0,
        zIndex: 12
    },
    tableHeadSecondCell: {
        backgroundColor: theme.palette.white.main,
        borderRight: '1px solid' + theme.palette.grey.light,
        left: '271px',
        padding: '0px 10px 0px 10px',
        position: 'sticky',
        top: 0,
        zIndex: 12
    }
}));
