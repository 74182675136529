// React
import React from 'react';
import PropTypes from 'prop-types';
// Style
import { useStyles } from 'components/chart/PerformanceGraph/PerformanceChart.style';
// Util
import { createData } from 'components/chart/PerformanceGraph/PerformanceChart.util';
// PCP Components
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
// React chartjs
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';

const options = {
    plugins: {
        legend: {
            display: true,
            fullWidth: true,
            position: 'bottom'
        }
    },
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                display: false
            },
            type: 'time',
            stacked: false
        },
        y: {
            grid: {
                display: false
            }
        }
    },
    tooltips: {
        intersect: false,
        mode: 'nearest'
    }
};

export default function PerformanceGraph({ isLoading, sites }) {
    const classes = useStyles();

    if (isLoading)
        return (
            <Paper className={classes.loaderContainer}>
                <CircularProgress size={50} />
            </Paper>
        );

    return (
        <Paper className={classes.graphContainer}>
            <Line data={{
                datasets: createData(sites)
            }}
            options={options} />
        </Paper>
    );
}

PerformanceGraph.propTypes = {
    isLoading: PropTypes.bool,
    sites: PropTypes.array
};
