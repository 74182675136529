import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    button: {
        margin: '5px',
        marginTop: '5px'
    },
    closeButton: {
        position: 'absolute',
        right: '2px',
        top: '2px'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0px 5px'
    },
    dialogContentNotFullScreen: {
        height: '100%'
    },
    slideImage: {
        borderRadius: '3px',
        width: '100%'
    },
    titleDialogBox: {
        color: theme.palette.grey.dark,
        marginBottom: 0,
        marginLeft: '2%',
        marginTop: 0
    },
    toPrintContainer: {
        display: 'none',
        height: '0px'
    }
}));
