// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Style
import { useStyles } from 'components/chip/CategoryChip/CategoryChip.style';
// Locales
import translate from 'locales/i18n';
// Material UI Components
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

const CategoryChip = ({ category, deleteCategory, editCategory, manageCategoryItems }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Chip key={category.id}
                label={category.name + ' ' + translate.i18n('COUNT_PRODUCTS', { count: category.associatedItems.length })}
                onDelete={handleClick}
                deleteIcon={<SettingsIcon />}
                className={classes.label}
            />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    manageCategoryItems(category);
                }}>
                    <ListItemIcon>
                        <CompareArrowsIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>{translate.i18n('MANAGE_PRODUCTS')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    editCategory(category);
                }} disabled={category.name === 'n/a'}>
                    <ListItemIcon>
                        <EditIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>{translate.i18n('RENAME')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    deleteCategory(category);
                }} disabled={category.name === 'n/a'}>
                    <ListItemIcon>
                        <DeleteIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>{translate.i18n('DELETE')}</ListItemText>
                </MenuItem>
            </Menu>

        </div>
    );
};

CategoryChip.propTypes = {
    category: PropTypes.object,
    deleteCategory: PropTypes.func,
    editCategory: PropTypes.func,
    manageCategoryItems: PropTypes.func
};

export default CategoryChip;
