import { shapes, colors } from 'pcp-graphical-lib';

/**
  * Method called to format received dataset by getting out useless data and transforming Dates
  *
  * @param currentItem currentItem Object modified by user
  * @returns { data: { siteId: string, name: string, icon: string, url: string, color: string, shape: Shape, data: array<number>, stock: array<string>, display: boolean }, minDate: Date }
  */
export const formatData = (scrapingData, sites) => {
    const sitesFilteredHistory = sites.map(site => {
        // This will transform the date to get ride of hour, and UTC transformation problems
        let { history } = site;

        // IF : all value are equal to 0 then get them out
        // NOTE : This is because of an api bug, this will have to be remove
        const filteredSum = history
            .map(value => value.price)
            .reduce((a, b) => a + b);
        if (filteredSum === 0) {
            history = history.map(value => {
                value.price = null;
                return value;
            });
        }

        return site;
    });

    // Get all dates
    const dates = sitesFilteredHistory.map(siteHistory => siteHistory.history.map(dateHistory => new Date(dateHistory.date))).flat();
    // Get min date
    const minDate = dates.length > 0 ? dates.reduce((a, b) => a.getTime() < b.getTime() ? a : b) : new Date();
    // Get max date
    const maxDate = dates.length > 0 ? dates.reduce((a, b) => a.getTime() < b.getTime() ? b : a) : new Date();
    // To calculate the time difference of two dates
    const differenceInTime = maxDate.getTime() - minDate.getTime();
    // To calculate the no. of days between two dates
    const differenceInDays = (differenceInTime / (1000 * 3600 * 24)) + 1;
    // Get all possible Shape/Color combination
    const colorShapeCombi = [];
    shapes.forEach(shape => colors.forEach((color) => colorShapeCombi.push({ color, shape })));
    // Create initial data
    const returnData = sitesFilteredHistory.map((siteHistory, index) => {
        const historyData = historyToData(siteHistory, minDate, maxDate, differenceInDays);
        return {
            siteId: siteHistory.id,
            name: siteHistory.name,
            icon: 'https://www.google.com/s2/favicons?sz=32&domain_url=' + scrapingData.find(site => siteHistory.id === site.siteId).siteUrl,
            url: scrapingData.find(site => siteHistory.id === site.siteId).siteUrl,
            color: colorShapeCombi[index%colorShapeCombi.length].color,
            shape: colorShapeCombi[index%colorShapeCombi.length].shape,
            data: historyData.prices,
            stock: historyData.stock,
            display: true
        };
    });
    return { data: returnData, minDate };
};

/**
  * Method called to format dates and transform them to ordered array following date chronology
  *
  * @param siteHistory History of one site
  * @param minDate min found date
  * @param maxDate max found date
  * @returns { price: array<number>, stock: array<string>}
  */
const historyToData = (siteHistory, minDate, maxDate) => {
    // Generate all dates
    const tempDatesPrice = {};
    const tempDatesStock = {};
    const dates = getDates(minDate, maxDate);
    dates.forEach((date) => {
        let day = String(date.getDate());
        if (day.length === 1) day = '0' + day;
        let month = String(date.getMonth()+1);
        if (month.length === 1) month = '0' + month;
        const year = date.getFullYear();
        tempDatesPrice[`${year}/${month}/${day}`] = null;
        tempDatesStock[`${year}/${month}/${day}`] = null;
    });
    let testIfDataAreOk = true;
    if (siteHistory.history.length === 2) {
        if (siteHistory.history[0].price === 0 && siteHistory.history[1].price === 0) {
            testIfDataAreOk = false;
        }
    }
    testIfDataAreOk && siteHistory.history.forEach(hist => {
        const date = new Date(new Date(hist.date.split('T')[0]));
        let day = String(date.getDate());
        if (day.length === 1) day = '0' + day;
        let month = String(date.getMonth()+1);
        if (month.length === 1) month = '0' + month;
        const year = date.getFullYear();
        tempDatesPrice[`${year}/${month}/${day}`] = hist.price;
        tempDatesStock[`${year}/${month}/${day}`] = hist.stock;
    });

    // Dictionnary to Array
    const prices = [];
    const stock = [];
    Object.entries(tempDatesPrice).forEach((value) => {
        prices.push(value[1]);
    });
    Object.entries(tempDatesStock).forEach((value) => {
        stock.push(value[1]);
    });

    // Convert dictionnary to array
    const returnData = { prices, stock };
    return returnData;
};

/**
   * Method called to generate an array containing all dates between 2 dates
   *
   * @param startDate  The starting date (included)
   * @param stopDate The ending date (included)
   * @returns <array<Date>>
   */
export const getDates = (startDate, stopDate) => {
    if (!startDate || !stopDate) return [];

    const dateArray = new Array();
    let currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date (currentDate));
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
};

Date.prototype.addDays = function(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

/**
  * Method called to get all stat from received dataset
  *
  * @param data <array> Received last data
  * @param currentItem <object>
  * @param currentAccount <object>
  * @param history <array>
  * @returns { minPrice: number, maxPrice: number, minAllPrice: number, maxAllPrice: number, yourMinPrice: number, yourMaxPrice: number, avgPrice: number, yourAvgPrice: number, yourPrice: number, otherAvgPrice: number}
  */
export const getPriceStats = (data, currentItem, currentAccount, history) => {
    const tempPriceStats = {
        minPrice: null,
        maxPrice: null,
        minAllPrice : null,
        maxAllPrice : null,
        yourMinPrice: null,
        yourMaxPrice: null,
        avgPrice: null,
        yourAvgPrice: null,
        yourPrice: null,
        otherAvgPrice : null,
        alertLowPrice: null,
        rrp: null,
        rrpAlertPercent :null
    };

    // --- PARTIE MAINTENANT ---
    // Récupération du prix minimum actuel
    tempPriceStats.minPrice = Math.min(...data.map(item => item.scrapedData.price));
    // Récupération du prix maximum actuel
    tempPriceStats.maxPrice = Math.max(...data.map(item => item.scrapedData.price));
    // Récupération du prix moyen actuel
    let avgPrice = data.map(item => item.scrapedData.price);
    avgPrice = avgPrice.reduce((a, b) => a + b) / avgPrice.length;
    tempPriceStats.avgPrice = avgPrice;

    // --- PARTIE TOUT LE TEMPS ---
    // Récupération de tous les prix
    let priceHistory = history.map(oneSiteHistory => oneSiteHistory.history);
    priceHistory = priceHistory.map(hist => hist.map(oneHist => oneHist.price));
    priceHistory = priceHistory.flat();
    // Récupération du prix minimum jamais observé
    tempPriceStats.minAllPrice = Math.min(...priceHistory);
    // Récupération du prix maximum jamais observé
    tempPriceStats.maxAllPrice = Math.max(...priceHistory);
    // --- PARTIE VENDEUR ---
    // Récupération du prix pratiqué par le vendeur
    tempPriceStats.yourPrice = data.filter(prod => prod.siteId === currentAccount.site.id);
    tempPriceStats.yourPrice = tempPriceStats.yourPrice.length > 0 ? tempPriceStats.yourPrice[0].scrapedData.price : null;
    // Récupération de tous les prix du vendeur
    let yourPriceHistory = history.filter(site => site.id === currentAccount.site.id);
    if (yourPriceHistory[0]) {
        yourPriceHistory = yourPriceHistory[0].history;
        yourPriceHistory = yourPriceHistory.map(hist => hist.price);
        yourPriceHistory = yourPriceHistory.filter(price => price > 0);
        yourPriceHistory = yourPriceHistory.flat();

        // Récupération du prix minimum du vendeur
        tempPriceStats.yourMinPrice = Math.min(...yourPriceHistory);
        // Récupération du prix maximum du vendeur
        tempPriceStats.yourMaxPrice = Math.max(...yourPriceHistory);
        // Récupération du prix moyen du vendeur
        let yourAvgPrice = yourPriceHistory.length > 0 && yourPriceHistory.reduce((a, b) => a + b);
        yourAvgPrice /= yourPriceHistory.length;
        tempPriceStats.yourAvgPrice = yourAvgPrice;
    }

    // --- PARTIE AUTRES VENDEURS ---
    // Récupération de tous les prix des autres vendeur
    let otherVendorPriceHistory = history.filter(oneSiteHistory => oneSiteHistory.id !== currentAccount.site.id);

    let otherAvgPrice;
    if (otherVendorPriceHistory.length > 0) {
        otherVendorPriceHistory = otherVendorPriceHistory.map(hist => hist.history.map(priceHist => priceHist.price));
        otherVendorPriceHistory = otherVendorPriceHistory.flat();
        otherVendorPriceHistory = otherVendorPriceHistory.filter(value => value > 0);

        // Récupération du prix moyen des autres vendeurs
        if (otherVendorPriceHistory.length > 0) {
            otherAvgPrice = otherVendorPriceHistory.reduce((a, b) => a + b);
            otherAvgPrice /= otherVendorPriceHistory.length;
            tempPriceStats.otherAvgPrice = otherAvgPrice;
        }
    } else {
        tempPriceStats.otherAvgPrice = null;
    }

    tempPriceStats.rrp = currentItem.rrp ? Number(currentItem.rrp) : null;
    tempPriceStats.rrpAlertPercent = currentItem.rrpAlertPercentage ? Number(currentItem.rrpAlertPercentage) : null;
    tempPriceStats.alertLowPrice = currentItem.alertingLowPrice ? Number(currentItem.alertingLowPrice) : null;
    return tempPriceStats;
};

/**
  * Method called when user want to send edited data database to validate his input
  *
  * @param currentItem currentItem Object modified by user
  * @param supplierMode <boolean> true if supplier
  * @returns <boolean>
  */
export const validItem = (currentItem, supplierMode) => {
    if (!currentItem.name || currentItem.name === '') return false;
    if (!currentItem.category.id || currentItem.category.id === '') return false;
    if (supplierMode) {
        if (!currentItem.rrp || currentItem.rrp === '') return false;
        if (!currentItem.rrpAlertPercentage || currentItem.rrpAlertPercentage === '') return false;
    }
    return true;
};
