// Abstract
import AbstractStore from 'core/abstracts/AbstractStore';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Entities
import User from 'core/entities/UserObject';
// Locales
import translate from 'locales/i18n';

class UserStore extends AbstractStore {

    constructor() {

        const CHANGE_EVENT = 'USER_CHANGE_EVENT';
        const NAME = 'USER_STORE';

        super(NAME, CHANGE_EVENT);

        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));

        this._initStore();
    }

    _initStore() {
        // currentUser
        this.currentUser = new User();

        // if he is waiting to login
        this.isLoading = false;

        // credentials used by the user but maybe wrong
        this.credentials = {
            login: ''
        };
    }

    getCurrentUser() {
        return this.currentUser;
    }

    isLoading() {
        return this.isLoading;
    }

    saveProfile(data) {
        this.getCurrentUser().setLogin(data.login);
        this.getCurrentUser().setFirstname(data.firstname);
        this.getCurrentUser().setLastname(data.lastname);
        this.getCurrentUser().setLanguage(data.language);

        translate.setLanguage(this.getCurrentUser().getLanguage());
    }

    changeLanguage(lang) {
        this.getCurrentUser().setLanguage(lang);
        translate.setLanguage(lang);
    }

    // Sign methods
    signUser(data) {
        this.currentUser = new User(data);
        this.currentUser.isLogged = true;
        translate.setLanguage(this.getCurrentUser().getLanguage());
    }

    isLogged() {
        if (this.currentUser !== null) {
            return this.currentUser.isLogged;
        }
        return false;
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
        case Constants.USER_LOGIN:
            this.signUser(action.value.user);
            this._emitChange();
            break;
        case Constants.USER_LOGIN_START:
            this.credentials = action.value;
            this.isLoading = true;
            this._emitChange();
            break;
        case Constants.USER_LOGOUT:
            this._initStore();
            this._emitChange();
            break;
        case Constants.USER_LOGIN_FAILED:
            this.isLoading = false;
            this._emitChange();
            break;
        case Constants.USER_SAVE_PROFILE:
            this.saveProfile(action.value);
            this._emitChange();
            break;
        case Constants.USER_CHANGE_LANGUAGE:
            this.changeLanguage(action.value);
            this._emitChange();
            break;
        default:
            return false;
        }
    }
}

export default new UserStore();
