import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    categoryListContainer: {
        height: 'calc(100% - 36px)',
        overflow: 'auto',
        width: 'calc(100% + 1px)'
    },
    iconSettings: {
        width: '32px'
    },
    infoButton: {
        padding: '5px',
        position: 'absolute',
        right: '6px',
        top: '6px',
        zIndex: 400
    },
    infoImgContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    infoLegendText: {
        fontSize: '12px',
        marginTop: '6px',
        textAlign: 'center'
    },
    infoMainText: {
        fontWeight: 500
    },
    infoSubText: {
        fontStyle: 'italic'
    },
    notAllowed: {
        cursor: 'wait',
        opacity: 0.5
    },
    paper: {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        width: '100%'
    },
    resetButton: {
        padding: '5px',
        position: 'absolute',
        right: '35px',
        top: '5px',
        zIndex: 400
    },
    settingsButtonContainer: {
        background: theme.palette.grey.semilight,
        boxShadow: '0px 0px 3px 1px rgb(0 0 0 / 15%) inset'
    },
    simpleBar: {
        height: '100%',
        width: '100%'
    },
    sortButtonContainer: {
        height: '32px',
    },
    title: {
        color: theme.palette.grey.ultradark,
        fontSize: '12px',
        fontWeight: '500',
        marginBottom: '8px',
        marginTop: '6px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        height: '22px',
        alignItems: 'center'
    },
}));
