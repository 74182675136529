import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: '2px',
        top: '2px'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '450px'
    },
    dialogContentNotFullScreen: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: '200px',
        minWidth: '300px',
        paddingLeft: '10%',
        paddingRight: '10%'
    },
    leftIcon: {
        fontSize: 20,
        marginRight: theme.spacing(1)
    },
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%'
    },
    radioButton: {
        marginLeft: theme.spacing(1)
    }
}));
