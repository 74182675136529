// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Service
import CategoryService from 'services/CategoryService';
// Entities
import Category from 'core/entities/CategoryObject';
// Action
import AlertAction from './AlertAction';
// Locales
import translate from 'locales/i18n';

class CategoryAction {

    /**
      * Method called to get categories
      *
      * @param accountId the account id
      */
    getCategories(accountId) {
        Dispatcher.dispatch({
            actionType: Constants.CATEGORIES_GET_START
        });

        CategoryService.getCategories(accountId).then(categories => {
            Dispatcher.dispatch({
                actionType: Constants.CATEGORIES_GET_END,
                value: categories.map(category => new Category(category))
            });
        }, response => (response && response.error) && AlertAction.open('error', translate.i18n(response.error), 'top-right'));
    }

    /**
      * Method called to get categories
      *
      * @param options the options to add a category
      * @returns <Promise> the promise of the request done
      */
    addCategory(options) {
        return CategoryService.addCategory(options).then(category => {
            Dispatcher.dispatch({
                actionType: Constants.CATEGORY_ADD,
                value: new Category(category)
            });
        }, response => (response && response.error) && AlertAction.open('error', translate.i18n(response.error, { name: options.name }), 'top-right'));
    }

    /**
      * Method called to get categories
      *
      * @param options the options to delete a category
      * @returns <Promise> the promise of the request done
      */
    deleteCategory(options) {
        return CategoryService.deleteCategory(options).then(() => {
            Dispatcher.dispatch({
                actionType: Constants.CATEGORY_DELETE,
                value: options.category
            });
        }, response => (response && response.error) && AlertAction.open('error', translate.i18n(response.error), 'top-right'));
    }

    /**
      * Method called to rename categories
      *
      * @param options the options to rename a category
      * @returns <Promise> the promise of the request done
      */
    editCategory(options) {
        return CategoryService.editCategory(options).then(category => {
            Dispatcher.dispatch({
                actionType: Constants.CATEGORY_EDIT,
                value: new Category(category)
            });
            AlertAction.open('success', translate.i18n('SUCCESS_UPDATE_CATEGORY'), 'top-right');
        }, response => (response && response.error) && AlertAction.open('error', translate.i18n(response.error, { name: options.name }), 'top-right'));
    }
}

export default new CategoryAction();
