// Material UI Components
import makeStyles from '@mui/styles/makeStyles';
// Images
import BackgroundImg from 'assets/img/background-logo.jpg';

export const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: theme.palette.green.dark,
        margin: '4px'
    },
    displayHomeButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    displayText: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '30px',
        width: '100%'
    },
    navLink: {
        '&:active': {
            textDecoration: 'none'
        },
        '&:focus': {
            textDecoration: 'none'
        },
        '&:hover': {
            color: theme.palette.grey.dark,
            textDecoration: 'none'
        },
        '&:link': {
            textDecoration: 'none'
        },
        '&:visited': {
            textDecoration: 'none'
        },
        color: theme.palette.black.main,
        textDecoration: 'none'
    },
    paper: {
        padding: '25px'
    },
    quatreText: {
        color: theme.palette.green.dark
    },
    root: {
        alignItems: 'center',
        backgroundImage: 'url(' + BackgroundImg + ')',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        position: 'fixed',
        right: 0,
        top: 0,
        width: '100%'
    },
    sentence: {
        color: theme.palette.grey.dark,
        fontSize: '20px',
        fontWeight: 'normal',
        lineHeight: 'inherit',
        margin: 0,
        padding: '15px 23px 10px 0'
    },
    text404: {
        borderRight: '1px solid rgba(0, 0, 0, .3)',
        display: 'flex',
        fontSize: '60px',
        fontWeight: 500,
        margin: 0,
        marginRight: 20,
        padding: '10px 23px 10px 0'
    },
    textColumnCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%'
    },
    zeroText: {
        color: theme.palette.green.light
    }
}));
