import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    categoryListContainer: {
        height: 'calc(100% - 36px)',
        overflow: 'auto',
        width: 'calc(100% + 1px)'
    },
    dataBar: {
        '&:hover': {
            opacity: '0.9'
        },
        alignItems: 'center',
        borderRadius: '3px',
        cursor: 'pointer',
        display: 'flex',
        height: '25px',
        justifyContent: 'center',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '2px',
        width: 'calc(100% - 20px)',
    },
    dataChip: {
        '&:hover': {
            opacity: '0.9'
        },
        backgroundColor: theme.palette.grey.light,
        cursor: 'pointer',
        fontSize: '12px',
        height: '70%',
        opacity: '0.9'
    },
    paper: {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        width: '100%'
    },
    simpleBar: {
        height: '100%',
        width: '100%'
    },
    title: {
        alignItems: 'center',
        color: theme.palette.grey.ultradark,
        display: 'flex',
        fontSize: '12px',
        fontWeight: '500',
        height: '22px',
        justifyContent: 'space-between',
        marginBottom: '8px',
        marginTop: '6px',
        textAlign: 'center'
    }
}));
