export const createTable = sites => {
    if (!sites || sites.length === 0) return null;

    let dates = [];

    sites.slice(0).forEach(site => dates = dates.concat(site.performances.map(r => new Date(r.date.slice(0, 10)))));

    const setDates = new Set(dates.map(d => d.getTime()));
    let filteredDates = [];

    for (const d of setDates) {
        filteredDates.push(new Date(d));
    }

    filteredDates = filteredDates.sort((d1, d2) => d1.getTime() < d2.getTime() ? -1 : 1);

    const finalPerformances = filteredDates.map(d => ({ date: d }));

    sites.forEach((site) => {
        finalPerformances.forEach((finalPerformance) => {
            site.performances.forEach((sitePerformance) => {
                const date1 = finalPerformance.date;
                const date2 = new Date(sitePerformance.date.slice(0, 10));
                if (date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()) {
                    finalPerformance[site.name] = sitePerformance.count;
                }
                return true;
            });
            return true;
        });
        return true;
    });

    return finalPerformances;
};
