import makeStyles from '@mui/styles/makeStyles';
import { getRatingColor } from 'components/sheet/NotationSheet/NotationSheet.util';

export const useStyles = makeStyles(() => ({
    halfRatingStar: {
        backgroundImage: rating => `linear-gradient(to right, ${getRatingColor(rating)}, ${getRatingColor(rating)} 50%, #C7C7C7 50%)`,
        borderRadius: '2px',
        height: '20px',
        margin: '2px',
        width: '20px',
        paddingLeft: '1.5px',
        paddingTop: '1.5px'
    },
    noRatingStar: {
        backgroundColor: '#C7C7C7',
        borderRadius: '2px',
        height: '20px',
        margin: '2px',
        width: '20px'
    },
    ratingStar: {
        backgroundColor: rating => getRatingColor(rating),
        borderRadius: '2px',
        height: '20px',
        margin: '2px',
        width: '20px',
        paddingLeft: '1.5px',
        paddingTop: '1.5px'
    },
    starsContainer: {
        display: 'flex'
    }
}));
