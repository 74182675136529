import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    description: {
        color: theme.palette.grey.ultradark,
        fontSize: '14px',
        fontWeight: 450
    },
    infoListContainer: {
        minWidth: '400px',
        zIndex: 1000
    },
    title: {
        fontSize: '25px',
        fontWeight: 550,
        textAlign: 'center'
    }
}));
