// React
import React from 'react';
import PropTypes from 'prop-types';
//Pcp components
import Loader from 'components/commons/Loader/Loader';
import PricingEngineKpi from 'components/sheet/PricingEngineKpiSheet/PricingEngineKpi';
//Mui components
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EqualIcon from '@mui/icons-material/DragHandle';
//Styles
import { useStyles } from 'components/sheet/PricingEngineKpiSheet/PricingEngineKpiSheet.style';
// Theme
import { useTheme } from '@mui/styles';
// Locales
import translate from 'locales/i18n';
//Utils
import { round, displayNumber } from 'components/sheet/PricingEngineKpiSheet/PricingEngineKpiSheet.util';

const PricingEngineKpiSheet = ({ positioningKpis, sitesLength }) => {

    const classes = useStyles();
    const theme = useTheme();

    const renderEvolutionIcon = (value) => {
        const Icon = round(value) !== 0 ? value < 0 ? ArrowDownwardIcon : ArrowUpwardIcon : EqualIcon;
        return <Icon fontSize={'small'}
            style={{ transform: round(value) !== 0 ? value < 0 ? 'rotate(-45deg)' : 'rotate(45deg)' : undefined }}
            className={classes.kpiTrendArrow}
        />;
    };

    if (Object.keys(positioningKpis).length === 0) {
        return <div className={classes.loader}>
            <Loader size={50} />
        </div>;
    }

    const { avgPriceDifferences, avgMargin, avgPositions } = positioningKpis;

    return <div className={classes.kpisContainer}>
        <div className={classes.kpi}>
            <p className={classes.kpiText}> {translate.i18n('POSITIONING_KPIS.AVG_PRICE_DIF') + ' :'}</p>
            <PricingEngineKpi value={avgPriceDifferences.onDate} color={avgPriceDifferences.onDate > avgPriceDifferences.withRepricing ? theme.palette.primary.main : avgPriceDifferences.trend === 0 ? theme.palette.primary.light : theme.palette.primary.ultraLight} />
            <div className={classes.kpiTrendContainer}>
                {avgPriceDifferences.trend !== undefined && renderEvolutionIcon(avgPriceDifferences.trend)}
                <p className={classes.kpiTrendText}>{round(avgPriceDifferences.trend) !== 0 && displayNumber(avgPriceDifferences.trend)}</p>
            </div>
            <PricingEngineKpi value={avgPriceDifferences.withRepricing} color={avgPriceDifferences.onDate < avgPriceDifferences.withRepricing ? theme.palette.primary.main : avgPriceDifferences.trend === 0 ? theme.palette.primary.light : theme.palette.primary.ultraLight} />
        </div>
        <div className={classes.kpi}>
            <p className={classes.kpiText}> {translate.i18n('POSITIONING_KPIS.AVG_MARGIN') + ' :'}</p>
            <PricingEngineKpi value={avgMargin.onDate} isMargin={true} color={avgMargin.onDate < avgMargin.withRepricing ? theme.palette.primary.main : avgMargin.trend === 0 ? theme.palette.primary.light : theme.palette.primary.ultraLight} />
            <div className={classes.kpiTrendContainer}>
                {avgMargin.trend !== undefined && renderEvolutionIcon(avgMargin.trend)}
                <p className={classes.kpiTrendText}>{round(avgMargin.trend) !== 0 && displayNumber(avgMargin.trend)}</p>
            </div>
            <PricingEngineKpi value={avgMargin.withRepricing} isMargin={true} color={avgMargin.onDate > avgMargin.withRepricing ? theme.palette.primary.main : avgMargin.trend === 0 ? theme.palette.primary.light : theme.palette.primary.ultraLight} />
        </div>
        <div className={classes.kpi}>
            <p className={classes.kpiText}> {translate.i18n('POSITIONING_KPIS.AVG_POSITION') + ' :'}</p>
            <PricingEngineKpi value={avgPositions.onDate} sitesLength={sitesLength} color={avgPositions.onDate > avgPositions.withRepricing ? theme.palette.primary.main : avgPositions.trend === 0 ? theme.palette.primary.light : theme.palette.primary.ultraLight} />
            <div className={classes.kpiTrendContainer}>
                {avgPositions.trend !== undefined && renderEvolutionIcon(-avgPositions.trend)}
                <p className={classes.kpiTrendText}>{round(avgPositions.trend) !== 0 && displayNumber(avgPositions.trend)}</p>
            </div>
            <PricingEngineKpi value={avgPositions.withRepricing} sitesLength={sitesLength} color={avgPositions.onDate < avgPositions.withRepricing ? theme.palette.primary.main : avgPositions.trend === 0 ? theme.palette.primary.light : theme.palette.primary.ultraLight} />
        </div>
    </div>;
};

PricingEngineKpiSheet.propTypes = {
    positioningKpis: PropTypes.object,
    sitesLength: PropTypes.number
};

export default PricingEngineKpiSheet;
