import Item from 'core/entities/ItemObject';

class Category {

    constructor(data) {
        this.id = data?.id;
        this.name = data?.name;
        this.associatedItems = data && data.associated_articles ? data.associated_articles.map(article => new Item(article)) : data.associated_articles;
        this.faceToFaceLoosingRate = data?.face_to_face_loosing_rate;
        this.articlesCardinalities = data?.articles_cardinalities;
        this.currentFaceToFaceLoosingRate = data?.current_face_to_face_loosing_rate;
        this.withRepricingFaceToFaceLoosingRate = data?.with_repricing_face_to_face_loosing_rate;
    }

    /**
     * GETTERS
     */
    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    /**
     * SETTERS
     */
    setId(id) {
        this.id = id;
    }

    setName() {
        this.name = name;
    }

    /**
     * METHODS
     */
    toJson() {
        return {
            id: this.id,
            name: this.name
        };
    }
}

export default Category;
