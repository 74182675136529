import AbstractService from 'core/abstracts/AbstractService';
import Facade from 'core/Facade';

class AttributeService extends AbstractService {

    /**
     * Get all attribute names.
    */
    getAttributeNames(accountId) {
        return this.request({
            method: 'GET',
            url:  Facade.getApiRoot() + `accounts/${accountId}/attributes/custom`
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Get an attribute values.
    */
    getAttributeValues(options) {
        const { accountId, attributeName } = options;
        return this.request({
            method: 'GET',
            url:  Facade.getApiRoot() + `accounts/${accountId}/attributes/custom/${attributeName}/values`
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }
}

export default new AttributeService();
