//React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//Utils
import { calcSizeFromDate, getRankColor } from './ProductRulesSheet.util';
//Theme
import { useTheme } from '@mui/material';
//Styles
import { useStyles } from 'components/sheet/ProductRulesSheet/ProductRulesSheet.style';
//Pcp component
import RuleInfoPopper from 'components/popper/RuleInfoPopper/RuleInfoPopper';
//Action
import RuleAction from 'actions/RuleAction';

const ProductRuleBlock = ({ rule, index, widthUnit, prevDate, isDragging, draggableStyle, delayHandler, setDelayHandler, providedDraggable }) => {

    const theme = useTheme();
    const classes = useStyles();

    const [hover, setHover] = useState(false);
    const [popperAnchor, setPopperAnchor] = useState(null);

    const [fullRule, setFullRule] = useState(rule);
    const [makeRequest, setMakeRequest] = useState(false);
    const [requestDelay, setRequestDelay] = useState(null);

    useEffect(() => {
        makeRequest ? RuleAction.getRule(rule.id).then(response => setFullRule(response)) : null;
    }, [makeRequest]);

    /**
     * Function used to calculate the length of the rule at the scale of the page.
     * For this, we use widthUnit from the parent component wich is the length of 60 days
     *
     * The size of the starting and ending arrow when they touch the line is widthUnit/8
     * One day is widthUnit/60
     */

    const renderRuleBlock = () => {
        const { startingPosition, length } = calcSizeFromDate(rule, prevDate);

        const arrowLeft = {
            width: '30px',
            height: '40px',
            borderTop: '20px solid transparent',
            borderBottom: '20px solid transparent',
            borderRight: '28px solid' + getRankColor(index + 1, theme)
        };

        const arrowRight = {
            width: '30px',
            height: '40px',
            borderTop: '20px solid transparent',
            borderBottom: '20px solid transparent',
            borderLeft: '28px solid' + getRankColor(index + 1, theme)
        };

        const style = {
            marginLeft: '0px',
            width: widthUnit / 8 - 50 + 'px',
            height: '40px',
            backgroundColor: getRankColor(index + 1, theme),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '15px',
            paddingTop: '10px'
        };

        if (startingPosition < 0) {
            if (length < 0) {
                return (
                    <>
                        <div style={arrowLeft}></div>
                        <div style={style}> {rule.name} </div>
                    </>);
            } else if (length > 30) {
                style.width = 2 * (widthUnit / 8 - 30) + (30 * widthUnit) / 60 + 'px';
                return (
                    <>
                        <div style={arrowLeft}></div>
                        <div style={style}> {rule.name} </div>
                        <div style={arrowRight}></div>
                    </>);
            }
            style.width = widthUnit / 8 - 30 + (length * widthUnit) / 60 + 'px';

            return (
                <>
                    <div style={arrowLeft}></div>
                    <div style={style}> {rule.name} </div>
                </>);
        } else if (startingPosition > 30) {
            style.marginLeft = widthUnit / 8 + (30 * widthUnit) / 60 + 17 + 'px';
            return (
                <>
                    <div style={style}> {rule.name} </div>
                    <div style={arrowRight}></div>
                </>);
        }

        style.marginLeft = widthUnit / 8 + (startingPosition * widthUnit) / 60 + 'px';
        if (length + startingPosition > 30) {
            style.width = (2 * (widthUnit / 8 - 30) + (30 * widthUnit) / 60) - (widthUnit / 8 + (startingPosition * widthUnit) / 60) + 30 + 'px';
            return (
                <>
                    <div style={style}> {rule.name} </div>
                    <div style={arrowRight}></div>
                </>);
        }
        style.width = (length * widthUnit) / 60 + 'px';
        return (
            <>
                <div style={style}> {rule.name} </div>
            </>);

    };

    //Styles to apply when dragging
    const listItemStyle = () => ({
        ...draggableStyle,

        ...isDragging && {
            opacity: '0.5'
        },
    });

    const handleMouseEnter = () => {
        setDelayHandler(setTimeout(() => setHover(true), 750));
        setRequestDelay(setTimeout(() => setMakeRequest(true), 250));
    };

    const handleMouseLeave = () => {
        clearTimeout(delayHandler);
        clearTimeout(requestDelay);
        setMakeRequest(false);
        setHover(false);
    };

    return (
        <div ref={providedDraggable.innerRef} {...providedDraggable.draggableProps} {...providedDraggable.dragHandleProps} style={listItemStyle()} className={classes.rankedRuleContainer}>
            <div ref={el => setPopperAnchor(el)}
                style={{ display: 'flex' }}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}>
                {renderRuleBlock()}
            </div>
            <RuleInfoPopper rule={fullRule} hover={hover} popperAnchor={popperAnchor} mwidth={widthUnit / 2} />
        </div>
    );
};

ProductRuleBlock.propTypes = {
    rule: PropTypes.object,
    index: PropTypes.number,
    widthUnit: PropTypes.number,
    prevDate: PropTypes.func,
    isDragging: PropTypes.bool,
    draggableStyle: PropTypes.object,
    delayHandler: PropTypes.number,
    setDelayHandler: PropTypes.func,
    providedDraggable: PropTypes.object
};

export default ProductRuleBlock;
