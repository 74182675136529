import AbstractService from 'core/abstracts/AbstractService';
import Facade from 'core/Facade';

class SliderService extends AbstractService {
    getSliders(options) {
        const from = options.from.getFullYear() + '-' + (options.from.getMonth() + 1) + '-' + options.from.getDate();
        const to = options.to.getFullYear() + '-' + (options.to.getMonth() + 1) + '-' + options.to.getDate();

        // if no options were provided return reject
        if (!options.accountId) {
            return Promise.reject();
        }

        return this.request({
            method: 'GET',
            url: Facade.getApiRoot() + `slider/analyse?accountId=${options.accountId}&fromStr=${from}&toStr=${to}`

        }).then(responseData => {
            if (responseData.ok) {
                return responseData.json();
            }
            throw responseData.ok;
        });
    }
}

export default new SliderService();
