import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { hrStyle } from 'components/pages/ProductPage/ProductPage.style';
import { styles } from 'components/sheet/EditProductSheet/EditProductSheet.style.js';
import { StatusGraph } from 'pcp-graphical-lib';
import classNames from 'classnames';
import Facade from 'core/Facade';
// Material UI Components
import { Button, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
// PCP Components
import DialogAddUrl from 'components/dialog/DialogAddUrl/DialogAddUrl';
// Locales
import translate from 'locales/i18n';

const EditProductSheet = ({ classes, scrapingDatas, updateScrapingData, resetData, dialogDelete, dialogSave, currentItem }) => {
    const [dialogSuggestionOpen, openSuggestionDialog] = useState(false);
    const [currentScrapingData, setCurrentScrapingData] = useState(null);

    const dialogUrl = () => <DialogAddUrl
        item={currentItem}
        scrapingData={currentScrapingData}
        close={(url, status) => {
            if (url && typeof url === 'string') {
                updateScrapingData('url', currentScrapingData.siteId, url);
            }
            if (status && typeof status === 'string') {
                updateScrapingData('status', currentScrapingData.siteId, status);
            }
            openSuggestionDialog(false);
        }}
        open={dialogSuggestionOpen}
        website={currentScrapingData ? { id :currentScrapingData.siteId, name: currentScrapingData.siteName, url: currentScrapingData.siteUrl }:null}
    />;

    const formatUrl = (url) => url.slice(url.indexOf('www'), url.lenght).split('/');

    const isValid = (url, scrapingData, formattedSiteUrl) => {
        if (url === '') return true;
        if (url.includes(formattedSiteUrl[0])) return true;
        if (scrapingData.alternativeUrls.length === 0) return false;

        let res = false;
        scrapingData.alternativeUrls.forEach((alternativeUrl) => {
            if (url.includes(formatUrl(alternativeUrl)[0])) res = true;
        });
        return res;
    };

    return (
        <div className={classes.editContainer}>
            <div className={classes.editTitle}>
                <div className={classes.editTitleUpSection}>
                    <div className={classes.contentTitle}>{translate.i18n('EDIT_ARTICLE')}</div>
                    <Button
                        variant='contained'
                        color='secondary'
                        className={classNames(classes.deleteButton)}
                        startIcon={<DeleteIcon />}
                        onClick={() => dialogDelete()}
                    >
                        {translate.i18n('DELETE_ARTICLE')}
                    </Button>
                </div>
                <div className={classes.editTitleBottomSection}>
                    <hr style={hrStyle}></hr>
                </div>
            </div>
            <div className={classes.editElements}>
                <form>
                    {scrapingDatas && scrapingDatas.map((scrapingData, index) => {
                        const formattedSiteUrl = formatUrl(scrapingData.siteUrl);
                        return (
                            <div key={index} className={classes.editProductContainer}>
                                <div className={classes.editProductLeft}>
                                    <a href={scrapingData.siteUrl} target='_blank' rel='noreferrer'>
                                        <img height='25' src={`https://www.google.com/s2/favicons?sz=64&domain_url=${scrapingData.siteUrl}`}/>
                                    </a>
                                    <TextField
                                        variant='standard'
                                        size='small'
                                        error={!isValid(scrapingData.url, scrapingData, formattedSiteUrl)}
                                        id={`product_url${index}`}
                                        className={classes.siteUrl}
                                        label={Facade.siteDisplayName(scrapingData.siteName)}
                                        helperText={!isValid ? `L'url doit commencer par : ${scrapingData.siteUrl}` : null}
                                        value={scrapingData.url}
                                        onChange={event => {
                                            updateScrapingData('status', scrapingData.siteId, 'SOLD');
                                            updateScrapingData('url', scrapingData.siteId, event.target.value);
                                        }}
                                    />
                                    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' className={classes.searchUrlIcon} onClick={() => {
                                        const newData = scrapingData;
                                        newData.to = scrapingData;
                                        setCurrentScrapingData(newData);
                                        openSuggestionDialog(true);
                                    }}>
                                        <path d='M8.05556 0C12.5045 0 16.1111 3.6066 16.1111 8.05556C16.1111 9.97986 15.4364 11.7466 14.3106 13.132L19.7559 18.5774C20.0814 18.9028 20.0814 19.4305 19.7559 19.7559C19.4601 20.0518 18.9971 20.0787 18.6709 19.8366L18.5774 19.7559L13.132 14.3106C11.7466 15.4364 9.97986 16.1111 8.05556 16.1111C3.6066 16.1111 0 12.5045 0 8.05556C0 3.6066 3.6066 0 8.05556 0ZM8.05556 1.66667C4.52707 1.66667 1.66667 4.52707 1.66667 8.05556C1.66667 11.584 4.52707 14.4444 8.05556 14.4444C11.584 14.4444 14.4444 11.584 14.4444 8.05556C14.4444 4.52707 11.584 1.66667 8.05556 1.66667Z' fill='#0E5562' />
                                    </svg>
                                </div>
                                <div className={classes.editProductRight}>
                                    <StatusGraph
                                        status={ scrapingData.status }
                                        url={ scrapingData.url }
                                        handleStatusChange={value => updateScrapingData('status', scrapingData.siteId, value) }
                                    />
                                </div>
                            </div>
                        );
                    })}
                </form>
            </div>
            <div className={classes.editButtons}>
                <Button
                    variant='contained'
                    color='secondary'
                    className={classes.cancelButton}
                    startIcon={<ClearIcon />}
                    onClick={() => resetData()}
                >
                    {translate.i18n('CANCEL')}
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    className={classes.saveButton}
                    startIcon={<AddIcon />}
                    onClick={() => dialogSave()}
                >
                    {translate.i18n('SAVE')}
                </Button>
            </div>
            {dialogUrl()}
        </div>
    );
};

EditProductSheet.propTypes = {
    classes: PropTypes.object.isRequired,
    currentItem: PropTypes.object,
    dialogDelete: PropTypes.func,
    dialogSave: PropTypes.func,
    item: PropTypes.object,
    resetData: PropTypes.func,
    scrapingDatas: PropTypes.array,
    updateScrapingData: PropTypes.func
};

export default withStyles(styles)(EditProductSheet);
