// React
import React from 'react';
import ReactDOM from 'react-dom';
import history from 'history.js';
import { Router, Route, Switch } from 'react-router-dom';
// Actions
// eslint-disable-next-line
import SignAction from 'actions/SignAction';
import UserAction from 'actions/UserAction';
import AlertAction from 'actions/AlertAction';
import LayoutAction from 'actions/LayoutAction';
// Locales
import translate from 'locales/i18n';
// Services
import SignService from 'services/SignService';
// Stores
import LayoutStore from 'stores/LayoutStore';
// Workers
import * as serviceWorker from 'workers/serviceWorker';
// PCP Component
import Main from 'components/pages/MainLayout/MainLayout';
import LoadingPage from 'components/pages/LoadingPage/LoadingPage';

const renderApp = () => {
    // Mount the main component with the router
    ReactDOM.render(
        <Router history={history}>
            <Switch>
                <Route path={'/'} render={() => <Main />} />;
            </Switch>
        </Router>, document.getElementById('root'));
};

// Mount the loader waiting for the main component
ReactDOM.render(
    <LoadingPage />, document.getElementById('root'));

if (localStorage.getItem('pricecomparator_token')) {
    SignService.tokenExists().then(response => {
        if (response) {
            UserAction.self().then(() => {
                renderApp();
            }, resp => {
                if (resp && resp.error && resp.error === 'NO_ASSOCIATED_ACCOUNTS') {
                    renderApp();
                    LayoutAction.setError('NO_ACCOUNTS');
                } else {
                    AlertAction.open('error', translate.i18n('CANNOT_RETRIEVE_USER_INFO'), 'top-right');
                }
            });
        } else {
            LayoutStore.setLocationRedirect({
                pathname: window.location.pathname,
                search: window.location.search
            });
            localStorage.removeItem('pricecomparator_token');
            localStorage.removeItem('pricing_engine_tutorial');
            renderApp();
        }
    }, response => {
        LayoutStore.setLocationRedirect({
            pathname: window.location.pathname,
            search: window.location.search
        });
        localStorage.removeItem('pricecomparator_token');
        renderApp();
        throw response;
    });
} else {
    renderApp();
}

// Change unregister to register to launch the SW
serviceWorker.unregister();
