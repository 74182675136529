// Facade
import Facade from 'core/Facade';
// Locales
import translate from 'locales/i18n';

/**
  * Method called to create data needed for leadership filter
  *
  * @param websites <array<object>> the array of websites
  * @param theme <object> the theme to get colors
  * @returns <array<object>> data to create leadership filter
  */
export const createData = (websites, theme) => {
    if (websites.length === 0)
        return null;

    const firstSetCurrent = [];
    const secondSetCurrent = [];
    const thirdSetCurrent = [];
    const forthSetCurrent = [];
    const fifthSetCurrent = [];
    const sixthSetCurrent = [];

    const firstSetRepriced = [];
    const secondSetRepriced = [];
    const thirdSetRepriced = [];
    const forthSetRepriced = [];
    const fifthSetRepriced = [];
    const sixthSetRepriced = [];

    const labelSetCurrent = [];
    const labelSetRepriced = [];
    const labels = [];

    websites.forEach((website) => {
        firstSetCurrent.push(website.pricesLeaderships[5].cardinalities.current);
        secondSetCurrent.push(website.pricesLeaderships[4].cardinalities.current);
        thirdSetCurrent.push(website.pricesLeaderships[3].cardinalities.current);
        forthSetCurrent.push(website.pricesLeaderships[2].cardinalities.current);
        fifthSetCurrent.push(website.pricesLeaderships[1].cardinalities.current);
        sixthSetCurrent.push(website.pricesLeaderships[0].cardinalities.current);

        firstSetRepriced.push(website.pricesLeaderships[5].cardinalities.with_repricing);
        secondSetRepriced.push(website.pricesLeaderships[4].cardinalities.with_repricing);
        thirdSetRepriced.push(website.pricesLeaderships[3].cardinalities.with_repricing);
        forthSetRepriced.push(website.pricesLeaderships[2].cardinalities.with_repricing);
        fifthSetRepriced.push(website.pricesLeaderships[1].cardinalities.with_repricing);
        sixthSetRepriced.push(website.pricesLeaderships[0].cardinalities.with_repricing);

        labelSetCurrent.push(0);
        labelSetRepriced.push(0);

        labels.push(Facade.siteDisplayName(website.name));
    });

    const data = {
        datasets: [{
            label: '.',
            backgroundColor: theme.palette.graph.five,
            borderColor: theme.palette.graph.five,
            data: firstSetCurrent,
            hoverBackgroundColor: theme.palette.graph.five,
            hoverBorderColor: theme.palette.graph.five,
            stack: 'Stack 0',
        }, {
            label: '..',
            backgroundColor: theme.palette.graph.four,
            borderColor: theme.palette.graph.four,
            data: secondSetCurrent,
            hoverBackgroundColor: theme.palette.graph.four,
            hoverBorderColor: theme.palette.graph.four,
            stack: 'Stack 0',
        }, {
            label: '...',
            backgroundColor: theme.palette.graph.three,
            borderColor: theme.palette.graph.three,
            data: thirdSetCurrent,
            hoverBackgroundColor: theme.palette.graph.three,
            hoverBorderColor: theme.palette.graph.three,
            stack: 'Stack 0',
        }, {
            label: '....',
            backgroundColor: theme.palette.graph.two,
            borderColor: theme.palette.graph.two,
            data: forthSetCurrent,
            hoverBackgroundColor: theme.palette.graph.two,
            hoverBorderColor: theme.palette.graph.two,
            stack: 'Stack 0',
        }, {
            label: '.....',
            backgroundColor: theme.palette.graph.one,
            borderColor: theme.palette.graph.one,
            data: fifthSetCurrent,
            hoverBackgroundColor: theme.palette.graph.one,
            hoverBorderColor: theme.palette.graph.one,
            stack: 'Stack 0',
        }, {
            label: '......',
            backgroundColor: theme.palette.grey.main,
            borderColor: theme.palette.grey.main,
            data: sixthSetCurrent,
            hoverBackgroundColor: theme.palette.grey.main,
            hoverBorderColor: theme.palette.grey.main,
            stack: 'Stack 0',
        },{
            label: '.......',
            backgroundColor: 'white',
            borderColor: 'white',
            data: labelSetCurrent,
            hoverBackgroundColor: 'white',
            hoverBorderColor: 'white',
            stack: 'Stack 0',
        }, {
            label: '........',
            backgroundColor: theme.palette.graph.five,
            borderColor: theme.palette.graph.five,
            data: firstSetRepriced,
            hoverBackgroundColor: theme.palette.graph.five,
            hoverBorderColor: theme.palette.graph.five,
            stack: 'Stack 1',
        }, {
            label: '.........',
            backgroundColor: theme.palette.graph.four,
            borderColor: theme.palette.graph.four,
            data: secondSetRepriced,
            hoverBackgroundColor: theme.palette.graph.four,
            hoverBorderColor: theme.palette.graph.four,
            stack: 'Stack 1',
        }, {
            label: '..........',
            backgroundColor: theme.palette.graph.three,
            borderColor: theme.palette.graph.three,
            data: thirdSetRepriced,
            hoverBackgroundColor: theme.palette.graph.three,
            hoverBorderColor: theme.palette.graph.three,
            stack: 'Stack 1',
        }, {
            label: '...........',
            backgroundColor: theme.palette.graph.two,
            borderColor: theme.palette.graph.two,
            data: forthSetRepriced,
            hoverBackgroundColor: theme.palette.graph.two,
            hoverBorderColor: theme.palette.graph.two,
            stack: 'Stack 1',
        }, {
            label: '............',
            backgroundColor: theme.palette.graph.one,
            borderColor: theme.palette.graph.one,
            data: fifthSetRepriced,
            hoverBackgroundColor: theme.palette.graph.one,
            hoverBorderColor: theme.palette.graph.one,
            stack: 'Stack 1',
        }, {
            label: '.............',
            backgroundColor: theme.palette.grey.main,
            borderColor: theme.palette.grey.main,
            data: sixthSetRepriced,
            hoverBackgroundColor: theme.palette.grey.main,
            hoverBorderColor: theme.palette.grey.main,
            stack: 'Stack 1',
        }, {
            label: '..............',
            backgroundColor: 'white',
            borderColor: 'white',
            data: labelSetRepriced,
            hoverBackgroundColor: 'white',
            hoverBorderColor: 'white',
            stack: 'Stack 1',
        }],
        labels
    };
    return data;
};

/**
 * Method called to get all needed options of leadership filter
 */
export const getOptions = () => ({
    maintainAspectRatio: false,
    borderRadius: 2,
    plugins: {
        datalabels: {
            rotation: -90,
            color: 'grey',
            align: 'top',
            offset: 4,
            formatter: (value, context) => {
                if (context.dataIndex === 0 && context.datasetIndex === 6) return 'Actuel';
                if (context.dataIndex === 0 && context.datasetIndex === 13) return 'Repricing';
                return null;
            }
        },
        legend: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: tooltipItem => {
                    if (tooltipItem.datasetIndex === 0 || tooltipItem.datasetIndex === 7) return ` ${tooltipItem.formattedValue} ${translate.i18n('MOST_EXPENSIVE')}`;
                    else if (tooltipItem.datasetIndex === 1 || tooltipItem.datasetIndex === 8) return ` ${tooltipItem.formattedValue} ${translate.i18n('AMONG_MOST_EXPENSIVE')}`;
                    else if (tooltipItem.datasetIndex === 2 || tooltipItem.datasetIndex === 9) return ` ${tooltipItem.formattedValue} ${translate.i18n('NEITHER_MOST_EXPENSIVE_NOR_CHEAPER')}`;
                    else if (tooltipItem.datasetIndex === 3 || tooltipItem.datasetIndex === 10) return ` ${tooltipItem.formattedValue} ${translate.i18n('AMONG_CHEAPEST')}`;
                    else if (tooltipItem.datasetIndex === 4 || tooltipItem.datasetIndex === 11) return ` ${tooltipItem.formattedValue} ${translate.i18n('STRICTLY_CHEAPEST')}`;
                    else if (tooltipItem.datasetIndex === 5 || tooltipItem.datasetIndex === 12) return ` ${tooltipItem.formattedValue} ${translate.i18n('ALONE_SELLER')}`;

                    return tooltipItem;
                }
            }
        },
        title: {
            display: true,
            fontSize: 12,
            text: translate.i18n('LEADERSHIP_TITLE'),
            padding: {
                bottom: 60,
                top: 8
            }
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            stacked: true,
            ticks: {
                display: false
            }
        },
        y: {
            grid: {
                display: false,
                drawBorder: false
            },
            stacked: true,
            ticks: {
                display: false
            }
        }
    },
});
