import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    categoriesContainer: {
        '& > *': {
            margin: theme.spacing(0.5)
        },
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%',
        overflow: 'auto',
        maxHeight: 'calc(100% - 70px)',
    },
    closeButton: {
        position: 'absolute',
        right: '2px',
        top: '2px'
    },
    dialogContent: {
        height: '100%',
        overflow: 'hidden',
        padding: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    dialogPaper: {
        height: '80%',
        maxWidth: '80%',
        width: '80%'
    },
    filterContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(2),
    },
    loaderContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1
    },
    searchBar: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1
    }
}));
