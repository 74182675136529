import Color from 'color';
// Facade
import Facade from 'core/Facade';

export const createData = sites => sites.map((site) => {
    const newDataset = {
        backgroundColor: site.color,
        borderCapStyle: 'butt',
        borderColor: site.color,
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'round',
        data: [],
        fill: false,
        label: Facade.siteDisplayName(site.name),
        lineTension: 0,
        pointBackgroundColor: site.color,
        pointBorderColor: site.color,
        pointBorderWidth: 5,
        pointHitRadius: 10,
        pointHoverBackgroundColor: Color(site.color).alpha(0.5).string(),
        pointHoverBorderColor: site.color,
        pointHoverBorderWidth: 2,
        pointHoverRadius: 8,
        pointRadius: 1,
        spanGaps: false
    };

    newDataset.data = site.performances.map(performance => {
        const date = new Date(performance.date.slice(0, 10));

        const dataUnit = {
            x: date,
            y: performance.count
        };

        return dataUnit;
    });

    return newDataset;
});
