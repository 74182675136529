// React
import React from 'react';
import PropTypes from 'prop-types';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import Loader from 'components/commons/Loader/Loader';
// Style
import { useStyles } from 'components/dialog/DialogLoading/DialogLoading.style';

const DialogConfirm = ({ open }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
            classes={{
                paper: classes.dialog
            }}
        >
            <DialogContent className={classes.dialogContent}><Loader size={50} /></DialogContent>
        </Dialog>
    );
};

DialogConfirm.propTypes = {
    open: PropTypes.bool
};

export default DialogConfirm;
