// Material UI
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    graphCol: {
        display: 'flex',
        flex: '2.5%',
        fontSize: '0.8vw',
        height: '90%',
        margin: '2px 0.5% 0% 0.5%',
        paddingTop: '2%',
        width: '100%'
    },
    graphCol2: {
        display: 'flex',
        flex: '5.5%',
        fontSize: '0.7vw',
        height: '90%',
        margin: '2px 0.5% 0.5% 0.5%',
        paddingTop: '1%',
        width: '100%'
    },
    graphContainer: {
        alignItems: 'center',
        height: '250px',
        justifyContent: 'center',
        margin: 0,
        padding: '0 5px 5px 5px',
        width: '90%'
    },
    graphRow: {
        display: 'flex',
        height: '12%',
        width: '100%'
    },
    paper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '280px',
        width: '100%',
        position: 'relative',
    },
    titleGraph: {
        color: theme.palette.grey.semidark,
        margin: '15px 0 10px 0',
        padding: 0,
        fontSize: '13px'
    },
    iconReset: {
        position: 'absolute',
        right: '10px',
    }
}));
