// Images
import BackgroundImg from 'assets/img/background-logo.jpg';

const styles = theme => ({
    background: {
        backgroundImage: 'url(' + BackgroundImg + ')',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        height: '100%',
        position: 'fixed',
        right: 0,
        top: 0,
        width: '100%'
    },
    bottomButtonContainer: {
        marginLeft: 'auto',
        marginTop: 'auto'
    },
    button: {
        margin: theme.spacing(2)
    },
    codeContainer: {
        display: 'flex',
        marginLeft: '24px',
        marginRight: '24px',
        width: 'calc(100% - 48px)'
    },
    codeInput: {
        fontSize: 27,
        paddingBottom: '8px',
        paddingTop: '8px'
    },
    informationText: {
        marginLeft: '24px',
        marginRight: '24px'
    },
    link: {
        '&:active': {
            textDecoration: 'none'
        },
        '&:focus': {
            textDecoration: 'none'
        },
        '&:hover': {
            textDecoration: 'none'
        },
        '&:link': {
            textDecoration: 'none'
        },
        '&:visited': {
            textDecoration: 'none'
        },
        textDecoration: 'none'
    },
    pageContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        maxWidth: '300px'
    },
    paper: {
        backgroundColor: theme.palette.white.main,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '300px',
        minWidth: '460px',
        padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`
    },
    paperFinalStep: {
        backgroundColor: theme.palette.white.main,
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        minHeight: '120px',
        minWidth: '350px',
        padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`
    },
    paperLoading: {
        backgroundColor: theme.palette.white.main,
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`
    },
    progress: {
        margin: theme.spacing(2)
    },
    sendCodeAgainLink: {
        color: theme.palette.primary.dark,
        cursor: 'pointer',
        marginBottom: '16px',
        marginTop: 0,
        textDecoration: 'underline',
        width: 'auto'
    },
    sendCodeAgainLinkContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    stepper: {
        padding: '24px 0px'
    },
    textField: {
        marginBottom: '24px',
        marginLeft: '24px',
        marginRight: '24px',
        width: 'calc(100% - 48px)'
    },
    textFieldCode: {
        marginBottom: '16px',
        marginLeft: '8px',
        marginRight: '8px'
    }
});

export default styles;
