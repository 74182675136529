class PricesBasket {

    constructor(data) {
        this.id = data?.id;
        this.bounds = data?.bounds;
        this.pricesCardinalities = data?.prices_cardinalities;
        this.current = data?.current_cardinality;
        this.withRepricing = data?.with_repricing_cardinality;
    }

}

export default PricesBasket;
