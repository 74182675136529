class PricingEngineInfo {

    constructor(data) {
        this.repricedPrice = data?.repriced_price;
        this.rulesApplication = data?.rules_application;
    }

}

export default PricingEngineInfo;
