class Mail {

    constructor(data) {
        this.content = data?.content;
        this.date = data?.date;
        this.fromMail = data?.from_mail;
        this.fromName = data?.from_name;
        this.html = data?.html;
        this.subject = data?.subject;
        this.siteId = data?.site_id;
    }

}

export default Mail;
