import React, { useState } from 'react';
import PropTypes from 'prop-types';
//Styles
import { useStyles } from 'components/dialog/DialogAddProduct/DialogAddProduct.style';
import classNames from 'classnames';
//Locales
import translate from 'locales/i18n';
//Mui components
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/SettingsApplications';
import PreviewIcon from '@mui/icons-material/Preview';
//Pcp components
import DialogManageCategories from 'components/dialog/DialogManageCategories/DialogManageCategories';

const DialogAddProductFixedAttributes = ({
    attribute,
    categories,
    handleChangeFixedAttributes,
    setImgUrl
}) => {

    const classes = useStyles();

    const [openDialogCategories, setOpenDialogCategories] = useState(false);

    const renderDialogManageCategories = () =>
        <DialogManageCategories
            close={() => setOpenDialogCategories(false)}
            open={openDialogCategories}
            categories={categories}
        />;

    const renderCategories = () => categories.map((categorie, key) => <MenuItem key={key} value={categorie.id}>{categorie.name}</MenuItem>);

    if (!attribute) return null;
    const name = translate.i18n(attribute.name.toUpperCase()) ? translate.i18n(attribute.name.toUpperCase()) : attribute.name.toUpperCase();

    if (attribute.name === 'category_id')
        return (
            <div className={classNames(classes.categoryButtonContainer, classes.fixedAttribute)}>
                <FormControl variant='outlined'
                    size='small'
                    className={classes.categorySelect}>
                    <InputLabel
                        htmlFor='outlined-age-native-simple'
                        required={attribute.required}
                    >
                        {name}
                    </InputLabel>
                    <Select
                        value={attribute.value}
                        onChange={handleChangeFixedAttributes(attribute.name)}
                        label={name}
                    >
                        {renderCategories()}
                    </Select>
                </FormControl>
                <IconButton
                    color='primary'
                    className={classes.buttonCategorySettings}
                    onClick={() => setOpenDialogCategories(true)}
                    size='large'>
                    <SettingsIcon className={classes.iconCategorySettings} />
                </IconButton>
                {renderDialogManageCategories()}
            </div>
        );

    if (attribute.name === 'img_url')
        return <div className={classNames(classes.categoryButtonContainer, classes.fixedAttribute)}>
            <TextField
                size='small'
                id={attribute.name}
                className={classes.categorySelect}
                variant='outlined'
                label={name}
                value={attribute.value}
                onChange={handleChangeFixedAttributes(attribute.name)}
            />
            <Tooltip title={translate.i18n('SEE_IMAGE')}>
                <IconButton
                    color='primary'
                    className={classes.buttonCategorySettings}
                    onClick={() => setImgUrl(attribute.value)}
                    size='large'>
                    <PreviewIcon className={classes.iconCategorySettings} />
                </IconButton>
            </Tooltip>
        </div>;

    return (
        <TextField
            size='small'
            required={attribute.required}
            id={attribute.name}
            className={classes.fixedAttribute}
            variant='outlined'
            label={name}
            value={attribute.value}
            onChange={handleChangeFixedAttributes(attribute.name)}
        />
    );
};

DialogAddProductFixedAttributes.propTypes = {
    attribute: PropTypes.object,
    categories: PropTypes.array,
    handleChangeFixedAttributes: PropTypes.func,
    setImgUrl: PropTypes.func
};

export default DialogAddProductFixedAttributes;
