// React
import React from 'react';
import PropTypes from 'prop-types';
// Material UI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import DialogContent from '@mui/material/DialogContent';
// Styles
import { useStyles } from 'components/dialog/DialogInfo/DialogInfo.style';

const DialogInfo = ({ close, open, title, children, image }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={close}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
            classes={{
                paper: classes.paper
            }}
        >
            <IconButton
                color='secondary'
                onClick={close}
                className={classes.closeButton}
                size='large'>
                <ClearIcon />
            </IconButton>
            <DialogTitle id='responsive-dialog-title' className={classes.title}> {image ? <img className={classes.image} src={image} /> : null} {title}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {children}
            </DialogContent>
        </Dialog>
    );
};

DialogInfo.propTypes = {
    close: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node,
    image: PropTypes.string
};

export default DialogInfo;
