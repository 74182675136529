import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    button: {
        margin: '5px',
        marginTop: '5px',
        width: '20%'
    },
    closeButton: {
        position: 'absolute',
        right: '2px',
        top: '2px'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0px 24px 8px 24px'
    },
    iframeMail: {
        border: 0,
        height: '100%',
        margin: '0px',
        padding: '0px',
        transform: 'scale(1)',
        transformOrigin: '0 0',
        width: '100%'
    },
    iframeMailPrint: {
        height: '2500px',
        transform: 'scale(1)',
        width: '100%'
    },
    paper: {
        height: '600px',
    },
    titleDialogBox: {
        color: theme.palette.grey.dark,
        marginBottom: 0,
        marginLeft: '2%',
        marginTop: 0
    },
    subTitleDialogBox: {
        color: theme.palette.grey.dark,
        fontSize: '13px',
        marginLeft: '3%'
    }
}));
