// React
import React, { useState, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
// Caroussel
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// Stores
import AccountStore from 'stores/AccountStore';
import AdBoxStore from 'stores/AdBoxStore';
// Material UI Components
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Collapse from '@mui/material/Collapse';
// Action
import AdBoxAction from 'actions/AdBoxAction';
// Locales
import translate from 'locales/i18n';
// PCP Components
import MailDayFilter from 'components/filter/mail/MailDayFilter/MailDayFilter';
import MailHourFilter from 'components/filter/mail/MailHourFilter/MailHourFilter';
import DialogMail from 'components/dialog/DialogMail/DialogMail';
import MailDayHourFilter from 'components/filter/mail/MailDayHourFilter/MailDayHourFilter';
import Loader from 'components/commons/Loader/Loader';
import MailPieChart from 'components/filter/mail/MailPieChart/MailPieChart';
import EmailCard from 'components/card/EmailCard/EmailCard';
import DatePickers from 'components/commons/DatePickers/DatePickers';
// Styles
import { useStyles } from 'components/pages/AdboxPage/AdboxPage.style';
import { useTheme } from '@mui/styles';
//Tag cloud
import MailTagCloud from 'components/filter/mail/MailTagCloud/MailTagCloud';

const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 1600 },
        items: 4
    },
    smallDesktop: {
        breakpoint: { max: 1600, min: 1200 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1200, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const AdboxPage = () => {

    const classes = useStyles();

    const sendersColors = ['#020A0C', '#051E23', '#093239', '#0F5A67', '#126D7D', '#3D8794', '#68A2AC', '#7DAFB8', '#93BCC3', '#A8C9CF', '#BED7DB'];
    const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    const hours = ['H00', 'H01', 'H02', 'H03', 'H04', 'H05', 'H06', 'H07', 'H08', 'H09', 'H10', 'H11', 'H12', 'H13', 'H14', 'H15', 'H16', 'H17', 'H18', 'H19', 'H20', 'H21', 'H22', 'H23'];

    const [hideFilters, setHideFilters] = useState(false);
    const [dialogMailOpen, setDialogMailOpen] = useState(false);

    const [mails, setMails] = useState(AdBoxStore.getMails());
    const [statsByDay, setStatsByDay] = useState(AdBoxStore.getStats().statsByDay);
    const [statsByHour, setStatsByHour] = useState(AdBoxStore.getStats().statsByHour);
    const [statsByDayAndHour, setStatsByDayAndHour] = useState(AdBoxStore.getStats().statsByDayAndHour);
    const [statsBySites, setStatsBySites] = useState(AdBoxStore.getStats().statsBySites);
    const [tagCloudData, setTagCloudData] = useState(AdBoxStore.getStats().tagCloud);
    const [isLoading, setIsLoading] = useState(AdBoxStore.getIsLoadingMail());

    const [selectedMail, setSelectedMail] = useState(null);

    const d = new Date();
    d.setDate(d.getDate() - 7);
    const [fromStr, setFromStr] = useState(d);
    const [toStr, setToStr] = useState(new Date());
    const [sender, setSender] = useState(false);
    const [delayHandler, setDelayHandler] = useState(false);

    const [filterSites, setFilterSites] = useState([]);
    const [filterDays, setFilterDays] = useState([]);
    const [filterHours, setFilterHours] = useState([]);

    const toggleFiltersLabel = hideFilters ? translate.i18n('SHOW_FILTERS') : translate.i18n('HIDE_FILTERS');

    const theme = useTheme();

    useEffect(() => {
        requestAdbox();
    }, []);

    useEffect(() => {
        AdBoxStore.addChangeListener(onAdboxChange);
        return () => AdBoxStore.removeChangeListener(onAdboxChange);
    }, []);

    useEffect(() => {
        clearTimeout(delayHandler);
        delayRequest();
    }, [filterSites, filterDays, filterHours, sender]);

    const delayRequest = () => {
        setDelayHandler(setTimeout(() => {
            requestAdbox();
        }, 1400));
    };

    const requestAdbox = () => {
        if (!fromStr || !toStr) return;
        AdBoxAction.search({
            fromStr,
            toStr,
            accountId: AccountStore.getCurrentAccount().getId(),
            sites: filterSites,
            days: filterDays,
            hours: filterHours,
        });
    };

    const onAdboxChange = () => {
        setMails(AdBoxStore.getMails());
        setStatsByDay(AdBoxStore.getStats().statsByDay);
        setStatsByHour(AdBoxStore.getStats().statsByHour);
        setStatsByDayAndHour(AdBoxStore.getStats().statsByDayAndHour);
        setStatsBySites(AdBoxStore.getStats().statsBySites);
        setIsLoading(AdBoxStore.getIsLoadingMail());
        setTagCloudData(AdBoxStore.getStats().tagCloud);
    };

    const getSiteColor = (siteId) => {
        if (!siteId || !statsBySites) return sendersColors[10];
        const { ratio } = statsBySites.find(site => site.id === siteId);
        if (ratio < 20) return theme.palette.adbox.one;
        if (ratio < 30) return theme.palette.adbox.two;
        if (ratio < 40) return theme.palette.adbox.three;
        if (ratio < 50) return theme.palette.adbox. four;
        return theme.palette.adbox.five;
    };

    const resetFilters = (filter) => {
        if (filter === 'DAY' || filter === 'ALL' || filter === 'DAY_HOUR')
            setFilterDays([]);
        if (filter === 'HOUR' || filter === 'ALL' || filter === 'DAY_HOUR')
            setFilterHours([]);
        if (filter === 'SITE' || filter === 'ALL')
            setFilterSites([]);
        if (filter === 'ALL') {
            setFromStr(d);
            setToStr(new Date());
        }
    };

    const filter = (statToFilter, value) => {
        if (statToFilter === 'DAY') {
            if (filterDays.includes(days[value])) setFilterDays(filterDays.filter(day => day !== days[value]));
            else setFilterDays([...filterDays, days[value]]);
        } else if (statToFilter === 'HOUR') {
            if (filterHours.includes(hours[value])) setFilterHours(filterHours.filter(hour => hour !== hours[value]));
            else setFilterHours([...filterHours, hours[value]]);
        } else if (statToFilter === 'SITE') {
            if (filterSites.includes(value)) setFilterSites(filterSites.filter(site => site !== value));
            else setFilterSites([...filterSites, value]);
        }
    };

    const renderMails = () => {

        if (mails.length === 0 && !isLoading)
            return <p className={classes.titleNoMail}>{translate.i18n('NO_MAIL_FOR_THIS_DATES')}</p>;

        return mails.map((mail, key) => <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
            <EmailCard
                backgroundColor={getSiteColor(mail.siteId)}
                index={key}
                mail={mail}
                openDialogMail={(index) => {
                    setSelectedMail(mails[index]);
                    setDialogMailOpen(true);
                }}
            />
        </Grid>
        );
    };

    return (
        <SimpleBar className={classes.root}>
            <div className={classes.adBoxHeader}>
                <div>
                    <DatePickers
                        fromStr={fromStr}
                        toStr={toStr}
                        isLoading={isLoading}
                        setFromStr={setFromStr}
                        setToStr={setToStr}
                        callBack={() => setSender(!sender)}
                        titleText={['ADBOX.TITLE1', 'ADBOX.TITLE2']}
                        reset={() => resetFilters('ALL')}
                    />
                </div>
                <IconButton
                    aria-label='Toggle password visibility'
                    onClick={() => setHideFilters(!hideFilters)}
                    color='primary'
                    title={toggleFiltersLabel}
                    size='large'>
                    {hideFilters ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
            </div>
            {!isLoading ?
                <>
                    <Collapse in={!hideFilters}>
                        <div className={classes.filterContainer}>
                            <Carousel
                                swipeable={true}
                                draggable={false}
                                responsive={responsive}
                                keyBoardControl={true}
                                transitionDuration={300}
                                removeArrowOnDeviceType={['mobile']}
                                itemClass={classes.itemClass}
                                sliderClass={classes.sliderClass}
                                containerClass={classes.containerClass}
                                autoPlay={false}
                                shouldResetAutoplay={false}
                            >
                                <MailDayFilter
                                    statsByDay={statsByDay}
                                    resetDays={() => resetFilters('DAY')}
                                    selectDay={filter} />
                                <MailHourFilter
                                    statsByHour={statsByHour}
                                    resetHours={() => resetFilters('HOUR')}
                                    selectHour={filter} />
                                <MailDayHourFilter
                                    statsByDayAndHour={statsByDayAndHour}
                                    totalMails={mails.length}
                                    resetDaysHours={() => resetFilters('DAY_HOUR')}
                                    selectDayHour={filter} />
                                <MailPieChart
                                    statsBySites={statsBySites}
                                    selectSite={filter}
                                    resetSites={() => resetFilters('SITE')}
                                    totalMails={mails.length}
                                    sendersColors={sendersColors} />
                                <MailTagCloud data={tagCloudData} />
                            </Carousel>
                        </div>
                    </Collapse>
                    <Grid container spacing={4}>
                        {renderMails()}
                    </Grid>
                </>
                :
                <div className={classes.loaderContainer}>
                    <Loader size={70} />
                </div>
            }
            <DialogMail open={dialogMailOpen}
                close={() => setDialogMailOpen(false)}
                mail={selectedMail} />
        </SimpleBar>
    );
};

export default AdboxPage;
