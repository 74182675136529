// Constants
import Constants from 'constants.js';
// Dispatcher
import Dispatcher from 'core/Dispatcher';

class AlertAction {

    /**
      * Method called to open alert
      *
      * @param type <string> the type of alert (info || warning || success || error)
      * @param text <string> the text of alert
      * @param pos <string> the position of alert (ex: top-end)
      */
    open(type, text, pos) {
        let position = pos;
        if (!position || typeof position === 'undefined') {
            position = 'top-end';
        }
        if (!text || typeof text === 'undefined' || !type || typeof type === 'undefined') {
            return false;
        }

        Dispatcher.dispatch({
            actionType: Constants.ALERT_OPEN,
            value: {
                position,
                text,
                type
            }
        });
    }

    /**
      * Method called to close alert
      */
    close() {
        Dispatcher.dispatch({
            actionType: Constants.ALERT_CLOSE
        });
    }
}

export default new AlertAction();
