// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
// Locales
import translate from 'locales/i18n';
// Style
import { styles } from 'components/sidebar/Sidebar.style';
// Material Components
import withStyles from '@mui/styles/withStyles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
//Custom component
import { CustomSwitch } from 'components/commons/CustomSwitch/CustomSwitch';
// Store
import LayoutStore from 'stores/LayoutStore';
import UserStore from 'stores/UserStore';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
//SVG
import blurCheckedIcon from 'assets/img/svg/blur_checked.svg';
import blurUnCheckedIcon from 'assets/img/svg/blur_unchecked.svg';
import editIcon from 'assets/img/svg/editIcon.svg';
//Theme
import { useTheme } from '@mui/styles';

const Sidebar = ({ classes, handleDrawerClose, routes, sidebarOpen }) => {

    const renderIcon = (route, color) => {
        const { iconName, iconPath, iconComponent } = route;
        if (iconName)
            return <Icon style={{ color }}>{iconName}</Icon>;

        else if (iconPath)
            return <svg width='17px' viewBox='0 0 24 34' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ marginLeft: '3px' }}>
                <path d={iconPath} fill={color} />
            </svg>;
        else if (iconComponent)
            return iconComponent('24','24',color);
        return null;
    };

    const [blur, setBlur] = useState(LayoutStore.getBlur());
    const theme = useTheme();
    const [showEditIcon, setshowEditIcon] = useState(LayoutStore.getshowEditIcon());

    return (
        <Drawer
            variant='permanent'
            classes={{
                paper: classNames(classes.drawerPaper, !sidebarOpen && classes.drawerPaperClose),
            }}
            open={sidebarOpen}>
            <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose} size='large'>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <List className={classes.list}>
                {routes.map((route, key) => {
                    if (!route.color) return null;
                    let color = route.display ? route.color : theme.palette.grey.dark;
                    color = window.location.pathname.includes(route.path) ? theme.palette.white.main : color;

                    return (
                        <NavLink
                            to={ route.path }
                            key={key}
                            className={classes.navLink}>
                            <Tooltip title={<div className={classes.tooltipLabel}>{`${route.sidebarName} ${route.display? '' : translate.i18n('DO_NOT_HAVE_MODULE')}`}</div>} placement='right' TransitionComponent={Zoom} arrow>
                                <ListItem button className={classes.listItem} style={window.location.pathname.includes(route.path) ? { backgroundColor: route.color } : null}>
                                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                        {renderIcon(route, color)}
                                    </ListItemIcon>
                                    {sidebarOpen && <p className={classes.routeName} style={{ color: window.location.pathname.includes(route.path) && theme.palette.white.main }}>{route.sidebarName}</p>}
                                </ListItem>
                            </Tooltip>
                        </NavLink>
                    );
                })}
            </List>
            {UserStore.getCurrentUser().isSuperUser() &&
                <div style={{ position: 'absolute', bottom: '10px', left: '7px', display: 'flex', flexDirection: 'column' }}>
                    <CustomSwitch
                        handleChange={ () => {
                            Dispatcher.dispatch({ actionType: Constants.SET_BLUR });
                            setBlur(LayoutStore.getBlur());
                        } }
                        checked={ blur }
                        checkedIconStyle={{
                            top: '0px',
                            left: '0px',
                        }}
                        unCheckedIconStyle={{
                            top: '0px',
                            left: '0px',
                        }}
                        checkedIcon={ blurCheckedIcon }
                        unCheckedIcon={ blurUnCheckedIcon }
                        trackColor={'#1890a552'}
                        roundColor={theme.palette.primary.main} />
                    <CustomSwitch
                        handleChange={() => {
                            Dispatcher.dispatch({ actionType: Constants.SHOW_EDIT_ICON });
                            setshowEditIcon(LayoutStore.getshowEditIcon());
                        } }
                        checked={ showEditIcon }
                        checkedIconStyle={{
                            top: '0px',
                            left: '0px',
                        }}
                        unCheckedIconStyle={{
                            top: '0px',
                            left: '0px',
                        }}
                        checkedIcon={ editIcon }
                        unCheckedIcon={ editIcon }
                        trackColor={'#1890a552'}
                        roundColor={theme.palette.primary.main} />
                </div>
            }
        </Drawer>
    );
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    handleDrawerClose: PropTypes.func,
    routes: PropTypes.array,
    sidebarOpen: PropTypes.bool
};

export default withStyles(styles)(Sidebar);
