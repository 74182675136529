import React, { useEffect, useState } from 'react';
import Facade from 'core/Facade';
// Stores
import UserStore from 'stores/UserStore';
import AccountStore from 'stores/AccountStore';
import LayoutStore from 'stores/LayoutStore';
// Material UI Components
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import IconButton from '@mui/material/IconButton';
import IconRefresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
// Locales
import translate from 'locales/i18n';
// Stores
import SliderStore from 'stores/SliderStore';
// PCP components
import SliderAction from 'actions/SliderAction';
import SliderTimelineFilter from 'components/filter/slider/SliderTimelineFilter/SliderTimelineFilter';
import SliderSheet from 'components/sheet/SliderSheet/SliderSheet';
import Loader from 'components/commons/Loader/Loader';
import Slide from 'components/pages/SliderPage/Slide';
// Util
import { getStats, similarDate } from 'components/pages/SliderPage/SliderPage.util';
// Styles
import { useStyles } from 'components/pages/SliderPage/SliderPage.style';
import { Card, CardHeader, CardContent, Chip, Collapse, DialogTitle, DialogContent } from '@mui/material';
import { useTheme } from '@emotion/react';
import SliderStats from 'components/sheet/SliderSheet/SliderStats';
import { CustomSwitch } from 'components/commons/CustomSwitch/CustomSwitch';

const SliderPage = () => {

    const [currentDay, setCurrentDay] = useState(new Date());
    const [evolution, setEvolution] = useState(SliderStore.getEvolution());
    const [generalPageView, setGeneralPageView] = useState(true);
    const [hideFilters, setHideFilters] = useState(false);
    const [params, setParams] = useState(SliderStore.getParams());

    const [showDialogDetails, setShowDialogDetails] = useState(false);
    const [currentSite, setCurrentSite] = useState({});

    const [sliderStats, setSliderStats] = useState({});

    const [pageChanges, setPageChanges] = useState(false);

    const classes = useStyles({ generalPageView });

    const theme = useTheme();

    const [blur, setBlur] = useState(UserStore.getCurrentUser().isSuperUser() ? LayoutStore.getBlur() : false);

    function onLayoutStoreChange() {
        setBlur(LayoutStore.getBlur());
    }

    useEffect(() => {
        UserStore.getCurrentUser().isSuperUser() ? LayoutStore.addChangeListener(onLayoutStoreChange) : null;
        return () => UserStore.getCurrentUser().isSuperUser() ? LayoutStore.removeChangeListener(onLayoutStoreChange) : null;
    }, []);

    useEffect(() => {
        SliderStore.addChangeListener(onSliderStoreStoreChange);
        return () => SliderStore.removeChangeListener(onSliderStoreStoreChange);
    }, []);

    useEffect(() => {
        if ((SliderStore.getSliderSites().length === 0 || SliderStore.getEvolution().length === 0) && UserStore.isLogged()) {
            SliderAction.search(getCurrentSearchOptions());
        } else {
            setSliderStats(getStats(SliderStore.getSliderSites(), SliderStore.getEvolution()));
        }
    }, [currentDay]);

    useEffect(() => {
        addToUrl();
    }, [params, currentDay]);

    useEffect(() => {
        currentSite.siteSlides ? setShowDialogDetails(true) : null;
    }, [currentSite]);

    const onSliderStoreStoreChange = () => {

        if (SliderStore.getEvolution().length > 0)
            if (currentDay < new Date(SliderStore.getEvolution()[0].date) || currentDay > new Date(SliderStore.getEvolution()[SliderStore.getEvolution().length - 1].date))
                setCurrentDay(new Date(SliderStore.getEvolution()[SliderStore.getEvolution().length - 1].date));

        setSliderStats(getStats(SliderStore.getSliderSites(), SliderStore.getEvolution()));
        setEvolution(SliderStore.getEvolution());
        setParams(SliderStore.getParams());
    };

    // FONCTIONS

    const searchSlider = () => {
        SliderAction.search(getCurrentSearchOptions());
    };

    const addToUrl = (key, val) => {
        if (key && val)
            Facade.setUrlParam(key, val);
        Facade.setUrlParam('from', params.from);
        Facade.setUrlParam('to', params.to);
        Facade.setUrlParam('current', currentDay);
    };

    const getCurrentSearchOptions = () => {
        const accountId = AccountStore.getCurrentAccount().getId();

        return {
            accountId,
            currentDay,
            from: params.from,
            to: params.to
        };
    };

    const toggleHideFilters = () => {
        setPageChanges(true);
        setHideFilters(!hideFilters);
        setTimeout(() => setPageChanges(false), 1000);
    };

    const handleDates = ([from, to]) => {
        if (from && to && from.getTime() < to.getTime()) {
            const newParams = params;
            newParams.from = from;
            newParams.to = to;

            setParams(newParams);
        }
    };

    const selectDayDisplay = (day) => {
        setCurrentDay(new Date(day));
    };

    const resetFilters = () => {
        setPageChanges(true);
        Facade.deleteAllUrlParams();
        SliderAction.reset();

        setHideFilters(false);
        setTimeout(() => setPageChanges(false), 1000);
    };

    const changePageView = () => {
        setPageChanges(true);
        setGeneralPageView(!generalPageView);
        setTimeout(() => setPageChanges(false), 1000);
    };

    // RENDERS

    const renderDatePickers = () => <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
            inputFormat={'dd/MM/yyyy'}
            startText={translate.i18n('FROM')}
            endText={translate.i18n('TO')}
            value={[params.from, params.to]}
            onChange={(value) => handleDates(value)}
            maxDate={new Date()}
            renderInput={(startProps, endProps) =>
                <React.Fragment>
                    <TextField {...startProps} size='small' classes={{ root: classes.datePicker }} />
                    <TextField {...endProps} size='small' classes={{ root: classes.datePicker }} />
                </React.Fragment>
            }
        />
    </LocalizationProvider>;

    const renderSlidesSites = () => {
        const currentValues = evolution.find(e => similarDate(e.date, currentDay));
        if (!currentValues) {
            return (
                <div className={classes.progressContainer}>
                    <Loader size={50} />
                </div>);
        }

        let sliderSites = currentValues.summary.sort((c1, c2) => c1.slider_images.length > c2.slider_images.length ? 1 : -1);
        sliderSites = sliderSites.sort((c1, c2) => c1.slider_images.filter(s => s.status === 'new' || s.status === 'removed').length < c2.slider_images.filter(s => s.status === 'new' || s.status === 'removed').length ? 1 : -1);
        return (
            <div className={classes.slidesBoxContainer}>
                {sliderSites.map((siteSlides, index) => {
                    const site = sliderStats?.sites.find(e => e.id === siteSlides.slider_site_id);
                    const newSlides = siteSlides.slider_images.filter(s => s.status === 'new');
                    const existedSlides = siteSlides.slider_images.filter(s => s.status === 'existed');
                    const deletedSlides = siteSlides.slider_images.filter(s => s.status === 'removed');

                    return (
                        <Card key={index} className={classes.sliderCard} elevation={5}>
                            <CardHeader
                                onClick={() => Facade.openUrl(site.url)}
                                avatar={
                                    <img
                                        src={`https://img.pricecomparator.pro/sites_images/${site.name}/1`}
                                        onError={(e) => {
                                            e.target.onerror = null; e.target.src = `https://www.google.com/s2/favicons?domain=${site.url}`;
                                        }}
                                        alt={Facade.siteDisplayName(site.name)}
                                        title={Facade.siteDisplayName(site.name)}
                                        className={classes.sliderCardImage} />
                                }
                                title={Facade.siteDisplayName(site.name)}
                                subheader={siteSlides.slider_images.length + ' slide' + (siteSlides.slider_images.length > 1 ? 's' : '')}
                                classes={{
                                    title: classes.sliderCardTitle,
                                    subheader: classes.sliderCardSubheader,
                                    action: classes.sliderCardAction
                                }}
                                className={classes.sliderCardHeader}
                                style={ blur? { filter: 'blur(0.2rem)' } : null }
                            />
                            <CardContent
                                className={classes.sliderCardContent}
                                onClick={() => {
                                    setCurrentSite({
                                        site,
                                        newSlides,
                                        existedSlides,
                                        deletedSlides,
                                        siteSlides
                                    });
                                }}>
                                {slider(site, newSlides, existedSlides, deletedSlides, siteSlides)}
                            </CardContent>
                        </Card>
                    );
                })}
            </div>
        );
    };

    const slider = (site, newSlides, existedSlides, deletedSlides, siteSlides) => <>
        {!siteSlides || siteSlides.slider_images.length === 0 ?
            <p className={classes.noSlidesMessages}>{translate.i18n('NO_SLIDES_MESSAGE')}</p>
            :
            <div className={classes.sliderCardSimpleBar}>
                {newSlides.length > 0 &&
                    <>
                        <Chip label={translate.i18n('NEW_SLIDES_MESSAGE')} size='small' className={classes.slideSiteBoxLabel} style={{ backgroundColor: '#33cd58' }} />
                        {newSlides.map((slide, i) =>
                            <div key={i} className={classes.sitesSlidesBoxContentSlide}>
                                <Slide slide={slide} site={site} />
                            </div>
                        )}
                    </>
                }
                {deletedSlides.length > 0 &&
                    <>
                        <Chip label={translate.i18n('REMOVED_SLIDES_MESSAGE')} size='small' className={classes.slideSiteBoxLabel} style={{ backgroundColor: '#ff241d' }} />
                        {deletedSlides.map((slide, i) =>
                            <div key={i} className={classes.sitesSlidesBoxContentSlide}>
                                <Slide slide={slide} site={site} />
                            </div>
                        )}
                    </>
                }
                {existedSlides.length > 0 &&
                    <>
                        <Chip label={translate.i18n('ACTUAL_SLIDES_MESSAGE')} size='small' className={classes.slideSiteBoxLabel} style={{ backgroundColor: theme.palette.primary.main }} />
                        {existedSlides.map((slide, i) =>
                            <div key={i} className={classes.sitesSlidesBoxContentSlide}>
                                <Slide slide={slide} site={site} />
                            </div>
                        )}
                    </>
                }
            </div>
        }
    </>;

    if (Facade.isEmpty(sliderStats)) return <></>;

    return (
        <div className={classes.root}>
            <div className={classes.sliderContent}>
                <div className={classes.sliderHeader}>
                    {renderDatePickers()}
                    <Button variant='contained' color='primary' className={classes.searchButton} size='large' onClick={() => searchSlider()}>
                        {translate.i18n('SEARCH')}
                    </Button>
                    <IconButton
                        aria-label='Reset filters'
                        onClick={() => resetFilters()}
                        color='primary'
                        title={translate.i18n('RESET_FILTERS')}
                        size='large'>
                        <IconRefresh />
                    </IconButton>
                    <IconButton
                        aria-label='Toggle password visibility'
                        onClick={() => toggleHideFilters()}
                        color='primary'
                        title={hideFilters ? translate.i18n('SHOW_FILTERS') : translate.i18n('HIDE_FILTERS')}
                        hidden={generalPageView}
                        size='large'>
                        {hideFilters ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                    <div className={classes.switchButtons}>
                        <div className={classes.textContainer}>
                            {translate.i18n('VISUALIZE')}
                        </div>
                        <CustomSwitch
                            handleChange={() => changePageView()}
                            checked={!generalPageView}
                            trackColor={'#1890a552'}
                            roundColor={theme.palette.primary.main}
                            uncheckedColor={theme.palette.primary.main}
                            uncheckedTrackColor={'#1890a552'} />
                        <div className={classes.textContainer}>
                            {translate.i18n('ANALIZE')}
                        </div>
                    </div>
                </div>
                {evolution.length !== 0 ?
                    <>
                        <Collapse in={!generalPageView && !hideFilters}>
                            <SliderStats sliderStats={sliderStats} />
                        </Collapse>
                        <div className={classes.sliderTimelineFilterContainer}>
                            <SliderTimelineFilter
                                currentDay={currentDay}
                                selectDayDisplay={selectDayDisplay}
                                pageChanges={pageChanges}
                                evolution={evolution}
                            />
                        </div>
                        {generalPageView ? renderSlidesSites() :
                            <SliderSheet
                                sites={sliderStats?.sites}
                                currentDay={currentDay}
                                evolution={evolution} />
                        }
                    </>
                    :
                    <div className={classes.loaderContainer}>
                        <Loader size={70} />
                    </div>
                }
            </div>
            <Dialog
                classes={{
                    paper: classes.dialogDetails
                }}
                onClose={() => setShowDialogDetails(false)}
                open={showDialogDetails}>
                <IconButton
                    color='secondary'
                    onClick={() => setShowDialogDetails(false)}
                    className={classes.closeButton}
                    size='large'>
                    <ClearIcon />
                </IconButton>
                <DialogTitle onClick={() => Facade.openUrl(currentSite.site.url)}> {Facade.siteDisplayName(currentSite.site?.name)} </DialogTitle>
                <DialogContent className={classes.sliderDialogContent}>
                    {slider(currentSite.site, currentSite.newSlides, currentSite.existedSlides, currentSite.deletedSlides, currentSite.siteSlides)}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SliderPage;
