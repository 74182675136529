import AbstractService from 'core/abstracts/AbstractService';
import Facade from 'core/Facade';

class CategoryService extends AbstractService {
    /**
     * Get all categories.
     */
    getCategories(accountId) {
        return this.request({
            method: 'GET',
            url:  Facade.getApiRoot() + `accounts/${accountId}/categories`
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Add a new category.
     */
    addCategory(options) {
        const { accountId, name } = options;

        return this.request({
            method: 'POST',
            url:  Facade.getApiRoot() + `accounts/${accountId}/categories`,
            json: {
                name
            }
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Delete a category
     */
    deleteCategory(options) {
        const { accountId, category } = options;

        return this.request({
            method: 'DELETE',
            url:  Facade.getApiRoot() + `accounts/${accountId}/categories/${category.id}`
        }).then(async (responseData) => {
            if (responseData.ok) {
                return responseData.ok;
            }
            throw await responseData.json();
        });
    }

    /**
     * Edit a category
     */
    editCategory(options) {
        const { accountId, name, id } = options;

        return this.request({
            method: 'PUT',
            url:  Facade.getApiRoot() + `accounts/${accountId}/categories/${id}`,
            json: {
                name,
            }
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }
}

export default new CategoryService();
