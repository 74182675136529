const styles = theme => ({
    '@media (max-width: 800px)': {
        paper: {
            width: '100%'
        }
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(1)
    },
    bottomButton: {
        width: '48%'
    },
    bottomButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    informationContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    languageButton: {
        padding: 0
    },
    loginTextField: {
        flexGrow: 2,
        marginRight: theme.spacing(1)
    },
    noneMenuItem: {
        display: 'none'
    },
    paper: {
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        maxWidth: '580px',
        padding: theme.spacing(3),
        width: '100%'
    },
    profileContainer: {
        alignItems: 'center',
        display: 'flex'
    },
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    saveProfileButton: {
        marginBottom: theme.spacing(1)
    }
});

export default styles;
