import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    imageContainer: {
        height: '40px',
        width: '40px'
    },
    itemContainer: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: '4px',
        marginTop: '4px'
    },
    itemName: {
        fontSize: '14px',
        fontWeight: 500,
        marginLeft: '4px',
        display: 'flex'
    },
}));
