import React from 'react';
// PCP Components
import Loader from 'components/commons/Loader/Loader';

class LoadingPage extends React.Component {

    render() {
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                height: '98vh',
                justifyContent: 'center',
                width: '100%'
            }}>
                <Loader />
            </div>
        );
    }
}

export default LoadingPage;
