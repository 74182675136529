const styles = theme => ({
    arrow: {
        color: theme.palette.primary.main,
        fontSize: '35px',
        marginBottom: '4px',
        marginLeft: '8px'
    },
    basedOnContainer: {
        alignItems: 'center',
        display: 'flex'
    },
    bigLabel: {
        fontSize: '16px',
        fontWeight: '500',
        marginLeft: '6px',
        marginRight: '6px',
        marginTop: '12px'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    editContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        marginLeft: '10px',
        marginRight: '10px'
    },
    exempleContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '15px',
        marginRight: '10px'
    },
    exempleText: {
        fontSize: '15px',
        fontWeight: 500
    },
    finalPriceText: {
        color: '#1890A5',
        fontSize: '35px',
        fontWeight: 600
    },
    formControl: {
        margin: theme.spacing(0.5),
        width: 'auto'
    },
    header: {
        display: 'flex',
        marginBottom: '6px'
    },
    input: {
        padding: '10px 14px'
    },
    multipleSelect: {
        paddingTop: 0
    },
    onCompetitorsSettingsContainer: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        marginBottom: '10px',
        width: '100%'
    },
    plusSymbol: {
        color: theme.palette.primary.main,
        fontSize: '15px'
    },
    radioButtonContainer: {
        marginLeft: '16px'
    },
    radioStrategy: {
        backgroundColor: theme.palette.grey.main,
        height: '1px',
        width: '100%'
    },
    resumeText: {
        border: '1px solid' + theme.palette.grey.main,
        borderRadius: '3px',
        fontSize: '16px',
        fontWeight: 500,
        marginBottom: '10px',
        marginTop: '10px',
        padding: '10px',
        width: '100%'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    roundingContainer: {
        border: '1px solid' + theme.palette.grey.main,
        borderRadius: '4px',
        marginLeft: '4px',
        marginTop: '4px',
        padding: '6px',
        width: 'calc(100% - 300px)'
    },
    roundingSubContainer: {
        display: 'flex'
    },
    roundingSubSubContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '30%'
    },
    smallRadioLabel: {
        fontSize: '13px'
    },
    subSettingsContainer: {
        border: '1px solid' + theme.palette.grey.main,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginLeft: '10px',
        padding: '6px',
        width: '20%'
    },
    symbol: {
        color: theme.palette.primary.main,
        fontSize: '20px',
        marginBottom: '2px'
    },
    title: {
        alignItems: 'center',
        color: theme.palette.primary.main,
        display: 'flex',
        fontSize: '17px',
        fontWeight: 500,
        marginLeft: '16px',
        marginRight: '16px'
    },
    titleBar: {
        backgroundColor: theme.palette.primary.main,
        flexGrow: 1,
        height: '2px',
        marginLeft: '16px'
    },
    titleSecondaryText: {
        fontSize: '15px',
        fontWeight: 500
    },
    valueTextField: {
        margin: '4px',
        maxWidth: '80px'
    }
});

export default styles;
