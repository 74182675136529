import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    accountName: {
        backgroundColor: 'white',
        fontSize: '15px',
        fontWeight: 'bold',
        left: '17px',
        paddingLeft: '5px',
        paddingRight: '10px',
        position: 'absolute',
        top: '-6px',
        zIndex: 1000
    },
    accountProduct: {
        display: 'flex',
        height: '230px',
        margin: '5px',
        width: 'calc(40% - 10px)'
    },
    accountProductImage: {
        height: 'auto',
        margin: 'auto',
        marginLeft: '10px',
        width: '150px'
    },
    accountProductInfos: {
        maxWidth: 'calc(100% - 150px)',
        paddingTop: '5px'
    },
    categoryTooltip: {
        fontSize: '11px',
        whiteSpace: 'pre-wrap'
    },
    closeButton: {
        position: 'absolute',
        right: '2px',
        top: '2px',
        zIndex: 1000
    },
    configsButton: {
        width: '100%'
    },
    configsInfo: {
        margin: 'auto',
        marginBottom: '15px'
    },
    configsSelector: {
        marginLeft: '40%',
        textAlign: 'right',
        width: '50%'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    dialogContentNotFullScreen: {
        minHeight: 'auto',
        minWidth: '700px'
    },
    hideOverflow: {
        borderRadius: '3px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    hoverBg: {
        borderRadius: '3px',
        paddingLeft: '3px',
        paddingRight: '3px',
        width: 'fit-content'
    },
    infoMessage: {
        color: 'grey',
        fontSize: '10px',
        marginLeft: '10px'
    },
    loader: {
        alignItems: 'center',
        display: 'flex',
        height: '100px',
        justifyContent: 'center'
    },
    margin: {
        margin: theme.spacing(1)
    },
    modifyUrlTextField: {
        flexGrow: 3,
        margin: theme.spacing(1)
    },
    noProduct: {
        height: '180px',
        paddingTop: '10%',
        textAlign: 'center'
    },
    productInfo: {
        marginBottom: '3px'
    },
    productName: {
        borderRadius: '3px',
        fontWeight: 'bold',
        marginBottom: '10px',
        paddingLeft: '2px'
    },
    products: {
        display: 'flex',
        position: 'relative'
    },
    rightIcon: {
        marginLeft: '10px'
    },
    searchBar: {
        marginTop: '10px',
        width: '100%'
    },
    searchContainer: {
        display: 'flex',
        width: '30%'
    },
    searchIcon: {
        marginTop: '5px'
    },
    siteConfigs: {
        height: '140px',
        marginTop: '10px',
        paddingRight: '15px',
        paddingTop: '15px'
    },
    siteConfigsButtons: {
        margin: theme.spacing(1),
        width: '90%'
    },
    siteName: {
        backgroundColor: 'white',
        fontSize: '15px',
        fontWeight: 'bold',
        left: 'calc(40% + 17px)',
        paddingLeft: '5px',
        paddingRight: '10px',
        position: 'absolute',
        top: '-6px',
        zIndex: 1000
    },
    siteProduct: {
        display: 'flex',
        height: '230px',
        margin: '5px',
        width: 'calc(60% - 20px)'
    },
    siteProductImage: {
        height: '130px',
        marginBottom: 'auto',
        marginLeft: '5px',
        marginRight: '10px',
        marginTop: 'auto',
        width: 'auto'
    },
    siteProductInfo: {
        height: '170px',
        marginTop: '10px',
        paddingTop: '10px'
    },
    siteProductInfoImage: {
        alignItems: 'center',
        display: 'flex',
        height: '170px',
        marginTop: '10px'
    },
    suggestionTitle: {
        alignItems: 'center',
        backgroundColor: theme.palette.grey.light,
        borderRadius: '3px',
        color: theme.palette.grey.dark,
        display: 'flex',
        height: '25px',
        marginTop: '15px',
        paddingLeft: '15px',
        width: '67%'
    },
    suggestionTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    }
}));
