export const styles = theme => ({
    agendaIcon: {
        color: theme.palette.white.main,
        fontSize: '40px',
        position: 'absolute'
    },
    agendaIconCircle: {
        background: '#0E5562',
        borderRadius: '50%',
        height: '60px',
        lineHeight: '100px',
        padding: '30px',
        position: 'absolute',
        textAlign: 'center',
        verticalAlign: 'middle',
        width: '60px'
    },
    bottomProductPart: {
        backgroundColor: 'white',
        borderRadius: '4px 4px 4px 4px',
        boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.37)',
        flexGrow: 1,
        height: 'calc(100% - 308px)',
        position: 'relative',
        width: '100%'
    },
    buttonsContainer: {
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        paddingBottom: '11px',
        width: '52px'
    },
    dateFromInput: {
        height: '33px',
        textAlign: 'left'
    },
    dateFromText: {
        justifyContent: 'left',
        position: 'absolute'
    },
    datePicker: {
        display: 'flex'
    },
    datePickerContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '50px',
        justifyContent: 'center',
        marginBottom: '15px'
    },
    datePickerFrom: {
        marginRight: '40px'
    },
    datePickerTo: {
        marginTo: '40px'
    },
    dateToInput: {
        height: '33px',
        textAlign: 'right'
    },
    dateToText: {
        justifyContent: 'right',
        position: 'absolute'
    },
    editProductContainer: {
        alignItems: 'center',
        display: 'flex',
        gap: '15px'
    },
    errorNoHistoryContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorNoHistory: {
        fontWeight: 800,
        fontSize: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorNoHistoryIcon: {
        fontWeight: 800,
        fontSize: '40px',
        marginRight: '10px'
    },
    goBackButton: {
        paddingTop: '10px'
    },
    goBackButtonContainer: {
        display: 'flex'
    },
    hideShadow: {
        backgroundColor: 'white',
        height: '10px',
        position: 'absolute',
        top: '-6px',
        width: 'calc(100% - 52px)'
    },
    infoSectionContainer: {
        height: '100%',
        overflow: 'auto'
    },
    leftBar: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginRight: '15px',
        paddingBottom: '10px',
        position: 'relative',
        width: '380px'
    },
    priceDetailsChart: {
        height: '100%',
        width: '65%'
    },
    priceSectionNoPrice: {
        display: 'flex',
        width: '100%',
        height: '50px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: '800'
    },
    productButton: {
        '&:hover': {
            backgroundColor: '#e8faff'
        },
        alignItems: 'center',
        backgroundColor: theme.palette.white.main,
        borderRadius: '5px',
        boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.37)',
        display: 'flex',
        height: '40px',
        justifyContent: 'center',
        margin: 0,
        width: '40px'
    },
    productButtonDisabled: {
        alignItems: 'center',
        backgroundColor: theme.palette.white.main,
        borderRadius: '5px',
        boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.37)',
        display: 'flex',
        height: '40px',
        justifyContent: 'center',
        margin: 0,
        width: '40px'
    },
    productClickable: {
        cursor: 'pointer'
    },
    productDetails: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridTemplateRows: 'repeat(16, 1fr)',
        height: '100%',
        marginTop: '5px',
        width: '100%'
    },
    productDetailsContainer: {
        display: 'flex',
        height: '200px',
        width: '100%'
    },
    productDetailsContainerTop: {
        backgroundColor: 'white',
        borderRadius: '4px 4px 0px 4px',
        boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.37)',
        width: 'calc(100% - 52px)'
    },
    productPicturePaper: {
        padding: '5px',
        backgroundColor: theme.palette.white.main,
        borderRadius: '5px',
        boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.37)',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    productPictureContainer: {
        borderRadius: '5px 5px 0 0',
        height: '100%',
        padding: '10px 10px 17px 10px'
    },
    productPicture: {
        maxHeight: '100%',
        maxWidth: '100%'
    },
    productsChart: {
        height: '100%',
        padding: '10px'
    },
    rightContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '10px',
        width: '100%'
    },
    root: {
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        padding: '0px 15px 15px 15px',
        width: '100%'
    },
    selected: {
        backgroundColor: '#0E5562',
        boxShadow: 'inset 0px 0px 6px 0px #000000'
    },
    waitingForDelete: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        width: '100vw'
    },
    waitingForDeleteContainer: {
        backgroundColor: '#7d7d7daa',
        height: '100vh',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100vw',
        zIndex: 999
    }
});

export const hrStyle = {
    color: '#0E5562',
    borderColor : '#0E5562',
    backgroundColor: '#0E5562',
    height: '0',
    flexGrow: '1',
    marginRight: '5px'
};
