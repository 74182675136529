// Abstract
import AbstractStore from 'core/abstracts/AbstractStore';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';

class CategoryStore extends AbstractStore {

    constructor() {

        const CHANGE_EVENT = 'ACCOUNT_CHANGE_EVENT';
        const NAME = 'ACCOUNT_STORE';

        super(NAME, CHANGE_EVENT);

        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));

        this._initStore();
    }

    _initStore() {
        this.categories = [];
        this.loading = true;
    }

    isLoading() {
        return this.loading;
    }

    setCategories(categories) {
        this.categories = categories;
    }

    getCategories() {
        return this.categories;
    }

    addCategory(category) {
        this.categories.push(category);
        this.categories = this.categories.slice(0);
    }

    deleteCategory(categoryToDelete) {
        this.categories = this.categories.filter(category => categoryToDelete.id !== category.id);
    }

    editCategory(categoryToEdit) {
        this.categories = this.categories.map(category => categoryToEdit.id === category.id ? categoryToEdit : category);
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
        case Constants.USER_LOGOUT:
            this._initStore();
            break;
        case Constants.CATEGORIES_GET_START:
            this.loading = true;
            this._emitChange();
            break;
        case Constants.CATEGORIES_GET_END:
            this.loading = false;
            this.setCategories(action.value);
            this._emitChange();
            break;
        case Constants.CATEGORY_ADD:
            this.addCategory(action.value);
            this._emitChange();
            break;
        case Constants.CATEGORY_DELETE:
            this.deleteCategory(action.value);
            this._emitChange();
            break;
        case Constants.CATEGORY_EDIT:
            this.editCategory(action.value);
            this._emitChange();
            break;
        default:
            return false;
        }
    }
}

export default new CategoryStore();
