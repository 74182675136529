// Locales
import translate from 'locales/i18n';

export const createData = (statsByDay, theme) => {
    const values = [];
    const colors = [];
    for (let i = 0; i < 8; i++) {
        colors.push(theme.palette.primary.main);
    }

    if (statsByDay && statsByDay.length > 0) {
        statsByDay.forEach(day => values.push(day.cardinality));
    }

    const data = {
        datasets: [{
            backgroundColor: colors,
            borderColor: colors,
            data: values,
            hoverBackgroundColor: colors,
            hoverBorderColor: colors,
            label: 'mail(s)'
        }],
        labels: [
            translate.i18n('SHORT_MONDAY'),
            translate.i18n('SHORT_TUESDAY'),
            translate.i18n('SHORT_WEDNESDAY'),
            translate.i18n('SHORT_THURSDAY'),
            translate.i18n('SHORT_FRIDAY'),
            translate.i18n('SHORT_SATURDAY'),
            translate.i18n('SHORT_SUNDAY')
        ]
    };

    return data;
};

export const options = {
    maintainAspectRatio: false,
    borderRadius: 2,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            text: translate.i18n('ADBOX.MAIL_DAYS_TITLE'),
            padding: {
                bottom: 15,
                top: 8
            }
        },
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            stacked: true,
            ticks: {
                display: true
            }
        },
        y: {
            grid: {
                display: false,
                drawBorder: false
            },
            stacked: true,
            ticks: {
                display: true,
                callback: (value) => Number.isInteger(value) ? value : null
            }
        }
    }
};
