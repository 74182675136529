const styles = theme => ({
    '@media (max-width: 950px)': {
        hideLabel: {
            display: 'none'
        }
    },
    borderBottom: {
        backgroundColor: theme.palette.grey.light,
        height: '1px',
        position: 'absolute',
        top: 57,
        width: '100%',
        zIndex: 12
    },
    borderFirstColumn: {
        backgroundColor: theme.palette.grey.light,
        height: '100%',
        left: 270,
        position: 'absolute',
        top: 0,
        width: '1px',
        zIndex: 12
    },
    buttonBottom: {
        marginLeft: '4px',
        marginTop: '4px',
        maxWidth: '20%'
    },
    buttonToggleFilters: {
        padding: 0,
        width: '100%'
    },
    containerClass: {
        height: '100%'
    },
    datePicker: {
        margin: '0px 5px 0px 0px',
        maxWidth: '120px'
    },
    datePickerBox: {
        padding: '10px'
    },
    filtersBottomContainer: {
        height: '40%',
        marginTop: '2px',
        width: '100%'
    },
    filtersTitle: {
        alignItems: 'center',
        backgroundColor: theme.palette.grey.ultralight,
        borderRadius: '4px',
        color: theme.palette.grey.dark,
        display: 'flex',
        fontFamily: 'roboto',
        fontSize: '13px',
        fontWeight: 500,
        justifyContent: 'space-between',
        paddingLeft: '14px',
        paddingRight: '4px',
        width: '100%'
    },
    filtersTopContainer: {
        alignItems: 'center',
        display: 'flex',
        width: '100%'
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '2px',
        width: '100%'
    },
    formControlMode: {
        margin: '5px',
        minWidth: 120
    },
    hide: {
        display: 'none'
    },
    itemClass: {
        padding: '5px'
    },
    leftIcon: {
        fontSize: 20,
        marginRight: '4px'
    },
    listingTitle: {
        alignItems: 'center',
        backgroundColor: theme.palette.grey.ultralight,
        borderRadius: '4px',
        color: theme.palette.grey.dark,
        display: 'flex',
        fontFamily: 'roboto',
        fontSize: '13px',
        fontWeight: 500,
        justifyContent: 'space-between',
        marginTop: '4px',
        paddingLeft: '4px',
        paddingRight: '4px',
        width: '100%'
    },
    minifiedNoItemFound: {
        height: '45%'
    },
    minifiedPaper: {
        height: '45%'
    },
    minifiedProgressContainer: {
        height: '45%'
    },
    noItemFound: {
        alignItems: 'center',
        color: theme.palette.grey.main,
        display: 'flex',
        flexGrow: 1,
        fontSize: '30px',
        fontWeight: 'bold',
        justifyContent: 'center',
        width: '100%'
    },
    pagination: {
        borderBottom: 0
    },
    paper: {
        flexGrow: 1,
        marginTop: theme.spacing(1),
        overflow: 'hidden',
        position: 'relative',
        width: '100%'
    },
    pricePageContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    progressContainer: {
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        width: '100%'
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%'
    },
    searchBar: {
        flexGrow: 1
    },
    searchIcon: {
        color: theme.palette.common.grey
    },
    sliderClass: {
        height: '100%'
    },
    tablePagination: {
        width: 'auto'
    },
    tablePriceContainer: {
        height: '100%',
        width: '100%'
    }
});

export default styles;
