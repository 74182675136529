import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    titleText: {
        fontSize: '16px',
        fontWeight: 'bold',
        margin: '5px'
    }
}));
