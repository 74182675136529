// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Actions
import SuggestionAction from 'actions/SuggestionAction';
import ItemAction from 'actions/ItemAction';
// Stores
import AccountStore from 'stores/AccountStore';
// Locales
import translate from 'locales/i18n';
// PCP Components
import DialogEdit from 'components/dialog/DialogEdit/DialogEdit';
import DialogConfirm from 'components/dialog/DialogConfirm/DialogConfirm';
import DialogConfigUrl from '../DialogConfigUrl/DialogConfigUrl';
import DialogAddUrlSiteProduct from './DialogAddUrlSiteProduct';
import DialogAddUrlAccountProduct from './DialogAddUrlAccountProduct';
import SuggestionSheet from 'components/sheet/SuggestionSheet/SuggestionSheet';
import Loader from 'components/commons/Loader/Loader';
import Carousel from 'react-multi-carousel';
// Styles
import { useStyles } from 'components/dialog/DialogAddUrl/DialogAddUrl.style.js';
//Mui components
import {
    Dialog,
    DialogContent,
    InputAdornment,
    TextField
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 2500 },
        items: 6,
        slidesToSlide: 5
    },
    desktop: {
        breakpoint: { max: 2500, min: 1300 },
        items: 5,
        slidesToSlide: 4
    },
    smallDesktop: {
        breakpoint: { max: 1300, min: 1000 },
        items: 4,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1000, min: 464 },
        items: 3,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1
    }
};

const DialogAddUrl = ({ open, close, fullScreen, item, scrapingData, website, accountSiteUrl }) => {
    const [dialogEditOpen, setDialogEditOpen] = useState(false);
    const [dialogConfirmDelete, setDialogConfirmDelete] = useState(false);
    const [dialogConfig, setDialogConfig] = useState(false);

    const [newUrl, setNewUrl] = useState(scrapingData && scrapingData.url ? scrapingData.url : '');
    const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(true);
    const [suggestions, setSuggestions] = useState([]);

    const classes = useStyles();

    const [selectedProduct, setSelectedProduct] = useState({});
    const [search, setSearch] = useState('');

    const getSuggestions = searchText => {
        setIsLoadingSuggestions(true);
        if (searchText) {
            SuggestionAction.searchSuggestions(AccountStore.getCurrentAccount().getId(), website.id, 1, 15, searchText)
                .then(result => {
                    setSuggestions(result);
                    setIsLoadingSuggestions(false);
                }, () => setIsLoadingSuggestions(false));
        } else {
            SuggestionAction.getSuggestions(AccountStore.getCurrentAccount().getId(), item.id, website.id, 1, 15, item.name)
                .then(result => {
                    if (result && result.length > 0) {
                        setSuggestions(result);
                        setIsLoadingSuggestions(false);
                    } else {
                        SuggestionAction.searchSuggestions(AccountStore.getCurrentAccount().getId(), website.id, 1, 15, item.name)
                            .then(s => {
                                setSuggestions(s);
                                setIsLoadingSuggestions(false);
                            }, () => setIsLoadingSuggestions(false));
                    }
                });
        }
    };

    useEffect(() => {
        if (open) {
            getSuggestions();
            setNewUrl(scrapingData && scrapingData.url ? scrapingData.url : '');
        }
    }, [open]);

    useEffect(() => {
        if (scrapingData && scrapingData.url === newUrl) {
            let configs;
            let addInfos;
            if (suggestions.find((suggestion) => suggestion.url.split('?')[0] === newUrl.split('?')[0])) {
                const sug = suggestions.find((suggestion) => suggestion.url.split('?')[0] === newUrl.split('?')[0]);
                ({ configs } = sug);
                addInfos = { brand :sug.brand, categories: sug.categories };
            }
            configs || addInfos ? setSelectedProduct({ ...scrapingData.to, url: scrapingData.url, configs, brand: addInfos?.brand, categories: addInfos?.categories }) : setSelectedProduct({ ...scrapingData.to, url: scrapingData.url, brand: addInfos?.brand, categories: addInfos?.categories });
        } else suggestions.find((suggestion) => suggestion.url.split('?')[0] === newUrl.split('?')[0]) ? setSelectedProduct(suggestions.find((suggestion) => suggestion.url.split('?')[0] === newUrl.split('?')[0])) : null;
    }, [newUrl, suggestions]);

    const changeStatus = (status, params) => {

        const options = {
            accountId: AccountStore.getCurrentAccount().getId(),
            itemId: item.id,
            params,
            scrapingDataId: scrapingData.id ? scrapingData.id : '',
            siteId: website.id,
            status
        };

        ItemAction.updateScrapingData(options);
        close(newUrl,status);
    };

    const deleteScrapingData = () => {
        scrapingData.data = scrapingData.to;
        scrapingData.data.status = 'UNDEFINED';
        scrapingData.id = '';
        scrapingData.url = '';

        const options = {
            accountId: AccountStore.getCurrentAccount().getId(),
            itemId: item.id,
            scrapingData,
            siteId: website.id
        };

        ItemAction.deleteScrapingData(options);
        close();
    };

    const displayCategories = (categories) => {
        let res = '';
        if (categories.length === 1) return ` - ${categories[0]}`;

        categories.forEach(cat => {
            res = res.concat(` - ${cat} \n`);
        });
        return res;
    };

    const renderDialogEdit = () =>
        <DialogEdit
            label={translate.i18n('DELAY')}
            title={translate.i18n('CHOOSE_A_DELAY')}
            close={() => setDialogEditOpen(false)}
            open={dialogEditOpen}
            startAdornment={<InputAdornment position='start'>{translate.i18n('DAYS')}</InputAdornment>}
            callback={delay => {
                changeStatus('NV_BELL', { delay: parseInt(delay), url: newUrl });
                setDialogEditOpen(false);
            }}
            type='number'
            defaultValue={30} />;

    const displayPrice = (price) => {
        if (!price) return ' - ';
        const splitedPrice = price.toString().split('.');
        return `${splitedPrice[0]},${splitedPrice.length === 2 ? splitedPrice[1].length === 2 ? splitedPrice[1] : splitedPrice[1] + '0' : '00'} ${AccountStore.getCurrentAccount().getCurrency()}`;
    };
    if (!scrapingData || !item || !website) return null;

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={() => close(false,false)}
            maxWidth={'lg'}
            fullWidth={true}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
        >
            <IconButton
                color='secondary'
                onClick={() => close()}
                className={classes.closeButton}
                size='large'>
                <ClearIcon />
            </IconButton>
            <DialogContent className={classNames(!fullScreen && classes.dialogContentNotFullScreen, classes.dialogContent)}>
                <div className={classes.products}>
                    <DialogAddUrlAccountProduct
                        item={item}
                        displayPrice={(price) => displayPrice(price)}
                        accountSiteUrl={accountSiteUrl}
                    />
                    <DialogAddUrlSiteProduct
                        selectedProduct={selectedProduct}
                        newUrl={newUrl}
                        setNewUrl={(url) => setNewUrl(url)}
                        displayPrice={(price) => displayPrice(price)}
                        scrapingData={scrapingData}
                        siteName={website.name}
                        websiteUrls={[website.url].concat(website.alternativeUrls)}
                        setDialogConfirmDelete={(isOpen) => setDialogConfirmDelete(isOpen)}
                        changeStatus={(status, params) => changeStatus(status, params)}
                        setDialogEditOpen={(isOpen) => setDialogEditOpen(isOpen)}
                        setDialogConfig={(isOpen) => setDialogConfig(isOpen)}
                        displayCategories={(cat) => displayCategories(cat)}
                        itemName={item.name}
                    />
                </div>
                {suggestions.length > 0 &&
                    <div className={classes.suggestionTitleContainer}>
                        <div className={classes.suggestionTitle}>
                            {translate.i18n('DIALOG_ADD_URL.SUGGESTIONS')}
                        </div>
                        <div className={classes.searchContainer}>
                            <TextField
                                id='search'
                                label={translate.i18n('DIALOG_ADD_URL.SEARCH_IN_SUGGESTIONS')}
                                variant='outlined'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={(e) => e.key === 'Enter' && getSuggestions(search)}
                                size='small'
                                className={classes.searchBar}
                            />
                            <IconButton aria-label='info' size='large' className={classes.searchIcon} onClick={() => getSuggestions(search)}>
                                <Search fontSize='inherit' />
                            </IconButton>
                        </div>
                    </div>
                }
                <div className={classes.suggestions}>
                    {isLoadingSuggestions ?
                        <div className={classes.loader}> <Loader size={50} /> </div>
                        :
                        <Carousel
                            swipeable={true}
                            draggable={false}
                            responsive={responsive}
                            keyBoardControl={false}
                            transitionDuration={300}
                            removeArrowOnDeviceType={['mobile']}
                            autoPlay={false}
                            shouldResetAutoplay={false}
                        >
                            {suggestions.map((suggestion, index) =>
                                <SuggestionSheet
                                    suggestion={suggestion}
                                    key={index}
                                    addUrlToConfig={(url) => setNewUrl(url)}
                                    displayPrice={(price) => displayPrice(price)}
                                    isSelected={suggestion.url.split('?')[0] === newUrl.split('?')[0]}
                                    displayCategories={(cat) => displayCategories(cat)}
                                    changeStatus={(status, params) => changeStatus(status, params)}
                                />
                            )}
                        </Carousel>
                    }
                </div>
                {suggestions.length > 0 &&
                    <div className={classes.infoMessage}>
                        {translate.i18n('DIALOG_ADD_URL.SUGGESTION_INFO_MESSAGE')}
                    </div>
                }
                <DialogConfirm
                    callback={() => deleteScrapingData()}
                    close={() => setDialogConfirmDelete(false)}
                    content={translate.i18n('DIALOG_ADD_URL.DELETE_CONFIRM')} open={dialogConfirmDelete} />
                <DialogConfigUrl
                    close={() => setDialogConfig(false)}
                    open={dialogConfig}
                    selectedProduct={selectedProduct}
                    fullUrl={newUrl}
                    callback={(url) => {
                        setNewUrl(url);
                        setDialogConfig(false);
                    }}
                />
                {renderDialogEdit()}
            </DialogContent>
        </Dialog>
    );
};

DialogAddUrl.propTypes = {
    classes: PropTypes.object,
    close: PropTypes.func,
    fullScreen: PropTypes.bool,
    item: PropTypes.object,
    open: PropTypes.bool,
    scrapingData: PropTypes.object,
    website: PropTypes.object,
    accountSiteUrl: PropTypes.string,
};

export default DialogAddUrl;
