import React, { useState } from 'react';
import PropTypes from 'prop-types';
//Mui components
import {
    IconButton,
    TextField
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconRefresh from '@mui/icons-material/Cached';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/fr';
//Styles
import { useStyles } from 'components/commons/DatePickers/DatePickers.style';
//Locales
import translate from 'locales/i18n';

const DatePickers = ({
    callBack,
    fromStr,
    isLoading,
    reset,
    setFromStr,
    setToStr,
    titleText,
    toStr
}) => {

    const classes = useStyles();

    const [openFstDatePicker, setOpenFstDatePicker] = useState(false);
    const [openSndDatePicker, setOpenSndDatePicker] = useState(false);

    return <div className={classes.titleContainer}>
        <div className={classes.titleText}>
            {titleText && translate.i18n(titleText[0])}
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
            <DatePicker
                value={fromStr}
                maxDate={new Date()}
                onChange={(newValue) => {
                    if (!newValue) return;
                    setFromStr(newValue.$d);
                }}
                open={openFstDatePicker}
                disabled={isLoading}
                onClose={() => {
                    setOpenFstDatePicker(false);
                    setOpenSndDatePicker(true);
                }}
                renderInput={(params) => <TextField {...params} onClick={() => !isLoading && setOpenFstDatePicker(true)} size='small' />}
            />
        </LocalizationProvider>
        <div className={classes.titleText}>
            {titleText && translate.i18n(titleText[1])}
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
            <DatePicker
                value={toStr}
                minDate={fromStr}
                maxDate={new Date()}
                disabled={!openSndDatePicker || isLoading}
                open={openSndDatePicker}
                onChange={(newValue) => {
                    if (!newValue) return;
                    setToStr(newValue.$d);
                }}
                onClose={() => {
                    setOpenSndDatePicker(false);
                    callBack();
                }}
                renderInput={(params) => <TextField {...params} size='small' />}
            />
        </LocalizationProvider>
        <IconButton onClick={reset} color='primary'>
            <IconRefresh />
        </IconButton>
    </div>;
};

DatePickers.propTypes = {
    callBack: PropTypes.func,
    fromStr: PropTypes.instanceOf(Date),
    isLoading: PropTypes.bool,
    reset: PropTypes.func,
    setFromStr: PropTypes.func,
    setToStr: PropTypes.func,
    titleText: PropTypes.array,
    toStr: PropTypes.instanceOf(Date)
};

export default DatePickers;
