// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Styles
import { useStyles } from 'components/dialog/DialogEdit/DialogEdit.style';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const DialogEdit = ({ close, callback, open, title, label, startAdornment, type, defaultValue, error }) => {
    const [value, setValue] = useState(defaultValue);
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => setValue(defaultValue), [defaultValue]);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={close}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
        >
            <DialogTitle id='responsive-dialog-title'>{title}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <TextField
                    id='delay'
                    error={!!error}
                    helperText={error}
                    className={classes.textField}
                    variant='outlined'
                    type={type}
                    label={label}
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    InputProps={{
                        startAdornment,
                    }}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') callback(value);
                    }}
                    onChange={event => setValue(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <IconButton
                    color='secondary'
                    onClick={close}
                    size='large'>
                    <ClearIcon />
                </IconButton>
                <IconButton
                    color='primary'
                    onClick={() => callback(value)}
                    size='large'>
                    <DoneIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

DialogEdit.propTypes = {
    callback: PropTypes.func,
    close: PropTypes.func,
    defaultValue: PropTypes.any,
    error: PropTypes.string,
    label: PropTypes.string,
    open: PropTypes.bool,
    startAdornment: PropTypes.node,
    title: PropTypes.string,
    type: PropTypes.string
};

export default DialogEdit;
