import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//Styles
import { useStyles } from 'components/filter/TrendFilters/TrendBarFilter/TrendBarFilter.style';
//Mui components
import { Chip } from '@mui/material';
import { useTheme } from '@mui/styles';
//Stores
import UserStore from 'stores/UserStore';
import LayoutStore from 'stores/LayoutStore';

const TrendDataBar = ({
    data,
    higherCardinality,
    isFirstSite,
    isSites,
    selectData
}) => {

    const classes = useStyles();
    const theme = useTheme();
    const [blur, setBlur] = useState(UserStore.getCurrentUser().isSuperUser() ? LayoutStore.getBlur() : false);

    function onLayoutStoreChange() {
        setBlur(LayoutStore.getBlur());
    }

    useEffect(() => {
        UserStore.getCurrentUser().isSuperUser() ? LayoutStore.addChangeListener(onLayoutStoreChange) : null;
        return () => UserStore.getCurrentUser().isSuperUser() ? LayoutStore.removeChangeListener(onLayoutStoreChange) : null;
    }, []);

    const getPercentage = higherCardinality === 0 ? 0 : data.value === 'NaN' ? 0 : (data.value / higherCardinality) * 50;

    const displayPercentage = (percentage) => percentage === 'NaN' ? ' - ':`${percentage >= 0 ? '+':'-'} ${percentage >= 0 ? percentage : String(percentage).slice(1)} %`;

    const displaySite = (s) => isSites && s.includes('_') ? s.split('_')[0] + '.' + s.split('_')[1] : s;

    return <div className={classes.dataBar}
        onClick={() => selectData()}
        style={
            isFirstSite ? {
                background: `linear-gradient(90deg, white 0%, white ${ data.value > 0 ? 50:50 + getPercentage}%, rgb(95, 207, 125) ${ data.value > 0 ? 50:50 + getPercentage}%, rgb(95, 207, 125) ${ data.value > 0 ? 50 + getPercentage:50}%, white ${ data.value > 0 ? 50 + getPercentage:50}%, white 100%)`,
                marginBottom: '25px'
            } : isSites ?{
                background: `linear-gradient(90deg, white 0%, white ${ data.value > 0 ? 50:50 + getPercentage}%, rgb(105, 206, 209) ${ data.value > 0 ? 50:50 + getPercentage}%, rgb(105, 206, 209) ${ data.value > 0 ? 50 + getPercentage:50}%, white ${ data.value > 0 ? 50 + getPercentage:50}%, white 100%)`
            } : {
                background: `linear-gradient(90deg, white 0%, white ${ data.value > 0? 50:50 + getPercentage}%, ${theme.palette.primary.main} ${ data.value > 0? 50:50 + getPercentage }%, ${theme.palette.primary.main} ${ data.value > 0 ? 50 + getPercentage:50}%, white ${ data.value > 0 ? 50 + getPercentage:50}%, white 100%)`
            }}>
        <Chip
            className={classes.dataChip}
            label={<div style={{ display: 'flex' }}><div style={ isSites && blur? { filter: 'blur(0.2rem)' } : null }> {isSites? displaySite(data.name):data.name}</div> <div style={{ fontWeight: 'bold', marginLeft: '5px' }}>{displayPercentage(data.value)}</div></div>}
            size='small'
        />
    </div>;
};

TrendDataBar.propTypes = {
    data: PropTypes.object,
    higherCardinality: PropTypes.number,
    isFirstSite: PropTypes.bool,
    selectData: PropTypes.func,
    isSites: PropTypes.bool,
};

export default TrendDataBar;
