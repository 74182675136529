// Material UI Components
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    arrow: {
        cursor: 'pointer',
        position: 'absolute'
    },
    bottomDayContainer: {
        height: '24px',
        width: '100%'
    },
    datesLegendSpace: {
        border: '2px solid ' + theme.palette.primary.main,
        borderRadius: '10%',
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px -2px rgba(0,0,0,0.12)',
        height: '35px',
        padding: '3px',
        textAlign: 'center',
        width: '75px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    datesLegendText: {
        color: theme.palette.primary.dark,
        fontFamily: 'roboto',
        fontSize: '76%',
        fontWeight: 'bold',
        margin: '2px 0 0 0',
        padding: 0
    },
    dayCircle: {
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        },
        backgroundColor: theme.palette.primary.dark,
        borderRadius: '50%',
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px -2px rgba(0,0,0,0.12)',
        cursor: 'pointer',
        height: '15px',
        position: 'relative',
        top: '-5px',
        width: '13px',
        zIndex: 100
    },
    dayContainer: {
        height: '100%'
    },
    daysBox: {
        display: 'flex',
        height: '100%',
        margin: '0',
        width: '84%',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    dayWrapper: {
        height: '70px',
        width: '200px',
        zIndex: 22
    },
    dayWrapperBubble: {
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        color: 'white',
        display: 'flex',
        fontSize: '20px',
        fontWeight: 'bold',
        height: '40px',
        justifyContent: 'center',
        marginLeft: '-60px',
        position: 'relative',
        textAlign: 'center',
        width: '100%',
        zIndex: 22
    },
    dayWrapperOthers: {
        height: '70px',
        marginTop: '-10px',
        opacity: 0.5,
        width: '200px',
        zIndex: 23
    },
    dayWrapperTic: {
        backgroundColor: theme.palette.primary.main,
        height: '20px',
        marginLeft: '38px',
        width: '4px',
        zIndex: 22
    },
    fowardDateButton: {
        height: '30px',
        margin: '0.5%',
        marginTop: '27px',
        paddingTop: '2px',
        textAlign: 'center',
        width: '2%'
    },
    middleDayContainer: {
        backgroundColor: theme.palette.primary.dark,
        height: '5px',
        margin: 0,
        padding: 0,
        width: '100%'
    },
    newMonthBox: {
        left: '-0%',
        opacity: '0.5',
        position: 'relative',
        top: '-7px',
        width: '40px',
        zIndex: 20
    },
    newMonthText: {
        border: '2px solid ' + theme.palette.primary.main,
        color: theme.palette.primary.main,
        fontSize: '70%',
        padding: '5px',
        textAlign: 'center',
        width: '100%',
        zIndex: 20
    },
    newMonthTic: {
        borderLeft: '2px solid ' + theme.palette.primary.main,
        height: '10px',
        marginLeft: '48%',
        width: '1px',
        zIndex: 20
    },
    timelineFilterContent: {
        display: 'flex',
        height: '114px',
        margin: 0,
        padding: 0,
        width: '80%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    timelineFilterRoot: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '114px',
        padding: '0% 5% 0% 5%',
        width: '98%'
    },
    topDayContainer: {
        height: '40px',
        width: '100%'
    },
    containerClass: {
        height: '100%',
        width: '100%',
        position: 'unset'
    },
    itemClass: {
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
    },
    sliderClass: {
        height: '100%',
    },
    timelineFilter: {
        position: 'relative',
        width: '87%',
        height: '100%'
    },
    timelineFilterBar: {
        width: '100%',
        position: 'absolute',
        height: '4px',
        backgroundColor: 'rgba(24, 144, 165, 0.5)' ,
        top: 'calc(50% - 2px)'
    }
}));
