// Material UI Components
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    graphContainer: {
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%'
    },
    paper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '35%',
        marginRight: '12px',
        height: '100%'
    },
    title: {
        color: '#737373',
        fontSize: 'medium',
        marginTop: '5px'
    }
}));
