// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Material UI Components
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// Styles
import { useStyles } from 'components/pagination/CustomTablePagination.style';

const CustomTablePagination = React.memo(({ count, onPageChange, page, rowsPerPage }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const handleFirstPageButtonClick = event => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onPageChange(
            event,
            Math.max(0, Math.ceil(count / rowsPerPage) - 1),
        );
    };

    const createMenu = () => {
        const menu = [];
        const numberOfPage = Math.ceil(count / rowsPerPage) + 1;

        // Outer loop to create parent
        for (let i = 1; i < numberOfPage; i++) {
            //Create the parent and add the children
            menu.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
        }
        return menu;
    };

    const handleChangePage = event => {
        onPageChange(event, event.target.value - 1);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label='First Page'
                size='large'>
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label='Previous Page'
                size='large'>
                <KeyboardArrowLeft />
            </IconButton>
            <Select
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={page + 1}
                onChange={handleChangePage}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 200,
                        },
                    },
                }}
                variant='standard'
            >
                {createMenu()}
            </Select>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='Next Page'
                size='large'>
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='Last Page'
                size='large'>
                <LastPageIcon />
            </IconButton>
        </div>
    );
});

CustomTablePagination.propTypes = {
    count: PropTypes.number,
    onPageChange: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number
};

export default CustomTablePagination;
