// React
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';
// Stores
import UserStore from 'stores/UserStore';
import PricingEngineStore from 'stores/PricingEngineStore';
import AccountStore from 'stores/AccountStore';
// Action
import ItemAction from 'actions/ItemAction';
// Caroussel
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// Locales
import translate from 'locales/i18n';
// Material UI Components
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import ExportIcon from '@mui/icons-material/CloudDownload';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import IconRefresh from '@mui/icons-material/Cached';
import IconButton from '@mui/material/IconButton';
// Styles
import { useStyles } from 'components/pages/PricingEngineAnalyzePage/PricingEngineAnalyzePage.style';
// PCP Components
import CustomTablePagination from 'components/pagination/CustomTablePagination';
import RepricedItemsTable from 'components/table/RepricedItemsTable/RepricedItemsTable';
import DialogExportData from 'components/dialog/DialogExportData/DialogExportData';
import DialogInfo from 'components/dialog/DialogInfo/DialogInfo';
import PricingEngineKpiSheet from 'components/sheet/PricingEngineKpiSheet/PricingEngineKpiSheet';
import { CustomSwitch } from 'components/commons/CustomSwitch/CustomSwitch';
import StockFilter from 'components/filter/pricingEngine/StockFilter/StockFilter';
import RulesFilter from 'components/filter/pricingEngine/RulesFilter/RulesFilter';
import CategoryFilter from 'components/filter/pricingEngine/CategoryFilter/CategoryFilter';
import RepricedPricesGroupsFilter from 'components/filter/pricingEngine/RepricedPricesGroupsFilter/RepricedPricesGroupsFilter';
import LeadershipFilter from 'components/filter/pricingEngine/LeadershipFilter/LeadershipFilter';
import PriceBasketsFilter from 'components/filter/pricingEngine/PriceBasketsFilter/PriceBasketsFilter';
// Theme
import { useTheme } from '@mui/styles';
//Facade
import Facade from 'core/Facade';
//Icons
import barChartIcon from 'assets/img/svg/bar_chart.svg';
import euroIcon from 'assets/img/svg/euro.svg';
import boxIcon from 'assets/img/svg/box.svg';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4500, min: 2900 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 2900, min: 1600 },
        items: 4
    },
    smallDesktop: {
        breakpoint: { max: 1600, min: 1400 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1400, min: 600 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1
    }
};

const PricingEngineAnalyzePage = () => {
    const [countItems, setCountItems] = useState(PricingEngineStore.count);
    const [items, setItems] = useState(PricingEngineStore.getRepricedItems());
    const [loadingItems, setLoadingItems] = useState(true);
    const [hideFilters, setHideFilters] = useState(false);
    const [websites, setWebsites] = useState(PricingEngineStore.getWebsites());
    const [repricedPricesGroups, setRepricedPricesGroups] = useState(PricingEngineStore.getRepricedPricesGroups());
    const [pricesBaskets, setPricesBaskets] = useState(PricingEngineStore.getPricesBaskets());
    const [categories, setCategories] = useState(PricingEngineStore.getCategories());
    const [rulesDistribution, setRulesDistribution] = useState(PricingEngineStore.getRulesDistribution());
    const [dialogExportDataOpen, setDialogExportDataOpen] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [dialogTutorialOpen, setDialogTutorialOpen] = useState(!localStorage.getItem('pricing_engine_tutorial'));
    const [positioningKpis, setPositioningKpis] = useState(PricingEngineStore.getPositioningKpis());
    const [params, setParams] = useState(PricingEngineStore.getParams());
    const [hideKpis, setHideKpis] = useState(Facade.getUrlParam('hide_kpis') === 'true');
    const [hideStocks, setHideStocks] = useState(Facade.getUrlParam('hide_stocks') === 'true');
    const [hidePrices, setHidePrices] = useState(Facade.getUrlParam('hide_prices') === 'true');
    const classes = useStyles();
    const theme = useTheme();

    useEffect(() => {
        PricingEngineStore.addChangeListener(onPricingEngineStoreChange);
        if (UserStore.isLogged()) {
            searchItems();
        }

        return () => {
            PricingEngineStore.removeChangeListener(onPricingEngineStoreChange);
        };
    }, []);

    useEffect(() => {
        if (hideStocks) {
            setHidePrices(false);
        }
    }, [hidePrices, hideStocks]);

    useEffect(() => searchItems(), [params.page, params.pageSize]);
    useEffect(() => {
        if (params.page !== 1) {
            params.page = 1;
            setParams({ ...params });
        } else {
            searchItems();
        }
    }, [params.repricedPricesGroups, params.categories, params.stocksBaskets, params.leaderships, params.pricesBaskets, params.rules]);

    const onPricingEngineStoreChange = () => {
        setCountItems(PricingEngineStore.count);
        setItems(PricingEngineStore.getRepricedItems());
        setPositioningKpis(PricingEngineStore.getPositioningKpis());
        setLoadingItems(PricingEngineStore.isLoadingItems());
        setWebsites(PricingEngineStore.getWebsites());
        setCategories(PricingEngineStore.getCategories());
        setRulesDistribution(PricingEngineStore.getRulesDistribution());
        setRepricedPricesGroups(PricingEngineStore.getRepricedPricesGroups());
        setPricesBaskets(PricingEngineStore.getPricesBaskets());
    };

    const searchItems = () => ItemAction.getRepricedItems({
        accountId: AccountStore.getCurrentAccount().getId(),
        date: params.date,
        page: params.page,
        pageSize: params.pageSize,
        searchText: params.searchText,
        repricedPricesGroups: params.repricedPricesGroups,
        categories: params.categories,
        stocksBaskets: params.stocksBaskets,
        leaderships: params.leaderships,
        pricesBaskets: params.pricesBaskets,
        rules: params.rules,
    });

    const handleChangeRowsPerPage = event => {
        if (countItems < event.target.value * params.page) {
            params.page = Math.ceil(countItems / event.target.value);
        } else if (Math.ceil((params.page - 1) * params.pageSize / event.target.value > 0)) {
            params.page = Math.ceil((params.page - 1) * params.pageSize / event.target.value);
        }

        params.pageSize = event.target.value;
        setParams({ ...params });
    };

    const renderDialogExportData = () =>
        <DialogExportData
            open={dialogExportDataOpen}
            close={() => setDialogExportDataOpen(false)}
            exportData={(options) => {
                setIsExporting(true);
                ItemAction.exportRepricedItems(options, {
                    date: params.date, accountId: AccountStore.getCurrentAccount().getId(),
                    searchText: params.searchText,
                    repricedPricesGroups: params.repricedPricesGroups
                })
                    .then(() => setIsExporting(false));
            }}
            isLoadingExport={isExporting} />;

    const renderDialogTutorial = () => <DialogInfo
        open={dialogTutorialOpen}
        close={() => {
            setDialogTutorialOpen(false);
            localStorage.setItem('pricing_engine_tutorial', true);
        }}
        title={translate.i18n('WELCOME_ON_PRICING_ENGINE')}>
        <div className={classes.dialogInfoText}>{translate.i18n('PRICING_ENGINE_WARNING')}</div>
        <div className={classes.dialogInfoText}>{translate.i18n('SOME_INFORMATIONS')}</div>
        <div className={classes.dialogInfoText}>{translate.i18n('FIRST_COLUMN_IS_YOURS')}</div>
        <div className={classes.dialogInfoText}>{translate.i18n('SECOND_COLUMN_IS_PRICING_ENGINE')}</div>
        <div className={classes.dialogInfoText}>{translate.i18n('LEGEND')}</div>
        <div className={classes.dialogSubInfoText}><span className={classes.dialogInfoPrice}><ArrowUpwardIcon style={{ transform: 'rotate(45deg)' }} />90€</span>{translate.i18n('ICON_INDICATE_REPRICED_PRICE_AND_VARIATION')}</div>
        <div className={classes.dialogSubInfoText}><InfoIcon style={{ color: theme.palette.primary.dark, marginRight: '6px' }} />{translate.i18n('ICON_INDICATE_INFORMATIONS')}</div>
        <div className={classes.dialogSubInfoText}><ErrorIcon style={{ color: theme.palette.red.main, marginRight: '6px' }} />{translate.i18n('ICON_INDICATE_REPRICING_ERROR')}</div>
        <div className={classes.dialogInfoText}>{translate.i18n('EACH_ITEM_CAN_HAVE_A_RULE')}</div>
    </DialogInfo>;

    return <div className={classes.root}>
        <div className={classes.header}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    renderInput={(props) => <TextField {...props} size='small' classes={{ root: classes.datePicker }} />}
                    disabled={loadingItems}
                    label={translate.i18n('DATE')}
                    value={params.date}
                    onChange={date => setParams(Object.assign(params, { date }))}
                    inputVariant='outlined'
                    inputFormat='dd/MM/yyyy'
                    margin={'dense'}
                    maxDate={new Date()}
                />
            </LocalizationProvider>
            <TextField
                id='searchfield'
                className={classes.searchBar}
                disabled={loadingItems}
                variant='outlined'
                value={params.searchText}
                onChange={(event) => {
                    params.searchText = event.target.value;
                    setParams(Object.assign({}, params));
                }}
                onKeyPress={(event) => event.key === 'Enter' && searchItems()}
                placeholder={'Article name, EAN, etc...'}
                size='small'
                InputProps={{
                    startAdornment: <InputAdornment position='start'><SearchIcon className={classes.searchIcon} /></InputAdornment>
                }}
            />
            <IconButton
                onClick={() => {
                    setParams({
                        page: 1,
                        pageSize: 25,
                        date: new Date(),
                        categories: [],
                        stocksBaskets: [],
                        repricedPricesGroups: [],
                        rules: [],
                        leaderships: {
                            current: [],
                            withRepricing: []
                        },
                        pricesBaskets: {
                            current: [],
                            withRepricing: []
                        },
                        searchText: ''
                    });
                }}
                color='primary'
                title={translate.i18n('RESET_FILTERS')}
                disabled={loadingItems}
                size='medium'>
                <IconRefresh />
            </IconButton>
            <Link className={classes.link} to={'/rules'}>
                <Button
                    variant='outlined'
                    color='primary'
                    size='medium'
                    className={classes.button}
                    endIcon={<ArrowRightIcon />}
                >
                    {translate.i18n('LIBRARY')}
                </Button>
            </Link>
        </div>
        <div className={classes.tableTitle}>
            <div>
                {translate.i18n('POSITIONING_KPIS.TITLE')}
            </div>
            <CustomSwitch
                handleChange={() => {
                    setHideKpis(!hideKpis);
                    Facade.setUrlParam('hide_kpis', !hideKpis);
                }}
                checked={!hideKpis}
                checkedIconStyle={{
                    top: '0px',
                    left: '0px',
                }}
                unCheckedIconStyle={{
                    top: '0px',
                    left: '0px',
                }}
                checkedIcon={barChartIcon}
                unCheckedIcon={barChartIcon}
                trackColor={'#1890a552'}
                roundColor={theme.palette.primary.main}
            />
        </div>
        {!hideKpis &&
            <PricingEngineKpiSheet positioningKpis={positioningKpis} sitesLength={websites.length} />
        }
        <div className={classes.tableTitle}>
            <div>
                {translate.i18n('FILTERS')}
            </div>
            <div>
                <CustomSwitch
                    handleChange={() => {
                        setHideFilters(!hideFilters);
                        Facade.setUrlParam('hide_filters', !hideFilters);
                    }}
                    checked={!hideFilters}
                    checkedIconStyle={{
                        top: '0px',
                        left: '0px',
                    }}
                    unCheckedIconStyle={{
                        top: '0px',
                        left: '0px',
                    }}
                    checkedIcon={barChartIcon}
                    unCheckedIcon={barChartIcon}
                    trackColor={'#1890a552'}
                    roundColor={theme.palette.primary.main}
                />
            </div>
        </div>
        <div className={classNames(classes.filtersBottomContainer, hideFilters && classes.hide)}>
            <Carousel
                swipeable={true}
                draggable={false}
                responsive={responsive}
                keyBoardControl={true}
                transitionDuration={300}
                removeArrowOnDeviceType={['mobile']}
                itemClass={classes.itemClass}
                sliderClass={classes.sliderClass}
                containerClass={classes.containerClass}
                autoPlay={false}
                shouldResetAutoplay={false}
            >
                <RepricedPricesGroupsFilter
                    repricedPricesGroups={repricedPricesGroups}
                    selectRepricedPricesGroups={id => {
                        if (params.repricedPricesGroups.indexOf(id) === -1) {
                            params.repricedPricesGroups.push(id);
                        } else {
                            params.repricedPricesGroups.splice(params.repricedPricesGroups.indexOf(id), 1);
                        }
                        params.repricedPricesGroups = params.repricedPricesGroups.slice(0);
                        setParams(Object.assign({}, params));
                    }}
                    reset={() => {
                        params.repricedPricesGroups = [];
                        setParams(Object.assign({}, params));
                    }}
                    isLoading={loadingItems}
                />
                <RulesFilter
                    rules={rulesDistribution}
                    selectRule={ruleId => {
                        params.pageIndex = 1;
                        let isSelected = false;

                        params.rules.forEach((rule, key) => {
                            if (rule === ruleId) {
                                params.rules.splice(key, 1);
                                isSelected = true;
                            }
                        });

                        if (!isSelected) params.rules.push(ruleId);

                        params.rules = params.rules.slice(0);
                        setParams(Object.assign({}, params));
                    }}
                    reset={() => {
                        params.rules = [];
                        setParams(Object.assign({}, params));
                    }}
                    isLoading={loadingItems || rulesDistribution.length < 1} />
                <PriceBasketsFilter
                    pricesBaskets={pricesBaskets}
                    selectPricesBaskets={(pricesBasketId, type) => {
                        params.pageIndex = 1;
                        let isSelected = false;

                        params.pricesBaskets[type].forEach((pricesBasket, key) => {
                            if (pricesBasket === pricesBasketId) {
                                params.pricesBaskets[type].splice(key, 1);
                                isSelected = true;
                            }
                        });

                        if (!isSelected) params.pricesBaskets[type].push(pricesBasketId);

                        params.pricesBaskets = Object.assign({}, params.pricesBaskets);
                        setParams(Object.assign({}, params));
                    }}
                    reset={() => {
                        params.pricesBaskets = {
                            current: [],
                            withRepricing: []
                        };
                        setParams(Object.assign({}, params));
                    }}
                    pricesBasketsSelected={params.pricesBaskets}
                    isLoading={loadingItems || websites.length < 1} />
                <LeadershipFilter
                    websites={websites}
                    selectLeadership={(leadershipId, websiteId, type) => {
                        params.pageIndex = 1;
                        let isSelected = false;
                        params.leaderships[type].forEach((leadership, key) => {
                            if (leadership[0] === leadershipId && leadership[1] === websiteId) {
                                params.leaderships[type].splice(key, 1);
                                isSelected = true;
                            }
                        });

                        if (!isSelected) params.leaderships[type].push([leadershipId, websiteId]);

                        params.leaderships = Object.assign({}, params.leaderships);
                        setParams(Object.assign({}, params));
                    }}
                    reset={() => {
                        params.leaderships = {
                            current: [],
                            withRepricing: []
                        };
                        setParams(Object.assign({}, params));
                    }}
                    isLoading={loadingItems} />
                <CategoryFilter categories={categories}
                    selectCategory={id => {
                        if (params.categories.indexOf(id) === -1) {
                            params.categories.push(id);
                        } else {
                            params.categories.splice(params.categories.indexOf(id), 1);
                        }
                        params.categories = params.categories.slice(0);
                        setParams(Object.assign({}, params));
                    }}
                    reset={() => {
                        params.categories = [];
                        setParams(Object.assign({}, params));
                    }}
                    categoriesVisible={params.categories}
                    isLoading={loadingItems} />
                <StockFilter
                    websites={websites}
                    selectStocksBaskets={(stocksBasketsId, websiteId) => {
                        params.pageIndex = 1;
                        let isSelected = false;

                        params.stocksBaskets.forEach((stocksBaskets, key) => {
                            if (stocksBaskets[0] === stocksBasketsId && stocksBaskets[1] === websiteId) {
                                params.stocksBaskets.splice(key, 1);
                                isSelected = true;
                            }
                        });

                        if (!isSelected) params.stocksBaskets.push([stocksBasketsId, websiteId]);

                        params.stocksBaskets = params.stocksBaskets.slice(0);
                        setParams(Object.assign({}, params));
                    }}
                    reset={() => {
                        params.stocksBaskets = [];
                        setParams(Object.assign({}, params));
                    }}
                    isLoading={loadingItems || websites.length < 1} />
            </Carousel>
        </div>
        <div className={classes.tableTitle}>
            <div>{translate.i18n('LIST_OF_REPRICED_ITEMS')}</div>
            <div>
                <CustomSwitch
                    handleChange={() => {
                        setHideStocks(!hideStocks);
                        Facade.setUrlParam('hide_stocks', !hideStocks);
                    }}
                    checked={!hideStocks}
                    checkedIconStyle={{
                        top: '1px',
                        left: '1px',
                    }}
                    unCheckedIconStyle={{
                        top: '1px',
                        left: '1px',
                    }}
                    checkedIcon={boxIcon}
                    unCheckedIcon={boxIcon}
                    trackColor={'#1890a552'}
                    roundColor={theme.palette.primary.main} />
                <CustomSwitch
                    handleChange={() => {
                        setHidePrices(!hidePrices);
                        Facade.setUrlParam('hide_prices', !hidePrices);
                    }}
                    checked={!hidePrices}
                    checkedIconStyle={{
                        top: '3px',
                        left: '1px',
                    }}
                    unCheckedIconStyle={{
                        top: '3px',
                        left: '1px',
                    }}
                    checkedIcon={euroIcon}
                    unCheckedIcon={euroIcon}
                    trackColor={'#1890a552'}
                    roundColor={theme.palette.primary.main} />
            </div>
        </div>
        <div className={classes.paperContainer}>
            <Paper elevation={3} style={{ position: !loadingItems && 'relative', height: '100%' }}>
                <SimpleBar className={classes.tableContainer}>
                    <RepricedItemsTable
                        loadingItems={loadingItems}
                        items={items}
                        websites={websites}
                        hideStocks={hideStocks}
                        hidePrices={hidePrices}
                    />
                </SimpleBar>
            </Paper>
        </div>
        <div className={classes.footer}>
            <Table className={classes.tablePagination}>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            className={classes.pagination}
                            colSpan={3}
                            count={countItems}
                            rowsPerPage={params.pageSize}
                            page={params.page - 1}
                            onPageChange={(event, p) => {
                                params.page = p + 1;
                                setParams({ ...params });
                            }}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={translate.i18n('ROW_PER_PAGE')}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            labelDisplayedRows={({ from, to, count }) => from + '-' + to + ' ' + translate.i18n('OF') + ' ' + count}
                            ActionsComponent={CustomTablePagination}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
            <Link className={classes.link} to={'/rule'}>
                <Button
                    variant='text'
                    color='primary'
                    size='small'
                    className={classes.button}
                    startIcon={<AddIcon />}
                >
                    {translate.i18n('ADD_RULE')}
                </Button>
            </Link>
            <Button
                variant='text'
                color='primary'
                size='small'
                className={classes.button}
                startIcon={<ExportIcon />}
                onClick={() => setDialogExportDataOpen(true)}
                style={{ marginLeft: '4px' }}
            >
                {translate.i18n('EXPORT_DATA')}
            </Button>
        </div>
        {renderDialogExportData()}
        {renderDialogTutorial()}
    </div>;
};

export default PricingEngineAnalyzePage;
