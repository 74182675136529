// React
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';
// Facade
import Facade from 'core/Facade';
// Material UI Components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownArrowIcon from '@mui/icons-material/CallReceived';
import UpArrowIcon from '@mui/icons-material/CallMade';
// Util
import { createTable } from 'components/table/PerformanceTable/PerformanceTable.util';
// Style
import { useStyles } from 'components/table/PerformanceTable/PerformanceTable.style';

export default function PerformanceTable({ sites, from, to }) {
    const classes = useStyles();

    if (sites.length === 0) return null;

    const performancesFormatted = createTable(sites);

    return (
        <Paper className={classes.root}>
            <SimpleBar style={{ height: '100%', width: '100%' }}>
                <Table className={classes.table} aria-labelledby='tableTitle'>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell}>Date</TableCell>
                            {sites.map((site, key) => <TableCell title={Facade.siteDisplayName(site.name)} key={key} align='center' className={classes.tableHeadCell} style={{ color: site.color }}>{Facade.siteDisplayName(site.name)}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {performancesFormatted && performancesFormatted.map((performance, key) =>
                            <TableRow
                                hover
                                key={key}
                            >
                                <TableCell key={key} style={{ borderBottom: (key === performancesFormatted.length - 1) && '1px solid #FFFFFF' }}>{Facade.formatDate(new Date(performance.date))}</TableCell>
                                {sites.map((site, i) => {
                                    if (performance[site.name])
                                        return <TableCell key={i} align='center' style={{ borderBottom: (key === performancesFormatted.length - 1) && '1px solid #FFFFFF' }}>{performance[site.name]}</TableCell>;
                                    return <TableCell key={i} align='center' style={{ borderBottom: (key === performancesFormatted.length - 1) && '1px solid #FFFFFF' }}>-</TableCell>;
                                })}
                            </TableRow>)}
                        <TableRow hover className={classes.lastRow}>
                            <TableCell className={classNames(classes.tableBottomCell, classes.leftCell)} title={'Evolution: ' + Facade.formatDate(from) + ' - ' + Facade.formatDate(to)}>{'Evolution: ' + Facade.formatDate(from) + ' - ' + Facade.formatDate(to)}</TableCell>
                            {sites.map((site, key) => {
                                if (!site.performances[site.performances.length - 1])
                                    return (
                                        <TableCell key={key} align='center' className={classes.tableBottomCell}>
                                            -
                                        </TableCell>
                                    );

                                const value = ((site.performances[site.performances.length - 1].count / site.performances[0].count) - 1) * 100;
                                return (
                                    <TableCell key={key} align='center' className={classes.tableBottomCell}>
                                        {value.toFixed(0) + '%'}
                                        {value < 0 ?
                                            <DownArrowIcon className={classNames(classes.bottomCellIcon, classes.redIcon)} />
                                            :
                                            <UpArrowIcon className={classNames(classes.bottomCellIcon, classes.greenIcon, value === 0 && classes.hide)} />
                                        }
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </SimpleBar>
        </Paper >
    );
}

PerformanceTable.propTypes = {
    sites: PropTypes.array,
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date)
};
