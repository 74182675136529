import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Stores
import UserStore from 'stores/UserStore';
import ItemStore from 'stores/ItemStore';
import AccountStore from 'stores/AccountStore';
import CategoryStore from 'stores/CategoryStore';
import LayoutStore from 'stores/LayoutStore';
// Material UI
import withStyles from '@mui/styles/withStyles';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import EventBusyIcon from '@mui/icons-material/EventBusy';
// Action
import ItemAction from 'actions/ItemAction';
import CategoryAction from 'actions/CategoryAction';
// Style
import { styles } from 'components/pages/ProductPage/ProductPage.style';
// PCP Component
import PriceSection from 'components/chart/PricePositionChart/PricePositionChart';
import { ChartSection } from 'components/chart/PriceHistoryChart/PriceHistoryChart';
import EditProductSheet from 'components/sheet/EditProductSheet/EditProductSheet';
import InfoSection from 'components/sheet/ProductInfoSheet/ProductInfoSheet';
import DialogConfirm from 'components/dialog/DialogConfirm/DialogConfirm';
import DialogAlert from 'components/dialog/DialogAlert/DialogAlert';
import Loader from 'components/commons/Loader/Loader';
import DialogPrint from 'components/dialog/DialogPrint/DialogPrint';
import DialogManageCategories from 'components/dialog/DialogManageCategories/DialogManageCategories';
// Util
import { formatData, getPriceStats, validItem } from 'components/pages/ProductPage/ProductPage.util';
// Locales
import translate from 'locales/i18n';
// Facade
import Facade from 'core/Facade';
// Assets
import NoImageFound from 'assets/img/no-image.png';
//Images
import performanceChartIcon from 'assets/img/svg/performanceChart.js';
import pricingEngineIcon from 'assets/img/svg/pricingEngine.js';
import editProductIcon from 'assets/img/svg/editProduct.js';
import printIcon from 'assets/img/svg/print.js';
import ProductRulesSheet from 'components/sheet/ProductRulesSheet/ProductRulesSheet';
// History
import browserHistory from 'history.js';

class Product extends React.Component {

    initFrom = () => {
        const from = new Date();
        from.setDate(from.getDate() - 1);
        if (Math.floor((ItemStore.getParams().from - from) / (1000 * 60 * 60 * 24)) === -1)
            return new Date(from.setDate(from.getDate() - 30));
        return ItemStore.getParams().from;
    };

    state = {
        categories: CategoryStore.getCategories(),
        from: this.initFrom(),
        to: ItemStore.getParams().to,
        currentItem: ItemStore.getCurrentItem(),
        history: ItemStore.getHistory(),
        isLoadingHistory: ItemStore.getLoadingHistory(),
        priceStats: null,
        dialogConfirmSaveOpen: false,
        dialogConfirmDeleteOpen: false,
        dialogConfirmDeleteHistoryOpen: false,
        dialogPrintOpen: false,
        dialogManageCategories: false,
        mode: 1,
        blur: UserStore.getCurrentUser().isSuperUser() ? LayoutStore.getBlur() : false,
        pricingEngineRights: AccountStore.getCurrentAccount().getRight('pricing_engine')
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
        match: PropTypes.object
    };

    componentDidMount() {
        ItemStore.addChangeListener(this.onItemStoreChange);
        CategoryStore.addChangeListener(this.onCategoryStoreChange);
        UserStore.getCurrentUser().isSuperUser() ? LayoutStore.addChangeListener(this.onLayoutStoreChange) : null;

        if (UserStore.isLogged()) {
            this.getInitialData();
        }

        this.mounted = true;
    }

    componentWillUnmount() {
        ItemStore.removeChangeListener(this.onItemStoreChange);
        CategoryStore.removeChangeListener(this.onCategoryStoreChange);
        UserStore.getCurrentUser().isSuperUser() ? LayoutStore.removeChangeListener(this.onLayoutStoreChange) : null;
        this.mounted = false;
    }

    getInitialData() {
        const params = ItemStore.getParams();
        let from = new Date();
        from.setDate(from.getDate() - 1);
        from = Math.floor((params.from - from) / (1000 * 60 * 60 * 24)) === -1 ? new Date(from.setDate(from.getDate() - 30)) : params.from;

        const options = {
            itemId: this.props.match.params.id,
            accountId: AccountStore.getCurrentAccount().getId(),
            from,
            to: params.to
        };
        this.currentItem && this.handleStartDate(from);
        this.currentItem && this.handleEndDate(params.to);
        ItemAction.getProduct(options).then(null, () => browserHistory.push('/smart-price-extractor'));
        ItemAction.getHistory(options);
    }

    /*
     * Store change methods
     */
    onItemStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            currentItem: ItemStore.getCurrentItem(),
            history: ItemStore.getHistory(),
            isLoadingHistory: ItemStore.getLoadingHistory(),
        }, () => {
            this.processPriceStats();
        });
    };

    onLayoutStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            blur: LayoutStore.getBlur()
        });
    };

    onCategoryStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            categories: CategoryStore.getCategories(),
        });
    };

    handleStartDate = (date) => {
        this.setState({ from: date }, () => this.updateData());
    };

    handleEndDate = (date) => {
        this.setState({ to: date }, () => this.updateData());
    };

    handleDeleteItemDialog = () => {
        this.setState({
            dialogConfirmDeleteOpen: true
        });
    };

    handleSaveItemDialog = () => {
        this.setState({
            dialogConfirmSaveOpen: true
        });
    };

    handleDeleteHistoryAlert = () => {
        this.setState({
            dialogConfirmDeleteHistoryOpen: true
        });
    };

    handleManageCategories = () => {
        this.setState({
            dialogManageCategories: true
        });
    };

    updateScrapingData = (key, siteId, value) => {
        const { currentItem } = this.state;

        let oldValue = null;
        // replace wanted value
        currentItem.scrapingData = currentItem.scrapingData.map(sd => {
            if (sd.siteId === siteId) {
                oldValue = sd[key];
                sd[key] = value;
            }

            return sd;
        });

        // Check if status is changed from 'SOLD' OR 'NV_BELL TO 'NV' OR 'UNDEFINED'
        // OR if Url is changed from something to undefined then alert user that history will be destroyed
        if (oldValue) {
            if (key === 'status') {
                if ((oldValue === 'SOLD' || oldValue === 'NV_BELL') && (value === 'NV' || value === '')) {
                    this.handleDeleteHistoryAlert();
                } else if (oldValue !== 'NV_BELL' && value === 'NV_BELL') {
                    currentItem.scrapingData = currentItem.scrapingData.map(sd => {
                        if (sd.siteId === siteId) {
                            const date = new Date();
                            const result = date.toLocaleDateString('fr-FR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            });
                            sd.nvBellParams = {
                                delayInDays: 30,
                                displayDate: result,
                                insertDate: result
                            };
                        }

                        return sd;
                    });
                }
            } else if (key === 'url') {
                if (oldValue && value === '') {
                    this.handleDeleteHistoryAlert();
                }
            }
        }

        // Update State
        this.setState({ currentItem });
    };

    updateItem = (key, value, onlyNum = false) => {
        if (onlyNum && !Facade.isDigit(value) && value !== '') return;
        const { currentItem } = this.state;
        if (key === 'category') {
            const categorie = this.state.categories.find(el => el.id === value);
            currentItem[key] = categorie;
        } else currentItem[key] = value;
        this.setState({ currentItem });
    };

    updateData() {
        const options = {
            from: this.state.from,
            to: this.state.to,
            itemId: this.state.currentItem.id,
            accountId: AccountStore.getCurrentAccount().getId()
        };

        ItemAction.getHistory(options);
        ItemAction.getProduct(options);
    }

    processPriceStats = () => {
        let priceStats = {
            minPrice: 0,
            maxPrice: 0,
            avgPrice: 0,
            currentPrice: 0,
        };

        if (!this.state.currentItem || !this.state.history || !this.state.currentItem.scrapingData) {
            return null;
        }

        // Recupération de tous les prix sans ceux = à -1 ou 0
        const filteredProducts = this.state.currentItem.scrapingData.filter((prod) => prod.scrapedData.price > 0);
        if (filteredProducts.length > 0) {
            priceStats = getPriceStats(filteredProducts, this.state.currentItem, AccountStore.getCurrentAccount(), this.state.history);
        }

        this.setState({
            priceStats,
        });
    };

    saveItem = () => {
        if (!validItem(this.state.currentItem, AccountStore.getCurrentAccount().isSupplier())) return;
        const itemToSave = this.state.currentItem;
        itemToSave.associatedPricingEngineRules = itemToSave.associatedPricingEngineRules.map(rule => rule.id);
        ItemAction.updateItem(itemToSave);
        this.closeDialog();
    };

    closeDialog = () => {
        this.setState({
            dialogConfirmSaveOpen: false,
            dialogConfirmDeleteOpen: false,
            dialogConfirmDeleteHistoryOpen: false,
            dialogPrintOpen: false,
            dialogManageCategories: false
        });
    };

    renderWaitingForDelete() {
        const { classes } = this.props;
        return this.state.waitingForDelete ?
            <div className={classes.waitingForDeleteContainer}>
                <div className={classes.waitingForDelete}>
                    <div>
                        <h1>En Cours de suppression</h1>
                    </div>
                    <div>
                        <Loader size='80px' />
                    </div>
                </div>
            </div>
            : <></>;
    }

    renderDialogConfirmDelete = () =>
        <DialogConfirm
            close={this.closeDialog}
            callback={() => {
                this.setState({ waitingForDelete: true });

                ItemAction.deleteProduct({
                    accountId: AccountStore.getCurrentAccount().getId(),
                    itemId: this.state.currentItem.id
                }, true).then(() => {
                    this.setState({ dialogConfirmDeleteOpen: false });
                    window.history.back();
                });
            }}
            open={this.state.dialogConfirmDeleteOpen}
            text={translate.i18n('CONFIRM_DELETE_ITEM')}
        />;

    renderDialogDeleteHistoryAlert = () =>
        <DialogAlert
            close={this.closeDialog}
            open={this.state.dialogConfirmDeleteHistoryOpen}
            text={translate.i18n('CONFIRM_DELETE_HISTORY')}
        />;

    renderDialogPrint = () =>
        <DialogPrint
            close={this.closeDialog}
            open={this.state.dialogPrintOpen}
            settings={[
                { name: 'Mise en page', value: 'Paysage' },
                { name: 'Mise à l\'échelle', value: 74 },
                { name: 'Marges', value: 'Minimum' },
                { name: 'Graphique d\'arrière-plan', value: 'Coché' }
            ]}
            callback={() => {
                this.closeDialog();
                setTimeout(() => window.print(), 300);
            }}
        />;

    renderDialogSaveConfirm = () =>
        <DialogConfirm
            close={this.closeDialog}
            callback={() => this.saveItem()}
            open={this.state.dialogConfirmSaveOpen}
            text={translate.i18n('CONFIRM_EDIT_ITEM')}
        />;

    renderDialogCategorySettings = () =>
        <DialogManageCategories
            close={this.closeDialog}
            open={this.state.dialogManageCategories}
            categories={this.state.categories}
        />;

    render() {
        const { classes } = this.props;
        const { currentItem, from, to, mode, history, isLoadingHistory, priceStats, categories, blur } = this.state;

        if (!currentItem && UserStore.isLogged())
            return null;

        const formattedDate = history ? formatData(currentItem.scrapingData, history) : null;
        const formattedDataLength = formattedDate?.data.map(oneFormattedDate => oneFormattedDate.data).flat().filter(data => data !== null).length;

        return <>
            <div className={classes.root}>
                {/* Left Bar */}
                <div className={classes.leftBar}>
                    <div style={{ height: '45px' }} className={classes.goBackButtonContainer}>
                        <div>
                            <IconButton
                                title='Smart Price Extractor'
                                placement='top'
                                className={classes.goBackButton}
                                onClick={() => window.history.back()}
                                size='large'>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.datePickerContainer}>
                        <div className={classes.datePicker}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    renderInput={(props) => <TextField {...props} size='small' classes={{ root: classes.datePickerFrom }} />}
                                    margin='dense'
                                    label={translate.i18n('FROM')}
                                    value={from}
                                    onChange={this.handleStartDate}
                                    inputVariant='outlined'
                                    inputFormat='dd/MM/yyyy'
                                    maxDate={new Date(to)}
                                />
                                <MobileDatePicker
                                    renderInput={(props) => <TextField {...props} size='small' classes={{ root: classes.datePickerTo }} />}
                                    margin='dense'
                                    label={translate.i18n('TO')}
                                    value={to}
                                    onChange={this.handleEndDate}
                                    inputVariant='outlined'
                                    inputFormat='dd/MM/yyyy'
                                    minDate={from}
                                    maxDate={new Date()}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className={classes.agendaIconCircle}></div>
                        <EventNoteIcon className={classes.agendaIcon} />
                    </div>
                    <div className={classes.productDetailsContainer}>
                        <div className={classes.productDetailsContainerTop}>
                            <div className={`${classes.productPictureContainer}`}>
                                <div className={classes.productPicturePaper}>
                                    <img
                                        src={`https://img.pricecomparator.pro/${currentItem.imgPath}?$${Date.now()}`}
                                        onError={(e) => {
                                            e.target.src = NoImageFound;
                                            e.target.onerror = null;
                                        }}
                                        className={classes.productPicture}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.buttonsContainer}>
                            <div
                                className={classNames(classes.productButton, classes.productClickable, mode === 1 && classes.selected)}
                                onClick={() => {
                                    this.state.mode !== 1 && this.setState({ mode: 1 }) && this.getInitialData();
                                }}
                            >
                                {performanceChartIcon('25', '25', this.state.mode === 1 ? 'white' : '#0E5562')}
                            </div>
                            <div
                                className={AccountStore.getCurrentAccount().getModules().pricing_engine ? classNames(classes.productButton, classes.productClickable, mode === 2 && classes.selected) : classes.productButtonDisabled}
                                title={this.state.pricingEngineRights ? 'Pricing Engine' : 'Pricing Engine (' + translate.i18n('YOU_DO_NOT_HAVE_ACCESS') + ')'}
                                onClick={() => this.state.pricingEngineRights ? this.setState({ mode: 2 }) : null}
                            >
                                {pricingEngineIcon('31', '31', this.state.mode === 2 ? 'white' : '#0E5562')}
                            </div>
                            <div
                                className={classNames(UserStore.getCurrentUser().isScrapingDataEditor() ? classNames(classes.productButton, classes.productClickable) : classes.productButtonDisabled, mode === 3 && classes.selected)}
                                onClick={() => {
                                    if (UserStore.getCurrentUser().isScrapingDataEditor() && this.state.mode !== 3) {
                                        CategoryAction.getCategories(AccountStore.getCurrentAccount().getId());
                                        this.setState({ mode: 3 });
                                    }
                                }}
                                title='Edition'
                            >
                                {editProductIcon('25', '25', this.state.mode === 3 ? 'white' : '#0E5562')}
                            </div>
                            <div
                                className={classNames(classes.productButton, classes.productClickable)}
                                onClick={() => this.setState({ mode: 1 }, () => this.setState({ dialogPrintOpen: true }))}
                                title='Imprimer'
                            >
                                {printIcon('25', '25', this.state.mode === 4 ? 'white' : '#0E5562')}
                            </div>
                        </div>
                    </div>
                    <div className={classes.bottomProductPart}>
                        <div className={classes.hideShadow}></div>
                        <div className={classes.infoSectionContainer}>
                            <InfoSection
                                currentItem={currentItem}
                                edit={mode === 3}
                                categories={categories}
                                updateItem={this.updateItem}
                                handleManageCategories={this.handleManageCategories}
                                blur={blur}
                            />
                        </div>
                    </div>
                </div>
                {/* Right Content */}
                <div className={classes.rightContent}>
                    { // If page is in mode 1, show charts
                        mode === 1 && formattedDate && formattedDataLength > 0 &&
                        <>
                            <PriceSection
                                classes={classes}
                                priceStats={priceStats}
                                supplierMode={AccountStore.getCurrentAccount().isSupplier()}
                                currentAccount={AccountStore.getCurrentAccount()}
                                currentItem={currentItem}
                                from={from}
                                to={to}
                            />
                            {history && !isLoadingHistory &&
                                <ChartSection
                                    classes={classes}
                                    data={formattedDate.data}
                                    date={formattedDate.minDate}
                                    currency={AccountStore.getCurrentAccount().currency}
                                    reset={() => this.getInitialData()}
                                    productName={currentItem.name}
                                />
                            }
                        </>
                    }
                    { //If page is in mode 2, show rules for the product
                        mode === 2 &&
                        <ProductRulesSheet
                            product={currentItem}
                        />
                    }
                    { // If page is in mode 3, show edit section
                        mode === 3 &&
                        <>
                            <EditProductSheet
                                currentItem={currentItem}
                                scrapingDatas={currentItem.scrapingData}
                                updateScrapingData={this.updateScrapingData}
                                resetData={() => {
                                    this.setState({ mode: 1 });
                                    this.getInitialData();
                                }}
                                dialogDelete={() => this.handleDeleteItemDialog()}
                                dialogSave={() => this.handleSaveItemDialog()}
                            />
                        </>
                    }
                    { // If there are no data, show a message
                        !formattedDate || formattedDataLength === 0 && mode === 1 &&
                        <div className={classes.errorNoHistoryContainer}>
                            <div className={classes.errorNoHistory}><EventBusyIcon className={classes.errorNoHistoryIcon} /> {translate.i18n('PRODUCT_PAGE_NO_HISTORY')}</div>
                        </div>
                    }
                </div>
                {this.renderDialogConfirmDelete()}
                {this.renderWaitingForDelete()}
                {this.renderDialogSaveConfirm()}
                {this.renderDialogDeleteHistoryAlert()}
                {this.renderDialogPrint()}
                {this.renderDialogCategorySettings()}
            </div>
        </>;
    }
}

export default withStyles(styles)(Product);
