// Abstract
import AbstractStore from 'core/abstracts/AbstractStore';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';

class TrendStore extends AbstractStore {

    constructor() {

        const CHANGE_EVENT = 'TREND_CHANGE_EVENT';
        const NAME = 'TREND_STORE';

        super(NAME, CHANGE_EVENT);

        Dispatcher.register(this._registerToActions.bind(this));

        this._initStore();
    }

    _initStore() {
        this.trendAnalyze = {};
        this.isLoading = false;
    }

    setIsLoading(isLoading) {
        this.isLoading = isLoading;
    }

    getIsLoading() {
        return this.isLoading;
    }

    setTrendAnalyze(trendAnalyze) {
        this.trendAnalyze = trendAnalyze;
    }

    getTrendAnalyze() {
        return this.trendAnalyze;
    }

    _registerToActions(action) {
        switch (action.actionType) {
        case Constants.USER_LOGOUT:
            this._initStore();
            break;
        case Constants.TRENDS_GET_START:
            this.setIsLoading(true);
            this._emitChange();
            break;
        case Constants.TRENDS_GET_END:
            this.setTrendAnalyze(action.value);
            this.setIsLoading(false);
            this._emitChange();
            break;
        case Constants.TRENDS_GET_ERROR:
            this.setIsLoading(false);
            this._emitChange();
            break;
        default:
            return false;
        }
    }
}

export default new TrendStore();
