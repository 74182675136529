const drawerWidth = 200;

export const styles = theme => ({
    '@media (max-width: 600px)': {
        hideMobile: {
            display: 'none'
        }
    },
    accountName: {
        fontSize: '16px',
        fontWeight: 500,
        margin: '6px'
    },
    appBar: {
        backgroundColor: theme.palette.primary.dark,
        backgroundImage: 'linear-gradient(to right, #0e5562, #2ab4ad)',
        transition: theme.transitions.create(['width', 'margin'], {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp
        }),
        zIndex: theme.zIndex.drawer + 1
    },
    appBarShift: {
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['width', 'margin'], {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.sharp
        }),
        width: `calc(100% - ${drawerWidth}px)`
    },
    avatarImg: {
        maxHeight: '60%',
        maxWidth: '60%',
        minHeight: '50%',
        minWidth: '50%'
    },
    avatarRoot: {
        backgroundColor: theme.palette.white.main,
        cursor: 'pointer',
        marginRight: '10px'
    },
    circle: {
        backgroundColor: 'white',
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'inline-block',
        height: '40px',
        margin: '5px 10px 5px 5px',
        textAlign: 'center',
        transition: 'linear 0.25s',
        verticalAlign: 'middle',
        width: '40px'
    },
    envLabel: {
        color: theme.palette.red.light,
        fontSize: '44px',
        fontWeight: '700',
        marginRight: '5%'
    },
    filledInput: {
        backgroundColor: theme.palette.background.default,
        maxHeight: 45
    },
    formControl: {
        margin: '10px'
    },
    hide: {
        display: 'none'
    },
    logoAccountButton: {
        height: '20px',
        marginTop: '10px',
        width: '20px'
    },
    menuButton: {
        cursor: 'pointer',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    menuItem: {
        alignItems: 'center',
        display: 'flex'
    },
    selectAccount: {
        backgroundColor: theme.palette.white.main
    },
    subText: {
        color: '#2AB4AD',
        marginLeft: '1px'
    },
    typography: {
        flex: 1
    },
    typographySubtitle: {
        color: theme.palette.green.contrastText,
        flex: 1,
        marginLeft: '140px',
        marginTop: '-7px'
    },
    blur: {
        filter: 'blur(0.2rem)'
    }
});
