// Locales
import translate from 'locales/i18n';

/**
  * Method which create data for a graph display
  *
  * @param groups <array<object>> array of repriced prices group
  * @returns <array<object>> Data object formatted to display graph
  */
export const createData = (groups, theme) => {
    if (groups.length === 0)
        return null;

    const data = {
        labels: groups.map(group => translate.i18n(group.name)),
        datasets: [{
            backgroundColor: [theme.palette.graph.five, theme.palette.graph.four, theme.palette.graph.three, theme.palette.grey.light, theme.palette.rulesRanks.five],
            borderColor: [theme.palette.graph.five, theme.palette.graph.four, theme.palette.graph.three, theme.palette.grey.light, theme.palette.rulesRanks.five],
            borderWidth: 1,
            data: groups.map(group => group.cardinality)
        }],
    };
    return data;
};

/**
  * Method which return the options for a graph display
  *
  * @returns <object> the options object
  */
export const getOptions = (count) => ({
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            backgroundColor: 'rgba(87, 87, 87, 0.92)',
            bodyFont: {
                size: 10
            },
            borderColor: 'rgba(87, 87, 87, 0.92)',
            callbacks: {
                label: context => {
                    const label = context.label || '';
                    if (context.raw && context.raw > 0 && count && count > 0) return `${label} : ${context.raw} (${(context.raw / count * 100).toFixed(2)}%)`;
                    return label;
                }
            },
            cornerRadius: 5,
            displayColors: false,
            padding: 5
        }
    },
    rtl: true
});
