import ScrapingData from 'core/entities/ScrapingDataObject';
import Category from 'core/entities/CategoryObject';
import PricingEngineInfo from 'core/entities/PricingEngineInfoObject';
import Rule from './RuleObject';

class Item {

    constructor(data) {
        this.id = data?.id;
        this.alertingLowPrice = data?.alerting_low_price;
        this.associatedPricingEngineRules = data?.associated_pricing_engine_rules?.map(rule => new Rule(rule));
        this.category = data && data.category ? new Category(data.category) : data.category;
        this.imgPath = data?.img_path;
        this.imgUrl = data?.img_url;
        this.margin = data?.margin;
        this.name = data?.name;
        this.purchasingPrice = data?.purchasing_price;
        this.rrp = data?.rrp;
        this.rrpAlertPercentage = data?.rrp_alert_percentage;
        this.scrapingData = data && data.scraping_data ? data.scraping_data.map(scrapingData => new ScrapingData(scrapingData)) : data?.scraping_data;
        this.pricingEngine = data && data.pricing_engine ? new PricingEngineInfo(data?.pricing_engine) : data?.pricing_engine;
        this.isLoading = data?.isLoading;
        this.cmsProductId = data?.cms_product_id;
        this.cmsLastKnownStock = data?.cms_last_known_stock;
        this.evaluation = data?.evaluation;
        this.customAttributes = data && data.custom_attributes ? data.custom_attributes : [];
        this.toMinPrice = data.to_min_price;
        this.toMaxPrice = data.to_max_price;
    }

    /**
     * METHODS
     */
    toJson() {
        return {
            category_id: this.category.id,
            img_url: this.imgUrl,
            name: this.name,
            purchasing_price: this.purchasingPrice ? Number(this.purchasingPrice) : undefined,
            rrp: this.rrp ? Number(this.rrp) : undefined,
            rrp_alert_percentage: this.rrpAlertPercentage ? Number(this.rrpAlertPercentage) : undefined,
            alerting_low_price: this.alertingLowPrice ? Number(this.alertingLowPrice) : undefined,
            cms_product_id: this.cmsProductId,
            cms_last_known_stock: this.cmsLastKnownStock ? Number(this.cmsLastKnownStock) : undefined,
            scraping_data: this.scrapingData ? this.scrapingData.map(sd => sd.toJson()) : undefined,
            pricing_engine_rule_ids: this.associatedPricingEngineRules,
            custom_attributes: this.customAttributes
        };
    }
}

export default Item;
