const styles = theme => ({
    categorySetting: {
        '&:hover': {
            cursor: 'pointer'
        },
    },
    formControl: {
        width: '100%',
        marginRight: '10px'
    },
    productCategory: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginTop: '10px',
        textAlign: 'right'
    },
    productEdit: {
        background: theme.palette.white.main,
        borderRadius: '5px',
        margin: '5px',
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    productInfos: {
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        borderRadius: '0 5px 5px 5px',
        padding: '15px'
    },
    productInfosText: {
        fontSize: '16px',
        marginTop: '5px',
        display: 'flex'
    },
    productName: {
        fontSize: '20px',
        fontWeight: 'bold'
    },
    productNameEdit: {
        color: theme.palette.black.main
    },
    productNameEditContainer: {
        width: '100%'
    },
    blur: {
        filter: 'blur(0.2rem)'
    }
});

export default styles;
