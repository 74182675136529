class Trend {
    constructor(data) {
        if (data) {
            this.accountEvolution = data.account_evolution;
            this.competitorsEvolution = data.competitors_evolution;

            this.accountAvgPrices = data.account_avg_prices;
            this.competitorsAvgPrices = data.competitors_avg_prices;

            this.accountTrendLine = data.account_trend_line;
            this.competitorsTrendLine = data.competitors_trend_line;

            this.sitesEvolution = data.sites_evolution;
            this.categoriesEvolution = data.categories_evolution;
        }
    }
}

export default Trend;
