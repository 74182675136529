// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Service
import PerformanceService from 'services/PerformanceService';
// Stores
import PerformanceStore from 'stores/PerformanceStore';
//Action
import AlertAction from 'actions/AlertAction';
//i18n
import translate from 'locales/i18n';
// Logger
import Logger from 'core/Logger';
// Entities
import Site from 'core/entities/SiteObject';

class PerformanceAction {

    /**
      * Method called to get performances data
      *
      * @param { accountId, from, to } options <object> the options to get performances
      */
    getPerformances(options) {
        if (!PerformanceStore.firstLoad) {
            Dispatcher.dispatch({
                actionType: Constants.PERFORMANCE_SEARCH_START,
                value: options
            });
        }

        PerformanceService.getPerformances(options).then(data => {
            Dispatcher.dispatch({
                actionType: Constants.PERFORMANCE_SEARCH_END,
                value: data.performances.map(d => {
                    const { site } = d;
                    site.performances = d.site_performances;
                    return new Site(site);
                })
            });
        }, err => {
            Logger.error(err);
            AlertAction.open('error', translate.i18n('ERROR_RETRIEVE_PERFORMANCES'), 'top-right');
        });
    }
}

export default new PerformanceAction();
