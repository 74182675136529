// React
import React from 'react';
import PropTypes from 'prop-types';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import { DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
// Locales
import translate from 'locales/i18n';
//Styles
import { useStyles } from 'components/dialog/DialogAbout/DialogAbout.style';

const DialogAbout = ({ open, close }) => {

    const classes = useStyles();

    return <Dialog
        open={open}
        onClose={close}
        maxWidth={false}
        aria-labelledby='responsive-dialog-title'>
        <DialogTitle className={classes.dialogTitle}>
            {translate.i18n('ABOUT_DIALOG.TITLE')}
        </DialogTitle>
        <DialogContent>
            <div className={classes.dialogContent}>
                <img src={'https://img.pricecomparator.pro/front/images/utils/montpellier.jpeg'} className={classes.cityImg} />
                <div className={classes.dialogContentRight}>
                    <img src={'https://img.pricecomparator.pro/front/images/utils/logo.png'} className={classes.logoImg} />
                    <div>
                        {translate.i18n('ABOUT_DIALOG.TEXT')}
                    </div>
                </div>
            </div>
            <div className={classes.dialogFooter}>
                {`${translate.i18n('ABOUT_DIALOG.CONTACT')} : support@pricecomparator.pro`}
            </div>
        </DialogContent>
        <DialogActions>
            <IconButton onClick={() => close()}>
                <CheckIcon />
            </IconButton>
        </DialogActions>
    </Dialog>;
};

DialogAbout.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func
};

export default DialogAbout;
