// React
import React from 'react';
import PropTypes from 'prop-types';
// i18n
import translate from 'locales/i18n';
// Material UI Components
import withStyles from '@mui/styles/withStyles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import IconHide from '@mui/icons-material/Visibility';
import IconUnhide from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
// Styles
import styles from 'components/sheet/EditStrategyRuleSheet/EditStrategyRuleSheet.style';
// Stores
import AccountStore from 'stores/AccountStore';

const stocks = ['en stock', 'sup. 48h', 'rupture', ''];

const EditStrategyRuleSheet = React.memo(({ classes, title, strategy, edit, sites, resume, hide, toggleHide, error }) =>
    <div className={classes.root}>
        <div className={classes.title}>
            {title}
            <div className={classes.titleBar}></div>
            <IconButton
                onClick={toggleHide}
                color='primary'
                title={translate.i18n('HIDE_STRATEGY')}
                size='large'>
                {hide ? <IconHide /> : <IconUnhide />}
            </IconButton>
        </div>
        <div className={classes.editContainer}>
            <div className={classes.header}>
                <div className={classes.bigLabel} style={{ width: '120px' }}>
                    {translate.i18n('I_WANT_TO_BE')}
                </div>
                <FormControl variant='outlined' className={classes.formControl} error={error === 'POSITIONING_IS_MISSING'} style={{ minWidth: '135px' }}>
                    <InputLabel>{'* ' + translate.i18n('POSITIONING')}</InputLabel>
                    <Select
                        value={strategy.positioning}
                        onChange={event => edit('positioning', event.target.value, () => edit('rounding', 'TO_PRICE'))}
                        label={'* ' + translate.i18n('POSITIONING')}
                        inputProps={{
                            name: 'Strategie',
                            className: classes.input
                        }}
                    >
                        <MenuItem value='NONE'>
                            <em>{translate.i18n('SELECT')}</em>
                        </MenuItem>
                        <MenuItem value={'MIN_PRICE'}>{translate.i18n('MIN_PRICE')}</MenuItem>
                        <MenuItem value={'AVG_PRICE'}>{translate.i18n('AVG_PRICE')}</MenuItem>
                        <MenuItem value={'MAX_PRICE'}>{translate.i18n('MAX_PRICE')}</MenuItem>
                        <MenuItem value={'SELF_ORIENTED_STRATEGY'}>{translate.i18n('SELF_ORIENTED_STRATEGY')}</MenuItem>
                    </Select>
                    {error === 'POSITIONING_IS_MISSING' ?
                        <FormHelperText>{translate.i18n(error)}</FormHelperText>
                        :
                        null
                    }
                </FormControl>
                {strategy.positioning === 'SELF_ORIENTED_STRATEGY' &&
                    <FormControl variant='outlined' className={classes.formControl} style={{ minWidth: '100px' }}>
                        <InputLabel>{translate.i18n('BASED_ON')}</InputLabel>
                        <Select
                            value={strategy.element}
                            onChange={event => edit('element', event.target.value)}
                            label={translate.i18n('BASED_ON')}
                            inputProps={{
                                name: translate.i18n('BASED_ON'),
                                className: classes.input
                            }}
                        >
                            <MenuItem value={'RRP'}>RRP</MenuItem>
                            <MenuItem value={'PURCHASING_PRICE'}>{translate.i18n('PURCHASING_PRICE')}</MenuItem>
                        </Select>
                    </FormControl>

                }
                <FormControl variant='outlined' className={classes.formControl} style={{ width: '70px' }}>
                    <Select
                        value={strategy.offset.sign}
                        onChange={event => edit('offset', {
                            value: strategy.offset.value,
                            mode: strategy.offset.mode,
                            sign: event.target.value
                        })}
                        inputProps={{
                            className: classes.input
                        }}
                    >
                        <MenuItem value={'+'}>+</MenuItem>
                        <MenuItem value={'-'}>-</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label={translate.i18n('VALUE')}
                    type='number'
                    value={strategy.offset.value}
                    onChange={event => {
                        edit('offset', {
                            value: event.target.value < 0 ? strategy.offset.mode : event.target.value,
                            mode: strategy.offset.mode,
                            sign: strategy.offset.sign
                        });
                    }}
                    variant='outlined'
                    className={classes.valueTextField}
                    inputProps={{
                        className: classes.input,
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                    }}
                />
                <FormControl variant='outlined' className={classes.formControl} style={{ width: '75px' }}>
                    <InputLabel>{translate.i18n('MODE')}</InputLabel>
                    <Select
                        value={strategy.offset.mode}
                        onChange={event => edit('offset', {
                            value: strategy.offset.value,
                            mode: event.target.value,
                            sign: strategy.offset.sign
                        })}
                        label={translate.i18n('MODE')}
                        inputProps={{
                            name: translate.i18n('MODE'),
                            className: classes.input
                        }}
                    >
                        <MenuItem value={'PERCENTAGE'}>%</MenuItem>
                        <MenuItem value={'VALUE'}>{AccountStore.getCurrentAccount().getCurrency()}</MenuItem>
                    </Select>
                </FormControl>
                {strategy.positioning === 'SELF_ORIENTED_STRATEGY' ?
                    <div className={classes.roundingContainer}>
                        <RadioGroup value={strategy.rounding} onChange={event => edit('rounding', event.target.value)} style={{ width: '100%', marginBottom: '8px' }}>
                            <div>
                                <FormControlLabel value='TO_PRICE' control={<Radio color='primary' />} label={translate.i18n('TO_PRICE')} classes={{ label: classes.smallRadioLabel }} />
                            </div>
                            <div className={classes.roundingSubContainer}>
                                <div className={classes.roundingSubSubContainer}>
                                    <FormControlLabel value='TO_LOWEST_9' control={<Radio color='primary' />} label={translate.i18n('TO_LOWEST_9')} classes={{ label: classes.smallRadioLabel }} />
                                    <FormControlLabel value='TO_LOWEST_09' control={<Radio color='primary' />} label={translate.i18n('TO_LOWEST_09')} classes={{ label: classes.smallRadioLabel }} />
                                    <FormControlLabel value='TO_LOWEST_099' control={<Radio color='primary' />} label={translate.i18n('TO_LOWEST_099')} classes={{ label: classes.smallRadioLabel }} />
                                </div>
                                <div className={classes.roundingSubSubContainer}>
                                    <FormControlLabel value='TO_HIGHEST_9' control={<Radio color='primary' />} label={translate.i18n('TO_HIGHEST_9')} classes={{ label: classes.smallRadioLabel }} />
                                    <FormControlLabel value='TO_HIGHEST_09' control={<Radio color='primary' />} label={translate.i18n('TO_HIGHEST_09')} classes={{ label: classes.smallRadioLabel }} />
                                    <FormControlLabel value='TO_HIGHEST_099' control={<Radio color='primary' />} label={translate.i18n('TO_HIGHEST_099')} classes={{ label: classes.smallRadioLabel }} />
                                </div>
                                <div className={classes.roundingSubSubContainer}>
                                    <FormControlLabel value='TO_NEAREST_9' control={<Radio color='primary' />} label={translate.i18n('TO_NEAREST_9')} classes={{ label: classes.smallRadioLabel }} />
                                    <FormControlLabel value='TO_NEAREST_09' control={<Radio color='primary' />} label={translate.i18n('TO_NEAREST_09')} classes={{ label: classes.smallRadioLabel }} />
                                    <FormControlLabel value='TO_NEAREST_099' control={<Radio color='primary' />} label={translate.i18n('TO_NEAREST_099')} classes={{ label: classes.smallRadioLabel }} />
                                </div>
                            </div>
                        </RadioGroup>
                    </div>
                    :
                    <div className={classes.bigLabel} style={{ width: 'calc(100% - 260px)' }}>
                        {resume}
                    </div>
                }
            </div>
            {!hide && !['NONE', 'SELF_ORIENTED_STRATEGY'].some(e => e === strategy.positioning) ?
                <div className={classes.onCompetitorsSettingsContainer}>
                    <div className={classes.subSettingsContainer} style={{ marginLeft: 0 }}>
                        <RadioGroup value={strategy.competitors} onChange={event => edit('competitors', event.target.value, () => edit('filteringSiteIds', []))} style={{ width: '100%', marginBottom: '8px' }}>
                            <FormControlLabel value='ALL_COMPETITORS' control={<Radio color='primary' />} label={translate.i18n('ALL_COMPETITORS')} classes={{ label: classes.smallRadioLabel }} />
                            <FormControlLabel value='SOME_COMPETITORS' control={<Radio color='primary' />} label={translate.i18n('SOME_COMPETITORS')} classes={{ label: classes.smallRadioLabel }} />
                        </RadioGroup>
                        <FormControl variant='outlined' className={classes.formControl} margin={'dense'} disabled={strategy.competitors === 'ALL_COMPETITORS'}>
                            <InputLabel>{translate.i18n('SELECT_COMPETITORS')}</InputLabel>
                            <Select
                                classes={{ root: classes.multipleSelect }}
                                multiple
                                value={strategy.filteringSiteIds}
                                onChange={event => edit('filteringSiteIds', event.target.value)}
                                input={<Input />}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 300,
                                        },
                                    },
                                }}
                                renderValue={(selected) =>
                                    <div className={classes.chips}>
                                        {selected.map((value) => <Chip key={value} label={sites.find(site => site.id === value).name} className={classes.chip} />)}
                                    </div>
                                }
                            >
                                {sites.filter(s => AccountStore.getCurrentAccount().isSupplier() || AccountStore.getCurrentAccount().getSiteId() !== s.id).map((site) =>
                                    <MenuItem key={site.id} value={site.id}>
                                        <Checkbox checked={strategy.filteringSiteIds.indexOf(site.id) > -1} />
                                        <ListItemText primary={site.name} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.subSettingsContainer}>
                        <RadioGroup value={strategy.stocks} onChange={event => edit('stocks', event.target.value, () => edit('filteringStocks', []))} style={{ width: '100%', marginBottom: '8px' }}>
                            <FormControlLabel value='ALL_STOCKS' control={<Radio color='primary' />} label={translate.i18n('ALL_STOCKS')} classes={{ label: classes.smallRadioLabel }} />
                            <FormControlLabel value='SOME_STOCKS' control={<Radio color='primary' />} label={translate.i18n('SOME_STOCKS')} classes={{ label: classes.smallRadioLabel }} />
                        </RadioGroup>
                        <FormControl variant='outlined' className={classes.formControl} margin={'dense'} disabled={strategy.stocks === 'ALL_STOCKS'}>
                            <InputLabel>{translate.i18n('SELECT_STOCKS')}</InputLabel>
                            <Select
                                classes={{ root: classes.multipleSelect }}
                                multiple
                                value={strategy.filteringStocks}
                                onChange={event => edit('filteringStocks', event.target.value)}
                                input={<Input />}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 350,
                                        },
                                    },
                                }}
                                renderValue={(selected) =>
                                    <div className={classes.chips}>
                                        {selected.map((value) => <Chip key={value} label={value === '' ? translate.i18n('UNSPECIFIED') : value} className={classes.chip} />)}
                                    </div>
                                }
                            >
                                {stocks.map((stock) =>
                                    <MenuItem key={stock} value={stock}>
                                        <Checkbox checked={strategy.filteringStocks.indexOf(stock) > -1} />
                                        <ListItemText primary={stock === '' ? translate.i18n('UNSPECIFIED') : stock} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.subSettingsContainer}>
                        <RadioGroup value={strategy.isLowLimitPrice} onChange={event => edit('isLowLimitPrice', event.target.value)} style={{ width: '100%', marginBottom: '8px' }}>
                            <FormControlLabel value={'WITHOUT_LOW_LIMIT'} control={<Radio color='primary' />} label={translate.i18n('WITHOUT_LOW_LIMIT')} classes={{ label: classes.smallRadioLabel }} />
                            <FormControlLabel value={'WITH_LOW_LIMIT'} control={<Radio color='primary' />} label={translate.i18n('WITH_LOW_LIMIT')} classes={{ label: classes.smallRadioLabel }} />
                        </RadioGroup>
                        <div className={classes.basedOnContainer}>
                            <FormControl variant='outlined' className={classes.formControl} disabled={strategy.isLowLimitPrice === 'WITHOUT_LOW_LIMIT'}>
                                <InputLabel>{translate.i18n('BASED_ON')}</InputLabel>
                                <Select
                                    value={strategy.lowLimitPrice.element}
                                    onChange={event => edit('lowLimitPrice', {
                                        element: event.target.value,
                                        mode: strategy.lowLimitPrice.mode,
                                        value: strategy.lowLimitPrice.value
                                    })}
                                    label={translate.i18n('BASED_ON')}
                                    inputProps={{
                                        name: translate.i18n('BASED_ON'),
                                        className: classes.input
                                    }}
                                >
                                    <MenuItem value={'RRP'}>RRP</MenuItem>
                                    <MenuItem value={'PURCHASING_PRICE'}>{translate.i18n('PURCHASING_PRICE')}</MenuItem>
                                </Select>
                            </FormControl>
                            <div className={classes.plusSymbol}>+</div>
                            <TextField
                                disabled={strategy.isLowLimitPrice === 'WITHOUT_LOW_LIMIT'}
                                label={translate.i18n('VALUE')}
                                type='number'
                                value={strategy.lowLimitPrice.value}
                                onChange={event => edit('lowLimitPrice', {
                                    element: strategy.lowLimitPrice.element,
                                    mode: strategy.lowLimitPrice.mode,
                                    value: parseFloat(event.target.value)
                                })}
                                variant='outlined'
                                className={classes.valueTextField}
                                inputProps={{
                                    className: classes.input
                                }}
                            />
                            <FormControl variant='outlined' className={classes.formControl} disabled={strategy.isLowLimitPrice === 'WITHOUT_LOW_LIMIT'}>
                                <InputLabel>Mode</InputLabel>
                                <Select
                                    value={strategy.lowLimitPrice.mode}
                                    onChange={event => edit('lowLimitPrice', {
                                        element: strategy.lowLimitPrice.element,
                                        mode: event.target.value,
                                        value: strategy.lowLimitPrice.value
                                    })}
                                    label='Mode'
                                    inputProps={{
                                        name: 'Mode',
                                        className: classes.input
                                    }}
                                >
                                    <MenuItem value={'PERCENTAGE'}>%</MenuItem>
                                    <MenuItem value={'VALUE'}>{AccountStore.getCurrentAccount().getCurrency()}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className={classes.subSettingsContainer}>
                        <RadioGroup value={strategy.isHighLimitPrice} onChange={event => {
                            edit('isHighLimitPrice', event.target.value);
                        }} style={{ width: '100%', marginBottom: '8px' }}>
                            <FormControlLabel value={'WITHOUT_HIGH_LIMIT'} control={<Radio color='primary' />} label={translate.i18n('WITHOUT_HIGH_LIMIT')} classes={{ label: classes.smallRadioLabel }} />
                            <FormControlLabel value={'WITH_HIGH_LIMIT'} control={<Radio color='primary' />} label={translate.i18n('WITH_HIGH_LIMIT')} classes={{ label: classes.smallRadioLabel }} />
                        </RadioGroup>
                        <div className={classes.basedOnContainer}>
                            <FormControl variant='outlined' className={classes.formControl} disabled={strategy.isHighLimitPrice === 'WITHOUT_HIGH_LIMIT'}>
                                <InputLabel>{translate.i18n('BASED_ON')}</InputLabel>
                                <Select
                                    value={strategy.highLimitPrice.element}
                                    onChange={event => edit('highLimitPrice', {
                                        element: event.target.value,
                                        mode: strategy.highLimitPrice.mode,
                                        value: strategy.highLimitPrice.value
                                    })}
                                    label={translate.i18n('BASED_ON')}
                                    inputProps={{
                                        name: translate.i18n('BASED_ON'),
                                        className: classes.input
                                    }}
                                >
                                    <MenuItem value={'RRP'}>RRP</MenuItem>
                                    <MenuItem value={'PURCHASING_PRICE'}>{translate.i18n('PURCHASING_PRICE')}</MenuItem>
                                </Select>
                            </FormControl>
                            <div className={classes.plusSymbol}>+</div>
                            <TextField
                                disabled={strategy.isHighLimitPrice === 'WITHOUT_HIGH_LIMIT'}
                                label={translate.i18n('VALUE')}
                                type='number'
                                value={strategy.highLimitPrice.value}
                                onChange={event => edit('highLimitPrice', {
                                    element: strategy.highLimitPrice.element,
                                    mode: strategy.highLimitPrice.mode,
                                    value: parseFloat(event.target.value)
                                })}
                                variant='outlined'
                                className={classes.valueTextField}
                                inputProps={{
                                    className: classes.input
                                }}
                            />
                            <FormControl variant='outlined' className={classes.formControl} disabled={strategy.isHighLimitPrice === 'WITHOUT_HIGH_LIMIT'}>
                                <InputLabel>Mode</InputLabel>
                                <Select
                                    value={strategy.highLimitPrice.mode}
                                    onChange={event => edit('highLimitPrice', {
                                        element: strategy.highLimitPrice.element,
                                        mode: event.target.value,
                                        value: strategy.highLimitPrice.value
                                    })}
                                    label='Mode'
                                    inputProps={{
                                        name: 'Mode',
                                        className: classes.input
                                    }}
                                >
                                    <MenuItem value={'PERCENTAGE'}>%</MenuItem>
                                    <MenuItem value={'VALUE'}>{AccountStore.getCurrentAccount().getCurrency()}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className={classes.subSettingsContainer}>
                        <RadioGroup value={strategy.rounding} onChange={event => edit('rounding', event.target.value)} style={{ width: '100%', marginBottom: '8px' }}>
                            <FormControlLabel value='TO_PRICE' control={<Radio color='primary' />} label={translate.i18n('TO_PRICE')} classes={{ label: classes.smallRadioLabel }} />
                            {strategy.positioning === 'MIN_PRICE' && <FormControlLabel value='TO_LOWEST_9' control={<Radio color='primary' />} label={translate.i18n('TO_LOWEST_9')} classes={{ label: classes.smallRadioLabel }} />}
                            {strategy.positioning === 'MIN_PRICE' && <FormControlLabel value='TO_LOWEST_09' control={<Radio color='primary' />} label={translate.i18n('TO_LOWEST_09')} classes={{ label: classes.smallRadioLabel }} />}
                            {strategy.positioning === 'MIN_PRICE' && <FormControlLabel value='TO_LOWEST_099' control={<Radio color='primary' />} label={translate.i18n('TO_LOWEST_099')} classes={{ label: classes.smallRadioLabel }} />}
                            {strategy.positioning === 'MAX_PRICE' && <FormControlLabel value='TO_HIGHEST_9' control={<Radio color='primary' />} label={translate.i18n('TO_HIGHEST_9')} classes={{ label: classes.smallRadioLabel }} />}
                            {strategy.positioning === 'MAX_PRICE' && <FormControlLabel value='TO_HIGHEST_09' control={<Radio color='primary' />} label={translate.i18n('TO_HIGHEST_09')} classes={{ label: classes.smallRadioLabel }} />}
                            {strategy.positioning === 'MAX_PRICE' && <FormControlLabel value='TO_HIGHEST_099' control={<Radio color='primary' />} label={translate.i18n('TO_HIGHEST_099')} classes={{ label: classes.smallRadioLabel }} />}
                            {strategy.positioning === 'AVG_PRICE' && <FormControlLabel value='TO_NEAREST_9' control={<Radio color='primary' />} label={translate.i18n('TO_NEAREST_9')} classes={{ label: classes.smallRadioLabel }} />}
                            {strategy.positioning === 'AVG_PRICE' && <FormControlLabel value='TO_NEAREST_09' control={<Radio color='primary' />} label={translate.i18n('TO_NEAREST_09')} classes={{ label: classes.smallRadioLabel }} />}
                            {strategy.positioning === 'AVG_PRICE' && <FormControlLabel value='TO_NEAREST_099' control={<Radio color='primary' />} label={translate.i18n('TO_NEAREST_099')} classes={{ label: classes.smallRadioLabel }} />}
                        </RadioGroup>
                    </div>
                </div>
                :
                null
            }
        </div>
    </div>);

EditStrategyRuleSheet.propTypes = {
    classes: PropTypes.object,
    edit: PropTypes.func,
    error: PropTypes.string,
    hide: PropTypes.bool,
    resume: PropTypes.string,
    selectedSites: PropTypes.array,
    sites: PropTypes.array,
    strategy: PropTypes.object,
    title: PropTypes.string,
    toggleHide: PropTypes.func
};

export default withStyles(styles)(EditStrategyRuleSheet);
