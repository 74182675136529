// React
import React from 'react';
import PropTypes from 'prop-types';
//Styles
import { useStyles } from 'components/sheet/PricingEngineKpiSheet/PricingEngineKpiSheet.style';
//Utils
import { displayNumber } from 'components/sheet/PricingEngineKpiSheet/PricingEngineKpiSheet.util';

const PricingEngineKpi = ({ value, color, sitesLength, isMargin }) => {

    const classes = useStyles({ value, color });

    return <div className={classes.kpiBlock}>
        {value ? (sitesLength ? '' : value > 0 ? isMargin ? '' : '+ ' : '') + displayNumber(value) + (sitesLength ? ' / ' + sitesLength : ' %') : 'N/A'}
    </div>;
};

PricingEngineKpi.propTypes = {
    value: PropTypes.number,
    sitesLength: PropTypes.number,
    color: PropTypes.string,
    isMargin: PropTypes.bool
};

export default PricingEngineKpi;
