// Abstract
import AbstractStore from 'core/abstracts/AbstractStore';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Facade
import Facade from 'core/Facade';

class LayoutStore extends AbstractStore {

    constructor() {

        const CHANGE_EVENT = 'LAYOUT_CHANGE_EVENT';
        const NAME = 'LAYOUT_STORE';

        super(NAME, CHANGE_EVENT);

        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));

        this._initStore();
    }

    _initStore() {
        this.height = window.innerHeight;
        this.width = window.innerWidth;
        this.isBlue = Facade.getUrlParam('isBlue') ? Facade.getUrlParam('isBlue') === 'true' : true;
        this.blur = localStorage.getItem('blur') ? localStorage.getItem('blur') === 'true' : true;
        this.showEditIcon = localStorage.getItem('showEditIcon') ? localStorage.getItem('showEditIcon') === 'true' : false;

        this.error = '';

        this.mobile = false;
        if (this.width < 600)
            this.mobile = true;

        this.locationRedirect = null;

        // Add windows size listener for mobile
        window.addEventListener('resize', this.onScreenSizeChange);
    }

    onScreenSizeChange = () => {
        this.height = window.innerHeight;
        this.width = window.innerWidth;

        if (this.width < 600) {
            this.mobile = true;
        } else {
            this.mobile = false;
        }

        this._emitChange();
    };

    isMobile() {
        return this.mobile;
    }

    getZise() {
        return {
            height: this.height,
            width: this.width
        };
    }

    getError() {
        return this.error;
    }

    setError(error) {
        this.error = error;
    }

    setLocationRedirect(location) {
        this.locationRedirect = location;
    }

    cleanLocationRedirect() {
        this.locationRedirect = null;
    }

    getLocationRedirect() {
        return this.locationRedirect;
    }

    handleBlurChange() {
        this.blur = !this.blur;
        localStorage.setItem('blur', this.blur);
    }

    handleEditIconChange() {
        this.showEditIcon = !this.showEditIcon;
        localStorage.setItem('showEditIcon', this.showEditIcon);
    }

    getBlur() {
        return this.blur;
    }

    getshowEditIcon() {
        return this.showEditIcon;
    }

    handleColorChange() {
        this.isBlue = !this.isBlue;
        Facade.setUrlParam('isBlue', this.isBlue);
    }

    getColor() {
        return this.isBlue;
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
        case Constants.USER_LOGOUT:
            this._initStore();
            this._emitChange();
            break;
        case Constants.CLEAN_LOCATION_REDIRECT:
            this.locationRedirect = null;
            this._emitChange();
            break;
        case Constants.SET_BLUR:
            this.handleBlurChange();
            this._emitChange();
            break;
        case Constants.SHOW_EDIT_ICON:
            this.handleEditIconChange();
            this._emitChange();
            break;
        case Constants.CHANGE_COLOR_THEME:
            this.handleColorChange();
            this._emitChange();
            break;
        case Constants.SET_ERROR:
            this.setError(action.value);
            this._emitChange();
            break;
        default:
            return false;
        }
    }
}

export default new LayoutStore();
