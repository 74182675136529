import MarketplaceOffer from 'core/entities/MarketplaceOfferObject';
import AdditionalData from './AdditionalDataObject';

class ScrapedData {

    constructor(data) {
        this.availability = data.availability;
        this.imgUrl = data.img_url;
        this.price = data.price;
        this.status = data.status;
        this.stock = data.stock;
        this.date = data && data.date ? data.date.split('T')[0] : data.date;
        this.marketplaceOffers = data && data.marketplace_offers ? data.marketplace_offers.map(offer => new MarketplaceOffer(offer)) : data.marketplace_offers;
        this.pricingAdvising = data.pricing_advising;
        this.additionalData = data && data.additional_data ? new AdditionalData(data.additional_data) : data.additional_data;
    }

    /**
     * METHODS
     */
    toJson() {
        return {
            availability: this.availability,
            img_url: this.imgUrl,
            status: this.status,
            price: this.price,
            stock: this.stock,
        };
    }
}

export default ScrapedData;
