import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// Stores
import UserStore from 'stores/UserStore';
import AccountStore from 'stores/AccountStore';
import CategoryStore from 'stores/CategoryStore';
// Actions
import RuleAction from 'actions/RuleAction';
import CategoryAction from 'actions/CategoryAction';
// Locales
import translate from 'locales/i18n';
// Material UI Components
import withStyles from '@mui/styles/withStyles';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import ArrowLeftIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import { IconButton, Paper } from '@mui/material';
// PCP Components
import EditPriceRuleSheet from 'components/sheet/EditPriceRuleSheet/EditPriceRuleSheet';
import EditStrategyRuleSheet from 'components/sheet/EditStrategyRuleSheet/EditStrategyRuleSheet';
import SelectItemsSheet from 'components/sheet/SelectItemsSheet/SelectItemsSheet';
import DialogConfirm from 'components/dialog/DialogConfirm/DialogConfirm';
import Loader from 'components/commons/Loader/Loader';
import DialogLoading from 'components/dialog/DialogLoading/DialogLoading';
// Styles
import styles from 'components/pages/RulePage/RulePage.style';
// Util
import { getStrategy, getResume } from 'components/pages/RulePage/RulePage.util';
// Facade
import Facade from 'core/Facade';
// History
import history from 'history.js';

class Rule extends React.PureComponent {

    state = {
        categories: [],
        dialogConfirmDeleteRuleOpen: false,
        dialogTryToLeaveOpen: false,
        error: '',
        from: null,
        hadChanged: false,
        hideStrategy: false,
        loadingRule: true,
        savingRule: false,
        items: [],
        maxPrice: {
            element: 'RRP',
            mode: 'PERCENTAGE',
            value: 0
        },
        minPrice: {
            element: 'PURCHASING_PRICE',
            mode: 'PERCENTAGE',
            value: 0
        },
        name: '',
        redirection: null,
        ruleId: this.props.match.params.id,
        selectedItems: [],
        strategy: {
            competitors: 'ALL_COMPETITORS',
            offset: {
                mode: 'PERCENTAGE',
                value: 0,
                sign: '-'
            },
            element: 'PURCHASING_PRICE',
            filteringSiteIds: [],
            filteringStocks: [],
            highLimitPrice: {
                element: 'RRP',
                mode: 'PERCENTAGE',
                value: 0.0
            },
            isHighLimitPrice: 'WITHOUT_HIGH_LIMIT',
            isLowLimitPrice: 'WITHOUT_LOW_LIMIT',
            lowLimitPrice: {
                element: 'PURCHASING_PRICE',
                mode: 'PERCENTAGE',
                value: 0.0
            },
            positioning: 'NONE',
            rounding: 'TO_PRICE',
            stocks: 'ALL_STOCKS',
            type: 'STANDARD_STRATEGY'
        },
        to: null
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
        match: PropTypes.object
    };

    componentDidMount() {
        CategoryStore.addChangeListener(this.onCategoryStoreChange);
        let isCopy = false;

        if (Facade.getAllUrlParams()[0]) {
            if (Facade.getAllUrlParams()[0][0] && Facade.getAllUrlParams()[1][0]) {
                if (Facade.getAllUrlParams()[0][0] === 'id' && Facade.getAllUrlParams()[1][0] === 'name') {
                    isCopy = true;
                }
            }
        }

        if (UserStore.isLogged() && (this.props.match.params.id || isCopy)) {
            RuleAction.getRule(isCopy ? Facade.getAllUrlParams()[0][1] : this.props.match.params.id).then(rule => {
                const { from, maxPrice, minPrice, name, strategy, to, selectedItems } = this.state;
                this.setState({
                    from: rule && rule.applyFrom ? new Date(rule.applyFrom) : from,
                    maxPrice: rule ? rule.maxPrice : maxPrice,
                    minPrice: rule ? rule.minPrice : minPrice,
                    mode: rule ? 'edit' : 'add',
                    strategy: rule ? {
                        competitors: rule.strategy.filteringSiteIds ? 'SOME_COMPETITORS' : strategy.competitors,
                        filteringSiteIds: rule.strategy.filteringSiteIds ? rule.strategy.filteringSiteIds : strategy.filteringSiteIds,
                        filteringStocks: rule.strategy.filteringStocks ? rule.strategy.filteringStocks : strategy.filteringStocks,
                        highLimitPrice: rule.strategy.highLimitPrice ? rule.strategy.highLimitPrice : strategy.highLimitPrice,
                        isHighLimitPrice: rule.strategy.highLimitPrice ? 'WITH_HIGH_LIMIT' : strategy.isHighLimitPrice,
                        isLowLimitPrice: rule.strategy.lowLimitPrice ? 'WITH_LOW_LIMIT' : strategy.isLowLimitPrice,
                        lowLimitPrice: rule.strategy.lowLimitPrice ? rule.strategy.lowLimitPrice : strategy.lowLimitPrice,
                        element: rule.strategy.element ? rule.strategy.element : strategy.element,
                        offset: rule.strategy.offset ? {
                            mode: rule.strategy.offset.mode,
                            sign: rule.strategy.offset.value < 0 ? '-' : '+',
                            value: Math.abs(rule.strategy.offset.value)
                        } : strategy.offset,
                        positioning: rule.strategy.type === 'SELF_ORIENTED_STRATEGY' ? 'SELF_ORIENTED_STRATEGY' : rule.strategy.positioning ? rule.strategy.positioning : strategy.positioning,
                        rounding: rule.strategy.rounding ? rule.strategy.rounding : strategy.rounding,
                        stocks: rule.strategy.filteringStocks ? 'SOME_STOCKS' : strategy.stocks,
                        type: rule.strategy.type
                    } : strategy,
                    selectedItems: rule && rule.associatedArticles ? rule.associatedArticles.map((article, index) => ({ id: article.id, rank: index }) ) : selectedItems,
                    to: rule && rule.applyUntil ? new Date(rule.applyUntil) : to,
                    loadingRule: false
                });
                if (isCopy) {
                    this.setState({
                        name: rule ? rule.name + Facade.getAllUrlParams()[1][1] : Facade.getAllUrlParams()[1][1]
                    });
                } else {
                    this.setState({
                        name: rule ? rule.name : name
                    });
                }
            });
        } else {
            this.setState({ loadingRule: false });
        }

        if (UserStore.isLogged()) {
            CategoryAction.getCategories(AccountStore.getCurrentAccount().getId());
        }

        this.mounted = true;
    }

    componentWillUnmount() {
        CategoryStore.removeChangeListener(this.onCategoryStoreChange);
        this.mounted = false;
    }

    onCategoryStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            categories: CategoryStore.getCategories()
        });
    };

    submit = () => {
        const { name, from, to, maxPrice, minPrice, strategy, selectedItems, ruleId } = this.state;
        if (!name) {
            this.setState({ error: 'NAME_IS_MISSING' });
            return false;
        }

        if (strategy.positioning === 'NONE') {
            this.setState({ error: 'POSITIONING_IS_MISSING' });
            return false;
        }

        const rule = {
            name,
            apply_from: from ? from.getFullYear() + '-' + (from.getMonth() + 1) + '-' + from.getDate() : undefined,
            apply_until: to ? to.getFullYear() + '-' + (to.getMonth() + 1) + '-' + to.getDate() : undefined,
            min_price: minPrice,
            max_price: maxPrice,
            strategy: getStrategy(strategy),
            associated_articles: selectedItems
        };

        this.setState({ hadChanged: false, savingRule: true });

        ruleId ? RuleAction.updateRule(AccountStore.getCurrentAccount().getId(), rule, ruleId)
            .then(() => this.setState({ savingRule: false }),
                response => this.setState({ error: response?.error, savingRule: false }))
            : RuleAction.createRule(AccountStore.getCurrentAccount().getId(), rule)
                .then(r => {
                    this.setState({ ruleId: r.id });
                    this.setState({ savingRule: false });
                    history.replace('/rule/' + r.id);
                }, response => this.setState({ error: response?.error, savingRule: false }));
    };

    delete = () => {
        RuleAction.deleteRule(AccountStore.getCurrentAccount().getId(), this.state.ruleId);
        history.push('/rules');
    };

    editMaxPrice = (key, value) => this.setState({ maxPrice: Object.assign({}, this.state.maxPrice, { [key]: value }), hadChanged: true });

    editMinPrice = (key, value) => this.setState({ minPrice: Object.assign({}, this.state.minPrice, { [key]: value }), hadChanged: true });

    editStrategy = (key, value, callback) => {
        this.setState({ strategy: Object.assign({}, this.state.strategy, { [key]: value }), hadChanged: true, error: '' }, callback);
    };

    hideCategory = (categoryId) => this.setState({
        categories: this.state.categories.map(category => {
            if (category.id === categoryId) {
                category.hide = !category.hide;
            }
            return category;
        })
    });

    selectProducts = (products, categoryId) => {
        const selectedItems = this.state.selectedItems.slice(0);
        let categories = this.state.categories.slice(0);

        if (categoryId) {
            categories = categories.map(c => c.id === categoryId ? Object.assign({}, c, { checked: true }) : c);
        }
        products.forEach(p => {
            selectedItems.push({ id: p, rank: 0 });
        });
        this.setState({ selectedItems, hadChanged: true, categories });
    };

    unselectItems = (ids, categoryId) => {
        let selectedItems = this.state.selectedItems.slice(0);
        let categories = this.state.categories.slice(0);

        if (categoryId) {
            categories = categories.map(c => c.id === categoryId ? Object.assign({}, c, { checked: false }) : c);
        }

        ids.forEach(id => {
            selectedItems = selectedItems.filter(selectedItem => selectedItem.id !== id);
        });

        this.setState({ selectedItems, hadChanged: true, categories });
    };

    setRank = (productId, rank) => {
        const selectedItems = this.state.selectedItems.slice(0);
        selectedItems.forEach(p => {
            if (p.id === productId) p.rank = rank;
        });
        this.setState({ selectedItems, hadChanged: true });
    };

    renderDialogTryToLeave = () => <DialogConfirm
        text={translate.i18n('IF_YOU_LEAVE')}
        open={this.state.dialogTryToLeaveOpen}
        close={() => this.setState({ dialogTryToLeaveOpen: false })}
        callback={() => history.push(this.state.redirection)}
    />;

    renderDialogConfirmDeleteRule = () => <DialogConfirm
        text={translate.i18n('CONFIRM_DELETE_RULE', { ruleName: this.state.name })}
        open={this.state.dialogConfirmDeleteRuleOpen}
        close={() => this.setState({ dialogConfirmDeleteRuleOpen: false })}
        callback={this.delete}
    />;

    renderLoadingDialog = () => <DialogLoading open={this.state.savingRule} />;

    render() {
        const { from, to, name, minPrice, maxPrice, strategy, error, categories, selectedItems, hadChanged, hide, loadingRule, ruleId } = this.state;
        const { classes } = this.props;
        if (loadingRule) return <div className={classes.loaderContainer}><Loader size={50} /></div>;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <Link className={classes.link} to={'/pricing-engine-analyze'}
                        style={{ marginLeft: 'auto' }}
                        onClick={e => {
                            if (hadChanged) {
                                e.preventDefault();
                                e.stopPropagation();
                                this.setState({ dialogTryToLeaveOpen: true, redirection: '/pricing-engine-analyze' });
                            }
                        }}>
                        <Button
                            variant='outlined'
                            color='primary'
                            size='small'
                            className={classes.button}
                            startIcon={<ArrowLeftIcon />}
                        >
                            {translate.i18n('ANALYZE')}
                        </Button>
                    </Link>
                    <TextField
                        id='rule-name'
                        error={error === 'NAME_IS_MISSING' || error === 'UNAVAILABLE_NAME'}
                        className={classes.name}
                        variant='outlined'
                        value={name}
                        size={'small'}
                        label={'* ' + translate.i18n('NAME')}
                        onChange={event => this.setState({ name: event.target.value, error: '' })}
                        onKeyPress={this.handleKeyPress}
                        placeholder={translate.i18n('NAME_OF_THE_RULE')}
                        margin={'dense'}
                        helperText={error === 'NAME_IS_MISSING' || error === 'UNAVAILABLE_NAME' ? translate.i18n(error) : null}
                    />
                    {ruleId ?
                        <Button
                            variant='contained'
                            color='secondary'
                            size={'small'}
                            className={classes.button}
                            startIcon={<DeleteIcon />}
                            onClick={() => this.setState({ dialogConfirmDeleteRuleOpen: true })}
                        >
                            {translate.i18n('DELETE')}
                        </Button>
                        :
                        null
                    }
                    <Button
                        variant='contained'
                        color='primary'
                        size={'small'}
                        className={classes.button}
                        startIcon={<SaveIcon />}
                        onClick={this.submit}
                        style={{ marginLeft: '8px' }}
                    >
                        {translate.i18n('SAVE')}
                    </Button>
                    <Link className={classes.link} to={'/rules'}
                        style={{ marginLeft: '8px' }}
                        onClick={e => {
                            if (hadChanged) {
                                e.preventDefault();
                                e.stopPropagation();
                                this.setState({ dialogTryToLeaveOpen: true, redirection: '/rules' });
                            }
                        }}>
                        <Button
                            variant='outlined'
                            color='primary'
                            size={'small'}
                            className={classes.button}
                            endIcon={<ArrowRightIcon />}
                        >
                            {translate.i18n('LIBRARY')}
                        </Button>
                    </Link>
                </div>
                <div className={classes.body}>
                    <Paper className={classes.fullPaper} elevation={3}>
                        <EditStrategyRuleSheet
                            hide={hide}
                            error={error}
                            toggleHide={() => this.setState({ hide: !hide })}
                            title={translate.i18n('STRATEGY')}
                            strategy={strategy}
                            edit={this.editStrategy}
                            resume={getResume(this.state.strategy, this.state.from, this.state.to)}
                            sites={AccountStore.getCurrentAccount().getPriceMonitoringInformations() ? AccountStore.getCurrentAccount().getPriceMonitoringInformations().sites : []} />
                    </Paper>
                    <div className={classes.bottomContainer}>
                        <div className={classes.editPriceContainer}>
                            <Paper className={classes.datePaper} elevation={3} style={{ marginTop: 0 }}>
                                <div className={classes.title}>{translate.i18n('APPLICATIONS_DATES')}<div className={classes.titleBar}></div></div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField {...props} size='small' classes={{ root: classes.datePicker }} />}
                                        label={translate.i18n('BEGINNING')}
                                        value={from}
                                        onChange={date => this.setState({ from: date })}
                                        inputVariant='outlined'
                                        inputFormat='dd/MM/yyyy'
                                        margin={'dense'}
                                    />
                                    <IconButton
                                        variant='contained'
                                        color='secondary'
                                        style={{ padding: '6px 0px 6px 4px' }}
                                        onClick={() => this.setState({ from: null })}
                                        size='large'>
                                        <DeleteIcon />
                                    </IconButton>
                                    <DatePicker
                                        renderInput={(props) => <TextField {...props} size='small' classes={{ root: classes.datePicker }} />}
                                        label={translate.i18n('ENDING')}
                                        value={to}
                                        onChange={date => this.setState({ to: date })}
                                        inputVariant='outlined'
                                        inputFormat='dd/MM/yyyy'
                                        minDate={from}
                                        margin={'dense'}
                                    />
                                    <IconButton
                                        variant='contained'
                                        color='secondary'
                                        style={{ padding: '6px 0px 6px 4px' }}
                                        onClick={() => this.setState({ to: null })}
                                        size='large'>
                                        <DeleteIcon />
                                    </IconButton>
                                </LocalizationProvider>
                            </Paper>
                            <Paper className={classes.midPaper} elevation={3} style={{ marginTop: 0 }}>
                                <EditPriceRuleSheet
                                    title={translate.i18n('MINIMUM_SALE_PRICE')}
                                    element={minPrice.element}
                                    mode={minPrice.mode}
                                    value={minPrice.value}
                                    edit={this.editMinPrice} />
                            </Paper>
                            <Paper className={classes.midPaper} elevation={3} style={{ marginBottom: '10px' }}>
                                <EditPriceRuleSheet
                                    title={translate.i18n('MAXIMUM_SALE_PRICE')}
                                    element={maxPrice.element}
                                    mode={maxPrice.mode}
                                    value={maxPrice.value}
                                    edit={this.editMaxPrice} />
                            </Paper>
                        </div>
                        <Paper className={classes.itemsPaper} elevation={3}>
                            <SelectItemsSheet
                                categories={categories}
                                selectedItems={selectedItems}
                                selectItems={this.selectProducts}
                                unselectItems={this.unselectItems}
                                ruleId={ruleId}
                                setRank={this.setRank}
                                hideCategory={this.hideCategory} />
                        </Paper>
                    </div>
                </div>
                {this.renderDialogTryToLeave()}
                {this.renderDialogConfirmDeleteRule()}
                {this.renderLoadingDialog()}
            </div>
        );
    }
}

export default withStyles(styles)(Rule);
