import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    bellIcon: {
        color: theme.palette.grey.dark,
        fontSize: '15px',
        marginLeft: '2px'
    },
    notSoldTableCell: {
        borderRight: '1px solid' + theme.palette.grey.light,
        color: theme.palette.grey.dark,
        fontSize: '12px',
        fontWeight: '500',
        padding: 0
    },
    notSoldTableCellContent: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    svgIcon: {
        marginTop: '2px'
    },
    tableCell: {
        borderRight: '1px solid' + theme.palette.grey.light,
        color: theme.palette.grey.dark,
        fontSize: '16px',
        fontWeight: '500',
        padding: 0
    },
    tableCellNotFoundPrice: {
        borderRight: '1px solid' + theme.palette.grey.light,
        color: theme.palette.grey.dark,
        fontSize: '12px',
        fontWeight: '500',
        padding: 0
    },
    tableCellContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    }
}));
