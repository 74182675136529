export const palette = (isBlue) => ({
    black: {
        main: '#000000'
    },
    error: {
        main: '#B30000'
    },
    adbox: {
        five: '#020A0C',
        four: '#051E23',
        one: '#126D7D',
        three: '#093239',
        two: '#0F5A67'
    },
    graph: isBlue ? {
        five: '#0F5562',
        four: '#1A9FB7',
        one: '#A6E7F2',
        three: '#51D0E6',
        two: '#8BE0EE'
    } : {
        five: '#FF1B1B',
        four: '#EB8F23',
        one: '#8CDC42',
        three: '#ebe834',
        two: '#C5E91E'
    },
    graphGradiant: isBlue ? '#000000, #031012, #051F24, #082F36, #0A3E47, #0D4E59, #0F5D6B, #127181, #158093, #1890A5, #1A9FB7, #1A9FB7, #1FBFDB, #2DC6E1, #3FCBE4, #51D0E6, #5ED3E8, #70D8EB, #7EDCED, #8BE0EE, #98E3F0, #A6E7F2, #B3EAF4' : '#FF1A1A, #FF1B1B, #FF1C1C,#FF1F1F,#FF2121,#FF2525,#FF2727, #FC2E2A,#F6422D,#EB8F23,#DFD218,#D2EA18,#C5E91E,#B8E525,#AAE22D,#9EE133,#94E137,#8DE239,#89E33B,#89E13C,#88E03E,#89DF3F,#8ADD41, #8CDC42',
    green: {
        dark: '#315b51',
        light: '#5b8a5e',
        main: '#1BCC00'
    },
    grey: {
        dark: '#808080',
        light: '#DFDFDF',
        main: '#ABABAB',
        semidark: '#7A7A7A',
        semilight: '#f2f2f2',
        ultradark: '#545454',
        ultralight: '#EBEBEB'
    },
    largeGraph: isBlue ? {
        one: '#000000',
        two: '#031012',
        three: '#051F24',
        four: '#082F36',
        five: '#0A3E47',
        six: '#0D4E59',
        seven: '#0F5D6B',
        eight: '#127181',
        nine: '#158093',
        ten: '#1890A5',
        eleven: '#1A9FB7',
        twelve: '#1A9FB7',
        thirteen: '#1FBFDB',
        fourteen: '#2DC6E1',
        fifteen: '#3FCBE4',
        sixteen: '#51D0E6',
        seventeen: '#5ED3E8',
        eighteen: '#70D8EB',
        nineteen: '#7EDCED',
        twenty: '#8BE0EE',
        twentyOne: '#98E3F0',
        twentyTwo: '#A6E7F2',
        twentyThree: '#B3EAF4'
    } : {
        one: '#FF1A1A',
        two: '#FF1B1B',
        three: '#FF1C1C',
        four: '#FF1F1F',
        five: '#FF2121',
        six: '#FF2525',
        seven: '#FF2727',
        eight: '#FC2E2A',
        nine: '#F6422D',
        ten: '#EB8F23',
        eleven: '#DFD218',
        twelve: '#D2EA18',
        thirteen: '#C5E91E',
        fourteen: '#B8E525',
        fifteen: '#AAE22D',
        sixteen: '#9EE133',
        seventeen: '#94E137',
        eighteen: '#8DE239',
        nineteen: '#89E33B',
        twenty: '#89E13C',
        twentyOne: '#88E03E',
        twentyTwo: '#89DF3F',
        twentyThree: '#8ADD41'
    },
    module: {
        adbox: '#7AC27A',
        perf: '#178174',
        price: '#0e5562',
        pricingengine: '#315b51',
        slider: '#6bb98b'
    },
    orange: {
        main: '#FF7614'
    },
    primary: {
        dark: '#126D7D',
        light: '#3BCAE3',
        main: '#1890A5',
        ultraLight: '#B3EBF4'
    },
    red: {
        light: '#DF4343',
        main: '#B30000'
    },
    rulesRanks: {
        five: '#99b3ff',
        four: '#668cff',
        one: '#002db3',
        three: '#3366ff',
        two: '#0040ff'
    },
    secondary: {
        dark: '#750000',
        light: '#FF3D3D',
        main: '#B30000'
    },
    success: {
        main: '#178174'
    },
    white: {
        dark: '#F2F2F2',
        main: '#FFFFFF',
        secondary: '#FAFAFA'
    },
    yellow: {
        main: '#CCC200'
    }
});
