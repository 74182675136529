// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Styles
import { useStyles } from 'components/filter/slider/TimelineDate/TimelineDate.style';
//Mui components
import { Chip, Tooltip, useTheme } from '@mui/material';
//Utils
import { formatDate, getColor } from 'components/filter/slider/SliderTimelineFilter/SliderTimelineFilter.util';
import { similarDate } from 'components/pages/SliderPage/SliderPage.util';
//Icons
import calendarFirstDay from 'assets/img/svg/calendarFirstDay';

const TimelineDate = ({ day, currentDay, selectDayDisplay, openDate, scale }) => {

    const theme = useTheme();
    const classes = useStyles({ day, scale });

    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(true);
    const [ref, setRef] = useState(null);

    const isFirstDay = new Date(day.date).getDate() === 1;

    useEffect(() => {
        setOpen(false);
        ref?.offsetParent?.attributes[1]?.nodeValue === 'false' ? setVisible(true) : setVisible(false);
    }, [openDate, currentDay]);

    const renderChip = () => <div onClick={() => {
        selectDayDisplay(day.date);
    }}>
        <Tooltip
            title={day.count}
            placement='top'
            open={visible && openDate}
            arrow={!isFirstDay}
            classes={{
                tooltip: isFirstDay? classes.firstDayTooltipTitle : classes.infoTooltipTitle,
                arrow: classes.infoTooltipArrow,
                popper: classes.tooltipPopper
            }}>
            {!isFirstDay ? <Chip
                label={''}
                className={classes.dayChip}
                ref={(el) => setRef(el)}
                classes={{
                    label: classes.dayChipLabel
                }}
                style={{
                    backgroundColor: getColor(day.count, scale, theme)
                }}
                onClick={() => {
                    selectDayDisplay(day.date);
                }} />
                :
                <div ref={(el) => setRef(el)} onClick={() => {
                    selectDayDisplay(day.date);
                }} style={{ backgroundColor: 'white', cursor: 'pointer' }}>
                    {calendarFirstDay(20,20,getColor(day.count, scale, theme))}
                </div>
            }
        </Tooltip>
    </div>;

    return similarDate(day.date, currentDay) ?
        <Tooltip
            title={formatDate(day.date, true)}
            arrow
            disableFocusListener
            disableHoverListener
            disableTouchListener
            classes={{
                tooltip: classes.selectedDayChipTooltipTitle,
                arrow: classes.dayChipTooltipArrow,
                popper: classes.tooltipPopper
            }}
            open={visible && openDate}>
            {renderChip()}
        </Tooltip>
        :
        <Tooltip
            title={formatDate(day.date, true)}
            arrow
            classes={{ tooltip: classes.dayChipTooltipTitle }}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}>
            {renderChip()}
        </Tooltip>;

};

TimelineDate.propTypes = {
    currentDay: PropTypes.instanceOf(Date),
    day: PropTypes.object,
    selectDayDisplay: PropTypes.func,
    openDate: PropTypes.bool,
    scale: PropTypes.object
};

export default TimelineDate;

