// React
import React from 'react';
import PropTypes from 'prop-types';
// Theme
import { useTheme } from '@mui/styles';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import useMediaQuery from '@mui/material/useMediaQuery';

const DialogAlert = ({ open, close, text }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={close}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
        >
            <DialogTitle id='responsive-dialog-title'>{text}</DialogTitle>
            <DialogActions>
                <IconButton color='primary' onClick={close} size='large'>
                    <DoneIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

DialogAlert.propTypes = {
    callback: PropTypes.func,
    close: PropTypes.func,
    fullScreen: PropTypes.bool,
    open: PropTypes.bool,
    text: PropTypes.string
};

export default DialogAlert;
