// Locales
import translate from 'locales/i18n';

export const createData = (statsBySites,sendersColors, theme) => {

    if (statsBySites.length === 0)
        return {
            datasets: [{
                data: []
            }],
            labels: []
        };

    const dataMailsTotals = [];
    const dataMailsLabels = [];
    const dataMailsRatio = [];
    const dataMailsColors = [];

    statsBySites.forEach((value) => {
        dataMailsTotals.push(value.cardinality);
        dataMailsLabels.push(value.name.split('_')[0]);
        dataMailsRatio.push(value.ratio);
        dataMailsColors.push(getColor(value.ratio, theme));
    });

    const data = {
        datasets: [{
            backgroundColor: dataMailsColors,
            data: dataMailsTotals,
            hoverBackgroundColor: theme.palette.grey.light,
            ratios: dataMailsRatio
        }],
        labels: dataMailsLabels,
    };
    return data;
};

export const options = {
    maintainAspectRatio: false,
    plugins: {
        datalabels: {
            backgroundColor: (context) => context.dataset.backgroundColor,
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 2,
            color: 'white',
            anchor: 'end',
            font: {
                weight: 'bold'
            },
            padding: 6,
            formatter: (value, context) => context.dataset.ratios[context.dataIndex] > 5 ? context.chart.data.labels[context.dataIndex] : null,
        },
        legend: {
            display: false
        },
        title: {
            display: true,
            text: translate.i18n('ADBOX.MAIL_PIE_TITLE')
        },
        tooltip: {
            callbacks: {
                title(context) {
                    return context[0].label;
                },
                label(context) {
                    return `${translate.i18n('ADBOX.NUMBER_OF_MAILS')} : ${context.formattedValue} = ${context.dataset.ratios[context.dataIndex]}%`;
                }
            }
        }
    },
    layout: {
        padding: {
            bottom: 14
        }
    },
};

const getColor = (ratio, theme) => {
    if (ratio < 20) return theme.palette.adbox.one;
    if (ratio < 30) return theme.palette.adbox.two;
    if (ratio < 40) return theme.palette.adbox.three;
    if (ratio < 50) return theme.palette.adbox. four;
    return theme.palette.adbox.five;
};
