import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    card: {
        height: '310px',
        margin: ' 5px',
        position: 'relative',
        width: '220px'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '220px',
        justifyContent: 'space-between'
    },
    cardImage: {
        height: '35%',
        margin: 'auto',
        width: '50%'
    },
    categoryTooltip: {
        fontSize: '11px',
        whiteSpace: 'pre-wrap'
    },
    hideOverflow: {
        borderRadius: '3px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    openInNewIcon: {
        left: '180px',
        position: 'absolute',
        top: '0px'
    },
    productInfo: {
        marginBottom: '3px'
    },
    productName: {
        fontWeight: 'bold',
        marginBottom: '10px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical'
    },
    infoContainer: {
        height: '100%'
    }
}));
