import React from 'react';
import PropTypes from 'prop-types';
//Style
import { useStyles } from 'components/pages/TeaserPage/TeaserPage.style';
//Const
import { teaserConst } from 'components/pages/TeaserPage/TeaserConstants';
//Locales
import translate from 'locales/i18n';
//Mui components
import { Button } from '@mui/material';
//Facade
import Facade from 'core/Facade';

const TeaserPage = ({ componentName }) => {

    const classes = useStyles();

    return <div className={classes.teaserContainer}>
        <div className={classes.teaserTitle}>{teaserConst[componentName].title}</div>
        <div className={classes.teaserPart}>
            <div className={classes.teaserPartImage}>
                <img src={teaserConst[componentName].image1} className={classes.teaserImage} />
            </div>
            <div className={classes.teaserPartText}>
                <div className={classes.teaserPartTextTitle}>
                    {translate.i18n(teaserConst[componentName].text1Title)}
                </div>
                <br/>
                {teaserConst[componentName].text1.map(t => t.jumpLine ?
                    <>
                        <br/>
                        <br/>
                    </>
                    :
                    t.bold ?
                        <b>{translate.i18n(t.text)}</b>
                        :
                        !Array.isArray(t.text) ?
                            translate.i18n(t.text)
                            :
                            t.text.map(point => <>{translate.i18n(point)}<br/></>)
                )}
            </div>
        </div>
        <div className={classes.teaserPart}>
            <div className={classes.teaserPartText}>
                <div className={classes.teaserPartTextTitle}>
                    {translate.i18n(teaserConst[componentName].text2Title)}
                </div>
                <br/>
                {teaserConst[componentName].text2.map(t => t.jumpLine ?
                    <>
                        <br/>
                        <br/>
                    </>
                    :
                    t.bold ?
                        <b>{translate.i18n(t.text)}</b>
                        :
                        !Array.isArray(t.text) ?
                            translate.i18n(t.text)
                            :
                            t.text.map(point => <p key={point} style={{ marginTop: 0, marginBottom: 0, marginLeft: '35px' }}>{translate.i18n(point)}</p>)
                )}
            </div>
            <div className={classes.teaserPartImage}>
                <img src={teaserConst[componentName].image2} className={classes.teaserImage} />
            </div>
        </div>
        <div className={classes.teaserAction}>
            <div className={classes.teaserActionText}>{translate.i18n('TEASER.ACTION_TEXT')}</div>
            <Button
                className={classes.teaserActionButton}
                color='primary'
                onClick={() => Facade.openUrl('https://meetings.hubspot.com/damien-leblond')}
                variant='contained'>
                {translate.i18n('TEASER.ACTION_BUTTON')}
            </Button>
        </div>
    </div>;
};

TeaserPage.propTypes = {
    componentName: PropTypes.string
};

export default TeaserPage;
