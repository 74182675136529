// React
import React from 'react';
import PropTypes from 'prop-types';
// Locales
import translate from 'locales/i18n';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
// Util
import { colorsBarem } from 'components/filter/mail/MailDayHourFilter/MailDayHourFilter.util';
// Style
import { useStyles } from 'components/filter/mail/MailDayHourFilter/MailDayHourFilter.style';
//Mui
import IconButton from '@mui/material/IconButton';
import IconRefresh from '@mui/icons-material/Cached';

const MailDayHourFilter = ({
    resetDaysHours,
    selectDayHour,
    statsByDayAndHour,
    totalMails
}) => {
    const classes = useStyles();

    if (!statsByDayAndHour)
        return (
            <Paper className={classes.paper}>
            </Paper>
        );

    const barem = colorsBarem(totalMails);

    return (
        <Paper className={classes.paper}>
            <h5 className={classes.titleGraph}>{translate.i18n('ADBOX.WEEKLY_MAIL_CONCENTRATION')}</h5>
            <div className={classes.graphContainer}>
                {statsByDayAndHour.map((arrayDay) => <div className={classes.graphRow} key={arrayDay.day}>
                    <div style={{ flex: '15%' }} className={classes.graphCol}>{translate.i18n(`SHORT_${arrayDay.day}`)}</div>
                    {arrayDay.cardinalities.map((hour, k) => {
                        let [{ color }] = barem;
                        if (hour > barem[0].value)
                            [, { color }] = barem;
                        if (hour > barem[1].value)
                            [, , { color }] = barem;
                        if (hour > barem[2].value)
                            [, , , { color }] = barem;
                        if (hour > barem[3].value)
                            [, , , , { color }] = barem;
                        if (hour > barem[4].value)
                            [, , , , , { color }] = barem;
                        return (
                            <Tooltip title={arrayDay.day + ' entre ' + k + 'h et ' + (k + 1) + 'h : ' + hour + ' mail(s)'} key={k} disableInteractive>
                                <div style={{ backgroundColor: color, cursor: 'pointer' }} className={classes.graphCol} key={k} onClick={() => selectDayHour(arrayDay.day, k)} />
                            </Tooltip>
                        );
                    })}
                </div>
                )
                }
                <div className={classes.graphRow}>
                    <div style={{ flex: '13%' }} className={classes.graphCol}></div>
                    <div className={classes.graphCol2}>0h</div>
                    <div className={classes.graphCol2}>2h</div>
                    <div className={classes.graphCol2}>4h</div>
                    <div className={classes.graphCol2}>6h</div>
                    <div className={classes.graphCol2}>8h</div>
                    <div className={classes.graphCol2}>10h</div>
                    <div className={classes.graphCol2}>12h</div>
                    <div className={classes.graphCol2}>14h</div>
                    <div className={classes.graphCol2}>16h</div>
                    <div className={classes.graphCol2}>18h</div>
                    <div className={classes.graphCol2}>20h</div>
                    <div className={classes.graphCol2}>22h</div>
                </div>
            </div>
            <IconButton className={classes.iconReset} color='primary' onClick={resetDaysHours}>
                <IconRefresh />
            </IconButton>
        </Paper>
    );
};

MailDayHourFilter.propTypes = {
    resetDaysHours: PropTypes.func,
    selectDayHour: PropTypes.func,
    statsByDayAndHour: PropTypes.array,
    totalMails: PropTypes.number
};

export default MailDayHourFilter;
