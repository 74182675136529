import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: '2px',
        top: '2px'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    dialogPaper: {
        width: 'auto'
    },
    margin: {
        marginBottom: '5px',
        marginTop: '5px'
    },
    titleDialogBox: {
        color: theme.palette.grey.dark,
        marginBottom: 0,
        marginLeft: '2%',
        marginTop: 0
    },
    titleDialogBoxCompany: {
        color: theme.palette.primary.dark
    },
    titleDialogBoxProduct: {
        color: theme.palette.primary.main,
        marginBottom: 0,
        marginLeft: '2%',
        marginTop: 0
    }
}));
