/**
  * Method to validate an email
  *
  * @param email <string> the email to validate
  * @returns <boolean> if email is valid or not
  */
export const validateEmail = email => {
    if (!email || typeof email !== 'string') return false;

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

/**
  * Method to validate a password
  *
  * @param password <string>
  * @returns <boolean> if password is valid or not
  */
export const validatePassword = password => {
    if (!password || typeof password !== 'string') return false;

    const re = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?=_€£$;&@+-])(?=.{8,})');
    return re.test(password);
};
