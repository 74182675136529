// Material UI
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    button: {
        height: '100%',
        marginLeft: '4px'
    },
    containerClass: {
        height: '100%'
    },
    datePicker: {
        maxWidth: '120px'
    },
    dialogInfoPrice: {
        alignItems: 'center',
        color: theme.palette.info.light,
        display: 'flex',
        fontSize: '18px',
        marginRight: '6px'
    },
    dialogInfoText: {
        fontSize: '16px',
        fontWeight: 600,
        marginTop: '6px'
    },
    dialogSubInfoText: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '14px',
        fontWeight: 500,
        marginTop: '4px'
    },
    filtersBottomContainer: {
        height: '40%',
        marginTop: '2px',
        width: '100%'
    },
    footer: {
        display: 'flex',
        marginTop: 'auto',
        width: '100%'
    },
    formControlMode: {
        marginLeft: 'auto',
        marginTop: '8px',
        minWidth: 120
    },
    header: {
        display: 'flex',
        marginBottom: '6px',
        marginTop: '6px',
        width: '100%'
    },
    hide: {
        display: 'none'
    },
    itemClass: {
        padding: '4px'
    },
    link: {
        marginLeft: 'auto',
        textDecoration: 'none'
    },
    loaderContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    },
    pagination: {
        borderBottom: 0
    },
    paperContainer: {
        flexGrow: 1,
        height: 'calc(30% - 50px)',
        width: '100%'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%'
    },
    searchBar: {
        flexGrow: 1,
        marginLeft: '5px',
        marginRight: '5px'
    },
    sliderClass: {
        height: '100%'
    },
    tableContainer: {
        height: '100%',
        width: '100%'
    },
    tablePagination: {
        width: 'auto'
    },
    tableTitle: {
        alignItems: 'center',
        backgroundColor: theme.palette.grey.ultralight,
        borderRadius: '4px',
        color: theme.palette.grey.dark,
        display: 'flex',
        fontFamily: 'roboto',
        fontSize: '13px',
        fontWeight: 500,
        justifyContent: 'space-between',
        marginBottom: '4px',
        marginTop: '2px',
        paddingLeft: '14px',
        paddingRight: '4px',
        width: '100%'
    }
}));
