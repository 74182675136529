import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//Styles
import { useStyles } from 'components/filter/TrendFilters/TrendBarFilter/TrendBarFilter.style';
//Mui components
import {
    Paper,
    IconButton,
} from '@mui/material';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import SortIcon from '@mui/icons-material/Sort';
import IconRefresh from '@mui/icons-material/Cached';
//Pcp components
import SimpleBar from 'simplebar-react';
import TrendDataBar from 'components/filter/TrendFilters/TrendBarFilter/TrendDataBar';
//Locales
import translate from 'locales/i18n';
import AccountStore from 'stores/AccountStore';

const TrendBarFilter = ({
    dataToDisplay,
    filterData,
    isSites,
    reset
}) => {
    const classes = useStyles();

    const [state, setState] = useState({
        alpha: true,
        percent: false,
        sort: true,
        value: 2
    });
    const { alpha, percent, sort, value } = state;

    const [selectedData, setSelectedData] = useState('');

    useEffect(() => {
        if (selectedData) {
            filterData(selectedData,isSites);
            setSelectedData('');
        }
    }, [selectedData]);

    const getHigherCardinality = () => {
        let maxCard = 0;
        dataToDisplay.forEach((data) => {
            if (Math.abs(data.value) > maxCard) maxCard = Math.abs(data.value);
        });
        return maxCard;
    };

    const handleChange = (v) => {
        if (v === 0)
            setState({
                alpha: !alpha,
                percent,
                sort,
                value: v
            });

        if (v === 1)
            setState({
                alpha,
                percent,
                sort: !sort,
                value: v
            });

        if (v === 2)
            setState({
                alpha,
                percent: !percent,
                sort,
                value: v
            });
    };

    const orderData = () => {
        let newData = dataToDisplay;
        if (value === 0) {
            newData.sort((a,b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                if (nameA < nameB) return alpha? 1:-1;
                if (nameA > nameB) return alpha? -1:1;
                return 0;
            });
        } else if (value === 1) {
            newData.sort((a,b) => {
                if (a.value === 'NaN' && b.value === 'NaN') {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) return -1;
                    return 1;
                }
                if (a.value === 'NaN') return sort? -1:1;
                if (b.value === 'NaN') return sort? 1:-1;
                if (Math.abs(a.value) < Math.abs(b.value)) return sort? -1:1;
                if (Math.abs(a.value) > Math.abs(b.value)) return sort? 1:-1;
                return 0;
            });
        } else if (value === 2) {
            newData.sort((a,b) => {
                if (a.value === 'NaN' && b.value === 'NaN') {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) return -1;
                    return 1;
                }
                if (a.value === 'NaN') return percent? -1:1;
                if (b.value === 'NaN') return percent? 1:-1;
                if (a.value < b.value) return percent? -1:1;
                if (a.value > b.value) return percent? 1:-1;
                return 0;
            });
        }
        if (AccountStore.getCurrentAccount().isRetailer() && isSites) {
            const retailerSite = newData.find(s => s.id === AccountStore.getCurrentAccount().site.id);
            newData = [retailerSite].concat(newData.filter(s => s.id !== AccountStore.getCurrentAccount().site.id));
        }
        return newData;
    };

    return <Paper className={classes.paper}>
        <div className={classes.title}>
            <div>
                <IconButton onClick={() => handleChange(0)} color={state.value === 0 ? 'primary':'grey'}>
                    <SortByAlphaIcon />
                </IconButton>
                <IconButton onClick={() => handleChange(1)} color={state.value === 1 ? 'primary':'grey'}>
                    <SortIcon />
                </IconButton>
                <IconButton onClick={() => handleChange(2)} color={state.value === 2 ? 'primary':'grey'}>
                    <mat-icon inline='true' matSuffix>%</mat-icon>
                </IconButton>
            </div>
            {isSites ? translate.i18n('SITES'):translate.i18n('CATEGORIES')}
            <div style={{ width: '110px', textAlign: 'end' }}>
                <IconButton
                    color='primary'
                    onClick={() => reset(isSites)}
                >
                    <IconRefresh />
                </IconButton>
            </div>
        </div>
        <div className={classes.categoryListContainer}>
            <SimpleBar className={classes.simpleBar}>
                {orderData().map((data, index) => <TrendDataBar
                    key={data.id}
                    data={data}
                    isFirstSite={index === 0 && isSites && AccountStore.getCurrentAccount().isRetailer()}
                    higherCardinality={getHigherCardinality()}
                    selectData={() => setSelectedData(data.id)}
                    isSites={isSites}
                />)}
            </SimpleBar>
        </div>
    </Paper>;
};

TrendBarFilter.propTypes = {
    dataToDisplay: PropTypes.array,
    filterData: PropTypes.func,
    isSites: PropTypes.bool,
    reset: PropTypes.func,
};

export default TrendBarFilter;
