// Material UI Components
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    dateLineArrow: {
        cursor: 'pointer',
        display: 'flex',
        height: '100%',
        justifyContent: 'end',
        width: '5%',
        '&:hover': { background: 'none' }
    },
    dateLineArrowRight: {
        marginLeft: '-5%',
        paddingRight: '0px'
    },
    datesLine: {
        backgroundColor: theme.palette.white.secondary,
        display: 'flex',
        flexDirection: 'row',
        height: '40px',
        paddingLeft: '2%',
        paddingRight: '2%',
        zIndex: 1
    },
    datesLineDay: {
        color: theme.palette.primary.main,
        fontSize: '10px',
        fontWeight: 'bold',
        height: '100%',
        textAlign: 'center',
        width: 'calc(95% / 11)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    filtersContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        minHeight: '250px',
        paddingBottom: '12px',
        paddingTop: '12px',
        width: '100%'
    },
    paper: {
        height: '100%',
        padding: '7px 10px 7px 10px',
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    sliderInfoChip: {
        height: '5px',
        position: 'sticky',
        top: 0,
        width: '100%',
        zIndex: 10
    },
    sliderInfoChipContainer: {
        height: '0px',
        left: '0px',
        position: 'sticky',
        top: '0px',
        zIndex: 15
    },
    sliderSite: {
        borderRadius: '3px 0px 0px 3px',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        display: 'flex',
        flexDirection: 'row',
        height: '120px',
        margin: '10px 2% 0 2%'
    },
    sliderSiteContent: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%'
    },
    sliderSiteDay: {
        backgroundColor: 'rgb(255,255,255,0.2)',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        marginLeft: '1px',
        width: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': { width: '0px' }
    },
    sliderSiteLegend: {
        borderRadius: '3px 0px 0px 3px',
        height: '120px',
        width: '5%'
    },
    sliderSiteLogo: {
        marginLeft: '30%',
        marginTop: '14px',
        width: '40%'
    },
    sliderSiteLogoBox: {
        backgroundColor: 'white',
        border: '4px solid',
        borderRadius: '50%',
        cursor: 'pointer',
        height: '60px',
        left: '-25px',
        marginLeft: '0%',
        position: 'relative',
        top: '30px',
        width: '60px'
    },
    statsBoxContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between'
    },
    statsText: {
        color: theme.palette.primary.main,
        fontFamily: 'roboto',
        fontSize: '100%',
        fontWeight: 'bold',
        margin: '0px 0 0 0'
    },
    statsTextSub: {
        fontSize: '80%'
    },
    statsTextValue: {
        color: theme.palette.primary.dark,
        fontFamily: 'roboto',
        fontSize: '270%',
        fontWeight: 'bold',
        margin: '3px 0 0 0'
    },
    timelineContent: {
        marginLeft: '1%',
        marginTop: '1%',
        paddingBottom: '20px',
        width: '98%'
    },
    slidesContainer: {
        width: '100%',
        lineHeight: '0'
    }
}));

