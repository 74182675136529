// Material UI
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    addRuleButton: {
        marginBottom: '10px',
        marginRight: '10px',
        width: 'auto'
    },
    button: {
        marginBottom: '8px'
    },
    itemsAssociated: {
        fontWeight: 600
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '25%'
    },
    link: {
        textDecoration: 'none'
    },
    listPrimaryText: {
        fontSize: '13px',
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    listSecondaryText: {
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: 1.43,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    noRuleSelectedText: {
        alignItems: 'center',
        color: 'rgb(160 160 160)',
        display: 'flex',
        fontSize: '35px',
        fontWeight: 600,
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    },
    resumeContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginLeft: '10px',
        width: 'calc(75% - 10px)'
    },
    root: {
        display: 'flex',
        height: '100%',
        overflowX: 'auto',
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%'
    },
    ruleContent: {
        paddingRight: '100px'
    },
    ruleDateText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    ruleNameText: {
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    rulesContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: 'calc(100% - 41px)'
    },
    ruleSelectedContent: {
        backgroundColor: theme.palette.grey.light,
        paddingRight: '100px'
    },
    rulesList: {
        marginBottom: 'auto',
        marginTop: '4px',
        overflow: 'auto'
    },
    searchTextField: {
        marginLeft: '5%',
        marginTop: '15px',
        width: '90%'
    },
    title: {
        margin: theme.spacing(4, 0, 2)
    }
}));
