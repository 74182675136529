const locales = {
    ABOUT: 'À propos',
    ABOUT_DIALOG: {
        CONTACT: 'Nous contacter ',
        TEXT: 'Fait avec amour à Montpellier',
        TITLE: 'A propos de l\'application PriceComparator.pro'
    },
    ADBOX: {
        NAME: 'AdBox',
        TITLE1: 'Analyse des mails entre le',
        TITLE2: 'et le',
        WEEKLY_MAIL_CONCENTRATION: 'Concentration de mails par heure',
        MAIL_DAYS_TITLE: 'Nombre de mails par jour',
        MAIL_HOURS_TITLE: 'Nombre de mails par heure',
        MAIL_PIE_TITLE: 'Nombre de mails envoyés',
        NUMBER_OF_MAILS: 'Nombre de mails',
        TAG_CLOUD_TITLE: 'Répartition des mots dans les objets des mails'
    },
    ACCOUNT: 'Compte',
    ACCOUNT_NOT_FOUND: 'Le compte n\'a pas été trouvé',
    ACTIVE: 'Actif(s)',
    ACTUAL_SLIDES_MESSAGE: 'Slides actuels',
    ADD: 'Ajouter',
    ADD_AN_ITEM: 'Ajouter un article',
    ADD_CATEGORY: 'Ajouter la catégorie {name} ?',
    ADD_ITEM: 'Ajouter article',
    ADD_OR_FIND_CATEGORY: 'Ajouter ou trouver une catégorie',
    ADD_RULE: 'Ajouter une règle',
    ADD_URL: 'Ajouter une URL',
    ADDED: 'Ajoutés',
    ADJUSTED_PRICES: 'Prix ajusté(s)',
    ADMIN: 'Admin',
    ALERTS: 'Alerte(s)',
    ALL: 'Tout',
    ALL_COMPETITORS: ' de tous mes concurrents',
    ALL_ITEMS: 'Tous les produits',
    ALL_STOCKS: ' quels que soient les stocks',
    ALONE_SELLER: 'Seul vendeur',
    ALPHA_ORDER: 'Ordre alphabétique',
    AMONG_CHEAPEST: 'Parmi les moins chers',
    AMONG_MOST_EXPENSIVE: 'Parmi les plus chers',
    ANALIZE: 'Analyser',
    ANALYZE: 'Analyse',
    ANALYZE_BY_CATEGORY: 'Analyse par catégorie',
    AND: 'et',
    APPLICATIONS_DATES: 'Dates d\'applications',
    APPLIED_RULE: 'Règle appliquée',
    ARTICLES: 'Produits',
    ASSOCIATED_ARTICLES_LIST: 'Liste des produits associés',
    ASSOCIATED_RULE: 'Règle associée',
    ATTRIBUTE_ERROR: 'Un attribut est vide, dupliqué ou incorrect',
    ATTRIBUTE_NAME: 'Nom de l\'attribut',
    ATTRIBUTE_REGISTER_WARN: 'Attention, les modifications apportées ne seront sauvegardées qu\'après avoir cliquer sur ENREGISTRER dans la page principale',
    ATTRIBUTE_VALUE: 'Valeur de l\'attribut',
    AVAILABILITY: 'Disponibilité',
    AVAILABLE_SOON: 'Disponible bientôt',
    AVG_PRICE: 'au prix moyen',
    BASED_ON: 'Basé sur le',
    BASED_ON_ELEM: 'basé sur le {elem}',
    BEGINNING: 'Début',
    BETWEEN: 'Entre',
    BRAND: 'Marque',
    BUYING_PRICE: 'Prix d\'achat',
    BY_VALUE: 'de {value}',
    CANCEL: 'Annuler',
    CANNOT_RETRIEVE_USER_INFO: 'Impossible de récupérer les informations de l\'utilisateur',
    CATALOG_SEARCH: 'Rechercher dans le catalogue',
    CATEGORIES: 'Catégories',
    CATEGORY: 'Catégorie',
    CATEGORY_FILTER_EXPLANATION_1: 'Ce filtre liste l\'ensemble de vos catégories selon plusieurs critères :',
    CATEGORY_FILTER_EXPLANATION_2: 'Le positionnement des prix du concurrent par rapport aux vôtres (plus la couleur est foncée, moins vos concurrents sont chers que vous).',
    CATEGORY_FILTER_EXPLANATION_3: 'Ainsi que le nombre de produits présent dans les filtres sélectionnés sur le nombre de produits total (la longueur et les cardinalités à droite du filtre).',
    CATEGORY_FILTER_EXPLANATION_4: 'Les catégories sont cliquables afin d\'affiner votre recherche, ainsi que les options de tri en haut du filtre.',
    CATEGORY_FILTER_EXPLANATION_PRICING_ENGINE_1: 'Ce filtre liste l\'ensemble de vos catégories selon plusieurs critères actuellement et après repricing :',
    CATEGORY_FILTER_EXPLANATION_PRICING_ENGINE_3: 'Ainsi que le nombre de produits présent dans les filtres sélectionnés sur le nombre de produits total (la longueur et les cardinalités au centre du filtre)',
    CATEGORY_ID: 'Catégorie',
    CATEGORY_MANAGEMENT: 'Gestion de la catégorie "{name}"',
    CATEGORY_NAME: 'Nom de la catégorie',
    CATEGORY_NOT_FOUND: 'Catégorie non trouvée',
    CHANGE_ATTRIBUTES: 'Modifier les attributs',
    CHANGE_PASSWORD: 'Modifier le mot de passe',
    CHANGE_URL: 'Modifier l\'url',
    CHANGE_VIEW: 'Changer de vue',
    CHANGES: 'changements',
    CHANGES_BY_WEEK: 'changements par semaine',
    CHANGES_ON_SELECTED_PERIOD: 'Changements sur la période selectionnée',
    CHEAPEST: 'Moins cher',
    CHECK_CODE: 'Pour votre sécurité, nous devons vérifier votre identité. L\'équipe de sécurité de PriceComparator vient de vous envoyer un code par email sur {email}. Veuillez le saisir ci-dessous.',
    CHOOSE_A_DELAY: 'Choisir un délais',
    CHOOSE_A_NEW_PASSWORD: 'Choisir un nouveau mot de passe',
    CHOOSE_A_REPORT: 'Choisissez un rapport',
    CHOOSE_CONFIG: 'Choisir une configuration',
    CLICK_HERE: 'cliquez ici',
    CLOSE: 'Fermer',
    CODE_NOT_GOOD: 'Le code n\'est pas bon',
    COLOR: 'Couleur',
    CONFIGURATION: 'Configuration',
    CONFIRM_DELETE_HISTORY: 'Si vous faites ceci, l\'historique du produit associé sera effacé !',
    CONFIRM_DELETE_ITEM: 'Êtes vous sur de vouloir supprimer ce produit? Cette action est irréversible.',
    CONFIRM_DELETE_RULE: 'Voulez vous vraiment supprimer la règle {ruleName} ?',
    CONFIRM_DELETE_USER: 'Voulez vous vraiment supprimer cette utilisateur?',
    CONFIRM_EDIT_ITEM: 'Êtes vous sur de vouloir modifier ce produit? Cette action est irréversible.',
    CONFIRM_PASSWORD: 'Confirmation du mot de passe',
    CONTACT_SUPPORT: 'Pour contacter le support',
    COPY_TO_CLIPBOARD: 'Copier dans le presse-papier',
    COUNT_OF_PRODUCTS: 'Nombre de produits',
    COUNT_PRODUCTS: '({count} Produit[s])',
    CRITICAL_CATEGORY: 'Catégorie critique',
    CRITICAL_PRICES: 'Prix critique(s)',
    CURRENT_PASSWORD: 'Mot de passe actuel',
    CURRENT_SLIDES: 'Slide(s) actuelle(s)',
    DATE: 'Date',
    DAYS: 'Jours',
    DELAY: 'Délais',
    DELETE: 'Supprimer',
    DELETE_ARTICLE: 'Supprimer ce produit',
    DELETE_ATTRIBUTE: 'Supprimer l\'attribut',
    DELETE_CATEGORY: 'Êtes vous sure de vouloir supprimer la catégorie {name}, il y a surement des produits qui lui sont associés?',
    DELETED: 'Supprimés',
    DELETED_SLIDES: 'Slide(s) supprimé(s)',
    DETAILS: 'Détails',
    DIALOG_ADD_URL: {
        ADD: 'Ajouter',
        ADD_CONFIG: 'Ajouter une config',
        BRAND: 'Marque',
        CATEGORY: 'Catégorie',
        CONFIG_NAME: 'Nom de la config',
        CONFIGS: 'Configs',
        CURRENT_ARTICLE: 'Article actuellement sélectionné pour',
        DELETE: 'Supprimer',
        DELETE_CONFIRM: 'Voulez vous vraiment supprimer cette URL ?',
        DELETE_URL: 'Supprimer l\'URL',
        EAN: 'EAN',
        ENTER_CONFIGS: 'Ajouer des configurations',
        ENTER_OTHER: 'Entrer une autre proposition',
        ENTER_URL: 'Entrez une URL ou choisissez en un dans les suggestions',
        FINISH_CONFIG: 'Cliquez pour finir de configurer l\'url',
        MULTIPLY_BY: 'Multiplier par',
        NEW_PRODUCT: 'L\'URL ne correspond à aucun article en suggestion',
        NO_PRODUCT: 'Aucun article séléctioné actuellement',
        NONE: 'Aucune',
        NOT_SOLD: 'Non vendu',
        NV: 'L\'article n\'est plus disponible sur cette URL ou n\'est pas vendu sur le site',
        NV_BLL_URL: 'Le produit n\'est plus disponible sur cette URL',
        NV_URL: 'L\'article n\'est pas vendu par ce site',
        OPERATIONS: 'Opérations',
        PENDING_PRODUCT: 'L\'extraction des données pour cet article est en cours',
        PRICE: 'Prix',
        REF: 'Ref',
        RRP: 'RRP',
        SAVE_CHANGES: 'Sauvegarder les changements',
        SAVE_WOUT_CONF: 'Sauvegarder sans passer par la configuration',
        SEARCH_GOOGLE: 'Rechercher sur google shopping',
        SEARCH_IN_SUGGESTIONS: 'Rechercher parmi les suggestions',
        SEE_ON_SITE: 'Voir sur le site',
        SEE_PROPS: 'Voir les proposition',
        SELECT: 'Selectionner',
        SUGGESTION_INFO_MESSAGE: 'Les informations affichées dans les suggestions peuvent être légerement différentes de celles sur le site actuellement.',
        SUGGESTIONS: 'Suggestions',
        URL_ERROR: 'L\'url saisie ne correspond pas au site surveillé',
        VALUE: 'Valeur',
        YOUR_ARTICLE: 'Votre article'
    },
    DIFF_ALERTING_PRICE: 'Différence par rapport au prix d\'alerte',
    DIFF_RETAILER_PRICE: 'Différence par rapport à votre prix',
    DISCOUNT: 'Discount',
    DISCOUNT_RRP: 'Discount RRP',
    DISTRIBUTION_OF_REPRICED_PRICES: 'Répartition des prix repricés',
    DND_ATTRIBUTES: 'Glisser/déposer les attributs pour en modifier l\'ordre',
    DO_NOT_HAVE_MODULE: '(Vous n\'avez pas ce module)',
    DROPPING_PRICES: 'Prix à la baisse',
    EACH_ITEM_CAN_HAVE_A_RULE: 'Vous pouvez associer plusieurs règles à un produit. Le Pricing Engine tentera de les appliquer dans l\'ordre, jusqu\'à trouver une règle qui s\'applique. Il est toutefois possible qu\'aucune règle ne soit applicable (icône rouge).',
    EDIT: 'Editer',
    EDIT_ARTICLE: 'Modifier le produit',
    EMAIL: 'Email',
    EMAIL_NOT_REGISTERED: 'Votre email n\'est associé à aucun compte.',
    END_DATE: 'Date de fin',
    ENDING: 'Fin',
    EQUAL_PRICES: 'Sans effet sur le prix actuel',
    ERR_PRICING_ENGINE_ALREADY_USED_RULE_NAME: 'Le nom de la règle est déjà utilisé par une autre règle',
    ERROR_ADD_ARTICLE: 'Le produit ne peut pas être ajouté',
    ERROR_AUTHENTICATION_FAILURE: 'Votre mot de passe n\'est pas bon, nombre d\'essais restants : {retries}.',
    ERROR_BLOCKED_USER: 'Votre utilisateur est bloqué, veuillez contacter le support de PriceComparator.',
    ERROR_CREATE_RULE: 'La règle ne peut pas être créée',
    ERROR_DELETE_ARTICLE: 'Le produit ne peut pas être supprimer',
    ERROR_EMAIL: 'Veuillez entrer un email valide.',
    ERROR_FIELD_EMPTY: 'Veuillez remplir ce champs',
    ERROR_FILL_ALL_FIELDS: 'Remplissez tous les champs requis.',
    ERROR_INVALID_PRODUCT_ID: 'Id produit incorrect',
    ERROR_MARKETPLACE: 'Cette url doit être un url de marketplace.',
    ERROR_OPEN_SUGGESTIONS: 'Le nom de le produit est nécessaire afin de faire une recherche de suggestions',
    ERROR_REQUIRED_ATTRIBUTES: 'Tous les attributs requis doivent être remplis',
    ERROR_RETRIEVE_ARTICLE: 'Le produit ne peut pas être récupéré',
    ERROR_RETRIEVE_ARTICLES: 'Les produits ne peuvent pas être récupérés',
    ERROR_RETRIEVE_EMAILS: 'Les emails ne peuvent pas être récupérés',
    ERROR_RETRIEVE_HISTORY: 'L\'historique ne peut pas être récupéré',
    ERROR_RETRIEVE_PERFORMANCES: 'Les performances ne peuvent pas être récupérées',
    ERROR_RETRIEVE_RULES: 'Les règles ne peuvent pas être récupérées',
    ERROR_RETRIEVE_SLIDERS: 'Les slides ne peuvent pas être récupérés',
    ERROR_RETRIEVE_SUGGESTIONS: 'Les suggestions ne peuvent pas être récupérées',
    ERROR_UPDATE_ARTICLE: 'Le produit ne peut pas être mis à jour',
    ERROR_UPDATE_CATEGORY: 'Erreur lors de la modification de la catégorie',
    ERROR_UPDATE_RULE: 'La règle ne peut pas être mise à jour',
    ERROR_USER_SELECTED_NOT_EXIST: 'L\'utilisateur n\'existe pas.',
    EVOLUTION_OF_PRICE_AND_STOCK: 'Évolution du prix et stock',
    EXPORT_DATA: 'Exporter données',
    EXPORT_REPORTS: 'Exporter le rapport',
    EXTRACTING: 'Extraction',
    FILE_EXPORTED: 'Le fichier à bien été exporté',
    FILE_NAME: 'Nom du fichier',
    FILL_PASSWORD: 'Veuillez remplir les mots de passes.',
    FILTERS: 'FILTRES',
    FIRST_COLUMN_IS_YOURS: 'Votre colonne : Vos prix actuels',
    FIRSTNAME: 'Prénom',
    FORBIDDEN_MODULE: 'Vous n\'avez pas accès à cette fonctionnalité',
    FRIDAY: 'vendredi',
    FROM: 'Du',
    GENDER: 'Genre',
    GIVE_REGISTERED_EMAIL: 'Envoyez l\'email associé à votre compte',
    HELP: 'Aide',
    HIDE_FILTERS: 'Masquer les filtres',
    HOME: 'Accueil',
    HOW_IT_WORKS: 'Comment ça marche ?',
    I_WANT_TO_BE: 'Je veux être ',
    ICON_INDICATE_INFORMATIONS: 'Point d\'information',
    ICON_INDICATE_REPRICED_PRICE_AND_VARIATION: 'Nouveau prix calculé à partir de la règle appliquée.',
    ICON_INDICATE_REPRICING_ERROR: 'Aucune règle associée n\'est applicable (paramètre(s) du produit manquants ou incohérence de calcul).',
    IF_YOU_LEAVE: 'Si vous partez sans sauvegarder, vos changements seront perdus.',
    IMG_URL: 'URL Image',
    IMPOSSIBLE_REPRICING: 'Repricing impossible',
    IN: 'en',
    INSERT_CODE: 'Rentrez le code reçu',
    INVALID_DATE: 'La date n\'est pas valide',
    INVALID_URL: 'Url invalide',
    ITEM_DELETE_CONFIRM: 'Voulez vous vraiment supprimer le produit suivant : "{name}"?',
    ITEMS: 'Produits',
    ITEMS_SELECTION: 'Sélection des produits',
    LASTNAME: 'Nom',
    LAUNCH_SEARCH: 'Lancez une recherche',
    LEADERSHIP_FILTER_1_RETAILER: 'Ce filtre représente le positionnement des prix de chacun de vos concurrents par rapport au marché :',
    LEADERSHIP_FILTER_1_SUPPLIER: 'Ce filtre représente le positionnement des prix de chacun de vos distributeurs par rapport au marché :',
    LEADERSHIP_FILTER_2: 'Vous pouvez survoler les sections afin d\'avoir plus d\'informations, ainsi que les cliquer afin d\'affiner votre recherche.',
    LEADERSHIP_FILTER_PRICING_ENGINE: 'Ce filtre représente le positionnement des prix de chacun de vos concurrents par rapport au marché actuellement et après repricing :',
    LEADERSHIP_TITLE: 'Positionnement prix par rapport aux autres',
    LEGEND: 'Légende :',
    LESS_THAN: 'Inférieur à {number}',
    LESS_THAN_LIMIT: 'Inférieur au seuil mini de {min} à {max}',
    LIBRARY: 'Librairie',
    LIMIT: 'Limite',
    LIMIT_PRICE: 'Prix limite',
    LIST_OF_PRODUCTS: 'LISTE DES PRODUITS',
    LIST_OF_REPRICED_ITEMS: 'LISTE DES PRODUITS',
    LISTING: 'Liste',
    LOGIN: 'Identifiant',
    LOGIN_BUTTON: 'Se connecter',
    LOGOUT: 'Déconnexion',
    MAILS: 'E-mails',
    MANAGE_CATEGORIES: 'Gestion des catégories',
    MANAGE_PRODUCTS: 'Gérer les produits',
    MANAGE_WEBSITES: 'Gérer les sites',
    MAP_FILTER_EXPLANATION_1: 'Cette carte vous indique la position géographique de vos concurrents dans le monde et vous donne quelques informations complémentaires :',
    MAP_FILTER_EXPLANATION_2: 'La taille, qui représente le nombre de produits vendu par votre client en commun avec votre catalogue.',
    MAP_FILTER_EXPLANATION_3: 'La couleur, qui vous indique votre position par rapport aux prix de votre concurrent.',
    MAP_FILTER_EXPLANATION_4: '(Les différents points sur la carte sont cliquables si vous voulez sélectionner des sites spécifiques)',
    MARGIN: 'Marge',
    MARKETPLACE_REPLACE_URL: '{siteName} est une marketplace. PriceComparator est configuré pour récupérer l\'ensemble des offres proposées pour le produit surveillé. Si besoin, l\'url que vous venez de saisir sera remplacée par l\'url correspondant à la page contenant toutes les offres. Aucune action de votre part n\'est requise.',
    MARKETPLACE_REPORTS: 'Rapports Marketplace',
    MAX_PRICE: 'le plus cher',
    MAXIMUM_SALE_PRICE: 'Prix de vente maximum',
    MIN_PRICE: 'le moins cher',
    MINIMUM_SALE_PRICE: 'Prix de vente minimum',
    MISSING_RETAIL_PRICE: 'Veillez renseigner le prix d\'achat',
    MISSING_RRP: 'Veuillez renseigner le RRP',
    MISSING_RRP_PERCENTAGE: 'Veuillez renseigner le discount RRP',
    MODE: 'Mode',
    MONDAY: 'lundi',
    MORE_EXPENSIVE: 'Plus cher',
    MORE_THAN: 'Supérieur à {number}',
    MORE_THAN_RRP: 'Supérieur au RRP de {min} à {max}',
    MOST_EXPENSIVE: 'Le plus cher',
    MP_RECAP_NOT_FOUND: 'Les rapports de marketplace n\'ont pas été trouvés',
    MUST_CONTAINS_: 'Il doit contenir au moins un chiffre (0 … 9).',
    MUST_CONTAINS_8_CHAR: 'Il doit contenir au moins 8 caractères.',
    MUST_CONTAINS_ONE_LOWER_CASE: 'Il doit contenir au moins une lettre minuscule (a … z).',
    MUST_CONTAINS_ONE_UPPER_CASE: 'Il doit contenir au moins une lettre majuscule (A … Z).',
    MUST_CONTAINS_SPECIAL_CHAR: 'Il doit contenir au moins un caractère spécial parmi !?=_€£$;&@+-',
    NAME: 'Nom',
    NAME_IS_MISSING: 'Vous devez saisir un nom avant d\'enregistrer la règles',
    NAME_OF_THE_RULE: 'Nom de la règle (obligatoire)',
    NEITHER_MOST_EXPENSIVE_NOR_CHEAPER: 'Ni les plus chers, ni les moins chers',
    NEW_SLIDES: 'Nouveau(x) slide(s)',
    NEW_SLIDES_MESSAGE: 'Nouveaux slides',
    NEXT: 'Suivant',
    NO_ACCOUNTS: 'Auncun compte n\'est associé à votre utilisateur',
    NO_BEGINNING_DATE: 'Aucune date de début',
    NO_CATEGORY: 'Aucune catégorie',
    NO_DATA_FOUND: 'Aucune donnée trouvée',
    NO_DATA_YET: 'Pas encore de relevés',
    NO_DATE: 'Aucune date',
    NO_EFFECT: 'Sans effet',
    NO_EVALUATION: 'Pas d\'évaluation',
    NO_INFORMATION: 'Aucune information',
    NO_ITEM_FOUND: 'Aucun produit trouvé',
    NO_LIMIT_DATE: 'Aucune date limite',
    NO_MAIL_FOR_THIS_DATES: 'Aucun mail pour ces dates',
    NO_NEW_SLIDES_MESSAGE: 'Pas de nouveau slides pour cette date',
    NO_PRICE: 'Aucun prix',
    NO_PRICE_FOUND: 'Prix non trouvé',
    NO_PROMO_PRICE: 'Prix hors promo',
    NO_RULE_ASSOCIATED: 'Aucune règle associée',
    NO_RULE_SELECTED: 'Aucune règle sélectionnée',
    NO_SLIDES_MESSAGE: 'Pas de slides',
    NO_SUGGESTIONS_FOUND: 'Pas de suggestions trouvées',
    NO_SUGGESTIONS_FOUND_FOR: 'Pas de suggestions trouvées pour "{text}"',
    NOT_APPLICABLES_RULES: 'Règle non-applicable',
    NOT_FOUND: '{dataName} non trouvé',
    NOT_FOUND_EVAL: 'Non trouvé',
    NOT_SOLD: 'Non vendu',
    NOT_SOLD_BELL: 'Non vendu temporairement',
    OF: 'de',
    OF_MY: 'de mon',
    OLD_PASSWORD_NOT_GOOD: 'Votre ancien mot de passe n\'est pas valide.',
    ONLY_WITHOUT_RULES: 'Seulement sans règle associée',
    OPEN: 'Ouvrir',
    OPEN_MENU: 'Ouvrir le menu',
    OPPONENTS_AVERAGE: 'Moyenne des concurrents',
    OPPORTUNITY: 'Opportunité(s)',
    OPTIONAL_ATTRIBUTES: 'Attributs optionnels',
    OTHERS: 'Autres',
    PAGE_NOT_FOUND: 'Cette page n\'a pas pu être trouvée.',
    PASSWORD: 'Mot de passe',
    PASSWORD_DOES_NOT_MATCH_CRITERIA: 'Votre mot de passe ne correspond pas aux critères.',
    PASSWORD_FORGOTTEN: 'Mot de passe oublié ?',
    PASSWORD_HAS_BEEN_RESET: 'Votre mot de passe a été changé, retournez au login.',
    PASSWORDS_DOES_NOT_MATCH: 'Les mots de passes sont différents.',
    PENDING_EXTRACTION: 'L\'extraction du prix de cette référence sera effectuée à partir de demain, merci de votre compréhension',
    PERCENTAGE: 'Pourcentage',
    PERFORMANCE: 'Performance',
    POSITIONING: 'Positionnement',
    POSITIONING_IS_MISSING: 'Sélectionner un positionnement',
    POSITIONING_KPIS: {
        AVG_MARGIN: 'Marge moyenne',
        AVG_POSITION: 'Position moyenne',
        AVG_PRICE_DIF: 'Écart au prix moyen',
        TITLE: 'ÉVOLUTION DE VOTRE POSITIONNEMENT'
    },
    PREVIOUS: 'Précédent',
    PRICE: 'Prix',
    PRICE_ADVISING: 'Conseil de prix',
    PRICE_BASKETS_FILTER_1_SUPPLIER: 'Ce filtre représente le positionnement des prix de vos distributeurs par rapport à votre "RRP" et votre "Limit" :',
    PRICE_BASKETS_FILTER_2: 'Vous pouvez survoler les colonnes afin d\'avoir plus d\'informations, ainsi que les cliquer afin d\'affiner votre recherche.',
    PRICE_BASKETS_FILTER_3_RETAILER: 'Le signe "=" représente la colonne des prix égaux aux vôtres.',
    PRICE_BASKETS_FILTER_3_SUPPLIER: 'Le sigle "RRP" et "Limit" représente la colonne des prix égaux au RRP ou à la limite.',
    PRICE_BASKETS_FILTER_PRICING_ENGINE: 'Ce filtre représente le positionnement de vos prix par rapport à ceux de vos concurrents de "en dessous -50%" à "au dessus de 50%" actuellement et après repricing :',
    PRICE_BETWEEN_RRP_LIMIT: 'Prix compris entre le seuil mini et le RRP',
    PRICE_EQUAL_LIMIT: 'Prix égal au seuil mini',
    PRICE_EQUAL_RRP: 'Prix égal au RRP',
    PRICE_LIKE_YOURS: 'Prix pareils que les vôtres',
    PRICE_MOVEMENTS: 'Mouvements de prix',
    PRICE_SECTION: 'Section prix',
    PRICE_STATS: 'Prix détaillés',
    PRICES_BASKETS_TITLE_RETAILER: 'Prix concurrents comparés aux vôtres',
    PRICES_BASKETS_TITLE_SUPPLIER: 'Prix des revendeurs comparés aux RRP',
    PRICES_FILTERED: 'prix filtrés',
    PRICES_LESS_EXPENSIVE: '{number}% des prix sont moins chers',
    PRICES_LESS_EXPENSIVE_BR: '{number}% des prix </br> sont moins chers',
    PRICING_ENGINE: 'Pricing Engine',
    PRICING_ENGINE_ANALYZE: 'Analyse Pricing Engine',
    PRICING_ENGINE_INFO: {
        ASSOCIATE_RULE_TEXT: 'à votre produit : Sélectionnez une règle dans la partie droite de l\'écran (i.e. section Librairie des règles) puis faites-la glisser jusqu\'au rang auquel vous souhaitez qu\'elle soit appliquée.',
        ASSOCIATE_RULE_TITLE: 'Pour associer une nouvelle règle',
        DELETE_RULE_TEXT: 'actuellement associé à votre produit : Cliquez simplement sur la règle dans la partie gauche (i.e. section Règles de repricing actuelles) puis faites-la glisser dans la librairie.',
        DELETE_RULE_TITLE: 'Pour retirer une règle',
        EDIT_RULE_TEXT: ' : Sélectionnez la règle que vous souhaitez monter ou descendre puis déplacez-la au rang désiré par simple glisser.',
        EDIT_RULE_TITLE: 'Pour modifier l\'ordre d\'application des règles',
        RULE_ORDER_TEXT: 'Lorsque votre produit est associé à plusieurs règles, le Pricing Engine applique les règles dans l\'ordre et s\'arrête à la première qui permet d\'obtenir le prix ajusté effectif.',
        RULE_ORDER_TITLE: 'Ordre d\'application des règles : comment ça marche ?',
        TITLE: 'Informations Pricing Engine'
    },
    PRICING_ENGINE_INFORMATIONS: 'Informations Pricing Engine',
    PRICING_ENGINE_LIBRARY: 'Librairie Pricing Engine',
    PRICING_ENGINE_RULE: 'Règle Pricing Engine',
    PRICING_ENGINE_RULE_NOT_FOUND: 'La règle n\'a pas été trouvée.',
    PRICING_ENGINE_RULES: 'Règles Pricing Engine',
    PRICING_ENGINE_WARNING: 'Ce module vous permet de définir votre prix en fonction de vos propres règles de calcul.',
    PRINT: 'Imprimer',
    PRINT_DIALOG_TITLE: 'Pour imprimer cette page, vous devrez appliquer les paramètres suivants après avoir cliqué sur le bouton d\'impression :',
    PRODUCT_NOT_FOUND: 'Le produit n\'a pas été trouvé',
    PRODUCT_NOT_SET: 'Produit non défini',
    PRODUCT_PAGE_NO_HISTORY: 'Aucun historique disponible pour le moment',
    PRODUCT_PAGE_PRICE_SECTION_ALL_COMPETITOR_AVERAGE_PRICE: 'Prix moyen de tous les concurrents',
    PRODUCT_PAGE_PRICE_SECTION_MAX_PRICE: 'Prix max',
    PRODUCT_PAGE_PRICE_SECTION_MIN_PRICE: 'Prix min',
    PRODUCT_PAGE_PRICE_SECTION_NO_PRICE: 'Aucun prix à afficher',
    PRODUCT_PAGE_PRICE_SECTION_PRODUCT_ALERT_PRICE: 'Prix d\'alerte',
    PRODUCT_PAGE_PRICE_SECTION_PRODUCT_AVERAGE_PRICE: 'Prix moyen du produit',
    PRODUCT_PAGE_PRICE_SECTION_PRODUCT_AVG_PRICE: 'Prix moyen',
    PRODUCT_PAGE_PRICE_SECTION_RRP_ALERT_MAX: 'Alerte RRP max',
    PRODUCT_PAGE_PRICE_SECTION_RRP_ALERT_MIN: 'Alerte RRP min',
    PRODUCT_PAGE_PRICE_SECTION_YOUR_AVERAGE_PRICE: 'Votre prix moyen',
    PRODUCT_PAGE_PRICE_SECTION_YOUR_PRICE: 'Votre prix',
    PRODUCT_PAGE_PROMO_FIRST: 'Découvrez la nouvelle fiche produit : nouvelles fonctionnalités, nouveaux graphiques, nouveaux filtres... 🚀',
    PRODUCT_PAGE_PROMO_SECOND: 'Vous pouvez y accéder dès maintenant via le module Smart Price Extractor, en cliquant sur le nom du produit. 🎉🎉🎉 ',
    PROFILE: 'Profil',
    PUBLIC_PRICE: 'Prix public',
    PURCHASING_PRICE: 'P. d\'achat',
    PURCHASING_PRICE_BASED: 'Basé sur le prix d\'achat',
    RAISING_PRICES: 'Prix à la hausse',
    RANK: 'Rang',
    RATING: 'Score',
    REACTIVITY: 'Reactivité',
    RECOMMENDED_RETAIL_PRICE: 'Prix de vente conseillé',
    REF: 'Ref',
    REFERENCE: 'Référence',
    REMOVED_SLIDES_MESSAGE: 'Slides suprimés',
    RENAME: 'Renommer',
    RENAME_CATEGORY: 'Please specify the new category name :',
    REPRICED_PRICES_GROUPS_EXPLANATION_1: 'Ce filtre représente la répartition des prix repricés qui vous sont proposés :',
    REPRICED_PRICES_GROUPS_EXPLANATION_2: 'Vous pouvez survoler les sections afin d\'avoir plus d\'informations, ainsi que les cliquer afin d\'affiner votre recherche.',
    REPRICING: 'Repricing',
    REQUIRED_ATTRIBUTES: 'Attributs requis',
    RESET_FILTERS: 'Réinitialiser les filtres',
    RETAIL_PRICE: 'Prix d\'achat',
    ROW_PER_PAGE: 'Lignes par page',
    RRP: 'RRP',
    RRP_ALERT_PERCENTAGE: 'Pourcentage d\'alerte RRP',
    RULE_APPLICATION_POPPER: {
        FINAL_REPRICED_PRICE: 'Prix repricé final',
        NONE: 'Aucune ',
        PE_ERR_ANALYSIS_DATE_AFTER_PERIOD: 'La période d\'Analyse ne correspond pas aux dates d\'application de la règle',
        PE_ERR_ANALYSIS_DATE_BEFORE_PERIOD: 'La période d\'Analyse ne correspond pas aux dates d\'application de la règle',
        PE_ERR_DATE_AFTER_PERIOD: 'La date d\'analyse est postérieure à la date de fin d\'application de la règle',
        PE_ERR_DATE_BEFORE_PERIOD: 'La date d\'analyse est antérieure à la date de début d\'application de la règle',
        PE_ERR_RULE_INVALID_PRICE_BOUNDS: 'Le Prix Minimum est supérieur au Prix Maximum',
        PE_ERR_RULE_MAXIMUM_PRICE_ELEMENT_UNSUPPORTED_VALUE: 'Autre Attribut du produit manquant',
        PE_ERR_RULE_MAXIMUM_PRICE_MODE_UNSUPPORTED_VALUE: 'Erreur calcul du Prix Maximum : Elément de valeur/pourcentage non-supporté',
        PE_ERR_RULE_MAXIMUM_PRICE_PURCHASING_PRICE_MISSING: 'Prix d\'achat manquant',
        PE_ERR_RULE_MAXIMUM_PRICE_RRP_MISSING: 'RRP manquant',
        PE_ERR_RULE_MINIMUM_PRICE_ELEMENT_UNSUPPORTED_VALUE: 'Autre Attribut du produit manquant',
        PE_ERR_RULE_MINIMUM_PRICE_MODE_UNSUPPORTED_VALUE: 'Erreur calcul du Prix Minimum : Elément de valeur/pourcentage non-supporté',
        PE_ERR_RULE_MINIMUM_PRICE_PURCHASING_PRICE_MISSING: 'Prix d\'achat manquant',
        PE_ERR_RULE_MINIMUM_PRICE_RRP_MISSING: 'RRP manquant',
        PE_ERR_RULE_SKIPPED_VALUE: 'Une règle de rang plus important a abouti à un prix repricé.',
        PE_ERR_STRATEGY_IRRELEVANT_VALUE: 'La Stratégie définie ne permet pas d\'obtenir un prix pertinent (produit non-vendu ou stock incohérent)',
        PE_ERR_STRATEGY_MAXIMUM_PRICE_ELEMENT_UNSUPPORTED_VALUE: 'Autre Attribut du produit manquant',
        PE_ERR_STRATEGY_MAXIMUM_PRICE_IRRELEVANT_VALUE: 'La Borne Supérieure définie ne permet pas le calcul de la Stratégie',
        PE_ERR_STRATEGY_MAXIMUM_PRICE_MODE_UNSUPPORTED_VALUE: 'La Borne Supérieure de la Stratégie ne peut être calculée car un élément de valeur/pourcentage est non-supporté',
        PE_ERR_STRATEGY_MAXIMUM_PRICE_PURCHASING_PRICE_MISSING: 'Prix d\'achat manquant',
        PE_ERR_STRATEGY_MAXIMUM_PRICE_RRP_MISSING: 'RRP manquant',
        PE_ERR_STRATEGY_MINIMUM_PRICE_ELEMENT_UNSUPPORTED_VALUE: 'Autre Attribut du produit manquant',
        PE_ERR_STRATEGY_MINIMUM_PRICE_IRRELEVANT_VALUE: 'La Borne Inférieure définie ne permet pas le calcul de la Stratégie',
        PE_ERR_STRATEGY_MINIMUM_PRICE_MODE_UNSUPPORTED_VALUE: 'La Borne Inférieure de la Stratégie ne peut être calculée car un élément de valeur/pourcentage est non-supporté',
        PE_ERR_STRATEGY_MINIMUM_PRICE_PURCHASING_PRICE_MISSING: 'Prix d\'achat manquant',
        PE_ERR_STRATEGY_MINIMUM_PRICE_RRP_MISSING: 'RRP manquant',
        PE_ERR_STRATEGY_OFFSET_MODE_UNSUPPORTED_VALUE: 'Erreur Calcul : L\'ajustement de prix ne peut être calculée car un élément de valeur/pourcentage est non-supporté',
        PE_ERR_STRATEGY_OFFSET_NAN_VALUE: 'L\'ajustement de prix ne permet pas d\'obtenir un prix pertinent (produit non-vendu ou stock incohérent)',
        PE_ERR_STRATEGY_POSITIONING_UNSUPPORTED_VALUE: 'La Stratégie ne peut être calculée car un élément de Positionnement est non-supporté',
        PE_ERR_STRATEGY_ROUNDING_UNSUPPORTED_VALUE: 'La Stratégie ne peut être calculée car un élément d\'Arrondi est non-supporté',
        RULE_APPLIED: 'Règle appliquée',
        RULE_NOT_APPLICABLE: 'Règle non applicable',
        STRATEGY_PRICE: 'Prix résultant de la stratégie'
    },
    RULE_ASSOCIATED_TO_X_ITEMS: 'Cette règle est associée à {nbOfItems} produits',
    RULE_CREATED: 'Règle crée',
    RULE_DELETED: 'Règle supprimée',
    RULE_UPDATED: 'Règle mise à jour',
    RULES: 'Règles',
    RULES_FILTER_EXPLANATION_1: 'Ce filtre représente la répartition des règles appliquées à des articles : ',
    RULES_FILTER_EXPLANATION_2: 'Si un article à plusieurs règles associés, alors seule celle appliquée ne sera prise en compte. Si aucune règle ne peut s\'appliquer alors toutes les règles associés seront prise en compte.',
    RULES_FILTER_TITLE: 'Répartition des règles appliquées',
    SATURDAY: 'samedi',
    SAVE: 'Enregistrer',
    SAVE_PROFILE: 'Sauvegarder le profil',
    SCRAPING_DATA_NOT_FOUND: 'La scraping data n\'a pas été trouvée',
    SEARCH: 'Chercher',
    SECOND_COLUMN_IS_PRICING_ENGINE: 'Colonne Repricing : Vos prix calculés',
    SECTOR_AVERAGE: 'Moyenne du secteur',
    SEE_IMAGE: 'Visualiser l\'image',
    SEE_MORE: 'Voir plus',
    SELECT: 'Sélectionner',
    SELECT_ALL_PRODUCTS: 'Selectionner tous les produits',
    SELECT_COMPETITORS: 'Sélectionnez les concurrents',
    SELECT_STOCKS: 'Sélectionnez les stocks',
    SELECTED_SITES: 'Sites sélectionnés',
    SELECTED_STOCKS: 'Stocks sélectionnés',
    SELF_ORIENTED_STRATEGY: 'à un prix défini',
    SELLER: 'Vendeur',
    SEND_CODE_AGAIN: 'Renvoyez le code',
    SENDERS: 'Expéditeurs',
    SEPARATOR: 'Séparateur',
    SESSION_EXPIRED: 'Votre session a expirée',
    SETTINGS: 'Paramètres',
    SHIPPING_COST: 'Coûts de livraison',
    SHIPPING_INFORMATION: 'Information de livraison',
    SHORT_FRIDAY: 'Ven',
    SHORT_MONDAY: 'Lun',
    SHORT_SATURDAY: 'Sam',
    SHORT_SUNDAY: 'Dim',
    SHORT_THURSDAY: 'Jeu',
    SHORT_TUESDAY: 'Mar',
    SHORT_WEDNESDAY: 'Mer',
    SHOW_FILTERS: 'Afficher plus de filtres',
    SIGN_IN_ERROR: 'Mot de passe ou adresse mail incorrect',
    SINCE_7_DAYS: 'Depuis 7 jours',
    SINCE_LAST_VALUE: 'Depuis le dernier relevé',
    SITE_NOT_FOUND: 'Le site n\'a pas été trouvé',
    SITES: 'Sites',
    SLIDER: 'Slider',
    SLIDER_DAY_TITLE: 'Nombre de nouveaux slides par jour',
    SLIDER_PIE_TITLE: 'Nombre de nouveaux slides',
    SMART_PRICE_EXTRACTOR: 'Smart Price Extractor',
    SOLD: 'Vendu',
    SOME_COMPETITORS: ' des concurrents sélectionnés',
    SOME_INFORMATIONS: 'Quelques informations importantes avant de démarrer :',
    SOME_STOCKS: ' uniquement pour les stocks sélectionnés',
    SORT_BY: 'Trier par',
    START_DATE: 'Date de début',
    STATUS: 'Statut',
    STOCK: 'Stock',
    STOCK_5_DAYS: '+5 jours',
    STOCK_FILTER_EXPLANATION_1_RETAILER: 'Ce filtre représente vos stocks et ceux de vos concurrents :',
    STOCK_FILTER_EXPLANATION_1_SUPPLIER: 'Ce filtre représente les stocks de chacun de vos distributeurs :',
    STOCK_FILTER_EXPLANATION_2: 'Vous pouvez survoler les sections afin d\'avoir plus d\'informations, ainsi que les cliquer afin d\'affiner votre recherche.',
    STOCK_FILTER_TITLE: 'Disponibilité des stocks',
    STOCK_IN: 'En stock',
    STOCK_MOVEMENTS: 'Mouvements de stock',
    STOCK_NO_INFORMATION: 'Stock non précisé',
    STOCK_PRICE_CHART_TITLE: 'Evolution des prix et stocks',
    STOCK_SOLD_OUT: 'Rupture',
    STOCK_SUP_48H: 'Supérieur à 48h',
    STRATEGY: 'Stratégie',
    STRICTLY_CHEAPEST: 'Strictement moins cher',
    SUCCESS_ADD_ARTICLE: 'Le produit a bien été ajouté',
    SUCCESS_COPY: 'Copié dans le presse-papier',
    SUCCESS_DELETE_ARTICLE: 'Le produit a bien été supprimé',
    SUCCESS_UPDATE_ARTICLE: 'Le produit a bien été mis à jour',
    SUCCESS_UPDATE_CATEGORY: 'Catégorie modifiée avec succès',
    SUGGESTED_PRICE: 'Prix conseillé',
    SUNDAY: 'dimanche',
    TEASER: {
        ACTION_BUTTON: 'Prendre RDV avec le service client',
        ACTION_TEXT: 'Vous souhaitez bénéficier de ce module ou en savoir plus?',
        ADBOX_TEXT_1_0: 'Grâce à AdBox, ne subissez plus votre marché ! Identifiez les ',
        ADBOX_TEXT_1_1: 'stratégies promotionnelles de vos concurrents ',
        ADBOX_TEXT_1_2: 'à travers leurs campagnes emailing et newsletters (date, heure et fréquence d\'envoi). Le tableau de bord du module et ses graphiques vous guide pour les contrer avec la stratégie la plus adaptée.',
        ADBOX_TEXT_1_3: 'L\'analyse des campagnes emailing est essentielle pour élaborer des newsletters pertinentes avec une ',
        ADBOX_TEXT_1_4: 'garantie de ROI élevé. ',
        ADBOX_TEXT_1_5: 'PriceComparator permet de choisir les bonnes offres à envoyer et au bon prix. Les deux incontournables pour assurer de bons taux de clics, ',
        ADBOX_TEXT_1_6: 'améliorer son e-réputation ',
        ADBOX_TEXT_1_7: 'et augmenter ses ventes à chaque envoi !',
        ADBOX_TEXT_1_TITLE: 'Visualisez les Newsletters de vos concurrents et distributeurs',
        ADBOX_TEXT_2_0: 'Analysez les dernières promotions de vos concurrents au sein du tableau de bord de notre module Adbox. ',
        ADBOX_TEXT_2_1: 'Adoptez la meilleure stratégie de promotion possible ',
        ADBOX_TEXT_2_2: 'en fonction des offres concurrentes.',
        ADBOX_TEXT_2_3: 'Surveiller les prix est indispensable. Mais si vous n\'êtes pas au courant de la sortie d\'un Code Promo chez votre concurrent, alors vous passez à côté des « vrais » prix en ligne et donc possiblement de nombreuses ventes !',
        ADBOX_TEXT_2_TITLE: 'Identifiez les nouvelles opérations commerciales de vos concurrents/distributeurs',
        PE_TEXT_1_0: 'Le pricing dynamique permet d\'',
        PE_TEXT_1_1: 'élaborer automatiquement le prix de vente ',
        PE_TEXT_1_2: 'de vos produits en optimisant automatiquement vos marges et votre compétitivité.',
        PE_TEXT_1_3: 'Avec le ',
        PE_TEXT_1_4: 'Pricing Engine, ',
        PE_TEXT_1_5: 'définissez des ',
        PE_TEXT_1_6: 'règles intelligentes ',
        PE_TEXT_1_7: 'qui adaptent vos prix aux mouvements quotidiens de vos concurrents (prix, stocks, promotions…).',
        PE_TEXT_1_8: 'De la collecte des données à l\'',
        PE_TEXT_1_9: 'application de vos stratégies de prix, ',
        PE_TEXT_1_10: 'en passant par l\'',
        PE_TEXT_1_11: 'analyse intelligente de la data, ',
        PE_TEXT_1_12: 'PriceComparator et son module Pricing Engine fournissent la meilleure technologie du marché.',
        PE_TEXT_1_13: 'Propulsez votre e-commerce vers une optimisation tarifaire et une croissance digitale à forte valeur ajoutée !',
        PE_TEXT_1_TITLE: 'Qu\'est-ce que le Pricing Engine par PriceComparator ?',
        PE_TEXT_2_0: 'Saisissez les opportunités concurrentielles ',
        PE_TEXT_2_1: 'tout en gagnant du temps et en respectant votre stratégie e-commerce globale, ',
        PE_TEXT_2_2: 'pour plus de ventes et de marges !',
        PE_TEXT_2_3: 'Ne vous laissez plus surprendre par les actions concurrentes lors des promotions saisonnières. ',
        PE_TEXT_2_4: 'Analysez l\'impact des mouvements de prix sur votre positionnement ',
        PE_TEXT_2_5: 'tarifaire et ajustez vos règles de prix selon vos stratégies. Votre trafic en ligne et vos ventes seront révélateurs de vos performances !',
        PE_TEXT_2_6: 'Exemples de ce que vous pourrez faire avec le Pricing Engine : ',
        PE_TEXT_2_7_0: '- Réagir automatiquement face aux changements de prix des concurrents',
        PE_TEXT_2_7_1: '- Automatiser les calculs de vos prix optimaux',
        PE_TEXT_2_7_2: '- Remonter les prix trop bas pour augmenter vos marges !',
        PE_TEXT_2_7_3: '- Optimiser les prix trop hauts pour booster vos ventes !',
        PE_TEXT_2_7_4: '- Anticiper vos campagnes de promotion et offres commerciales',
        PE_TEXT_2_TITLE: 'Repricing optimisé pour chaque produit',
        PERF_TEXT_1_0: 'Avec le module Performance de PriceComparator, analysez les ',
        PERF_TEXT_1_1: 'performances commerciales et marketing ',
        PERF_TEXT_1_2: 'de votre marché par rapport à vous.',
        PERF_TEXT_1_3: 'Trouvez les ',
        PERF_TEXT_1_4: 'stratégies gagnantes ',
        PERF_TEXT_1_5: 'de votre marché et priorisez vos activités en conséquence. Adaptez vos actions et vos KPI en suivant les performances des acteurs de votre marché et ',
        PERF_TEXT_1_6: 'atteignez vos objectifs de positionnement !',
        PERF_TEXT_1_TITLE: 'Analysez les performances de votre marché',
        PERF_TEXT_2_0: 'Suivez la fréquentation des sites de votre secteur ',
        PERF_TEXT_2_1: 'sur des périodes régulières et définies. Les tendances de trafic, organique (SEO) et payant (SEA), témoignent de la santé d\'un site internet et de ses performances marketing. Avec le tableau de bord des performances web, vous pouvez comparer les fréquentations des sites des acteurs de votre marché et suivre leur évolution.',
        PERF_TEXT_2_2: 'L\'analyse de performance permet d\'identifier les actions de vos concurrents et d\'en déduire l\'impact sur votre marché. ',
        PERF_TEXT_2_3: 'Comparez les tendances tarifaires, commerciales et marketing pour déterminer les stratégies gagnantes.',
        PERF_TEXT_2_TITLE: 'Suivez l\'évolution de votre marché',
        SLIDER_TEXT_1_0: 'Visualisez les nouvelles bannières et promotions ',
        SLIDER_TEXT_1_1: 'disponibles sur les sites de vos concurrents. Tout est accessible depuis le module Slider.',
        SLIDER_TEXT_1_2: 'Ne perdez plus votre temps à collecter et sauvegarder les dernières nouveautés et offres promotionnelles : ',
        SLIDER_TEXT_1_3: 'tout est automatisé !',
        SLIDER_TEXT_1_4: 'Avec l\'historique et ses filtres d\'accès, ',
        SLIDER_TEXT_1_5: 'identifiez les stratégies gagnantes ',
        SLIDER_TEXT_1_6: 'et leur périodicité pour vous en inspirer à votre tour !',
        SLIDER_TEXT_1_TITLE: 'Visualisez les promotions disponibles sur les sites de vos concurrents/distributeurs',
        SLIDER_TEXT_2_0: 'Grâce au module Slider, vous pouvez suivre les dernières tendances marketing et ',
        SLIDER_TEXT_2_1: 'opérations commerciales de vos concurrents en un coup d\'œil. ',
        SLIDER_TEXT_2_2: 'Restez réactifs en lançant vos propres opérations commerciales au bon moment.',
        SLIDER_TEXT_2_3: 'Le nouveau Code Promo qui vient de sortir ou le nouveau Destockage concurrent qui vient d\'apparaître ne vous prend plus au dépourvu et vous pouvez ',
        SLIDER_TEXT_2_4: 'réagir immédiatement pour écouler vos stocks !',
        SLIDER_TEXT_2_TITLE: 'Gagnez en réactivité',
        SPE_TEXT_1_0: 'Les internautes comparent les offres en ligne en 2 clics et choisissent la meilleure ! ',
        SPE_TEXT_1_1: 'Comparez les prix et les stocks ',
        SPE_TEXT_1_2: 'de vos concurrents et identifiez votre niveau de compétitivité par rapport à votre marché. ',
        SPE_TEXT_1_3: 'Tout est automatique ! ',
        SPE_TEXT_1_4: 'Retrouvez les ',
        SPE_TEXT_1_5: 'évolutions des prix ',
        SPE_TEXT_1_6: 'de vos concurrents ou revendeurs au cours de l\'année grâce à l\'',
        SPE_TEXT_1_7: 'historique complet de chaque produit. ',
        SPE_TEXT_1_8: 'Objectif : ',
        SPE_TEXT_1_9: 'Développez vos ventes, conservez vos marges tout en gagnant des parts de marché. ',
        SPE_TEXT_1_10: 'Vous pouvez ',
        SPE_TEXT_1_11: 'trier les informations ',
        SPE_TEXT_1_12: 'en fonction des marques, des catégories et des produits en quelques clics afin de ',
        SPE_TEXT_1_13: 'vous focaliser sur ce qui est essentiel pour vous. ',
        SPE_TEXT_1_14: 'Adaptez votre stratégie de pricing à tout moment et identifiez votre meilleure stratégie avec les graphiques interactifs de notre module d\'analyse tarifaire. ',
        SPE_TEXT_1_TITLE: 'Gagnez en compétitivité',
        SPE_TEXT_2_0: 'Visualisez l\'ensemble des ',
        SPE_TEXT_2_1: 'mouvements de prix ',
        SPE_TEXT_2_2: 'sur la période que vous souhaitez pour n\'importe quel produit de votre catalogue.',
        SPE_TEXT_2_3: 'Identifiez quel concurrent est le plus compétitif, pour que vos changements de prix vous permettent de gagner en compétitivité sur votre marché.',
        SPE_TEXT_2_4: 'Nos robots relèvent les prix de vos concurrents ou de vos revendeurs chaque jour. ',
        SPE_TEXT_2_5: 'Vous êtes assuré d\'avoir ',
        SPE_TEXT_2_6: 'les derniers tarifs et la disponibilité des produits en ligne. ',
        SPE_TEXT_2_7: 'Adaptez votre stratégie de marché en fonction des prix, mais aussi des stocks de vos concurrents ou revendeurs.',
        SPE_TEXT_2_TITLE: 'Identifiez les mouvement de prix',
        TRENDS_TEXT_1_0: 'L\'évaluation de son positionnement tarifaire en ',
        TRENDS_TEXT_1_1: 'période d\'inflation ',
        TRENDS_TEXT_1_2: 'est essentielle pour maintenir ses ventes, ses marges et son image de marque. ',
        TRENDS_TEXT_1_3: 'L\'enjeu est énorme : ',
        TRENDS_TEXT_1_4: 'il s\'agit d\'augmenter ses prix en lien avec l\'inflation pour conserver ses marges, tout en restant attractifs vis-à-vis de ses clients.',
        TRENDS_TEXT_1_5: 'Le module Trends vous permet de ',
        TRENDS_TEXT_1_6: 'comparer les évolutions des prix ',
        TRENDS_TEXT_1_7: 'moyens de l\'ensemble des acteurs de votre marché. Choisissez la période et les courbes apparaissent pour vous révéler les tendances de chaque acteur ! Un outil indispensable pour ',
        TRENDS_TEXT_1_8: 'sécuriser sa stratégie de prix ',
        TRENDS_TEXT_1_9: 'et ses ventes.',
        TRENDS_TEXT_1_TITLE: 'Mesurer l\'impact de l\'inflation sur son marché',
        TRENDS_TEXT_2_0: 'Identifier l\'évolution des stratégies de prix ',
        TRENDS_TEXT_2_1: 'de vos concurrents et revendeurs face à l\'inflation. Maintenez votre positionnement et votre image de marque auprès de vos clients pour optimiser vos ventes et vos marges.',
        TRENDS_TEXT_2_2: 'Comparez l\'impact de l\'inflation sur les différentes catégories ',
        TRENDS_TEXT_2_3: 'de produits pour optimiser vos nouveaux positionnements produits et leur catégorisation à venir.',
        TRENDS_TEXT_2_4: 'Croisez les données pour décrypter l\'impact de l\'inflation sur votre marché et tirez en des indicateurs décisifs pour ',
        TRENDS_TEXT_2_5: 'atteindre vos objectifs ',
        TRENDS_TEXT_2_6: 'de vente et de ',
        TRENDS_TEXT_2_7: 'chiffre d\'affaires !',
        TRENDS_TEXT_2_TITLE: 'Inflation et tendances sur votre secteur'
    },
    THAT_IS_IT: 'C\'est le bon',
    THE: 'Le',
    THURSDAY: 'jeudi',
    TIPS_N_TRICKS: 'Tips & Tricks ',
    TO: 'au',
    TO_HIGHEST_9: ' en arrondissant au 9 plus haut.',
    TO_HIGHEST_09: ' en arrondissant au 0.9 plus haut.',
    TO_HIGHEST_099: ' en arrondissant au 0.99 plus haut.',
    TO_LOWEST_9: ' en arrondissant au 9 plus bas.',
    TO_LOWEST_09: ' en arrondissant au 0.9 plus bas.',
    TO_LOWEST_099: ' en arrondissant au 0.99 plus bas.',
    TO_NEAREST_9: ' en arrondissant au 9 plus proche.',
    TO_NEAREST_09: ' en arrondissant au 0.9 plus proche.',
    TO_NEAREST_099: ' en arrondissant au 0.99 plus proche.',
    TO_PRICE: ' sans l\'arrondir.',
    TO_REQUEST_IT: 'Pour en faire la demande',
    TOP_CRITICAL_PRICES: 'Top prix critiques',
    TOP_GROWTH: 'Top croissance',
    TOTAL_PRICE: 'Prix total',
    TRENDS: {
        CONCURRENT_AVG_PRICE: 'Prix moyen de vox concurrents',
        CONCURRENT_EVOLUTION: 'Vos concurrents',
        CONCURRENT_EVOLUTION_LEGEND: 'Evolution des prix moyens de vos concurrents',
        CONCURRENT_TREND_LEGEND: 'Courbe de tendance des prix moyens de vos concurrents',
        CONSTANT: 'Constante',
        EXP: 'Exponentielle',
        FILTERS_TITLE: 'Filtres d\'analyse',
        LEGENDS: 'Légendes',
        LINEAR: 'Linéaire',
        LOADING: 'Les données sont en cours de récupération, cette opération peut prendre un peu de temps',
        LOGARITHMIC: 'Logarithmique',
        NAME: 'Trends',
        NOT_ENOUGH_DATA: 'Données insuffisantes : Trends n\'a pas suffisamment de données pour établir un résultat fiable. Merci d\'élargir la plage de dates et/ou de modifier les filtres d\'analyse.',
        POWER: 'Puissances',
        RESELLERS_AVG_PRICE: 'Prix moyen de vox revendeurs',
        RESELLERS_EVOLUTION: 'Vos revendeurs',
        RETAILER_TITLE: 'Evolution de votre politique tarifaire par rapport à la concurrence du',
        SELLERS_EVOLUTION_LEGEND: 'Evolution des prix moyens de vos revendeurs',
        SELLERS_TREND_LEGEND: 'Courbe de tendance des prix moyens de vos revendeur',
        TENDENCE_TYPE: 'Type de tendance :',
        UNKNOWN: 'Oups, une erreur s\'est produite lors du traitement des données. Veuillez réessayer. Si le problème persiste, n\'hésitez pas à contacter l\'équipe support.',
        YOUR_AVG_PRICE: 'Votre prix moyen',
        YOUR_EVOLUTION: 'Vous',
        YOUR_EVOLUTION_LEGEND: 'Evolution des prix moyens de votre enseigne',
        YOUR_TREND_LEGEND: 'Courbe de tendance de vos prix'
    },
    TUESDAY: 'mardi',
    TYPE_EMAIL_PASSWORD_LOST: 'Pour pouvoir récupérer vos accès à l\'application, merci de saisir l\'adresse email associée à votre compte PriceComparator. Il s\'agit de l\'adresse email que vous utilisez pour vous connecter à l\'application.',
    UNAVAILABLE_NAME: 'La nom choisi existe déjà. Merci de saisir un autre nom.',
    UNCHANGEABLE_RESOURCE: 'La catégorie spécifiée correspond à la catégorie \'n/a\' qui ne peut être supprimée.',
    UNDEFINED: 'Non défini',
    UNSELECTED: 'Non selectionné',
    UNSPECIFIED: 'Non précisé',
    UPDATE: 'Modifier',
    UPDATE_ATTRIBUTE: 'Ajouter, modifier, réorganiser les attributs',
    UPDATE_DATA: 'Modifier les données',
    USER_DISCONNECTED: 'Vous avez été déconnecté',
    USERS: 'Utilisateurs',
    VALUE: 'Valeur',
    VISUALIZE: 'Visualiser',
    WARNING_CMS_ID: 'Attention, vous êtes sur le point de modifier le CMS ID',
    WARNING_CMS_ID_CONTENT_FIRST: 'Le CMS ID doit correspondre au même article dans votre CMS et dans PriceComparator. Une erreur de saisie pourrait entraîner des modifications irrémédiables sur votre CMS.',
    WARNING_CMS_ID_CONTENT_SECOND: 'En cas de doute, contactez votre conseiller PriceComparator.',
    WEBSITE: 'Website',
    WEBSITES: 'Websites',
    WEDNESDAY: 'mercredi',
    WELCOME_ON_PRICING_ENGINE: 'Bienvenue sur le Pricing Engine !',
    WITH_HIGH_LIMIT: 'sauf si le prix est supérieur à',
    WITH_LOW_LIMIT: 'sauf si le prix est inférieur à',
    WITH_PRICE_HIGHER_OR_EQUAL_AT: 'ayant un prix supérieur ou égal à',
    WITH_PRICE_LOWER_OR_EQUAL_AT: 'ayant un prix inférieur ou égal à',
    WITHOUT_ASSOCIATED_PRICING_ENGINE_RULE: 'Produits sans règle associée',
    WITHOUT_HIGH_LIMIT: 'sans limite haute de prix',
    WITHOUT_LOW_LIMIT: 'sans limite basse de prix',
    YOU_DO_NOT_HAVE_ACCESS: 'Vous n\'avez pas encore accès à ce module',
    YOUR_LAST_VALUE: 'Votre dernier relevé'
};

export default locales;
