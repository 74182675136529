import makeStyles from '@mui/styles/makeStyles';

export const useStyles = bigFilter => makeStyles((theme) => ({
    concurrentEvolution: {
        borderTop: '3px solid rgb(105, 206, 209)',
        marginRight: '3px',
        width: '15px'
    },
    concurrentTrend: {
        borderTop: '3px solid rgba(105, 206, 209, 0.7)',
        marginRight: '3px',
        width: '15px'
    },
    evolutionPercentage: {
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        height: '70px',
        justifyContent: 'center',
        margin: '30px',
        textAlign: 'center',
        width: '120px'
    },
    evolutionPercentageContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '25%'
    },
    evolutionPercentageValue: {
        fontSize: '13px',
        fontWeight: 'bold'
    },
    graphContainer: {
        padding: '15px',
        transition: 'width 0.7s',
        width: bigFilter ? '43%' : '50%'
    },
    legend: {
        alignItems: 'center',
        color: theme.palette.grey.semidark,
        display: 'flex',
        fontSize: '11px',
        marginTop: '2px',
        paddingLeft: '10px'
    },
    legendsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '25%'
    },
    trendSheetContainer: {
        alignItems: 'center',
        display: 'flex',
        height: bigFilter ? '42%' : '55%',
        marginTop: '25px',
        transition: 'height 0.7s',
        width: '100%'
    },
    trendType: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '13px',
        fontWeight: 'bold',
        marginBottom: '2px',
        marginTop: '25px'
    },
    trendTypeSelector: {
        marginLeft: '5px',
        width: '120px'
    },
    yourEvolution: {
        borderTop: '3px solid rgb(95, 207, 125)',
        marginRight: '3px',
        width: '15px'
    },
    yourTrend: {
        borderTop: '3px solid rgba(95, 207, 125, 0.7)',
        marginRight: '3px',
        width: '15px'
    }
}));
