// React
import React from 'react';
import PropTypes from 'prop-types';
// Locales
import translate from 'locales/i18n';
// Material UI Components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// Style
import { useStyles } from 'components/table/MarketplaceOffersTable/MarketplaceOffersTable.style';

const MarketplaceOffersTable = ({ marketplaceOffers, scrapingData }) => {
    const classes = useStyles();

    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHead}>{translate.i18n('SELLER')}</TableCell>
                    <TableCell align={'left'} className={classes.tableHead}>{translate.i18n('PRICE')}</TableCell>
                    <TableCell className={classes.tableHead}>{translate.i18n('SHIPPING_COST')}</TableCell>
                    <TableCell className={classes.tableHead}>{translate.i18n('TOTAL_PRICE')}</TableCell>
                    <TableCell className={classes.tableHead}>{translate.i18n('AVAILABILITY')}</TableCell>
                    <TableCell className={classes.tableHead}>{translate.i18n('SHIPPING_INFORMATION')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {marketplaceOffers.map((offer, key) =>
                    <TableRow key={key}>
                        <TableCell
                            className={classes.nameCell}
                            title={scrapingData.url}>
                            {offer.sellerName}
                        </TableCell>
                        <TableCell align={'left'}>{offer.price}</TableCell>
                        <TableCell>{offer.shippingCost}</TableCell>
                        <TableCell>{(offer.shippingCost + offer.price).toFixed(2)}</TableCell>
                        <TableCell>{offer.availability}</TableCell>
                        <TableCell>{offer.shippingInfo}</TableCell>
                    </TableRow>)}
            </TableBody>
        </Table>
    );
};

MarketplaceOffersTable.propTypes = {
    marketplaceOffers: PropTypes.array,
    scrapingData: PropTypes.object
};

export default MarketplaceOffersTable;
