// React
import React from 'react';
import PropTypes from 'prop-types';
import { sha256 } from 'js-sha256';
// Locales
import translate from 'locales/i18n';
// Style
import { styles } from 'components/dialog/DialogChangePassword/DialogChangePassword.style';
// Util
import { validatePassword } from 'components/dialog/DialogChangePassword/DialogChangePassword.util';
// Material UI Components
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// Actions
import UserAction from 'actions/UserAction';
import UserStore from 'stores/UserStore';

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width='lg' fullScreen={false} />;

class DialogChangePassword extends React.Component {

    state = {
        confirmPassword: '',
        currentPassword: '',
        password: '',
        showPassword: false
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
        close: PropTypes.func,
        fullScreen: PropTypes.bool,
        open: PropTypes.bool
    };

    handleChange = prop => event => {
        this.setState({
            errorMsg: '',
            [prop]: event.target.value
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleNext();
        }
    };

    checkPassword = () => {
        const { currentPassword, password, confirmPassword } = this.state;

        if (currentPassword === '' || password === '' || confirmPassword === '') {
            this.setState({ errorMsg: translate.i18n('FILL_PASSWORD') });
            return false;
        } else if (password !== confirmPassword) {
            this.setState({ errorMsg: translate.i18n('PASSWORDS_DOES_NOT_MATCH') });
            return false;
        } else if (!validatePassword(password)) {
            this.setState({ errorMsg: translate.i18n('PASSWORD_DOES_NOT_MATCH_CRITERIA') });
            return false;
        }

        return true;
    };

    updatePassword = () => {
        const { currentPassword, password } = this.state;
        if (this.checkPassword()) {
            UserAction.updateUser({
                password_new: sha256(password),
                password_old: sha256(currentPassword)
            }, UserStore.getCurrentUser().id).then(() => {
                this.setState({
                    confirmPassword: '',
                    currentPassword: '',
                    password: '',
                    showPassword: false
                });
                this.props.close();
            }, () => this.setState({ errorMsg: translate.i18n('OLD_PASSWORD_NOT_GOOD') }));
        }
    };

    render() {
        const { fullScreen, classes } = this.props;

        return (
            <Dialog
                fullScreen={fullScreen}
                open={this.props.open}
                onClose={this.props.close}
                disableRestoreFocus={true}
                aria-labelledby='responsive-dialog-title'
                className={classes.dialog}
            >
                <IconButton
                    color='secondary'
                    onClick={this.props.close}
                    className={classes.closeButton}
                    size='large'>
                    <ClearIcon />
                </IconButton>
                <DialogTitle id='responsive-dialog-title'>{translate.i18n('CHANGE_PASSWORD')}</DialogTitle>
                <DialogContent>
                    <div className={classes.dialogContentContainer}>
                        <TextField
                            id='password'
                            className={classes.margin}
                            variant='outlined'
                            type={this.state.showPassword ? 'text' : 'password'}
                            label={translate.i18n('CURRENT_PASSWORD')}
                            value={this.state.currentPassword}
                            onChange={this.handleChange('currentPassword')}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='Toggle password visibility'
                                            onClick={() => this.setState(state => ({ showPassword: !state.showPassword }))}
                                            size='large'>
                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                        <TextField
                            id='password'
                            className={classes.margin}
                            variant='outlined'
                            type={this.state.showPassword ? 'text' : 'password'}
                            label={translate.i18n('PASSWORD')}
                            value={this.state.password}
                            onChange={this.handleChange('password')}
                        />
                        <div>
                            <ul>
                                <li>
                                    {translate.i18n('MUST_CONTAINS_8_CHAR')}
                                </li>
                                <li>
                                    {translate.i18n('MUST_CONTAINS_ONE_LOWER_CASE')}
                                </li>
                                <li>
                                    {translate.i18n('MUST_CONTAINS_ONE_UPPER_CASE')}
                                </li>
                                <li>
                                    {translate.i18n('MUST_CONTAINS_')}
                                </li>
                                <li>
                                    {translate.i18n('MUST_CONTAINS_SPECIAL_CHAR')}
                                </li>
                            </ul>
                        </div>
                        <TextField
                            id='confirm-password'
                            className={classes.margin}
                            variant='outlined'
                            type={this.state.showPassword ? 'text' : 'password'}
                            label={translate.i18n('CONFIRM_PASSWORD')}
                            value={this.state.confirmPassword}
                            onChange={this.handleChange('confirmPassword')}
                        />
                        {this.state.errorMsg && <p className={classes.errorMessage}>{this.state.errorMsg}</p>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <IconButton color='primary' onClick={() => this.updatePassword()} size='large'>
                        <SaveIcon />
                    </IconButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withMobileDialog()(withStyles(styles)(DialogChangePassword));
