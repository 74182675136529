class PositioningKpis {

    constructor(data) {
        this.avgPriceDifferences = data.avg_price_differences ? {
            onDate: data.avg_price_differences.on_date,
            withRepricing: data.avg_price_differences.with_repricing,
            trend: data.avg_price_differences.trend
        } : null;
        this.avgMargin = data.avg_margins ? {
            onDate: data.avg_margins.on_date,
            withRepricing: data.avg_margins.with_repricing,
            trend: data.avg_margins.trend
        } : null;
        this.avgPositions = data.avg_positions ? {
            onDate: data.avg_positions.on_date,
            withRepricing: data.avg_positions.with_repricing,
            trend: data.avg_positions.trend
        } : null;
    }
}
export default PositioningKpis;
