import React from 'react';
import PropTypes from 'prop-types';
//Styles
import { useStyles } from 'components/sheet/SliderSheet/SliderSheet.style';
//I18n
import translate from 'locales/i18n';
//PCP Components
import SliderDayFilter from 'components/filter/slider/SliderDayFilter/SliderDayFilter';
import SliderPieChart from 'components/filter/slider/SliderPieChart/SliderPieChart';
//Material
import { Paper } from '@mui/material';

export const SliderStats = ({ sliderStats }) => {
    const classes = useStyles();
    return (
        <div className={classes.filtersContainer}>
            <SliderDayFilter daysValues={sliderStats?.daysStats} />
            <SliderPieChart sites={sliderStats?.sites} />
            <div className={classes.statsBoxContainer}>
                <Paper className={classes.paper}>
                    <h6 className={classes.statsTextValue}>
                        {sliderStats?.globalStats.totalNew + sliderStats?.globalStats.totalRemoved}
                    </h6>
                    <h6 className={classes.statsText}>
                        {translate.i18n('CHANGES_ON_SELECTED_PERIOD')}
                        <br />
                        <span className={classes.statsTextSub}>
                            ({translate.i18n('DELETED')} : {sliderStats?.globalStats.totalRemoved} / {translate.i18n('ADDED')} : {sliderStats?.globalStats.totalNew})
                        </span>
                    </h6>
                </Paper>
                <Paper className={classes.paper} style={{ marginTop: '20px' }}>
                    <h6 className={classes.statsTextValue}>
                        {(sliderStats?.globalStats.averageNew + sliderStats?.globalStats.averageRemoved).toFixed(1)}
                    </h6>
                    <h6 className={classes.statsText}>
                        {translate.i18n('CHANGES_BY_WEEK')}
                        <br />
                        <span className={classes.statsTextSub}>
                            ({translate.i18n('DELETED')} : {sliderStats?.globalStats.averageRemoved.toFixed(1)} / {translate.i18n('ADDED')} : {sliderStats?.globalStats.averageNew.toFixed(1)})
                        </span>
                    </h6>
                </Paper>
            </div>
        </div>);
};

SliderStats.propTypes = {
    sliderStats: PropTypes.object
};

export default SliderStats;
