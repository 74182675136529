import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//Styles
import { useStyles } from 'components/dialog/DialogAddProduct/DialogAddProduct.style';
//Mui components
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//Pcp components
import SimpleBar from 'simplebar-react';
import DialogAddProductScrappingData from 'components/dialog/DialogAddProduct/DialogAddProductScrappingData';
import DialogAddProductFixedAttributes from 'components/dialog/DialogAddProduct/DialogAddProductFixedAttributes';
import DialogAddProductOptionalAttributes from 'components/dialog/DialogAddProduct/DialogAddProductOptionalAttributes';
//Utils
import { createProduct, verifyBannedNames, reorder } from 'components/dialog/DialogAddProduct/DialogAddProduct.util';
//Trads
import translate from 'locales/i18n';
// Images
import imgNotFound from 'assets/img/img-not-found.png';
//Stores && Actions
import CategoryStore from 'stores/CategoryStore';
import AccountStore from 'stores/AccountStore';
import ItemAction from 'actions/ItemAction';
import CategoryAction from 'actions/CategoryAction';
import AlertAction from 'actions/AlertAction';
import AttributeStore from 'stores/AttributeStore';
import AttributeAction from 'actions/AttributeAction';
//Dnd
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const DialogAddProduct = ({ open, onClose, defaultValues, setCustomAttributes }) => {

    const [categories, setCategories] = useState([]);
    const [websites, setWebsites] = useState(AccountStore.getCurrentAccount().getPriceMonitoringInformations()?.sites);

    const [fixedAttributes, setFixedAttributes] = useState([]);
    const [optionalAttributes, setOptionalAttributes] = useState([]);
    const [existingAttributeNames, setExistingAttributeNames] = useState([]);
    const [existingAttributeValues, setExistingAttributeValues] = useState({});

    const [imgUrl, setImgUrl] = useState('');

    const classes = useStyles();

    useEffect(() => {
        CategoryStore.addChangeListener(onCategoryStoreChange);
        return () => CategoryStore.removeChangeListener(onCategoryStoreChange);
    }, []);

    useEffect(() => {
        AttributeStore.addChangeListener(onAttributeStoreChange);
        return () => AttributeStore.removeChangeListener(onAttributeStoreChange);
    }, []);

    useEffect(() => {
        if (defaultValues) {
            defaultValues.forEach(value => value.id = value.name);
            setOptionalAttributes(defaultValues);
        }

        if (!open) AttributeStore.resetAttributeValues();
    }, [open]);

    const onCategoryStoreChange = () => setCategories(CategoryStore.getCategories());

    const onAttributeStoreChange = () => {
        setExistingAttributeNames(AttributeStore.getAttributeNames());
        setExistingAttributeValues(AttributeStore.getAttributeValues());
    };

    useEffect(() => {
        if (open) {
            CategoryAction.getCategories(AccountStore.getCurrentAccount().getId());
            AttributeAction.getAttributeNames(AccountStore.getCurrentAccount().getId());
            getFixedAttributes();
            setWebsites(initWebsites(websites.slice()));
            !defaultValues && setOptionalAttributes([]);
        }
    }, [open]);

    const getFixedAttributes = () => {
        const requiredAttributes = AccountStore.getCurrentAccount().getPriceMonitoringInformations()?.requiredAttributes;
        const optAttributes = AccountStore.getCurrentAccount().getPriceMonitoringInformations()?.optionalAttributes;
        const attributes = [];

        requiredAttributes.forEach(attribute => {
            attributes.push({
                name: attribute.name,
                type: attribute.type,
                value: '',
                required: true
            });
        });

        optAttributes.forEach(attribute => {
            attributes.push({
                name: attribute.name,
                type: attribute.type,
                value: '',
                required: false
            });
        });

        setFixedAttributes(attributes);

    };

    const initWebsites = (sites) => sites.map((website) => {
        website.value = '';
        website.status = '';
        if (website.status === 'NV_BELL')
            website.delay = 30;
        return website;
    });

    const addProduct = () => {

        let attributesValid = true;
        optionalAttributes.forEach(oa => {
            if (verifyAttributeName(oa.name) || verifyAttributeValue(oa.value)) attributesValid = false;
        });

        if (fixedAttributes.find((ra) => ra && ra.value === '' && ra.required) || !attributesValid) {
            AlertAction.open('error', translate.i18n('ATTRIBUTE_ERROR'), 'top-right');
            return null;
        }

        const product = createProduct(fixedAttributes, optionalAttributes, websites);

        ItemAction.addItem(product);
        onClose();
    };

    const updateAttributes = () => {

        let attributesValid = true;
        optionalAttributes.forEach(oa => {
            if (verifyAttributeName(oa.name) || verifyAttributeValue(oa.value)) attributesValid = false;
        });

        if (!attributesValid) {
            AlertAction.open('error', translate.i18n('ATTRIBUTE_ERROR'), 'top-right');
            return null;
        }

        setCustomAttributes(optionalAttributes);
        onClose();
    };

    const handleChangeFixedAttributes = (attributeName) => event => {
        const newAttributes = fixedAttributes.slice();
        newAttributes.forEach(attribute => {
            if (attribute && attribute.name === attributeName ) attribute.value = event.target.value;
        });
        setFixedAttributes(newAttributes);
    };

    const handleChangeWebsite = (id, status, delay) => (event) => {
        const newWebsites = websites.slice();
        newWebsites.forEach(website => {
            if (website.id === id) {
                if (status) {
                    website.status = status;
                    if (delay) website.delay = delay;
                } else {
                    if (event && event.target.value) {
                        website.value = event.target.value;
                        website.status = 'SOLD';
                    } else {
                        website.status = '';
                        website.value = '';
                    }
                }
            }
        });
        setWebsites(newWebsites);
    };

    const handleAddAttribute = () => {
        if (optionalAttributes.length > 14) return true;
        const optAttributes = optionalAttributes.slice();
        const found = optAttributes.find(a => a.name === '');
        !found && optAttributes.push({ name: '', value: '', id: String(new Date().getTime()) });
        setOptionalAttributes(optAttributes);
    };

    const removeAttribute = (index) => {
        const optAttributes = optionalAttributes.slice();
        optAttributes.splice(index, 1);
        setOptionalAttributes(optAttributes);
    };

    const handleChangeOptionalAttributeName = (event, index) => {

        const optAttributes = optionalAttributes.slice();
        optAttributes[index].name = event.target ? event.target.value : event;
        setOptionalAttributes(optAttributes);

        if (existingAttributeNames.includes(event))
            AttributeAction.getAttributeValues(AccountStore.getCurrentAccount().getId(), event);
        if (event.target && existingAttributeNames.includes(event.target.value))
            AttributeAction.getAttributeValues(AccountStore.getCurrentAccount().getId(), event.target.value);
    };

    const handleChangeOptionalAttributeValue = (event, index) => {
        const optAttributes = optionalAttributes.slice();
        optAttributes[index].value = event.target ? event.target.value : event;
        setOptionalAttributes(optAttributes);
    };

    const verifyAttributeName = (attributeName) => {
        if (attributeName.length > 30 || attributeName === '' || verifyBannedNames(attributeName)) return true;
        const res = optionalAttributes.filter(oa => oa.name === attributeName);
        return res.length > 1;
    };

    const verifyAttributeValue = (attributeValue) => {
        if (attributeValue.length > 200 || attributeValue === '') return true;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const attributes = reorder( optionalAttributes, result.source.index, result.destination.index );
        setOptionalAttributes(attributes);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
            classes={{
                paper: defaultValues ? classes.dialogEditPaper : classes.dialogPaper
            }}
        >
            <IconButton
                color='secondary'
                onClick={onClose}
                className={classes.closeButton}
                size='large'>
                <ClearIcon />
            </IconButton>
            <DialogTitle id='responsive-dialog-title'>{defaultValues ? translate.i18n('UPDATE_ATTRIBUTE') : translate.i18n('ADD_AN_ITEM')}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <SimpleBar className={classes.scrollContainer}>
                    <div className={classes.itemInformation}>
                        {!defaultValues && <div className={classes.imgContainer}>
                            <img src={imgUrl ? imgUrl : imgNotFound} className={classes.img} alt='img' onError={(error) => {
                                error.target.onerror = null;
                                error.target.src = imgNotFound;
                            }}></img>
                        </div>
                        }
                        <div className={classes.attributesContainer}>
                            {!defaultValues && <>
                                <p className={classes.subtitle}>{translate.i18n('REQUIRED_ATTRIBUTES')}</p>
                                <div className={classes.fixedAttributesContainer} style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                                    {fixedAttributes.map((attribute) => attribute && <DialogAddProductFixedAttributes
                                        key={attribute.name}
                                        attribute={attribute}
                                        handleChangeFixedAttributes={handleChangeFixedAttributes}
                                        categories={categories}
                                        setImgUrl={setImgUrl} />)}
                                </div>
                            </>
                            }
                            {!defaultValues && <p className={classes.subtitle}>{translate.i18n('OPTIONAL_ATTRIBUTES')}</p>}
                            <p className={classes.subSubtitle}>{translate.i18n('DND_ATTRIBUTES')}</p>
                            <div className={classes.optionalAttributesContainer}>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId='droppable'>
                                        {(providedDroppable) => <List {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
                                            {optionalAttributes.map((attribute, index) => <Draggable key={attribute.id} draggableId={attribute.id} index={index}>
                                                {(providedDraggable) => <div
                                                    ref={providedDraggable.innerRef}
                                                    {...providedDraggable.draggableProps}
                                                    {...providedDraggable.dragHandleProps}>
                                                    <DialogAddProductOptionalAttributes
                                                        attribute={attribute}
                                                        existingAttributeNames={existingAttributeNames}
                                                        existingAttributeValues={existingAttributeValues}
                                                        handleChangeOptionalAttributeName={handleChangeOptionalAttributeName}
                                                        handleChangeOptionalAttributeValue={handleChangeOptionalAttributeValue}
                                                        index={index}
                                                        removeAttribute={removeAttribute}
                                                        verifyAttributeName={verifyAttributeName}
                                                        verifyAttributeValue={verifyAttributeValue}
                                                    />
                                                </div>
                                                }
                                            </Draggable>
                                            )}
                                            {providedDroppable.placeholder}
                                        </List>
                                        }
                                    </Droppable>
                                </DragDropContext>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className={classes.addButton}
                                    disabled={optionalAttributes.length > 14}
                                    onClick={() => handleAddAttribute()}>
                                    {translate.i18n('ADD')}
                                    <AddIcon className={classes.rightIcon} />
                                </Button>
                            </div>
                        </div>
                    </div>
                    {!defaultValues && <DialogAddProductScrappingData websites={websites} handleChangeWebsite={handleChangeWebsite} />}
                </SimpleBar>
            </DialogContent>
            <DialogActions>
                <Button color='secondary'
                    onClick={onClose}>
                    <ClearIcon className={classes.leftIcon} />
                    <div>{translate.i18n('CANCEL')}</div>
                </Button>
                <Button color='primary'
                    onClick={() => {
                        defaultValues ? updateAttributes() : addProduct();
                    }}>
                    {defaultValues ?
                        <NavigateNextIcon className={classes.leftIcon} />
                        :
                        <AddIcon className={classes.leftIcon} />
                    }
                    {defaultValues ?
                        <Tooltip title={translate.i18n('ATTRIBUTE_REGISTER_WARN')} placement='top'>
                            <div> {translate.i18n('NEXT')} </div>
                        </Tooltip>
                        :
                        <div> {translate.i18n('ADD_ITEM')}</div>
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DialogAddProduct.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    defaultValues: PropTypes.array,
    setCustomAttributes: PropTypes.func
};

export default DialogAddProduct;
