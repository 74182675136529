export const createData = (data, theme) => {
    if (!data) return [];
    const newData = data.map((d) => ({
        text: d.value,
        value: d.count,
        size: getSize(d.count, data.length),
        color: theme.palette.adbox[getColor(d.count, data[0].count)]
    }));
    return newData;
};

const getColor = (value, max) => {
    if (value < max / 5) return 'one';
    else if (value < max / 4) return 'two';
    else if (value < max / 3) return 'three';
    else if (value < max / 2) return 'four';
    return 'five';
};

const getSize = (count, length) => {
    let size = Math.sqrt(count*2);
    if (length < 20 ) size *= 35;
    else if (length < 50 ) size *= 25;
    else size *= 15;
    if (size > 70) return 70;
    return size;
};
