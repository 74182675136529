// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import classNames from 'classnames';
// Locales
import translate from 'locales/i18n';
// Material UI
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import IconRefresh from '@mui/icons-material/Cached';
// PCP Components
import DialogInfo from 'components/dialog/DialogInfo/DialogInfo';
// Images
import RulesFilterExample from 'assets/img/rulesFilterExample.png';
// Util
import { getOptions, createData } from 'components/filter/pricingEngine/RulesFilter/RulesFilter.util';
// Style
import { useStyles } from 'components/filter/pricingEngine/RulesFilter/RulesFilter.style';
// Theme
import { useTheme } from '@mui/styles';
// Chart plugin
import ChartDataLabels from 'chartjs-plugin-datalabels';

const legends = ['DROPPING_PRICES', 'NO_EFFECT', 'RAISING_PRICES', 'IMPOSSIBLE_REPRICING'];

const RulesFilter = ({ isLoading, reset, selectRule, rules }) => {
    const [dialogInfoOpen, setDialogInfoOpen] = useState(false);
    const classes = useStyles();
    const theme = useTheme();

    const backgroundColors = [theme.palette.graph.five, theme.palette.graph.four, theme.palette.graph.three, theme.palette.rulesRanks.five];

    const renderDialogInfo = () => <DialogInfo open={dialogInfoOpen}
        close={() => setDialogInfoOpen(false)}>
        <div className={classes.infoMainText}>{translate.i18n('RULES_FILTER_EXPLANATION_1')}</div>
        <ul>
            <li className={classes.infoSubText}>{translate.i18n('RULES_FILTER_EXPLANATION_2')}</li>
        </ul>
        <div className={classes.infoImgContainer}><img src={RulesFilterExample} /></div>
    </DialogInfo>;

    return (
        <Paper className={classNames(classes.paper, isLoading && classes.notAllowed)}>
            <div className={classes.title}>{translate.i18n('RULES_FILTER_TITLE')}</div>
            {rules.length !== 0 &&
                <>
                    <div className={classes.graphContainer}>
                        <div className={classes.graphContent} style={{ height: 40 * rules.length }}>
                            <Bar
                                plugins={[ChartDataLabels]}
                                data={createData(rules, theme)}
                                getElementAtEvent={element => {
                                    if (element.length !== 0 && !isLoading) {
                                        selectRule(rules[element[0].index].id);
                                    }
                                }}
                                options={getOptions(rules)}
                            />
                        </div>
                    </div>
                    <div className={classes.legendContainer}>
                        {legends.map((legend, index) => <div className={classes.legend} key={legend}>
                            <div className={classes.legendColor} style={{ backgroundColor: backgroundColors[index] }} />
                            {translate.i18n(legend)}
                        </div>
                        )}
                    </div>
                </>
            }
            <IconButton
                color='primary'
                onClick={reset}
                className={classes.resetButton}>
                <IconRefresh />
            </IconButton>
            <IconButton
                color='primary'
                onClick={() => setDialogInfoOpen(true)}
                className={classes.infoButton}>
                <InfoIcon />
            </IconButton>
            {renderDialogInfo()}
        </Paper>
    );
};

RulesFilter.propTypes = {
    isLoading: PropTypes.bool,
    reset: PropTypes.func,
    selectRule: PropTypes.func,
    rules: PropTypes.array
};

export default RulesFilter;

