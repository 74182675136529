// core components/views
import translate from 'locales/i18n';
import Price from 'components/pages/SmartPriceExtractorPage/SmartPriceExtractorPage';
import Error from 'components/pages/ErrorPage/ErrorPage';
import PricingEngineAnalyze from 'components/pages/PricingEngineAnalyzePage/PricingEngineAnalyzePage';
import PricingEngineLibrary from 'components/pages/PricingEngineLibraryPage/PricingEngineLibraryPage';
import Performance from 'components/pages/PerformancePage/PerformancePage';
import Profile from 'components/pages/ProfilePage/ProfilePage';
import Login from 'components/pages/LoginPage/LoginPage';
import Product from 'components/pages/ProductPage/ProductPage';
import NotFound from 'components/pages/NotFoundPage/NotFoundPage';
import AdBoxPage from 'components/pages/AdboxPage/AdboxPage';
import SliderPage from 'components/pages/SliderPage/SliderPage';
import ForgotPassword from 'components/pages/ForgotPasswordPage/ForgotPasswordPage';
import Rule from 'components/pages/RulePage/RulePage';
import TrendsPage from 'components/pages/TrendsPage/TrendsPage';
// Thème
import theme from 'assets/theme/theme.js';
//Icons
import pricingEngineIcon from 'assets/img/svg/pricingEngine';
import TrendIcon from 'assets/img/svg/TrendIcon';

const routes = [
    // Main modules
    {
        appbarName: translate.i18n('SMART_PRICE_EXTRACTOR'),
        color: theme().palette.module.price,
        component: Price,
        display: false,
        displayLayout: true,
        iconName: 'euro_symbol',
        path: '/smart-price-extractor',
        rights: 'spe',
        sidebarName: translate.i18n('SMART_PRICE_EXTRACTOR'),
        private: true
    },
    {
        appbarName: translate.i18n('PRICING_ENGINE_ANALYZE'),
        color: theme().palette.module.pricingengine,
        component: PricingEngineAnalyze,
        display: false,
        displayLayout: true,
        iconComponent: pricingEngineIcon,
        path: '/pricing-engine-analyze',
        rights: 'pricing_engine',
        sidebarName: translate.i18n('PRICING_ENGINE'),
        private: true
    },
    {
        appbarName: translate.i18n('TRENDS.NAME'),
        color: theme().palette.module.pricingengine,
        component: TrendsPage,
        display: false,
        displayLayout: true,
        path: '/trends',
        rights: 'trends',
        private: true,
        iconComponent: TrendIcon,
        sidebarName: translate.i18n('TRENDS.NAME'),
    },
    {
        appbarName: translate.i18n('PERFORMANCE'),
        color: theme().palette.module.perf,
        component: Performance,
        display: false,
        displayLayout: true,
        iconName: 'trending_up',
        path: '/performance',
        rights: 'perf',
        sidebarName: translate.i18n('PERFORMANCE'),
        private: true
    },
    {
        appbarName: translate.i18n('SLIDER'),
        color: theme().palette.module.slider,
        component: SliderPage,
        display: false,
        displayLayout: true,
        iconName: 'calendar_view_day',
        path: '/slider',
        rights: 'slider',
        sidebarName: translate.i18n('SLIDER'),
        private: true
    },
    {
        appbarName: translate.i18n('ADBOX.NAME'),
        color: theme().palette.module.adbox,
        component: AdBoxPage,
        display: false,
        displayLayout: true,
        iconName: 'mail',
        path: '/adbox',
        rights: 'adbox',
        sidebarName: translate.i18n('ADBOX.NAME'),
        private: true
    },

    // Not Main Routes
    {
        appbarName: translate.i18n('SMART_PRICE_EXTRACTOR'),
        component: Product,
        display: false,
        displayLayout: true,
        iconName: 'ballot',
        path: '/items/:id',
        rights: 'spe',
        private: true
    },
    {
        appbarName: translate.i18n('PROFILE'),
        component: Profile,
        display: true,
        displayLayout: true,
        iconName: 'person',
        path: '/profil',
        rights: null,
        private: true
    },
    {
        appbarName: translate.i18n('PRICING_ENGINE_LIBRARY'),
        component: PricingEngineLibrary,
        display: true,
        displayLayout: true,
        path: '/rules',
        rights: 'pricing_engine',
        private: true
    },
    {
        appbarName: translate.i18n('PRICING_ENGINE_RULE'),
        component: Rule,
        display: false,
        displayLayout: true,
        path: '/rule/:id',
        rights: 'pricing_engine',
        private: true
    },
    {
        appbarName: translate.i18n('PRICING_ENGINE_RULE'),
        component: Rule,
        display: false,
        displayLayout: true,
        path: '/rule',
        rights: 'pricing_engine',
        private: true
    },
    {
        component: Login,
        display: true,
        displayLayout: false,
        path: '/login',
        rights: null
    },
    {
        component: ForgotPassword,
        display: true,
        displayLayout: false,
        path: '/forgot-password',
        rights: null
    },
    // Error
    {
        component: Error,
        display: false,
        displayLayout: false,
        path: '/error',
        error: true
    },
    // Redirection
    {
        path: '/',
        redirect: true,
        rights: null,
        to: '/smart-price-extractor'
    },
    {
        path: '/dashboard',
        redirect: true,
        rights: null,
        to: '/'
    },
    {
        path: '/price',
        redirect: true,
        rights: null,
        to: '/smart-price-extractor'
    },
    {
        path: '/profile',
        redirect: true,
        rights: null,
        to: '/profil'
    },
    {
        path: '/adbox_mail',
        redirect: true,
        rights: null,
        to: '/adbox'
    },
    // Not found
    {
        component: NotFound,
        display: true,
        displayLayout: false,
        path: '*',
        rights: null
    }
];

export default routes;
