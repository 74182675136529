export const createProduct = (fixedAttributes, optionalAttributes, websites) => {
    const item = {};

    fixedAttributes.forEach((ra) => {
        if (ra && ra.value)
            item[ra.name] = ra.type !== 'Int' ? ra.type === 'Double' ? Number.parseFloat(ra.value) : ra.value : Number.parseInt(ra.value);
    });

    item.custom_attributes = optionalAttributes;

    item.scraping_data = websites.map((website) => {
        const data = {
            params: {
                delay: website.delay
            },
            site_id: website.id,
            status: website.status
        };
        website.value ? data.params.url = website.value : null;
        return data;
    });

    return item;
};

const bannedAttributesNames = [
    'name',
    'category',
    'category_name',
    'category_id',
    'cms_product_id',
    'cms_last_known_stock',
    'img_url',
    'img_path',
    'repriced_price',
    'applied_rule',
    'not_applicable_rules',
    'rrp',
    'pp',
    'purchasing_price',
    'purchasing price',
    'rrp_alert_percentage',
    'rrp_alert_factor',
    'your_url'
];

export const verifyBannedNames = (attributeName) => bannedAttributesNames.includes(attributeName);

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
