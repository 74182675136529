const styles = theme => ({
    datePicker: {
        marginRight: '6px',
    },
    informationContainer: {
        height: 'calc(100% - 70px);'
    },
    loaderContainer: {
        alignItems: 'center',
        display: 'flex',
        height: 'calc(100% - 70px);',
        justifyContent: 'center',
        width: '100%'
    },
    noDataFoundContainer: {
        alignItems: 'center',
        color: theme.palette.grey.main,
        display: 'flex',
        fontSize: '30px',
        fontWeight: 'bold',
        height: 'calc(100% - 70px);',
        justifyContent: 'center'
    },
    root: {
        height: '100%',
        overflowX: 'auto',
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%'
    },
    topContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
        marginTop: 8,
        width: '100%'
    }
});

export default styles;
