// Images
import BackgroundImg from 'assets/img/background-logo.jpg';

const styles = theme => ({
    '@media (max-width: 600px)': {
        logo: {
            display: 'none'
        }
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(2)
    },
    button: {
        margin: '16px 16px 8px 16px'
    },
    envMessage: {
        color: 'white',
        fontSize: '17px',
        fontWeight: 500,
        marginBottom: '20px',
        textAlign: 'center',
        textShadow: '0px 0px 7px #000000'
    },
    envText: {
        color: '#FF3D3D'
    },
    errorMessage: {
        color: theme.palette.red.main,
        fontFamily: 'roboto',
        fontSize: '12px',
        fontWeight: '400',
        marginBottom: theme.spacing(1) / 2,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1) / 2
    },
    link: {
        '&:hover': {
            color: theme.palette.grey.dark,
            textDecoration: 'none'
        },
        color: theme.palette.primary.dark
    },
    loginBackground: {
        backgroundImage: 'url(' + BackgroundImg + ')',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        height: '100%',
        position: 'fixed',
        right: 0,
        top: 0,
        width: '100%'
    },
    loginContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        maxWidth: '698px'
    },
    logo: {
        height: '123px',
        margin: '15px',
        position: 'absolute',
        width: '100px'
    },
    paper: {
        alignItems: 'center',
        backgroundColor: theme.palette.white.main,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '300px',
        padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`
    },
    progress: {
        margin: theme.spacing(2)
    },
    promoText: {
        color: theme.palette.white.main,
        fontSize: '18px',
        fontWeight: 500,
        marginBottom: '5px',
        textAlign: 'center',
        textShadow: '0px 0px 7px #000000'
    },
    startIcon: {
        color: theme.palette.primary.main
    },
    textField: {
        width: '90%'
    }
});

export default styles;
