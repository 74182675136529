import React, { useState } from 'react';
import PropTypes from 'prop-types';
//Styles
import { useStyles } from 'components/dialog/DialogAddProduct/DialogAddProduct.style';
//Facade
import Facade from 'core/Facade';
//Pcp components
import imgNotFound from 'assets/img/img-not-found.png';
import DialogEdit from 'components/dialog/DialogEdit/DialogEdit';
//Mui components
import {
    Button,
    InputAdornment,
    TextField
} from '@mui/material';
import BellIcon from '@mui/icons-material/Notifications';
//Locales
import translate from 'locales/i18n';

const DialogAddProductScrappingData = ({
    handleChangeWebsite,
    websites
}) => {

    const classes = useStyles();

    const [openDialogEdit, setOpenDialogEdit] = useState();
    const [currentWebsite, setCurrentWebsite] = useState();

    const renderDialogEdit = () =>
        <DialogEdit
            close={() => setOpenDialogEdit(false)}
            open={openDialogEdit}
            title={translate.i18n('CHOOSE_A_DELAY')}
            label={translate.i18n('DELAY')}
            startAdornment={<InputAdornment position='start'>{translate.i18n('DAYS')}</InputAdornment>}
            callback={delay => {
                handleChangeWebsite(currentWebsite.id, 'NV_BELL', delay)();
                setOpenDialogEdit(false);
            }}
            type='number'
            defaultValue={30} />;

    if (websites.length === 0)
        return null;

    return <div className={classes.scrapingInformation}>
        {websites.map((website, key) => <div className={classes.scrapingDataFormContainer} key={key}>
            <img src={Facade.getWebsiteIco(website.url)}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = imgNotFound;
                }}
                alt={Facade.siteDisplayName(website.name)}
                className={classes.logo} />
            <p className={classes.websiteName}>{Facade.siteDisplayName(website.name)}</p>
            <TextField
                size='small'
                id={website.id}
                className={classes.margin}
                variant='outlined'
                label={'URL ' + Facade.siteDisplayName(website.name)}
                value={website.value}
                onChange={handleChangeWebsite(website.id)}
                fullWidth
                disabled={website.status === 'NV' || website.status === 'NV_BELL'}
            />
            <Button
                variant='contained'
                size='small'
                color={website.status === 'NV' ? 'secondary' : 'inherit'}
                className={classes.margin}
                onClick={handleChangeWebsite(website.id, website.status === 'NV' ? '' : 'NV')}>
                {translate.i18n('NOT_SOLD')}
            </Button>
            <Button
                variant='contained'
                size='small'
                color={website.status === 'NV_BELL' ? 'secondary' : 'inherit'}
                className={classes.margin}
                onClick={() => {
                    if (website.status === 'NV_BELL') {
                        handleChangeWebsite(website.id, '')();
                    } else {
                        setCurrentWebsite(website);
                        setOpenDialogEdit(true);
                    }
                }}>
                {translate.i18n('NOT_SOLD')}
                <BellIcon className={classes.rightIcon} />
            </Button>
        </div>)}
        {renderDialogEdit()}
    </div>;

};

DialogAddProductScrappingData.propTypes = {
    handleChangeWebsite: PropTypes.func,
    websites: PropTypes.array
};

export default DialogAddProductScrappingData;
