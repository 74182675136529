import AbstractService from 'core/abstracts/AbstractService';
import Facade from 'core/Facade';

class SuggestionService extends AbstractService {

    /**
     * Get Suggestions
     */
    getSuggestions(accountId, itemId, siteId, page, size) {
        return this.request({
            method: 'GET',
            url: Facade.getApiRoot() + `accounts/${accountId}/products/${itemId}/suggestions?siteId=${siteId}&p=${page}&ps=${size}`
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Get Suggestions
     */
    searchSuggestions(accountId, siteId, page, size, searchText) {
        return this.request({
            method: 'GET',
            url: Facade.getApiRoot() + `accounts/${accountId}/sites/${siteId}/products?p=${page}&ps=${size}&q=${searchText}`
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }
}

export default new SuggestionService();
