import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    addButton: {
        marginLeft: '24px',
        width: '100px'
    },
    attributesContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    buttonCategorySettings: {
        height: 'fit-content',
        padding: 0
    },
    categoryButtonContainer: {
        alignItems: 'center',
        display: 'flex'
    },
    categorySelect: {
        width: '90%'
    },
    closeButton: {
        position: 'absolute',
        right: '2px',
        top: '2px'
    },
    dialogContent: {
        height: '100%',
        overflow: 'hidden',
        padding: 0,
        width: '100%'
    },
    dialogPaper: {
        height: '100%',
        maxWidth: '100%',
        width: '100%'
    },
    dialogEditPaper: {
        height: '70%',
        maxWidth: '100%',
        width: '720px'
    },
    errorMessage: {
        color: theme.palette.red.main,
        fontFamily: 'roboto',
        fontSize: '12px',
        fontStyle: 'italic',
        fontWeight: '400',
        marginBottom: theme.spacing(1) / 2,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1) / 2
    },
    fixedAttribute: {
        margin: theme.spacing(1),
        width: '25%'
    },
    iconCategorySettings: {
        height: '30px',
        width: '30px'
    },
    img: {
        height: 'auto',
        maxHeight: '250px',
        maxWidth: '250px',
        width: 'auto'
    },
    imgContainer: {
        alignItems: 'center',
        borderRadius: '3px',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        display: 'flex',
        height: '252px',
        justifyContent: 'center',
        margin: theme.spacing(1),
        width: '252px'
    },
    itemInformation: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(2),
        minHeight: '250px'
    },
    leftIcon: {
        fontSize: 20,
        marginRight: theme.spacing(1)
    },
    listIcon: {
        color: 'grey',
        cursor: 'pointer'
    },
    logo: {
        minHeight: '21px',
        minWidth: '21px'
    },
    margin: {
        margin: theme.spacing(1)
    },
    optionalAttribute: {
        alignItems: 'center',
        display: 'flex'
    },
    optionalAttributesContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%'
    },
    optionalAttributeText: {
        margin: theme.spacing(1),
        width: '250px'
    },
    requiredAttributesContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    scrapingDataFormContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        margin: theme.spacing(1),
        minHeight: '50px'
    },
    scrapingInformation: {
        flexDirection: 'column',
        padding: theme.spacing(1)
    },
    scrollContainer: {
        height: '100%',
        padding: '0 24px 24px',
        width: '100%'
    },
    subtitle: {
        fontFamily: 'roboto',
        fontSize: '14',
        fontWeight: '500',
        lineHeight: '1.6',
        margin: theme.spacing(1)
    },
    subSubtitle: {
        margin: '0px',
        fontSize: '12px',
        color: theme.palette.grey.main,
        marginLeft: theme.spacing(1)
    },
    websiteName: {
        fontFamily: 'roboto',
        fontSize: '14px',
        fontWeight: '400',
        margin: theme.spacing(1),
        width: '200px'
    }
}));
