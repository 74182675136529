/**
  * Method to validate a password
  *
  * @param password <string>
  * @returns <boolean> if password is valid or not
  */
export const validatePassword = password => {
    if (!password || typeof password !== 'string') return false;

    const re = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?=_€£$;&@+-])(?=.{8,})');
    return re.test(password);
};
