import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { jsPDF } from 'jspdf';
import * as htmlToImage from 'html-to-image';
import PoweredByPCP from 'assets/img/PoweredByPCP.png';
import Facade from 'core/Facade';
// Material UI Components
import GetAppIcon from '@mui/icons-material/GetApp';
import SvgIcon from '@mui/material/SvgIcon';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CachedIcon from '@mui/icons-material/Cached';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
// Style
import { hrStyle } from 'components/pages/ProductPage/ProductPage.style';
import { useStyles } from 'components/chart/PriceHistoryChart/PriceHistoryChart.style';
// PCP Components
import { Graph } from 'pcp-graphical-lib';
// Stores
import UserStore from 'stores/UserStore';
import LayoutStore from 'stores/LayoutStore';
// Locales
import translate from 'locales/i18n';

export const ChartSection = ({ data, date, currency, reset, productName }) => {
    const [graphRef, setGraphRef] = useState();
    const [fullscreen, setFullscreen] = useState(false);
    const [downloadMode, setDownloadMode] = useState(false);
    const classes = useStyles();

    const [blur, setBlur] = useState(UserStore.getCurrentUser().isSuperUser() ? LayoutStore.getBlur() : false);

    function onLayoutStoreChange() {
        setBlur(LayoutStore.getBlur());
    }

    useEffect(() => {
        UserStore.getCurrentUser().isSuperUser()? LayoutStore.addChangeListener(onLayoutStoreChange):null;
        return () => UserStore.getCurrentUser().isSuperUser()? LayoutStore.removeChangeListener(onLayoutStoreChange):null;
    }, []);

    // TODO : Get it out in util file
    const handleDownload = () => {
        setFullscreen(true);
        setDownloadMode(true);
        setTimeout(() => {
            htmlToImage.toPng(graphRef.current)
                .then(function (dataUrl) {
                    // Make pdf
                    const doc = new jsPDF({ orientation: 'landscape', compress: false });
                    const svgWidth = graphRef.current.clientWidth;
                    const svgHeight = graphRef.current.clientHeight;
                    const pdfWidth = doc.internal.pageSize.getWidth();
                    const pdfHeight = doc.internal.pageSize.getHeight();
                    const ratio = pdfWidth / svgWidth;
                    // Add product Name
                    doc.text(productName, 5, 10);
                    // Add image
                    doc.addImage(dataUrl, 'PNG', 5, 20, svgWidth * ratio - 10, svgHeight * ratio - 10);
                    const imgSize = 50;
                    doc.addImage(PoweredByPCP, 'PNG', pdfWidth - imgSize - 5, pdfHeight - imgSize * 0.220670391 - 5, imgSize, imgSize * 0.220670391);

                    // Save document
                    doc.save('charts.pdf');
                    setFullscreen(false);
                    setDownloadMode(false);
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
        }, 500);
    };

    return (
        <div className={fullscreen ? classes.chartSectionFullScreen : classes.chartSection}>
            <div className={classes.contentTitleContainer}>
                <div className={classes.contentTitle}>{translate.i18n('EVOLUTION_OF_PRICE_AND_STOCK')}</div>
                <hr style={hrStyle}></hr>
                <Tooltip title='Reset' placement='top'>
                    <IconButton onClick={() => reset()} size='large'>
                        <SvgIcon component={CachedIcon} />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Télécharger' placement='top'>
                    <IconButton onClick={() => handleDownload()} size='large'>
                        <SvgIcon component={GetAppIcon} />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Plein écran' placement='top'>
                    <IconButton onClick={() => setFullscreen(!fullscreen)} size='large'>
                        <SvgIcon component={fullscreen ? FullscreenExitIcon : FullscreenIcon} />
                    </IconButton>
                </Tooltip>
            </div>
            <Graph
                data={data}
                blur={blur}
                date={date}
                currency={currency}
                downloadMode={downloadMode}
                setGraphRef={setGraphRef}
                siteDisplayName={Facade.siteDisplayName}
                showDecimal={Facade.showDecimal}
            />
        </div>
    );
};

ChartSection.propTypes = {
    data: PropTypes.array,
    date: PropTypes.object,
    currency: PropTypes.object,
    reset: PropTypes.func,
    productName: PropTypes.string
};
