/**
  * Method called when you want top get the current route prop
  *
  * @param routes An array of routes object
  * @returns <object> The current route
  */
export const getCurrentRouteProp = routes => {
    const [, path] = window.location.pathname.split('/');
    for (let i = 0; i < Object.keys(routes).length; i += 1) {
        if (routes[i].path.split('/')[1] === path || routes[i].path === '*')
            return routes[i];
    }
    return null;
};
