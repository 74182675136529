/**
  * Method which return the color for the notation
  *
  * @param item <object|number> the item or the rating of the item
  * @returns <string> of notation color
  */
export const getRatingColor = (item) => {

    if (!item) {
        return '#C7C7C7';
    }

    if (typeof item === 'number') {
        if (item < 1.7) {
            return '#D12626';
        } else if (item < 2.7) {
            return '#E77811';
        } else if (item < 3.7) {
            return '#EEC329';
        } else if (item < 4.2) {
            return '#64A630';
        }
        return '#30A6A6';
    }

    if (item.count === '' || item.count === '0') {
        return '#C7C7C7';
    }
    const grade = () => {
        if (typeof item.rating === 'string') {
            return (item.rating.replace(',','.') / item.max_rating) * 5;
        }
        return (item.rating / item.max_rating) * 5;
    };
    if (!grade() || grade() > 5) {
        return '#C7C7C7';
    }
    if (grade() < 1.7) {
        return '#D12626';
    } else if (grade() < 2.7) {
        return '#E77811';
    } else if (grade() < 3.7) {
        return '#EEC329';
    } else if (grade() < 4.2) {
        return '#64A630';
    }
    return '#30A6A6';
};

/**
  * Method which return the grade in the display format
  *
  * @param price <number> the grade on 5
  * @returns <string> the grade with 2 digit and a coma instead of a dot
  */
export const displayRating = (price) => {
    if (!price) {
        return '';
    }

    if (typeof price !== 'number' && typeof price !== 'string') {
        return 'NA';
    }
    let priceString = price.toString();

    while (priceString.length > 3) {
        priceString = priceString.slice(0, -1);
    }

    priceString = priceString.replace('.', ',');

    return priceString;
};
