// Abstract
import AbstractStore from 'core/abstracts/AbstractStore';
import AccountStore from 'stores/AccountStore';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Facade
import Facade from 'core/Facade';
import Color from 'color';

class ItemStore extends AbstractStore {

    constructor() {

        const CHANGE_EVENT = 'ITEM_CHANGE_EVENT';
        const NAME = 'ITEM_STORE';

        super(NAME, CHANGE_EVENT);

        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));

        this._initStore();
    }

    _initStore() {
        // Items
        this.items = [];

        // If he is waiting for new items or history
        this.loading = true;
        this.isLoadingHistory = true;

        this.params = this._initParams();

        this.count = 0;

        this.categories = [];

        this.websites = [];

        this.pricesBaskets = [];

        this.currentItem = null;

        this.history = null;

        this.isLoadingExport = false;

        this.itemLoading = true;

        this.exportError = false;

        this.firstLoad = true;

        this.itemFirstLoad = true;

        this.avgEvaluation = {};
    }

    _initParams() {
        const params = this.getInitialParams();

        const urlParams = Facade.getAllUrlParams();

        if (urlParams.length === 0)
            return params;

        if (Facade.getUrlParam('searchText'))
            params.searchText = Facade.getUrlParam('searchText');

        if (Facade.getUrlParam('sort'))
            params.sort = Facade.getUrlParam('sort');

        if (Facade.getUrlParam('mode'))
            params.mode = Facade.getUrlParam('mode');

        if (Facade.getUrlParam('from'))
            params.from = new Date(Facade.getUrlParam('from'));

        if (Facade.getUrlParam('to'))
            params.to = new Date(Facade.getUrlParam('to'));

        if (Facade.getUrlParam('categories'))
            params.categories = Facade.getUrlParam('categories').split(',');

        if (Facade.getUrlParam('pricesBaskets'))
            params.pricesBaskets = Facade.getUrlParam('pricesBaskets').split(',');

        if (Facade.getUrlParam('websites'))
            params.websites = Facade.getUrlParam('websites').split(',');

        if (Facade.getUrlParam('pageSize'))
            params.pageSize = parseInt(Facade.getUrlParam('pageSize'));

        if (Facade.getUrlParam('pageIndex'))
            params.pageIndex = parseInt(Facade.getUrlParam('pageIndex'));

        if (Facade.getUrlParam('leaderships')) {
            const leadershipsList = Facade.getUrlParam('leaderships').split(',');
            for (let index = 0; index < leadershipsList.length; index += 2) {
                const newLeadership = [leadershipsList[index], leadershipsList[index + 1]];
                params.leadership.push(newLeadership);
            }
        }

        if (Facade.getUrlParam('stocksBaskets')) {
            const stocksBasketsList = Facade.getUrlParam('stocksBaskets').split(',');
            for (let index = 0; index < stocksBasketsList.length; index += 2) {
                const newStocksBaskets = [stocksBasketsList[index], stocksBasketsList[index + 1]];
                params.stocksBaskets.push(newStocksBaskets);
            }
        }

        return params;
    }

    isLoading() {
        return this.loading;
    }

    isLoadingItem() {
        return this.itemLoading;
    }

    getInitialParams() {
        const yesterdayDate = new Date();
        yesterdayDate.setDate(yesterdayDate.getDate() - 1);

        return {
            accountId: AccountStore.getCurrentAccount() ? AccountStore.getCurrentAccount().getId() : '',
            categories: [],
            from: yesterdayDate,
            leadership: [],
            mode: 'listing',
            pageIndex: 1,
            pageSize: 10,
            pricesBaskets: [],
            searchText: '',
            sort: 'alpha_asc',
            stocksBaskets: [],
            to: new Date(),
            websites: []
        };
    }

    getItems() {
        return this.items;
    }

    getParams() {
        this.params.accountId = AccountStore.getCurrentAccount() ? AccountStore.getCurrentAccount().getId() : '';
        return this.params;
    }

    getCategories() {
        return this.categories;
    }

    getWebsites() {
        return this.websites;
    }

    getPricesBaskets() {
        return this.pricesBaskets;
    }

    getCount() {
        return this.count;
    }

    getHistory() {
        return this.history;
    }

    getCurrentItem() {
        return this.currentItem;
    }

    getLoadingExport() {
        return this.isLoadingExport;
    }

    getLoadingHistory() {
        return this.isLoadingHistory;
    }

    getExportError() {
        return this.exportError;
    }

    getUrl(siteId) {
        if (!this.websites)
            return '';
        return this.websites.find(e => e.id === siteId) ? this.websites.find(e => e.id === siteId).url : '';
    }

    getAvgEvaluation() {
        return this.avgEvaluation;
    }

    setData(data) {
        this.count = data.count;
        this.items = data.items;
        this.categories = data.categories;
        this.pricesBaskets = data.pricesBaskets;
        this.websites = data.sites;
        this.avgEvaluation = data.evaluation;
    }

    setParams(params) {
        this.params = Object.assign({}, params);
    }

    setParam(name, value) {
        this.params[name] = value;
    }

    setExportError(bool) {
        this.exportError = bool;
    }

    setHistory(history) {
        const tempHistory = [];
        history.forEach((val) => {
            if (val.color[0] !== '#') val.color = '#' + val.color;

            try {
                val.color = Color(val.color !== '#' ? val.color : '#000000').alpha(0.6).string();
            } catch (error) {
                val.color = Color('#000000').alpha(0.6).string();
            }

            tempHistory.push(val);
        });
        this.history = tempHistory;
    }

    setCurrentItem(data) {
        this.currentItem = data;
    }

    setUserSettings() {
        if (!Facade.getUrlParam('pageSize'))
            this.params.pageSize = 10;
    }

    deleteItem(id) {
        this.items = this.items.filter(item => item.id !== id);
    }

    updateScrapingData(data) {
        this.items = this.items.map((item) => {
            if (item.id === data.itemId) {
                item.scrapingData = item.scrapingData.map((scrapingData) => {
                    if (scrapingData.siteId === data.siteId) {
                        data.newScrapingData.isLoading = false;
                        return data.newScrapingData;
                    }

                    return scrapingData;
                });
            }

            return item;
        });
    }

    updateScrapedData(data) {
        this.items = this.items.map((item) => {
            if (item.id === data.itemId) {
                item.scrapingData = item.scrapingData.map((scrapingData) => {
                    if (scrapingData.siteId === data.siteId) {
                        scrapingData.to = data.newScrapedData;
                        scrapingData.isLoading = false;
                    }
                    return scrapingData;
                });
            }

            return item;
        });
    }

    changeLoadingStatusScrapingData(data, loadingStatus) {
        this.items = this.items.map((item) => {
            if (item.id === data.itemId) {
                item.scrapingData = item.scrapingData.map((scrapingData) => {
                    if (scrapingData.siteId === data.siteId) {
                        scrapingData.isLoading = loadingStatus;
                    }
                    return scrapingData;
                });
            }

            return item;
        });
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
        case Constants.USER_LOGOUT:
            this._initStore();
            this._emitChange();
            break;
        case Constants.USER_LOGIN:
            this.setUserSettings(action.value.user);
            break;
        case Constants.ITEM_SEARCH_START:
            this.setParams(action.value);
            this.loading = true;
            this._emitChange();
            break;
        case Constants.ITEM_SEARCH_END:
            this.setData(action.value);
            this.firstLoad = false;
            this.loading = false;
            this._emitChange();
            break;
        case Constants.PRODUCT_GET_START:
            this.itemLoading = true;
            this._emitChange();
            break;
        case Constants.PRODUCT_GET_END:
            this.itemLoading = false;
            this.itemFirstLoad = false;
            this.setCurrentItem(action.value);
            this._emitChange();
            break;
        case Constants.PRODUCT_GET_END_ERROR:
            this.itemLoading = false;
            this.itemFirstLoad = false;
            this._emitChange();
            break;
        case Constants.FETCH_ITEM_HISTORY_START:
            this.history = null;
            this.isLoadingHistory = true;
            this._emitChange();
            break;
        case Constants.FETCH_ITEM_HISTORY_END:
            this.firstLoad = false;
            this.isLoadingHistory = false;
            this.setHistory(action.value);
            this._emitChange();
            break;
        case Constants.ITEM_EXPORT_START:
            this.isLoadingExport = true;
            this._emitChange();
            break;
        case Constants.ITEM_EXPORT_END:
            this.setExportError(action.value);
            this.isLoadingExport = false;
            this._emitChange();
            break;
        case Constants.ITEM_UPDATE_SCRAPING_DATA:
            this.updateScrapingData(action.value);
            this._emitChange();
            break;
        case Constants.ITEM_START_UPDATE_SCRAPING_DATA:
            this.changeLoadingStatusScrapingData(action.value, true);
            this._emitChange();
            break;
        case Constants.PRODUCT_DELETED:
            this.deleteItem(action.value);
            this._emitChange();
            break;
        case Constants.ITEM_UPDATE_SCRAPED_DATA:
            this.updateScrapedData(action.value);
            this._emitChange();
            break;
        case Constants.ITEM_START_UPDATE_SCRAPED_DATA:
            this.changeLoadingStatusScrapingData(action.value, true);
            break;
        case Constants.ITEM_UPDATE_DATA_FAILED:
            this.changeLoadingStatusScrapingData(action.value, false);
            break;
        case Constants.RESET_ITEM_STORE:
            this._initStore();
            this._emitChange();
            break;
        case Constants.ACCOUNT_CHANGE:
            this._initStore();
            this._emitChange();
            break;
        default:
            return false;
        }
    }
}

export default new ItemStore();
