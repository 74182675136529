import translate from 'locales/i18n';

const daysNames = [
    translate.i18n('SUNDAY'),
    translate.i18n('MONDAY'),
    translate.i18n('TUESDAY'),
    translate.i18n('WEDNESDAY'),
    translate.i18n('THURSDAY'),
    translate.i18n('FRIDAY'),
    translate.i18n('SATURDAY')
];

/**
  * Method which format a date to display it with day name or without day name
  *
  * @param dateToFormat <Date> object
  * @param withDay <boolean>
  * @returns <string> with the formatted date
  */
export const formatDate = (dateToFormat, withDay) => {
    const date = new Date(dateToFormat);

    return withDay ?
        daysNames[date.getDay()].charAt(0).toUpperCase() + daysNames[date.getDay()].substring(1).toLowerCase() :
        (date.getDate() >= 10 ?
            date.getDate() : '0' + date.getDate()) + '/' +
        (date.getMonth() + 1 >= 10 ?
            date.getMonth() + 1 : '0' + (date.getMonth() + 1)) + '/' + date.getFullYear();
};

export const similarDate = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
};

export const getStats = (sites, evolution) => {
    const daysStats = [
        { dayNumber: 1, nbNewSlides: 0, nbRemovedSlides: 0, selected: true },
        { dayNumber: 2, nbNewSlides: 0, nbRemovedSlides: 0, selected: true },
        { dayNumber: 3, nbNewSlides: 0, nbRemovedSlides: 0, selected: true },
        { dayNumber: 4, nbNewSlides: 0, nbRemovedSlides: 0, selected: true },
        { dayNumber: 5, nbNewSlides: 0, nbRemovedSlides: 0, selected: true },
        { dayNumber: 6, nbNewSlides: 0, nbRemovedSlides: 0, selected: true },
        { dayNumber: 7, nbNewSlides: 0, nbRemovedSlides: 0, selected: true }
    ];

    const globalStats = {
        averageNew: 0,
        averageRemoved: 0,
        totalNew: 0,
        totalRemoved: 0
    };

    const colors = ['#020A0C', '#093239', '#0F4F5A', '#126D7D', '#68A2AC', '#93BCC3', '#A8C9CF', '#BED7DB'];

    let sitesStats = sites.map(site => {
        site.nbNew = 0;
        site.nbRemoved = 0;

        evolution.forEach(day => {
            const date = new Date(day.date);
            day.summary.forEach(sum => {
                if (sum.slider_site_id === site.id) {
                    const nbNew = sum.slider_images.filter(s => s.status === 'new').length;
                    const nbRemoved = sum.slider_images.filter(s => s.status === 'removed').length;

                    globalStats.totalNew += nbNew;
                    site.nbNew += nbNew;
                    daysStats[date.getDay()].nbNewSlides = daysStats[date.getDay()].nbNewSlides + nbNew;

                    globalStats.totalRemoved += nbRemoved;
                    site.nbRemoved += nbRemoved;
                    daysStats[date.getDay()].nbRemovedSlides = daysStats[date.getDay()].nbRemovedSlides + nbRemoved;
                }
            });
        });

        return site;
    });

    sitesStats.sort((s1, s2) => s1.nbNew + s1.nbRemoved > s2.nbNew + s2.nbRemoved ? -1 : 1);

    sitesStats = sitesStats.map((s, key) => {
        s.color = colors[key];
        return s;
    });

    globalStats.averageNew = globalStats.totalNew / (evolution.length / 7);
    globalStats.averageRemoved = globalStats.totalRemoved / (evolution.length / 7);

    return {
        daysStats,
        globalStats,
        sites: sitesStats
    };
};
