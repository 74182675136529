import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    image: {
        cursor: 'pointer',
        maxHeight: '100%',
        width: '100%',
    },
    imageContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    },
    imgLarge: {
        borderRadius: '3px',
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
        maxHeight: '400px',
        maxWidth: '400px'
    },
    newBoxOnPicture: {
        color: 'lightgreen',
        fontSize: '250%',
        fontWeight: 'bold',
        height: '100%',
        marginTop: '1%',
        position: 'absolute',
        textAlign: 'center',
        verticalAlign: 'center',
        width: '48%'
    },
    popper: {
        zIndex: 1500
    },
    removeBoxOnPicture: {
        color: 'red',
        fontSize: '250%',
        fontWeight: 'bold',
        height: '100%',
        marginTop: '1%',
        position: 'absolute',
        textAlign: 'center',
        verticalAlign: 'center',
        width: '48%'
    },
    root: {
        height: '100%',
        padding: '0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));
