import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: '2px',
        top: '2px'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    dialogContentNotFullScreen: {
        height: '100%'
    },
    dialogTitle: {
        cursor: 'pointer'
    },
    img: {
        marginRight: theme.spacing(1),
        marginTop: '20px'
    },
    itemPicture: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0'
    },
    titleDialogBox: {
        display: 'flex'
    },
    titleItemDialog: {
        color: theme.palette.grey.dark,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 0,
        marginLeft: '2%',
        marginTop: '10px'
    },
    titleWebsiteDialog: {
        color: theme.palette.grey.dark,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 0,
        marginLeft: '30%',
        paddingLeft: '20%'
    },
    websitePicture: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '2%'
    }
}));
