/* eslint-disable */

class Logger {
    welcome() {
        console.log('%c Welcome on PCP!', 'font-weight: bold; font-size: 35px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)');
    }

    error(message) {
        console.log('%c <<<< error >>>> ', 'color: white; background-color: #b51500; font-size: 12px; font-weight: bold');
        console.error(message);
    }

    info(message) {
        console.log('%c <<<< info >>>> ', 'color: white; background-color: #0036b5; font-size: 12px; font-weight: bold');
        console.info(message);
    }

    warning(message) {
        console.log('%c <<<< warning >>>> ', 'color: white; background-color: #ab8c00; font-size: 12px; font-weight: bold');
        console.warn(message);
    }

    success(message) {
        console.log('%c <<<< success >>>> ', 'color: white; background-color: #00911d; font-size: 12px; font-weight: bold');
        console.log(message);
    }
}

export default new Logger();
