/**
  * Method called to calc the higher cardinalities of an array off websites
  *
  * @param websites array<object>> the array of website
  * @returns <int> higher cardinality
  */
export const getHigherCardinality = websites => {
    let higherCardinality = 0;

    websites.map((website) => {
        if (website.stats.cardinality > higherCardinality)
            higherCardinality = website.stats.cardinality;
        return true;
    });

    return higherCardinality;
};

/**
  * Method called to calc the color and size of each markers for an array of websites
  *
  * @param websites array<object>> the array of websites
  * @returns array<object>> array of websites with size and color
  */
export const calcColorAndSize = (websites, theme) => {
    const colors = [theme.palette.graph.one, theme.palette.graph.two, theme.palette.graph.three, theme.palette.graph.four, theme.palette.graph.five];
    let lowerWinningRate = 0;
    let higherWinningRate = 0;
    let slot = 0;

    websites.map((website) => {
        if (website.stats.rate > higherWinningRate)
            higherWinningRate = website.stats.rate;
        if (website.stats.rate < lowerWinningRate)
            lowerWinningRate = website.stats.rate;
        return true;
    });

    slot = (higherWinningRate - lowerWinningRate) / 5;

    websites.map((website) => {
        let position = (website.stats.rate - lowerWinningRate) / slot;
        if (position === 5)
            position = 4;
        website.color = colors[Math.floor(position)];
        return true;
    });

    return websites;
};
