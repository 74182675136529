// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// Material UI
import { TableCell } from '@mui/material';
import IconUrl from '@mui/icons-material/OpenInNew';
// Stores
import AccountStore from 'stores/AccountStore';
import UserStore from 'stores/UserStore';
// Actions
import ItemAction from 'actions/ItemAction';
// PCP Components
import DialogConfirm from 'components/dialog/DialogConfirm/DialogConfirm';
import ImageViewer from 'components/commons/ImageViewer/ImageViewer';
import NotationSheet from 'components/sheet/NotationSheet/NotationSheet';
// Locales
import translate from 'locales/i18n';
// Style
import { useStyles } from 'components/cell/ItemCell/ItemCell.style';
//Theme
import { useTheme } from '@mui/styles';
// Facade
import Facade from 'core/Facade';

const ItemCell = ({ canDelete, index, item, hideNotations }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [dialogConfirmOpen, setDialogConfirmOpen] = useState(false);

    const renderRefs = () => <div className={classes.refsContainer}>
        {item.customAttributes.map((r, key) => key < 3 && <div key={key} className={classes.ref} style={{ maxWidth: '60 px' }}>
            {r.name} : &nbsp;
            {r.value.startsWith('http') ?
                <IconUrl onClick={() => Facade.openUrl(r.value)} className={classes.icon} />
                :
                <>{r.value}</>
            }
        </div>)}
    </div>;

    return (
        <TableCell className={classes.tableCell} style={{ backgroundColor: index % 2 === 0 ? theme.palette.grey.semilight : theme.palette.white.main }}>
            <div className={classes.tableCellContent}>
                {UserStore.getCurrentUser().isScrapingDataEditor() && canDelete ?
                    <div className={classes.deleteButton} onClick={() => setDialogConfirmOpen(true)}>x</div>
                    :
                    null
                }
                <div className={classes.imgContainer}>
                    <ImageViewer
                        imageUrl={'https://img.pricecomparator.pro/' + item.imgPath + '?n=' + new Date().getTime()}
                        title={item.name}
                        alt={item.name}
                    />
                </div>
                <div>
                    <Link to={'/items/' + item.id} className={classes.link}>
                        <div className={classes.label} title={item.name}>
                            {item.name}
                        </div>
                        {renderRefs()}
                    </Link>
                </div>
                {!hideNotations &&
                    <div className={classes.itemAvgEval}>
                        <NotationSheet evaluation={item.evaluation} isAvg={true} />
                    </div>
                }
            </div>
            {UserStore.getCurrentUser().isScrapingDataEditor() ?
                <DialogConfirm open={dialogConfirmOpen}
                    close={() => setDialogConfirmOpen(false)}
                    callback={() => ItemAction.deleteProduct({
                        accountId: AccountStore.getCurrentAccount().getId(),
                        itemId: item.id
                    }, false)}
                    text={translate.i18n('ITEM_DELETE_CONFIRM', { name: item.name })} />
                :
                null
            }
        </TableCell>
    );
};

ItemCell.propTypes = {
    canDelete: PropTypes.bool,
    index: PropTypes.number,
    item: PropTypes.object,
    hideNotations: PropTypes.bool
};

export default ItemCell;
