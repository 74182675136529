import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.grey.dark,
        display: 'flex',
        height: '100%',
        textDecoration: 'none',
        width: '100%'
    },
    specificInformationContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        marginLeft: '4px',
        paddingRight: 2,
        width: '100%'
    },
    specificLabel: {
        color: theme.palette.primary.dark,
        fontSize: 11,
        fontWeight: '500',
        margin: '6px 0 0 0',
        overflow: 'hidden',
        padding: 0,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        wrap: 'nowrap',
        display: 'flex',
        justifyContent: 'center'
    },
    specificLabelValue: {
        color: theme.palette.primary.dark,
        fontSize: '75%',
        fontWeight: '400',
        margin: 0,
        overflow: 'hidden',
        padding: 0
    },
    tableCell: {
        borderBottom: '1px solid' + theme.palette.grey.light,
        borderRight: '1px solid' + theme.palette.grey.light,
        borderTop: 0,
        padding: 0,
        position: 'sticky',
        zIndex: 11,
        left: '271px',
        top: 'auto'
    },
    tableCellContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    blur: {
        filter: 'blur(0.2rem)'
    }
}));
