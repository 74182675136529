// React
import React from 'react';
import PropTypes from 'prop-types';
// Chart
import { Bar } from 'react-chartjs-2';
// Material UI Components
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import IconRefresh from '@mui/icons-material/Cached';
// Style
import { useStyles } from 'components/filter/mail/MailDayFilter/MailDayFilter.style';
// Util
import { createData, options } from 'components/filter/mail/MailDayFilter/MailDayFilter.util';
//Theme
import { useTheme } from '@mui/styles';

const MailDayFilter = ({
    resetDays,
    selectDay,
    statsByDay
}) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <>
            {!statsByDay ?
                <Paper className={classes.paper}>
                </Paper>
                :
                <Paper className={classes.paper}>
                    <div className={classes.graphContainer}>
                        <Bar
                            data={createData(statsByDay, theme)}
                            options={options}
                            getElementAtEvent={element => {
                                if (element.length !== 0) {
                                    selectDay('DAY', element[0].index);
                                }
                            }}
                        />
                    </div>
                    <IconButton className={classes.iconReset} color='primary' onClick={resetDays}>
                        <IconRefresh />
                    </IconButton>
                </Paper>

            }
        </>
    );
};

MailDayFilter.propTypes = {
    selectDay: PropTypes.func,
    statsByDay: PropTypes.array,
    resetDays: PropTypes.func
};

export default MailDayFilter;
