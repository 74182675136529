export const styles = theme => ({
    '@media (min-width: 600px)': {
        listItem: {
            paddingLeft: '24px',
            paddingRight: '24px'
        }
    },
    disableLink: {
        pointerEvents: 'none'
    },
    drawerPaper: {
        height: '100vh',
        position: 'relative',
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.sharp
        }),
        whiteSpace: 'nowrap',
        width: 200
    },
    drawerPaperClose: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9)
        },
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp
        }),
        width: theme.spacing(7)
    },
    list: {
        padding: '0px'
    },
    listItem: {
        '&:focus': {
            backgroundColor: theme.palette.grey.main
        }
    },
    listItemIcon: {
        minWidth: '36px'
    },
    navLink: {
        '&:active': {
            textDecoration: 'none'
        },
        '&:focus': {
            textDecoration: 'none'
        },
        '&:hover': {
            color: theme.palette.grey.dark,
            textDecoration: 'none'
        },
        '&:link': {
            textDecoration: 'none'
        },
        '&:visited': {
            textDecoration: 'none'
        },
        color: theme.palette.black.main,
        textDecoration: 'none'
    },
    routeName: {
        fontSize: '14px',
        fontWeight: '500',
        margin: 0,
    },
    selectedIcon: {
        backgroundColor: theme.palette.grey.main
    },
    toolbar: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar
    },
    arrow: {
        color: theme.palette.black.main,
    },
    tooltip: {
        backgroundColor: theme.palette.black.main,
    },
    tooltipLabel: {
        fontSize: '12px',
        marginTop: '2px',
        marginBottom: '2px',
    }
});
