import Site from 'core/entities/SiteObject';
class Account {
    constructor(data) {
        if (data) {
            this.id = data.id;
            this.logo = data.logo_url;
            this.name = data.name;
            this.type = data.type;
            this.site = data.site;
            this.attributes = data.attributes;
            this.currency = data.currency;
            this.modules = data.modules;
            this.priceMonitoring = {
                optionalAttributes: data.price_monitoring.optional_attributes,
                requiredAttributes: data.price_monitoring.required_attributes,
                sites: data.price_monitoring.sites.map(site => new Site(site))
            };
        }
    }

    /**
     * GETTERS
     */
    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getLogo() {
        return this.logo;
    }

    getType() {
        return this.type;
    }

    getSite() {
        return this.site;
    }

    getSiteId() {
        return this.site.id;
    }

    getCurrency() {
        return this.currency.symbol;
    }

    getModules() {
        return this.modules;
    }

    getPriceMonitoringInformations() {
        return this.priceMonitoring;
    }

    /**
     * SETTERS
     */
    setName(name) {
        this.name = name;
    }

    setLogo(logo) {
        this.logo = logo;
    }

    setType(type) {
        this.type = type;
    }

    /**
     * METHODS
     */
    isSupplier() {
        return this.type === 'SUPPLIER';
    }

    isRetailer() {
        return this.type === 'RETAILER';
    }

    getRight(right) {
        if (this.getModules() && this.getModules()[right]) {
            return this.getModules()[right];
        }

        return false;
    }
}

export default Account;
