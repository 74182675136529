import React from 'react';

const calendarFirstDay = (width,height,color) =>
    <svg width={width ? width:'25'} height={height ? height:'25'} viewBox='0 0 21 21' xmlns='http://www.w3.org/2000/svg'>
        <g transform='translate(2.5,20.5) scale(0.003,-0.003)' fill={color ? color:'#fff'} stroke='#fff'>
            <path d='M790 5109 c-57 -23 -93 -80 -92 -146 l1 -43 -122 0 c-123 0 -181 -11
                -248 -46 -94 -48 -177 -149 -211 -256 -17 -57 -18 -144 -18 -2151 0 -1740 3
                -2101 14 -2143 42 -160 175 -283 336 -314 34 -6 686 -10 1854 -10 l1801 0 458
                458 457 457 0 1822 c0 1744 -1 1824 -18 1881 -34 107 -117 208 -211 256 -67
                35 -125 46 -248 46 l-122 0 0 43 c1 56 -13 88 -50 120 -100 84 -241 15 -241
                -118 l0 -45 -283 0 -283 0 0 49 c2 84 -61 146 -147 146 -90 0 -147 -61 -147
                -155 l0 -40 -282 2 -283 3 3 37 c7 79 -67 158 -148 158 -81 0 -155 -79 -148
                -158 l3 -37 -283 -3 -282 -2 0 48 c0 91 -71 158 -159 149 -80 -9 -137 -70
                -134 -148 l1 -49 -284 0 -284 0 0 49 c0 106 -103 178 -200 140z m4000 -2431
                l0 -1662 -27 -28 -27 -28 -212 0 c-250 0 -289 -9 -367 -85 -86 -85 -92 -107
                -95 -365 l-3 -225 -27 -27 -26 -28 -1732 0 c-1251 0 -1748 3 -1786 11 -65 14
                -130 73 -147 134 -8 29 -11 596 -11 2003 l0 1962 2230 0 2230 0 0 -1662z'/>
            <path d='M2505 3268 c-38 -116 -102 -188 -210 -238 -68 -32 -140 -47 -267 -56
                l-88 -6 0 -164 0 -164 210 0 210 0 0 -765 0 -765 265 0 265 0 0 1105 0 1105
                -184 0 -184 0 -17 -52z'/>
        </g>
    </svg>;

export default calendarFirstDay;
