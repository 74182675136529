// React
import React from 'react';
import PropTypes from 'prop-types';
// Chart
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
// Material UI Components
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import IconRefresh from '@mui/icons-material/Cached';
// Style
import { useStyles } from 'components/filter/mail/MailPieChart/MailPieChart.style';
import { useTheme } from '@mui/styles';
// Utils
import { createData, options } from 'components/filter/mail/MailPieChart/MailPieChart.util';

const MailPieChart = ({
    resetSites,
    selectSite,
    sendersColors,
    statsBySites,
    totalMails
}) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <>
            {!statsBySites || statsBySites.length === 0 ?
                <Paper className={classes.paper}>
                </Paper>
                :
                <Paper className={classes.paper}>
                    <div className={classes.graphContainer}>
                        <Pie
                            plugins={[ChartDataLabels]}
                            data={createData(statsBySites, sendersColors, theme)}
                            options={options}
                            getElementAtEvent={element => {
                                if (element.length !== 0)
                                    selectSite('SITE', statsBySites[element[0].index].id);
                            }}
                        />
                    </div>
                    <IconButton className={classes.iconReset} color='primary' onClick={resetSites}>
                        <IconRefresh />
                    </IconButton>
                    <div className={classes.totalMails}>
                            Total : {totalMails}
                    </div>
                </Paper>
            }
        </>
    );
};

MailPieChart.propTypes = {
    resetSites: PropTypes.func,
    selectSite: PropTypes.func,
    sendersColors: PropTypes.array,
    statsBySites: PropTypes.array,
    totalMails: PropTypes.number,
};

export default MailPieChart;
