// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
// Material UI Components
import Paper from '@mui/material/Paper';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import SortIcon from '@mui/icons-material/Sort';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import IconRefresh from '@mui/icons-material/Cached';
// PCP Components
import CategoryBar from 'components/filter/price/CategoryBar/CategoryBar';
import DialogManageCategories from 'components/dialog/DialogManageCategories/DialogManageCategories';
import DialogInfo from 'components/dialog/DialogInfo/DialogInfo';
// Util
import { calcColorAndSize, getHigherCardinality, sortCategories } from 'components/filter/price/CategoryFilter/CategoryFilter.util';
// Styles
import { useStyles } from 'components/filter/price/CategoryFilter/CategoryFilter.style';
// Store
import CategoryStore from 'stores/CategoryStore';
import AccountStore from 'stores/AccountStore';
// Action
import CategoryAction from 'actions/CategoryAction';
// Theme
import { useTheme } from '@mui/styles';
// Locales
import translate from 'locales/i18n';
// Images
import CatagoryFilterExample from 'assets/img/category-filter-example.png';

const CategoryFilter = ({ categoriesStats, categoriesVisible, isLoading, reset, selectCategory }) => {
    const [dialogInfoOpen, setDialogInfoOpen] = useState(false);
    const classes = useStyles();
    const [categories, setCategories] = useState([]);
    const [dialogManageCategoriesOpen, setDialogManageCategoriesOpen] = useState(false);
    const [state, setState] = useState({
        alpha: true,
        percent: true,
        sort: true,
        value: 1
    });
    const theme = useTheme();

    useEffect(() => {
        CategoryStore.addChangeListener(onCategoryStoreChange);
        return () => CategoryStore.removeChangeListener(onCategoryStoreChange);
    }, []);

    const onCategoryStoreChange = () => setCategories(CategoryStore.getCategories());

    const { alpha, percent, sort, value } = state;

    const higherCardinality = getHigherCardinality(categoriesStats);

    const handleChange = (v) => {
        if (v === 0)
            setState({
                alpha: !alpha,
                percent,
                sort,
                value: v
            });

        if (v === 1)
            setState({
                alpha,
                percent,
                sort: !sort,
                value: v
            });

        if (v === 2)
            setState({
                alpha,
                percent: !percent,
                sort,
                value: v
            });
    };

    const renderDialogInfo = () => <DialogInfo open={dialogInfoOpen}
        close={() => setDialogInfoOpen(false)}>
        <div className={classes.infoMainText}>{translate.i18n('CATEGORY_FILTER_EXPLANATION_1')}</div>
        <ul>
            <li className={classes.infoSubText}>{translate.i18n('CATEGORY_FILTER_EXPLANATION_2')}</li>
            <li className={classes.infoSubText}>{translate.i18n('CATEGORY_FILTER_EXPLANATION_3')}</li>
        </ul>
        <div className={classes.infoImgContainer}><img src={CatagoryFilterExample} /></div>
        <div className={classes.infoLegendText}>{translate.i18n('CATEGORY_FILTER_EXPLANATION_4')}</div>
    </DialogInfo>;

    return (
        <Paper className={classNames(classes.paper, isLoading && classes.notAllowed)}>
            <div className={classes.title}>
                <div>
                    <IconButton onClick={() => handleChange(0)} color={state.value === 0 ? 'primary':'grey'}>
                        <SortByAlphaIcon />
                    </IconButton>
                    <IconButton onClick={() => handleChange(1)} color={state.value === 1 ? 'primary':'grey'}>
                        <SortIcon />
                    </IconButton>
                    <IconButton onClick={() => handleChange(2)} color={state.value === 2 ? 'primary':'grey'}>
                        <mat-icon inline='true' matSuffix>%</mat-icon>
                    </IconButton>
                </div>
                {translate.i18n('CATEGORIES')}
                <div>
                    <IconButton
                        color='primary'
                        onClick={() => {
                            CategoryAction.getCategories(AccountStore.getCurrentAccount().getId());
                            setDialogManageCategoriesOpen(true);
                        }}
                        size='small'>
                        <SettingsIcon />
                    </IconButton>
                    <IconButton
                        color='primary'
                        onClick={reset}>
                        <IconRefresh />
                    </IconButton>
                    <IconButton
                        color='primary'
                        onClick={() => setDialogInfoOpen(true)}>
                        <InfoIcon />
                    </IconButton>
                </div>
            </div>
            <div className={classes.categoryListContainer}>
                <SimpleBar className={classes.simpleBar}>
                    {sortCategories(calcColorAndSize(categoriesStats.slice(0), theme), alpha, sort, percent, value).map((category, key) =>
                        <CategoryBar category={category}
                            higherCardinality={higherCardinality}
                            categoriesVisible={categoriesVisible}
                            isLoading={isLoading}
                            selectCategory={selectCategory}
                            key={key} />
                    )}
                </SimpleBar>
            </div>
            <DialogManageCategories
                close={() => setDialogManageCategoriesOpen(false)}
                open={dialogManageCategoriesOpen}
                categories={categories} />
            {renderDialogInfo()}
        </Paper>
    );
};

CategoryFilter.propTypes = {
    categoriesStats: PropTypes.array,
    categoriesVisible: PropTypes.array,
    isLoading: PropTypes.bool,
    reset: PropTypes.func,
    selectCategory: PropTypes.func
};

export default CategoryFilter;
