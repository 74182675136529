// React
import React from 'react';
import PropTypes from 'prop-types';
// Facade
import Facade from 'core/Facade';
//PCP components
import NotationBar from 'components/bar/NotationBar/NotationBar';
// Locales
import translate from 'locales/i18n';
import Paper from '@mui/material/Paper';
import PriceIcon from '@mui/icons-material/MonetizationOn';
import AvailabilityIcon from '@mui/icons-material/LocalShipping';
import StockIcon from '@mui/icons-material/Layers';
import ArrowIcon from '@mui/icons-material/ArrowForward';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import BellIcon from '@mui/icons-material/Notifications';
// Stores
import ItemStore from 'stores/ItemStore';
import AccountStore from 'stores/AccountStore';
import { useStyles } from 'components/popper/PricePopper/PricePopper.style';
//Utils
import { displayRating } from 'components/sheet/NotationSheet/NotationSheet.util';

const PricePopper = ({ isHovering, anchor, from, to }) => {
    const classes = useStyles(from, to);

    const getStock = stock => {
        switch (stock) {
        case 'en stock':
            return translate.i18n('STOCK_IN');
        case 'rupture':
            return translate.i18n('STOCK_SOLD_OUT');
        case 'sup 48h':
        case 'sup. 48h':
        case 'supp 48h':
        case 'supp. 48h':
            return translate.i18n('STOCK_SUP_48H');
        default:
            return translate.i18n('STOCK_NO_INFORMATION');
        }
    };

    const renderFrom = () => {
        const price = from.price < 0 ? translate.i18n('NO_PRICE_FOUND') : from.price + ' ' + AccountStore.getCurrentAccount().getCurrency();
        const stockLabel = getStock(from.stock);
        const availabilityLabel = !from.availability || from.availabilty === '' ? translate.i18n('NO_INFORMATION') : from.availability.charAt(0).toUpperCase() + from.availability.slice(1);

        if (from.status === 'UNDEFINED')
            return (
                <div className={classes.halfContainer}>
                    <p className={classes.date}>{Facade.formatDate(ItemStore.getParams().from)}</p>
                    <div className={classes.notSoldInfoContainer}>
                        <p className={classes.notSoldLabel}>
                            N/A
                        </p>
                    </div>
                </div>
            );

        if (from.status === 'NV')
            return (
                <div className={classes.halfContainer}>
                    <p className={classes.date}>{Facade.formatDate(ItemStore.getParams().from)}</p>
                    <div className={classes.notSoldInfoContainer}>
                        <p className={classes.notSoldLabel}>
                            {translate.i18n('NV')}
                        </p>
                    </div>
                </div>
            );

        if (from.status === 'NV_BELL')
            return (
                <div className={classes.halfContainer}>
                    <p className={classes.date}>{Facade.formatDate(ItemStore.getParams().from)}</p>
                    <div className={classes.notSoldInfoContainer}>
                        <p className={classes.notSoldLabel}>
                            {translate.i18n('NOT_SOLD')}
                        </p>
                        <BellIcon className={classes.bellIcon} />
                    </div>
                </div>
            );

        return (
            <div className={classes.halfContainer}>
                <p className={classes.date}>{Facade.formatDate(ItemStore.getParams().from)}</p>
                <div className={classes.infoContainer}>
                    <PriceIcon fontSize={'small'}
                        color={'primary'}
                        classes={{
                            fontSizeSmall: classes.smallIcon
                        }} />
                    <p className={classes.p}>{' ' + price}{(from.additionalData && from.additionalData.oldPrice) && <span className={classes.oldPrice}>{from.additionalData.oldPrice + ' ' + AccountStore.getCurrentAccount().getCurrency()}</span>}</p>
                </div>
                <div className={classes.infoContainer}>
                    <StockIcon fontSize={'small'}
                        color={'primary'}
                        classes={{
                            fontSizeSmall: classes.smallIcon
                        }} />
                    <p className={classes.p}>{stockLabel}</p>
                </div>
                <div className={classes.infoContainer}>
                    <AvailabilityIcon fontSize={'small'}
                        color={'primary'}
                        classes={{
                            fontSizeSmall: classes.smallIcon
                        }} />
                    <p className={classes.p} title={availabilityLabel}>{availabilityLabel.length > 45 ? availabilityLabel.slice(0, 42) + '...' : availabilityLabel}</p>
                </div>
                {from.additionalData?.evaluation &&
                    <div className={classes.notationContainer}>
                        <NotationBar rating={from.additionalData.evaluation.rating && from.additionalData.evaluation.rating !== '' && from.additionalData.evaluation.count !== '' && from.additionalData.evaluation.count !== '0' ? (from.additionalData.evaluation.rating / (from.additionalData.evaluation.max_rating ? from.additionalData.evaluation.max_rating : 5)) * 5 : 0} />
                        <div className={classes.notationText}>
                            {from.additionalData?.evaluation?.count && from.additionalData?.evaluation?.count !== '0' ?
                                <>
                                    <div className={from.additionalData.evaluation.rating === '' || from.additionalData.evaluation.count === '' ? classes.notFound : classes.gradeFrom}> {from.additionalData.evaluation.rating === '' || from.additionalData.evaluation.count === '' ? translate.i18n('NOT_FOUND_EVAL') : displayRating((from.additionalData.evaluation.rating / from.additionalData.evaluation.max_rating) * 5)} </div>
                                    <div className={classes.count}>({from.additionalData?.evaluation?.count === '' ? '-' : from.additionalData.evaluation.count})</div>
                                </>
                                :
                                <>
                                    <div className={classes.notFound}>{translate.i18n('NO_EVALUATION')}</div>
                                    <div className={classes.count}>(0)</div>
                                </>
                            }
                        </div>
                    </div>
                }
                {from.additionalData && from.additionalData.ref ?
                    <div className={classes.infoContainer}>
                        <p className={classes.p}>{translate.i18n('REF') + ' : ' + from.additionalData.ref}</p>
                    </div>
                    :
                    null
                }
            </div>
        );
    };

    const renderTo = () => {
        const price = to.price === -1 ? translate.i18n('NO_PRICE_FOUND') : to.price + ' ' + AccountStore.getCurrentAccount().getCurrency();
        const stockLabel = getStock(to.stock);
        const availabilityLabel = !to.availability || to.availabilty === '' ? translate.i18n('NO_INFORMATION') : to.availability.charAt(0).toUpperCase() + to.availability.slice(1);

        if (to.status === 'UNDEFINED')
            return (
                <div className={classes.halfContainer}>
                    <p className={classes.date}>{Facade.formatDate(ItemStore.getParams().to)}</p>
                    <div className={classes.notSoldInfoContainer}>
                        <p className={classes.notSoldLabel}>
                            N/A
                        </p>
                    </div>
                </div>
            );

        if (to.status === 'NV')
            return (
                <div className={classes.halfContainer}>
                    <p className={classes.date}>{Facade.formatDate(ItemStore.getParams().to)}</p>
                    <div className={classes.notSoldInfoContainer}>
                        <p className={classes.notSoldLabel}>
                            {translate.i18n('NV')}
                        </p>
                    </div>
                </div>
            );

        if (to.status === 'NV_BELL')
            return (
                <div className={classes.halfContainer}>
                    <p className={classes.date}>{Facade.formatDate(ItemStore.getParams().to)}</p>
                    <div className={classes.notSoldInfoContainer}>
                        <p className={classes.notSoldLabel}>
                            {translate.i18n('NOT_SOLD')}
                        </p>
                        <BellIcon className={classes.bellIcon} />
                    </div>
                </div>
            );

        return (
            <div className={classes.halfContainer}>
                <p className={classes.date}>{Facade.formatDate(ItemStore.getParams().to)}</p>
                <div className={classes.infoContainer}>
                    <PriceIcon fontSize={'small'}
                        color={'primary'}
                        classes={{
                            fontSizeSmall: classes.smallIcon
                        }} />
                    <p className={classes.p}>{' ' + price}{(to.additionalData && to.additionalData.oldPrice) && <span className={classes.oldPrice}>{to.additionalData.oldPrice + ' ' + AccountStore.getCurrentAccount().getCurrency()}</span>}</p>
                </div>
                <div className={classes.infoContainer}>
                    <StockIcon fontSize={'small'}
                        color={'primary'}
                        classes={{
                            fontSizeSmall: classes.smallIcon
                        }} />
                    <p className={classes.p}>{stockLabel}</p>
                </div>
                <div className={classes.infoContainer}>
                    <AvailabilityIcon fontSize={'small'}
                        color={'primary'}
                        classes={{
                            fontSizeSmall: classes.smallIcon
                        }} />
                    <p className={classes.p} title={availabilityLabel}>{availabilityLabel.length > 45 ? availabilityLabel.slice(0, 42) + '...' : availabilityLabel}</p>
                </div>
                {to.additionalData?.evaluation &&
                    <div className={classes.notationContainer}>
                        <NotationBar rating={to.additionalData?.evaluation?.rating && to.additionalData?.evaluation?.rating !== '' && to.additionalData?.evaluation?.count !== '' && to.additionalData?.evaluation?.count !== '0' ? (to.additionalData.evaluation.rating / (to.additionalData.evaluation.max_rating ? to.additionalData.evaluation.max_rating : 5)) * 5 : 0} />
                        <div className={classes.notationText}>
                            {to.additionalData?.evaluation?.count && to.additionalData?.evaluation?.count !== '0' ?
                                <>
                                    <div className={to.additionalData.evaluation.rating === '' || to.additionalData.evaluation.count === '' ? classes.notFound : classes.gradeTo}> {to.additionalData.evaluation.rating === '' || to.additionalData.evaluation.count === '' ? translate.i18n('NOT_FOUND_EVAL') : displayRating((to.additionalData.evaluation.rating / to.additionalData.evaluation.max_rating) * 5)} </div>
                                    <div className={classes.count}>({to.additionalData?.evaluation?.count === '' ? '-' : to.additionalData.evaluation.count})</div>
                                </>
                                :
                                <>
                                    <div className={classes.notFound}>{translate.i18n('NO_EVALUATION')}</div>
                                    <div className={classes.count}>(0)</div>
                                </>
                            }
                        </div>
                    </div>
                }
                {to.additionalData && to.additionalData.ref ?
                    <div className={classes.infoContainer}>
                        <p className={classes.p}>{translate.i18n('REF') + ' : ' + to.additionalData.ref}</p>
                    </div>
                    :
                    null
                }
            </div>
        );
    };

    return (
        <Popper open={isHovering}
            anchorEl={anchor}
            transition
            disablePortal={false}
            className={classes.popper}
            placement='bottom'
            modifiers={[
                {
                    name: 'flip',
                    enabled: true,
                    options: {
                        altBoundary: true,
                        rootBoundary: 'document',
                        padding: 8,
                    },
                },
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        altAxis: true,
                        altBoundary: true,
                        tether: true,
                        rootBoundary: 'document',
                        padding: 8,
                    },
                }]}
            style={{ backgroundColor: 'transparent' }}>
            {({ TransitionProps, placement }) =>
                <Grow
                    {...TransitionProps}
                    id='paper-grow'
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper className={classes.paper}>
                        {renderFrom()}
                        <div className={classes.dividerContainer}>
                            <ArrowIcon fontSize={'small'}
                                color={'primary'}
                                classes={{
                                    fontSizeSmall: classes.smallIconArrow
                                }} />
                            <div className={classes.divider}></div>
                        </div>
                        {renderTo()}
                    </Paper>
                </Grow>}
        </Popper>
    );
};

PricePopper.propTypes = {
    anchor: PropTypes.object,
    from: PropTypes.object,
    isHovering: PropTypes.bool,
    to: PropTypes.object
};

export default PricePopper;
