import React, { useState, useEffect } from 'react';
//Action & Stores
import TrendAction from 'actions/TrendAction';
import TrendStore from 'stores/TrendStore';
import AccountStore from 'stores/AccountStore';
//Material components
import {
    IconButton
} from '@mui/material';
import 'dayjs/locale/fr';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
//Locales
import translate from 'locales/i18n';
//Pcp components
import TrendSheet from 'components/sheet/TrendSheet/TrendSheet';
import Loader from 'components/commons/Loader/Loader';
import TrendBarFilter from 'components/filter/TrendFilters/TrendBarFilter/TrendBarFilter';
//Styles
import { useStyles } from 'components/pages/TrendsPage/TrendsPage.style';
import DatePickers from 'components/commons/DatePickers/DatePickers';

const TrendsPage = () => {

    const [trendAnalyze, setTrendAnalyze] = useState(TrendStore.getTrendAnalyze());
    const [isLoading, setIsLoading] = useState(true);

    const d = new Date();
    d.setMonth(d.getMonth() -3);
    const [fromStr, setFromStr] = useState(d);
    const [toStr, setToStr] = useState(new Date());

    const [trendLineType, setTrendLineType] = useState('LINEAR');
    const [sites, setSites] = useState([]);
    const [categories, setCategories] = useState([]);

    const [bigFilter, setBigFilter] = useState(false);
    const [delayHandler, setDelayHandler] = useState(false);

    const [sender, setSender] = useState(false);

    const classes = useStyles(bigFilter)();

    useEffect(() => {
        clearTimeout(delayHandler);
        delayRequest();
    },[trendLineType, sites, categories, sender]);

    useEffect(() => {
        TrendStore.addChangeListener(onTrendStoreChange);
        return () => TrendStore.removeChangeListener(onTrendStoreChange);
    }, []);

    const delayRequest = () => {
        setDelayHandler(setTimeout(() => {
            requestTrends();
        }, 700));
    };

    const requestTrends = () => {
        if (!fromStr || !toStr) return;
        TrendAction.getTrends(AccountStore.getCurrentAccount().getId(), formatDate(fromStr), formatDate(toStr), {
            trend_line_type: trendLineType,
            sites,
            categories
        });
    };

    const onTrendStoreChange = () => {
        setTrendAnalyze(TrendStore.getTrendAnalyze());
        setIsLoading(TrendStore.getIsLoading());
    };

    const filterData = (data, isSite) => {
        if (isSite) {
            let newSites = sites.slice();
            if (newSites.includes(data)) newSites = newSites.filter(s => s !== data);
            else newSites.push(data);
            setSites(newSites);
        } else {
            let newCategories = categories.slice();
            if (newCategories.includes(data)) newCategories = newCategories.filter(s => s !== data);
            else newCategories.push(data);
            setCategories(newCategories);
        }
    };

    const reset = (isSite, resetAll = false) => {
        if (resetAll) {
            setSites([]);
            setCategories([]);
            setFromStr(d);
            setToStr(new Date());
        } else {
            isSite? setSites([]):setCategories([]);
        }
    };

    const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    return <div style={{ height: '100%' }}>
        <div className={classes.titleContainer}>
            <DatePickers
                fromStr={fromStr}
                setFromStr={setFromStr}
                toStr={toStr}
                setToStr={setToStr}
                titleText={['TRENDS.RETAILER_TITLE','TO']}
                reset={() => reset(false, true)}
                isLoading={isLoading}
                callBack={() => setSender(!sender)}
            />
        </div>
        {isLoading?
            <div className={classes.loaderContainer}>
                {translate.i18n('TRENDS.LOADING')}
                <Loader size={50} />
            </div>
            :
            <TrendSheet
                trendAnalyze={trendAnalyze}
                trendLineType={trendLineType}
                setTrendLineType={setTrendLineType}
                bigFilter={bigFilter}
            />
        }
        <div className={classes.filtersTitle}>
            {translate.i18n('TRENDS.FILTERS_TITLE')}
            <IconButton onClick={() => setBigFilter(!bigFilter)} size='small'>
                {bigFilter?
                    <KeyboardArrowDownIcon />
                    :
                    <KeyboardArrowUpIcon />
                }
            </IconButton>
        </div>
        <div className={classes.filtersContainer}>
            {trendAnalyze.sitesEvolution && <>
                <div className={classes.filter}>
                    <TrendBarFilter
                        dataToDisplay={trendAnalyze.sitesEvolution}
                        isSites={true}
                        filterData={filterData}
                        reset={reset}
                    />
                </div>
                <div className={classes.filter}>
                    <TrendBarFilter
                        dataToDisplay={trendAnalyze.categoriesEvolution}
                        isSites={false}
                        filterData={filterData}
                        reset={reset}
                    />
                </div>
            </>
            }
        </div>
    </div>;
};

export default TrendsPage;
