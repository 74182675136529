import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    dialogContent: {
        paddingBottom: 0
    },
    textField: {
        margin: theme.spacing(1),
    },
}));
