// React
import React from 'react';
import PropTypes from 'prop-types';
// Material UI
import withStyles from '@mui/styles/withStyles';
import { Paper } from '@mui/material';
// Locales
import translate from 'locales/i18n';
// Styles
import styles from 'components/sheet/RuleSheet/RuleSheet.style';
// Util
import { getResume } from 'components/sheet/RuleSheet/RuleSheet.util';
// PCP Components
import ItemsListTable from 'components/table/ItemsListTable/ItemsListTable';
// Stores
import AccountStore from 'stores/AccountStore';

const RuleSheet = ({ rule, classes }) => <div className={classes.root}>
    <div className={classes.ruleName}>{rule.name}</div>
    <div className={classes.dates}>{translate.i18n('FROM')}: <em className={classes.date}>{(rule.applyFrom ? rule.applyFrom : translate.i18n('NO_BEGINNING_DATE'))}</em> - {translate.i18n('TO')}: <em className={classes.date}>{(rule.applyUntil ? rule.applyUntil : translate.i18n('NO_LIMIT_DATE'))}</em></div>
    <div className={classes.detailsContainer}>
        <Paper className={classes.midPaper} elevation={3}>
            <div className={classes.paperTitle}>{translate.i18n('MINIMUM_SALE_PRICE')}</div>
            <div className={classes.priceMode}><span className={classes.coloredPriceMode}>{translate.i18n('BASED_ON') + ' ' + translate.i18n(rule.minPrice.element)}</span> {translate.i18n('IN')} <span className={classes.coloredPriceMode}>{translate.i18n(rule.minPrice.mode)}</span></div>
            <div className={classes.price}>{rule.minPrice.value < 0 ? rule.minPrice.value : '+' + rule.minPrice.value}{rule.minPrice.mode === 'VALUE' ? AccountStore.getCurrentAccount().getCurrency() : '%'}</div>
        </Paper>
        <Paper className={classes.midPaper} elevation={3}>
            <div className={classes.paperTitle}>{translate.i18n('MAXIMUM_SALE_PRICE')}</div>
            <div className={classes.priceMode}><span className={classes.coloredPriceMode}>{translate.i18n('BASED_ON') + ' ' + translate.i18n(rule.maxPrice.element)}</span> {translate.i18n('IN')} <span className={classes.coloredPriceMode}>{translate.i18n(rule.maxPrice.mode)}</span></div>
            <div className={classes.price}>{rule.maxPrice.value < 0 ? rule.maxPrice.value : '+' + rule.maxPrice.value}{rule.maxPrice.mode === 'VALUE' ? AccountStore.getCurrentAccount().getCurrency() : '%'}</div>
        </Paper>
        <Paper className={classes.midPaper} elevation={3} style={{ padding: '15px 15px 5px 15px' }}>
            <div className={classes.paperTitle}>{translate.i18n('STRATEGY')}</div>
            <div className={classes.strategyResume}>{getResume(rule.strategy)}</div>
            <div className={classes.strategyInfos}>
                {rule.strategy && rule.strategy.filteringStocks ?
                    <div className={classes.column}>
                        <div className={classes.subTitle}>{translate.i18n('SELECTED_STOCKS')}</div>
                        {rule.strategy.filteringStocks.map(s => <div key={s}>{s}</div>)}
                    </div>
                    :
                    null
                }
                {rule.strategy && rule.strategy.filteringSiteIds ?
                    <div className={classes.column}>
                        <div className={classes.subTitle}>{translate.i18n('SELECTED_SITES')}</div>
                        {rule.strategy.filteringSiteIds.map(id => <div key={id}>{AccountStore.getCurrentAccount().getPriceMonitoringInformations().sites.find(s => s.id === id).name}</div>)}
                    </div>
                    :
                    null
                }
            </div>
        </Paper>
        <Paper className={classes.midPaper} elevation={3} style={{ padding: '0px' }}>
            <ItemsListTable
                items={rule.associatedArticles} />
        </Paper>
    </div>
</div>;

RuleSheet.propTypes = {
    classes: PropTypes.object,
    rule: PropTypes.object
};

export default withStyles(styles)(RuleSheet);
