import { ExportToCsv } from 'utils/Csv.util';
import xlsx from 'json-as-xlsx';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Service
import ItemService from 'services/ItemService';
// Locales
import translate from 'locales/i18n';
//Stores
import AccountStore from 'stores/AccountStore';
import ItemStore from 'stores/ItemStore';
// Action
import AlertAction from 'actions/AlertAction';
// Logger
import Logger from 'core/Logger';
// Entities
import Item from 'core/entities/ItemObject';
import Site from 'core/entities/SiteObject';
import ScrapedData from 'core/entities/ScrapedDataObject';
import ScrapingData from 'core/entities/ScrapingDataObject';
import Category from 'core/entities/CategoryObject';
import PricesBasket from 'core/entities/PricesBasketObject';
import PositioningKpis from 'core/entities/PositioningKpisObject';
import Rule from 'core/entities/RuleObject';

class ItemAction {

    /**
      * Method to get analyse of smart price extractor module
      *
      * @param {  accountId, from, to, pageIndex, pageSize, sort, mode } options the options to get analyse
      */
    search(options) {
        if (!ItemStore.firstLoad) {
            Dispatcher.dispatch({
                actionType: Constants.ITEM_SEARCH_START,
                value: options
            });
        }

        ItemService.getAnalyse(options).then(({ categories, count, from, items, prices_baskets, sites, evaluation }) => {
            Dispatcher.dispatch({
                actionType: Constants.ITEM_SEARCH_END,
                value: {
                    categories: categories.map(category => new Category(category)),
                    count,
                    from,
                    items: items.map(item => new Item(item)),
                    pricesBaskets: prices_baskets.map(pricesBasket => new PricesBasket(pricesBasket)),
                    sites: sites.map(site => new Site(site)),
                    evaluation
                }
            });
        }, response => {
            Logger.error(response);
            if (response && response.error && translate.i18n(response.error)) AlertAction.open('error', translate.i18n(response.error), 'top-right');
            else AlertAction.open('error', translate.i18n('ERROR_RETRIEVE_ARTICLES'), 'top-right');
        });
    }

    /**
      * Method to update a scraping data
      *
      * @param {  accountId, itemId, siteId, status, params } options the options to update the scraping data
      */
    updateScrapingData(options) {
        Dispatcher.dispatch({
            actionType: Constants.ITEM_START_UPDATE_SCRAPING_DATA,
            value: {
                itemId: options.itemId,
                siteId: options.siteId
            }
        });

        ItemService.updateScrapingData(options).then(data => {
            let scrapingData = null;
            if (data.scraping_data) scrapingData = new ScrapingData(data.scraping_data);
            else scrapingData = new ScrapingData(data);
            ItemService.getScrapedData(Object.assign({ date: new Date() }, options)).then(scrapedData => {
                scrapingData.to = new ScrapedData(scrapedData);
                Dispatcher.dispatch({
                    actionType: Constants.ITEM_UPDATE_SCRAPING_DATA,
                    value: {
                        itemId: options.itemId,
                        newScrapingData: scrapingData,
                        siteId: options.siteId
                    }
                });
            });
            AlertAction.open('success', translate.i18n('SUCCESS_UPDATE_ARTICLE'), 'top-right');
        }, response => {
            Dispatcher.dispatch({
                actionType: Constants.ITEM_UPDATE_DATA_FAILED,
                value: {
                    itemId: options.itemId,
                    siteId: options.siteId
                }
            });
            Logger.error(response);
            if (response && response.error) AlertAction.open('error', translate.i18n(response.error), 'top-right');
            else AlertAction.open('error', translate.i18n('ERROR_UPDATE_ARTICLE'), 'top-right');
        });
    }

    /**
      * Method to update a scraped data
      *
      * @param {  accountId, itemId, siteId, newScrapedData } options the options to update scraped data
      */
    updateScrapedData(options) {
        Dispatcher.dispatch({
            actionType: Constants.ITEM_START_UPDATE_SCRAPED_DATA,
            value: {
                itemId: options.itemId,
                siteId: options.siteId
            }
        });

        ItemService.updateHistory(options).then(() => {
            Dispatcher.dispatch({
                actionType: Constants.ITEM_UPDATE_SCRAPED_DATA,
                value: {
                    itemId: options.itemId,
                    newScrapedData: new ScrapedData(options.newScrapedData),
                    siteId: options.siteId
                }
            });
            AlertAction.open('success', translate.i18n('SUCCESS_UPDATE_ARTICLE'), 'top-right');
        }, response => {
            Dispatcher.dispatch({
                actionType: Constants.ITEM_UPDATE_DATA_FAILED,
                value: {
                    itemId: options.itemId,
                    siteId: options.siteId
                }
            });
            Logger.error(response);
            if (response && response.error) AlertAction.open('error', translate.i18n(response.error), 'top-right');
            else AlertAction.open('error', translate.i18n('ERROR_UPDATE_ARTICLE'), 'top-right');
        });
    }

    /**
      * Method to update a specific historic value at a specific date
      *
      * @param {  accountId, itemId, siteId, date, newScrapedData } options the options to update scraped data on a specific date
      */
    updateHistory(options) {
        ItemService.updateHistory(options).then(() => {
            const params = {
                accountId: options.accountId,
                from: options.from,
                itemId: options.itemId,
                to: options.to
            };

            this.getHistory(params);
            AlertAction.open('success', translate.i18n('SUCCESS_UPDATE_ARTICLE'), 'top-right');
        }, response => {
            Logger.error(response);
            if (response && response.error) AlertAction.open('error', translate.i18n(response.error), 'top-right');
            else AlertAction.open('error', translate.i18n('ERROR_UPDATE_ARTICLE'), 'top-right');
        });
    }

    /**
      * Method delete a specific scraping data
      *
      * @param { itemId, siteId, scrapingData } options the options to delete scraping data
      */
    deleteScrapingData(options) {
        Dispatcher.dispatch({
            actionType: Constants.ITEM_START_UPDATE_SCRAPING_DATA,
            value: {
                itemId: options.itemId,
                siteId: options.siteId
            }
        });

        ItemService.deleteScrapingData(options).then(() => {
            Dispatcher.dispatch({
                actionType: Constants.ITEM_UPDATE_SCRAPING_DATA,
                value: {
                    itemId: options.itemId,
                    newScrapingData: options.scrapingData,
                    siteId: options.siteId
                }
            });
            AlertAction.open('success', translate.i18n('SUCCESS_UPDATE_ARTICLE'), 'top-right');
        }, response => {
            if (response && response.error) AlertAction.open('error', translate.i18n(response.error), 'top-right');
            else AlertAction.open('error', translate.i18n(response && response.error ? response.error : 'ERROR_UPDATE_ARTICLE'), 'top-right');
        });
    }

    /**
      * Method called to add a new item
      *
      * @param item the new item with all of his values
      */
    addItem(item) {
        ItemService.addItem(item, AccountStore.getCurrentAccount().getId()).then(data => {
            const params = ItemStore.getInitialParams();
            params.searchText = data.name;
            this.search(params);
            AlertAction.open('success', translate.i18n('SUCCESS_ADD_ARTICLE'), 'top-right');
        }, response => {
            Logger.error(response);
            if (response && response.error) AlertAction.open('error', response.error, 'top-right');
            else AlertAction.open('error', translate.i18n('ERROR_ADD_ARTICLE'), 'top-right');
        });
    }

    /**
      * Method called to update a specific item
      *
      * @param item the new item with all of his new values
      */
    updateItem(item) {
        Dispatcher.dispatch({
            actionType: Constants.PRODUCT_GET_START
        });

        ItemService.updateItem(item, AccountStore.getCurrentAccount().getId()).then(data => {
            Dispatcher.dispatch({
                actionType: Constants.PRODUCT_GET_END,
                value: new Item(data)
            });
            AlertAction.open('success', translate.i18n('SUCCESS_UPDATE_ARTICLE'), 'top-right');
        }, response => {
            Logger.error(response);
            Dispatcher.dispatch({
                actionType: Constants.PRODUCT_GET_END_ERROR,
            });
            if (response && response.error) AlertAction.open('error', translate.i18n(response.error), 'top-right');
            else AlertAction.open('error', translate.i18n('ERROR_UPDATE_ARTICLE'), 'top-right');
        });
    }

    /**
      * Method called to update a specific item
      *
      * @param itemId the id of the item to update
      * @param accountId the id of the account to update
      * @param categoryId the id of the new category
      */
    updateProductCategory(productId, categoryId) {
        return ItemService.updateProductCategory(productId, categoryId, AccountStore.getCurrentAccount().getId()).then(item => {
            AlertAction.open('success', translate.i18n('SUCCESS_UPDATE_ARTICLE'), 'top-right');
            return new Item(item);
        }, response => {
            if (response && response.error) AlertAction.open('error', translate.i18n(response.error), 'top-right');
        });
    }

    /**
      * Method called to get a specific product
      *
      * @param { itemId, accountId, from, to } options the options to retrieve product
      */
    getProduct(options) {
        if (!ItemStore.itemFirstLoad) {
            Dispatcher.dispatch({
                actionType: Constants.PRODUCT_GET_START
            });
        }

        return ItemService.getProduct(options).then(data => {
            Dispatcher.dispatch({
                actionType: Constants.PRODUCT_GET_END,
                value: new Item(data)
            });
        }, response => {
            Logger.error(response);
            Dispatcher.dispatch({
                actionType: Constants.PRODUCT_GET_END_ERROR
            });
            if (response && response.error) AlertAction.open('error', translate.i18n(response.error), 'top-right');
            else AlertAction.open('error', translate.i18n('ERROR_RETRIEVE_ARTICLE'), 'top-right');
            throw response;
        });
    }

    /**
      * Method called to delete a specific product
      *
      * @param { itemId, accountId } options <object> the options to delete product
      * @param search <boolean> to know if we have to launch a new search after delete product
      * @returns <Promise> of delete product request
      */
    deleteProduct(options, search) {
        return ItemService.deleteProduct(options).then(data => {
            if (search) {
                const params = ItemStore.getParams();
                this.search(params);
            } else {
                Dispatcher.dispatch({
                    actionType: Constants.PRODUCT_DELETED,
                    value: options.itemId
                });
            }

            AlertAction.open('success', translate.i18n('SUCCESS_DELETE_ARTICLE'), 'top-right');
            return data;
        }, (response) => {
            if (response && response.error) AlertAction.open('error', translate.i18n(response.error), 'top-right');
            else AlertAction.open('error', translate.i18n('ERROR_DELETE_ARTICLE'), 'top-right');
        });
    }

    /**
      * Method called to reset params of item search on smart price extractor
      *
      * @param { mode, pageSize } options the options to reset params because not every params have to be reset
      */
    reset(options) {
        const yesterdayDate = new Date();
        yesterdayDate.setDate(yesterdayDate.getDate() - 1);

        const params = {
            accountId: AccountStore.getCurrentAccount().getId(),
            categories: [],
            from: yesterdayDate,
            leadership: [],
            mode: options.mode ? options.mode : 10,
            pageIndex: 1,
            pageSize: options.pageSize ? options.pageSize : 10,
            pricesBaskets: [],
            searchText: '',
            sort: 'alpha_asc',
            stocksBaskets: [],
            to: new Date(),
            websites: []
        };

        Dispatcher.dispatch({
            actionType: Constants.ITEM_SEARCH_START,
            value: params
        });

        ItemService.getAnalyse(params).then(({ categories, count, from, items, prices_baskets, sites }) => {
            Dispatcher.dispatch({
                actionType: Constants.ITEM_SEARCH_END,
                value: {
                    categories: categories.map(category => new Category(category)),
                    count,
                    from,
                    items: items.map(item => new Item(item)),
                    pricesBaskets: prices_baskets.map(pricesBasket => new PricesBasket(pricesBasket)),
                    sites: sites.map(site => new Site(site))
                }
            });
        }, response => {
            Logger.error(response);
            if (response && response.error) AlertAction.open('error', translate.i18n(response.error), 'top-right');
            else AlertAction.open('error', translate.i18n('ERROR_RETRIEVE_ARTICLES'), 'top-right');
        });
    }

    /**
      * Method called to get history of an item
      *
      * @param { itemId, accountId, from, to } options the options to retrieve history
      */
    getHistory(options) {
        if (!ItemStore.itemFirstLoad) {
            Dispatcher.dispatch({
                actionType: Constants.FETCH_ITEM_HISTORY_START,
                value: options
            });
        }

        ItemService.getHistory(options).then(history => {
            Dispatcher.dispatch({
                actionType: Constants.FETCH_ITEM_HISTORY_END,
                value: history.map(site => new Site(site))
            });
        }, response => {
            if (response && response.error) AlertAction.open('error', translate.i18n(response.error), 'top-right');
            else AlertAction.open('error', translate.i18n('ERROR_RETRIEVE_HISTORY'), 'top-right');
        });
    }

    /**
      * Method called to get all repriced items with certains options
      *
      * @param { accountId, pageSize, page, date } options the options to retrieve history
      */
    getRepricedItems(options) {
        Dispatcher.dispatch({
            actionType: Constants.REPRICED_ITEMS_GET_START,
            value: options
        });

        ItemService.getRepricedItems(options).then(({ count, items, sites, positioning_kpis, repriced_prices_groups, categories, applied_rules_distribution, basket_prices }) => {
            Dispatcher.dispatch({
                actionType: Constants.REPRICED_ITEMS_GET_END,
                value: {
                    count,
                    items: items.map(item => new Item(item)),
                    sites: sites.map(site => new Site(site)),
                    kpis: new PositioningKpis(positioning_kpis),
                    repricedPricesGroups: repriced_prices_groups,
                    categories: categories.map(category => new Category(category)),
                    rulesDistribution: applied_rules_distribution.map(rule => new Rule(rule)),
                    pricesBaskets: basket_prices.map(basket => new PricesBasket(basket))
                }
            });
        }, response => {
            Logger.error(response);
            if (response && response.error) AlertAction.open('error', response.error, 'top-right');
            else AlertAction.open('error', translate.i18n('ERROR_RETRIEVE_REPRICED_ITEMS'), 'top-right');
        });
    }

    /**
      * Method called to export as file the current smart price extractor data
      *
      * @param exportOptions the export options object
      * @param searchOptions the search options object
      */
    exportItems(exportOptions, searchOptions) {
        const dataExported = [];
        Dispatcher.dispatch({
            actionType: Constants.ITEM_EXPORT_START
        });

        searchOptions.pageSize = -1;

        ItemService.getItemsExport(searchOptions).then(data => {
            const columns = [];
            data.data.forEach((item) => {
                const newData = {};
                data.header.forEach(h => {
                    newData[h] = item[h];
                    columns.push({ label: h, value: h });
                });
                dataExported.push(newData);
                return true;
            });

            if (exportOptions.format === 'csv') {
                const csvExporter = new ExportToCsv(exportOptions);
                csvExporter.generateCsv(dataExported);
            } else if (exportOptions.format === 'xlsx') {
                xlsx([{ columns, content: dataExported }], { fileName: exportOptions.filename });
            }

            AlertAction.open('success', translate.i18n('FILE_EXPORTED'), 'top-right');

            Dispatcher.dispatch({
                actionType: Constants.ITEM_EXPORT_END,
                value: false
            });

        }, (response) => {
            Dispatcher.dispatch({
                actionType: Constants.ITEM_EXPORT_END,
                value: true
            });
            if (response && response.error) AlertAction.open('error', response.error, 'top-right');
        });
    }

    /**
      * Method called to export as file the current pricing engine data
      *
      * @param exportOptions the export options object
      * @param searchOptions the search options object
      */
    exportRepricedItems(exportOptions, searchOptions) {
        const dataExported = [];

        Dispatcher.dispatch({
            actionType: Constants.PRICING_ENGINE_ITEM_EXPORT_START
        });

        return ItemService.getRepricedItemsExport(searchOptions).then(({ data, header }) => {
            const columns = [];
            data.forEach((item) => {
                const newData = {};
                header.forEach(h => {
                    newData[h] = item[h];
                    columns.push({ label: h, value: h });
                });
                dataExported.push(newData);
            });

            if (exportOptions.format === 'csv') {
                const csvExporter = new ExportToCsv(exportOptions);
                csvExporter.generateCsv(dataExported);
            } else if (exportOptions.format === 'xlsx') {
                xlsx([{ columns, content: dataExported }], { fileName: exportOptions.filename });
            }
        }, response => {
            if (response && response.error) AlertAction.open('error', response.error, 'top-right');
        });
    }
}

export default new ItemAction();
