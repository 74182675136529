// React
import React, { useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
// Material UI Components
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
// PCP Components
import TimelineDate from 'components/filter/slider/TimelineDate/TimelineDate';
//Components
import Carousel from 'react-multi-carousel';
// Locales
import translate from 'locales/i18n';
// Util
import { formatDate } from 'components/filter/slider/SliderTimelineFilter/SliderTimelineFilter.util';
import { useStyles } from 'components/filter/slider/SliderTimelineFilter/SliderTimelineFilter.style';

const SliderTimelineFilter = ({ currentDay, evolution, selectDayDisplay, pageChanges }) => {
    const classes = useStyles();

    const [carouselRef, setCarouselRef] = useState(null);

    const [openDate, setOpenDate] = useState(true);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 2500, min: 1900 },
            items: 31
        },
        desktop: {
            breakpoint: { max: 1900, min: 1024 },
            items: 20,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 10,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 5,
        }
    };

    useEffect(() => {
        carouselRef ? carouselRef.goToSlide(evolution.length - getStartingSlide(carouselRef)) : null;
    }, [evolution, carouselRef]);

    useLayoutEffect(() => {
        setOpenDate(false);
        setTimeout(() => setOpenDate(true), 1000);
    }, [window.innerWidth, window.innerHeight]);

    // FONCTIONS
    const getStartingSlide = (car) => car.state.deviceType === 'superLargeDesktop' ?
        31 : car.state.deviceType === 'desktop' ?
            20 : car.state.deviceType === 'tablet' ?
                10 : 5;

    const evolutionScale = () => {
        const scale = { min: null, max: null };
        for (const day of evolution) {
            !scale.min || scale.min > day.count ? scale.min = day.count : null;
            !scale.max || scale.max < day.count ? scale.max = day.count : null;
        }

        return scale;
    };

    if (!evolution || evolution.length === 0) return null;

    return <div className={classes.timelineFilterContent}>
        <div className={classes.datesLegendSpace}>
            <p className={classes.datesLegendText}>
                {translate.i18n('FROM').toUpperCase()}
                <br></br>
                {formatDate(evolution[0].date, false)}
            </p>
        </div>
        <div className={classes.timelineFilter}>
            <div className={classes.timelineFilterBar} />
            <Carousel
                ref={(el) => setCarouselRef(el)}
                swipeable={false}
                draggable={false}
                keyBoardControl={false}
                centerMode={false}
                responsive={responsive}
                transitionDuration={300}
                itemClass={classes.itemClass}
                sliderClass={classes.sliderClass}
                containerClass={classes.containerClass}
                autoPlay={false}
                customLeftArrow={<KeyboardArrowLeft className={classes.arrow} style={{ left: '-110px' }} />}
                customRightArrow={<KeyboardArrowRight className={classes.arrow} style={{ right: '-110px' }} />}
                beforeChange={() => setOpenDate(false)}
                afterChange={() => setTimeout(() => setOpenDate(true), 300)}
                slidesToSlide={5}
                shouldResetAutoplay={false}>
                {evolution.map((day, index) => <TimelineDate key={index}
                    day={day}
                    currentDay={currentDay}
                    selectDayDisplay={(date) => selectDayDisplay(date)}
                    scale={evolutionScale()}
                    openDate={openDate && !pageChanges} />)}
            </Carousel>
        </div>
        <div className={classes.datesLegendSpace}>
            <p className={classes.datesLegendText}>
                {translate.i18n('TO').toUpperCase()}
                <br></br>
                {formatDate(evolution[evolution.length - 1].date, false)}
            </p>
        </div>
    </div>;
};

SliderTimelineFilter.propTypes = {
    currentDay: PropTypes.object,
    evolution: PropTypes.array.isRequired,
    selectDayDisplay: PropTypes.func,
    pageChanges: PropTypes.bool
};

export default SliderTimelineFilter;
