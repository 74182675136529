// React
import React from 'react';
import PropTypes from 'prop-types';
//Styles
import { useStyles } from './NotationBar.styles';
//SVG
import ratingStarIcon from 'assets/img/svg/ratingStarIcon';

const NotationBar = ({ rating }) => {

    const classes = useStyles(rating);

    const stars = [];

    for (let i = 0; i < 5; i++) {
        if ((rating - i) < 0.3) {
            stars.push(<div className={classes.noRatingStar} key={i}></div>);
        } else if ((rating - i) < 0.7) {
            stars.push(<div className={classes.halfRatingStar} key={i}> { ratingStarIcon('20', '20') }</div>);
        } else {
            stars.push(<div className={classes.ratingStar} key={i}> { ratingStarIcon('20', '20') }</div>);
        }
    }

    return (
        <div className={classes.starsContainer}>
            {stars}
        </div>
    );

};

NotationBar.propTypes = {
    rating: PropTypes.number
};

export default NotationBar;
