// React
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns/esm';
// Style
import { useStyles } from 'components/card/EmailCard/EmailCard.style';
// Material UI Components
import MailIcon from '@mui/icons-material/Mail';
// Util
import { pickTextColorBasedOnBgColor } from 'components/card/EmailCard/EmailCard.util';
//Theme
import { useTheme } from '@mui/styles';

const EmailCard = ({ backgroundColor, mail, openDialogMail, index }) => {
    const classes = useStyles();
    const theme = useTheme();
    const mailDate = format(new Date(mail.date), 'dd/MM/yyyy');
    const mailHeure = format(new Date(mail.date), 'hh:mm');

    return (
        <div className={classes.mailBox} style={{ backgroundColor }}>
            <div className={classes.notifContainer} style={{
                backgroundColor,
                color: pickTextColorBasedOnBgColor(backgroundColor, theme.palette.primary.light, theme.palette.primary.dark)
            }} onClick={() => openDialogMail(index)}>
                <p className={classes.senderTitle}><MailIcon className={classes.iconMail}></MailIcon>{mail.fromName}<span className={classes.mailDate}> - {mailDate} à {mailHeure}</span></p>
                <p className={classes.mailTitle}>{mail.subject}</p>
            </div>
            <div className={classes.mailContent} onClick={() => openDialogMail(index)}>
                <iframe srcDoc={mail.html} className={classes.iframeMail} title={mail.fromName + ' ' + index}></iframe>
            </div>
        </div>);
};

EmailCard.propTypes = {
    backgroundColor: PropTypes.string,
    index: PropTypes.number,
    mail: PropTypes.object,
    openDialogMail: PropTypes.func,
    statsSendersFiltered: PropTypes.array
};

export default EmailCard;
