// React
import React from 'react';
import PropTypes from 'prop-types';
//Style
import { useStyles } from 'components/sheet/NotationSheet/NotationSheet.style';
//Images
import ratingStarIcon from 'assets/img/svg/ratingStarIcon';
import crossedStarIcon from 'assets/img/etoile-barre.png';
//Utils
import { displayRating } from './NotationSheet.util';

const NotationSheet = ({ evaluation, isAvg }) => {

    const classes = useStyles(evaluation);

    const parsedEvaluation = () => {
        if (typeof evaluation.rating === 'string') {
            return (evaluation.rating.replace(',','.') / evaluation.max_rating) * 5;
        }
        return (evaluation.rating / evaluation.max_rating) * 5;
    };
    return (
        <>
            {evaluation ?
                isAvg || (evaluation.count && evaluation.count !== '0') ?
                    <div className={classes.notationContainer}>
                        <div className={`${classes.ratingStar}`}>
                            {ratingStarIcon('30', '30')}
                        </div>
                        <div>
                            <div className={classes.ratingText}>
                                {evaluation.rating ? evaluation.rating === '' || evaluation.count === '' ? '-' : displayRating(parsedEvaluation()): 'NA'}
                            </div>
                            {!isAvg &&
                                <div className={classes.count}>
                                    ({evaluation.count === '' ? '-' : evaluation.count})
                                </div>
                            }
                        </div>
                    </div>
                    :
                    <div className={classes.notationContainer}>
                        <div className={`${classes.ratingStar}`}>
                            {ratingStarIcon('30', '30')}
                        </div>
                        <div>
                            <div className={classes.ratingText}>
                                NA
                            </div>
                            <div className={classes.count}>
                                (0)
                            </div>
                        </div>
                    </div>
                :
                isAvg ?
                    <div className={classes.notationContainer}>
                        <div className={`${classes.ratingStar}`}>
                            {ratingStarIcon('30', '30')}
                        </div>
                        <div>
                            <div className={classes.ratingText}>
                                NA
                            </div>
                        </div>
                    </div>
                    :
                    <div className={classes.notationContainer}>
                        <div>
                            <img src={crossedStarIcon} className={classes.noStarIcon}></img>
                        </div>
                    </div>
            }
        </>
    );
};

NotationSheet.propTypes = {
    evaluation: PropTypes.object,
    isAvg: PropTypes.bool
};

export default NotationSheet;
