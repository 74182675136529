// React
import React from 'react';
import PropTypes from 'prop-types';
// Stores
import UserStore from 'stores/UserStore';
// Actions
import UserAction from 'actions/UserAction';
// Locales
import translate from 'locales/i18n';
import withStyles from '@mui/styles/withStyles';
import LockIcon from '@mui/icons-material/LockOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// PCP Components
import DialogChangePassword from 'components/dialog/DialogChangePassword/DialogChangePassword';
// Countries
import { countryToFlag } from 'locales/countries';
// Styles
import styles from 'components/pages/ProfilePage/ProfilePage.style';

class Profile extends React.Component {

    state = {
        anchorEl: null,
        dialogChangePasswordOpen: false,
        language: UserStore.getCurrentUser().getLanguage(),
        login: UserStore.getCurrentUser().getLogin()
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    componentDidMount() {
        UserStore.addChangeListener(this.onUserStoreChange);
        this.mounted = true;
    }

    componentWillUnmount() {
        UserStore.removeChangeListener(this.onUserStoreChange);
        this.mounted = false;
    }

    onUserStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            login: UserStore.getCurrentUser().getLogin()
        });
    };

    saveProfile = () => {
        const newData = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            login: this.state.login
        };

        UserAction.saveProfile(newData);
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleClickLanguage = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleChangeLanguage = lang => {
        this.setState({ language: lang }, UserAction.changeLanguage(lang));
        this.handleCloseLanguage();
    };

    handleCloseLanguage = () => {
        this.setState({ anchorEl: null });
    };

    closeDialog = () => {
        this.setState({ dialogChangePasswordOpen: false });
    };

    openDialogPassword = () => {
        this.setState({ dialogChangePasswordOpen: true });
    };

    renderDialogChangePassword = () =>
        <DialogChangePassword
            open={this.state.dialogChangePasswordOpen}
            close={this.closeDialog} />;

    renderProfileForm = () => {
        const { classes } = this.props;
        const { anchorEl } = this.state;

        return (
            <Paper className={classes.paper}>
                <div className={classes.header}>
                    <Avatar className={classes.avatar}>
                        <LockIcon />
                    </Avatar>
                    <Typography component='h1' variant='h5'>
                        {translate.i18n('PROFILE')}
                    </Typography>
                </div>
                <div className={classes.informationContainer}>
                    <TextField
                        id='login'
                        label={translate.i18n('LOGIN')}
                        value={this.state.login}
                        onChange={this.handleChange('login')}
                        margin='normal'
                        variant='outlined'
                        className={classes.loginTextField}
                        disabled
                    />
                    <Button onClick={this.handleClickLanguage}
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup='true'
                        className={classes.languageButton} >
                        {countryToFlag(this.state.language)}
                    </Button>
                    <Menu
                        id='simple-menu'
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleCloseLanguage}
                    >
                        <MenuItem value='' className={classes.noneMenuItem}>
                            <em>None</em>
                        </MenuItem>
                        <MenuItem onClick={() => this.handleChangeLanguage('fr')}>{countryToFlag('fr')}</MenuItem>
                        <MenuItem onClick={() => this.handleChangeLanguage('gb')}>{countryToFlag('gb')}</MenuItem>
                    </Menu>
                </div>
                <Button variant='outlined' color='primary' onClick={() => this.saveProfile()} className={classes.saveProfileButton} disabled>
                    {translate.i18n('SAVE_PROFILE')}
                </Button>
                <div className={classes.bottomButtonContainer}>
                    <Button variant='outlined' color='primary' onClick={() => this.openDialogPassword()} className={classes.bottomButton}>
                        {translate.i18n('CHANGE_PASSWORD')}
                    </Button>
                    <Button variant='outlined' color='primary' onClick={() => this.openDialogPassword()} className={classes.bottomButton} disabled>
                        {translate.i18n('SETTINGS')}
                    </Button>
                </div>
                {this.renderDialogChangePassword()}
            </Paper>
        );
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                {this.renderProfileForm()}
            </div>
        );
    }
}

export default withStyles(styles)(Profile);
