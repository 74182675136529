// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Locales
import translate from 'locales/i18n';
// Style
import { useStyles } from 'components/dialog/DialogMarketplaceReports/DialogMarketplaceReports.style';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ExportIcon from '@mui/icons-material/CloudDownload';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// Stores
import AccountStore from 'stores/AccountStore';
// Action
import MarketplaceAction from 'actions/MarketplaceAction';
// Util
import { getMonthName, formatDate } from 'components/dialog/DialogMarketplaceReports/DialogMarketplaceReports.util';

const DialogMarketplaceReports = ({ close, open }) => {
    const [name, setName] = useState('pcp-data');
    const [separator, setSeparator] = useState('|');
    const [reports, setReports] = useState([]);
    const [reportId, setReportId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        MarketplaceAction.getMarketplaceReports(AccountStore.getCurrentAccount().getId()).then(r => {
            if (r.length > 0) {
                setReportId(r[0].id);
                setName(AccountStore.getCurrentAccount().name + '-' + getMonthName(translate.getCurrentLanguage(), new Date(r[0].from)) + '-' + new Date(r[0].from).getFullYear());
            }
            if (reports) setReports(r);
            setIsLoading(false);
        }, () => setIsLoading(false));
    }, [open]);

    const exportReport = () => {
        const options = {
            decimalseparator: ',',
            fieldSeparator: separator,
            filename: name ? name : 'pcp-data',
            quoteStrings: '',
            showLabels: true,
            showTitle: false,
            useBom: true,
            useKeysAsHeaders: true
        };

        MarketplaceAction.exportMarketplaceReport(AccountStore.getCurrentAccount().getId(), reportId, options);
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={close}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
        >
            <IconButton
                color='secondary'
                onClick={close}
                className={classes.closeButton}
                size='large'>
                <ClearIcon />
            </IconButton>
            <DialogTitle id='responsive-dialog-title' className={classes.dialogTitle}>{translate.i18n('MARKETPLACE_REPORTS')}</DialogTitle>
            {isLoading ?
                <DialogContent className={classNames(!fullScreen && classes.dialogContentNotFullScreen, classes.dialogContent)}>
                    <CircularProgress className={classes.progress} size={50} />
                </DialogContent>
                :
                <DialogContent className={classNames(!fullScreen && classes.dialogContentNotFullScreen, classes.dialogContent)}>
                    <FormControl variant='outlined' className={classes.textField}>
                        <InputLabel
                            htmlFor='outlined-age-native-simple'
                        >
                            {translate.i18n('CHOOSE_A_REPORT')}
                        </InputLabel>
                        <Select
                            label={translate.i18n('CHOOSE_A_REPORT')}
                            native
                            value={reportId}
                            onChange={event => {
                                setReportId(event.target.value);
                                setName(AccountStore.getCurrentAccount().name + '-' + getMonthName(translate.getCurrentLanguage(), new Date(reports.find(r => event.target.value === r.id).from)) + '-' + new Date(reports.find(r => event.target.value === r.id).from).getFullYear());
                            }}
                        >
                            {reports.map(r => <option key={r.id} value={r.id}>{new Date(r.from).getFullYear()} - {translate.i18n('FROM')} {formatDate(new Date(r.from))} {translate.i18n('TO')} {formatDate(new Date(r.to))}</option>)}
                        </Select>
                    </FormControl>
                    <TextField
                        disabled={!reports.length}
                        id='filename'
                        className={classes.textField}
                        variant='outlined'
                        type='text'
                        label={translate.i18n('FILE_NAME')}
                        value={name}
                        onChange={event => setName(event.target.value)}
                    />
                    <FormControl
                        variant='outlined'
                        className={classes.textField}
                        disabled={!reports.length}>
                        <InputLabel
                            htmlFor='outlined-age-native-simple'
                        >
                            {translate.i18n('SEPARATOR')}
                        </InputLabel>
                        <Select
                            label={translate.i18n('SEPARATOR')}
                            native
                            value={separator}
                            onChange={event => setSeparator(event.target.value)}
                        >
                            <option value={','}>,</option>
                            <option value={';'}>;</option>
                            <option value={'.'}>.</option>
                            <option value={'|'}>|</option>
                        </Select>
                    </FormControl>
                </DialogContent>}
            <DialogActions>
                <Button color='primary' size='small'
                    onClick={exportReport}>
                    <ExportIcon className={classes.leftIcon} />
                    {translate.i18n('EXPORT_REPORTS')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DialogMarketplaceReports.propTypes = {
    close: PropTypes.func,
    open: PropTypes.bool
};

export default DialogMarketplaceReports;
