// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Locales
import translate from 'locales/i18n';
// Material UI Components
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
// Style
import { useStyles } from 'components/table/RepricedItemsTable/RepricedItemsTable.style';
// PCP Components
import Loader from 'components/commons/Loader/Loader';
import ItemCell from 'components/cell/ItemCell/ItemCell';
import SoftPriceCell from 'components/cell/SoftPriceCell/SoftPriceCell';
import PricingEngineCell from 'components/cell/PricingEngineCell/PricingEngineCell';
import ItemCellSpecificInfos from 'components/cell/ItemCellSpecificInfos/ItemCellSpecificInfos';
// Facade
import Facade from 'core/Facade';
// Theme
import { useTheme } from '@mui/styles';
// Stores
import AccountStore from 'stores/AccountStore';
import LayoutStore from 'stores/LayoutStore';
import UserStore from 'stores/UserStore';

const RepricedItemsTable = ({ items, loadingItems, websites, hideStocks, hidePrices }) => {

    const classes = useStyles();
    const theme = useTheme();

    const [blur, setBlur] = useState(UserStore.getCurrentUser().isSuperUser() ? LayoutStore.getBlur() : false);

    function onLayoutStoreChange() {
        setBlur(LayoutStore.getBlur());
    }

    useEffect(() => {
        UserStore.getCurrentUser().isSuperUser() ? LayoutStore.addChangeListener(onLayoutStoreChange) : null;
        return () => UserStore.getCurrentUser().isSuperUser() ? LayoutStore.removeChangeListener(onLayoutStoreChange) : null;
    }, []);

    if (loadingItems) {
        return (
            <div className={classes.loaderContainer}>
                <Loader size={50} />
            </div>
        );
    }

    if (!items || items.length === 0) {
        return <div className={classes.loaderContainer}><div className={classes.noItemsFound}>No items found</div></div>;
    }
    return (
        <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
                <TableRow className={classes.tableHead}>
                    <TableCell className={classes.tableHeadFirstCell}>
                        <div className={classes.tableHeadContent}>
                            <Typography variant='body2' color='inherit' noWrap className={classes.label}>
                                {translate.i18n('ITEMS') + ' / ' + translate.i18n('WEBSITES')}
                            </Typography>
                        </div>
                    </TableCell>
                    <TableCell className={classes.tableHeadSecondCell}>
                        <div className={classes.tableHeadContent}>
                            <Typography variant='body2' color='inherit' noWrap className={classes.label}>
                                Infos
                            </Typography>
                        </div>
                    </TableCell>
                    {AccountStore.getCurrentAccount().isRetailer() ? websites.map((website, key) => {
                        if (AccountStore.getCurrentAccount().isRetailer() && AccountStore.getCurrentAccount().getSiteId() === website.id)
                            return <TableCell key={key} className={classes.tableHeadCell} title={Facade.siteDisplayName(website.name)} onClick={() => Facade.openUrl(website.url)} style={{ cursor: 'pointer' }}>
                                <div className={classes.tableHeadContent} style={blur ? { filter: 'blur(0.2rem)' } : null}>
                                    <img src={`https://img.pricecomparator.pro/sites_images/${website.name}/1`}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = `https://www.google.com/s2/favicons?domain=${website.url}`;
                                        }} alt={Facade.siteDisplayName(website.name)} height={18} width={18} />
                                    <Typography variant='body2' color='inherit' noWrap className={classes.label}>
                                        {Facade.siteDisplayName(website.name)}
                                    </Typography>
                                </div>
                            </TableCell>;
                        return null;
                    })
                        :
                        null
                    }
                    <TableCell className={classes.tableHeadCell}>
                        <div className={classes.tableHeadContent}>
                            <Typography variant='body2' color='inherit' noWrap className={classes.label}>
                                {translate.i18n('REPRICING')}
                            </Typography>
                        </div>
                    </TableCell>
                    {websites.map((website, key) => {
                        if (AccountStore.getCurrentAccount().isRetailer() && AccountStore.getCurrentAccount().getSiteId() === website.id)
                            return null;
                        return <TableCell key={key} className={classes.tableHeadCell} title={Facade.siteDisplayName(website.name)} onClick={() => Facade.openUrl(website.url)} style={{ cursor: 'pointer' }}>
                            <div className={classes.tableHeadContent} style={blur ? { filter: 'blur(0.2rem)' } : null}>
                                <img src={`https://img.pricecomparator.pro/sites_images/${website.name}/1`}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = `https://www.google.com/s2/favicons?domain=${website.url}`;
                                    }} alt={Facade.siteDisplayName(website.name)} height={18} width={18} />
                                <Typography variant='body2' color='inherit' noWrap className={classes.label}>
                                    {Facade.siteDisplayName(website.name)}
                                </Typography>
                            </div>
                        </TableCell>;
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map((item, index) =>
                    <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? theme.palette.grey.semilight : theme.palette.white.main }}>
                        <ItemCell item={item}
                            index={index}
                            key={index}
                            canDelete={false}
                            hideNotations={true} />
                        <ItemCellSpecificInfos
                            item={item}
                            index={index}
                            blur={blur}
                        />
                        {AccountStore.getCurrentAccount().isRetailer() && item.scrapingData.map((scrapingData, key) => {
                            if (AccountStore.getCurrentAccount().isRetailer() && AccountStore.getCurrentAccount().getSiteId() === scrapingData.siteId)
                                return <SoftPriceCell
                                    key={key}
                                    scrapingData={scrapingData}
                                    hideStocks={hideStocks}
                                    hidePrices={hidePrices} />;
                            return null;
                        })}
                        <PricingEngineCell
                            product={item}
                            blur={blur}
                            originalScrapingData={AccountStore.getCurrentAccount().isRetailer() && item.scrapingData.find(scrapingData => AccountStore.getCurrentAccount().getSiteId() === scrapingData.siteId)} />
                        {item.scrapingData.map((scrapingData, key) => {
                            if (AccountStore.getCurrentAccount().isRetailer() && AccountStore.getCurrentAccount().getSiteId() === scrapingData.siteId)
                                return null;
                            return <SoftPriceCell
                                key={key}
                                scrapingData={scrapingData}
                                hideStocks={hideStocks}
                                hidePrices={hidePrices} />;
                        })}
                    </TableRow>)
                }
            </TableBody>
        </Table>
    );
};

RepricedItemsTable.propTypes = {
    items: PropTypes.array,
    loadingItems: PropTypes.bool,
    websites: PropTypes.array,
    hideStocks: PropTypes.bool,
    hidePrices: PropTypes.bool
};

export default RepricedItemsTable;
