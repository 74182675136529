const styles = theme => ({
    arrow: {
        color: theme.palette.primary.main,
        fontSize: '35px',
        marginBottom: '4px',
        marginLeft: '3%'
    },
    editContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexGrow: 1
    },
    exempleContainer: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '10px',
        marginRight: '10px'
    },
    exempleText: {
        fontSize: '15px',
        fontWeight: 500
    },
    finalPriceText: {
        color: '#1890A5',
        fontSize: '30px',
        fontWeight: 600
    },
    formControl: {
        margin: theme.spacing(1)
    },
    input: {
        padding: '10px 14px'
    },
    plusSymbol: {
        color: theme.palette.primary.main,
        fontSize: '25px',
        marginBottom: '2px'
    },
    radioButtonContainer: {
        marginLeft: '16px'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    title: {
        alignItems: 'center',
        color: theme.palette.primary.main,
        display: 'flex',
        fontSize: '17px',
        fontWeight: 500,
        marginLeft: '16px',
        marginRight: '16px',
        marginTop: '10px'
    },
    titleBar: {
        backgroundColor: theme.palette.primary.main,
        flexGrow: 1,
        height: '2px',
        marginLeft: '16px'
    },
    titleSecondaryText: {
        fontSize: '15px',
        fontWeight: 500
    },
    valueTextField: {
        margin: theme.spacing(1),
        maxWidth: '70px'
    }
});

export default styles;
