import React from 'react';
import PropTypes from 'prop-types';
// Style
import { hrStyle } from 'components/pages/ProductPage/ProductPage.style';
import { useStyles } from 'components/chart/PricePositionChart/PricePositionChart.style';
// PCP components
import Loader from 'components/commons/Loader/Loader';
import { PricePositionGraph } from 'pcp-graphical-lib';
// Facade
import Facade from 'core/Facade';
// Locales
import translate from 'locales/i18n';

const PriceSection = ({ priceStats, supplierMode, currentAccount }) => {
    const classes = useStyles();

    return (
        <div className={classes.priceSectionContainer}>
            <div className={classes.contentTitleContainer}>
                <div className={classes.contentTitle}>{translate.i18n('PRICE_SECTION')}</div>
                <hr style={hrStyle}></hr>
            </div>
            {priceStats && priceStats.avgPrice !== 0 && priceStats.currentPrice !== 0 && priceStats.maxPrice !== 0 && priceStats.minPrice !== 0 ? <div className={classes.priceSection}>
                {priceStats ?
                    !supplierMode ? <div className={classes.priceDetails}>
                        <div className={classes.priceDetailsBold}>{translate.i18n('PRODUCT_PAGE_PRICE_SECTION_YOUR_PRICE')} : {priceStats && Facade.showDecimal(priceStats.yourPrice, 2).replace('.', ',')} {currentAccount.currency.symbol}</div>
                        <div className={classes.priceDetailsBold}>{translate.i18n('PRODUCT_PAGE_PRICE_SECTION_YOUR_AVERAGE_PRICE')} : {priceStats && Facade.showDecimal(priceStats.yourAvgPrice, 2).replace('.', ',')} {currentAccount.currency.symbol}</div>
                        <div>{translate.i18n('PRODUCT_PAGE_PRICE_SECTION_ALL_COMPETITOR_AVERAGE_PRICE')}: {Facade.showDecimal(priceStats.otherAvgPrice, 2).replace('.', ',')} {currentAccount.currency.symbol} </div>
                        <div>{translate.i18n('PRODUCT_PAGE_PRICE_SECTION_MAX_PRICE')} : {Facade.showDecimal(priceStats.maxPrice, 2).replace('.', ',')} {currentAccount.currency.symbol} </div>
                        <div>{translate.i18n('PRODUCT_PAGE_PRICE_SECTION_MIN_PRICE')} : {Facade.showDecimal(priceStats.minPrice, 2).replace('.', ',')} {currentAccount.currency.symbol} </div>
                    </div>
                        : <div className={classes.priceDetails}>
                            <div>{translate.i18n('PRODUCT_PAGE_PRICE_SECTION_PRODUCT_AVERAGE_PRICE')} : {Facade.showDecimal(priceStats.avgPrice, 2).replace('.', ',')} {currentAccount.currency.symbol} </div>
                            <div>{translate.i18n('PRODUCT_PAGE_PRICE_SECTION_MAX_PRICE')} : {Facade.showDecimal(priceStats.maxPrice, 2).replace('.', ',')} {currentAccount.currency.symbol} </div>
                            <div>{translate.i18n('PRODUCT_PAGE_PRICE_SECTION_MIN_PRICE')} : {Facade.showDecimal(priceStats.minPrice, 2).replace('.', ',')} {currentAccount.currency.symbol} </div>
                            <div>{translate.i18n('PRODUCT_PAGE_PRICE_SECTION_RRP_ALERT_MIN')} : {Facade.showDecimal(priceStats.rrp - (priceStats.rrp * priceStats.rrpAlertPercent / 100), 2).replace('.', ',')} {currentAccount.currency.symbol} </div>
                            <div>RRP: {Facade.showDecimal(priceStats.rrp, 2).replace('.', ',')} {currentAccount.currency.symbol} </div>
                            <div>{translate.i18n('PRODUCT_PAGE_PRICE_SECTION_RRP_ALERT_MAX')} : {Facade.showDecimal(priceStats.rrp + (priceStats.rrp * priceStats.rrpAlertPercent / 100), 2).replace('.', ',')} {currentAccount.currency.symbol} </div>
                        </div>
                    : <Loader />
                }
                {priceStats && <PricePositionGraph
                    data={priceStats}
                    supplierMode={supplierMode}
                    currency={currentAccount.currency?.symbol}
                    translate={translate}
                    showDecimal={Facade.showDecimal}
                />}
            </div>
                :
                <div className={classes.priceSectionNoPrice}>{translate.i18n('PRODUCT_PAGE_PRICE_SECTION_NO_PRICE')}</div>
            }

        </div>
    );
};

PriceSection.propTypes = {
    priceStats: PropTypes.object,
    supplierMode: PropTypes.bool.isRequired,
    currentAccount: PropTypes.object,
};

export default PriceSection;
