import AbstractService from 'core/abstracts/AbstractService';
import Facade from 'core/Facade';

class SignService extends AbstractService {

    /**
     * Sign in with a username and password.
     */
    getToken(options) {
        // if no credentials were provided, reject
        if (!options.login || !options.password) {
            return Promise.reject();
        }

        return this.request({
            method: 'POST',
            url:  Facade.getApiRoot() + 'auth',
            json: {
                login: options.login,
                pwd: options.password
            }
        }).then(async responseData => {
            if (responseData.ok) {
                return await responseData.json();
            }

            throw await responseData.json();
        });
    }

    /**
     * Sign in with a username and password.
     */
    tokenExists() {
        return this.request({
            method: 'GET',
            url:  Facade.getApiRoot() + 'tokens/self/is-valid'
        }).then(async (responseData) => {
            if (responseData.ok) {
                return await responseData.json();
            }
            throw await responseData.json();
        });
    }

    /**
     * Sign in with a username and password.
     */
    logout() {
        return this.request({
            method: 'DELETE',
            url:  Facade.getApiRoot() + 'logout'
        }).then(responseData => responseData);
    }
}

export default new SignService();
