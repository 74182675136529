import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    cityImg: {
        height: '200px',
        padding: '5px',
        width: '320px'
    },
    dialogContent: {
        display: 'flex',
        position: 'relative',
        width: '100%'
    },
    dialogContentRight: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        marginLeft: '50px',
        marginRight: '50px'
    },
    dialogFooter: {
        position: 'absolute',
        textAlign: 'center',
        top: '297px',
        width: '95%'
    },
    dialogTitle: {
        textAlign: 'center'
    },
    logoImg: {
        marginBottom: '25px',
        width: '150px'
    }
}));
