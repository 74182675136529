// Util
import { ExportToCsv } from 'utils/Csv.util';
// Service
import MarketplaceService from 'services/MarketplaceService';
// Action
import AlertAction from './AlertAction';
// Locales
import translate from 'locales/i18n';

class MarketplaceAction {

    /**
      * Method called to get marketplace reports
      *
      * @param accountId the account id
      */
    getMarketplaceReports(accountId) {
        return MarketplaceService.getMarketplaceReports(accountId).then(reports => reports, response => {
            if (response && response.error) AlertAction.open('error', translate.i18n(response.error), 'top-right');
            throw response;
        });
    }

    /**
      * Method called to export a marketplace report
      *
      * @param accountId the account id
      * @param id the marketplace report id
      * @param exportOptions the export options
      */
    exportMarketplaceReport(accountId, id, exportOptions) {
        return MarketplaceService.getMarketplaceReport(accountId, id).then(data => {
            const dataExported = [];
            data.data.forEach((item) => {
                const newData = {};
                data.header.forEach(h => newData[h] = item[h]);
                dataExported.push(newData);
                return true;
            });

            const csvExporter = new ExportToCsv(exportOptions);
            csvExporter.generateCsv(dataExported);

            return data;
        }, response => {
            if (response && response.error) AlertAction.open('error', translate.i18n(response.error), 'top-right');
            throw response;
        });
    }
}

export default new MarketplaceAction();
