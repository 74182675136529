// Abstract
import AbstractStore from 'core/abstracts/AbstractStore';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';

class AlertStore extends AbstractStore {

    constructor() {

        const CHANGE_EVENT = 'ALERT_CHANGE_EVENT';
        const NAME = 'ALERT_STORE';

        super(NAME, CHANGE_EVENT);

        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));

        this._initStore();
    }

    _initStore() {
        this.text = '';
        this.type = '';
        this.position = '';

        this.open = false;
    }

    getAlertConfig() {
        return {
            position: this.position,
            text: this.text,
            type: this.type
        };
    }

    updateAlert(data) {
        this.text = data.text;
        this.type = data.type;
        this.position = data.position;
        this.open = true;
    }

    closeAlert() {
        this.open = false;
    }

    isOpen() {
        return this.open;
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
        case Constants.USER_LOGOUT:
            this._initStore();
            this._emitChange();
            break;
        case Constants.ALERT_OPEN:
            this.updateAlert(action.value);
            this._emitChange();
            break;
        case Constants.ALERT_CLOSE:
            this.closeAlert();
            this._emitChange();
            break;
        default:
            return false;
        }
    }
}

export default new AlertStore();
