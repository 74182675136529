import translate from 'locales/i18n';
/**
  * Method which create data for a graph display
  *
  * @param daysValues <array<object>> the array of day values
  * @returns <object> Data object formatted to display graph
  */
export const createData = daysValues => {
    const values = [0, 0, 0, 0, 0, 0, 0];
    const colors = ['#1A9FB7', '#1A9FB7', '#1A9FB7', '#1A9FB7', '#1A9FB7', '#1A9FB7', '#1A9FB7', '#1A9FB7'];

    daysValues.forEach((val, i) => {
        values[i] = daysValues[i].nbNewSlides;
        return true;
    });

    const data = {
        datasets: [{
            backgroundColor: colors,
            borderColor: colors,
            data: values,
            hoverBackgroundColor: colors,
            hoverBorderColor: colors,
            label: translate.i18n('NEW_SLIDES_MESSAGE')
        }],
        labels: ['Mon', 'Thu', 'Wen', 'Tue', 'Fri', 'Sat', 'Sun']
    };

    return data;
};

/**
  * Method which return the options for a graph display
  *
  * @returns <object> the option object
  */
export const getOptions = () => ({
    maintainAspectRatio: false,
    borderRadius: 2,
    plugins: {
        title: {
            display: true,
            text: ''
        },
        legend: {
            display: false
        },
        datalabels: {
            backgroundColor: (context) => context.dataset.backgroundColor,
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 2,
            color: 'white',
            anchor: 'end',
            font: {
                weight: 'bold'
            },
            padding: 6,
            display: (context) => context.dataset.data[context.dataIndex] > 0,
        },
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            stacked: true,
            ticks: {
                display: true,
            }
        },
        y: {
            grid: {
                display: false,
                drawBorder: false
            },
            stacked: true,
            ticks: {
                display: false,
                callback: (value) => Number.isInteger(value) ? value : null
            }
        }
    },
});
