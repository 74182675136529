// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import classNames from 'classnames';
// Facade
import Facade from 'core/Facade';
// Locales
import translate from 'locales/i18n';
// Material UI
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import IconRefresh from '@mui/icons-material/Cached';
// PCP Components
import DialogInfo from 'components/dialog/DialogInfo/DialogInfo';
// Images
import StockFilterExample from 'assets/img/stock-filter-example.png';
//Store
import UserStore from 'stores/UserStore';
import LayoutStore from 'stores/LayoutStore';
// Util
import { getOptions, createData } from 'components/filter/price/StockFilter/StockFilter.util';
// Style
import { useStyles } from 'components/filter/price/StockFilter/StockFilter.style';
// Theme
import { useTheme } from '@mui/styles';
// Store
import AccountStore from 'stores/AccountStore';

const StockFilter = ({ isLoading, reset, selectStocksBaskets, websites }) => {
    const [dialogInfoOpen, setDialogInfoOpen] = useState(false);
    const classes = useStyles();
    const theme = useTheme();

    const [blur, setBlur] = useState(UserStore.getCurrentUser().isSuperUser() ? LayoutStore.getBlur() : false);

    function onLayoutStoreChange() {
        setBlur(LayoutStore.getBlur());
    }

    useEffect(() => {
        UserStore.getCurrentUser().isSuperUser() ? LayoutStore.addChangeListener(onLayoutStoreChange) : null;
        return () => UserStore.getCurrentUser().isSuperUser() ? LayoutStore.removeChangeListener(onLayoutStoreChange) : null;
    }, []);

    const renderLogoList = () => websites.map((website, key) => {
        let total = 0;
        for (let i = 0; i < 4; i++)
            total += website.stocksBaskets[i].cardinalities[0];

        const width = websites.length > 5 ? 100 / websites.length + 3 : null;

        return <img key={key} src={`https://img.pricecomparator.pro/sites_images/${website.name}/1`} onError={(e) => {
            e.target.onerror = null; e.target.src = `https://www.google.com/s2/favicons?domain=${website.url}`;
        }} alt={Facade.siteDisplayName(website.name)} title={website.name + ' : ' + total + ' ' + translate.i18n('ITEMS')} width={width} height={15} />;
    });

    const renderDialogInfo = () => <DialogInfo open={dialogInfoOpen}
        close={() => setDialogInfoOpen(false)}>
        <div className={classes.infoMainText}>{AccountStore.getCurrentAccount().isRetailer() ? translate.i18n('STOCK_FILTER_EXPLANATION_1_RETAILER') : translate.i18n('STOCK_FILTER_EXPLANATION_1_SUPPLIER')}</div>
        <ul>
            <li className={classes.infoSubText}>{translate.i18n('STOCK_FILTER_EXPLANATION_2')}</li>
        </ul>
        <div className={classes.infoImgContainer}><img src={StockFilterExample} /></div>
    </DialogInfo>;

    if (websites.length === 0)
        return (
            <Paper className={classNames(classes.paper, isLoading && classes.notAllowed)}>
            </Paper>
        );

    return (
        <Paper className={classNames(classes.paper, isLoading && classes.notAllowed)}>
            <div className={classes.graphContainer}>
                <Bar
                    data={createData(websites, theme)}
                    getElementAtEvent={element => {
                        if (element.length !== 0 && !isLoading) {
                            let index = element[0].datasetIndex - 1;
                            if (index === -1) index = 3;
                            selectStocksBaskets(websites[0].stocksBaskets[index].id, websites[element[0].index].id);
                        }
                    }}
                    options={getOptions()}
                />
            </div>
            <div className={classes.logoContainer} style={blur ? { filter: 'blur(0.2rem)' } : null}>{renderLogoList()}</div>
            <IconButton
                color='primary'
                onClick={reset}
                className={classes.resetButton}>
                <IconRefresh />
            </IconButton>
            <IconButton
                color='primary'
                onClick={() => setDialogInfoOpen(true)}
                className={classes.infoButton}>
                <InfoIcon />
            </IconButton>
            {renderDialogInfo()}
        </Paper>
    );
};

StockFilter.propTypes = {
    isLoading: PropTypes.bool,
    reset: PropTypes.func,
    selectStocksBaskets: PropTypes.func,
    websites: PropTypes.array
};

export default StockFilter;

