import React from 'react';
import Facade from 'core/Facade';
import PropTypes from 'prop-types';

import ImageViewer from 'components/commons/ImageViewer/ImageViewer';

function Slide ({ slide, site, openDetailsOnClick }) {
    const legend = Facade.siteDisplayName(site.name);

    return (
        <ImageViewer
            imageUrl={'https://img.pricecomparator.pro/slider_images/slider/' + site.name + '/' + slide.slider_image}
            title={Facade.siteDisplayName(site.name)}
            alt={Facade.siteDisplayName(site.name)}
            legend={legend}
            openDetailsOnClick={openDetailsOnClick}
            imageStyle={{ borderRadius: '3px' }}
            hideHover />
    );
}

Slide.defaultProps = {
    openDetailsOnClick: false
};

Slide.propTypes = {
    slide: PropTypes.object,
    site: PropTypes.object,
    openDetailsOnClick: PropTypes.bool
};

export default Slide;
