import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    addIcon: {
        height: '18px',
        width: '18px'
    },
    bellIcon: {
        color: theme.palette.grey.dark,
        fontSize: '16px'
    },
    bottomContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    cellContent: {
        display: 'flex',
        justifyContent: 'center',
    },
    editContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    imgContainer:  {
        height: '64px',
        width: '64px',
        margin: '5px'
    },
    img: {
        width: '100%',
        height: '100%'
    },
    disabled: {
        opacity: 0.5
    },
    editIcon: {
        height: 30,
        padding: 0,
        width: 30
    },
    evolutionIconContainer: {
        marginTop: '5px'
    },
    hoverObserver: {
        width: '100%'
    },
    informationContainer: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        margin: '5px'
    },
    informationTopContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    label: {
        color: theme.palette.grey.dark,
        fontWeight: '500'
    },
    marketplaceIconContainer: {
        cursor: 'pointer',
        position: 'absolute',
        right: '5px',
        top: '5px'
    },
    newIcon: {
        color: theme.palette.green.main,
        fontSize: '30px'
    },
    notationContainer: {
        margin: '5px',
        cursor: 'pointer',
    },
    nvBellContent: {
        alignItems: 'center',
        display: 'flex'
    },
    oldPrice: {
        color: theme.palette.grey.semidark,
        fontSize: '11px',
        marginLeft: '5px',
        textDecoration: 'line-through'
    },
    priceCellContainerEmpty: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    priceCellContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        fontWeight: '500',
        minWidth: '70px',
        justifyContent: 'center',
        whiteSpace: 'nowrap'
    },
    priceContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '2px',
        width: '100%'
    },
    pricingAdvising: {
        color: theme.palette.info.light
    },
    smallIconDown: {
        color: theme.palette.secondary.light,
        fontSize: '30px',
        transform: 'rotate(-45deg)'
    },
    smallIconUp: {
        fontSize: '30px',
        transform: 'rotate(45deg)'
    },
    stockEditContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px'
    },
    tableCell: {
        borderRight: '1px solid' + theme.palette.grey.light,
        padding: 0,
        position: 'relative',
        minWidth: 'max-content'
    },
    tableCellEmpty: {
        borderRight: '1px solid' + theme.palette.grey.light,
        padding: 0,
        position: 'relative'
    },
    tableCellLoading: {
        backgroundColor: theme.palette.white.common,
        borderLeft: '1px solid' + theme.palette.grey.light,
        borderRight: '1px solid' + theme.palette.grey.light,
        padding: 0,
        position: 'relative'
    }
}));
