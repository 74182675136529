export const styles = theme => ({
    closeButton: {
        position: 'absolute',
        right: '2px',
        top: '2px'
    },
    dialog: {
        minWidth: '350px'
    },
    dialogContentContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    errorMessage: {
        color: theme.palette.red.main,
        fontFamily: 'roboto',
        fontSize: '12px',
        fontStyle: 'italic',
        fontWeight: '400',
        marginBottom: theme.spacing(1) / 2,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1) / 2
    },
    margin: {
        margin: theme.spacing(1)
    }
});
