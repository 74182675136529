// React
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// Stores
import UserStore from 'stores/UserStore';
import AccountStore from 'stores/AccountStore';
import PricingEngineStore from 'stores/PricingEngineStore';
// Actions
import RuleAction from 'actions/RuleAction';
// Locales
import translate from 'locales/i18n';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Gavel';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ArrowLeftIcon from '@mui/icons-material/ArrowBack';
import FileCopyIcon from '@mui/icons-material/FileCopy';
// Styles
import { useStyles } from 'components/pages/PricingEngineLibraryPage/PricingEngineLibraryPage.style';
// PCP Components
import DialogConfirm from 'components/dialog/DialogConfirm/DialogConfirm';
import RuleSheet from 'components/sheet/RuleSheet/RuleSheet';
// Facade
import Facade from 'core/Facade';
//Utils
import { findCopyName } from 'components/pages/PricingEngineLibraryPage/PricingEngineLibraryPage.util';

const PricingEngineLibraryPage = () => {
    const [currentRule, setCurrentRule] = useState(null);
    const [rules, setRules] = useState(PricingEngineStore.getRules());
    const [deleteRule, setDeleteRule] = useState({ dialogConfirmDeleteRuleOpen: false, rule: null });
    const [search, setSearch] = useState('');
    const classes = useStyles();

    useEffect(() => {
        PricingEngineStore.addChangeListener(onPricingEngineStoreChange);

        if (UserStore.isLogged()) {
            RuleAction.getRules(AccountStore.getCurrentAccount().getId());
        }

        return () => PricingEngineStore.removeChangeListener(onPricingEngineStoreChange);
    }, []);

    const onPricingEngineStoreChange = () => {
        setRules(PricingEngineStore.getRules());
    };

    const renderDialogConfirmDeleteRule = () => <DialogConfirm
        text={translate.i18n('CONFIRM_DELETE_RULE', { ruleName: deleteRule.rule ? deleteRule.rule.name : '' })}
        open={deleteRule.dialogConfirmDeleteRuleOpen}
        close={() => setDeleteRule({ dialogConfirmDeleteRuleOpen: false, rule: deleteRule.rule })}
        callback={() => {
            if (currentRule && currentRule.id === deleteRule.rule.id) setCurrentRule(null);
            RuleAction.deleteRule(AccountStore.getCurrentAccount().getId(), deleteRule.rule.id);
        }}
    />;

    return (
        <div className={classes.root}>
            <div className={classes.leftContainer}>
                <Link className={classes.link} to={{
                    pathname: '/pricing-engine-analyze',
                }}>
                    <Button
                        variant='outlined'
                        color='primary'
                        size='medium'
                        className={classes.button}
                        startIcon={<ArrowLeftIcon />}
                    >
                        {translate.i18n('ANALYZE')}
                    </Button>
                </Link>
                <Paper elevation={3}
                    className={classes.rulesContainer}>
                    <TextField
                        label={translate.i18n('SEARCH')}
                        value={search}
                        onChange={event => setSearch(event.target.value)}
                        variant='outlined'
                        className={classes.searchTextField}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'><SearchIcon className={classes.searchIcon} /></InputAdornment>
                        }}
                    />
                    <List dense={true}
                        className={classes.rulesList}>
                        {rules.filter(r => r.name.toLowerCase().includes(search.toLowerCase())).map((rule, key) =>
                            <ListItem key={key}
                                title={translate.i18n('RULE_ASSOCIATED_TO_X_ITEMS', { nbOfItems: rule.nbOfAssociatedArticles })}
                                button
                                onClick={() => RuleAction.getRule(rule.id).then(data => setCurrentRule(data))}
                                className={currentRule && currentRule.id === rule.id ? classes.ruleSelectedContent : classes.ruleContent}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FolderIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    classes={{
                                        primary: classes.ruleNameText,
                                        secondary: classes.ruleDateText
                                    }}
                                    primary={<div className={classes.listPrimaryText}>{rule.name}</div>}
                                    disableTypography={true}
                                    secondary={<div className={classes.listSecondaryText}>{`${Facade.formatDate(new Date(rule.lastUpdateDate))} | `}<span className={classes.itemsAssociated}>{`${rule.nbOfAssociatedArticles} ${translate.i18n('ITEMS')}`}</span></div>}
                                />
                                <ListItemSecondaryAction>
                                    <Link className={classes.link} to={'/rule/' + rule.id}>
                                        <IconButton edge='end' aria-label='delete' color='primary' size='large'>
                                            <EditIcon />
                                        </IconButton>
                                    </Link>
                                    <Link className={classes.link} to={ '/rule?id=' + rule.id + '&name=' + findCopyName(rule.name, rules)}>
                                        <IconButton edge='end' aria-label='delete' color='primary' size='large'>
                                            <FileCopyIcon />
                                        </IconButton>
                                    </Link>
                                    <IconButton
                                        color='secondary'
                                        edge='end'
                                        onClick={() => setDeleteRule({ dialogConfirmDeleteRuleOpen: true, rule })}
                                        size='large'>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                    <Link className={classes.link} to={'/rule'} style={{ marginLeft: 'auto', marginTop: '4px' }}>
                        <Button
                            variant='outlined'
                            color='primary'
                            size='large'
                            className={classes.addRuleButton}
                            startIcon={<AddIcon />}
                        >
                            {translate.i18n('ADD_RULE')}
                        </Button>
                    </Link>
                </Paper>
            </div>
            <Paper elevation={3}
                className={classes.resumeContainer}>
                {currentRule ?
                    <RuleSheet rule={currentRule} />
                    :
                    <div className={classes.noRuleSelectedText}>{translate.i18n('NO_RULE_SELECTED')}</div>
                }
            </Paper>
            {renderDialogConfirmDeleteRule()}
        </div>
    );
};

export default PricingEngineLibraryPage;
