// React
import React from 'react';
import PropTypes from 'prop-types';
// Locales
import translate from 'locales/i18n';
// Styles
import { useStyles } from 'components/cell/SoftPriceCell/SoftPriceCell.style';
// Material UI
import { TableCell } from '@mui/material';
import IconBell from '@mui/icons-material/Notifications';
// PCP Components
import Loader from 'components/commons/Loader/Loader';
// Stores
import AccountStore from 'stores/AccountStore';
// Util
import { stockColor, displayPrice } from 'components/cell/SoftPriceCell/SoftPriceCell.util';
//Theme
import { useTheme } from '@mui/styles';
// Facade
import Facade from 'core/Facade';
// SVG
import stockIcon from 'assets/img/svg/stockIcon';

const SoftPriceCell = ({ scrapingData, hideStocks, hidePrices }) => {
    const classes = useStyles();
    const theme = useTheme();

    if (scrapingData.scrapedData.status === 'UNDEFINED') return <TableCell className={classes.notSoldTableCell}></TableCell>;

    if (scrapingData.scrapedData.status === 'NV_BELL') return <TableCell className={classes.notSoldTableCell}><div className={classes.notSoldTableCellContent} onClick={() => Facade.openUrl(scrapingData.url)}>{translate.i18n('NOT_SOLD')}<IconBell className={classes.bellIcon}></IconBell></div></TableCell>;

    if (scrapingData.scrapedData.status === 'NV') return <TableCell className={classes.notSoldTableCell}><div className={classes.notSoldTableCellContent}>{translate.i18n('NOT_SOLD')}</div></TableCell>;

    if (scrapingData.scrapedData.status === 'PENDING') return <TableCell className={classes.tableCell} onClick={() => Facade.openUrl(scrapingData.url)}><div className={classes.tableCellContent}><Loader size={30} /></div></TableCell>;

    return <TableCell className={scrapingData.scrapedData.price === -2 ? classes.tableCellNotFoundPrice : classes.tableCell}>
        <div className={classes.tableCellContent}
            onClick={() => Facade.openUrl(scrapingData.url)}>
            {!hidePrices && (scrapingData.scrapedData.price === -2 ? translate.i18n('NO_PRICE') : `${displayPrice(scrapingData.scrapedData.price)} ${AccountStore.getCurrentAccount().getCurrency()}`)}
            {!hideStocks && stockIcon(22, 22, stockColor(scrapingData.scrapedData.stock, theme))}
        </div>
    </TableCell>;
};

SoftPriceCell.propTypes = {
    scrapingData: PropTypes.object,
    hideStocks: PropTypes.bool,
    hidePrices: PropTypes.bool
};

export default SoftPriceCell;
