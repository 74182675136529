import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    graphContainer: {
        height: '280px',
        marginRight: 10,
        padding: '10px',
        width: '100%'
    },
    iconReset: {
        position: 'absolute',
        right: '10px'
    },
    paper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '280px',
        position: 'relative',
        width: '100%'
    }
}));
