import AbstractService from 'core/abstracts/AbstractService';
import Facade from 'core/Facade';

class adBoxService extends AbstractService {
    getMails(options) {
        const fromStr = options.fromStr.getFullYear() + '-' + (options.fromStr.getMonth() + 1) + '-' + options.fromStr.getDate();
        const toStr = options.toStr.getFullYear() + '-' + (options.toStr.getMonth() + 1) + '-' + options.toStr.getDate();

        // if no options were provided return reject
        if (!options.accountId) {
            return Promise.reject();
        }

        return this.request({
            method: 'POST',
            url: Facade.getApiRoot() + `ad-box/analyse?accountId=${options.accountId}&fromStr=${fromStr}&toStr=${toStr}`,
            json: {
                sites: options.sites,
                days: options.days,
                hours: options.hours
            }

        }).then(responseData => {
            if (responseData.ok) {
                return responseData.json();
            }
            throw responseData.ok;
        });
    }
}

export default new adBoxService();
