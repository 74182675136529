import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    adBoxContent: {
        height: '100%',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    adBoxHeader: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '10px',
        marginTop: '6px'
    },
    datePicker: {
        margin: '5px'
    },
    filterContainer: {
        marginBottom: '20px'
    },
    hide: {
        display: 'none'
    },
    itemClass: {
        padding: '5px'
    },
    loaderContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '85%',
        justifyContent: 'center',
        width: '100%'
    },
    root: {
        height: '100%',
        overflowX: 'hidden',
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%'
    },
    searchButton: {
        marginLeft: '5px',
        marginRight: '5px'
    },
    titleNoMail: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        width: '100%'
    }
}));

