import React from 'react';
import PropTypes from 'prop-types';
//Tag cloud
import WordCloud from 'react-d3-cloud';
//Mui components
import { Paper } from '@mui/material';
//Style
import { useStyles } from 'components/filter/mail/MailTagCloud/MailTagCloud.style';
import { useTheme } from '@mui/styles';
//Locales
import translate from 'locales/i18n';
//Utils
import { createData } from 'components/filter/mail/MailTagCloud/MailTagCloud.util';

const MailTagCloud = ({ data }) => {
    const classes = useStyles();
    const theme = useTheme();

    return !data ?
        <Paper className={classes.paper}>
        </Paper>
        :
        <Paper className={classes.paper}>
            <h5 className={classes.titleGraph}>{translate.i18n('ADBOX.TAG_CLOUD_TITLE')}</h5>
            <div className={classes.graphContainer}>
                <WordCloud
                    width={520}
                    height={300}
                    font='Times'
                    fontWeight='bold'
                    fontSize={(word) => word.size}
                    spiral='rectangular'
                    rotate={0}
                    padding={5}
                    random={Math.random}
                    fill={(d) => d.color}
                    data={createData(data,theme)} />
            </div>
        </Paper>;
};

MailTagCloud.propTypes = {
    data: PropTypes.array
};

export default MailTagCloud;
