// React
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Locales
import translate from 'locales/i18n';
// Actions
import SignAction from 'actions/SignAction';
import AccountAction from 'actions/AccountAction';
// Style
import { styles } from 'components/appbar/Appbar.style';
// Style
import { getCurrentRouteProp } from 'components/appbar/Appbar.util';
// PCP Components
import MenuPopper from 'components/popper/MenuPopper/MenuPopper';
import withStyles from '@mui/styles/withStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import DialogAbout from 'components/dialog/DialogAbout/DialogAbout';
// Material Components
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Avatar } from '@mui/material';
// Stores
import UserStore from 'stores/UserStore';
import AccountStore from 'stores/AccountStore';
import ItemStore from 'stores/ItemStore';
// Images
import PCPLogo from 'assets/img/pcp-logo.png';
// Facade
import Facade from 'core/Facade';
import LayoutStore from 'stores/LayoutStore';

class Appbar extends React.Component {

    state = {
        account: AccountStore.getCurrentAccount() ? AccountStore.getCurrentAccount() : '',
        accounts: UserStore.getCurrentUser().getAccounts(),
        anchorEl: null,
        language: UserStore.getCurrentUser().getLanguage(),
        openMenu: false,
        websites: ItemStore.getWebsites(),
        blur: UserStore.getCurrentUser().isSuperUser() ? LayoutStore.getBlur() : false,
        aboutPopup: false
    };

    static propTypes = {
        classes: PropTypes.object.isRequired,
        routes: PropTypes.array,
        sidebarOpen: PropTypes.bool,
        toggleSidebar: PropTypes.func
    };

    componentDidMount() {
        AccountStore.addChangeListener(this.onAccountStoreChange);
        ItemStore.addChangeListener(this.onItemStoreChange);
        UserStore.getCurrentUser().isSuperUser() ? LayoutStore.addChangeListener(this.onLayoutStoreChange) : null;
        this.mounted = true;
    }

    componentWillUnmount() {
        AccountStore.removeChangeListener(this.onAccountStoreChange);
        ItemStore.removeChangeListener(this.onItemStoreChange);
        UserStore.getCurrentUser().isSuperUser() ? LayoutStore.removeChangeListener(this.onLayoutStoreChange) : null;
        this.mounted = false;
    }

    onAccountStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            account: AccountStore.getCurrentAccount(),
        });
    };

    onLayoutStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            blur: LayoutStore.getBlur()
        });
    };

    onItemStoreChange = () => {
        if (!this.mounted) {
            return false;
        }

        this.setState({
            websites: ItemStore.getWebsites(),
        });
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    toggleMenu = () => {
        this.setState(state => ({ openMenu: !state.openMenu }));
    };

    handleCloseMenu = event => {
        if (this.anchorEl.contains(event.target)) return;
        this.setState({ openMenu: false });
    };

    toggleAboutPopup = () => this.setState({ aboutPopup: !this.state.aboutPopup });

    getAccountImage() {
        const { websites, account } = this.state;
        if (account.logo) return account.logo;
        if (AccountStore.getCurrentAccount().isRetailer() && websites && websites.length > 0) {
            const retailerSite = websites.find(website => website.id === account.site.id);
            if (retailerSite) return Facade.getWebsiteIco(retailerSite.url);
        }
    }

    renderMenu = () =>
        <MenuPopper
            open={this.state.openMenu}
            handleClose={this.handleCloseMenu}
            anchor={this.anchorEl}
            logout={() => SignAction.logout(false)}
            openAboutPopup={this.toggleAboutPopup}
        />;
    renderAboutPopup = () =>
        <DialogAbout
            open={this.state.aboutPopup}
            close={this.toggleAboutPopup} />;

    renderSelectAccount = () => {
        const { classes } = this.props;
        const { account, accounts, blur } = this.state;

        if (accounts.length < 2)
            return <div className={classes.accountName}>{account.name}</div>;

        return (
            <FormControl sx={{ m: 1, minWidth: 80 }}
                size='small'
                className={classes.formControl}
                variant='filled'>
                <InputLabel>{translate.i18n('ACCOUNT')}</InputLabel>
                <Select
                    className={`${classes.selectAccount} ${blur ? classes.blur : null}`}
                    value={account.id}
                    label={translate.i18n('ACCOUNT')}
                    onChange={event => AccountAction.switchAccount(event.target.value)}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: '50%',
                            },
                        },
                    }}>
                    {accounts.map((a, key) => <MenuItem value={a.id} key={key} >{a.name}</MenuItem>)}
                </Select>
            </FormControl>
        );
    };

    render() {
        const { classes, sidebarOpen, toggleSidebar, routes } = this.props;
        const { account, blur } = this.state;

        const currentRoute = getCurrentRouteProp(routes);

        if (!account.id)
            return null;

        return (
            <AppBar
                position='absolute'
                className={classNames(classes.appBar, sidebarOpen && classes.appBarShift)}>
                <Toolbar
                    disableGutters={!sidebarOpen}>
                    <img src={PCPLogo}
                        height={40}
                        width={40}
                        className={classNames(classes.menuButton, sidebarOpen && classes.hide)}
                        onClick={toggleSidebar}
                        title={'PriceComparator'}
                        alt={'PriceComparator'}></img>
                    <Typography variant='h5' color='inherit' noWrap className={classNames(classes.typography, sidebarOpen && classes.hideMobile)}>
                        Price<span className={classes.subText}>Comparator </span>
                        {currentRoute ?
                            <Typography color='inherit' noWrap className={classNames(classes.typographySubtitle, sidebarOpen && classes.hideMobile)}>
                                {currentRoute.appbarName}
                            </Typography>
                            :
                            null
                        }
                    </Typography>
                    {process.env.REACT_APP_ENV !== 'production' && <span className={classes.envLabel}>ATTENTION VOUS ÊTES EN TEST!</span>}
                    {this.renderSelectAccount()}
                    <Avatar alt={AccountStore.getCurrentAccount().getName()}
                        src={this.getAccountImage()}
                        ref={node => {
                            this.anchorEl = node;
                        }}
                        className={blur ? classes.blur : null}
                        classes={{
                            img: classes.avatarImg,
                            root: classes.avatarRoot
                        }}
                        onClick={this.toggleMenu} />
                </Toolbar>
                {this.renderMenu()}
                {this.renderAboutPopup()}
            </AppBar>
        );
    }
}

export default withStyles(styles)(Appbar);
