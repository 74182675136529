import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    teaserAction: {
        alignItems: 'center',
        display: 'flex',
        height: '40px',
        justifyContent: 'end',
        margin: '10px',
        marginTop: '0px',
        width: 'calc(100% - 20px)'
    },
    teaserActionText: {
        fontSize: '15px',
        fontWeight: 'bold',
        marginRight: '10px'
    },
    teaserContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    teaserImage: {
        maxHeight: '100%',
        maxWidth: '100%'
    },
    teaserPart: {
        alignItems: 'center',
        display: 'flex',
        height: 'calc((100% - 140px) / 2)',
        justifyContent: 'space-between',
        margin: '10px',
        width: 'calc(100% - 40px)'
    },
    teaserPartImage: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: 'calc(50% - 10px)'
    },
    teaserPartText: {
        fontSize: '15px',
        height: '100%',
        overflowY: 'auto',
        textAlign: 'justify',
        width: 'calc(50% - 10px)'
    },
    teaserPartTextTitle: {
        fontSize: '19px',
        fontWeight: 'bold'
    },
    teaserTitle: {
        fontSize: '25px',
        fontWeight: 'bold',
        height: '40px',
        margin: '5px',
        textAlign: 'center',
        width: '100%'
    }
}));
