/**
  * Method which return the color for the stock
  *
  * @param stock <string> of the stock
  * @returns <string> of stock color
  */
export const stockColor = (stock,theme) => {

    if (!theme) return '#7A7A7A';
    let color = theme.palette.grey.main;

    if (!stock || typeof stock !== 'string') return color;

    switch (stock.toLowerCase()) {
    case 'en stock':
        color = theme.palette.green.main;
        break;
    case 'rupture':
        color = theme.palette.black.main;
        break;
    case '+5 jours':
        color = theme.palette.red.main;
        break;
    case 'sup 48h':
    case 'sup. 48h':
    case 'supp 48h':
    case 'supp. 48h':
        color = theme.palette.red.main;
        break;
    default:
        color = theme.palette.grey.main;
    }

    return color;
};

export const displayPrice = (price) => {
    let priceString = typeof price === 'number' ? price.toString() : price;

    priceString = priceString.split('.');
    if (priceString.length < 2) return price;

    if (priceString[1].length === 1) return `${priceString[0]},${priceString[1]}0`;
    return `${priceString[0]},${priceString[1]}`;
};
