// Material UI
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    customSelect: {
        backgroundColor: theme.palette.text.secondary,
        maxHeight: '200px'
    },
    root: {
        alignItems: 'center',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row',
        flexShrink: 0,
        marginLeft: theme.spacing(2.5)
    }
}));
