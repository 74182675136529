/**
 * Function used to calculate the size and the position of the rule during the seleced date
 * @param {Object} rule The rule
 * @param {Date} prevDate The starting date
 * @returns {Object} {startingPosition, length}
 * The starting position can be :
 *      -1 if it is before prevDate
 *      0 if equals prevDate
 *      n the number of days after prevDate
 * The length is the number of days of application of the rule
 */
export const calcSizeFromDate = (rule, prevDate) => {

    let startingPosition = -1;
    let length = 31;
    let formatedApplyDate;

    if (!rule) return { startingPosition, length };

    const { applyFrom, applyUntil } = rule;

    if (applyFrom) {
        formatedApplyDate = new Date(applyFrom);
        if (formatedApplyDate.getTime() >= prevDate().getTime()) {
            startingPosition = (formatedApplyDate.getTime() - prevDate().getTime()) / (1000 * 3600 * 24);
        }
    }
    if (applyUntil) {
        const formatedUntilDate = new Date(applyUntil);
        if (startingPosition < 0) length = ((formatedUntilDate.getTime() - prevDate().getTime()) / (1000 * 3600 * 24)) + 1;
        else length = ((formatedUntilDate.getTime() - formatedApplyDate.getTime()) / (1000 * 3600 * 24)) + 1;
    }
    return { startingPosition, length };
};

/**
 * Returns the color according to the rule rank
 * @param {number} rank
 * @param theme
 * @returns {string} The color from the palette
 */
export const getRankColor = (rank, theme) => {
    if (!rank || !theme) return '#99b3ff';
    switch (rank) {
    case 1:
        return theme.palette.rulesRanks.one;
    case 2:
        return theme.palette.rulesRanks.two;
    case 3:
        return theme.palette.rulesRanks.three;
    case 4:
        return theme.palette.rulesRanks.four;
    default:
        return theme.palette.rulesRanks.five;
    }
};

/**
 * Theese 3 functions are used to make modifications when you drop a rule
 * Check react-beutiful-dnd for more info
 */

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const insert = (list, index, rule) => {
    const result = Array.from(list);
    result.push(rule);
    return reorder(result, result.length - 1, index);
};

export const deleteAtIndex = (list, index) => {
    const result = Array.from(list);
    result.splice(index, 1);
    return result;
};
