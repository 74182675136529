// Abstract
import AbstractStore from 'core/abstracts/AbstractStore';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Facade
import Facade from 'core/Facade';

class SliderStore extends AbstractStore {

    constructor() {

        const CHANGE_EVENT = 'SLIDER_CHANGE_EVENT';
        const NAME = 'SLIDER_STORE';

        super(NAME, CHANGE_EVENT);

        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));

        this._initStore();
    }

    _initStore() {
        // Mails
        this.sliderSites = [];
        this.evolution = [];
        this.params = this._initParams();
        this.isloadingSlider = true;
        this.firstLoad = true;
    }

    _initParams() {
        const yesterdayDate = new Date(new Date().setHours(0,0,0,0));
        yesterdayDate.setDate(yesterdayDate.getDate() - 30);

        const today = new Date(new Date().setHours(23,59,59,0));

        const currentDay = new Date();

        const params = {
            currentDay: currentDay.setDate(currentDay.getDate() - 1),
            from: yesterdayDate,
            to: today
        };

        const urlParams = Facade.getAllUrlParams();

        if (urlParams.length === 0)
            return params;

        if (Facade.getUrlParam('from'))
            params.from = new Date(Facade.getUrlParam('from'));

        if (Facade.getUrlParam('to'))
            params.to = new Date(Facade.getUrlParam('to'));

        if (Facade.getUrlParam('current'))
            params.currentDay = new Date(Facade.getUrlParam('current'));

        return params;
    }

    getIsLoadingSliders() {
        return this.isloadingSlider;
    }

    getSliderSites(){
        return this.sliderSites;
    }

    getEvolution(){
        return this.evolution;
    }

    getParams() {
        return this.params;
    }

    setParams(params) {
        this.params = Object.assign({}, params);
    }

    setData(data) {
        this.sliderSites = data.sliderSites;
        this.evolution = data.evolution;
    }

    _registerToActions(action) {
        switch (action.actionType) {
        case Constants.SLIDER_SEARCH_START:
            this.setParams(action.value);
            this.isLoadingSlider = true;
            this._emitChange();
            break;
        case Constants.SLIDER_SEARCH_END:
            this.setData(action.value);
            this.firstLoad = false;
            this.isLoadingSlider = false;
            this._emitChange();
            break;
        case Constants.RESET_SLIDER_STORE:
            this._initStore();
            this.isLoading = true;
            this._emitChange();
            break;
        case Constants.USER_LOGOUT:
            this._initStore();
            this._emitChange();
            break;
        case Constants.ACCOUNT_CHANGE:
            this._initStore();
            this._emitChange();
            break;
        default:
            return false;
        }
    }
}

export default new SliderStore();
