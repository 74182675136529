// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import classNames from 'classnames';
// Locales
import translate from 'locales/i18n';
// Material UI
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import IconRefresh from '@mui/icons-material/Cached';
import Tooltip from '@mui/material/Tooltip';
// PCP Components
import DialogInfo from 'components/dialog/DialogInfo/DialogInfo';
// Images
import RepricedPricesGroupsFilterExample from 'assets/img/pricing-engine-examples/pricing-engine-repriced-prices-filter-example.png';
// Util
import { getOptions, createData } from 'components/filter/pricingEngine/RepricedPricesGroupsFilter/RepricedPricesGroupsFilter.util';
// Style
import { useStyles } from 'components/filter/pricingEngine/RepricedPricesGroupsFilter/RepricedPricesGroupsFilter.style';
// Theme
import { useTheme } from '@mui/styles';

const repricedPricesGroupsFilter = ({ isLoading, reset, selectRepricedPricesGroups, repricedPricesGroups }) => {
    const [dialogInfoOpen, setDialogInfoOpen] = useState(false);
    const classes = useStyles();
    const theme = useTheme();

    const backgroundColors = [theme.palette.graph.five, theme.palette.graph.four, theme.palette.graph.three, theme.palette.grey.light, theme.palette.rulesRanks.five];
    const count = repricedPricesGroups.reduce((c, group) => c + group.cardinality, 0);

    const renderDialogInfo = () => <DialogInfo open={dialogInfoOpen}
        close={() => setDialogInfoOpen(false)}>
        <div className={classes.infoMainText}>{translate.i18n('REPRICED_PRICES_GROUPS_EXPLANATION_1')}</div>
        <ul>
            <li className={classes.infoSubText}>{translate.i18n('REPRICED_PRICES_GROUPS_EXPLANATION_2')}</li>
        </ul>
        <div className={classes.infoImgContainer}><img src={RepricedPricesGroupsFilterExample} /></div>
    </DialogInfo>;

    return (
        <Paper className={classNames(classes.paper, isLoading && classes.notAllowed)}>
            <div className={classes.title}>
                {translate.i18n('DISTRIBUTION_OF_REPRICED_PRICES')}
            </div>
            {repricedPricesGroups.length !== 0 && <div className={classes.graphContainer}>
                <div className={classes.legendContainer}>
                    {repricedPricesGroups.map((group, key) =>
                        <Tooltip title={count > 0 ? `${translate.i18n(group.name)} : ${group.cardinality} (${((group.cardinality / count) * 100).toFixed(2)}%)` : translate.i18n(group.name)} arrow placement='top' key={key}>
                            <div className={classes.legend} onClick={() => selectRepricedPricesGroups(group.id)}>
                                <div className={classes.legendColor} style={{ backgroundColor: backgroundColors[key] }} />
                                {translate.i18n(group.name)}
                            </div>
                        </Tooltip>)}
                </div>
                <div className={classes.pieContainer}>
                    <Pie data={createData(repricedPricesGroups, theme)}
                        getElementAtEvent={element => {
                            if (element.length !== 0 && !isLoading) {
                                const [{ index }] = element;
                                selectRepricedPricesGroups(repricedPricesGroups[index].id);
                            }
                        }}
                        options={getOptions(count)}
                    />
                </div>
            </div>}
            <IconButton
                color='primary'
                onClick={reset}
                className={classes.resetButton}>
                <IconRefresh />
            </IconButton>
            <IconButton
                color='primary'
                onClick={() => setDialogInfoOpen(true)}
                className={classes.infoButton}>
                <InfoIcon />
            </IconButton>
            {renderDialogInfo()}
        </Paper>
    );
};

repricedPricesGroupsFilter.propTypes = {
    isLoading: PropTypes.bool,
    reset: PropTypes.func,
    selectRepricedPricesGroups: PropTypes.func,
    repricedPricesGroups: PropTypes.array,
};

export default repricedPricesGroupsFilter;

