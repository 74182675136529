// Locales
import translate from 'locales/i18n';
// Stores
import AccountStore from 'stores/AccountStore';

/**
  * Method called to get the resume of strategy
  *
  * @param strategy <object> the strategy object
  * @returns <string> the resume of strategy
  */
export const getResume = strategy => {
    if (!strategy) return '';

    const { filteringSiteIds, positioning, filteringStocks, rounding, lowLimitPrice, highLimitPrice, offset, type, element } = strategy;

    let resume = '';

    if (type === 'SELF_ORIENTED_STRATEGY') {
        resume = `${translate.i18n('I_WANT_TO_BE')} ${translate.i18n('SELF_ORIENTED_STRATEGY')} ${translate.i18n('BASED_ON_ELEM', { elem: translate.i18n(element) }) } ${translate.i18n('BY_VALUE', { value: `${offset.value} ${offset.mode === 'VALUE' ? AccountStore.getCurrentAccount().getCurrency() : '%'}` })}, ${rounding ? translate.i18n(rounding) : translate.i18n('TO_PRICE')}`;
        return resume;
    }

    // Positioning
    resume = `${translate.i18n('I_WANT_TO_BE')} ${positioning ? translate.i18n(positioning) : translate.i18n('MIN_PRICE')}`;

    // Offset
    resume += offset && offset.value !== 0 ? ` ${translate.i18n('BY_VALUE', { value: `${offset.value} ${offset.mode === 'VALUE' ? AccountStore.getCurrentAccount().getCurrency() : '%'}` })},` : '';

    // Competitors
    resume += filteringSiteIds ? `${translate.i18n('SOME_COMPETITORS')}, ` : `${translate.i18n('ALL_COMPETITORS')}, `;

    // Stocks
    resume += filteringStocks ? `${translate.i18n('SOME_STOCKS')}, ` : `${translate.i18n('ALL_STOCKS')}, `;

    // Low limit
    resume += !lowLimitPrice ? ` ${translate.i18n('WITHOUT_LOW_LIMIT')}, ` : `${translate.i18n('WITH_LOW_LIMIT')} ${lowLimitPrice.value < 0 ? '' : '+'}${lowLimitPrice.value}${lowLimitPrice.mode === 'PERCENTAGE' ? '%'
        : AccountStore.getCurrentAccount().getCurrency()} ${translate.i18n('OF_MY')} ${translate.i18n(lowLimitPrice.element)}, `;

    // High limit
    resume += !highLimitPrice ? ` ${translate.i18n('WITHOUT_HIGH_LIMIT')}, `
        : ` ${translate.i18n('WITH_HIGH_LIMIT')} ${highLimitPrice.value < 0 ? '' : '+'}${highLimitPrice.value}${highLimitPrice.mode === 'PERCENTAGE' ? '%' : AccountStore.getCurrentAccount().getCurrency()} ${translate.i18n('OF_MY')} ${translate.i18n(highLimitPrice.element)}, `;

    // Rounding
    resume += rounding ? translate.i18n(rounding) : translate.i18n('TO_PRICE');

    return resume;
};
