import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    graphContainer: {
        height: '82%',
        width: '100%',
        overflowY: 'scroll'
    },
    graphContent: {
        marginRight: '14px',
        width: 'calc(100% - 16px)'
    },
    infoButton: {
        padding: '5px',
        position: 'absolute',
        right: '6px',
        top: '6px',
        zIndex: 400
    },
    infoImgContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    infoMainText: {
        fontWeight: 500
    },
    infoSubText: {
        fontStyle: 'italic'
    },
    logoContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%'
    },
    notAllowed: {
        cursor: 'wait',
        opacity: 0.5
    },
    paper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    resetButton: {
        padding: '5px',
        position: 'absolute',
        right: '30px',
        top: '6px',
        zIndex: 400
    },
    title: {
        alignItems: 'center',
        color: theme.palette.grey.ultradark,
        display: 'flex',
        fontSize: '12px',
        fontWeight: '500',
        height: '22px',
        justifyContent: 'center',
        marginBottom: '8px',
        marginTop: '6px',
        textAlign: 'center'
    },
    legend: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '11.5px',
        fontWeight: '500',
        cursor: 'pointer'
    },
    legendColor: {
        backgroundColor: 'grey',
        height: '10px',
        marginRight: '5px',
        width: '10px'
    },
    legendContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginLeft: 5,
        width: '100%',
        paddingBottom: '7px'
    },
}));
