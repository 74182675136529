// Facade
import Facade from 'core/Facade';
// Locales
import translate from 'locales/i18n';

/**
  * Method called to create data needed for leadership filter
  *
  * @param websites <array<object>> the array of websites
  * @param currentAccount <Account> the current account
  * @param theme <object> the theme to get colors
  * @returns <array<object>> data to create leadership filter
  */
export const createData = (websites, currentAccount,theme) => {
    if (websites.length === 0)
        return null;

    const firstSet = [];
    const secondSet = [];
    const thirdSet = [];
    const forthSet = [];
    const fifthSet = [];
    const sixthSet = [];
    const labels = [];

    websites.forEach((website) => {
        if (currentAccount.getSiteId() === website.id && currentAccount.isRetailer()) {
            firstSet.push(website.pricesLeadership[5].cardinalities[0]);
            secondSet.push(website.pricesLeadership[4].cardinalities[0]);
            thirdSet.push(website.pricesLeadership[3].cardinalities[0]);
            forthSet.push(website.pricesLeadership[2].cardinalities[0]);
            fifthSet.push(website.pricesLeadership[1].cardinalities[0]);
            sixthSet.push(website.pricesLeadership[0].cardinalities[0]);
        } else {
            firstSet.push(website.pricesLeadership[1].cardinalities[0]);
            secondSet.push(website.pricesLeadership[2].cardinalities[0]);
            thirdSet.push(website.pricesLeadership[3].cardinalities[0]);
            forthSet.push(website.pricesLeadership[4].cardinalities[0]);
            fifthSet.push(website.pricesLeadership[5].cardinalities[0]);
            sixthSet.push(website.pricesLeadership[0].cardinalities[0]);
        }
        labels.push(Facade.siteDisplayName(website.name));
    });

    const data = {
        datasets: [{
            label: '.',
            backgroundColor: theme.palette.graph.five,
            borderColor: theme.palette.graph.five,
            data: firstSet,
            hoverBackgroundColor: theme.palette.graph.five,
            hoverBorderColor: theme.palette.graph.five,
        }, {
            label: '..',
            backgroundColor: theme.palette.graph.four,
            borderColor: theme.palette.graph.four,
            data: secondSet,
            hoverBackgroundColor: theme.palette.graph.four,
            hoverBorderColor: theme.palette.graph.four,
        }, {
            label: '...',
            backgroundColor: theme.palette.graph.three,
            borderColor: theme.palette.graph.three,
            data: thirdSet,
            hoverBackgroundColor: theme.palette.graph.three,
            hoverBorderColor: theme.palette.graph.three,
        }, {
            label: '....',
            backgroundColor: theme.palette.graph.two,
            borderColor: theme.palette.graph.two,
            data: forthSet,
            hoverBackgroundColor: theme.palette.graph.two,
            hoverBorderColor: theme.palette.graph.two,
        }, {
            label: '.....',
            backgroundColor: theme.palette.graph.one,
            borderColor: theme.palette.graph.one,
            data: fifthSet,
            hoverBackgroundColor: theme.palette.graph.one,
            hoverBorderColor: theme.palette.graph.one,
        }, {
            label: '......',
            backgroundColor: theme.palette.grey.main,
            borderColor: theme.palette.grey.main,
            data: sixthSet,
            hoverBackgroundColor: theme.palette.grey.main,
            hoverBorderColor: theme.palette.grey.main,
        }],
        labels
    };
    return data;
};

/**
 * Method called to get all needed options of leadership filter
 */
export const getOptions = (currentAccount, websites) => ({
    maintainAspectRatio: false,
    borderRadius: 2,
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: tooltipItem => {
                    let index = -1;
                    index = currentAccount.isRetailer() ? websites.findIndex(website => currentAccount.getSiteId() === website.id) : -1;
                    if (tooltipItem.dataIndex === index) {
                        if (tooltipItem.datasetIndex === 0) return ' ' + tooltipItem.formattedValue + ' ' + translate.i18n('MOST_EXPENSIVE');
                        else if (tooltipItem.datasetIndex === 1) return ' ' + tooltipItem.formattedValue + ' ' + translate.i18n('AMONG_MOST_EXPENSIVE');
                        else if (tooltipItem.datasetIndex === 2) return ' ' + tooltipItem.formattedValue + ' ' + translate.i18n('NEITHER_MOST_EXPENSIVE_NOR_CHEAPER');
                        else if (tooltipItem.datasetIndex === 3) return ' ' + tooltipItem.formattedValue + ' ' + translate.i18n('AMONG_CHEAPEST');
                        else if (tooltipItem.datasetIndex === 4) return ' ' + tooltipItem.formattedValue + ' ' + translate.i18n('STRICTLY_CHEAPEST');
                        else if (tooltipItem.datasetIndex === 5) return ' ' + tooltipItem.formattedValue + ' ' + translate.i18n('ALONE_SELLER');
                    } else {
                        if (tooltipItem.datasetIndex === 4) return ' ' + tooltipItem.formattedValue + ' ' + translate.i18n('MOST_EXPENSIVE');
                        else if (tooltipItem.datasetIndex === 3) return ' ' + tooltipItem.formattedValue + ' ' + translate.i18n('AMONG_MOST_EXPENSIVE');
                        else if (tooltipItem.datasetIndex === 2) return ' ' + tooltipItem.formattedValue + ' ' + translate.i18n('NEITHER_MOST_EXPENSIVE_NOR_CHEAPER');
                        else if (tooltipItem.datasetIndex === 1) return ' ' + tooltipItem.formattedValue + ' ' + translate.i18n('AMONG_CHEAPEST');
                        else if (tooltipItem.datasetIndex === 0) return ' ' + tooltipItem.formattedValue + ' ' + translate.i18n('STRICTLY_CHEAPEST');
                        else if (tooltipItem.datasetIndex === 5) return ' ' + tooltipItem.formattedValue + ' ' + translate.i18n('ALONE_SELLER');
                    }

                    return tooltipItem;
                }
            }
        },
        title: {
            display: true,
            fontSize: 12,
            text: translate.i18n('LEADERSHIP_TITLE'),
            padding: {
                bottom: 15,
                top: 8
            }
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            stacked: true,
            ticks: {
                display: false
            }
        },
        y: {
            grid: {
                display: false,
                drawBorder: false
            },
            stacked: true,
            ticks: {
                display: false
            }
        }
    },
});
