import HttpService from 'services/HttpService';

/**
 * Services Base Class
 */
class AbstractService {

    /**
     *
     * Perform an HTTP call
     *
     */
    request(options) {
        return HttpService.request(options);
    }

}

export default AbstractService;
