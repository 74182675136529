import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    priceSectionContainer: {
        backgroundColor: theme.palette.white.main,
        borderRadius: '5px',
        boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.37)',
        margin: '10px',
        padding: '10px 10px 20px 10px'
    },
    chartSectionFullScreen: {
        backgroundColor: theme.palette.white.main,
        borderRadius: '5px',
        boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.37)',
        height: '98vh',
        left: '0.5vh',
        padding: '10px',
        position: 'absolute',
        top: '0.5vw',
        width: '99vw',
        zIndex: 9999
    },
    chartSection: {
        backgroundColor: theme.palette.white.main,
        borderRadius: '5px',
        boxShadow: '0px 5px 8px 0px rgba(0,0,0,0.37)',
        height: '100%',
        margin: '10px 10px 0px 10px',
        padding: '10px'
    },
    contentTitleContainer: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center'
    },
    contentTitle: {
        color: '#0E5562',
        fontSize: '20px',
        fontWeight: '500',
        paddingRight: '10px'
    },
}));
