import React from 'react';
import PropTypes from 'prop-types';
//Styles
import { useStyles } from 'components/sheet/SuggestionSheet/SuggestionSheet.style';
import { useTheme } from '@mui/styles';
//Material components
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    Tooltip
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveIcon from '@mui/icons-material/Save';
//Pcp components
import ImageViewer from 'components/commons/ImageViewer/ImageViewer';
// Locales
import translate from 'locales/i18n';
//Facade
import Facade from 'core/Facade';

const SuggestionSheet = ({ suggestion, addUrlToConfig, displayPrice, isSelected, displayCategories, changeStatus }) => {

    const classes = useStyles();
    const theme = useTheme();

    return (
        <Card className={classes.card} style={isSelected ? { boxShadow: '0 0 5px 2px' + theme.palette.primary.main } : {}}>
            <CardMedia className={classes.cardImage}>
                <ImageViewer imageUrl={suggestion.imgUrl}/>
            </CardMedia>
            <CardContent className={classes.cardContent}>
                <Grid container className={classes.infoContainer} justifyContent='space-between' direction='column'>
                    {suggestion.name.length > 60 ?
                        <Tooltip
                            title={suggestion.name}
                            placement='top'
                            disableInteractive
                            enterDelay={500}
                            classes={{
                                tooltip: classes.categoryTooltip
                            }}>
                            <Grid item className={classes.productName}>
                                {suggestion.name}
                            </Grid>
                        </Tooltip>
                        :
                        <Grid item className={classes.productName}>
                            {suggestion.name}
                        </Grid>
                    }
                    <Grid container>
                        <Grid item xs={5} className={classes.productInfo}>
                            {translate.i18n('DIALOG_ADD_URL.BRAND')} :
                        </Grid>
                        {suggestion.brand ?
                            <Tooltip
                                title={suggestion.brand}
                                placement='top'
                                disableInteractive
                                classes={{
                                    tooltip: classes.categoryTooltip
                                }}>
                                <Grid item xs={5} className={classes.hideOverflow}>
                                    {suggestion.brand}
                                </Grid>
                            </Tooltip>
                            :
                            <Grid item xs={5}>
                                -
                            </Grid>
                        }
                        <Grid item xs={5} className={classes.productInfo}>
                            {translate.i18n('DIALOG_ADD_URL.PRICE')} :
                        </Grid>
                        <Grid item xs={5} className={classes.productInfo}>
                            {suggestion.price ? displayPrice(suggestion.price) : ' - '}
                        </Grid>
                        <Grid item xs={5} className={classes.productInfo}>
                            {translate.i18n('DIALOG_ADD_URL.CATEGORY')} :
                        </Grid>
                        {suggestion.categories ?
                            <Tooltip
                                title={displayCategories(suggestion.categories)}
                                placement='top'
                                disableInteractive
                                classes={{
                                    tooltip: classes.categoryTooltip
                                }}>
                                <Grid item xs={5} className={classes.hideOverflow}>
                                    {suggestion.categories[0].split('>').at(-1)}
                                </Grid>
                            </Tooltip>
                            :
                            <Grid item xs={5}>
                                -
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <Tooltip title={translate.i18n('DIALOG_ADD_URL.FINISH_CONFIG')} placement='top' disableInteractive>
                        <Button onClick={() => addUrlToConfig(suggestion.url)} style={{ width: '60%' }}>
                            {translate.i18n('DIALOG_ADD_URL.SELECT')}
                        </Button>
                    </Tooltip>
                    <Tooltip title={translate.i18n('DIALOG_ADD_URL.SAVE_WOUT_CONF')} placement='top'>
                        <Button
                            id='save'
                            color='primary'
                            variant='contained'
                            size='small'
                            onClick={() => {
                                changeStatus('SOLD', { url: suggestion.url });
                            }}
                            className={classes.margin}>
                            <SaveIcon />
                        </Button>
                    </Tooltip>
                </div>
                <Tooltip title={translate.i18n('DIALOG_ADD_URL.SEE_ON_SITE')} disableInteractive>
                    <IconButton className={classes.openInNewIcon} onClick={() => Facade.openUrl(suggestion.url)}>
                        <OpenInNewIcon />
                    </IconButton>
                </Tooltip>
            </CardContent>
        </Card>
    );
};

SuggestionSheet.propTypes = {
    suggestion: PropTypes.object,
    addUrlToConfig: PropTypes.func,
    displayPrice: PropTypes.func,
    isSelected: PropTypes.bool,
    displayCategories: PropTypes.func,
    changeStatus: PropTypes.func
};

export default SuggestionSheet;
