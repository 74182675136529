// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactToPrint from 'react-to-print';
// PCP Components
import ToPrint from 'components/commons/ToPrint/ToPrint';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
// Locales
import translate from 'locales/i18n';
// Images
import NoImage from 'assets/img/no-image.png';
// Style
import { useStyles } from 'components/dialog/DialogImage/DialogImage.style';

const DialogImage = ({ alt, close, imageUrl, legend, open, title }) => {
    const [componentRef, setComponentRef] = useState(null);

    const classes = useStyles();

    if (!imageUrl || !open)
        return null;

    return (
        <Dialog
            fullScreen={false}
            open={open}
            onClose={close}
            maxWidth={'md'}
            disableRestoreFocus={true}
            aria-labelledby='responsive-dialog-title'
        >
            <IconButton
                color='secondary'
                onClick={() => {
                    close();
                }}
                className={classes.closeButton}
                size='large'>
                <ClearIcon />
            </IconButton>
            <DialogTitle id='responsive-dialog-title'>
                <span className={classes.titleDialogBox}>
                    {legend}
                </span>
            </DialogTitle>
            <DialogContent className={classNames(classes.dialogContentNotFullScreen, classes.dialogContent)}>
                <img src={imageUrl}
                    onError={(e) => {
                        e.target.onerror = null; e.target.src = NoImage;
                    }}
                    alt={alt}
                    title={title}
                    className={classes.slideImage} />
            </DialogContent>
            <ReactToPrint
                trigger={() =>
                    <Button variant='contained' color='primary' className={classes.button} size='large'>
                        {translate.i18n('PRINT')}
                    </Button>
                }
                content={() => componentRef}
            />
            <div className={classes.toPrintContainer}>
                <ToPrint imageUrl={imageUrl} alt={alt} title={title} classes={classes} ref={el => setComponentRef(el)} />
            </div>
        </Dialog>
    );
};

DialogImage.propTypes = {
    alt: PropTypes.string,
    close: PropTypes.func,
    imageUrl: PropTypes.string,
    legend: PropTypes.string,
    open: PropTypes.bool,
    title: PropTypes.string
};

export default DialogImage;

