import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    dialog: {
        height: '60px',
        width: '60px'
    },
    dialogContent: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        padding: '0px'
    }
}));
