class Performance {

    constructor(data) {
        this.date = data.date;
        this.count = data.nb_commande;
    }

}

export default Performance;
