import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    graphContainer: {
        display: 'flex',
        height: '280px',
        margin: 0,
        padding: 0,
        width: '100%'
    },
    iconReset: {
        position: 'absolute',
        right: '10px'
    },
    paper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        Height: '280px',
        position: 'relative',
        width: '100%'
    },
    totalMails: {
        position: 'absolute',
        bottom: '10px',
        right: '10px'
    }
}));
