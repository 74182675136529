// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Styles
import { useStyles } from 'components/cell/PricingEngineCell/PricingEngineCell.style';
// Material ui
import { List, ListItemText, TableCell } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EqualIcon from '@mui/icons-material/DragHandle';
// Locales
import translate from 'locales/i18n';
// PCP Components
import DialogInfo from 'components/dialog/DialogInfo/DialogInfo';
// Stores
import AccountStore from 'stores/AccountStore';
import RuleAction from 'actions/RuleAction';
import DialogLoading from 'components/dialog/DialogLoading/DialogLoading';
// Util
import { getResume, displayPrice, displayDate, displayPriceString } from 'components/cell/PricingEngineCell/PricingEngineCell.util';
//Theme
import { useTheme } from '@mui/styles';

const PricingEngineCell = ({ product, originalScrapingData, blur }) => {
    const [dialogInfoOpen, setDialogInfoOpen] = useState(false);
    const [loadingRule, setLoadingRule] = useState(false);
    const [rules, setRules] = useState(null);
    const classes = useStyles();
    const theme = useTheme();
    const { pricingEngine, name } = product;

    async function getAllRules() {
        setLoadingRule(true);
        const fullRules = [];
        for (const r of pricingEngine.rulesApplication) {
            const rule = await RuleAction.getRule(r.id);
            if (r.error) {
                rule.error = r.error;
            } else {
                rule.result = r.result;
            }
            fullRules.push(rule);
        }
        setRules(fullRules);
    }

    const renderDialogLoading = () => <DialogLoading open={loadingRule} />;

    const renderDialogInfo = () => <DialogInfo
        close={() => setDialogInfoOpen(false)}
        open={dialogInfoOpen && !loadingRule}
        title={name}
        image={product.imgUrl}>
        {pricingEngine ?
            <List>
                {rules && rules.map((rule, index) =>
                    <div key={rule.id} style={ rule.error && rule.error === 'PE_ERR_RULE_SKIPPED_VALUE'? { color: 'grey' }:null }>
                        <ListItemText classes={{ primary: classes.rulePopperRank }}> <strong>{translate.i18n('RANK') + ' #' + (index + 1)} : </strong> { rule.name } </ListItemText>
                        <List>
                            <ListItemText className={classes.rulePopperInfo}> <strong> {translate.i18n('STRATEGY') + ' : '} </strong>  { getResume(rule.strategy) }</ListItemText>
                            <ListItemText className={classes.rulePopperInfo}> <strong> {translate.i18n('START_DATE') + ' : '} </strong> { (rule.applyFrom ? displayDate(rule.applyFrom) : translate.i18n('RULE_APPLICATION_POPPER.NONE')) }</ListItemText>
                            <ListItemText className={classes.rulePopperInfo}><strong> {translate.i18n('END_DATE') + ' : '} </strong> { (rule.applyUntil ? displayDate(rule.applyUntil) : translate.i18n('RULE_APPLICATION_POPPER.NONE')) }</ListItemText>
                            {rule.error ?
                                <ListItemText className={classes.rulePopperInfo} style={rule.error === 'PE_ERR_RULE_SKIPPED_VALUE'? null : { color: 'red' }}> <strong> {translate.i18n('RULE_APPLICATION_POPPER.RULE_NOT_APPLICABLE') + ' : '} </strong> {translate.i18n('RULE_APPLICATION_POPPER.'+rule.error)} </ListItemText>
                                :
                                <div key='appliedRule'>
                                    <ListItemText className={classes.rulePopperInfo} classes={{ primary: classes.bluetext }}> <strong> {translate.i18n('RULE_APPLICATION_POPPER.RULE_APPLIED') + ' : '} </strong> {renderEvolutionIcon(true)} {pricingEngine.repricedPrice + AccountStore.getCurrentAccount().getCurrency() }</ListItemText>
                                    <List className={classes.appliedRuleList}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>{ translate.i18n('MINIMUM_SALE_PRICE') + ' : '}</td>
                                                    {console.log(rule, pricingEngine)}
                                                    <td className={classes.priceCell}>{ pricingEngine.rulesApplication[index].min_price ? pricingEngine.rulesApplication[index].min_price + AccountStore.getCurrentAccount().getCurrency() : ' - ' }</td>
                                                </tr>
                                                <tr>
                                                    <td>{ translate.i18n('MAXIMUM_SALE_PRICE') + ' : '}</td>
                                                    <td className={classes.priceCell}>{ pricingEngine.rulesApplication[index].max_price ? pricingEngine.rulesApplication[index].max_price + AccountStore.getCurrentAccount().getCurrency(): ' - ' }</td>
                                                </tr>
                                                <tr>
                                                    <td>{ translate.i18n('RULE_APPLICATION_POPPER.STRATEGY_PRICE') + ' : '}</td>
                                                    <td className={classes.priceCell}>{ displayPriceString(rule.result) + AccountStore.getCurrentAccount().getCurrency()}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan='2' style={{ borderBottom: 'solid 1px grey' }}></td>
                                                </tr>
                                                <tr>
                                                    <td>{ translate.i18n('RULE_APPLICATION_POPPER.FINAL_REPRICED_PRICE') + ' : '}</td>
                                                    <td className={classes.priceCell}>{ displayPriceString(pricingEngine.repricedPrice) + AccountStore.getCurrentAccount().getCurrency()}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </List>
                                </div>
                            }
                        </List>
                    </div>
                )}
            </List>
            :
            <div className={classes.alignText}>
                <InfoIcon className={classes.icon} style={{ color: theme.palette.primary.dark }}/><span className={classes.boldText}>{translate.i18n('NO_RULE_ASSOCIATED')}</span>
            </div>
        }
    </DialogInfo>;

    const renderEvolutionIcon = (onDialog) => {
        const Icon = pricingEngine.repricedPrice !== originalScrapingData.scrapedData.price ? pricingEngine.repricedPrice < originalScrapingData.scrapedData.price ? ArrowDownwardIcon : ArrowUpwardIcon : EqualIcon;
        return <Icon fontSize={'small'}
            style={{ transform: pricingEngine.repricedPrice !== originalScrapingData.scrapedData.price ? pricingEngine.repricedPrice < originalScrapingData.scrapedData.price ? 'rotate(-45deg)' : 'rotate(45deg)' : undefined }}
            className={onDialog ? classes.evolutionIconDialog : classes.evolutionIcon} />;
    };

    if (!pricingEngine) return <TableCell className={classes.tableCell}>
        <div className={classes.tableCellContentNoRule} onClick={() => setDialogInfoOpen(true)}>
            <InfoIcon />
        </div>
        {renderDialogInfo()}
    </TableCell>;

    if (!pricingEngine.repricedPrice) return <TableCell className={classes.tableCell}>
        <div className={classes.tableCellContentError} onClick={() => {
            getAllRules().then(() => setLoadingRule(false));
            setDialogInfoOpen(true);
        }}>
            <ErrorIcon />
        </div>
        {renderDialogInfo()}
        {renderDialogLoading()}
    </TableCell>;

    return <TableCell className={classes.tableCell} >
        <div className={classes.tableCellContentSuccess} onClick={() => {
            getAllRules().then(() => setLoadingRule(false));
            setDialogInfoOpen(true);
        }}>
            {renderEvolutionIcon()}
            {`${displayPriceString(pricingEngine.repricedPrice)} ${AccountStore.getCurrentAccount().getCurrency()}`}
        </div>
        <div className={classes.newMargin}>
            {translate.i18n('MARGIN') + ':'} &nbsp;  <div style={ blur? { filter: 'blur(0.2rem)' }:null }>{product.purchasingPrice? Math.round((( pricingEngine.repricedPrice / product.purchasingPrice ) - 1) * 10000) / 100:'N/A' } </div> %
        </div>
        {renderDialogInfo()}
        {renderDialogLoading()}
    </TableCell>;
};

PricingEngineCell.propTypes = {
    originalScrapingData: PropTypes.object,
    product: PropTypes.object,
    blur: PropTypes.bool
};

export default PricingEngineCell;
