// React
import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
// Actions
import AlertAction from 'actions/AlertAction';
// PCP Components
import MuiAlert from '@mui/material/Alert';

const AlertContent = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const Alert = ({ open, position, text, type }) => {
    const [vertical, horizontal] = position.split('-');

    return (
        <Snackbar open={open}
            autoHideDuration={3000}
            onClose={() => AlertAction.close()}
            anchorOrigin={{
                horizontal: horizontal ? horizontal : 'right',
                vertical: vertical ? vertical : 'top'
            }}>
            <AlertContent onClose={() => AlertAction.close()} severity={type} sx={{ width: '100%' }}>
                {text}
            </AlertContent>
        </Snackbar>
    );
};

Alert.propTypes = {
    open: PropTypes.bool,
    position: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string
};

export default Alert;
