import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles( theme => ({
    categoryName: {
        fontSize: '14px',
        fontWeight: 500
    },
    categoryNameContainer: {
        alignItems: 'center',
        backgroundColor: theme.palette.grey.light,
        display: 'flex',
        flexDirection: 'row',
        margin: 0
    },
    fullContainer: {
        border: '1px solid' + theme.palette.grey.main,
        borderRadius: '3px',
        height: 'calc(100% - 33px)',
        overflow: 'auto',
        width: '100%'
    },
    imageContainer: {
        height: '40px',
        width: '40px'
    },
    itemContainer: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: '4px',
        marginTop: '4px'
    },
    itemInfo: {
        color: theme.palette.grey.ultradark,
        marginLeft: '4px'
    },
    itemName: {
        display: 'flex',
        fontSize: '14px',
        fontWeight: 500,
        marginLeft: '4px'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0px 5px 5px 5px',
        width: '100%'
    },
    searchItems: {
        backgroundColor: '#f7f7f7cc',
        marginLeft: 'auto',
        marginRight: '15px',
        width: '30%'
    },
    selectRank: {
        height: '35px',
        marginLeft: 'auto',
        marginRight: '5%',
        width: '75px'
    },
    title: {
        alignItems: 'center',
        color: theme.palette.primary.main,
        display: 'flex',
        fontSize: '17px',
        fontWeight: 500,
        marginLeft: '10px'
    },
    titleBar: {
        backgroundColor: theme.palette.primary.main,
        flexGrow: 1,
        height: '2px',
        marginLeft: '16px'
    }
}));

