const styles = theme => ({
    categoryContainer: {
        display: 'flex',
        height: '100%',
        position: 'relative',
        width: '50%'
    },
    categoryLabel: {
        marginLeft: '12px',
        marginRight: '12px',
        overflow: 'hidden',
        paddingLeft: 0,
        paddingRight: 0,
        width: '100%'
    },
    chip: {
        backgroundColor: theme.palette.white.dark,
        cursor: 'pointer',
        height: '70%',
        opacity: 0.8,
        maxWidth: '75%'
    },
    chipContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 0,
    },
    tooltipContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
    },
    tooltipZone: {
        width: '50%',
        height: '100%',
    },
    categoryLeft: {
        borderBottomLeftRadius: '3px',
        borderTopLeftRadius: '3px',
        height: '100%',
        position: 'absolute',
        right: 0
    },
    categoryRight: {
        borderBottomRightRadius: '3px',
        borderTopRightRadius: '3px',
        height: '100%'
    },
    categoryTextContainer: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        height: '100%',
        paddingRight: '2%',
        position: 'absolute',
        width: '100%'
    },
    divider: {
        width: '2px'
    },
    hoverObserver: {
        display: 'flex',
        height: '24px',
        marginBottom: '2px',
        marginLeft: '5%',
        position: 'relative',
        width: '90%'
    },
    notAllowedCategory: {
        cursor: 'wait'
    },
    ratioLabel: {
        fontSize: '12px'
    },
    tooltipLabel: {
        fontSize: '12px',
        marginBottom: '2px',
        marginTop: '2px'
    }
});

export default styles;
