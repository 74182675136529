// Constants
import Constants from 'constants.js';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Locales
import translate from 'locales/i18n';
// Services
import SuggestionService from 'services/SuggestionService';
// Actions
import AlertAction from 'actions/AlertAction';
// Entities
import Suggestion from 'core/entities/SuggestionObject';

class SuggestionAction {

    /**
      * Method called to get suggestions
      *
      * @param accountId <string> the current account id
      * @param itemId <string> the item id
      * @param siteId <string> the site id
      * @param itemName <string> the itemName
      */
    getSuggestions(accountId, itemId, siteId, page, size) {
        return SuggestionService.getSuggestions(accountId, itemId, siteId, page, size).then(
            suggestions => suggestions.map(suggestion => new Suggestion(suggestion)),
            response => {
                AlertAction.open('error', translate.i18n(response && response.error ? response.error : 'ERROR_RETRIEVE_SUGGESTIONS'), 'top-right');
                throw response;
            }
        );
    }

    /**
      * Method called to get suggestions
      *
      * @param accountId <string> the current account id
      * @param siteId <string> the site id
      * @param page <int> the page index
      * @param size <int> the page size
      * @param searchText <string> the search text
      */
    searchSuggestions(accountId, siteId, page, size, searchText) {
        return SuggestionService.searchSuggestions(accountId, siteId, page, size, searchText).then(
            suggestions => suggestions.map(suggestion => new Suggestion(suggestion)),
            response => {
                AlertAction.open('error', translate.i18n(response && response.error ? response.error : 'ERROR_RETRIEVE_SUGGESTIONS'), 'top-right');
                throw response;
            }
        );
    }

    /**
      * Method called to clean suggestions
      **/
    cleanSuggestion() {
        Dispatcher.dispatch({
            actionType: Constants.SUGGESTION_CLEAN,
        });
    }
}

export default new SuggestionAction();
