// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import classNames from 'classnames';
// Facade
import Facade from 'core/Facade';
// Locales
import translate from 'locales/i18n';
// Stores
import AccountStore from 'stores/AccountStore';
import UserStore from 'stores/UserStore';
import LayoutStore from 'stores/LayoutStore';
// Material UI Components
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import IconRefresh from '@mui/icons-material/Cached';
// PCP Components
import DialogInfo from 'components/dialog/DialogInfo/DialogInfo';
// Images
import LeadershipFilterExample from 'assets/img/pricing-engine-examples/pricing-engine-leadership-filter.png';
// Util
import { getOptions, createData } from 'components/filter/pricingEngine/LeadershipFilter/LeadershipFilter.util';
// Style
import { useStyles } from 'components/filter/pricingEngine/LeadershipFilter/LeadershipFilter.style';
import { useTheme } from '@mui/styles';
// Chart plugin
import ChartDataLabels from 'chartjs-plugin-datalabels';

const LeadershipFilter = React.memo(({ isLoading, reset, selectLeadership, websites }) => {
    const [dialogInfoOpen, setDialogInfoOpen] = useState(false);
    const classes = useStyles();
    const theme = useTheme();

    const [blur, setBlur] = useState(UserStore.getCurrentUser().isSuperUser() ? LayoutStore.getBlur() : false);

    function onLayoutStoreChange() {
        setBlur(LayoutStore.getBlur());
    }

    useEffect(() => {
        UserStore.getCurrentUser().isSuperUser() ? LayoutStore.addChangeListener(onLayoutStoreChange) : null;
        return () => UserStore.getCurrentUser().isSuperUser() ? LayoutStore.removeChangeListener(onLayoutStoreChange) : null;
    }, []);

    const clickAll = websiteId => {
        const leaderships = [];

        websites[0].pricesLeaderships.forEach(pl => {
            if (pl.cardinalities.current !== 0) leaderships.push([pl.id, websiteId]);
        });

        selectLeadership(leaderships);
    };

    const renderLogoList = () => websites.map((website, key) => {
        let total = 0;
        for (let i = 0; i < 6; i++)
            total += website.pricesLeaderships[i].cardinalities.current;

        return <img
            key={key}
            src={`https://img.pricecomparator.pro/sites_images/${website.name}/1`} onError={(e) => {
                e.target.onerror = null; e.target.src = `https://www.google.com/s2/favicons?domain=${website.url}`;
            }}
            alt={Facade.siteDisplayName(website.name)}
            height={15}
            title={website.name + ' : ' + total + ' ' + translate.i18n('ITEMS')}
            onClick={() => clickAll(website.id)}
            className={classes.logo} />;
    });

    const renderDialogInfo = () => <DialogInfo open={dialogInfoOpen}
        close={() => setDialogInfoOpen(false)}>
        <div className={classes.infoMainText}>{translate.i18n('LEADERSHIP_FILTER_PRICING_ENGINE')}</div>
        <ul>
            <li className={classes.infoSubText}>{translate.i18n('LEADERSHIP_FILTER_2')}</li>
        </ul>
        <div className={classes.infoImgContainer}><img src={LeadershipFilterExample} /></div>
    </DialogInfo>;

    if (websites.length === 0)
        return (
            <Paper className={classNames(classes.paper, isLoading && classes.notAllowed)}>
            </Paper>
        );

    const data = createData(websites, theme);

    return (
        <Paper className={classNames(classes.paper, isLoading && classes.notAllowed)}>
            <div className={classes.graphContainer}>
                <Bar
                    plugins={[ChartDataLabels]}
                    data={data}
                    getElementAtEvent={element => {
                        if (element.length !== 0 && !isLoading) {
                            const index = element[0].datasetIndex >= 7 ? 12 - element[0].datasetIndex : 5 - element[0].datasetIndex;
                            selectLeadership(
                                websites[0].pricesLeaderships[index].id,
                                websites[element[0].index].id,
                                element[0].datasetIndex >= 6 ? 'withRepricing' : 'current'
                            );
                        }
                    }}
                    options={getOptions(AccountStore.getCurrentAccount(), websites)}
                />
            </div>
            <div className={classes.logoContainer} style={blur ? { filter: 'blur(0.2rem)' } : null}>{renderLogoList()}</div>
            <IconButton
                color='primary'
                onClick={reset}
                className={classes.resetButton}>
                <IconRefresh />
            </IconButton>
            <IconButton
                color='primary'
                onClick={() => setDialogInfoOpen(true)}
                className={classes.infoButton}>
                <InfoIcon />
            </IconButton>
            {renderDialogInfo()}
        </Paper>
    );
}, (prevProps, nextProps) => {
    if (prevProps.isLoading !== nextProps.isLoading || prevProps.websites !== nextProps.websites) {
        return false;
    }
    return true;
});

LeadershipFilter.propTypes = {
    isLoading: PropTypes.bool,
    reset: PropTypes.func,
    selectLeadership: PropTypes.func,
    websites: PropTypes.array
};

export default LeadershipFilter;
