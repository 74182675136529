import React from 'react';
import PropTypes from 'prop-types';
//Mui
import { List, ListItem, ListItemText, Paper, Popper } from '@mui/material';
// i18n
import translate from 'locales/i18n';
//Utils
import { displayPrice, displayDate } from './RuleInfoPopper.util';
import { getResume } from 'components/sheet/RuleSheet/RuleSheet.util';
//Style
import { useStyles } from './RuleInfoPopper.style';

const RuleInfoPopper = ({ rule, hover, popperAnchor, mwidth }) => {

    const classes = useStyles();

    return (
        <>
            {rule &&
            <Popper open={hover} anchorEl={popperAnchor} className={classes.infoListContainer} sx={{ width: mwidth }} >
                <Paper elevation={4}>
                    <List>
                        <ListItem key='title'>
                            <ListItemText classes={{ primary: classes.title }} primary={rule.name} />
                        </ListItem>
                        <ListItem
                            key='description'>
                            <ListItemText classes={{ primary: classes.description }} primary={ translate.i18n('STRATEGY') + ' : ' + getResume(rule.strategy) }/>
                        </ListItem>
                        <ListItem
                            key='minPrice'>
                            <ListItemText primary={ translate.i18n('MINIMUM_SALE_PRICE') + ' : ' + (rule.minPrice ? displayPrice(rule.minPrice) : ' - ')}/>
                        </ListItem>
                        <ListItem
                            key='maxPrice'>
                            <ListItemText primary={ translate.i18n('MAXIMUM_SALE_PRICE') + ' : ' + (rule.maxPrice ? displayPrice(rule.maxPrice) : ' - ' )}/>
                        </ListItem>
                        <ListItem
                            key='startDate'>
                            <ListItemText primary={ translate.i18n('START_DATE') + ' : ' + (rule.applyFrom ? displayDate(rule.applyFrom) : ' - ')}/>
                        </ListItem>
                        <ListItem
                            key='endDate'>
                            <ListItemText primary={ translate.i18n('END_DATE') + ' : ' + (rule.applyUntil ? displayDate(rule.applyUntil) : ' -')}/>
                        </ListItem>
                    </List>
                </Paper>
            </Popper>
            }
        </>
    );
};

RuleInfoPopper.propTypes = {
    rule: PropTypes.object,
    hover: PropTypes.bool,
    popperAnchor: PropTypes.object,
    mwidth: PropTypes.number
};

export default RuleInfoPopper;
