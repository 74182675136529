import Facade from 'core/Facade';

/**
  * Method to add a params in url query
  *
  * @param key <string>
  * @param val <string>
  * @returns <boolean> true if it succeed false if not
  */
export const addToUrl = (key, val) => {
    if (key && val) {
        Facade.setUrlParam(key, val);
        return true;
    }

    return false;
};
