// Stores
import UserStore from 'stores/UserStore';
// Facade
import Facade from 'core/Facade';
// History
import history from 'history.js';

class AccountAction {
    // TO FIX : find a better way to change account
    switchAccount(accountId) {
        const account = UserStore.getCurrentUser().getAccounts().filter(a => a.id === accountId);
        localStorage.setItem('pricecomparator_account', account[0].id);

        // Delete all params in url when a user change account
        Facade.deleteAllUrlParams();

        // Go to price module
        history.push('/smart-price-extractor');

        // Reload page
        window.location.reload();
    }
}

export default new AccountAction();
