// Constants between Actions and stores
const Constants = {
    ACCOUNT_CHANGE: 'ACCOUNT_CHANGE',
    ADBOX_SEARCH_END: 'ADBOX_SEARCH_END',
    ADBOX_SEARCH_START: 'ADBOX_SEARCH_START',
    ALERT_CLOSE: 'ALERT_CLOSE',
    ALERT_OPEN: 'ALERT_OPEN',
    ATTRIBUTE_NAMES_GET_END: 'ATTRIBUTE_NAMES_GET_END',
    ATTRIBUTE_NAMES_GET_START: 'ATTRIBUTE_NAMES_GET_START',
    ATTRIBUTE_VALUES_GET_END: 'ATTRIBUTE_VALUES_GET_END',
    ATTRIBUTE_VALUES_GET_START: 'ATTRIBUTE_VALUES_GET_START',
    CATEGORIES_GET_END: 'CATEGORIES_GET_END',
    CATEGORIES_GET_START: 'CATEGORIES_GET_START',
    CATEGORY_ADD: 'CATEGORY_ADD',
    CATEGORY_DELETE: 'CATEGORY_DELETE',
    CATEGORY_EDIT: 'CATEGORY_EDIT',
    CHANGE_COLOR_THEME: 'CHANGE_COLOR_THEME',
    CLEAN_LOCATION_REDIRECT: 'CLEAN_LOCATION_REDIRECT',
    FETCH_ITEM_HISTORY_END: 'FETCH_ITEM_HISTORY_END',
    FETCH_ITEM_HISTORY_START: 'FETCH_ITEM_HISTORY_START',
    ITEM_EXPORT_END: 'ITEM_EXPORT_END',
    ITEM_EXPORT_START: 'ITEM_EXPORT_START',
    ITEM_SEARCH_END: 'ITEM_SEARCH_END',
    ITEM_SEARCH_START: 'ITEM_SEARCH_START',
    ITEM_START_UPDATE_SCRAPED_DATA: 'ITEM_START_UPDATE_SCRAPED_DATA',
    ITEM_START_UPDATE_SCRAPING_DATA: 'ITEM_START_UPDATE_SCRAPING_DATA',
    ITEM_UPDATE_DATA_FAILED: 'ITEM_UPDATE_DATA_FAILED',
    ITEM_UPDATE_SCRAPED_DATA: 'ITEM_UPDATE_SCRAPED_DATA',
    ITEM_UPDATE_SCRAPING_DATA: 'ITEM_UPDATE_SCRAPING_DATA',
    PERFORMANCE_SEARCH_END: 'PERFORMANCE_SEARCH_END',
    PERFORMANCE_SEARCH_START: 'PERFORMANCE_SEARCH_START',
    PRICING_ENGINE_DELETE_RULE: 'PRICING_ENGINE_DELETE_RULE',
    PRICING_ENGINE_GET_RULES: 'PRICING_ENGINE_GET_RULES',
    PRODUCT_DELETED: 'PRODUCT_DELETED',
    PRODUCT_GET_END: 'PRODUCT_GET_END',
    PRODUCT_GET_END_ERROR: 'PRODUCT_GET_END_ERROR',
    PRODUCT_GET_START: 'PRODUCT_GET_START',
    REPRICED_ITEMS_GET_END: 'REPRICED_ITEMS_GET_END',
    REPRICED_ITEMS_GET_START: 'REPRICED_ITEMS_GET_START',
    RESET_ITEM_STORE: 'RESET_ITEM_STORE',
    RESET_SLIDER_STORE: 'RESET_SLIDER_STORE',
    SET_BLUR: 'SET_BLUR',
    SET_ERROR: 'SET_ERROR',
    SHOW_EDIT_ICON: 'SHOW_EDIT_ICON',
    SLIDER_SEARCH_END: 'SLIDER_SEARCH_END',
    SLIDER_SEARCH_START: 'SLIDER_SEARCH_START',
    TRENDS_GET_END: 'TRENDS_GET_END',
    TRENDS_GET_ERROR: 'TRENDS_GET_ERROR',
    TRENDS_GET_START: 'TRENDS_GET_START',
    USER_CHANGE_LANGUAGE: 'USER_CHANGE_LANGUAGE',
    USER_LOGIN: 'USER_LOGIN',
    USER_LOGIN_FAILED: 'USER_LOGIN_FAILED',
    USER_LOGIN_START: 'USER_LOGIN_START',
    USER_LOGOUT: 'USER_LOGOUT',
    USER_SAVE_PROFILE: 'USER_SAVE_PROFILE',
    USER_UPDATED: 'USER_UPDATED'
};

export default Constants;
