import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//Style
import { useStyles } from 'components/sheet/TrendSheet/TrendSheet.style';
//Locales
import translate from 'locales/i18n';
//Mui components
import {
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    Paper
} from '@mui/material';
//Store
import AccountStore from 'stores/AccountStore';
//Pcp components
import { Line } from 'react-chartjs-2';

const TrendSheet = ({
    bigFilter,
    setTrendLineType,
    trendAnalyze,
    trendLineType
}) => {
    const classes = useStyles(bigFilter)();

    const [data, setData] = useState(null);

    const options ={
        tension: 0.1,
        scales: {
            x: {
                ticks: {
                    callback(value) {
                        return displayDate(this.getLabelForValue(value));
                    }
                },
                stack: true,
            },
            y: {
                ticks: {
                    callback(value, index, values) {
                        if (index + 1 === values.length) {
                            return `${this.getLabelForValue(value)} ${AccountStore.getCurrentAccount().getCurrency()}`;
                        }
                        return this.getLabelForValue(value);
                    }
                },
            }
        },
        plugins: {
            legend : {
                display: false
            },
            tooltip: {
                callbacks: {
                    title(context) {
                        return context[0].dataset.label;
                    },
                    label(context) {
                        return `${translate.i18n('THE')} ${displayDate(context.label)} : ${context.formattedValue} ${AccountStore.getCurrentAccount().getCurrency()}`;
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (trendAnalyze.competitorsAvgPrices) {
            const labels = trendAnalyze.competitorsAvgPrices.map(c => c.date);
            const datasets = [{
                label: AccountStore.getCurrentAccount().isRetailer() ? translate.i18n('TRENDS.CONCURRENT_AVG_PRICE') : translate.i18n('TRENDS.SELLERS_AVG_PRICE'),
                data: trendAnalyze.competitorsAvgPrices.map(c => c.value),
                backgroundColor: 'rgba(105, 206, 209, 1)',
                borderColor: 'rgba(105, 206, 209, 1)',
                pointRadius: 1.5
            }, {
                label: AccountStore.getCurrentAccount().isRetailer() ? translate.i18n('TRENDS.CONCURRENT_TREND_LEGEND') : translate.i18n('TRENDS.SELLERS_TREND_LEGEND'),
                data: trendAnalyze.competitorsTrendLine.map(c => c.value),
                backgroundColor: 'rgba(105, 206, 209, 0.5)',
                borderColor: 'rgba(105, 206, 209, 0.5)',
                pointRadius: 0,
                hitRadius: 0
            },trendAnalyze.accountAvgPrices && {
                label: translate.i18n('TRENDS.YOUR_AVG_PRICE'),
                data: trendAnalyze.accountAvgPrices.map(c => c.value),
                backgroundColor: 'rgba(95, 207, 125, 1)',
                borderColor: 'rgba(95, 207, 125, 1)',
                pointRadius: 1.5
            },trendAnalyze.accountTrendLine && {
                label: translate.i18n('TRENDS.YOUR_TREND_LEGEND'),
                data: trendAnalyze.accountTrendLine.map(c => c.value),
                backgroundColor: 'rgba(95, 207, 125, 0.5)',
                borderColor: 'rgba(95, 207, 125, 0.5)',
                pointRadius: 0,
                hitRadius: 0
            }];
            setData({ labels,datasets });
        }
    }, [trendAnalyze]);

    const displayDate = (date) => `${date.split('-')[2]}/${date.split('-')[1]}/${date.split('-')[0]}`;

    const displayPercentage = (percentage) => percentage === 'NaN' ? ' - ':`${percentage >= 0 ? '+':'-'} ${percentage >= 0 ? percentage : String(percentage).slice(1)} %`;

    return <div className={classes.trendSheetContainer}>
        <div className={classes.evolutionPercentageContainer}>
            {AccountStore.getCurrentAccount().isRetailer() &&
                <div className={classes.evolutionPercentage} style={{ backgroundColor: 'rgba(95, 207, 125, 0.7)' }}>
                    <div className={classes.evolutionPercentageText}>
                        {translate.i18n('TRENDS.YOUR_EVOLUTION')}
                    </div>
                    <div className={classes.evolutionPercentageValue}>
                        {displayPercentage(trendAnalyze.accountEvolution)}
                    </div>
                </div>
            }
            <div className={classes.evolutionPercentage} style={{ backgroundColor: 'rgba(105, 206, 209, 0.7)' }}>
                <div className={classes.evolutionPercentageText}>
                    {AccountStore.getCurrentAccount().isRetailer() ?
                        translate.i18n('TRENDS.CONCURRENT_EVOLUTION')
                        :
                        translate.i18n('TRENDS.RESELLERS_EVOLUTION')}
                </div>
                <div className={classes.evolutionPercentageValue}>
                    {displayPercentage(trendAnalyze.competitorsEvolution)}
                </div>
            </div>
        </div>
        <div className={classes.graphContainer}>
            {data &&
                <Paper style={{ padding: '10px' }}>
                    <Line options={options} data={data} />
                </Paper>
            }
        </div>
        <div className={classes.legendsContainer}>
            <div className={classes.trendType}>
                {translate.i18n('TRENDS.TENDENCE_TYPE')}
                <FormControl>
                    <InputLabel id='trendTypeSelectLabel'>{translate.i18n('TRENDS.TREND_TYPE')}</InputLabel>
                    <Select
                        labelId='trendTypeSelectLabel'
                        id='trendTypeSelect'
                        value={trendLineType}
                        label={translate.i18n('TRENDS.TREND_TYPE')}
                        className={classes.trendTypeSelector}
                        size='small'
                        onChange={(event) => setTrendLineType(event.target.value)}
                    >
                        <MenuItem value={'LINEAR'}>{translate.i18n('TRENDS.LINEAR')}</MenuItem>
                        <MenuItem value={'EXPONENTIAL'}>{translate.i18n('TRENDS.EXP')}</MenuItem>
                        <MenuItem value={'LOGARITHMIC'}>{translate.i18n('TRENDS.LOGARITHMIC')}</MenuItem>
                        <MenuItem value={'POWER'}>{translate.i18n('TRENDS.POWER')}</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className={classes.trendType}>
                {translate.i18n('TRENDS.LEGENDS')}
            </div>
            {AccountStore.getCurrentAccount().isRetailer() && <div className={classes.legend}>
                <div className={classes.yourEvolution} />
                {translate.i18n('TRENDS.YOUR_EVOLUTION_LEGEND')}
            </div>}
            {AccountStore.getCurrentAccount().isRetailer() && <div className={classes.legend}>
                <div className={classes.yourTrend} />
                {translate.i18n('TRENDS.YOUR_TREND_LEGEND')}
            </div>}
            <div className={classes.legend}>
                <div className={classes.concurrentEvolution} />
                {AccountStore.getCurrentAccount().isRetailer() ?
                    translate.i18n('TRENDS.CONCURRENT_EVOLUTION_LEGEND') : translate.i18n('TRENDS.SELLERS_EVOLUTION_LEGEND')
                }
            </div>
            <div className={classes.legend}>
                <div className={classes.concurrentTrend} />
                {AccountStore.getCurrentAccount().isRetailer() ?
                    translate.i18n('TRENDS.CONCURRENT_TREND_LEGEND') : translate.i18n('TRENDS.SELLERS_TREND_LEGEND')
                }
            </div>
        </div>
    </div>;
};

TrendSheet.propTypes = {
    bigFilter: PropTypes.bool,
    trendAnalyze: PropTypes.object,
    trendLineType: PropTypes.string,
    setTrendLineType: PropTypes.func
};

export default TrendSheet;
