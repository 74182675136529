// Abstract
import AbstractStore from 'core/abstracts/AbstractStore';
// Dispatcher
import Dispatcher from 'core/Dispatcher';
// Constants
import Constants from 'constants.js';
// Facade
import Facade from 'core/Facade';

class PerformanceStore extends AbstractStore {

    constructor() {

        const CHANGE_EVENT = 'PERFORMANCE_CHANGE_EVENT';
        const NAME = 'PERFORMANCE_STORE';

        super(NAME, CHANGE_EVENT);

        // Registers action handler with the Dispatcher.
        Dispatcher.register(this._registerToActions.bind(this));

        this._initStore();
    }

    _initStore() {
        this.firstLoad = true;
        this.loading = true;
        this.sites = [];
        this.from = new Date();
        this.to = new Date();
        this.from.setDate(this.to.getDate() - 150);

        if (Facade.getUrlParam('from_perf'))
            this.from = new Date(Facade.getUrlParam('from_perf'));

        if (Facade.getUrlParam('to_perf'))
            this.to = new Date(Facade.getUrlParam('to_perf'));
    }

    setSites(sites) {
        this.sites = sites;
    }

    setParams(options) {
        this.from = options.from;
        this.to = options.to;
    }

    getSites() {
        return this.sites;
    }

    isLoading() {
        return this.loading;
    }

    // Switches over the action's type when an action is dispatched.
    _registerToActions(action) {
        switch (action.actionType) {
        case Constants.USER_LOGOUT:
            this._initStore();
            this._emitChange();
            break;
        case Constants.PERFORMANCE_SEARCH_START:
            this.setParams(action.value);
            this.loading = true;
            this._emitChange();
            break;
        case Constants.PERFORMANCE_SEARCH_END:
            this.setSites(action.value);
            this.firstLoad = false;
            this.loading = false;
            this._emitChange();
            break;
        case Constants.ACCOUNT_CHANGE:
            this._initStore();
            this._emitChange();
            break;
        default:
            return false;
        }
    }
}

export default new PerformanceStore();
