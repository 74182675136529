// Material UI Components
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    '@media (max-width: 950px)': {
        tooCheapLabel: {
            fontSize: '10px'
        },
        tooExpensiveLabel: {
            fontSize: '10px'
        }
    },
    graphContainer: {
        height: '95%',
        marginRight: 10,
        width: '100%'
    },
    infoButton: {
        padding: '5px',
        position: 'absolute',
        right: '2px',
        top: '2px',
        zIndex: 400
    },
    infoImgContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    infoMainText: {
        fontWeight: 500
    },
    infoSubText: {
        fontStyle: 'italic'
    },
    notAllowed: {
        cursor: 'wait',
        opacity: 0.5
    },
    paper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        width: '100%'
    },
    resetButton: {
        padding: '5px',
        position: 'absolute',
        right: '30px',
        top: '2px',
        zIndex: 400
    },
    ticks: {
        alignItems: 'center',
        backgroundImage: 'linear-gradient(to right,' + theme.palette.graphGradiant + ')',
        borderRadius: '4px',
        display: 'flex',
        height: '5%',
        justifyContent: 'space-between',
        padding: '8px',
        position: 'relative',
        width: 'calc(100% + 1px)'
    },
    tooCheapLabel: {
        color: theme.palette.common.grey,
        fontFamily: 'roboto',
        fontSize: '12px',
        margin: 0
    },
    tooExpensiveLabel: {
        color: theme.palette.common.white,
        fontFamily: 'roboto',
        fontSize: '12px',
        margin: 0
    }
}));
