/**
  * @param url <string>
  * @param operations <array[{mul: 10}, {add: 23}]>
  * @param ref <string>
  * @param configs <array[{key: couleur, chosenValue: vert}]>
  * @returns <string> url formatted with add mul ref and configs params
  */
export const buildUrl = (url, operations, configs, refs) => {
    if (!url || typeof url !== 'string') return '';

    const isConfig = configs && !!configs.find(c => c.chosenValue !== '');
    let newUrl = url;

    if (!((operations && operations.length > 0) || refs || isConfig)) return url;

    if (url.charAt(url.length - 1) !== '?') {
        if (url.indexOf('?') !== -1) {
            newUrl += '&';
        } else {
            newUrl += '?';
        }
    }
    if (operations) {
        operations.forEach((operation) => {
            Object.keys(operation)[0] === 'add' ? operation.add !== 0 ? newUrl += 'bw_add=' + operation.add + '&' : null : operation.mul !== 1 ? newUrl += 'bw_mul=' + operation.mul + '&' : null;
        });
    }

    if (refs) {
        newUrl += 'bw_ref=' + refs;
        if (isConfig) newUrl += '&';
    }

    if (isConfig) {
        newUrl += 'bw_config={';
        configs.forEach(config => {
            if (config.chosenValue) newUrl += `[${config.key},${config.chosenValue}]`;
        });
        newUrl += '}';
    }

    if (newUrl.endsWith('&')) newUrl = newUrl.slice(0, -1);
    return newUrl;
};

/**
  * @param url <string>
  * @param startConfigs <array>
  * @returns <object> an object with the url, a list of operations, a list of configs and a ref
  */

export const decodeUrl = (url, startConfigs) => {

    const operations = [];
    const configs = [];
    const refs = [];

    startConfigs && startConfigs.forEach(config => configs.push(Object.assign({ chosenValue: '', display: true, select: true }, config)));
    if (!(url && url.includes('?'))) return { newUrl: url? url:'', configs, operations, refs };

    const splitedUrl = url.split('?');
    const params = splitedUrl[1].split('&');

    let [newUrl] = splitedUrl;

    params.forEach(param => {
        param.startsWith('bw_add') && operations.push({ add: parseFloat(param.split('=')[1]) });
        param.startsWith('bw_mul') && operations.push({ mul: parseFloat(param.split('=')[1]) });
        param.startsWith('bw_ref') && refs.push(param.split('=')[1]);

        if (param.startsWith('bw_config')) {
            const newParam = param.replace('bw_config={', '').replace('}', '').replaceAll('[', '').split(']');

            newParam.forEach(conf => {
                if (conf) {
                    if (configs.length === 0 ) configs.push({ key: conf.split(',')[0], chosenValue: conf.split(',')[1], display: false });
                    else {
                        const existingConfig = configs.find(c => c.key.toLowerCase() === conf.split(',')[0].toLowerCase());
                        if (existingConfig && (existingConfig.chosenValue === '' || existingConfig.chosenValue.toLowerCase() === conf.split(',')[1].toLowerCase())) {
                            [,existingConfig.chosenValue] = conf.split(',');
                            if ( !existingConfig.value || !existingConfig.value.includes(conf.split(',')[1])) existingConfig.select = false;
                        } else configs.push({ key: conf.split(',')[0], chosenValue: conf.split(',')[1], display: false });
                    }
                }
            });
        }

        if (!param.startsWith('bw_add') && !param.startsWith('bw_mul') && !param.startsWith('bw_ref') && !param.startsWith('bw_config')) {
            newUrl.includes('?') ? newUrl += `&${param}` : newUrl += `?${param}`;
        }
    });

    return { newUrl, configs, operations, refs };
};
