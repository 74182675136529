import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: '2px',
        top: '2px'
    },
    datePicker: {
        marginRight: '6px'
    },
    dialogDetails: {
        height: '500px',
        width: '50%'
    },
    loaderContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '90%',
        justifyContent: 'center'
    },
    noSlidesMessages: {
        fontSize: 'large',
        padding: '10px'
    },
    progressContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '85%',
        justifyContent: 'center',
        width: '100%'
    },
    root: {
        fontFamily: 'roboto',
        height: '100%',
        width: '100%',
        overflow: 'auto'
    },
    searchButton: {
        marginLeft: '5px',
        marginRight: '5px'
    },
    sitesSlidesBoxContentSlide: {
        borderRadius: '25%',
        display: 'flex',
        width: '100%'
    },
    sliderCard: {
        backgroundColor: 'rgba( 24, 144, 165 , 0.1)',
        marginBottom: '20px',
        width: '350px'
    },
    sliderCardAction: {
        alignSelf: 'center'
    },
    sliderCardContent: {
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
        height: '270px',
        overflow: 'clip',
        padding: '0px !important'
    },
    sliderCardHeader: {
        cursor: 'pointer',
        padding: '10px'
    },
    sliderCardImage: {
        height: '25px',
        width: '25px'
    },
    sliderCardSimpleBar: {
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': { width: '0px' }
    },
    sliderCardTitle: {
        fontSize: '1.2rem'
    },
    sliderContent: {
        height: '100%',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    sliderHeader: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: '10px',
        marginTop: '6px'
    },
    sliderTimelineFilterContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px'
    },
    slidesBoxContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly'
    },
    slidesContainer: {
        width: '100%'
    },
    slideSiteBoxLabel: {
        color: 'white',
        display: 'flex',
        fontSize: 'large',
        height: '18px',
        justifyContent: 'flex-start',
        paddingLeft: '5px',
        position: 'sticky',
        textAlign: 'left',
        top: 0,
        width: '100%',
        zIndex: 10,
        borderRadius: '3px'
    },
    switchButtons: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: 'auto'
    },
    textContainer: {
        border: '1px solid ' + theme.palette.primary.main,
        borderRadius: '3px',
        color: theme.palette.primary.main,
        margin: '5px',
        padding: '5px',
        width: '75px',
        textAlign: 'center'
    },
    sliderDialogContent: {
        overflow: 'hidden',
        borderRadius: '3px'
    }
}));
