import queryString from 'query-string';
import SignAction from 'actions/SignAction';

class Ajax {

    _appendQuery(uri, query) {
        if (!uri && !query)
            return '';

        const querystring = queryString.stringify(query),
            prefix = uri.indexOf('?') === -1 ? '?' : '&';
        return uri + prefix + querystring;
    }

    /**
     * [call description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    call(options) {
        const responseType = options.responseType || 'json';
        const fetchOptions = {
            method: options.method || 'GET',
            headers: new Headers(options.headers || {})
        };
        let fetchUrl = options.url;

        if (responseType === 'json') {
            fetchOptions.headers.append('accept', 'application/json');
        } else if (responseType === 'text') {
            fetchOptions.headers.append('accept', 'text/plain; charset=UTF-8');
        }

        // Add query arguments to URL
        if (options.params) {
            fetchUrl = Ajax._appendQuery(fetchUrl, options.params);
        }

        // Add body
        if (typeof options.json === 'object') {
            fetchOptions.headers.append('Content-Type', 'application/json');
            fetchOptions.body = JSON.stringify(options.json);
        } else if (typeof options.data === 'object') {
            fetchOptions.headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
            fetchOptions.body = queryString.stringify(options.data);
        }

        // Call API
        return fetch(fetchUrl, fetchOptions).then(response => {
            // Manage errors
            if (response.status === 401 && fetchUrl.indexOf('auth') === -1 && fetchUrl.indexOf('init_reset_password') === -1 && fetchUrl.indexOf('reset_tokens') === -1 && fetchUrl.indexOf('reset_password') === -1)
                SignAction.logout(true);

            if (responseType === 'json' || responseType === 'text') {
                return response;
            }
            return null;
        }, err => {
            throw err;
        });
    }
}

export default new Ajax();
