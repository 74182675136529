// React
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// Locales
import translate from 'locales/i18n';
import withStyles from '@mui/styles/withStyles';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
// Styles
import styles from 'components/table/ItemsListTable/ItemsListTable.style';

class ItemsListTable extends React.PureComponent {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        items: PropTypes.array,
    };

    render() {
        const { classes, items } = this.props;

        return <div className={classes.root}>
            <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                    <TableRow className={classes.tableHead}>
                        <TableCell className={classes.tableHeadCell}>
                            <div className={classes.tableHeadContent}>
                                <Typography variant='body2' color='inherit' noWrap className={classes.title}>
                                    {translate.i18n('ASSOCIATED_ARTICLES_LIST')}
                                </Typography>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map(item =>
                        <TableRow key={item.id}>
                            <TableCell>
                                <Link to={'/items/' + item.id} className={classes.basicCellContent}>
                                    <Typography variant='body2' color='inherit' noWrap className={classes.itemName}>
                                        {item.name}
                                    </Typography>
                                </Link>
                            </TableCell>
                        </TableRow>)
                    }
                </TableBody>
            </Table>
        </div>;
    }
}

export default withStyles(styles)(ItemsListTable);
