import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    basicCell: {
        borderRight: '1px solid' + theme.palette.grey.light
    },
    basicCellContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    label: {
        color: theme.palette.grey.dark,
        fontSize: '14px',
        fontWeight: '500',
        marginLeft: '3px'
    },
    loaderContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    },
    noItemsFound: {
        color: 'rgb(160 160 160)',
        fontSize: '35px',
        fontWeight: 600
    },
    price: {
        color: theme.palette.grey.dark,
        fontSize: '17px',
        fontWeight: '500'
    },
    table: {
        borderCollapse: 'separate'
    },
    tableHead: {
        height: '50px'
    },
    tableHeadCell: {
        backgroundColor: theme.palette.white.main,
        borderRight: '1px solid' + theme.palette.grey.light,
        minWidth: '110px',
        padding: '6px',
        position: 'sticky',
        top: 0,
        zIndex: 11
    },
    tableHeadContent: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    },
    tableHeadFirstCell: {
        backgroundColor: theme.palette.white.main,
        borderRight: '1px solid' + theme.palette.grey.light,
        left: 0,
        padding: 10,
        position: 'sticky',
        top: 0,
        zIndex: 12
    },
    tableHeadSecondCell: {
        backgroundColor: theme.palette.white.main,
        borderRight: '1px solid' + theme.palette.grey.light,
        padding: '0px 10px 0px 10px',
        position: 'sticky',
        top: 0,
        left: '271px',
        zIndex: 12
    }
}));

