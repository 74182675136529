// React
import React from 'react';
import PropTypes from 'prop-types';
// Images
import NoImage from 'assets/img/no-image.png';

class ToPrint extends React.Component {
    static propTypes = {
        alt: PropTypes.string,
        classes: PropTypes.object.isRequired,
        imageUrl: PropTypes.string,
        title: PropTypes.string
    };

    render() {
        const { classes, imageUrl, title, alt } = this.props;
        return (
            <img src={imageUrl} onError={(e) => {
                e.target.onerror = null; e.target.src=NoImage;
            }} alt={alt} title={title} className={classes.slideImage}/>
        );
    }
}

export default ToPrint;
