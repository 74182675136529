// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Material UI Components
import Popper from '@mui/material/Popper';
import NoImage from 'assets/img/no-image.png';
import DialogImage from 'components/dialog/DialogImage/DialogImage';
import Grow from '@mui/material/Grow';
// Styles
import { useStyles } from 'components/commons/ImageViewer/ImageViewer.style';

const ImageViewer = ({ alt, imageStyle, imageUrl, legend, title, hideHover, openDetailsOnClick }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [displayPopper, setDisplayPopper] = useState(true);
    const [hover, setHover] = useState(false);
    const [delayHandler, setDelayHandler] = useState(null);
    const classes = useStyles();

    const handleMouseEnter = () => {
        setDelayHandler(setTimeout(() => {
            setHover(true);
        }, 700));
    };

    const handleMouseLeave = () => {
        clearTimeout(delayHandler);
        setHover(false);
    };

    return (
        <div className={classes.imageContainer}>
            <div className={classes.root} onClick={() => {
                openDetailsOnClick && setDialogOpen(true);
                setHover(false);
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
                <img src={imageUrl} id='imgViewer' onError={(e) => {
                    e.target.onerror = null; e.target.src = NoImage; setDisplayPopper(false);
                }} alt={alt} title={title} className={classes.image} ref={node => {
                    setAnchorEl(node);
                }}
                style={imageStyle ? imageStyle : {}} />
                {displayPopper && !hideHover ?
                    <Popper open={hover}
                        anchorEl={anchorEl}
                        transition
                        disablePortal={false}
                        placement='bottom'
                        modifiers={[
                            {
                                name: 'flip',
                                enabled: true,
                                options: {
                                    altBoundary: true,
                                    rootBoundary: 'document',
                                    padding: 8,
                                },
                            },
                            {
                                name: 'preventOverflow',
                                enabled: true,
                                options: {
                                    altAxis: true,
                                    altBoundary: true,
                                    tether: true,
                                    rootBoundary: 'document',
                                    padding: 8,
                                },
                            }]}
                        className={classes.popper}>
                        {({ TransitionProps, placement }) =>
                            <Grow
                                {...TransitionProps}
                                id='paper-grow'
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <img src={imageUrl}
                                    onError={(e) => {
                                        e.target.onerror = null; e.target.src = NoImage;
                                    }}
                                    alt={alt}
                                    title={title}
                                    className={classes.imgLarge} />
                            </Grow>
                        }
                    </Popper>
                    :
                    null}
            </div>
            <DialogImage open={dialogOpen}
                close={() => setDialogOpen(false)}
                imageUrl={imageUrl}
                alt={alt}
                title={title}
                legend={legend} />
        </div>
    );
};

ImageViewer.defaultProps = {
    openDetailsOnClick: true
};

ImageViewer.propTypes = {
    alt: PropTypes.string,
    imageStyle: PropTypes.object,
    imageUrl: PropTypes.string,
    legend: PropTypes.string,
    title: PropTypes.string,
    hideHover: PropTypes.bool,
    openDetailsOnClick: PropTypes.bool
};

export default ImageViewer;
