// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Facade
import Facade from 'core/Facade';
// Styles
import { useStyles } from 'components/sheet/SliderSheet/SliderSheet.style';
// Material UI Components
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
// Assets
import { Chip, IconButton } from '@mui/material';
// PCP Components
import Slide from 'components/pages/SliderPage/Slide';
import Loader from 'components/commons/Loader/Loader';
//Utils
import { formatDate, similarDate } from 'components/pages/SliderPage/SliderPage.util';
//Theme
import { useTheme } from '@mui/styles';

const SliderSheet = ({ currentDay, evolution, sites }) => {
    const from = new Date(evolution[0]?.date);
    const to = new Date(evolution[evolution.length - 1]?.date);
    const oneDay = 1000 * 60 * 60 * 24;

    const [fromView, setFromView] = useState(new Date());
    const [toView, setToView] = useState(new Date());

    const classes = useStyles();
    const theme = useTheme();

    useEffect(() => {
        setStartDates();
    }, [currentDay]);

    //FUNCTIONS

    const setStartDates = () => {
        const currentDate = new Date(currentDay);

        const diffFromToCurrent = (currentDate - from) / oneDay;
        const diffToCurrent = (to - currentDate) / oneDay;

        const newFromView = new Date(currentDay);
        const newToView = new Date(currentDay);

        if (diffFromToCurrent <= 5) {
            newFromView.setDate(currentDate.getDate() - diffFromToCurrent);
            newToView.setDate(currentDate.getDate() + (10 - diffFromToCurrent));
        } else if (diffToCurrent <= 5) {
            newFromView.setDate(currentDate.getDate() - (10 - diffToCurrent));
            newToView.setDate(currentDate.getDate() + diffToCurrent);
        } else {
            newFromView.setDate(currentDate.getDate() - 5);
            newToView.setDate(currentDate.getDate() + 5);
        }

        setFromView(newFromView.toISOString());
        setToView(newToView.toISOString());
    };

    const changeDate = status => {
        let tempFromView = new Date(fromView);
        let tempToView = new Date(toView);
        let diffDays = 0;

        if (status === 0) {
            diffDays = Math.ceil((tempFromView - new Date(from)) / oneDay);
            tempFromView = new Date(diffDays > 5 ? tempFromView.setDate(tempFromView.getDate() - 5) : tempFromView.setDate(tempFromView.getDate() - diffDays));
            tempToView = new Date(diffDays > 5 ? tempToView.setDate(tempToView.getDate() - 5) : tempToView.setDate(tempToView.getDate() - diffDays));
        } else {
            diffDays = Math.ceil((new Date(to) - tempToView) / oneDay);
            tempFromView = new Date(diffDays > 5 ? tempFromView.setDate(tempFromView.getDate() + 5) : tempFromView.setDate(tempFromView.getDate() + diffDays));
            tempToView = new Date(diffDays > 5 ? tempToView.setDate(tempToView.getDate() + 5) : tempToView.setDate(tempToView.getDate() + diffDays));
        }
        setFromView(tempFromView.toISOString());
        setToView(tempToView.toISOString());
    };

    //RENDERS

    const renderSlides = (day, key, site) => {

        if (day.date >= fromView && day.date <= toView && day.summary.length > 0) {
            const siteSlides = day.summary.find(e => e.slider_site_id === site.id);
            const newSlides = siteSlides.slider_images.filter(s => s.status === 'new');
            const removedSlides = siteSlides.slider_images.filter(s => s.status === 'removed');

            return (
                <div
                    className={classes.sliderSiteDay}
                    key={key}
                    style={siteSlides ? null : { backgroundColor: 'rgb(255,255,255,0.2)' }}>
                    <div className={classes.slidesContainer}>
                        {newSlides.length > 0 &&
                            <>
                                <Chip style={{ backgroundColor: '#33cd58' }} className={classes.sliderInfoChip} />
                                {newSlides.map((slide, i) =>
                                    <div key={i}>
                                        <Slide slide={slide} site={site} openDetailsOnClick={true} />
                                    </div>
                                )}
                            </>
                        }
                        {removedSlides.length > 0 &&
                            <>
                                <Chip style={{ backgroundColor: '#ff241d' }} className={classes.sliderInfoChip} />
                                {removedSlides.map((slide, i) =>
                                    <div key={i}>
                                        <Slide slide={slide} site={site} openDetailsOnClick={true} />
                                    </div>
                                )}
                            </>}
                    </div>
                </div>
            );
        }
        return true;
    };

    if (!evolution || evolution.length === 0 || !fromView || !toView)
        return <Loader size={50} />;

    return <div className={classes.timelineContent}>
        <div className={classes.datesLine}>
            <IconButton
                disabled={similarDate(fromView, from) && evolution.length > 10}
                className={classes.dateLineArrow}
                disableRipple={true}
                onClick={() => changeDate(0)}>
                <KeyboardArrowLeft color={!similarDate(fromView, from) && evolution.length > 10 ? 'black' : 'disabled'} />
            </IconButton>

            {evolution.map((day, dIndex) => {
                if (day.date >= fromView && day.date <= toView) {
                    return (
                        <div className={classes.datesLineDay} style={similarDate(currentDay, day.date) ? { backgroundColor: theme.palette.primary.main, borderRadius: '3px', color: 'white' } : null} key={dIndex}>
                            {formatDate(day.date, true)}
                            <br />
                            {formatDate(day.date, false)}
                        </div>
                    );
                }
                return true;
            })
            }

            <IconButton
                disabled={similarDate(toView, to) && evolution.length > 10}
                edge='end'
                className={classNames(classes.dateLineArrow, classes.dateLineArrowRight)}
                disableRipple={true}
                onClick={() => changeDate(1)}>
                <KeyboardArrowRight color={!similarDate(toView, to) && evolution.length > 10 ? 'black' : 'disabled'} />
            </IconButton>
        </div>

        {sites.map((site, index) =>
            <div className={classes.sliderSite} key={index}>
                <div className={classes.sliderSiteLegend} style={site.color ? { backgroundColor: site.color } : { backgroundColor: 'rgb(190, 215, 219)' }}>
                    <div className={classes.sliderSiteLogoBox} style={site.color ? { borderColor: site.color } : { borderColor: 'rgb(190, 215, 219)' }} onClick={() => Facade.openUrl(site.url)}>
                        <img key={index} src={`https://img.pricecomparator.pro/sites_images/${site.name}/1`} onError={(e) => {
                            e.target.onerror = null; e.target.src = `https://www.google.com/s2/favicons?domain=${site.url}`;
                        }} alt={Facade.siteDisplayName(site.name)} title={Facade.siteDisplayName(site.name)} className={classes.sliderSiteLogo} />
                    </div>
                </div>
                <div className={classes.sliderSiteContent} style={site.color ? { backgroundColor: site.color } : { backgroundColor: 'rgb(190, 215, 219)' }}>
                    {evolution.map((day, key) =>
                        renderSlides(day, key, site)
                    )}
                </div>
            </div>
        )}
    </div>;
};

SliderSheet.propTypes = {
    currentDay: PropTypes.object,
    evolution: PropTypes.array,
    sites: PropTypes.array
};

export default SliderSheet;
