import i18n from 'locales/i18n';

class Facade {

    /**
     * Method which return the correct api route depends of the current env
     *
     * @returns <string> the correct api route
     */
    getApiRoot() {
        console.log('RECT APP ==> ' + process.env.REACT_APP_ENV);
        console.log('RECT APP TEST ==> ' + process.env.REACT_APP_API_ROOT_TEST);
        if (process.env.REACT_APP_ENV === 'test' || process.env.REACT_APP_ENV === 'preproduction' || process.env.REACT_APP_ENV === 'development') {
            return process.env.REACT_APP_API_ROOT_TEST;
        }

        return process.env.REACT_APP_API_ROOT_PROD;
    }

    /**
     * Method which return the current env
     *
     * @returns <string> the current env
     */
    getEnv() {
        return process.env.REACT_APP_ENV;
    }

    /*****************************************************
     * List of functions helping managing url paramaters *
     *****************************************************/

    getAllUrlParams() {
        const searchParams = new URLSearchParams(window.location.search);

        const returnParams = [];

        // Iterate on the search parameters.
        for (const p of searchParams) {
            returnParams.push(p);
        }

        return returnParams;
    }

    getUrlParam(name) {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get(name);
    }

    setAllUrlParams(params) {
        for (const p of Object.keys(params)) {
            if (params[p] && params[p].length !== 0 && params[p] !== undefined && params[p])
                this.setUrlParam(p, params[p]);
        }
    }

    setUrlParam(name, value) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(name);

        searchParams.append(name, value);

        window.history.replaceState({}, '', window.location.pathname + '?' + searchParams);
    }

    deleteUrlParam(name) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(name);
        window.history.replaceState({}, '', window.location.pathname + '?' + searchParams);
    }

    deleteAllUrlParams() {
        const searchParams = new URLSearchParams(window.location.search);

        for (const p of searchParams) {
            searchParams.delete(p[0]);
        }

        window.history.replaceState({}, '', window.location.pathname);
    }

    /****************************************
     * List of functions helping developers *
     ****************************************/

    /**
     * Method which return formatted date
     *
     * @param date <Date> the date to format
     * @returns <string> the formatted date
     */
    formatDate(date, reversed) {
        if (!(date instanceof Date))
            return '';

        const language = i18n.getCurrentLanguage();

        let monthNames = [
            'Jan', 'Feb', 'Mar',
            'Apr', 'May', 'Jun', 'Jul',
            'Aug', 'Sep', 'Oct',
            'Nov', 'Dec'
        ];

        if (language === 'fr') {
            monthNames = [
                'Jan', 'Fev', 'Mars',
                'Avr', 'Mai', 'Juin', 'Jui',
                'Août', 'Sep', 'Oct',
                'Nov', 'Dec'
            ];
        }

        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        if (reversed) return `${year}-${(monthIndex + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        return `${day} ${monthNames[monthIndex]}, ${year}`;
    }

    /**
     * Method which return the name to display of a website
     *
     * @param name <string> name of the site
     * @returns <string> name to display of the site
     */
    siteDisplayName(name) {
        if (!name || typeof name !== 'string') return '';

        if (name.includes('zzzz')) {
            return name;
        }

        if (name.startsWith('YV_')) {
            return name.split('_')[3];
        }

        const nameSplitted = name.split('_');
        const displayName = nameSplitted.length > 1 ? nameSplitted[0] + '.' + nameSplitted[1] : name;

        return displayName;
    }

    /**
     * Method which return the url formatted to be opened
     *
     * @param url <string> the url to format
     * @returns <string> the url ready to be opened
     */
    formatUrl(url) {
        if (!url || typeof url !== 'string') return '';

        if (url.startsWith('http')) {
            return url;
        }

        const formattedUrl = `https://${url}`;

        return formattedUrl;
    }

    /**
     * Method which give us the current route path
     *
     * @returns <string> the current route path
     */
    getCurrentRoute() {
        const route = window.location.pathname;
        route.slice(1, route.length);

        return route;
    }

    /**
     * Method which return the url of the website ico
     *
     * @param url <string> url of the website
     * @returns <string> ico url of the website
     */
    getWebsiteIco(url) {
        if (!url || typeof url !== 'string') return '';

        // IDEA : https://icons.duckduckgo.com/ip3/www.google.com.ico
        // IDEA : https://api.statvoo.com/favicon/?url=google.com
        return `https://www.google.com/s2/favicons?domain=${url}`;
    }

    /**
     * Method which check if an object is empty
     *
     * @param obj <any> the obj we will check his emptiness
     * @returns <boolean> bool which tell us if object is empty or not
     */
    isEmpty(obj) {

        // null and undefined are "empty"
        if (obj === null || typeof obj === 'undefined') return true;

        // Assume if it has a length property with a non-zero value
        // that that property is correct.
        if (obj.length > 0) return false;
        if (obj.length === 0) return true;

        // If it isn't an object at this point
        // it is empty, but it can't be anything *but* empty
        // Is it empty?  Depends on your application.
        if (typeof obj !== 'object') return true;

        // Otherwise, does it have any properties of its own?
        // Note that this doesn't handle
        // toString and valueOf enumeration bugs in IE < 9
        for (const key in obj) {
            if (hasOwnProperty.call(obj, key)) return false;
        }

        return true;
    }

    /**
     * Method which tell us if an argument is an object or not
     *
     * @param o <any> to test if it is an object
     * @returns <boolean> which tell us if the argument is an object or not
     */
    isObject(o) {
        return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
    }

    /**
     * Method which camelize a key
     *
     * @param s <string> to camelize
     * @returns <string> camelized
     */
    toCamel(s) {
        if (!s || typeof s !== 'string') return '';
        return s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
    }

    /**
     * Method which camelize an object
     *
     * @param o <object> the object to camelize
     * @returns <object> the camelize object
     */
    keysToCamel(o) {
        if (this.isObject(o)) {
            const n = {};

            Object.keys(o)
                .forEach((k) => {
                    n[this.toCamel(k)] = this.keysToCamel(o[k]);
                });

            return n;
        } else if (Array.isArray(o)) return o.map((i) => this.keysToCamel(i));

        return o;
    }

    /**
     * Method which give us a number with a certain number of decimal to show
     *
     * @param value <number> the number to cut
     * @param zeroAfter <number> of number to cut after the zero
     * @returns <string> the cut number formatted in string
     */
    showDecimal(value, zeroAfter) {
        return (Math.round(value * 100) / 100).toFixed(zeroAfter);
    }

    /**
     * Method which tell us if a value is a digit
     *
     * @param value <any> the data to test if it is a digit
     * @returns <boolean> which tell us if the argument given is a digit or not
     */
    isDigit(value) {
        const val = !!Number(value);
        return val;
    }

    /**
     * Method to convert to Object
     *
     * @param {any} val
     */
    toObject(val) {
        if (val === null || val === undefined) {
            throw new Error('Object.assign cannot be called with null or undefined');
        }
        return Object(val);
    }

    /**
     * Method to assign data  to new Object
     *
     * @param {any}   target
     */
    objectAssign(target) {
        let from;
        const to = this.toObject(target);
        let symbols;

        for (let s = 1; s < arguments.length; s++) {
            from = Object(arguments[s]);

            for (const key in from) {
                if (Object.hasOwnProperty.call(from, key)) {
                    to[key] = from[key];
                }
            }

            if (Object.getOwnPropertySymbols) {
                symbols = Object.getOwnPropertySymbols(from);
                for (let i = 0; i < symbols.length; i++) {
                    if (Object.propIsEnumerable.call(from, symbols[i])) {
                        to[symbols[i]] = from[symbols[i]];
                    }
                }
            }
        }
        return to;
    }

    openUrl(url) {
        window.open(this.formatUrl(url), '_blank', 'noreferrer');
    }
}

export default new Facade();
