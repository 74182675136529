import makeStyles from '@mui/styles/makeStyles';

export const useStyles = bigFilter => makeStyles(() => ({
    filter: {
        height: bigFilter ? '340px' : '250px',
        maxWidth: bigFilter ? '650px' : '500px',
        transition: 'all 0.7s',
        width: bigFilter ? '48%' : '35%'
    },
    filtersContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%'
    },
    filtersTitle: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '16px',
        fontWeight: 'bold',
        margin: '15px'
    },
    legend: {
        display: 'flex'
    },
    loaderContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '16px',
        fontWeight: 'bold',
        height: bigFilter? 'calc(42% + 25px)':'calc(55% + 25px)',
        justifyContent: 'space-around',
        width: '100%'
    },
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    titleText: {
        fontSize: '16px',
        fontWeight: 'bold',
        margin: '5px'
    }
}));
